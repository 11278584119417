<MatchOnboardingDeadline bind:match {matchInitial} {saveMatch} />
{#if anySteps}
  <StepFilters bind:value={stepCriteria} />
{/if}

{#if applicationSteps?.length}
  <FormGroup
    dataTest="application-steps-form-group"
    valid={isValid}
    validationMessage="{toFriendlyList(stepsRequiredToSubmit)} must be completed in order to submit your application."
    messageAbove
    focusContainer={!schoolComplianceSteps?.length}
    showErrorsOnDirty={false}
  >
    <MatchSteps
      label="Application steps"
      stepType={StepType.Application}
      {match}
      {startDateOverride}
      {endDateOverride}
      steps={applicationSteps}
      {stepCriteria}
      expanded={expanded[StepType.Application]}
    />
  </FormGroup>
{/if}

{#if onboardingSteps?.length}
  <FormGroup>
    <MatchSteps
      stepType={StepType.Onboarding}
      label="Onboarding steps"
      {match}
      {startDateOverride}
      {endDateOverride}
      steps={onboardingSteps}
      {stepCriteria}
      expanded={expanded[StepType.Onboarding]}
    />
  </FormGroup>
{/if}

{#if activeSteps?.length}
  <FormGroup>
    <MatchSteps
      stepType={StepType.Active}
      label="Active steps"
      {match}
      {startDateOverride}
      {endDateOverride}
      steps={activeSteps}
      {stepCriteria}
      expanded={expanded[StepType.Active]}
    />
  </FormGroup>
{/if}

{#if offboardingSteps?.length}
  <FormGroup>
    <MatchSteps
      label="Offboarding steps"
      stepType={StepType.Offboarding}
      {match}
      {startDateOverride}
      {endDateOverride}
      steps={offboardingSteps}
      {stepCriteria}
      expanded={expanded[StepType.Offboarding]}
    />
  </FormGroup>
{/if}

{#if match.status !== MatchStatus.Closed && evaluationSteps?.length}
  <FormGroup>
    <MatchSteps
      label="Evaluation steps"
      stepType={StepType.Evaluation}
      {match}
      {startDateOverride}
      {endDateOverride}
      steps={evaluationSteps}
      {stepCriteria}
      expanded={expanded[StepType.Evaluation]}
    />
  </FormGroup>
{/if}

<script>
  import FormGroup from './bootstrap/FormGroup.svelte'
  import MatchSteps from 'components/MatchSteps.svelte'
  import MatchOnboardingDeadline from 'components/MatchOnboardingDeadline.svelte'
  import { StepType, MatchStatus, StepSubmissionStatus } from 'config/enums.js'
  import StepFilters from 'components/MatchModal.StepFilters.svelte'
  import storage from 'services/local-storage.js'
  import user from 'stores/user.js'
  import { toFriendlyList } from 'services/string-utils.js'

  export let match
  export let matchInitial
  export let startDateOverride
  export let endDateOverride
  export let saveMatch

  const storageKey = 'match-step-criteria'
  let stepCriteria = storage.getItem(storageKey) ?? {
    showAllSteps: false,
    groupBy: storage.getItem('match-steps-group-by') ?? 'user', // using old localstorage key for backwards compatibility, but can safely remove this in a few months (today is 4/14/2022)
  }

  $: storage.setItem(storageKey, stepCriteria)
  $: anySteps =
    match.applicationSteps.length ||
    match.onboardingSteps.length ||
    match.activeSteps.length ||
    match.offboardingSteps.length ||
    match.evaluationSteps.length
  $: showAllSteps = stepCriteria.showAllSteps
  $: userId = $user.userId
  $: schoolComplianceSteps = filterSteps(match.schoolComplianceSteps, showAllSteps, userId)
  $: applicationSteps = filterSteps(match.applicationSteps, showAllSteps, userId)
  $: onboardingSteps = filterSteps(match.onboardingSteps, showAllSteps, userId)
  $: activeSteps = filterSteps(match.activeSteps, showAllSteps, userId)
  $: offboardingSteps = filterSteps(match.offboardingSteps, showAllSteps, userId)
  $: evaluationSteps = filterSteps(match.evaluationSteps, showAllSteps, userId)
  $: matchIsPastUnsubmitted = match.status > MatchStatus.Unsubmitted
  $: expanded = {
    [StepType.SchoolCompliance]: anyStepsRequireAttention(match.schoolComplianceSteps),
    [StepType.Application]: anyStepsRequireAttention(match.applicationSteps),
    [StepType.Onboarding]: matchIsPastUnsubmitted && anyStepsRequireAttention(match.onboardingSteps),
    [StepType.Active]: matchIsPastUnsubmitted && anyStepsRequireAttention(match.activeSteps),
    [StepType.Offboarding]: matchIsPastUnsubmitted && anyStepsRequireAttention(match.offboardingSteps),
    [StepType.Evaluation]: matchIsPastUnsubmitted && anyStepsRequireAttention(match.evaluationSteps),
  }
  $: stepsRequiredToSubmit = [...match.schoolComplianceSteps, ...match.applicationSteps]
    .filter(s => s.status === StepSubmissionStatus.Incomplete && s.submissions.some(sub => sub.canBeCompleteNow))
    .map(s => s.name)
  $: isValid = !(match.status === MatchStatus.Unsubmitted && stepsRequiredToSubmit.length)

  function filterSteps(steps, _showAllSteps, userId) {
    return steps
      .map(step => {
        const submissions = _showAllSteps
          ? step.submissions.filter(sub => shouldSeeSubmission(userId, sub))
          : step.submissions.filter(sub => sub.canBeCompleteNow && shouldSeeSubmission(userId, sub))
        return {
          ...step,
          submissions,
        }
      })
      .filter(s => s.submissions.length)
  }

  function anyStepsRequireAttention(steps) {
    const attentionStatuses = new Set([StepSubmissionStatus.Incomplete, StepSubmissionStatus.Rejected, StepSubmissionStatus.AwaitingVerification])
    return steps.some(s => attentionStatuses.has(s.status))
  }
  // If this changes, update MatchRepository.cs SelectStepViewModelsQuery
  function shouldSeeSubmission(userId, submission) {
    if (
      match.isCoordinator ||
      match.isSchoolCoordinator ||
      submission.canCompleteNow ||
      submission.canDownloadData ||
      submission.canWaive ||
      submission.canVerify ||
      submission.canSkip
    )
      return true

    // if it's about them and not assigned to a specific user, they can see it because it affects their status
    // todo should we show them steps that are about them and ARE assigned to other users?  don't think we need to, at least
    if (submission.targetUserId == userId && submission.userId == null) return true

    // if it's not about them OR anyone else, they can see it because it affects their status
    if (submission.targetUserId == null && submission.userId == null) return true

    return false
  }
</script>

<ConfirmDeleteModal
  type="Opportunity"
  typeIcon="list"
  typeIconClass="color-text-purple"
  item={capacity}
  {buildReasons}
  {buildWarnings}
  {onDelete}
  {onCancel}
  {canDeleteItem}
  {deleteItem}
  {itemWasDeleted}
/>

<script>
  import api from 'services/api.js'
  import ConfirmDeleteModal from 'components/ConfirmDeleteModal.svelte'

  export let capacity
  export let onDelete
  export let onCancel

  function canDeleteItem() {
    return api.capacity.canDelete(capacity, api.noMonitor)
  }

  function deleteItem() {
    return api.capacity.softDelete(capacity, api.noMonitor)
  }

  function itemWasDeleted(result) {
    return result.capacityDeleted
  }

  function buildReasons(builder, result) {
    builder.addMatchStatusCountItems(result.matchStatusCountsPreventingDeletion)
  }

  function buildWarnings(builder, result) {
    builder.addMatchStatusCountItems(result.warningMatchStatusCounts)
    builder.addStepTypeCountItems(result.stepCountsByType)
    builder.addStaffCount(result.staffCount)
  }

  // TODO(services): Linking to match status counts should have a filter...
</script>

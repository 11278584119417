<div class="flex-row flex-align-center g05">
  <Icon
    title={userEmail.emailIsConfirmed ? 'Email confirmed' : 'Email not confirmed'}
    name={userEmail.emailIsConfirmed ? 'check' : 'alert-triangle'}
    class={userEmail.emailIsConfirmed ? 'text-success' : 'text-warning'}
  />
  <span>{userEmail.email}</span>
</div>

<script>
  import Icon from './Icon.svelte'
  export let userEmail
</script>

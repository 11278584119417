<FilterTypeStatus {filter} {editing} {appliedMeta} />

<script context="module">
  import FilterTypeStatus, { meta as baseMeta } from 'components/filter-types/FilterTypeStatus.svelte'
  import { FilterType, NameSuggestionStatus } from 'config/enums.js'
  import nameSuggestionStatusHelper from 'services/name-suggestion-status-helper.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.NameSuggestionStatuses,
    label: 'Status',
    icon: 'filter-status',
    iconClass: 'color-text-steel',
    canHaveMultiple: true,
    enumeration: NameSuggestionStatus,
    helper: nameSuggestionStatusHelper,
  }
</script>

<script>
  export let filter
  export let editing = false
  export let appliedMeta
</script>

<div class="staff-role well mb1 p1" data-test="osr-{index}">
  <div>
    <div class="flex-row flex-align-center g1">
      {#if showServiceOrCapacityAccessibility}
        <div>
          {#if roleGrantsAccess}
            <Icon x2 name="eye" class="text-success" title={roleGrantsAccessTooltip} />
          {:else}
            <Icon x2 name="hide" class="text-danger" title={roleGrantsAccessTooltip} />
          {/if}
        </div>

        <div class="hide">
          <div bind:this={roleGrantsAccessTooltipElem}>
            This role
            {#if roleGrantsAccess}
              <strong>can</strong>
            {:else}
              <strong>cannot</strong>
            {/if}
            access the {accessType}
            <Icon name={accessTypeIcon} class="color-text-purple" /> <strong>{accessTypeName}</strong>
          </div>
        </div>
      {/if}

      <RolePicker class="flex-grow" bind:value={osr.staffRole} name="osr-role-{index}" {disabled} on:change={setAutoAssigned} />

      {#if !disabled}
        <Btn on:click={() => removeOrgStaffRole(index)} title="Remove this role" color="outline-danger" dataTest="remove-role-btn{index}">
          <Icon name="delete" />
        </Btn>
      {/if}
    </div>
  </div>

  {#if osr.staffRole !== StaffRole.Admin || canRestrictByLocation || canRestrictByTeam}
    <div class="flex-row flex-align-center g1">
      {#if showServiceOrCapacityAccessibility}
        <!-- This is invisible just to take up the same horizontal space as the preceding <div>. -->
        <Icon x2 name="eye" class="invisible" />
      {/if}

      <div class="flex-grow">
        {#if isRoleAutoAssignable}
          <div class="mt05 flex-column" data-test="osr-{index}-extra">
            <InputCheckbox bind:checked={osr.isAutoAssigned} name="osr-auto-assigned-{index}" {disabled} labelClass="m0">
              {#if osr.staffRole === StaffRole.Preceptor}
                Automatically assign to rotations matching this role’s criteria
              {:else}
                Give access to rotations matching this role’s criteria
              {/if}
              {#if osr.staffRole === StaffRole.Preceptor}
                <Help>If unchecked, they will need to be manually assigned to specific rotations.</Help>
              {/if}
            </InputCheckbox>
          </div>
        {/if}

        <div class="flex-column g1" class:mt05={!isRoleAutoAssignable}>
          {#if inputLocationsVisible}
            <div>
              <div class="flex-row flex-align-center g05" class:disabled>
                <Icon name="map-marker" class={orgAndTeamClass} />
                <span>For <strong>locations</strong></span>
              </div>

              <SimpleOrgPicker
                bind:this={locationsPicker}
                bind:value={inputOrgIds}
                multiple
                placeholder="All locations"
                {disabled}
                ancestorOrgId={input.orgId}
              />
            </div>
          {/if}

          {#if inputTeamsVisible}
            <div>
              <div class="flex-row flex-align-center g05" class:disabled>
                <Icon name="users" class={orgAndTeamClass} />
                <span>For <strong>teams</strong></span>
              </div>

              <TeamPicker
                bind:this={teamsPicker}
                name="osr-teams-{index}"
                teams={teamOptions}
                bind:value={inputTeamIds}
                placeholder="All teams"
                multiple
                {disabled}
              />
            </div>
          {/if}

          {#if inputServicesVisible}
            <div>
              <div class="flex-row flex-align-center g05" class:disabled>
                <Icon name="shapes" class="color-text-purple" />
                <span>For <strong>services</strong></span>
              </div>

              <!-- TODO(services): Add locked filters for orgIds and teamIds.
                                  orgIds={roleOrgIds}
                                  teamIds={osr.teamIds}
              -->
              <ServicePicker
                bind:this={servicesPicker}
                name="osr-services-{index}"
                dataTest="osr-services-{index}"
                multiple
                bind:value={inputServiceIds}
                {disabled}
                modalTitle="Select services this role should be limited to"
                placeholder="All services"
              />
            </div>
          {/if}

          {#if inputCapacitiesVisible}
            <div>
              <div class="flex-row flex-align-center g05" class:disabled>
                <Icon name="list" class="color-text-purple" />
                <span>For <strong>opportunities</strong></span>
              </div>

              <!-- TODO(services): Add locked filters for orgIds and teamIds.
                                  orgIds={roleOrgIds}
                                  teamIds={osr.teamIds}
              -->
              <CapacityPicker
                bind:this={capacitiesPicker}
                name="osr-capacities-{index}"
                dataTest="osr-capacities-{index}"
                multiple
                bind:value={inputCapacityIds}
                {disabled}
                modalTitle="Select opportunities this role should be limited to"
                placeholder="All opportunities"
              />
            </div>
          {/if}

          {#if inputDisciplinesVisible}
            <div>
              <div class="flex-row flex-align-center g05" class:disabled>
                <Icon name="book" class="color-text-teal" />
                <span>For <strong>disciplines</strong></span>
              </div>

              <DisciplinesPicker
                bind:this={disciplinesPicker}
                multiple
                placeholder="All disciplines"
                bind:value={inputDisciplines}
                enabledOptions={roleOptions?.disciplines}
                hideDisabledOptions
                leaveDisabledOptionsSelected
                class={null}
                btnClass="btn btn-default full-width text-left"
                {disabled}
              />
            </div>
          {/if}

          {#if inputCustomTagsVisible}
            <div>
              <div class="flex-row flex-align-center g05" class:disabled>
                <Icon name="tag" class="color-text-light-brown" />
                <span>For <strong>rotations tagged with</strong></span>
              </div>

              <CustomTagsPicker
                bind:this={customTagsPicker}
                name="osr-custom-tags-{index}"
                orgId={input.orgId}
                bind:value={inputCustomTagIds}
                enabledCustomTagIds={roleOptions?.customTagIds}
                hideDisabledOptions
                leaveDisabledOptionsSelected
                placeholder="Anything/nothing"
                multiple
                {disabled}
              />
            </div>
          {/if}

          {#if inputStudentsVisible}
            <div>
              <div class="flex-row flex-align-center g05" class:disabled>
                <Icon name="users" class="color-text-teal" />
                <span>For <strong>students</strong></span>
              </div>

              <div class:disabled>For <strong>students</strong></div>
              <StudentPicker
                bind:this={studentsPicker}
                multiple
                name="assigned-students"
                dataTest="assigned-students"
                placeholder="All students"
                bind:value={inputStudentUserIds}
                disciplines={inputDisciplines}
                noDisciplineFilterWarning
                orgId={input.orgId}
                orgIds={osr.orgIds}
                {disabled}
                maxVisible={17}
              />
            </div>
          {/if}

          {#if textLinks.length}
            <!-- class="small" is too small; but without setting the font-size, roles that can be restricted by everything force the <Help> to the next line -->
            <div class="flex-row flex-align-center g05" class:disabled style="font-size: {showServiceOrCapacityAccessibility ? 92 : 95}%">
              {#if disabled}
                <span>
                  <em>Unrestricted</em> by
                  <FriendlyList or items={textLinks} max={Infinity} punctuation let:item={link}><strong>{link.restrictedBy}</strong></FriendlyList
                  >.</span
                >
              {:else}
                <span>
                  <em>Unrestricted</em> by
                  <FriendlyList or items={textLinks} max={Infinity} punctuation let:item={link}
                    ><a
                      href={null}
                      on:click={link.onClick}
                      data-test="osr-{index}-restrict-by-{link.dataTest}-link"
                      use:tip={`Click to ${anyInputVisible ? 'further' : ''} restrict this role by ${link.restrictedBy}`}>{link.restrictedBy}</a
                    ></FriendlyList
                  >.</span
                >
                <Help
                  >Click {textLinks.length === 1 ? 'the link' : 'one of the links'} to {anyInputVisible ? 'further' : ''} restrict this role{textLinks.length ===
                  1
                    ? ` by ${textLinks[0].restrictedBy}`
                    : ''}</Help
                >
              {/if}
            </div>
          {/if}

          {#if osrInitial.staffRole === StaffRole.Preceptor && osrInitial.isAutoAssigned && (osr.staffRole !== StaffRole.Preceptor || !osr.isAutoAssigned)}
            <Alert type="warning" class="m0">
              <strong class="strongish">{input.name}</strong> will be removed from rotations they were <em>automatically</em> assigned to as preceptor.
              If they were manually assigned as preceptor for a given rotation, they will remain assigned and will need to be manually removed (if desired).
            </Alert>
          {:else if osrInitial.staffRole === StaffRole.Preceptor && osrInitial.isAutoAssigned && osr.staffRole === StaffRole.Preceptor && osr.isAutoAssigned && (!validator.equals(osrInitial, osr) || orgHasChanged)}
            <Alert type="warning" class="m0">
              If <strong class="strongish">{input.name}</strong> was automatically added to a rotation, the assignment will be reviewed based on the new
              role criteria. For manually assigned rotations, they will remain in place unless manually removed.
            </Alert>
          {/if}
        </div>
      </div>
    </div>
  {/if}
</div>

<script>
  import { StaffRole } from 'config/enums.js'
  import { tick } from 'svelte'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import CapacityPicker from 'components/fields/CapacityPicker.svelte'
  import CustomTagsPicker from 'components/fields/CustomTagsPicker.svelte'
  import DisciplinesPicker from 'components/fields/DisciplinesPicker.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Help from 'components/Help.svelte'
  import Icon from 'components/Icon.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import orgsService from 'services/orgs-service.js'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import RolePicker from 'components/fields/RolePicker.svelte'
  import roleService from 'services/role-service.js'
  import ServicePicker from 'components/fields/ServicePicker.svelte'
  import SimpleOrgPicker from './SimpleOrgPicker.svelte'
  import StudentPicker from 'components/fields/StudentPicker.svelte'
  import TeamPicker from 'components/TeamPicker.svelte'
  import tip from 'decorators/tip.js'
  import validator from 'services/validator.js'
  import Alert from 'components/bootstrap/Alert.svelte'

  export let osr
  export let index
  export let input
  export let removeOrgStaffRole

  export let hasHealthFeature
  export let showServiceOrCapacityAccessibility
  export let canRestrictByLocation
  export let canRestrictByTeam
  export let canRestrictByServiceOrCapacity
  export let canRestrictByDiscipline
  export let canRestrictByCustomTag
  export let canRestrictByStudent

  export let roleGrantsAccess
  export let accessType
  export let accessTypeIcon
  export let accessTypeName

  export let orgs
  export let descendantOrgOptions
  export let teams
  export let loading = false
  export let canManageEntireStaffRecord = false
  export let orgHasChanged = false

  $: orgAndTeamClass = hasHealthFeature ? 'color-text-orange' : 'color-text-blue'

  const osrInitial = _.cloneDeep(osr)
  const buildTextLink = (restrictedBy, canRestrict, shouldShowInput, onClick) =>
    canRestrict && !shouldShowInput ? { restrictedBy, onClick, dataTest: _.kebabCase(restrictedBy) } : null

  let inputOrgIds = [...osr.orgIds]
  let inputTeamIds = [...osr.teamIds]
  let inputServiceIds = [...osr.serviceIds]
  let inputCapacityIds = [...osr.capacityIds]
  let inputDisciplines = [...osr.disciplines]
  let inputCustomTagIds = [...osr.customTagIds]
  let inputStudentUserIds = [...osr.studentUserIds]

  // Whether or not to show the inputs. If false, shows a text link instead.
  let showInputLocations = false
  let showInputTeams = false
  let showInputServices = false
  let showInputCapacities = false
  let showInputDisciplines = false
  let showInputCustomTags = false
  let showInputStudents = false
  let roleGrantsAccessTooltipElem = null
  // Variables needed to focus/open pickers when the user clicks a text link
  let locationsPicker = null
  let teamsPicker = null
  let servicesPicker = null
  let capacitiesPicker = null
  let disciplinesPicker = null
  let customTagsPicker = null
  let studentsPicker = null
  // If the user puts the role in a state where they couldn't manage it, let them undo it.
  let wasEnabled = false

  $: showInputLocations ||= !!inputOrgIds.length
  $: showInputTeams ||= !!inputTeamIds.length
  $: showInputServices ||= !!inputServiceIds.length
  $: showInputCapacities ||= !!inputCapacityIds.length
  $: showInputDisciplines ||= !!inputDisciplines.length
  $: showInputCustomTags ||= !!inputCustomTagIds.length
  $: showInputStudents ||= !!inputStudentUserIds.length

  $: inputLocationsVisible = canRestrictByLocation && showInputLocations
  $: inputTeamsVisible = canRestrictByTeam && showInputTeams
  $: inputServicesVisible = canRestrictByServiceOrCapacity && isRoleAssignableToOpportunitiesOrServicesOrDisciplines && showInputServices
  $: inputCapacitiesVisible = canRestrictByServiceOrCapacity && isRoleAssignableToOpportunitiesOrServicesOrDisciplines && showInputCapacities
  $: inputDisciplinesVisible = canRestrictByDiscipline && isRoleAssignableToOpportunitiesOrServicesOrDisciplines && showInputDisciplines
  $: inputCustomTagsVisible = canRestrictByCustomTag && showInputCustomTags && [StaffRole.Coordinator, StaffRole.Preceptor].includes(osr.staffRole)
  $: inputStudentsVisible = canRestrictByStudent && showInputStudents && osr.staffRole === StaffRole.Coordinator
  $: anyInputVisible =
    inputLocationsVisible ||
    inputTeamsVisible ||
    inputServicesVisible ||
    inputCapacitiesVisible ||
    inputDisciplinesVisible ||
    inputCustomTagsVisible ||
    inputStudentsVisible

  $: roleGrantsAccessTooltip = roleGrantsAccessTooltipElem
    ? { content: roleGrantsAccessTooltipElem, options: { theme: 'light-gray-scrollable' } }
    : null

  $: isRoleAssignableToOpportunitiesOrServicesOrDisciplines = roleService.isRoleAssignableToServicesOrOpportunitiesOrDisciplines(osr.staffRole)
  $: isRoleAutoAssignable = roleService.isRoleAutoAssignable($persona, osr.staffRole)

  $: textLinks = [
    buildTextLink('locations', canRestrictByLocation, showInputLocations, addInputLocations),
    buildTextLink('teams', canRestrictByTeam, showInputTeams, addInputTeams),
    buildTextLink(
      'services',
      canRestrictByServiceOrCapacity && isRoleAssignableToOpportunitiesOrServicesOrDisciplines,
      showInputServices,
      addInputServices
    ),
    buildTextLink(
      'opportunities',
      canRestrictByServiceOrCapacity && isRoleAssignableToOpportunitiesOrServicesOrDisciplines,
      showInputCapacities,
      addInputCapacities
    ),
    buildTextLink(
      'disciplines',
      canRestrictByDiscipline && isRoleAssignableToOpportunitiesOrServicesOrDisciplines,
      showInputDisciplines,
      addInputDisciplines
    ),
    buildTextLink(
      'custom tags',
      canRestrictByCustomTag && [StaffRole.Coordinator, StaffRole.Preceptor].includes(osr.staffRole),
      showInputCustomTags,
      addInputCustomTags
    ),
    buildTextLink('students', canRestrictByStudent && osr.staffRole === StaffRole.Coordinator, showInputStudents, addInputStudents),
  ].filter(Boolean)

  async function addInputLocations() {
    showInputLocations = true
    await tick()
    locationsPicker.focus()
    locationsPicker.open()
  }

  async function addInputTeams() {
    showInputTeams = true
    await tick()
    teamsPicker.focus()
    teamsPicker.open()
  }

  async function addInputServices() {
    showInputServices = true
    await tick()
    servicesPicker.focusAndOpen()
  }

  async function addInputCapacities() {
    showInputCapacities = true
    await tick()
    capacitiesPicker.focusAndOpen()
  }

  async function addInputDisciplines() {
    showInputDisciplines = true
    await tick()
    disciplinesPicker.focusAndOpen()
  }

  async function addInputCustomTags() {
    showInputCustomTags = true
    await tick()
    customTagsPicker.focus()
    const promise = customTagsPicker.open()
    if (promise) await promise
  }

  async function addInputStudents() {
    showInputStudents = true
    await tick()
    studentsPicker.focusAndOpen()
  }

  // The org(s) for this role
  $: roleOrgIds = osr?.orgIds?.length ? osr.orgIds : [input?.orgId]
  // Their available team IDs
  $: roleOrgTeamIds = getRoleOrgTeamIds(orgs, roleOrgIds)
  // The teams available to this role based on the org(s)
  $: teamOptions = teams?.filter(t => roleOrgTeamIds.has(t.teamId))
  let roleOptions = null

  $: setOsrOrgIds(inputOrgIds, descendantOrgOptions)
  $: setOsrTeamIds(inputTeamIds, teamOptions)
  $: setOsrServiceIds(inputServiceIds, roleOptions)
  $: setOsrCapacityIds(inputCapacityIds, roleOptions)
  $: setOsrDisciplines(inputDisciplines, roleOptions)
  $: setOsrCustomTagIds(inputCustomTagIds, roleOptions)
  $: setOsrStudentUserIds(inputStudentUserIds)

  $: wouldBeDisabled = osr.staffRoleId != null && !canManageEntireStaffRecord && !personaService.canManageRole(input.orgId, osr)
  $: wasEnabled ||= !wouldBeDisabled
  $: disabled = !wasEnabled && wouldBeDisabled

  async function getRoleOptions() {
    loading = true
    try {
      roleOptions = await api.role.getStaffRoleOptions(
        { orgId: input.orgId },
        { orgIds: osr.orgIds, teamIds: osr.teamIds, hasHealthFeature },
        api.noMonitor
      )
    } finally {
      loading = false
    }
  }

  getRoleOptions()

  function setOsrOrgIds(orgIds, orgs) {
    if (!orgs) return
    const oldIds = osr.orgIds
    const newIds = orgIds.filter(id => orgs.some(o => o.orgId === id))
    if (validator.equals(oldIds, newIds)) return
    osr.orgIds = newIds
    getRoleOptions()
  }

  function setOsrTeamIds(teamIds, teamOptions) {
    if (!teamOptions) return
    const oldIds = osr.teamIds
    const newIds = teamIds.filter(id => teamOptions.some(t => t.teamId === id))
    if (validator.equals(oldIds, newIds)) return
    osr.teamIds = newIds
    getRoleOptions()
  }

  function setOsrServiceIds(serviceIds, roleOptions) {
    if (!roleOptions || !hasHealthFeature) return
    const oldIds = osr.serviceIds
    const newIds = serviceIds.filter(id => roleOptions.serviceIds.includes(id))
    if (validator.equals(oldIds, newIds)) return
    osr.serviceIds = newIds
  }

  function setOsrCapacityIds(capacityIds, roleOptions) {
    if (!roleOptions || !hasHealthFeature) return
    const oldIds = osr.capacityIds
    const newIds = capacityIds.filter(id => roleOptions.capacityIds.includes(id))
    if (validator.equals(oldIds, newIds)) return
    osr.capacityIds = newIds
  }

  function setOsrDisciplines(disciplines, roleOptions) {
    if (!hasHealthFeature) {
      osr.disciplines = disciplines
      return
    }
    if (!roleOptions) return
    const oldValues = osr.disciplines
    const newValues = disciplines.filter(value => roleOptions.disciplines.includes(value))
    if (validator.equals(oldValues, newValues)) return
    osr.disciplines = newValues
  }

  function setOsrCustomTagIds(customTagIds, roleOptions) {
    if (!roleOptions) return
    const oldValues = osr.customTagIds
    const newValues = customTagIds.filter(id => roleOptions.customTagIds.includes(id))
    if (validator.equals(oldValues, newValues)) return
    osr.customTagIds = newValues
  }

  function setOsrStudentUserIds(studentUserIds) {
    const oldValues = osr.studentUserIds
    const newValues = studentUserIds
    if (validator.equals(oldValues, newValues)) return
    osr.studentUserIds = newValues
  }

  function getRoleOrgTeamIds(orgs, roleOrgIds) {
    if (orgs == null) return new Set()
    const roleOrgs = roleOrgIds?.length
      ? roleOrgIds
          .map(orgId => orgs.find(o => o.orgId === orgId))
          // convert org to an array of itself and its ancestors and its children
          .flatMap(o => [...orgsService.getFlatAncestors(o, orgs), ...orgsService.getFlatChildren(o, orgs, false)])
      : orgs
    const includedTeamIds = roleOrgs.flatMap(o => o.teams).map(t => t.teamId)
    return new Set(includedTeamIds)
  }

  function setAutoAssigned() {
    // coordinators are usually auto-assigned to rotations, whereas preceptors are often individually added to rotations explicitly
    osr.isAutoAssigned = osr.staffRole !== StaffRole.Preceptor
  }
</script>

<style>
  div.disabled {
    opacity: 0.65;
  }
</style>

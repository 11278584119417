{#if showLeft || showRight}
  <div class="details-container mt1" class:no-left={!showLeft}>
    {#if showLeft}
      <div class="flex-column g1 details-left">
        {#if show.activeCapacityCount}
          <ActiveCapacityCount count={service.activeCapacityCount} />
        {/if}

        {#if show.agreements}
          <AgreementCount count={service.agreementIds} />
        {/if}

        {#if show.description}
          <LongDesc class="m0" max={longDescMax} text={service.descriptionHtml} />
        {/if}

        {#if (show.specialties && service.specialties?.length) || (show.disciplines && disciplines?.length)}
          <div>
            <TagCloud
              ctx={{
                specialties: show.specialties ? service.specialties : [],
                disciplines: show.disciplines ? disciplines : [],
              }}
            />
          </div>
        {/if}
      </div>
    {/if}

    {#if showRight}
      <div class="flex-column g2 details-right">
        <div class="service-owner">
          <div class="flex-row flex-align-center g05 mb05">
            <Icon name="hospital" class="color-text-orange" />
            <h4 class="leading-none m0">Provided by</h4>
          </div>

          <div class="flex-column g05">
            {#if show.organization}
              <OrgProfilePictureAndName
                name={service.orgName}
                relativeName={service.orgRelativeName}
                profilePicture={service.orgProfilePicture}
                formattedAddress={service.orgAddress?.formattedAddress}
                size="medium"
                useOverflowEllipsis
              />
            {/if}

            {#if show.team && service.teamOrgId}
              <TeamProfilePictureAndName medium orgId={service.teamOrgId} name={service.teamName} />
            {/if}
          </div>
        </div>
      </div>
    {/if}
  </div>
{/if}

<script>
  import { computeServiceDisciplines } from 'components/InheritedTagPicker.svelte'
  import ActiveCapacityCount from 'components/ActiveCapacityCount.svelte'
  import AgreementCount from 'components/AgreementCount.svelte'
  import Icon from 'components/Icon.svelte'
  import LongDesc from 'components/LongDesc.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import TagCloud from 'components/TagCloud.svelte'
  import TeamProfilePictureAndName from 'components/TeamProfilePictureAndName.svelte'

  export let service = null
  // export let isSelected
  // export let isHovered

  $: show = $showDropdowns.servicePicker
  $: showLeft = show.activeCapacityCount || show.agreements || show.description || show.specialties || show.disciplines
  $: showRight = show.organization || show.team
  $: disciplines = computeServiceDisciplines(service)

  const longDescMax = 60
</script>

<style lang="scss">
  .details-container {
    display: grid;
    gap: 20px;
    // TODO(services): Make responsive
    grid-template-columns: auto 320px;
    grid-template-rows: auto;
    grid-template-areas: 'left right';

    &.no-left {
      grid-template-columns: auto;
      grid-template-areas: 'right';
    }
  }

  .details-left {
    grid-area: left;
  }

  .details-right {
    grid-area: right;
  }
</style>

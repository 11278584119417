import { FeatureType, Permission } from 'config/enums.js'

import metadata from './step-report.js'

export default {
  ...metadata,
  permission: Permission.ViewSchoolComplianceStepsReport,
  featureTypes: [FeatureType.SchoolComplianceRequirements],
  componentName: 'SchoolComplianceStepsReport',
  name: 'School compliance steps report',
  icon: 'SchoolCompliance',
  color: 'purple',
}

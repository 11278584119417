<span class="{className ? `${className} ` : ''}text-{helper.color}" data-test="agreement-status-text">
  <Icon name={helper.icon} />
  {helper.label}
</span>

<script>
  import agreementStatusHelper from 'services/agreement-status-helper.js'
  import Icon from 'components/Icon.svelte'

  export let status
  let className = 'strong'
  export { className as class }

  $: helper = agreementStatusHelper.get(status)
</script>

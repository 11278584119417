<Modal lg on:close={onClose}>
  <h4 slot="title">
    {#if capacityId}
      <div class:small={capacity?.name}>Opportunity staff</div>
      {capacity?.name ?? ''}
    {:else if serviceId}
      <div class:small={service?.name}>Service staff</div>
      {service?.name ?? ''}
    {:else}
      Staff
    {/if}
  </h4>

  <div class="modal-body">
    {#if loadingServiceOrCapacity}
      <Loading />
    {:else}
      {#if capacity?.service}
        <InputToggle name="include-service-staff" bind:checked={$includeServiceStaff} containerClass="flex-row flex-align-center g05 mb1">
          Include staff with full access to <Icon name="shapes" class="color-text-purple" />
          <strong>{capacity.service.name}</strong>.
        </InputToggle>
      {/if}

      {#if canManageStaff}
        <div class="mb1">
          <IconTextLink text="Add staff" href="staff/+" dataTest="add-staff-btn" />
        </div>
      {/if}

      <Grid
        name="staff-grid"
        let:row
        bind:currentPage
        bind:pageSize
        {loading}
        {rows}
        {paginatorTotalCount}
        paginatorBottomControlsClass="mb2"
        rowKeySelector={row => row.staffId}
        class="middle"
        pagesOfLabelSingular="staff member"
      >
        <GridCol {row} name="user" header="Staff member">
          <div class="flex-row flex-align-center g1">
            <ProfilePic src={row.user.profilePictureBig} id={row.user.userId} name={row.user.name} initials={row.user.initials} />
            {row.user.name}
          </div>
        </GridCol>

        <GridCol {row} name="roles" header="Roles">
          <StaffRoles {hasHealthFeature} {hasSchoolFeature} {hasTeamFeature} {hasCustomTagFeature} orgStaff={row} />
        </GridCol>

        <GridCol {row} name="title" header="Title" />

        <GridCol {row} name="actions" header="" class="shrink">
          {#if canManageStaff}
            <Btn
              icon="staff-settings"
              color="sm btn-primary"
              href="staff/{row.user.userName}"
              title="Manage this user’s title, external ID, and roles">Manage</Btn
            >
          {/if}
          <Btn icon="user" color="sm btn-outline-primary" href="/user/{row.user.userName}">View profile</Btn>
        </GridCol>
      </Grid>
    {/if}
  </div>
</Modal>

<Router>
  <Route path=":userName" let:params>
    <ServiceOrCapacityStaffModal
      {serviceId}
      {service}
      {capacityId}
      {capacity}
      {orgs}
      {teams}
      userName={params.userName}
      orgStaff={staffByUserName[params.userName]}
      onClose={onCloseStaffModal}
      on:reloadStaff={getStaffRows}
      {setWarningContext}
    />
  </Route>
</Router>

<script>
  import { FeatureType, Permission } from 'config/enums.js'
  import { Route, Router } from 'svelte-routing'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Grid from 'components/Grid.svelte'
  import GridCol from 'components/GridCol.svelte'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import Loading from 'components/Loading.svelte'
  import LocalStorageStore from 'stores/local-storage-store.js'
  import Modal from 'components/Modal.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import ProfilePic from 'components/ProfilePic.svelte'
  import StaffRoles from 'components/StaffRoles.svelte'
  import ServiceOrCapacityStaffModal from 'components/ServiceOrCapacityStaffModal.svelte'
  import unsavedForms from 'stores/unsaved-forms.js'

  export let serviceId
  export let service = null
  export let capacityId
  export let capacity = null
  export let onClose
  export let setWarningContext
  export let baseHref
  export let orgs
  export let teams

  const hasHealthFeature = personaService.canUseAnyFeatureType(FeatureType.HealthInstitution)
  const hasSchoolFeature = personaService.canUseAnyFeatureType(FeatureType.TeachingInstitution)
  const hasTeamFeature = personaService.canUseAnyFeatureType(FeatureType.TeamManagement)
  const hasCustomTagFeature = personaService.canUseAnyFeatureType(FeatureType.CustomTags)

  let loading = false
  let rows = []
  let paginatorTotalCount = null
  let currentPage = 1
  let pageSize = 10
  let staffByUserName = {}
  const includeServiceStaff = LocalStorageStore(`include-service-staff`, true)

  // TODO(services): This should be computed here, but work for capacities too
  // How it used to work (from a parent component):
  // function onServiceSet() {
  //   rolesThatCanManageThisService =
  //     service != null
  //       ? personaService.getRolesWithPermission(Permission.ManageServices, service.orgId, service.teamId, { serviceId: service.serviceId })
  //       : []
  //   canManageStaff = !!rolesThatCanManageThisService.length
  //   setEditableCapacities()
  // }

  // TODO(services): This should default to false, or be computed reactively

  const canManageStaff = personaService.hasStaffPermission(
    Permission.ManageStaff,
    capacity?.orgId ?? service?.orgId,
    capacity?.teamId ?? service?.teamId
  )

  $: serviceId, capacityId, $includeServiceStaff, currentPage, pageSize, getStaffRows()

  $: if (capacityId && capacity == null) loadCapacity()
  $: if (serviceId && service == null) loadService()
  $: loadingServiceOrCapacity = (capacityId && capacity == null) || (serviceId && service == null)

  $: orgs, teams, loadAvailableOrgsAndTeams()

  async function loadCapacity() {
    const response = await api.capacity.list({ selectedCapacityIds: [capacityId], pageSize: 0, excludeTotalCount: true })
    capacity = response.selectedCapacities[0]
  }

  async function loadService() {
    const response = await api.service.list({ selectedServiceIds: [serviceId], pageSize: 0, excludeTotalCount: true })
    service = response.selectedServices[0]
  }

  async function getStaffRows() {
    if (loading) return
    loading = true
    try {
      const response = await api.staff.search(
        {
          serviceId,
          capacityId,
          includeServiceStaff: $includeServiceStaff,
          offset: (currentPage - 1) * pageSize,
          pageSize,
        },
        api.noMonitor
      )
      paginatorTotalCount = response.totalCount
      rows = response.staff
      if (rows != null) {
        for (const row of rows) {
          staffByUserName[row.user.userName] = row
        }
        staffByUserName = staffByUserName
      }
    } finally {
      loading = false
    }
    if (currentPage > 1 && !rows.length) {
      currentPage = 1
    }
  }

  export function reload() {
    getStaffRows()
  }

  let orgsPromise = null
  let teamsPromise = null
  async function loadAvailableOrgsAndTeams() {
    if (orgsPromise || teamsPromise || orgs || teams) return
    orgsPromise = api.org.getChildren({ orgId: $persona.org?.rootOrgId }, api.noMonitor)
    teamsPromise = api.org.getTeams({ orgId: $persona.org?.rootOrgId, filterToUser: false }, api.noMonitor)
    const [orgsResponse, teamsResponse] = await Promise.all([orgsPromise, teamsPromise])
    orgs = orgsResponse
    teams = teamsResponse.map(t => ({
      ...t,
      disabled: !personaService.hasStaffPermission(Permission.ManageStaff, $persona.orgId, t.teamId),
    }))
    orgsPromise = null
    teamsPromise = null
  }

  function onCloseStaffModal() {
    unsavedForms.navigateSafe(`${baseHref}/${capacityId ?? serviceId}/staff`)
  }
</script>

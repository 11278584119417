<InputSelect
  bind:value
  {name}
  {options}
  filterable
  filterStringSelector={cg => [cg.guestOrgName]}
  valueSelector={cg => cg}
  {placeholder}
  {multiple}
  let:option={capacityGuest}
>
  <CapacityGuestProfilePictureAndName {capacityGuest} />
</InputSelect>

<script>
  import InputSelect from 'components/fields/InputSelect.svelte'
  import CapacityGuestProfilePictureAndName from 'components/CapacityGuestProfilePictureAndName.svelte'

  export let capacity
  export let value
  export let selected = null // Calling code can bind this upward to know the selected org(s), not just orgId(s). Calling code should not _set_ this, only read it.
  export let multiple = false
  export let name = multiple ? 'shift-guests' : 'shift-guest'
  export let placeholder = multiple ? 'Select schools' : 'Select school'
  export let excludeOpenAnySchool = false

  $: loading = capacity == null
  $: options = loading ? [] : excludeOpenAnySchool ? capacity.guests.filter(g => !!g.guestOrgId) : capacity.guests
  $: selectedArray = multiple ? value.map(v => options.find(o => o.orgId === v)) : [options.find(o => o.orgId === value)]
  $: selected = multiple ? selectedArray : selectedArray[0]
</script>

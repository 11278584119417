<UnauthorizedLayout backHref="/login" on:backClick={clearTwoFactor} backText="Back to log in">
  {#if loginResult.addTwoFactor}
    <h2>
      <Icon name="shield-check" />
      Add additional security
    </h2>
    <p class="text-left">
      Because you have access to confidential information, your organization requires an additional security measure to protect your account. From now
      on, when you login, you’ll need to enter a second code that you can choose to get via a text message or generated by an authenticator
      application. You’ll need to enter the code to finish logging in. This 2-step verification process increases the security of your account.
    </p>
    <label>How would you like to receive your verification code?</label>
    <InputSelect
      bind:value={twoFactorMethod}
      name="two-factor-method"
      options={[
        { value: 'authenticatorApp', label: 'Authenticator app' },
        { value: 'sms', label: 'Text message' },
      ]}
    />
    {#if twoFactorMethod === 'sms'}
      <Form on:submit={() => sendCodeNewPhone(input.phone)}>
        <FormGroup class="mt1">
          <InputText name="phone" bind:value={input.phone} placeholder="Mobile number" autofocus />
          <HelpBlock>We do not share this phone number. It will only be used for 2-step verification.</HelpBlock>
        </FormGroup>
        <FormGroup>
          <button type="submit" class="btn btn-block btn-{verifying ? 'disabled' : 'primary'} btn-lg mr1" disabled={verifying}>
            {verifying ? 'Verifying…' : 'Send verification code'}
          </button>
          <BackButton on:click={clearTwoFactor} />
        </FormGroup>
      </Form>
    {:else if twoFactorMethod === 'authenticatorApp'}
      <Form on:submit={() => verify(input.token, twoFactorMethod)} class="pt2">
        <AddTwoFactorAppForm bind:token={input.token} authenticatorInfo={loginResult.authenticatorInfo} />
        <VerifyButton {verifying} />
        <BackButton on:click={clearTwoFactor} />
      </Form>
    {/if}
  {:else}
    <h2>Two-step verification</h2>
    <p>
      Please enter the code
      {#if usingAuthenticator}
        from your authenticator app
      {:else}
        sent to mobile number ending in *
        <strong>{loginResult.phoneEnding}</strong>
      {/if}
    </p>
    <Form on:submit={() => verify(input.token, twoFactorMethod)}>
      <FormGroup valid={validator.required(input.token)}>
        <InputText name="token" bind:value={input.token} placeholder="Code" autofocus autocomplete="off" />
      </FormGroup>
      <FormGroup>
        <VerifyButton {verifying} />
      </FormGroup>
      {#if !usingAuthenticator}
        <div>
          <i
            use:tip={{
              content:
                "If you’re certain you've entered your mobile number correctly, try refreshing the page and try again. If the issue continues, contact Clinician Nexus.",
              options: { theme: 'help', interactive: true },
            }}
          >
            Didn't receive the code?
          </i>
        </div>
      {/if}
    </Form>
  {/if}
</UnauthorizedLayout>

<script>
  import AddTwoFactorAppForm from 'components/AddTwoFactorAppForm.svelte'
  import BackButton from 'components/TwoFactor.BackButton.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import UnauthorizedLayout from './UnauthorizedLayout.svelte'
  import VerifyButton from 'components/TwoFactor.VerifyButton.svelte'
  import { createEventDispatcher } from 'svelte'
  import tip from 'decorators/tip.js'

  import api from 'services/api.js'
  import validator from 'services/validator.js'

  const dispatch = createEventDispatcher()

  export let loginResult = {
    phoneEnding: null,
    addTwoFactor: null,
    verifyTwoFactor: false,
    authenticatorInfo: null,
  }
  let verifying = false
  const input = {
    token: '',
    phone: '',
  }
  let twoFactorMethod = null

  $: isValid = (() => {
    const addTwoFactor = loginResult == null ? null : loginResult.addTwoFactor
    return addTwoFactor && twoFactorMethod === 'sms' ? validator.required(input.phone) : validator.required(input.token)
  })()

  $: usingAuthenticator = loginResult != null && validator.empty(loginResult.phoneEnding)

  function sendCodeNewPhone(phone) {
    if (!isValid) return false
    const data = { phone }
    api.account.sendCodeNewPhone(data).then(phoneEnding => {
      loginResult.phoneEnding = phoneEnding
      loginResult.addTwoFactor = false
    })
  }

  function verify(token, twoFactorMethod) {
    if (!isValid) return false
    verifying = true
    const data = { token, twoFactorMethod }
    api.account.verifyCode(data).then(
      () => dispatch('verified'),
      () => (verifying = false)
    )
  }

  function clearTwoFactor() {
    if (loginResult == null) return
    loginResult.verifyTwoFactor = false
  }
</script>

<Modal on:close={onClose}>
  <h4 slot="title">Upgrade required</h4>
  <FeatureNeeded btnClass="btn-primary" messageClass="modal-body" btnContainerClass="modal-footer" {action}>
    <Btn color="outline-default" on:click={onClose}>Maybe later</Btn>
  </FeatureNeeded>
</Modal>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import FeatureNeeded from 'components/FeatureNeeded.svelte'
  import Modal from 'components/Modal.svelte'

  export let action
  export let onClose
</script>

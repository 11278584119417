<InputNumber
  bind:value
  bind:this={inputNumber}
  {min}
  {max}
  {name}
  {sm}
  {condense}
  class={className}
  {style}
  {autofocus}
  {disabled}
  dataTest={name}
  selectAllOnFocus
  on:focus
  on:keyup><span class="input-group-addon">{alwaysSingularUnit ? unit : singularOrPlural(value, unit)}</span></InputNumber
>

<script>
  import { singularOrPlural } from 'services/string-utils.js'
  import InputNumber from 'components/fields/InputNumber.svelte'

  export let value
  export let autofocus = false
  export let unit = 'day' // common one, so can be default
  export let disabled = false
  export let name = null
  export let min = 0
  export let max = null
  export let alwaysSingularUnit = false
  export let style = 'width: 50px'
  export let sm = false
  export let condense = false
  let className = 'input-group'
  export { className as class }

  let inputNumber

  export function focus() {
    setTimeout(() => inputNumber?.focus?.())
  }
</script>

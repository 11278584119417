<div class="flex-row flex-align-center g05">
  <Icon name="calendar-clock" class="mr-1 color-text-dark-green" />
  {#if capacity.matchLeadTimeDays > 0}
    <span data-test="lead-time">{pluralCount('day', capacity.matchLeadTimeDays)}</span>
    {#if verbose}
      of lead time
    {/if}
  {:else if verbose}
    <span><em class="small text-gray">No lead time</em></span>
  {:else}
    <span>
      <em class="small text-gray">None</em>
    </span>
  {/if}
</div>

<script>
  import { pluralCount } from 'services/string-utils'
  import Icon from 'components/Icon.svelte'

  export let capacity
  export let verbose = true
</script>

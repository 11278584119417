import { writable, derived } from 'svelte/store'

// <Sidebar> and <AuthenticatedNavigation> contribute to determining how much space
// is used up by left-navigation. This tells <MediaQuery> how much space is available
// for non-navigation content.
export const mainNavWidth = writable(0)
export const sideNavWidth = writable(0)
export const navWidth = derived([mainNavWidth, sideNavWidth], ([$mainNavWidth, $sideNavWidth]) => $mainNavWidth + $sideNavWidth)

// <Sidebar> and <AuthenticatedNavigation> contribute to determining how much space
// is used up by left-navigation. This tells <MediaQuery> how much space is available
// for non-navigation content.
export const mainNavTransitioning = writable(false)
export const sideNavTransitioning = writable(false)
import { getDifferences, hasDifferences } from 'services/object-utils.js'
import { isDevEnvironment } from 'services/environment.js'
import { navigate } from 'svelte-routing'
import { PersonaType } from 'config/enums.js'
import { writable } from 'svelte/store'
import personaStore from 'stores/persona.js'
import userStore from 'stores/user.js'

let isImpersonating = false
let isCnUser = false

userStore.subscribe(u => (isImpersonating = u?.isImpersonating))
personaStore.subscribe(p => (isCnUser = p?.personaType === PersonaType.CN))

// holds forms that have unsaved changes
function createUnsavedFormsStore() {
  const { subscribe, update } = writable({ forms: [], warnFor: null })
  const add = (configOrFormName, allowedPaths = []) =>
    update(state => ({
      ...state,
      forms: [
        ...state.forms,
        typeof configOrFormName === 'string' ? { formName: configOrFormName, allowedPaths } : { ...configOrFormName, allowedPaths },
      ],
    }))
  const del = formName => update(state => ({ ...state, forms: state.forms.filter(f => f.formName !== formName) }))
  const warn = onNavAway => update(state => ({ ...state, warnFor: { form: state.forms.at(-1) ?? null, onNavAway } }))
  const cancelNavAway = () => update(state => ({ ...state, warnFor: null }))
  const navAway = () => update(state => ({ warnFor: null, forms: state.forms.filter(f => f.formName !== state.warnFor.form.formName) }))

  let unsavedForm = null
  subscribe(state => (unsavedForm = state.forms.at(-1) ?? null))

  function pathIsSafe(path) {
    if (path == null || unsavedForm === null) return true
    const { allowedPaths } = unsavedForm
    if (!allowedPaths?.length) return false
    for (const allowedPath of allowedPaths) {
      if (allowedPath instanceof RegExp) {
        if (allowedPath.test(path)) return true
      } else if (path === allowedPath) return true
    }
    return false
  }

  function navigateSafe(path = null) {
    return new Promise(res => {
      const onNavAway = () => {
        if (path != null) navigate(path)
        res()
      }
      if (pathIsSafe(path)) onNavAway()
      else update(state => ({ ...state, warnFor: { path, form: unsavedForm, onNavAway } }))
    })
  }

  function doActionSafe(action, formName = null) {
    if (unsavedForm === null || (formName != null && unsavedForm.formName !== formName)) action()
    else warn(action)
  }

  function formHasChanges(formName, inputInitial, input, { comparableInput = _.identity, allowedPaths = [] } = {}) {
    let hasChanges = false
    let differences = null
    const cInputInitial = comparableInput(inputInitial)
    const cInput = comparableInput(input)

    const config = { formName }
    // CN users pay the performance cost of tracking all changes
    // whereas non-CN users short-circuit on the first difference
    if (isDevEnvironment || isCnUser || isImpersonating) {
      differences = getDifferences(cInputInitial, cInput)
      hasChanges = differences.length > 0
      if (hasChanges) {
        config.inputInitial = cInputInitial
        config.input = cInput
        config.differences = differences
      }
    } else {
      hasChanges = hasDifferences(cInputInitial, cInput)
    }

    if (hasChanges) {
      add(config, allowedPaths)
    } else {
      del(formName)
    }

    return hasChanges
  }

  return {
    subscribe,
    add, // add a form to the collection of forms that have unsaved changes
    del, // remove a form from if it has no unsaved changes
    warn, // ConfirmNavAway component calls this when the user tries to nav away with unsaved changes
    cancelNavAway, // ConfirmNavAway component calls this when the user cancels navigating away to continue working on the form
    navAway, // ConfirmNavAway component calls this when the user continues to navigate away and discards form changes
    navigateSafe, // all programmatic navigation (or closing of modals, etc) should use this to avoid losing unsaved form data
    pathIsSafe, // check whether navigating to the given path should result in a warning
    doActionSafe, // if no url change is happening, use this
    formHasChanges,
  }
}

export default createUnsavedFormsStore()

import { ArchiveActiveFilter, DashboardViewCategory, CriteriaFilterType, FeatureType, FilterType, Permission } from 'config/enums.js'

const excludedFilterTypes = [
  FilterType.KeywordSearch,
  FilterType.MatchClosedDate,
  FilterType.MatchCloseReason,
  FilterType.MatchEndDate,
  FilterType.MatchHasIncompleteSteps,
  FilterType.MatchHasProposedChanges,
  FilterType.MatchHasStepsICanComplete,
  FilterType.MatchHasStepsICanVerify,
  FilterType.MatchPastStatuses,
  FilterType.MatchStartDate,
  FilterType.MatchStatusCategory,
  FilterType.MatchStatuses,
  FilterType.StepSubmissionTargetUser,
]
// TODO: filterTypes and ignoredFilterTypes should use excludedFilterTypes but there is a conflict with MatchStatusCategory
export default {
  category: DashboardViewCategory.Reports,
  permission: Permission.Reports,
  featureTypes: [FeatureType.CapacityCalendar],
  componentName: 'CapacityCalendar',
  name: 'Opportunity usage report',
  icon: 'dashboard-calendar',
  criteria: {
    filters: [
      {
        type: FilterType.MatchStatusCategory,
        config: {
          statusCategory: ArchiveActiveFilter.Active,
        },
      },
    ],
    zoom: 'm',
    startDate: dayjs().format('M/D/YYYY'), // start at exactly today since they likely don't care about historical capacity usage--they likely only want to resolve any issues going forward
    endDate: dayjs().add(60, 'days').format('M/D/YYYY'), // go more than a month into the future, so it's clear user can look at more than just 1 month at a time
    filled: false,
    pending: false,
    waitlisted: false,
    overfilled: false,
    overfilledMaybe: false,
    showAll: true,
  },
  excludedFilterTypes,
  filtersConfig: [
    {
      prefix: '',
      name: 'filters',
      type: CriteriaFilterType.MatchFilter,
    },
  ],
  criteriaConfig: {
    zoom: { name: 'z' },
    startDate: { name: 'sd', method: 'readDate' },
    endDate: { name: 'ed', method: 'readDate' },
    filled: { name: 'f', method: 'readBool' },
    pending: { name: 'p', method: 'readBool' },
    waitlisted: { name: 'w', method: 'readBool' },
    overfilled: { name: 'of', method: 'readBool' },
    overfilledMaybe: { name: 'ofm', method: 'readBool' },
    showAll: { name: 'sa', method: 'readBool' },
    groupBy: { name: 'gb' },
    contextFilter: { name: 'cf' },
    keyword: { name: 'k' },
  },
  waitFor: { method: 'POST', route: /\/reports\/capacity-calendar/i },
  testFor: ['calendar'],
}

<div class="mb2">
  <Form on:submit={addComment} bind:this={commentForm}>
    <FormGroup class={enableCommentVisibilityPicker ? 'mb1' : 'mb0'}>
      <MessageBox
        bind:this={messageBoxComponent}
        placeholder="Add a comment…"
        bind:message={value}
        {mentionableUsers}
        name="new-comment"
        {disabled}
        maxlength={5000}
      />
      {#if !enableCommentVisibilityPicker}
        <HelpBlock>{commentBoxHelpText}</HelpBlock>
      {/if}
    </FormGroup>
    {#if hasChanges}
      <FormGroup>
        <div class="flex-column g05">
          {#if enableCommentVisibilityPicker}
            <CommentVisibilityPicker {matchUsers} bind:selectedUsers bind:selectedRoles></CommentVisibilityPicker>
          {/if}
          <Btn
            type="submit"
            class="btn-{addingComment ? 'disabled' : 'primary'}"
            disabled={addingComment}
            dataTest="add-comment-btn"
            icon="paper-plane"
          >
            {addingComment ? 'Adding comment…' : 'Add comment'}
          </Btn>
        </div>
      </FormGroup>
    {/if}
  </Form>
</div>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import MessageBox from 'components/fields/MessageBox.svelte'
  import toaster from 'services/toaster.js'
  import unsavedForms from 'stores/unsaved-forms.js'
  import validator from 'services/validator.js'
  import CommentVisibilityPicker from './CommentVisibilityPicker.svelte'

  export let onAddComment
  export let mentionableUsers
  export let commentBoxHelpText
  export let disabled = false
  export let enableCommentVisibilityPicker = false
  export let matchUsers = []

  const form = 'MatchActivity'
  let commentForm
  let addingComment = false
  let messageBoxComponent
  let value = ''
  let selectedUsers
  let selectedRoles

  $: hasChanges = !validator.empty(value)
  $: hasChanges ? unsavedForms.add(form) : unsavedForms.del(form)

  async function addComment() {
    if (addingComment) return
    addingComment = true
    try {
      await onAddComment(
        value,
        selectedRoles,
        selectedUsers?.map(u => u.userId)
      )
      value = ''
      messageBoxComponent?.focus?.()
      toaster.toast({ message: 'Comment saved', type: 'success', icon: 'comment-empty' })
    } finally {
      addingComment = false
    }
  }
</script>

<Btn
  on:click={startWaive}
  {loading}
  {disabled}
  class="{btnSm ? 'btn-sm ' : ''}btn-{stepSubmission.waived ? 'default' : 'warning'}"
  dataTest="waive-step"
>
  {stepSubmission.waived ? 'Unwaive' : 'Waive'}
</Btn>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import { StepAction } from 'config/enums.js'
  import api from 'services/api.js'

  export let stepSubmission
  export let startDateOverride
  export let endDateOverride
  export let onAction
  export let btnSm
  export let disabled = false

  let loading = false

  async function startWaive() {
    const shouldWaive = !stepSubmission.waived
    loading = true
    try {
      await onAction({
        type: shouldWaive ? StepAction.Waive : StepAction.UnWaive,
        invoke: buildInvoke(shouldWaive),
        alwaysShowOthers: true,
      })
    } finally {
      loading = false
    }
  }

  function buildInvoke(waived) {
    return stepSubmissions =>
      api.step.setStepsWaived(
        {
          startDateOverride,
          endDateOverride,
          waived,
        },
        stepSubmissions,
        api.noMonitor
      )
  }
</script>

<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} let:option={o} />

<script context="module">
  import FilterTypeListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeListFilter.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.Specialties,
    idKey: 'optionValue',
    label: 'Specialty',
    icon: 'file-certificate',
    iconClass: 'text-specialty',
    canHaveMultiple: true,
    filterProp: 'specialties',
    optionsEndpoint: 'getSpecialties',
    filterPropType: 'string',
    hasDoesntHave: true,
    labelApplied: 'specialty',
    allowEmpty: true,
    toMany: true,
    toManySuffix: 'of the following specialties',
    editLabel: null,
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>

import { CloseReason, MatchStatus, StepType } from 'config/enums.js'

import dateService from 'services/date-service.js'
import { formatEnumKey } from 'services/formatters.js'
import { getEnumerationOptions } from 'services/enemueration-helpers-utils.js'
import validator from 'services/validator.js'

function makeStatusHelper(key, icon, label, color, reportColor, describe) {
  return { key, icon, label, color, reportColor, describe }
}

const statuses = {
  [MatchStatus.Unsubmitted]: makeStatusHelper(
    MatchStatus.Unsubmitted,
    'Unsubmitted',
    'Unsubmitted',
    'warning',
    '#f79a48',
    match =>
      `${
        match.isStudent ? 'Your application' : 'Application'
      } is not yet visible to the clinical site. Once all application requirements are completed, it can be submitted to the clinical site for consideration.`
  ),

  [MatchStatus.PendingApplicationVerification]: makeStatusHelper(
    MatchStatus.PendingApplicationVerification,
    'PendingApplicationVerification',
    'Pending application verification',
    'purple',
    '#7e57c2',
    match => `${match.isStudent ? 'Your application' : 'Application'} is awaiting manual review by clinical site staff.`
  ),

  [MatchStatus.PendingClinicalSite]: makeStatusHelper(
    MatchStatus.PendingClinicalSite,
    'PendingClinicalSite',
    'Pending clinical site',
    'purple',
    '#7e57c2',
    match =>
      `${
        match.isStudent ? 'Your application' : 'Application'
      } is awaiting approval by the clinical site. You will be notified when the clinical site makes a decision.`
  ),

  [MatchStatus.Waitlisted]: makeStatusHelper(
    MatchStatus.Waitlisted,
    'Waitlisted',
    'Waitlisted',
    'sky',
    '#00c2e0',
    match =>
      `${match.isStudent ? 'Your application' : 'Application'} was added to waitlist by ${
        match.waitlistByUser == null ? 'clinical site staff' : match.waitlistByUser.name
      } ${dateService.fromnow(match.waitlistDate)}. Clinical site staff has until end of day ${dayjs(match.startDate)
        .add(-match.waitlistExpireWithinDays, 'day')
        .format('M/D/YYYY')} to accept ${match.isStudent ? 'your' : 'the'} application, or it will automatically close.`
  ),

  [MatchStatus.Paused]: makeStatusHelper(MatchStatus.Paused, 'Paused', 'Paused', 'gray', '#4e844f', match =>
    match.pausedReason
      ? `Reason: ${match.pausedReason}`
      : match.isForDocumentation
      ? 'Rotation has been manually paused by a clinical or school coordinator; a reason would have been supplied.'
      : ''
  ),

  [MatchStatus.Onboarding]: makeStatusHelper(
    MatchStatus.Onboarding,
    'Onboarding',
    'Onboarding',
    'warning',
    '#f3aa41',
    (/*match*/) =>
      `Rotation has been accepted by the clinical site. Students, faculty, and school coordinators should complete all steps prior to their due date.`
    // console.log(match) || match.onboardingDeadline || match.startDate // steps with lead time are due prior to start date, so let's just keep this generic. Maybe later, give summary showing how many steps are due on x date, y date etc
  ),

  [MatchStatus.Ready]: makeStatusHelper(
    MatchStatus.Ready,
    'Ready',
    'Ready',
    'success',
    '#74c476',
    match =>
      `Rotation has been accepted and all parties have completed and verified the required steps in order to begin the rotation. ${
        match.isStudent || match.isFaculty ? `You are ready to go!` : ''
      }`
  ),

  [MatchStatus.Active]: makeStatusHelper(
    MatchStatus.Active,
    'Active',
    'Active',
    'success',
    '#31a354',
    match => `Rotation started ${dateService.datestamp(match.startDate)} and will end on ${dateService.datestamp(match.endDate)}.`
  ),

  [MatchStatus.Offboarding]: makeStatusHelper(
    MatchStatus.Offboarding,
    'Offboarding',
    'Offboarding',
    'pink',
    '#c87a95',
    () => `Rotation has ended, but there are or will be outstanding steps to complete.`
  ),

  [MatchStatus.Completed]: makeStatusHelper(
    MatchStatus.Completed,
    'Completed',
    'Completed',
    'gray',
    '#666666',
    () => `Rotation was successfully completed and has finished offboarding.`
  ),

  [MatchStatus.Closed]: makeStatusHelper(MatchStatus.Closed, 'Closed', 'Closed', 'danger', '#ff2a32', match => {
    const hasOtherReason = !validator.empty(match.otherClosedReason)
    const showCloseReason = !(match.closedReason === CloseReason.Other && hasOtherReason)
    const closeReason = showCloseReason ? formatEnumKey(match.closedReasonName) : null
    return match.closedReasonName == null
      ? match.isForDocumentation
      ? 'Rotation was manually closed by a user, or it was automatically closed because a step was not completed in time that was configured to auto-close the rotation if not completed on time.'
      : 'Reason was not specified.'
      : `Reason${closeReason ? `: ${closeReason}` : ''}${hasOtherReason ? `: ${match.otherClosedReason}` : ''}`
  }),
}

// todo do a migration to fix matchchanges with status = 0
const brokenStatusInfo = {
  icon: '',
  color: 'warning',
}

function get(status) {
  return statuses.hasOwnProperty(status) ? statuses[status] : brokenStatusInfo
}

function getStep(status, stepLabel, stepHref) {
  const matchStatus = get(status)
  return {
    ...matchStatus,
    stepLabel,
    stepHref,
  }
}

export default {
  get(status) {
    return get(status)
  },

  getByName(statusName) {
    return MatchStatus.hasOwnProperty(statusName) && statuses.hasOwnProperty(MatchStatus[statusName])
      ? statuses[MatchStatus[statusName]]
      : brokenStatusInfo
  },

  getByStepType(stepType) {
    if (stepType === StepType.SchoolCompliance)
      return getStep(MatchStatus.PendingApplicationVerification, 'School compliance', 'settings/school-compliance-steps')
    if (stepType === StepType.Application) return getStep(MatchStatus.PendingApplicationVerification, 'Application', 'settings/application-steps')
    if (stepType === StepType.Onboarding) return getStep(MatchStatus.Onboarding, 'Onboarding', 'settings/onboarding-steps')
    if (stepType === StepType.Offboarding) return getStep(MatchStatus.Offboarding, 'Offboarding', 'settings/offboarding-steps')
    if (stepType === StepType.Active) return getStep(MatchStatus.Active, 'Active', 'settings/active-steps')
    return getStep(MatchStatus.Completed, 'Evaluation', 'settings/evaluation-steps')
  },

  getOptions($persona) {
    // don't show 'unsubmitted' option unless they're a student
    return getEnumerationOptions(MatchStatus, this).filter(h => h.key !== MatchStatus.Unsubmitted || $persona.isStudent)
  },
}

// singleton that manages our websocket connection

// import sockets from 'sockets'
// import { onDestroy } from 'svelte'
// let unsubscribe = sockets.on('IM', im => console.log(im.from.name, im.to.name, im.message))
// onDestroy(unsubscribe)

import { ApiCache } from 'services/apigenerated.js'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import environment from './environment.js'
import pubsub from 'services/pubsub.js'

function firePubSubDebounced(lowerEventNames, eventName, regex) {
  const alreadyFired = lowerEventNames.includes(eventName.toLowerCase())
  if (alreadyFired) return
  const shouldFire = lowerEventNames.some(n => regex.test(n))
  if (shouldFire) pubsub.fire(eventName)
}

class Sockets {
  constructor() {
    this.connection = new HubConnectionBuilder()
      .withUrl(`${environment.linkApi}/cnhub`)
      // "configures the client to wait 0, 2, 10, and 30 seconds respectively before trying each reconnect attempt, stopping after four failed attempts."
      // https://docs.microsoft.com/en-us/aspnet/core/signalr/javascript-client?view=aspnetcore-3.1#reconnect-clients
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build()
  }

  async connect() {
    try {
      await this.connection.start()
      window.cn_signalR_connectionId = this.connection.connection.connectionId
      ApiCache.resetAllCaches()
      window.dispatchEvent(new Event('SocketsReady'))
      window.__socketsReady = true
    } catch (error) {
      ApiCache.resetAllCaches()
      // eslint-disable-next-line no-console
      console.log('connection failed to start', error)
    }

    this.connection.onreconnected(connectionId => (window.cn_signalR_connectionId = connectionId))

    this.connection.onclose(() => {
      ApiCache.resetAllCaches()
      // eslint-disable-next-line no-console
      console.log('connection lost, reconnecting')
      window.__socketsReady = false
    })

    // fire events unique to each notification type
    this.connection.on('Notification', notification => {
      for (const notificationType of notification.notificationTypeNames) {
        pubsub.fire(notificationType)
      }
      const lowerEventNames = notification.notificationTypeNames.map(n => n.toLowerCase())
      firePubSubDebounced(lowerEventNames, 'UserChanged', /^(?:staff|student)/i)
      firePubSubDebounced(lowerEventNames, 'ConnectionsChanged', /^connection/i)
      firePubSubDebounced(lowerEventNames, 'AgreementsChanged', /^agreement/i)
    })

    // TODO: would like to do a pass to remove the underscore convention here - probably should just fire RefreshMatchModal from server instead of UI { Name = MatchModal } for example
    this.connection.on('UI', ui => pubsub.fire('UI_' + ui.name, ui))
  }

  on(eventName, callback) {
    this.connection.on(eventName, callback)
    pubsub.on(eventName, callback)

    // return a function that'll remove listener
    return () => {
      this.connection.off(eventName, callback)
      pubsub.off(eventName, callback)
    }
  }

  invoke(method, payload) {
    this.connection.invoke(method, payload)
  }
}

export default new Sockets()

<!--take in a FeatureType and link to documentation to show what the featuretype will give them-->
<div class={messageClass}>You must upgrade your subscription to {action}.</div>
<div class={btnContainerClass}>
  <Btn class={btnClass} icon="subscription" href="/pricing">Upgrade subscription</Btn>
  <slot />
</div>

<script>
  import Btn from './bootstrap/Btn.svelte'

  export let action
  export let messageClass = 'pb2'
  export let btnClass = 'btn-lg btn-outline-primary'
  export let btnContainerClass = null
</script>

<div class="flex-row flex-align-center g05">
  {#if overfilledShifts.length}
    <Icon name="alert-triangle" class="text-danger" title={overfilledShiftTooltipElem ? { content: overfilledShiftTooltipElem, options } : null} />
  {/if}

  {#if overfilledMaybeShifts.length}
    <Icon
      name="alert-triangle"
      class="text-warning"
      title={overfilledMaybeShiftTooltipElem ? { content: overfilledMaybeShiftTooltipElem, options } : null}
    />
  {/if}

  {#if anyBusy}
    <Icon
      name="calendar-day"
      class="text-warning"
      dataTest="is-double-book"
      title={doubleBookMessage ? { content: doubleBookMessage, options } : null}
    />
  {/if}

  <div class="hide">
    <div bind:this={overfilledShiftTooltipElem} class="text-left">
      <h4 class="mb05">
        {#if overfilledShifts.length === 1}
          This availability window will overfill
        {:else}
          These availability windows will overfill
        {/if}
      </h4>
      <ShiftCards shifts={overfilledShifts} {...shiftCardsProps} />
    </div>

    <div bind:this={overfilledMaybeShiftTooltipElem} class="text-left">
      <h4 class="mb05">
        {#if overfilledShifts.length === 1}
          This availability window might overfill
        {:else}
          These availability windows might overfill
        {/if}
      </h4>
      <ShiftCards shifts={overfilledMaybeShifts} {...shiftCardsProps} />
    </div>
  </div>
</div>

<script>
  import { toFriendlyList } from 'services/string-utils.js'
  import Icon from 'components/Icon.svelte'
  import ShiftCards from 'components/ShiftCards.svelte'

  export let capacity
  export let busy
  export let buckets

  const options = { theme: 'light-gray-scrollable', interactive: true }
  const shiftCardsProps = {
    capacity,
    noTooltip: true,
    isDetailsView: true,
    cardClass: 'm0',
  }

  let overfilledShifts = []
  let overfilledMaybeShifts = []

  let overfilledShiftTooltipElem = null
  let overfilledMaybeShiftTooltipElem = null

  $: buckets, setOverFillWarnings()
  $: anyBusy = busy?.length > 0
  $: doubleBookMessage =
    busy == null || busy.length === 0
      ? null
      : `${toFriendlyList(busy.map(otherMatch => otherMatch.name))} ${
          busy.length === 1 ? 'has' : 'have'
        } another rotation on this day. See above for details.`

  function setOverFillWarnings() {
    overfilledShifts = []
    overfilledMaybeShifts = []
    for (const shiftId in buckets) {
      const bucket = buckets[shiftId]
      if (bucket.overfilled) overfilledShifts.push(bucket)
      if (bucket.overfilledMaybe > 0) overfilledMaybeShifts.push(bucket)
    }
  }
</script>

<OrgProfilePictureAndName
  {name}
  {size}
  {useOverflowEllipsis}
  {nameClass}
  class={className}
  profilePicture={capacityGuest.guestOrgProfilePicture}
  relativeName={capacityGuest.guestOrgRelativeName}
  special={isOpenAnySchool}
>
  <slot name="profile-pic" />
</OrgProfilePictureAndName>

<script>
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'

  export let capacityGuest
  export let size = 'small'
  export let useOverflowEllipsis = false
  export let nameClass = null
  let className = 'flex-row flex-align-center nowrap'
  export { className as class }

  $: isOpenAnySchool = capacityGuest.guestOrgId == null || capacityGuest.guestOrgId === 0
  $: name = isOpenAnySchool ? 'Open (any school)' : capacityGuest.guestOrgName
</script>

import { writable } from 'svelte/store'
import api from 'services/api.js'
import dateService from 'services/date-service.js'

function createStore() {
  const { subscribe, set, update } = writable([])

  const markClicked = notification => {
    if (notification == null) return

    // mark it clicked if it isn't yet
    if (notification.dateClicked == null) {
      api.notifications.markClicked({ notificationId: notification.notificationId }, api.noMonitor)

      // indicate client-side
      update(notifications => {
        const n = notifications.find(x => x.notificationId === notification.notificationId)
        if (n == null) return notifications
        n.dateClicked = dateService.datetimestamp(new Date(), true)
        return notifications
      })
    }
  }

  return {
    subscribe,
    set,
    markClicked,
  }
}

export default createStore()

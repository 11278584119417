<div class="flex-row flex-align-center g05">
  {#if unlimitedMatches}
    <Icon lg name="infinity" class="text-info" />
    <span class="small">Unlimited rotations</span>
  {:else}
    <Icon name="rotation" autoColor />
    <span class="small">{pluralCount(unit, countable)} {afterUnit}</span>
  {/if}
</div>

<script>
  import { pluralCount } from 'services/string-utils.js'
  import Icon from 'components/Icon.svelte'

  export let unit
  export let countable
  export let afterUnit = ''
  export let unlimitedMatches = false
</script>

{#if hasConvenientImpersonations}
  <QuickDropdown
    btnClass="btn btn-purple{extraBtnClass ? ` ${extraBtnClass}` : ''}"
    icon="impersonate"
    label="Impersonate…"
    dropdownClass="p1"
    disabled={_disabled}
    loading={changing}
    canOpen={!disabled}
    bind:isOpen
  >
    <h4 class="text-left m0">Most frequently impersonated by you</h4>
    <ConvenientImpersonations {startImpersonating} users={mostFrequentlyImpersonatedUsers} />
    <h4 class="text-left mt1 mb0">Recently impersonated by you</h4>
    <ConvenientImpersonations {startImpersonating} users={recentlyImpersonatedUsers} />
  </QuickDropdown>
{/if}

<script>
  import api from 'services/api.js'
  import ConvenientImpersonations from 'components/ConvenientImpersonations.svelte'
  import currentUser from 'stores/user.js'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import { navigate } from 'svelte-routing'

  export let disabled = false
  export let changing = false
  export let extraBtnClass = null

  let isOpen = false

  $: _disabled = disabled || changing
  $: convenientImpersonations = $currentUser?.convenientImpersonations
  $: mostFrequentlyImpersonatedUsers = convenientImpersonations?.mostFrequentlyImpersonatedUsers ?? []
  $: recentlyImpersonatedUsers = convenientImpersonations?.recentlyImpersonatedUsers ?? []
  $: hasConvenientImpersonations = mostFrequentlyImpersonatedUsers.length || recentlyImpersonatedUsers.length

  async function startImpersonating(user) {
    if (user.deletedDateTime != null) return
    isOpen = false
    changing = true
    try {
      await api.impersonation.startImpersonating(user, api.noMonitor)
      await currentUser.load()
      navigate('/') // ideally we'd just stay on the same page, but many of our views don't handle reactively checking feature types and permissions. e.g. `$: canView = personaService.hasPermissionAnywhere(Permission.ViewCourses)` does not reactively update when $persona changes.
    } finally {
      changing = false
    }
  }
</script>

<a
  href={null}
  class="modal-close btn-reset"
  aria-label="Close"
  data-test="modal-close-btn"
  use:tip={{ content: 'Close', options: { placement: 'right' } }}
  use:onInteract={() => dispatch('close')}
>
  <Icon name="close" />
</a>

<script>
  import { createEventDispatcher } from 'svelte'
  import Icon from './Icon.svelte'
  import onInteract from 'decorators/on-interact.js'
  import tip from 'decorators/tip.js'

  const dispatch = createEventDispatcher()
</script>

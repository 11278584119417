<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} or let:option let:item hideSearchBox>
  <AgreementStatusLabel status={option.key} class={null} />
  <AgreementStatusLabel slot="selectedItem" status={item.key} />
</FilterTypeListFilter>

<script context="module">
  import { FilterType } from 'config/enums.js'
  import FilterTypeListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeListFilter.svelte'

  export const meta = {
    ...baseMeta,
    type: FilterType.AgreementStatuses,
    canHaveMultiple: true,
    label: 'Status',
    labelApplied: 'Status is',
    editLabel: 'Status is',
    icon: 'filter-status',
    iconClass: 'color-text-steel',
    filterProp: 'statuses',
    idKey: 'key',
  }
</script>

<script>
  import { AgreementStatus } from 'config/enums.js'
  import agreementStatusHelper from 'services/agreement-status-helper.js'
  import AgreementStatusLabel from 'components/AgreementStatusLabel.svelte'

  export let filter
  export let editing = false
  export let appliedMeta

  const filterOptions = Object.values(AgreementStatus)
    .sort((a, b) => a - b)
    .map(s => agreementStatusHelper.get(s))
</script>

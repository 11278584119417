import { FeatureType, Permission } from 'config/enums.js'
import metadata from './step-report.js'

export default {
  ...metadata,
  permission: Permission.ViewOffboardingStepsReport,
  featureTypes: [FeatureType.HealthInstitution, FeatureType.TeachingInstitution],
  componentName: 'OffboardingStepsReport',
  name: 'Offboarding steps report',
  icon: 'Offboarding',
  color: 'pink',
}

<!--controls intended for single-month calendar (not multi-month)-->
{#if start}
  {#if newView}
    <Btn class="btn-default btn-sm strongish btn-outline-lightgray" on:click={today}>Today</Btn>
    <Btn icon="chevron-left" class="btn-default btn-sm strongish btn-outline-lightgray" on:click={prev} dataTest="prev-month" />
    <DatePicker sm bind:value {format} autoSetOther={false} showAsDropdown />
    <Btn icon="chevron-right" class="btn-default btn-sm strongish btn-outline-lightgray" on:click={next} dataTest="next-month" />
  {:else}
    <div class="flex-row flex-align-center g05 mb05">
      <Btn class="btn-default btn-sm strongish" on:click={today}>Today</Btn>
      <Btn icon="chevron-left" class="btn-default btn-sm strongish" on:click={prev} dataTest="prev-month" />
      <DatePicker sm bind:value {format} autoSetOther={false} showAsDropdown />
      <Btn icon="chevron-right" class="btn-default btn-sm strongish" on:click={next} dataTest="next-month" />
    </div>
  {/if}
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import DatePicker from 'components/fields/DatePicker.svelte'
  import dayjs from 'dayjs'
  import { createEventDispatcher } from 'svelte'

  export let start
  export let end
  export let newView = false

  const format = 'MMMM YYYY'

  const dispatch = createEventDispatcher()
  let value = dayjs(start).format(format)

  $: selectMonth(value)

  function prev() {
    getMonth(start, -1)
  }

  function today() {
    getMonth()
  }

  function next() {
    getMonth(start, 1)
  }

  function getMonth(startDate, num = 0) {
    startDate ||= dayjs()

    start = dayjs(startDate).startOf('month').add(num, 'months')
    end = start.endOf('month')
    value = start.format(format)
    dispatch('changed')
  }

  function selectMonth(month) {
    start = dayjs(month).startOf('month')
    end = dayjs(month).endOf('month')
  }
</script>

import { StepType } from 'config/enums.js'

function makeStatusHelper(key, icon, label, iconClass, iconHref = null) {
  return { key, icon, label, iconProps: { class: iconClass }, iconHref }
}

const statuses = {
  [StepType.SchoolCompliance]: makeStatusHelper(StepType.SchoolCompliance, 'SchoolCompliance', 'School compliance', 'color-text-purple'),
  [StepType.Application]: makeStatusHelper(StepType.Application, 'Application', 'Application', 'color-text-purple'),
  [StepType.Onboarding]: makeStatusHelper(StepType.Onboarding, 'Onboarding', 'Onboarding', 'color-text-orange'),
  [StepType.Active]: makeStatusHelper(StepType.Active, 'Active', 'Active', 'color-text-green'),
  [StepType.Offboarding]: makeStatusHelper(StepType.Offboarding, 'Offboarding', 'Offboarding', 'color-text-pink'),
  [StepType.Evaluation]: makeStatusHelper(StepType.Evaluation, 'Evaluation', 'Evaluation', 'color-text-black'),
}

function get(status) {
  return statuses[status]
}

export default {
  get(status) {
    return get(status)
  },

  getOptions() {
    // correct ordering, despite their values being out of intuitive order
    return [StepType.SchoolCompliance, StepType.Application, StepType.Onboarding, StepType.Active, StepType.Evaluation, StepType.Offboarding].map(
      st => this.get(st)
    )
  },
}

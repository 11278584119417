{#each ssoProviders as p (p.ssoSchemeName)}
  <Btn class="btn btn-primary" data-test="sso-with-{p.ssoSchemeName}" on:click={() => startSso(p.ssoSchemeName)}>
    Sign in with {p.ssoDisplayName}
  </Btn>
{/each}

<script>
  import { startSso } from 'services/sso-service.js'
  import Btn from 'components/bootstrap/Btn.svelte'

  export let ssoProviders
</script>

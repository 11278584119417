{#if showingAnything}
  <div class="flex-column g05">
    {#if showProvidedBy}
      <div class="flex-row flex-align-center g1">
        <div class="flex-row flex-align-center g05">
          <Icon lg name="school" class="color-text-blue" />
          <h4 class="strongish leading-none m0">Provided by</h4>
        </div>

        <div class="flex-row flex-align-center g2">
          {#if show.org}
            <OrgProfilePictureAndName
              name={course.orgName}
              relativeName={course.orgRelativeName}
              profilePicture={course.orgProfilePicture}
              formattedAddress={course.orgAddress?.formattedAddress}
              size="small"
              useOverflowEllipsis
            />
          {/if}

          {#if showTeam}
            <TeamProfilePictureAndName small orgId={course.teamOrgId} name={course.teamName} />
          {/if}
        </div>
      </div>
    {/if}

    {#if showDescription}
      <LongDesc max={130} text={course.descriptionHtml} class={null} />
    {/if}

    {#if showDetails || showUsage}
      <div class="grid" class:showing-details={showDetails} class:showing-usage={showUsage}>
        {#if showDetails}
          <div class="flex-column g05">
            <h4 class="strongish leading-none m0">Details</h4>
            {#if show.status}
              <CourseStatusLabel {course} tipOptions={{ placement: 'left' }} iconProps={{ lg: true }} />
            {/if}

            {#if show.dates}
              <StartAndEndDate startDate={course.startDate} endDate={course.endDate} iconProps={{ lg: true }} />
            {/if}

            {#if showHours}
              <div>
                <Icon lg name="time" class="color-text-teal" />
                {course.hoursRequired}
                {singularOrPlural(course.hoursRequired, 'hour')} required
              </div>
            {/if}

            {#if showStudents}
              <div>
                <Icon lg name="graduation-cap" class="color-text-teal" />&nbsp;<CourseStudentCount {course} />{singularOrPlural(
                  course.studentCountGoal,
                  'student'
                )}
              </div>
            {/if}

            {#if showRotationTypes}
              <div>
                <TagCloud ctx={{ rotationTypes: course.rotationTypes }} />
              </div>
            {/if}
          </div>
        {/if}

        {#if showUsage}
          <div class="flex-column g05">
            <h4 class="strongish leading-none m0">Usage</h4>
            {#if show.matches}
              <div>
                <!-- TODO(mvp-nursing): When nursing-master merged into master, use RotationCount component -->
                <Icon lg name="rotation" autoColor />
                {pluralCount('rotation', course.matchCount)}
              </div>
            {/if}
            {#if show.progress}
              <div>
                <CourseProgress
                  bind:showDetails={show.progressDetails}
                  scheduled={course.totalScheduled}
                  completed={course.totalCompleted}
                  extraScheduled={course.totalExtraScheduled}
                  extraWorked={course.totalExtraWorked}
                  unscheduled={course.totalUnscheduled}
                />
              </div>
            {/if}
          </div>
        {/if}
      </div>
    {/if}
  </div>
{/if}

<script>
  import { pluralCount, singularOrPlural } from 'services/string-utils'
  import CourseProgress from 'components/CourseProgress.svelte'
  import CourseStatusLabel from 'components/CourseStatusLabel.svelte'
  import CourseStudentCount from 'components/CourseStudentCount.svelte'
  import Icon from 'components/Icon.svelte'
  import LongDesc from 'components/LongDesc.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import TagCloud from 'components/TagCloud.svelte'
  import TeamProfilePictureAndName from 'components/TeamProfilePictureAndName.svelte'
  import validator from 'services/validator.js'

  export let course

  $: show = $showDropdowns.coursePicker

  $: showTeam = show.team && course.teamOrgId
  $: showProvidedBy = show.org || showTeam

  $: showDescription = show.description && !validator.empty(course.descriptionHtml)

  $: showHours = show.hours && course.hoursRequired
  $: showStudents = show.students && course.studentCountGoal
  $: showRotationTypes = show.rotationTypes && course.rotationTypes?.length

  $: showDetails = show.status || show.dates || showHours || showStudents || showRotationTypes
  $: showUsage = show.matches || show.progress

  $: showingAnything = showProvidedBy || showDescription || showDetails || showUsage
</script>

<style lang="scss">
  .showing-details.showing-usage {
    grid-template-columns: 1fr 1fr;
  }
</style>

<FilterTypeStatus {filter} {editing} {appliedMeta} />

<script context="module">
  import FilterTypeStatus, { meta as baseMeta } from 'components/filter-types/FilterTypeStatus.svelte'
  import { FilterType, NameSuggestionIssue } from 'config/enums.js'
  import nameSuggestionIssueHelper from 'services/name-suggestion-issue-helper.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.HasIssues,
    label: 'Has issue',
    icon: 'alert-triangle',
    iconClass: 'text-warning',
    canHaveMultiple: true,
    enumeration: NameSuggestionIssue,
    helper: nameSuggestionIssueHelper,
    filterProp: 'issues',
  }
</script>

<script>
  export let filter
  export let editing = false
  export let appliedMeta
</script>

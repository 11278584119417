{#if files?.length}
  <div class="mb1">
    {#each files as f}
      <div class="flex-row">
        <div class="flex-grow">
          <DownloadTextLink file={f} bind:this={downloadTextLinkComponent} hideDoneBtn>
            <svelte:fragment slot="footer">
              <slot name="file-viewer-footer" />
            </svelte:fragment>
          </DownloadTextLink>
        </div>

        {#if canDelete}
          <Btn
            confirm={{
              title: 'Remove file',
              message: 'Are you sure you want to remove this file?',
              confirmLabel: 'Remove',
              confirmClass: 'btn-danger',
            }}
            clearBtnStyling
            class="pull-right text-danger"
            on:click={() => onDeleteFile(f)}
            title="Remove file"
            dataTest="remove-file"
            icon="delete"
            loading={deleting[f.url]}
          />
        {/if}
      </div>
    {/each}
  </div>
{/if}

<script>
  import DownloadTextLink from 'components/DownloadTextLink.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'

  export let files
  export let onDeleteFile
  export let canDelete
  export let deleting
  export let downloadTextLinkComponent
</script>

<FilterTypeStatus {filter} {editing} {appliedMeta} />

<script context="module">
  import FilterTypeStatus, { meta as baseMeta } from 'components/filter-types/FilterTypeStatus.svelte'
  import { FilterType, StepSubmissionStatus } from 'config/enums.js'
  import stepSubmissionStatusHelper from 'services/step-submission-status-helper.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.StepSubmissionStatus,
    label: 'Status',
    icon: 'filter-status',
    iconClass: 'color-text-steel',
    canHaveMultiple: true,
    enumeration: StepSubmissionStatus,
    helper: stepSubmissionStatusHelper,
  }
</script>

<script>
  export let filter
  export let editing = false
  export let appliedMeta
</script>

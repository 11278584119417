{#if !editing}Has proposed changes{/if}

<script context="module">
  import { FilterType } from 'config/enums.js'
  const label = 'Has proposed changes'
  export const meta = {
    type: FilterType.MatchHasProposedChanges,
    label,
    icon: 'has-changes',
    iconClass: 'text-sky',
  }
</script>

<script>
  export let editing = false
</script>

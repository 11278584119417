{#each capacity.studentYears as studentYear}
  <div class="flex-row">
    <QuickFilterTag
      icon="calendars"
      class="color-text-teal"
      value={studentYear === 10 ? 'Postgraduate students' : `${toOrdinalNumber(studentYear)} year`}
      type="Allowed student year"
      {filtersComponent}
      filterType={FilterType.StudentYears}
      configKey="studentYears"
      configValue={studentYear}
      simulationFilterOption={{}}
      dataTest="student-year-quick-filter-tag"
    />
  </div>
{:else}
  <div class="flex-row">
    <QuickFilterTag
      icon="calendars"
      class="color-text-teal"
      value="Any year student"
      type="Allowed student year"
      {filtersComponent}
      filterType={FilterType.StudentYears}
      configKey="studentYears"
      configValue={null}
      simulationFilterOption={{}}
      dataTest="student-year-quick-filter-tag"
    />
  </div>
{/each}

<script>
  import { FilterType } from 'config/enums.js'
  import QuickFilterTag from 'components/QuickFilterTag.svelte'
  import toOrdinalNumber from 'services/to-ordinal-number.js'

  export let capacity
  export let filtersComponent = null
</script>

<div class="optional-datepicker">
  <div class="radio radio-primary">
    <input {disabled} type="radio" name="{name}-no-value" id="{name}-no-value" bind:group={hasValue} value={false} on:click={valueSetToNull} />
    <label for="{name}-no-value" class={noValueLabelClass}><slot name="no-value-label">{noValueLabel}</slot></label>
  </div>
  <div class="radio radio-primary">
    <input {disabled} type="radio" name="{name}-has-value" id="{name}-has-value" bind:group={hasValue} value={true} on:click={valueSet} />
    <label for="{name}-has-value">
      <DatePicker
        {range}
        {sm}
        {min}
        {max}
        bind:value={internalStartValue}
        bind:endValue={internalEndValue}
        on:click={onFocusHasDateField}
        name="{name}-datepicker"
        {placeholder}
        {endPlaceholder}
        {disabled}
        {iconClass}
      >
        <svelte:fragment slot="rangeEndLabel">
          <span>to</span>
          <Icon name="calendar" class={iconEndClass} />
        </svelte:fragment>
      </DatePicker>
    </label>
  </div>
</div>

<script>
  import DatePicker from 'components/fields/DatePicker.svelte'
  import Icon from 'components/Icon.svelte'

  export let defaultStartValue = null
  export let defaultEndValue = null
  export let startValue = null
  export let endValue = null
  export let min = null
  export let max = null
  export let name = 'optional-date-picker'
  export let noValueLabel = 'No date set'
  export let disabled = false
  export let range = false
  export let sm = false
  export let placeholder = 'Start date'
  export let endPlaceholder = 'End date'
  export let iconClass = 'color-text-dark-green'
  export let iconEndClass = 'color-text-light-red'
  export let noValueLabelClass = null

  let internalStartValue = startValue
  let internalEndValue = endValue
  let hasValue = false

  if (startValue === null && endValue === null) {
    startValue = defaultStartValue
    endValue = defaultEndValue
  }

  function valueSetToNull() {
    internalStartValue = null
    internalEndValue = null
    hasValue = false
  }

  function valueSet() {
    hasValue = true
  }

  function onFocusHasDateField() {
    hasValue = true
  }

  $: hasValue = internalStartValue != null || internalEndValue != null

  $: {
    if (hasValue) {
      startValue = internalStartValue
      endValue = internalEndValue
    } else {
      startValue = defaultStartValue
      endValue = defaultEndValue
    }
  }
</script>

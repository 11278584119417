<div class="btn-group{color ? ` btn-group-${color}` : ''}{className ? ` ${className}` : ''}" data-test={dataTest}>
  <slot />
</div>

<script>
  let className = ''
  export { className as class }
  export let color = null

  export let dataTest = null
</script>

<style>
  .btn-group {
    display: inline-flex;
  }
</style>

{#if shouldCollect}
  <FormGroup valid={!inPast || stepSubmission.verificationExpirationDateSuggestion == null}>
    {#if !hideLabelAndHelpBlock}
      <label for="verification-expiration-suggestion">{labelText}</label>
      <HelpBlock>{helpText}</HelpBlock>
    {/if}
    <DatePicker name="verification-expiration-suggestion" min={today} bind:value={stepSubmission.verificationExpirationDateSuggestion} clearable />
  </FormGroup>
{/if}

<script context="module">
  export let labelText = 'When does this submission expire? (optional)'
  export let helpText = 'This can help speed up verification process for your coordinators.'
  export function shouldCollectVerificationExpirationSuggestion(stepSubmission) {
    return !stepSubmission.canVerify && stepSubmission.step.requireVerificationExpiration
  }
</script>

<script>
  import FormGroup from './bootstrap/FormGroup.svelte'
  import DatePicker from './fields/DatePicker.svelte'
  import HelpBlock from './fields/HelpBlock.svelte'

  export let stepSubmission
  export let hideLabelAndHelpBlock = false

  $: today = dayjs().startOf('day')
  $: verificationExpirationDateSuggestionParsed = stepSubmission.verificationExpirationDateSuggestion
    ? dayjs(stepSubmission.verificationExpirationDateSuggestion)
    : null
  $: inPast = verificationExpirationDateSuggestionParsed ? verificationExpirationDateSuggestionParsed.isBefore(today) : false
  $: shouldCollect = shouldCollectVerificationExpirationSuggestion(stepSubmission)
</script>

{#if definition != null}
  <svg
    use:tip={tooltip}
    data-test={dataTest}
    data-icon-name={name}
    class="icon{className ? ` ${className}` : ''}"
    class:auto-color={autoColor}
    class:monochrome
    class:spin
    class:cell
    class:rotate90
    class:fw
    class:xxs
    class:xs
    class:sm
    class:lg
    class:x2
    class:x3
    {focusable}
    {style}
    aria-hidden="true"
    role="img"
    viewBox="0 0 {definition[0]} {definition[1]}"
    xmlns="http://www.w3.org/2000/svg"
  >
    {#each normalizedPaths as d, i}
      <path class="path-{i}" {d} />
    {/each}
  </svg>
{/if}

<script>
  import icons from 'config/icons.js'
  import tip from 'decorators/tip.js'

  // name determines which SVG to pull from /icons directory
  // (which is pulled from fontawesome-pro npm package at build time)
  export let name
  export let title = null
  export let tipOptions = {}
  export let dataTest = null
  export let spin = false
  let className = ''
  export { className as class }
  export let style = null
  export let autoColor = false
  export let fw = false

  // size attributes, separate for simpler shorthand
  export let xxs = false
  export let xs = false
  export let sm = false
  export let lg = false
  export let x2 = false
  export let x3 = false
  export let cell = false

  export let rotate90 = false
  export let focusable = false

  $: icon = icons[name]
  $: if (icon == null) {
    // eslint-disable-next-line no-console
    console.warn('icon not found: ' + name)
  }

  $: definition = (icon && icon.icon) || [0, 0, '', [], '']
  $: paths = Array.isArray(definition[4]) ? definition[4] : [definition[4]]
  // For whatever reason, Font Awesome has the primary path second.
  // Since I want to support icons with 3+ paths, we'll just swap them.
  // That way, each path is in descending order of importance.
  $: normalizedPaths = paths.length === 2 ? [paths[1], paths[0]] : paths

  $: monochrome = !autoColor && name === 'rotation'
  $: tooltip = title == null ? null : typeof title === 'string' || title instanceof Node ? { content: title, options: tipOptions } : title
</script>

<style lang="scss">
  @import '../../css/helpers';

  $icon-width: 1em;
  $icon-vertical-align: -0.125em;

  svg.icon {
    flex-shrink: 0;
    width: $icon-width;
    height: $icon-width;
    vertical-align: $icon-vertical-align;
    outline: none;
    transition: 0.2s transform ease-in-out;

    &.rotate90 {
      transform: rotate(90deg);
    }

    &.fw {
      width: 1.5em;
      text-align: center;
    }

    &.lg {
      font-size: 1.33333em;
      line-height: 0.75em;
      vertical-align: -0.225em;
    }

    &.xxs {
      font-size: 0.625em;
    }

    &.xs {
      font-size: 0.75em;
    }

    &.sm {
      font-size: 0.875em;
    }

    &.x2 {
      font-size: $icon-width * 2;
    }

    &.x3 {
      font-size: $icon-width * 3;
    }

    &.spin {
      animation: 2s linear infinite spin;
    }
    &.cell {
      font-size: $icon-width * 2.2;
    }

    // Could move all of these into a style attribute so we don't have to eventually specify .path-4, .path-5, etc.
    // But I don't feel like looking into if there would be specificity issues at the moment. Good nuff.
    .path-0 {
      opacity: var(--path-0-opacity, 1);
      fill: var(--path-0-color, currentColor);
    }

    .path-1 {
      opacity: var(--path-1-opacity, 0.4);
      fill: var(--path-1-color, currentColor);
    }

    .path-2 {
      opacity: var(--path-2-opacity, 1);
      fill: var(--path-2-color, currentColor);
    }

    &.swap-opacity {
      .path-1 {
        opacity: var(--path-0-opacity, 1);
      }
      .path-0 {
        opacity: var(--path-1-opacity, 0.4);
      }
    }

    &.monochrome {
      // TODO: Don't currently handle 4+ path icons with this implementation.
      --path-2-opacity: 0.7;
    }

    &.auto-color {
      &[data-icon-name='grip-vertical'] {
        --path-0-color: #99999b;
      }
      &[data-icon-name='agreement'] {
        --path-0-color: #0079bf;
        --path-1-color: #e89113;
        --path-1-opacity: 1;
      }
      &[data-icon-name='file-spreadsheet'] {
        --path-0-color: #107c41;
        --path-1-color: #21a366;
        --path-1-opacity: 1;
      }
      &[data-icon-name='roles-list'] {
        --path-1-color: #000;
        --path-1-opacity: 0.75;
      }
      &[data-icon-name='id-badge-duo'] {
        --path-0-color: #fff;
        --path-0-opacity: 0.75;
        --path-1-opacity: 1;
      }
      &[data-icon-name='filter-status'] {
        color: var(--user-steel);
        --path-0-color: #000;
        --path-0-opacity: 0.6;
      }
      &[data-icon-name='calendar-up'] {
        color: var(--user-steel);
        --path-0-color: #000;
        --path-0-opacity: 0.6;
      }
      &[data-icon-name='file-circle-info'] {
        color: var(--user-steel);
        --path-0-color: #000;
        --path-0-opacity: 0.6;
      }
      &[data-icon-name='lock'] {
        --path-0-color: var(--theme-warning);
        --path-1-color: var(--theme-gray);
        --path-1-opacity: 0.9;
      }
      &[data-icon-name='road-barrier'] {
        --path-0-color: var(--theme-gray);
        --path-1-color: var(--theme-warning);
        --path-1-opacity: 0.9;
      }
      &[data-icon-name='bomb'] {
        --path-0-color: #222222;
        --path-1-color: #e89113;
        --path-1-opacity: 1;
      }
      &[data-icon-name='rotation'] {
        --path-0-color: #0079bf;
        --path-1-color: #e89113;
        --path-2-color: #009688;
        --path-0-opacity: 1;
        --path-1-opacity: 1;
        --path-2-opacity: 1;
      }
      &[data-icon-name='slots-check'] {
        --path-2-color: var(--theme-success);
      }
      &[data-icon-name='slots-question'] {
        --path-2-color: var(--theme-info);
      }
      &[data-icon-name='slots-exclamation'] {
        --path-2-color: var(--theme-danger);
      }
      &[data-icon-name='slots-release'] {
        --path-0-color: var(--user-green);
        --path-1-color: var(--user-steel);
      }
      &[data-icon-name='slots-stop'] {
        --path-0-color: var(--user-dark-red);
        --path-1-color: var(--user-steel);
      }
      &[data-icon-name='search-fail'] {
        --path-0-color: #333;
        --path-1-color: var(--theme-danger);
        --path-1-opacity: 1;
      }
      &[data-icon-name='search-success'] {
        --path-0-color: #333;
        --path-1-color: var(--theme-success);
        --path-1-opacity: 1;
      }
      &[data-icon-name='placeholder-student'] {
        --path-0-color: #99999b;
      }
    }
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .icon-container {
    display: inline-block;
  }
</style>

<div class="inline" style="">
  <kbd use:tip={tooltipContent ? { content: tooltipContent, options: { interactive: false } } : null}>{keyName}</kbd>
</div>

<script context="module">
  import Key from 'config/key.js'

  const nameByKey = _.invert(Key)
  nameByKey[Key.Enter] = 'Enter ⏎'
  nameByKey[Key.HyphenNumPad] = '-'
  nameByKey[Key.PeriodNumPad] = '.'
  nameByKey[Key.Comma] = ','

  const tooltipByKey = {
    [Key.Comma]: 'Comma',
  }
</script>

<script>
  import tip from 'decorators/tip.js'

  export let key // Should come from key.js

  $: keyName = nameByKey[key]
  $: tooltipContent = tooltipByKey[key]
</script>

<style lang="scss">
  kbd {
    background-color: #eee;
    border-radius: 3px;
    border: 1px solid #b4b4b4;
    box-shadow: 0 1px 1px rgb(0 0 0 / 20%), 0 2px 0 0 rgb(255 255 255 / 70%) inset;
    color: #333;
    display: inline-block;
    line-height: 1;
    padding: 2px 4px;
    white-space: nowrap;
  }
</style>

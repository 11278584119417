import { CriteriaFilterType, DashboardViewCategory, FilterType } from 'config/enums.js'

const excludedFilterTypes = [FilterType.MatchStatusCategory, FilterType.StepSubmissionTargetUser]

export default {
  category: DashboardViewCategory.Reports,
  criteria: {
    filters: [],
    matchFilters: [],
  },
  excludedFilterTypes,
  filtersConfig: [
    {
      prefix: '',
      name: 'filters',
      type: CriteriaFilterType.StepSubmissionFilter,
    },
    {
      prefix: 'match',
      name: 'matchFilters',
      type: CriteriaFilterType.MatchFilter,
    },
  ],
  criteriaConfig: {
    // filters: {
    //   prefix: '',
    //   name: 'filters',
    //   type: CriteriaFilterType.StepSubmissionFilter,
    // },
    // matchFilters: {
    //   prefix: 'match',
    //   name: 'matchFilters',
    //   type: CriteriaFilterType.MatchFilter,
    // },
    includeSubmissionData: { name: 'isd', method: 'readBool' },
    includeHealthOrgHierarchy: { name: 'ihoh', method: 'readBool' },
  },

  waitFor: { method: 'POST', route: /\/step-submissions\/list/ },
  testClick: 'run-report-btn',
  testFor: ['step-report'],
}

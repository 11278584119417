{#if stepType === StepType.Onboarding && match.status < MatchStatus.Onboarding}
  <Alert class="info mb1" dataTest="onboarding-work-ahead-message">
    Your rotation is
    {#if match.status === MatchStatus.Unsubmitted}unsubmitted.
    {:else if match.status === MatchStatus.Waitlisted}waitlisted.
    {:else}pending clinical site acceptance.{/if} If it is accepted, you will need to complete onboarding. If you’d like, you can work ahead to possibly
    save time later.
  </Alert>
{/if}

<!-- if this section should be expanded, don't use their remembered `open` value--expand it regardless -->
<Collapsible {label} name="match-modal-steps-{stepType}" open={_expanded} remember={!_expanded}>
  <div slot="label">
    <h3 class="m0">
      <Icon fw name={stepTypeIcon} class="text-{matchStatusHelper.getByStepType(stepType).color}" />
      {label}
      <Progress md>
        {#each statusCounts as statusCount, i}
          <ProgressBar
            color={getStepStatusColor(statusCount.stepSubmissionStatus)}
            title={statusCount.count + ' ' + formatEnumValue(StepSubmissionStatus, statusCount.stepSubmissionStatus)}
            percent={statusCount.percent}
            style={i > 0 ? 'border-left: 2px solid white;' : ''}
          >
            {statusCount.count}
            {formatEnumValue(StepSubmissionStatus, statusCount.stepSubmissionStatus)}
          </ProgressBar>
        {/each}
      </Progress>
    </h3>
  </div>

  <div data-test="match-steps-{stepType}">
    <div>
      {#each groupedSteps as group (group.key)}
        <div data-test={`${stepType}-${kebabCase(group.label)}`}>
          <div class="bg-light">
            <h3 class="my0 mt2">
              {#if group.user}
                {@const userMatchStatus = matchStatusHelper.get(group.user.status)}
                <Icon fw name={userMatchStatus.icon} class="status-icon text-{userMatchStatus.color}" title={userMatchStatus.label} />
              {/if}
              <SafeHtml value={group.label} />
            </h3>
            {#if group.step}
              <MatchStepsStepInfo step={group.step} />
            {/if}
          </div>

          <!--
          loop all possible step submissions
          if only one needs to be completed, group by target instead of user
        -->
          {#each group.submissions as stepSubmission (stepSubmission.submissionKeyGuaranteedUnique)}
            <div class="submission-row">
              <StepSubmissionRow {stepSubmission} matchModalMatchId={match.matchId} {startDateOverride} {endDateOverride} />
            </div>
          {/each}
        </div>
      {/each}
    </div>
  </div>
</Collapsible>

<script context="module">
  import { StepType } from 'config/enums.js'
  const iconByStepType = _.invert(StepType)
</script>

<script>
  import { formatEnumValue } from 'services/formatters.js'
  import { getStepStatusColor } from 'services/step-submission-service.js'
  import { MatchStatus, MatchRole, StepSubmissionStatus } from 'config/enums.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import Collapsible from './Collapsible.svelte'
  import Icon from 'components/Icon.svelte'
  import matchRoleHelpers from 'services/match-role-helpers.js'
  import matchStatusHelper from 'services/match-status-helper.js'
  import MatchStepsStepInfo from 'components/MatchSteps.StepInfo.svelte'
  import Progress from 'components/Progress.svelte'
  import ProgressBar from 'components/ProgressBar.svelte'
  import StepSubmissionRow from './StepSubmissionRow.svelte'
  import kebabCase from 'lodash/kebabCase'
  import SafeHtml from './SafeHtml.svelte'

  export let label
  export let stepType
  export let steps
  export let match
  export let startDateOverride = false
  export let endDateOverride = false
  export let stepCriteria
  export let expanded

  const _expanded = expanded // never auto-close

  let groupedSteps = []
  let statusCounts = []
  let stepActions = {}
  let stepActionKeysOrdered = []

  $: matchId = match.matchId
  $: if (matchId) {
    // if nav to different match, drop actions in flight--not a big deal since we take action on the _current_ match and are just loading others to potentially prompt the user about what they'd like to do about them.
    stepActionKeysOrdered = []
    stepActions = {}
  }
  $: groupBy = stepCriteria.groupBy
  $: steps, groupBy, setGroupedSteps()
  $: groupedSteps, setStatusCounts()
  $: stepTypeIcon = iconByStepType[stepType]

  function setGroupedSteps() {
    const groups = []
    for (const { submissions, ...step } of steps) {
      for (const submission of submissions) {
        submission.step = step
        let key = ''
        let label = ''
        const statusName = null
        let userId = null
        let groupSortValue = null
        switch (groupBy) {
          case 'step':
            key = step.stepId
            label = step.name
            groupSortValue = step.name.toLowerCase()
            break
          case 'user':
            if (submission.user != null) {
              // steps assigned directly to a user
              key = submission.user.userId
              label = `Steps for ${submission.user.name}`
              userId = submission.user.userId
              const user = getMatchUser(userId)
              if (user == null) continue
              groupSortValue = user.matchRole + submission.user.name.toLowerCase()
            } else if (submission.targetUser == null) {
              // steps not assigned to a specific user that do have a targetuser only affect the target user's status, so they should show up in that user's section
              key = step.completion.roles.sort((a, b) => a - b).join('-')
              label = `Steps for any ${matchRoleHelpers.toList(step.completion.roles, 'or', false)}`
              groupSortValue = '9' // make it last
            } else {
              // steps not assigned to a specific user or about a specific should show up in a different section
              // about them but not assigned to anyone in particular - show it under their name
              key = submission.targetUser.userId
              label = `Steps for ${submission.targetUser.name}`
              userId = submission.targetUser.userId
              const user = getMatchUser(userId)
              if (user == null) continue
              groupSortValue = user.matchRole + submission.targetUser.name.toLowerCase()
            }
            break
          case 'completion status':
            key = submission.status
            label = formatEnumValue(StepSubmissionStatus, submission.status)
            groupSortValue = submission.status
            break
        }

        // sort submissions within groups by whether they're assigned to a specific user or not, and whether coordinator can complete or not if
        // 1. assigned to JUST 1 user
        // 2. assigned to JUST 1 user (but can be completed on their behalf by some coordinators)
        // 3. assigned to anyone
        submission.sortValue =
          submission.user == null ? 3 : step.otherOrgCoordinatorsCanAlsoComplete || step.sameOrgCoordinatorsCanAlsoComplete ? 2 : 1

        // add to the group or make a new group
        let group = groups.find(g => g.key == key)
        if (group == null) {
          group = {
            key,
            label,
            statusName,
            groupSortValue,
            submissions: [submission],
          }
          if (groupBy === 'step') group.step = step
          else if (userId != null) group.user = getMatchUser(userId)
          groups.push(group)
        } else {
          group.submissions.push(submission)
        }
      }
    }
    // sort the submissions inside each group
    for (const g of groups) {
      // sort first by step name, then by sort value
      g.submissions = g.submissions.sort((a, b) => {
        if (a.step.name !== b.step.name) return a.step.name.toLowerCase() < b.step.name.toLowerCase() ? -1 : 1
        return a.sortValue - b.sortValue
      })
    }

    // sort the groups
    groupedSteps = groups.sort((a, b) => (a.groupSortValue < b.groupSortValue ? -1 : 1))
  }

  function setStatusCounts() {
    if (groupedSteps == null) {
      statusCounts = []
      return
    }
    const allSubmissions = groupedSteps.flatMap(g => g.submissions)
    statusCounts = Object.values(StepSubmissionStatus)
      .map(stepSubmissionStatus => {
        const count = allSubmissions.filter(s => s.status === stepSubmissionStatus).length
        return {
          stepSubmissionStatus,
          count,
          percent: ((count / allSubmissions.length) * 100).toFixed(4), // 4 decimals.. if it goes over 100% it'll wrap to next line
        }
      })
      .filter(c => c.count > 0)
  }

  function getMatchUser(userId) {
    if (match == null) return null
    const roles = new Set([MatchRole.Student, MatchRole.SchoolFaculty, MatchRole.Preceptor])
    return match.matchUsers.find(mu => mu.userId == userId && roles.has(mu.matchRole))
  }
</script>

<style>
  .submission-row {
    border-top: 1px solid #eee;
    padding-top: 10px;
  }
</style>

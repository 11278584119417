{#each times as time}
  {#if time.startTime != null || time.endTime != null}
    <StartAndEndTime hideIcons startTime={time.startTime} endTime={time.endTime} />
  {:else}
    <div>No set times</div>
  {/if}
{/each}

<script>
  import { buildShiftRepeatSchedules, repeatScheduleOccursOnDate } from 'services/calendar-service.js'
  import dateService from 'services/date-service.js'
  import StartAndEndTime from 'components/StartAndEndTime.svelte'

  export let capacity
  export let shift
  export let dayjsDay = null

  $: times = getTimes(capacity, shift)

  // Returns times that may land on an exception day, but the calendar will not show them
  function getTimes(capacity, shift) {
    const repeatSchedules = buildShiftRepeatSchedules(capacity, shift)
    const matching = dayjsDay == null ? repeatSchedules : repeatSchedules.filter(rs => repeatScheduleOccursOnDate(rs, dayjsDay))
    const sorted = dateService.sortByStartAndEndTime(matching)
    const distinct = dateService.distinctStartAndEndTime(sorted)
    return distinct
  }
</script>

import modelState from 'stores/model-state.js'
import toaster from './toaster.js'

export function handleRequestError(responseStatus, content) {
  // 401 not authenticated - redirect to login
  if (responseStatus === 401) {
    // Simply reload the page, so user is taken to login page with the current url. The public app tracks that url as the place to go upon login.
    window.location.reload()
    return
  }

  // 403 not authorized
  if (responseStatus === 403) {
    modelState.set(content)
    return
  }

  // 500 server error
  if (responseStatus === 500) {
    toaster.toast({
      message: 'Uh oh. Something went wrong. Please refresh and try again. If you keep seeing this, please contact us.',
      type: 'danger',
      icon: 'alert-triangle',
    })
    return
  }

  // 404 not found
  if (responseStatus === 404) {
    toaster.toast({
      message: 'Resource not found. If you think this is an error, please contact us.',
      type: 'warning',
      icon: 'alert-triangle',
    })
    return
  }

  // 429 too many requests
  // If this ever pops up in the app (shouldn't), the code is likely doing something bad and should be written better.
  if (responseStatus === 429) {
    toaster.toast({
      message: 'Too many requests. Please try again later. If you keep seeing this, please contact us.',
      type: 'danger',
      icon: 'alert-triangle',
    })
    return
  }

  modelState.set(content)
}

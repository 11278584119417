{#if capacity}
  <div>
    {#if shift.maxMatchCountPerDay == null}
      <Icon lg name="infinity" class="text-info" />
      Unlimited
    {:else}
      {readableNumber(shift.maxMatchCountPerDay)}
    {/if}
    {pluralCount(capacity.allowGroups ? 'group' : 'student/learner', shift.maxMatchCountPerDay, 'omitNumber')}
    per day
  </div>

  {#if capacity.allowGroups}
    <div>
      {#if shift.maxStudentsInGroup}
        {readableNumber(shift.maxStudentsInGroup)}
      {:else}
        <Icon lg name="infinity" class="text-info" />
        Unlimited
      {/if}
      {pluralCount('student/learner', shift.maxStudentsInGroup, 'omitNumber')} per group
    </div>
  {/if}

  {#if shift.matchDayLimitPerWeek != null && shift.matchDayLimitPerWeek !== 7}
    <div>
      Available to rotations {pluralCount('day', shift.matchDayLimitPerWeek)}/week
    </div>
  {/if}
{/if}

<script>
  import { pluralCount, readableNumber } from 'services/string-utils.js'
  import Icon from 'components/Icon.svelte'

  export let capacity
  export let shift
</script>

{#if match.proposedChanges.length > 0}
  <FormGroup>
    <h3>
      Proposed changes
      <Icon name="has-changes" class="text-sky" fw />
    </h3>
    <Grid let:row rows={match.proposedChanges}>
      <GridCol {row} name="proposerName" header="Name" />
      <GridCol {row} name="dateModified" header="Requested">{dateService.fromnow(row.dateModified)}</GridCol>
      <GridCol {row} name="due" header="Due">
        {#if row.dueDate != null}
          {#if !validator.inFuture(row.dueDate)}
            <Icon name="alert-triangle" class="text-danger mr1" />
          {/if}
          {dateService.fromnow(row.dueDate)}
        {/if}
      </GridCol>
      <GridCol {row} header="Comment">
        {#each getComments(row.comment) as c}
          {c}
          <br />
        {/each}
      </GridCol>
      <GridCol {row} header="">
        <Btn class="btn-sm" on:click={() => (change = row)} icon="eye">View details</Btn>
      </GridCol>
    </Grid>
  </FormGroup>

  {#if change}
    <MatchChangeModal {match} {onMatchChanged} {change} onClose={() => (change = null)} />
  {/if}
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Grid from 'components/Grid.svelte'
  import GridCol from 'components/GridCol.svelte'
  import Icon from 'components/Icon.svelte'
  import MatchChangeModal from 'components/MatchChangeModal.svelte'
  import dateService from 'services/date-service.js'
  import validator from 'services/validator.js'

  export let match
  export let onMatchChanged

  let change = null
  function getComments(comment) {
    return comment.split('\n')
  }
</script>

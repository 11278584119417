import { FeatureType, Permission } from 'config/enums.js'
import metadata from './step-report.js'

export default {
  ...metadata,
  permission: Permission.ViewOnboardingStepsReport,
  featureTypes: [FeatureType.HealthInstitution, FeatureType.TeachingInstitution],
  componentName: 'OnboardingStepsReport',
  name: 'Onboarding steps report',
  icon: 'Onboarding',
  color: 'orange',
}

<FilterTypeDate bind:filter bind:editing {label} includeNullComparisons />

<script context="module">
  import { meta as dateMeta } from 'components/filter-types/FilterTypeDate.svelte'
  import { FilterType } from 'config/enums.js'
  const label = 'Graduation date'
  export const meta = {
    ...dateMeta,
    type: FilterType.StudentGraduationDate,
    label,
    icon: 'graduation-cap',
    iconClass: 'color-text-teal',
  }
</script>

<script>
  import FilterTypeDate from 'components/filter-types/FilterTypeDate.svelte'
  export let filter
  export let editing = false
</script>

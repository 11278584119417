{#if show}
  <Btn icon="email" href="mailto:{email}" {dataTest} class={className}>{email}</Btn>
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import validator from 'services/validator.js'

  export let email
  export let dataTest = 'email-address-btn'
  export { className as class }
  let className = null

  $: show = !validator.empty(email)
</script>

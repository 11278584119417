<a
  class="tree-item-toggle"
  class:invisible={hide}
  class:expanded
  on:click={() => (expanded = !expanded)}
  href={null}
  use:tip={expanded ? 'Collapse' : 'Expand'}
  data-test={dataTest}
>
  <Icon name="caret-{expanded ? 'down' : 'right'}" fw lg />
</a>

<script>
  import tip from 'decorators/tip.js'
  import Icon from 'components/Icon.svelte'

  export let hide = false
  export let expanded = false
  export let dataTest = null
</script>

import { Token, tokens } from 'services/filters/tokens.js'
import validator from 'services/validator.js'

const emptyArrayTokens = `${Token.ArrayStart}${Token.FilterSeparatorOrEmptyArray}${Token.ArrayEnd}`

function escapeArg(arg) {
  if (typeof arg !== 'string') return arg
  let escaped = ''
  const count = arg.length
  for (let i = 0; i < arg.length; i++) {
    const char = arg[i]
    const nextChar = arg[i + 1] ?? ''
    if (tokens.includes(char)) {
      const mustEscape =
        char === Token.ArgSeparator ||
        char === Token.FilterSeparatorOrEmptyArray ||
        char === Token.ArrayEnd ||
        (i === 0 && char === Token.ArrayStart) ||
        (count === 1 && (char === Token.Null || char === Token.Undefined || char === Token.True || char === Token.False)) ||
        (char === Token.Escape && (nextChar === '' || tokens.includes(nextChar)))
      escaped += mustEscape ? Token.Escape + char : char
    } else {
      escaped += encodeURIComponent(char)
    }
  }
  return escaped
}

function encodeArg(arg) {
  if (arg === null) return Token.Null
  if (arg === undefined) return Token.Undefined
  if (arg === true) return Token.True
  if (arg === false) return Token.False
  if (Array.isArray(arg)) return arg.length ? `${Token.ArrayStart}${arg.map(encodeArg).join(',')}${Token.ArrayEnd}` : emptyArrayTokens
  if (validator.date(arg, 'YYYY-MM')) return escapeArg(dayjs(arg).format('YYYY-MM'))
  if (validator.date(arg) || validator.date(arg, 'YYYY-MM-DD')) return escapeArg(dayjs(arg).format('YYYY-MM-DD'))
  return escapeArg(arg)
}

export class FilterEncoder {
  #filterTypes
  #ignoredFilterTypes

  constructor(filterTypes, ignoredFilterTypes = []) {
    this.#filterTypes = filterTypes
    this.#ignoredFilterTypes = ignoredFilterTypes
  }

  encode(filters) {
    if (!filters) return ''
    const encoder = new InternalEncoder(this.#filterTypes, this.#ignoredFilterTypes, filters)
    return encoder.encode()
  }

  supportsFilterType(type) {
    return this.#filterTypes[type] != null
  }
}

export class CriteriaEncoder {
  #buffer = ''

  encode(criteria, config, defaults) {
    if (!criteria || !config) return ''

    for (const propName of Object.keys(config)) {
      const { name } = config[propName]
      const defaultValue = defaults[propName]
      const criteriaValue = criteria[propName]
      if (criteriaValue != null && criteriaValue !== defaultValue) {
        this.#buffer += name
        this.#buffer += Token.ArgSeparator
        this.#buffer += encodeArg(criteriaValue)
        this.#buffer += Token.FilterSeparatorOrEmptyArray
      }
    }
    return this.#buffer
  }
}

class InternalEncoder {
  #buffer = ''
  #state = 'empty'
  #writer = new ExposedWriter(this)
  #filterTypes
  #ignoredFilterTypes
  #filters

  constructor(filterTypes, ignoredFilterTypes, filters) {
    this.#filterTypes = filterTypes
    this.#ignoredFilterTypes = ignoredFilterTypes
    this.#filters = filters
  }

  encode() {
    for (const filter of this.#filters) {
      const meta = this.#filterTypes[filter.type]
      if (!meta) {
        if (this.#ignoredFilterTypes.includes(filter.type)) {
          continue
        } else {
          throw new Error(`Invalid FilterType (${filter.type})`)
        }
      }
      this.writeFilter(meta, filter)
    }
    return this.#buffer
  }

  writeArg(arg) {
    if (this.#state !== 'filter' && this.#state !== 'arg') throw new Error(`Invalid FilterEncoder state: ${this.#state}`)
    this.#buffer += Token.ArgSeparator
    this.#buffer += encodeArg(arg)
    this.#state = 'arg'
  }

  writeDate(arg) {
    if (arg == null) return this.writeArg(arg)
    if (!validator.nonStrictCheckDate(arg)) throw new Error(`Invalid date format: ${arg}`)
    this.writeArg(arg)
  }

  writeBoolArray(arg) {
    if (arg == null) return this.writeArg(arg)
    const bits = arg
      .map(b => (b ? 1 : 0))
      .reverse()
      .join('')
    const sum = Number(`0b${bits}`)
    const hex = sum.toString(16)
    this.writeArg(hex)
  }

  writeFilter(meta, filter) {
    if (this.#state !== 'empty') this.#buffer += Token.FilterSeparatorOrEmptyArray
    this.#buffer += meta.type.toString()
    this.#state = 'filter'
    if (meta.create != null) meta.encode(this.#writer, meta, filter.config)
  }
}

class ExposedWriter {
  #encoder

  constructor(encoder) {
    this.#encoder = encoder
  }

  writeArg(arg) {
    this.#encoder.writeArg(arg)
  }

  writeDate(arg) {
    this.#encoder.writeDate(arg)
  }

  writeBoolArray(arg) {
    this.#encoder.writeBoolArray(arg)
  }
}

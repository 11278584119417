<div class="inline-block">
  <div class="flex-row flex-align-center g05">
    {#if linkToPricing}
      <IconTextLink iconProps={{ sm: true }} {icon} dataTest="requires-upgrade" href="/pricing" text={label} />
    {:else}
      <span data-test="requires-upgrade">
        <Icon name={icon} sm />
        {label}
      </span>
    {/if}
    {#if help}<Help>{help}</Help>{/if}
  </div>
</div>

<script>
  import Help from './Help.svelte'
  import Icon from './Icon.svelte'
  import IconTextLink from './IconTextLink.svelte'

  export let linkToPricing = true
  export let help = null

  const label = 'Requires upgrade'
  const icon = 'subscription'
</script>

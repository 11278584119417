<!-- div so each is on their own line, span so the tooltip hover region doesn't include empty space -->
<div>
  <span class="staff-role" use:tip={{ content: tooltipElem, options: tipOptions }}>
    <strong>
      {role.staffRoleName}
    </strong>
    {#if showMultipleRestrictions && staffRoleDescription.length > 1}
      <span class="normal">Multiple restrictions</span>
    {:else}
      <FriendlyList punctuation max={Infinity} items={staffRoleDescription} />
    {/if}
  </span>
  {#if showAutoAssigned && role.isAutoAssigned}
    <Icon fw name="gear-code" class={iconClass} title={staffRoleAutoAssignmentTooltip} />
  {/if}
</div>

<div class="hide">
  <div bind:this={tooltipElem}>
    <div class="staff-role-tooltip">
      <h4><strong>{role.staffRoleName}</strong> at <strong>{orgStaff.org.name}</strong></h4>
      {#if !countsOnly}
        {#if role.orgs?.length}
          <h5>For <Icon name="map-marker" class={orgAndTeamClass} /> <strong>{pluralCount('location', role.orgs)}</strong>…</h5>
          <ul>
            {#each role.orgs as o (o.orgId)}
              <li>{o.name}</li>
            {/each}
          </ul>
        {/if}

        {#if hasTeamFeature}
          {#if role.teams?.length}
            <h5>For <Icon name="users" class={orgAndTeamClass} /> <strong>{pluralCount('team', role.teams)}</strong>…</h5>
            <ul>
              {#each role.teams as t (t.teamId)}
                <li>{t.name}</li>
              {/each}
            </ul>
          {/if}
        {/if}

        {#if hasHealthFeature && isRoleAssignableToOpportunitiesOrServicesOrDisciplines}
          <!-- TODO(tim): refactor this for many:many - think they should be separate columns that depend on each other rather than 1 tree -->
          {#if services.length}
            <h5>
              For
              {#if role.services?.length}
                {#if role.capacities?.length}
                  <Icon name="shapes" class="color-text-purple" />
                  <strong>{pluralCount('service', role.services)}</strong> and <Icon name="list" class="color-text-purple" />
                  <strong>{pluralCount('opportunity', role.capacities)}</strong>…
                {:else}
                  <Icon name="shapes" class="color-text-purple" />
                  <strong>{pluralCount('service', role.services)}</strong>…
                {/if}
              {:else}
                <Icon name="list" class="color-text-purple" />
                <strong>{pluralCount('opportunity', role.capacities)}</strong>…
              {/if}
            </h5>
            <ul>
              {#each services as s (s.serviceId)}
                <li>
                  {#if s.name != null && role.capacities?.length}
                    {#if s.capacities.length}
                      <Icon xs name="chart-pie-alt" class={iconClass} />
                    {:else}
                      <Icon xs name="circle" class={iconClass} />
                    {/if}
                  {/if}
                  {#if s.name == null}
                    <em>Not within a service</em>
                  {:else}
                    {s.name}
                  {/if}
                  {#if s.capacities.length}
                    <ul>
                      {#each s.capacities as c (c.capacityId)}
                        <li>{c.name}</li>
                      {/each}
                    </ul>
                  {/if}
                </li>
              {/each}
            </ul>
          {/if}

          {#if role.disciplines?.length}
            <h5>For <Icon name="book" class="color-text-teal" /> <strong>{pluralCount('discipline', role.disciplines)}</strong>…</h5>
            <ul>
              {#each role.disciplines as d (d.label)}
                <li>{d.label}</li>
              {/each}
            </ul>
          {/if}
        {/if}

        {#if hasCustomTagFeature && [StaffRole.Coordinator, StaffRole.Preceptor].includes(role.staffRole)}
          {#if role.customTags?.length}
            <h5>For <Icon name="tag" class="color-text-light-brown" /> <strong>{pluralCount('custom tag', role.customTags)}</strong>…</h5>
            <ul>
              {#each role.customTags as ct (ct.customTagId)}
                <li>{ct.name}</li>
              {/each}
            </ul>
          {/if}
        {/if}

        {#if hasSchoolFeature && role.staffRole === StaffRole.Coordinator}
          {#if role.students?.length}
            <h5>For <Icon name="users" class="color-text-teal" /> <strong>{pluralCount('student', role.students)}</strong>…</h5>
            <ul>
              {#each role.students as s (s.userId)}
                <li>{s.name}</li>
              {/each}
            </ul>
          {/if}
        {/if}
      {/if}
    </div>
  </div>
</div>

<script context="module">
  const tipOptions = { interactive: true, placement: 'left', theme: 'light-gray-scrollable' }
</script>

<script>
  import { StaffRole } from 'config/enums.js'
  import { pluralCount } from 'services/string-utils.js'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Icon from 'components/Icon.svelte'
  import roleService from 'services/role-service.js'
  import tip from 'decorators/tip.js'

  export let hasHealthFeature
  export let hasSchoolFeature
  export let hasTeamFeature
  export let hasCustomTagFeature
  export let orgStaff
  export let role
  export let showAutoAssigned
  export let showMultipleRestrictions
  export let countsOnly = false

  const iconClass = 'text-info'

  let tooltipElem = null
  let staffRoleAutoAssignmentTooltip = null

  $: isRoleAssignableToOpportunitiesOrServicesOrDisciplines = roleService.isRoleAssignableToServicesOrOpportunitiesOrDisciplines(role.staffRole)
  $: staffRoleDescription = buildStaffRoleDescription(role)
  $: orgAndTeamClass = hasHealthFeature ? 'color-text-orange' : 'color-text-blue'

  function buildStaffRoleDescription(role) {
    const list = []
    addToList(list, 'location', role.orgs)
    addToList(list, 'team', role.teams)
    addToList(list, 'service', role.services)
    addToList(list, 'opportunity', role.capacities)
    addToList(list, 'discipline', role.disciplines)
    addToList(list, 'custom tag', role.customTags)
    addToList(list, 'student', role.students)
    return list
  }

  function addToList(list, unit, countable) {
    if (countable?.length) list.push(pluralCount(unit, countable))
  }

  $: services = buildServices(role.services, role.capacities)

  function buildServices(services, capacities) {
    services ??= []
    capacities ??= []

    const servicesById = new Map()
    for (const service of services) {
      servicesById.set(service.serviceId, { ...service, capacities: [] })
    }

    for (const capacity of capacities) {
      const service = servicesById.get(capacity.capacityId)
      if (service == null) {
        servicesById.set(capacity.serviceId, {
          serviceId: capacity.serviceId,
          name: capacity.serviceName,
          capacities: [capacity],
        })
      } else {
        service.capacities.push(capacity)
      }
    }

    const allServices = _.sortBy([...servicesById.values()], s => s.name)
    for (const service of allServices) {
      service.capacities = _.sortBy(service.capacities, c => c.name)
    }

    return allServices
  }

  $: if (showAutoAssigned)
    staffRoleAutoAssignmentTooltip =
      role.staffRole === StaffRole.Preceptor
        ? orgStaff.user.name + ' is automatically assigned as preceptor to rotations matching this role’s criteria.'
        : orgStaff.user.name + ' has been given access to rotations matching this role’s criteria'
</script>

<style lang="scss">
  @import '../../css/helpers';

  .staff-role {
    cursor: inherit;
    font-size: 12px;

    > strong {
      text-decoration: underline dotted #aaa;
      text-underline-offset: 2px;
    }
    &:hover {
      > strong {
        color: $primary;
        text-decoration-color: $primary;
      }
    }
  }

  .staff-role-tooltip {
    * {
      padding: 0;
      margin: 0;
      text-align: left;
      line-height: 1.25;
    }

    h4,
    h5 {
      font-weight: normal;
    }

    h4 {
      > strong {
        color: $primary;
      }
    }

    ul {
      margin-left: 25px;
      list-style: none;
      font-size: 12px;
    }
  }
</style>

import { getScrollParent, lockScroll, unlockScroll } from 'services/scroll-service.js'
import { isTouchDevice } from 'services/device-utils.js'

/*
  TODO: multiple elements rendered with drag-and-select seem to conflict--only one at a time should allow drag-and-select (on mousedown)
    - low priority since we currently don't have this scenario anywhere
*/

const isTouch = isTouchDevice()
export const dragSelectTip = `multiple days can be selected and edited by ${isTouch ? 'touching' : 'clicking'} and dragging your ${
  isTouch ? 'finger' : 'mouse'
} on the calendar.`

// Make scrolling faster; we can't call `preventDefault()` in these handlers
// or we're breaking a contract we're signing up for.
// See https://developers.google.com/web/updates/2017/01/scrolling-intervention
const passive = { passive: true }

const xy = (x, y) => ({ x, y })

function getXY(e) {
  const src = e.touches?.[0] ?? e
  return xy(src.clientX, src.clientY)
}

function axisIntersects(box1, box2, axis) {
  const b1s = box1.start[axis]
  const b1e = box1.end[axis]
  const b2s = box2.start[axis]
  const b2e = box2.end[axis]
  return (b1s <= b2s && b1e >= b2s) || (b1s >= b2s && b1s <= b2e) || (b1e <= b2e && b1s >= b2e) || (b1e >= b2e && b1e <= b2s)
}

function intersects(box1, box2) {
  const xIntersects = axisIntersects(box1, box2, 'x')
  const yIntersects = axisIntersects(box1, box2, 'y')
  return xIntersects && yIntersects
}

export default function dragAndSelect(containerElem, options) {
  let selection = null
  let selectionDisplay = null
  let containerElements = null
  let offsetY = 0
  let offsetX = 0
  const html = document.querySelector('html')
  let containerScrollParent = null
  const events = []
  events.push(
    [window, 'scroll', setXYOffsets],
    // For touch
    [containerElem, 'touchstart', onStartSelect],
    [containerElem, 'touchmove', onSelectionChanged],
    [containerElem, 'touchend', onSelectComplete],
    [containerElem, 'touchcancel', resetVals],
    // For mouse (Set up touch & mouse events for devices supporting both; e.g. laptop with touch screen)
    [containerElem, 'mousedown', onStartSelect],
    [window, 'mousemove', onSelectionChanged],
    [window, 'mouseup', onSelectComplete]
  )

  init()

  function init() {
    const canInit = containerElem != null && options?.onSelectDays != null && options?.selector != null
    if (!canInit) return
    resetVals()
    setXYOffsets()
    for (const [node, eventName, callback] of events) {
      node.addEventListener(eventName, callback, passive)
    }
  }

  function destroy() {
    resetVals()
    for (const [node, eventName, callback] of events) {
      node.removeEventListener(eventName, callback, passive)
    }
  }

  function resetVals() {
    unlockScroll(containerScrollParent)
    containerScrollParent = null
    selection = null
    containerElements = null
    displaySelection()
  }

  function onStartSelect(e) {
    const rightClick = e.which === 3
    if (rightClick) return
    containerScrollParent = getScrollParent(containerElem)
    lockScroll(containerScrollParent, true)
    const start = getXY(e)
    selection = { start, end: start }
    containerElements = document.querySelectorAll(options.selector)
  }

  function onSelectionChanged(e) {
    if (selection === null) return
    selection.end = getXY(e)
    displaySelection()
  }

  function onSelectComplete() {
    if (selection === null) return
    options.onSelectDays(getSelectedElements())
    resetVals()
  }

  function displaySelection() {
    if (selection === null) {
      selectionDisplay?.remove()
      selectionDisplay = null
      return
    }
    if (selectionDisplay == null) {
      selectionDisplay = document.createElement('div')
      selectionDisplay.classList.add('drag-selection')
    }
    selectionDisplay.style.width = `${Math.abs(selection.start.x - selection.end.x)}px`
    selectionDisplay.style.height = `${Math.abs(selection.start.y - selection.end.y)}px`
    selectionDisplay.style.top = `${Math.min(selection.start.y, selection.end.y) + offsetY}px`
    selectionDisplay.style.left = `${(selection.start.x - selection.end.x > 0 ? selection.end.x : selection.start.x) + offsetX}px`
    document.body.append(selectionDisplay)
    options.onPreSelect(getSelectedElements())
  }

  function setXYOffsets() {
    offsetY = html.scrollTop
    offsetX = html.scrollLeft
  }

  function getSelectedElements() {
    const noItemsInContainer = containerElements == null || containerElements.length === 0
    if (noItemsInContainer) return []
    const selectedEls = []
    for (const elem of containerElements) {
      const bounds = elem.getBoundingClientRect()
      const elBox = {
        start: xy(bounds.left, bounds.top),
        end: xy(bounds.left + bounds.width, bounds.top + bounds.height),
      }
      if (intersects(selection, elBox)) selectedEls.push(elem)
    }
    return selectedEls
  }

  return {
    update(newOptions) {
      options = newOptions
      destroy()
      init()
    },

    destroy() {
      destroy()
    },
  }
}

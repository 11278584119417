import { Permission, DashboardViewCategory } from 'config/enums.js'

export default {
  category: DashboardViewCategory.Views,
  permission: Permission.CN,
  componentName: 'CNOverview',
  name: 'CN overview',
  icon: 'analytics',
  criteria: {},

  waitFor: { method: 'GET', route: /\/monitor\/stats/i },
  testFor: ['metrics'],
}

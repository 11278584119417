<div class="flex-row flex-wrap flex-align-stretch">
  <div class="flex-grow flex-column main-tab-content">
    <Tabs bind:active={tabName} bind:this={tabs}>
      <Tab href="general" name="general" icon="settings">General info</Tab>
      <Tab href="schools" name="schools" icon="user">Schools</Tab>
      <Tab href="scheduling" name="scheduling" icon="calendar">Availability</Tab>
    </Tabs>

    <div class="p3 flex-grow">
      <slot />
    </div>

    <div class="modal-footer flex-row flex-align-center g05">
      <slot name="footer" />
    </div>
  </div>

  {#if capacityId > 0}
    <MediaQuery minWidth={thinWidth}>
      <CapacityActivity {capacityId} disabled={false} {saving} />
    </MediaQuery>
  {/if}
</div>

{#if capacityId > 0}
  <MediaQuery maxWidth={thinWidth - 1}>
    <CapacityActivity {capacityId} disabled={false} {saving} />
  </MediaQuery>
{/if}

<script>
  import CapacityActivity from 'components/CapacityActivity.svelte'
  import MediaQuery from 'components/MediaQuery.svelte'
  import Tab from 'components/Tab.svelte'
  import Tabs from 'components/Tabs.svelte'

  export let tabName = 'general'
  export let capacity = null
  export let tabs = null
  export let saving = false
  const thinWidth = 1100

  $: capacityId = capacity?.capacityId
</script>

<style>
  .main-tab-content {
    width: calc(100% - 600px);
  }

  @media only screen and (max-width: 1250px) {
    .main-tab-content {
      width: 100%;
    }
  }
</style>

<div class="list-group-item log" transition:slide|local>
  <div class="flex-row flex-justify-between">
    <div>
      <div>
        <Icon name="calendar" />
        {log.dateFormatted}
        {dateService.formatTime(log.from)}-{dateService.formatTime(log.to)}
        ({log.total})
      </div>
      <div>
        <Icon name="map-marker" />
        {log.locationName}
      </div>
      <HelpBlock>{log.typeName}{hourLogsMustBeVerified ? `, ${pluralCount('supervisor', log.supervisorUserIds.length)}` : ''}</HelpBlock>
      {#if log.note}
        <HelpBlock>Notes: {log.note}</HelpBlock>
      {/if}
    </div>
    <div>
      <Btn icon="edit" class="btn-sm" on:click={() => dispatch('click', log)}>Edit</Btn>
      <Btn icon="delete" class="btn-outline-danger btn-sm" on:click={deleteLog} loading={deleting}>Delete</Btn>
      {#if log.canVerify}
        <HourLogVerificationBtn {log} on:changed />
      {/if}
    </div>
  </div>
  {#if log.verifier != null}
    <div class="text-success">Verified {dateService.calendarTimeLower(log.verifiedDate)} by {verifierName}</div>
  {:else if hourLogsMustBeVerified && logSupervisors.length > 0}
    <div class="text-warning">
      Awaiting verification from
      {toFriendlyList(
        logSupervisors.map(s => s.name),
        3,
        true
      )}
    </div>
  {/if}
  <slot />
</div>

<script>
  import HourLogVerificationBtn from 'components/HourLogVerificationBtn.svelte'
  import { toFriendlyList } from 'services/string-utils.js'
  import dateService from 'services/date-service.js'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import { createEventDispatcher } from 'svelte'
  import { slide } from 'svelte/transition'
  import loggedInUser from 'stores/user.js'
  import { pluralCount } from 'services/string-utils.js'

  export let log
  export let supervisors
  export let hourLogsMustBeVerified

  const dispatch = createEventDispatcher()
  let deleting = false

  $: logSupervisors = supervisors.filter(s => log.supervisorUserIds.includes(s.userId))
  $: verifierName = log.verifier ? (log.verifier.userId === $loggedInUser.userId ? 'you' : log.verifier.name) : null

  async function deleteLog() {
    deleting = true
    await api.hourLog.delete({ hourLogId: log.hourLogId, matchId: log.matchId }, api.noMonitor).finally(() => (deleting = false))
    dispatch('changed')
  }
</script>

<style>
  .log {
    border: 1px solid #eee;
    border-radius: 5px;
    margin-bottom: 5px;
    box-shadow: 0 1px 10px 4px rgba(0, 0, 0, 0.08);
  }
</style>

<!--
  remember: default route's path should be "/", so that its tab is determined to be `isCurrent`/active
  https://github.com/EmilTholin/svelte-routing#link
  https://github.com/EmilTholin/svelte-routing/blob/master/src/Link.svelte
-->
<li class:active={$active == name} class="tab-{color}" data-test="tab-{name}" use:tip={tooltip}>
  <Link to={href ?? ''} on:click={selectIfNoHref} getProps={selectIfHrefAndIsCurrent} class={linkClass}>
    {#if icon}
      <Icon x2 name={icon} />
    {/if}
    <slot />
  </Link>
</li>

<script>
  import { getContext, onDestroy } from 'svelte'
  import { key } from 'components/Tabs.svelte'
  import { Link, navigate } from 'svelte-routing'
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'

  const { active, tabs } = getContext(key)

  $: if (name && tabs != null) {
    $tabs = [
      ...getOtherTabs(),

      {
        tab: this,
        name,
        onSelectProgrammatically: () => {
          if (qualifiedHref) navigate(qualifiedHref)
        },
      },
    ]
  }

  onDestroy(() => {
    $tabs = getOtherTabs()
  })

  export let name = null
  export let href = null
  export let tooltip = null // string message to indicate the user should check this tab
  export let color = 'default'
  export let icon = null
  export let linkClass = null

  let qualifiedHref

  function selectIfNoHref(e) {
    if (href) return
    e.detail.preventDefault()
    setSelected()
  }

  function selectIfHrefAndIsCurrent(linkProps) {
    if (!href) return
    qualifiedHref = linkProps.href
    if (linkProps.isCurrent) setSelected()
  }

  function setSelected() {
    $active = name
  }

  function getOtherTabs() {
    return $tabs.filter(t => t.tab === this)
  }
</script>

{#if !isTooltip}
  <Help>{RequestDateHelpText}</Help>
{:else}
  <HelpBlock>{RequestDateHelpText}</HelpBlock>
{/if}

<script>
  import Help from 'components/Help.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'

  export let isTooltip = false
  export let isHealth = false

  $: RequestDateHelpText = isHealth
    ? 'This school can request rotations using this opportunity within these dates.'
    : 'You’re able to request rotations using this opportunity within these dates.'
</script>

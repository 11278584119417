<QuickDropdown
  label="Share"
  icon="share"
  btnClass="btn btn-default"
  labelClass="flex-row flex-align-center g05"
  dropdownClass="p2"
  dataTest="share-dropdown"
>
  <div class="flex-column g05">
    <p class="m0">You can share this page view by:</p>
    <ol>
      <li>Copying this URL <small>(it’s the same one that’s in the address bar)</small></li>
      <li>Sharing the QR code below</li>
    </ol>

    <ShareHref {name} {href} />
  </div>
</QuickDropdown>

<script>
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import ShareHref from 'components/ShareHref.svelte'

  export let name
  export let href
</script>

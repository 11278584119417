{#if loading}
  <Loading />
{:else if options != null}
  <InputTags bind:value {options} {placeholder} tagClass="info" name="specialties" />
{/if}

<script>
  import InputTags from 'components/fields/InputTags.svelte'
  import Loading from 'components/Loading.svelte'
  import api from 'services/api.js'

  export let value = null
  export let options = null
  export let placeholder = 'Specialties'

  let loading = false

  loadOptions()

  async function loadOptions() {
    if (options != null) return
    loading = true
    try {
      options = await api.tag.listSpecialties(api.noMonitor)
    } finally {
      loading = false
    }
  }
</script>

<div class="text-{getStepStatusColor(stepSubmission.status)}" data-test="step-submission-status">
  <Icon name={getStepStatusIcon(stepSubmission.status)} class="status-icon" fw />
  {formatEnumValue(StepSubmissionStatus, stepSubmission.status)}
</div>

{#if stepSubmission.status === StepSubmissionStatus.AwaitingVerification}
  <div data-test="specific-verifiers">
    {#if stepSubmission.step.verificationType === StepVerificationType.SpecificUsers}
      by
      {#each stepSubmission.step.verifierUsers as vuser, i}
        {#if i > 0},{/if}
        <a href={vuser.url}>{vuser.name}</a>
      {/each}
    {:else}by {#if stepSubmission.step.verificationType === StepVerificationType.AnySchoolCoordinator}a school coordinator{:else if stepSubmission.step.verificationType === StepVerificationType.AnySchoolFaculty}a
        school faculty member{:else}a clinical coordinator{/if}{/if}
  </div>
{/if}

<div>
  {#if stepSubmission.completed}
    <div class="em mb1" data-test="step-completed-info">
      Completed
      {#if stepSubmission.completedDate}{dateService.calendarTimeLower(stepSubmission.completedDate)}{/if}
      {#if stepSubmission.completedUser && !stepSubmission.anonymize}by {youOrName(stepSubmission.completedUser)}{/if}
      {#if stepSubmission.targetUser?.name && !stepSubmission.anonymize}for {youOrName(stepSubmission.targetUser)}{/if}
    </div>
  {/if}

  {#if stepSubmission.waived}
    <div class="em mb1" data-test="step-waived-info">
      Waived
      {dateService.calendarTimeLower(stepSubmission.waivedDate)}
      {#if stepSubmission.waivedUser}by {youOrName(stepSubmission.waivedUser)}{/if}
    </div>
  {/if}

  {#if stepSubmission.skipped}
    <div class="em mb1" data-test="step-skipped-info">
      Skipped
      {dateService.calendarTimeLower(stepSubmission.skippedDate)}
      {#if stepSubmission.skippedUser}by {youOrName(stepSubmission.skippedUser)}{/if}
    </div>
  {/if}

  {#if !stepSubmission.waived && !stepSubmission.skipped && stepSubmission.verificationStatus !== StepVerificationStatus.AwaitingVerification}
    <div class="em" data-test="step-rejection-info">
      {formatEnumValue(StepVerificationStatus, stepSubmission.verificationStatus)}
      {#if stepSubmission.verifiedDate}{dateService.calendarTimeLower(stepSubmission.verifiedDate)}{/if}
      {#if stepSubmission.verifiedUser}by {youOrName(stepSubmission.verifiedUser)}{/if}
      {#if stepSubmission.verificationStatus === StepVerificationStatus.Rejected && !validator.empty(stepSubmission.rejectedReason)}
        -
        {stepSubmission.rejectedReason}
      {/if}
      <StepVerificationExpiration verificationExpirationDate={stepSubmission.verificationExpirationDate} />
    </div>
  {/if}

  {#if !validator.empty(stepSubmission.submissionInformationHtml)}
    <div><SafeHtml value={stepSubmission.submissionInformationHtml} /></div>
  {/if}
</div>

<script>
  import { getStepStatusColor, getStepStatusIcon } from 'services/step-submission-service.js'
  import Icon from './Icon.svelte'
  import { formatEnumValue } from 'services/formatters.js'
  import StepVerificationExpiration from './StepVerificationExpiration.svelte'
  import SafeHtml from './SafeHtml.svelte'
  import validator from 'services/validator.js'
  import { youOrName } from 'services/you-service.js'
  import dateService from 'services/date-service.js'
  import { StepSubmissionStatus, StepVerificationStatus, StepVerificationType } from 'config/enums.js'

  export let stepSubmission
</script>

<div class="hide">
  <div bind:this={tooltipElem} class={className}>
    <slot />
  </div>
</div>

<script>
  export let tooltipElem
  let className = null
  export { className as class }
</script>

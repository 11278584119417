<!-- svelte-ignore a11y-no-noninteractive-tabindex -->
<div
  {id}
  class:has-error={showInvalid}
  class:submitted
  class="form-group{className ? ` ${className}` : ''}"
  {style}
  bind:this={container}
  data-test={dataTest}
  tabindex={focusContainer ? -1 : null}
>
  {#if !messageAbove}
    <slot />
  {/if}

  {#if showInvalid && hasValidationMessage}
    <HelpBlock class="validation-message bounce{validationClass ? ` ${validationClass}` : ''}">
      <slot name="validationMessage">
        {#if validationMessageIsHtml}
          <SafeHtml value={validationMessage} />
        {:else if contextValidationMessage}
          {contextValidationMessage}
        {:else}
          {validationMessage}
        {/if}
      </slot>
    </HelpBlock>
  {/if}

  {#if messageAbove}
    <slot />
  {/if}
</div>

<script>
  import { getContext, setContext, onDestroy } from 'svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import SafeHtml from 'components/SafeHtml.svelte'

  let className = ''
  export { className as class }
  export let style = ''
  export let valid = true
  export let validationMessage = null
  export let validationClass = 'mb1'
  export let validationMessageIsHtml = false
  export let dataTest = null
  export let allowSubmitIfInvalid = false // only an option for preview fields inside FormBuilder
  export let messageAbove = false
  export let focusContainer = false
  export let showErrorsOnDirty = true
  export let dirty = false
  export let id = null

  let container = null

  const submitted = getContext('submitted')
  const addValidation = getContext('addValidation')

  let contextValidationMessage = null
  function addFormGroupValidation(msg) {
    contextValidationMessage = msg
  }

  if (addValidation != null) {
    const removeValidation = addValidation({
      isValid() {
        return allowSubmitIfInvalid || valid
        // if (!result) highlightInvalid()
        // return result
      },

      getElement() {
        if (focusContainer) return container

        const focusElement = container.querySelector(
          'input[type="text"], input[type="password"], textarea, input[type="radio"], input[type="checkbox"], .input-select, .btn'
        )
        return focusElement || container
      },

      resetDirty() {
        dirty = false
      },
    })
    onDestroy(() => removeValidation())
  }

  setContext('markDirty', (isDirty = true) => (dirty = isDirty))
  setContext('addFormGroupValidation', addFormGroupValidation)

  $: showInvalid = !valid && ($submitted || (dirty && showErrorsOnDirty))
  $: hasValidationMessage = validationMessage || contextValidationMessage || $$slots.validationMessage
</script>

{#if guest}
  <div class="p1 card-left-thick-{guest.color}">
    <div class="mb05">
      <CapacityGuestProfilePictureAndName capacityGuest={guest} nameClass="strong text-lg" useOverflowEllipsis={true} />
    </div>
    <hr class="m0" />
    <div class="mt05">
      <table>
        {#if guest.guaranteedMatchCountGranted > 0}
          <tr>
            <td />
            <td class="text-black" style="font-style: italic;">{guest.guaranteedMatchCountGranted} guaranteed rotations</td>
          </tr>
        {/if}
        {#if guest.approvedOverMax > 0}
          <tr>
            <td class="color-text-{guest.color}">
              <Icon x2 name="square" class="mr05" />
            </td>
            <td class="text-black">{guest.approvedMatches - guest.approvedOverMax} approved</td>
          </tr>
          <tr>
            <td>
              <Icon
                x2
                name="square-exclamation"
                style="--path-0-color: {guest.textColor}; --path-0-opacity: 1 !important; --path-1-color: var(--user-{guest.color}); --path-1-opacity: 1;"
              />
            </td>
            <td class="text-black">{guest.approvedOverMax} approved over maximum</td>
          </tr>
        {:else if guest.approvedMatches > 0}
          <tr>
            <td class="color-text-{guest.color}">
              <Icon x2 name="square" class="mr05" />
            </td>
            <td class="text-black">{guest.approvedMatches} approved</td>
          </tr>
        {/if}
        {#if guest.pendingOverMax > 0}
          {#if guest.pendingMatches - guest.pendingOverMax > 0}
            <tr>
              <td class="color-text-{guest.color}">
                <Icon x2 name="square" class="mr05" style="opacity: .6" />
              </td>
              <td class="text-black">{guest.pendingMatches - guest.pendingOverMax} pending</td>
            </tr>
          {/if}
          <tr>
            <td>
              <Icon
                x2
                name="square-exclamation"
                style="--path-0-color: {guest.textColor}; --path-0-opacity: 1 !important; --path-1-color: var(--user-{guest.color}); --path-1-opacity: .6;"
              />
            </td>
            <td class="text-black">{guest.pendingOverMax} pending over maximum</td>
          </tr>
        {:else if guest.pendingMatches > 0}
          <tr>
            <td class="color-text-{guest.color}">
              <Icon x2 name="square" class="mr05" style="opacity: .6" />
            </td>
            <td class="text-black">{guest.pendingMatches} pending</td>
          </tr>
        {/if}
        {#if guest.guaranteedLeft > 0}
          <tr>
            <td class="color-text-{guest.color}">
              <Icon x2 name="square" style="opacity: .3" class="mr05" />
            </td>
            <td class="text-black">{guest.guaranteedLeft} guaranteed left</td>
          </tr>
        {/if}
      </table>
    </div>
  </div>
{/if}

<script>
  import Icon from 'components/Icon.svelte'
  import CapacityGuestProfilePictureAndName from './CapacityGuestProfilePictureAndName.svelte'

  export let guest
</script>

<style lang="scss">
</style>

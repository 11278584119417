<Grid
  name="capacity-shifts"
  rows={guestShifts}
  bind:selectedRow={selectedShiftId}
  rowKeySelector={row => row.shiftId}
  onRowClick={onClickShift}
  class="middle"
  enableSingleRowSelection
  emptyMessage="The opportunity school doesn’t have access to any availability windows."
  let:row
>
  <GridCol {row} header="Window name & location" class="shrink" thClass="nowrap">
    <div class="flex-column g05">
      <label class="mb0">{row.name}</label>
      {#if row.description}
        <em class="small">{row.description}</em>
      {/if}
      {#if row.locations?.length}
        {@const location = row.locations[0]}
        <OrgProfilePictureAndName
          name={location.name}
          relativeName={location.relativeName}
          profilePicture={location.profilePicture}
          useOverflowEllipsis
        />
      {:else}
        <em class="small">Available to all locations</em>
      {/if}
    </div>
  </GridCol>

  <GridCol {row} header="Staff" class="shrink">
    {#if row.staff?.length}
      {@const staffMembers = row.staff}
      <span class="small em">
        <div class="flex-row g05">
          {#each staffMembers as staff}
            <ProfilePic id={staff.userId} name={staff.name} src={staff.profilePicture} title={staff.name} small />
          {/each}
        </div>
      </span>
    {:else}
      <em class="small">No specific staff assigned</em>
    {/if}
  </GridCol>

  <GridCol {row} header="Group information" class="shrink">
    <div class="flex column g05">
      {#if capacity.allowGroups}
        <div class="flex-row flex-align-center g05">
          <Icon name="users" />
          {#if row.maxMatchCountPerDay}
            {readableNumber(row.maxMatchCountPerDay)}
          {:else}
            <Icon lg name="infinity" class="text-info" />
            Unlimited
          {/if}
          <span> {pluralCount('group', row.maxMatchCountPerDay, 'omitNumber')} per day</span>
        </div>
        <div class="flex-row flex-align-center g05">
          <Icon name="users" />
          {#if row.maxStudentsInGroup}
            {readableNumber(row.maxStudentsInGroup)}
          {:else}
            <Icon lg name="infinity" class="text-info" />
            Unlimited
          {/if}
          <span> {pluralCount('student/learner', row.maxStudentsInGroup, 'omitNumber')} per group</span>
        </div>
      {:else}
        <div class="flex-row flex-align-center g05">
          <Icon name="user" />
          {#if row.maxMatchCountPerDay === 0 || row.maxMatchCountPerDay == null}
            <Icon lg name="infinity" class="text-info" />
            Unlimited
          {:else}
            {readableNumber(row.maxMatchCountPerDay)}
          {/if}
          <span> students/learners per day</span>
        </div>
      {/if}
      {#if row.matchDayLimitPerWeek != null && row.matchDayLimitPerWeek !== 7}
        <div class="flex-row flex-align-center g05">
          <span> Available to rotations {pluralCount('day', row.matchDayLimitPerWeek)}/week</span>
        </div>
      {/if}
    </div>
  </GridCol>

  <GridCol {row} header="Schedules" class="shrink">
    {#if row.shiftDays?.length}
      {#each row.shiftDays as shiftDay}
        <ShiftDay {capacity} {shiftDay} shift={row} />
      {/each}
    {:else}
      <ShiftDay {capacity} shift={row} />
    {/if}
  </GridCol>
</Grid>

<script>
  import { pluralCount, readableNumber } from 'services/string-utils.js'
  import { shiftOccursOnDate } from 'services/calendar-service.js'
  import { getGuestShifts } from 'services/capacity-usage'
  import Grid from 'components/Grid.svelte'
  import GridCol from 'components/GridCol.svelte'
  import Icon from 'components/Icon.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import ShiftDay from 'components/ShiftDay.svelte'

  export let capacity
  export let capacityGuest = null
  export let onClickShift = _.noop
  export let selectedShiftId

  let guestShifts = []
  $: if (capacity) setGuestShifts()

  function setGuestShifts() {
    guestShifts = getGuestShifts(capacity.shifts, capacityGuest)
    const dateExceptionsInDayjs = capacity.dateExceptions.map(e => dayjs(e))
    for (const guestShift of guestShifts) {
      let totalExceptions = 0
      for (const currentDate of dateExceptionsInDayjs) {
        if (shiftOccursOnDate(capacity, guestShift, currentDate)) totalExceptions++
      }
      guestShift.totalExceptions = totalExceptions
    }
  }
</script>

{#if editing}
  <InputRadioGroup
    name="opportunity-type"
    {options}
    bind:value={filter.allowGroups}
    firstOptionClass="mt0"
    lastOptionClass="mb2"
    class="flex-row flex-align-center g1"
    let:option
  >
    <Icon md name={iconByOptionValue[option.value]} class="text-primary" />
    {option.name}
  </InputRadioGroup>
{:else if filter.allowGroups}
  <Icon md name="users" class="text-primary" />
  For groups
{:else}
  <Icon md name="user" class="text-primary" />
  For individuals
{/if}

<script context="module">
  import { buildOption } from 'services/option-builder.js'
  import { FilterType } from 'config/enums.js'

  const label = 'For individuals/groups'

  export const meta = {
    type: FilterType.CapacityAllowGroups,
    label,
    editLabel: null,
    hideLabelIcon: true,
    icon: 'users',
    iconClass: 'text-primary',

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.allowGroups)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.allowGroups = reader.readBool()
      return config
    },

    create() {
      return { allowGroups: true }
    },

    validate() {
      return true
    },
  }
  const options = [buildOption('For individuals', false), buildOption('For groups', true)]
  const iconByOptionValue = { false: 'user', true: 'users' }
</script>

<script>
  import Icon from 'components/Icon.svelte'
  import InputRadioGroup from 'components/fields/InputRadioGroup.svelte'

  export let filter
  export let editing = false
</script>

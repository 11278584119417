<FilterTypeUserListFilter {filter} {editing} {filterOptions} {appliedMeta} />

<script context="module">
  import FilterTypeUserListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeUserListFilter.svelte'
  import { FilterType, PersonaType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.OwnerUsers,
    label: 'Owner',
    labelApplied: 'owner',
    icon: 'user',
    iconClass: 'text-gray',
    filterProp: 'userIds',
    optionsEndpoint: 'getOwnerUsers',
    configure({ persona }) {
      this.iconClass = persona.personaType === PersonaType.ProviderStaff ? 'color-text-orange' : 'color-text-blue'
    },
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>

<Route path={path ? `${path}/*` : '*'}>
  <Router>
    <SimpleMatchList {matches} {baseUrl} {emptyMsg} />
    <Route path="/matches/:matchId/*" let:params>
      <MatchModal matchId={params.matchId} on:close={onMatchModalClosed} />
    </Route>
  </Router>
</Route>

<script>
  import { Router, Route } from 'svelte-routing'
  import SimpleMatchList from './SimpleMatchList.svelte'
  import MatchModal from './MatchModal.svelte'

  export let path = null
  export let matches
  export let baseUrl
  export let onMatchModalClosed
  export let emptyMsg
</script>

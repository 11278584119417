<ButtonModalPicker
  bind:this={buttonModalPicker}
  bind:open
  lg
  {disabled}
  {multiple}
  valueSelector={oc => oc.courseId}
  labelSelector={oc => oc.name}
  options={courses}
  optionsForLabel={coursesForLabel}
  bind:value
  bind:valueTemp
  {modalTitle}
  {placeholder}
  {dataTest}
  {allowSelectNull}
  {or}
  modalClass="overflow-visible"
  modalContentClass="overflow-visible"
>
  <!-- We don't want the contents of the picker to appear invalid, so wrap it in an always-valid <FormGroup> -->
  <FormGroup valid class="full-height flex-column g1">
    <CourseFilters fullWidth {excludedFilterTypes} bind:filters {interceptors} onChanged={() => loadPage(0)}>
      <svelte:fragment slot="after-keyword-search">
        <CoursePickerShowDropdown />
      </svelte:fragment>
    </CourseFilters>

    {#if loading && currentXhrBody.offset === 0}
      <div class="text-center mt1">
        <Spinner x3 class="m2" />
      </div>
    {:else}
      <InfiniteScroll
        currentCount={courses?.length}
        {totalCount}
        distanceToLoadPage={100}
        {loadPage}
        class="scrollable-lg flex-grow flex-column {showMinimal ? 'g05' : 'g1'}"
        style="padding-right: 15px"
      >
        <EmptyPickerSlot
          bind:valueTemp
          {allowSelectNull}
          text={emptyPickerText}
          {multiple}
          {buttonModalPicker}
          icon="course"
          iconClass="color-text-steel"
          {dataTest}
          lg={!showMinimal}
        />
        {#if courses?.length}
          {#each courses as oc, i (oc.courseId)}
            <PickerSlot
              {i}
              {dataTest}
              {multiple}
              value={oc.courseId}
              {buttonModalPicker}
              lg={!showMinimal}
              bind:valueTemp
              let:isSelected
              let:isHovered
            >
              <slot name="header" course={oc} slot="header">
                <Icon lg={!showMinimal} name="course" class="color-text-steel" />
                <svelte:element this={showMinimal ? 'h4' : 'h3'} class="leading-none m0 normal">{oc.name}</svelte:element>
              </slot>

              <slot course={oc} {isSelected} {isHovered}>
                <CoursePickerSlot course={oc} {isHovered} {isSelected} />
              </slot>
            </PickerSlot>
          {/each}
        {:else if courses == null}
          <!-- This should never happen, but just in case... -->
          Failed to load courses. <a href={null} on:click={() => loadPage(0)}>Retry?</a>
        {:else}
          <h4 class="p3 text-center">No courses found.</h4>
        {/if}
      </InfiniteScroll>
    {/if}
  </FormGroup>
</ButtonModalPicker>

<script>
  import api from 'services/api.js'
  import ButtonModalPicker from 'components/fields/ButtonModalPicker.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import InfiniteScroll from 'components/InfiniteScroll.svelte'
  import persona from 'stores/persona.js'
  import PickerSlot from 'components/PickerSlot.svelte'
  import CourseFilters from 'components/CourseFilters.svelte'
  import CoursePickerShowDropdown from 'components/CoursePickerShowDropdown.svelte'
  import CoursePickerSlot from 'components/CoursePicker.Slot.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import Spinner from 'components/Spinner.svelte'
  import validator from 'services/validator.js'
  import EmptyPickerSlot from 'components/EmptyPickerSlot.svelte'

  // Common picker exports
  export let value
  export let filters = []
  export let excludedFilterTypes = []
  export let placeholder = 'None selected'
  export let emptyPickerText = 'No course'
  export let multiple = false
  export let modalTitle = multiple ? 'Select the courses' : 'Select the course'
  export let disabled = false
  export let allowSelectNull = false
  export let dataTest = 'course-picker'
  export let interceptors = {}
  export let open = false
  export let or = false

  // Specific picker exports
  export let courseCount = null
  export let selectedCourse = null

  const pageSize = 15
  let buttonModalPicker = null
  let totalCount = null
  let courses = null
  let coursesForLabel = []
  let valueTemp = null
  let loading = false
  let currentXhrBody = null

  $: show = $showDropdowns.coursePicker
  $: showMinimal = !Object.keys(show)
    .map(k => show[k])
    .some(Boolean)

  $: personaOrgId = $persona.orgId
  $: personaOrgId, loadPage(0)
  $: open, loadFirstPageIfFiltersChanged()
  $: selectedCourse = value && courses?.length ? courses.find(oc => oc.courseId === value) ?? null : null

  function loadFirstPageIfFiltersChanged() {
    if (!open || validator.equals(filters ?? [], currentXhrBody?.filters ?? [])) return
    if (open) loadPage(0)
  }

  async function loadPage(offset) {
    if (!$persona.orgId) return

    const thisXhrBody = {
      filters: _.cloneDeep(filters),
      pageSize,
      offset,
    }

    const selectedCourseIds = (multiple ? value ?? [] : [value]).filter(id => id != null && !coursesForLabel.some(oc => oc.courseId === id))
    if (selectedCourseIds.length) thisXhrBody.selectedCourseIds = selectedCourseIds

    if (validator.equals(currentXhrBody, thisXhrBody)) return
    loading = true
    currentXhrBody = thisXhrBody

    try {
      const task = api.course.list(thisXhrBody, api.noMonitor)
      const response = await task
      // TODO: Could handle if filters were changed prior to response being received... for now, assume server is fast enough.
      totalCount = response.totalCount
      courses = offset ? [...courses, ...response.courses] : response.courses
      if (selectedCourseIds.length) coursesForLabel = [...coursesForLabel, ...(response.selectedCourses ?? [])]
      if (offset === 0 && courseCount == null) courseCount = totalCount
    } finally {
      if (validator.equals(currentXhrBody, thisXhrBody)) loading = false
    }
  }

  export function clear() {
    value = null
    valueTemp = null
  }

  export function focusAndOpen() {
    buttonModalPicker?.focusAndOpen()
  }
</script>

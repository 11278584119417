{#if routingConfig}
  {#if $matchCount || $studentCount}
    <div class="modal-footer flex-column g05">
      <strong class="strongish">Summary</strong>
      <div style="display: inline-grid; grid-template-columns: auto auto auto 1fr" class="flex-align-center g05">
        <RotationCount
          class="contents"
          count={$matchCount}
          icon={$allowGroups ? 'users' : 'user'}
          unit={$allowGroups ? 'Group rotation' : 'Individual rotation'}
        />
        <StudentCount class="contents" count={$studentCount} />
      </div>
    </div>
  {/if}

  <div class="modal-footer flex-row flex-align-center g1">
    <Btn
      dataTest="submit"
      loading={$loading}
      class="btn btn-primary"
      on:click={() => {
        $shouldClose = false
        scheduleStudentsForm.onSubmit()
      }}
    >
      Create
    </Btn>

    <Btn
      dataTest="submit-and-close"
      loading={$loading}
      class="btn btn-primary"
      on:click={() => {
        $shouldClose = true
        scheduleStudentsForm.onSubmit()
      }}
    >
      Create <em>and close</em>
    </Btn>

    <Btn on:click={routingConfig.close}>Cancel</Btn>
  </div>
{:else}
  <Btn dataTest="submit" loading={$loading} class="btn btn-primary" on:click={() => scheduleStudentsForm.onSubmit()}>
    {$submitButtonLabel}
  </Btn>
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import RotationCount from 'components/RotationCount.svelte'
  import StudentCount from 'components/StudentCount.svelte'

  export let scheduleStudentsForm
  export let routingConfig = null
  export let store

  const submitButtonLabel = store.submitButtonLabel
  const loading = store.loading
  const allowGroups = store.allowGroups
  const matchCount = store.matchCount
  const studentCount = store.studentCount
  const shouldClose = store.shouldClose
</script>

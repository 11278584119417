<FilterTypeOrg {filter} {editing} {filterOptions} {appliedMeta} />

<script context="module">
  import FilterTypeOrg, { meta as baseMeta } from 'components/filter-types/FilterTypeOrg.svelte'
  import { FilterType, PersonaType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.TargetOrgs,
    label: 'Target organization',
    filterProp: 'orgIds',
    optionsEndpoint: 'getTargetOrgs',
    configure({ persona }) {
      const isSchool = persona.personaType === PersonaType.SchoolStaff
      this.icon = isSchool ? 'hospital' : 'school'
      this.iconClass = isSchool ? 'color-text-orange' : 'color-text-blue'
    },
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>

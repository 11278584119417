window.logDiffs = false // can set this in production to determine why confirm-nav-away is popping up, for instance

export function hasDifferences(a, b, ignoreKeys = []) {
  if (isEmpty(a) && isEmpty(b)) return false //so null and empty string would be considered equal
  if (a === b) return false //same ref, strings, numbers
  if (a == b) return false // "2" should equal 2...
  if ((a == null && b != null) || (b == null && a != null)) return true //one's null, but not the other
  if (_.isString(a) && _.isString(b) && a.trim() === b.trim()) return false //ignore whitespace for strings
  if (_.isDate(a)) {
    if (!_.isDate(b)) return true
    return a.getTime() !== b.getTime()
  }
  if (Array.isArray(a)) {
    if (!Array.isArray(b)) return true //one's an array, but not the other
    if (a.length !== b.length) return true //different number of items
    // up to calling code to sort if needed
    for (const [i, aElement] of a.entries()) {
      const bElement = b[i]
      if (hasDifferences(aElement, bElement, ignoreKeys)) return true //recursively compare array items by index. NOTE if you don't care about sort-order, then pass a sorted array here (sort by your unique key, for instance)
    }
    return false
  }
  if (_.isObject(a)) {
    if (!_.isObject(b)) return true //one's an object, but not the other
    const allKeys = Object.keys({ ...a, ...b })
    for (const key of allKeys) {
      if (!ignoreKeys.includes(key) && hasDifferences(a[key], b[key], ignoreKeys)) {
        log(key, 'is different', a, b)
        return true //recursively compare object key values
      }
    }
    return false
  }
  return true //shouldn't get here, but if it does, default to indicate that there is a difference
}

// get an array of keys that are different
// deep keys will have dot notation like 'myObj.subProp.value'
// arrays will specify index like 'myArr[3].itemProp'
export function getDifferences(a, b, ignoreKeys = [], key = null, changedKeys = []) {
  const keyOrRoot = key || '.'
  if (isEmpty(a) && isEmpty(b)) return [] //so null and empty string would be considered equal
  if (a === b) return [] //same ref, strings, numbers
  if (a == b) return [] // "2" should equal 2...
  if ((a == null && b != null) || (b == null && a != null)) return [keyOrRoot] //one's null, but not the other
  if (_.isString(a) && _.isString(b) && a.trim() === b.trim()) return [] //ignore whitespace for strings
  if (_.isDate(a)) {
    if (!_.isDate(b)) return [keyOrRoot]
    return a.getTime() === b.getTime() ? [] : [keyOrRoot]
  }
  if (dayjs.isDayjs(a)) {
    if (!dayjs.isDayjs(b)) return [keyOrRoot]
    return a.format() === b.format() ? [] : [keyOrRoot]
  }
  if (Array.isArray(a)) {
    if (!Array.isArray(b)) return [keyOrRoot] //one's an array, but not the other
    if (a.length !== b.length) return [keyOrRoot] //different number of items
    // up to calling code to sort as needed
    const keyOrEmpty = key === null ? '' : `${key}`
    for (let i = 0; i < a.length; i++) {
      //recursively compare array items by index. NOTE if you don't care about sort-order, then pass a sorted array here (sort by your unique key, for instance)
      const subKey = `${keyOrEmpty}[${i}]`
      const diffs = getDifferences(a[i], b[i], ignoreKeys, subKey, [])
      changedKeys = [...changedKeys, ...diffs]
      log(`diffs for ${subKey}`, diffs, a, b)
    }
    return changedKeys
  }
  if (_.isObject(a)) {
    if (!_.isObject(b)) return true //one's an object, but not the other
    const keyOrEmpty = key === null ? '' : `${key}.`
    const allKeys = Object.keys({ ...a, ...b })
    for (const subKey of allKeys) {
      if (!ignoreKeys.includes(subKey)) {
        const subKeyDeep = `${keyOrEmpty}${subKey}`
        const diffs = getDifferences(a[subKey], b[subKey], ignoreKeys, subKeyDeep, [])
        changedKeys = [...changedKeys, ...diffs]
        log(`diffs for ${subKeyDeep}`, diffs, a, b)
      }
    }
    return changedKeys
  }
  return [keyOrRoot] //shouldn't get here, but if it does, default to indicate that there is a difference
}

export function isEmpty(obj) {
  return obj == null || !obj.toString().trim().length
}

function log() {
  // eslint-disable-next-line no-console
  if (window.logDiffs) console.log.apply(console, arguments)
}

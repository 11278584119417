import capitalize from 'lodash-es/capitalize.js'
import chunk from 'lodash-es/chunk.js'
import clone from 'lodash-es/clone.js'
import cloneDeep from 'lodash-es/cloneDeep.js'
import concat from 'lodash-es/concat.js'
import countBy from 'lodash-es/countBy.js'
import debounce from 'lodash-es/debounce.js'
import escapeRegExp from 'lodash-es/escapeRegExp.js'
import extend from 'lodash-es/extend.js'
import filter from 'lodash-es/filter.js'
import find from 'lodash-es/find.js'
import findIndex from 'lodash-es/findIndex.js'
import get from 'lodash-es/get.js'
import groupBy from 'lodash-es/groupBy.js'
import identity from 'lodash-es/identity.js'
import intersection from 'lodash-es/intersection.js'
import invert from 'lodash-es/invert.js'
import isDate from 'lodash-es/isDate.js'
import isObject from 'lodash-es/isObject.js'
import isString from 'lodash-es/isString.js'
import join from 'lodash-es/join.js'
import kebabCase from 'lodash-es/kebabCase.js'
import keyBy from 'lodash-es/keyBy.js'
import maxBy from 'lodash-es/maxBy.js'
import merge from 'lodash-es/merge.js'
import noop from 'lodash-es/noop.js'
import omit from 'lodash-es/omit.js'
import orderBy from 'lodash-es/orderBy.js'
import pickBy from 'lodash-es/pickBy.js'
import range from 'lodash-es/range.js'
import remove from 'lodash-es/remove.js'
import size from 'lodash-es/size.js'
import sortBy from 'lodash-es/sortBy.js'
import stubTrue from 'lodash-es/stubTrue.js'
import stubFalse from 'lodash-es/stubFalse.js'
import sum from 'lodash-es/sum.js'
import sumBy from 'lodash-es/sumBy.js'
import uniq from 'lodash-es/uniq.js'
import uniqBy from 'lodash-es/uniqBy.js'
import values from 'lodash-es/values.js'
import upperFirst from 'lodash-es/upperFirst.js'

export default {
  capitalize,
  chunk,
  clone,
  cloneDeep,
  concat,
  countBy,
  debounce,
  escapeRegExp,
  extend,
  filter,
  find,
  findIndex,
  get,
  groupBy,
  kebabCase,
  identity,
  intersection,
  invert,
  isDate,
  isObject,
  isString,
  join,
  maxBy,
  merge,
  noop,
  omit,
  orderBy,
  pickBy,
  range,
  remove,
  size,
  sortBy,
  stubTrue,
  stubFalse,
  sum,
  sumBy,
  uniq,
  uniqBy,
  upperFirst,
  values,
  keyBy,
}

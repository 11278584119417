<form on:submit|preventDefault|stopPropagation={onSubmit} {method} {autocomplete} bind:this={formEl} class={className} {style}>
  <slot />
  {#if noSubmitBtn}
    <!--if the calling code's form has no submit button, the form will not be submitted upon pressing enter. so put a hidden submit btn-->
    <input type="submit" class="hidden-submit-btn" />
  {/if}
</form>

<script>
  import { createEventDispatcher, setContext } from 'svelte'
  import { writable } from 'svelte/store'
  import validator from 'services/validator.js'

  export let method = 'post'
  export let submitted = false
  export let allowSubmitInvalid = false
  export let autocomplete = null
  export let style = null
  let className = null
  export { className as class }
  export let validationMessagesByFormGroupByFormSection = {}
  export let noSubmitBtn = false

  const dispatch = createEventDispatcher()
  const _submitted = writable(submitted)
  let formGroups = []
  let formEl

  setContext('submitted', _submitted)

  setContext('addValidation', addValidation)

  export function focusFirstInput() {
    if (formEl) {
      const firstInput = formEl.querySelector('input, button, a')
      if (firstInput) firstInput.focus()
    }
  }

  export function getFirstInvalid() {
    setSubmitted(true)
    return formGroups.find(fg => !fg.isValid())
  }

  export function reset() {
    setSubmitted(false)
    for (const fg of formGroups) fg.resetDirty()
  }

  export function validate() {
    const firstInvalid = getFirstInvalid()
    const invalidUnRenderedFormSections = validator.getInvalidFormSections(validationMessagesByFormGroupByFormSection)
    if (invalidUnRenderedFormSections.length) dispatchInvalidSubmit(invalidUnRenderedFormSections)
    if (!allowSubmitInvalid && firstInvalid != null) {
      firstInvalid.getElement().focus()
      return false
    } else if (invalidUnRenderedFormSections.length) {
      return false
    }
    return true
  }

  function setSubmitted(val) {
    submitted = val // so calling code can two-way bind up to itself and know if the form has been submitted
    $_submitted = val
  }

  function addValidation(fg) {
    formGroups = [...formGroups, fg]
    return () => {
      formGroups = formGroups.filter(f => f != fg)
    }
  }

  export function onSubmit() {
    const valid = validate()
    if (valid) dispatch('submit')
  }

  function dispatchInvalidSubmit(invalidUnRenderedFormSections) {
    dispatch('invalidSubmit', invalidUnRenderedFormSections)
  }
</script>

{#if existingUserDocument}
  <UserDocumentAbstractPreviewModal
    userDocument={existingUserDocument}
    {userDocumentSharing}
    {onClose}
    title="Replace document?"
    generalBodyClass="pt1"
  >
    <svelte:fragment slot="body-top">
      <div class="mb1">
        A document named <FileName value={existingUserDocument.nameOriginal} insideOf="sentence" /> already exists in your <Icon
          lg
          name="files"
          class="text-primary"
        /> Document library.
      </div>

      <div>Would you like to replace it or keep both documents?</div>
    </svelte:fragment>

    <svelte:fragment slot="footer">
      <Btn
        class="btn-primary initial-focus"
        on:click={_onAdd}
        dataTest="btn-add-user-document"
        id="btn-add-user-document"
        loading={adding}
        disabled={replacing}>Keep both documents</Btn
      >
      <Btn class="btn-warning" on:click={_onReplace} dataTest="btn-replace-user-document" loading={replacing} disabled={adding}
        >Replace existing document</Btn
      >
      <Btn class="btn-default" on:click={onClose} dataTest="btn-cancel">Cancel</Btn>
    </svelte:fragment>
  </UserDocumentAbstractPreviewModal>
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import FileName from 'components/FileName.svelte'
  import Icon from 'components/Icon.svelte'
  import UserDocumentAbstractPreviewModal from 'components/UserDocumentAbstractPreviewModal.svelte'

  export let existingUserDocument
  export let userDocumentSharing
  export let onAdd
  export let onReplace
  export let onClose

  let adding = false
  let replacing = false

  async function _onAdd() {
    adding = true
    try {
      await onAdd()
    } finally {
      adding = false
    }
  }

  async function _onReplace() {
    replacing = true
    try {
      await onReplace()
    } finally {
      replacing = false
    }
  }
</script>

import {
  candidateSelector,
  focusableCandidateSelector,
  getCandidates,
  getTabIndex,
  isNodeMatchingSelectorTabbable,
  isNodeMatchingSelectorFocusable,
} from './dom-helpers.js'

const tabbable = (node, options) => {
  options = options || {}
  const regularTabbables = []
  const orderedTabbables = []
  const candidates = getCandidates(node, options.includeContainer, isNodeMatchingSelectorTabbable)
  options.preprocessCandidates?.(candidates)
  for (const [i, node] of candidates.entries()) {
    const tabIndex = getTabIndex(node)
    if (tabIndex === 0) {
      regularTabbables.push(node)
    } else {
      orderedTabbables.push({ tabIndex, node, documentOrder: i })
    }
  }
  return [
    ...orderedTabbables.sort((a, b) => (a.tabIndex === b.tabIndex ? a.documentOrder - b.documentOrder : a.tabIndex - b.tabIndex)).map(a => a.node),
    ...regularTabbables,
  ]
}

const focusable = (node, options) => getCandidates(node, !!options?.includeContainer, isNodeMatchingSelectorFocusable)
const isTabbable = elem => elem.matches(candidateSelector) && isNodeMatchingSelectorTabbable(elem)
const isFocusable = elem => elem.matches(focusableCandidateSelector) && isNodeMatchingSelectorFocusable(elem)

export { tabbable, focusable, isTabbable, isFocusable }

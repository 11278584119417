<Alert type="info" noIcon>
  <Icon name="spinner" dataTest="loading" spin fw />
  {message}…
</Alert>

<script>
  import Icon from 'components/Icon.svelte'
  import Alert from 'components/bootstrap/Alert.svelte'

  export let message = 'Loading'
</script>

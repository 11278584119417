{#if conflictedRotations?.length}
  <Alert type="warning">
    {#if $user.isStudent}
      There are potential date conflicts with your rotation schedule.
    {:else}
      There
      {#if conflictedRotations && conflictedRotations.length > 1}are other rotations{:else}is another rotation{/if}
      that may conflict with this one.
    {/if}

    {#if show}
      <div transition:slide|local>
        <Grid let:row rows={conflictedRotations} name="conflicting-details">
          <GridCol {row} header="Status">
            {@const matchStatus = matchStatusHelper.get(row.status)}
            <Icon name={matchStatus.icon} class="text-{matchStatus.color}" title={matchStatus.label} />
            <span class="text-{matchStatus.color}">{matchStatus.label}</span>
          </GridCol>
          <GridCol {row} name="name" header="Name" />
          <GridCol {row} name="orgName" header="Organization" />
          <GridCol {row} name="serviceName" header="Service" />
          <GridCol {row} header="When">
            <FriendlyList items={row.conflictedDates} punctuation let:item>
              {#if item.from}
                every day from {item.from.format('M/D/YYYY')} to {item.to.format('M/D/YYYY')}
              {:else}
                {item}
              {/if}
            </FriendlyList>
          </GridCol>
          <GridCol {row} header="When"><Btn class="btn-sm btn-outline-warning" href={row.matchId}>View</Btn></GridCol>
        </Grid>
      </div>
    {/if}
    <a on:click={() => (show = !show)} href={null} data-test="conflict-details-toggle">
      {#if show}Hide{:else}See{/if}
      details
    </a>
  </Alert>
{/if}

<script>
  import Grid from 'components/Grid.svelte'
  import GridCol from 'components/GridCol.svelte'
  import { slide } from 'svelte/transition'
  import Icon from 'components/Icon.svelte'
  import Alert from 'components/bootstrap/Alert.svelte'
  import matchStatusHelper from 'services/match-status-helper.js'
  import user from 'stores/user.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'

  export let conflictedRotations

  let show = false
</script>

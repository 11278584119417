{#if !editing}I can manage{/if}

<script context="module">
  import { FilterType } from 'config/enums.js'
  const label = 'I can manage'
  export const meta = {
    type: FilterType.CanManage,
    label,
    icon: 'edit',
    iconClass: 'text-gray',
    create: null,
  }
</script>

<script>
  // export let filter
  // export let filterOptions
  export let editing = false
  // export let appliedMeta
</script>

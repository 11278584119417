<div class="flex-column g1 wrap" style={width ? `width: ${width}px` : null}>
  <Alert class="m0" wrapperClass="flex-row flex-align-center g1">
    Please note: Users will be limited to viewing information they have access to. If they have different permissions than you, some or even all of
    the data may not be visible to them.
  </Alert>

  <div class="flex-row flex-align-center g05">
    <Copyable
      name="{name}-share-href"
      value={href}
      valueStyle="overflow-y: auto; flex-grow: 1; word-break: break-all"
      successMsg="Copied link to your clipboard."
    />
  </div>

  <Qrcode value={href} class="flex-row flex-justify-center" />
</div>

<script context="module">
  import { ArchiveActiveFilter } from 'config/enums.js'
  import { orderedViews } from 'config/dashboard-view-types.js'
  import filterBuilder from 'services/filter-builder.js'
  import { CriteriaDecoder, CriteriaEncoder } from 'services/filters/index.js'

  // TODO: Ideally this'd just be `buildShareHref(SavedSearchType.MyStepsReport, criteria)`. And it can figure out a bunch of this meta stuff based on the SavedSearchType.
  //       Calling code currently has to know a butt load about the view it's building a url for...
  export function buildShareHref({
    baseHref,
    filterSets = null,
    criteria = null,
    criteriaConfig = null,
    criteriaDefaults = null,
    sortProperty = null,
    sortAscending = null,
    defaultSortProperty = null,
    defaultSortAscending = null,
    archiveActiveValue = null,
    defaultArchiveActiveValue = null,
    personaFiltersOrgId = null,
    personaFiltersTeamId = null,
    startDate = null,
    endDate = null,
    savedSearchType = null,
  }) {
    const encoded = filterSets
      ?.filter(filter => filter.filters?.length > 0)
      .map(filter => {
        const encodedFilters = filter.filterEncoder?.encode(filter.filters)
        return encodedFilters ? `${filter.prefix ?? ''}filters=${encodedFilters}` : null
      })

    const extraEncoded = new CriteriaEncoder().encode(criteria, criteriaConfig, criteriaDefaults)
    const queryParams = [
      ...(encoded ?? []),
      extraEncoded && `extra=${extraEncoded}`,
      sortProperty != null && sortProperty !== defaultSortProperty && `sort=${sortProperty}`,
      (sortProperty != null && sortProperty !== defaultSortProperty) || (sortAscending !== null && sortAscending !== defaultSortAscending)
        ? `asc=${sortAscending ? '1' : '0'}`
        : null,
      savedSearchType != null && `sst=${savedSearchType}`,
      archiveActiveValue != null && archiveActiveValue !== defaultArchiveActiveValue && `aaf=${archiveActiveValue}`,
      personaFiltersOrgId && `orgId=${personaFiltersOrgId}`,
      personaFiltersTeamId && `teamId=${personaFiltersTeamId}`,
      startDate != null && `start=${startDate}`,
      endDate != null && `end=${endDate}`,
    ]
      .filter(Boolean)
      .join('&')
    const relativeHref = `${baseHref}${queryParams.length ? `?${queryParams}` : ''}`
    const href = `${window.location.origin}${baseHref.startsWith('/') ? relativeHref : `/${relativeHref}`}`
    return href
  }

  export function parseShareHref(
    location,
    {
      filterDecoder,
      sortOptions = [],
      defaultSortProperty = null,
      defaultSortAscending = null,
      defaultArchiveActiveValue = ArchiveActiveFilter.Active,
      slug = null,
    }
  ) {
    const { search } = location
    if (!search.length) return null
    const params = new URLSearchParams(search)
    const aaf = Number.parseInt(params.get('aaf'), 10)
    const sort = params.get('sort')?.toLowerCase()
    const asc = params.get('asc')
    const startDate = params.get('start')
    const endDate = params.get('end')
    const orgId = params.get('orgId')
    const teamId = params.get('teamId')
    const orgIdParsed = Number.parseInt(orgId, 10)
    const teamIdParsed = Number.parseInt(teamId, 10)
    const savedSearchType = params.get('sst')
    const savedSearchTypeParsed = Number.parseInt(savedSearchType, 10)
    const selectedView = orderedViews.find(v => v.savedSearchType === savedSearchTypeParsed) || orderedViews.find(v => v.slug === slug)

    let sstFiltersConfig = selectedView?.filtersConfig

    if (filterDecoder) {
      sstFiltersConfig = [
        {
          name: 'filters',
          filterDecoder,
        },
      ]
    }

    const encodedFilters = sstFiltersConfig?.map(f => {
      return {
        ...f,
        encodeFilter: params.get((f.prefix ?? '') + 'filters'),
        ...filterBuilder(f.type),
      }
    })

    const sortProperty = sort
      ? sortOptions.find(o => o.sortProperty.toString().toLowerCase() === sort)?.sortProperty ?? defaultSortProperty
      : defaultSortProperty
    const sortOption = sortOptions.find(o => o.sortProperty === sortProperty)
    const forcedSortAscending = sortOption?.allowAscending === false ? false : sortOption?.allowDescending === false ? true : null

    const criteriaFilters = {}
    if (encodedFilters) {
      for (const filter of encodedFilters) {
        if (filter.encodeFilter) {
          criteriaFilters[filter.name] = filter.filterDecoder.decode(filter.encodeFilter)
        }
      }
    }

    const criteriaDecoder = new CriteriaDecoder()
    const extra = criteriaDecoder.decode(params.get('extra'), selectedView?.criteriaConfig)

    // If they're hacking the URL, let's only allow a sane year range...
    extra.month = extra.month && /(20\d\d)([/-])(0\d|1[0-2])$/.test(extra.month) ? extra.month : null

    const newUrl = window.location.origin + window.location.pathname
    window.history.replaceState({}, '', newUrl)

    return {
      ...criteriaFilters,
      archiveActiveValue: Object.values(ArchiveActiveFilter).includes(aaf) ? aaf : defaultArchiveActiveValue,
      sortProperty,
      sortAscending: forcedSortAscending ?? (asc === '1' ? true : asc === '0' ? false : defaultSortAscending),
      startDate,
      endDate,
      orgId: Number.isNaN(orgIdParsed) ? null : orgIdParsed,
      teamId: Number.isNaN(teamIdParsed) ? null : teamIdParsed,
      savedSearchType: Number.isNaN(savedSearchTypeParsed) ? selectedView?.savedSearchType : savedSearchTypeParsed,
      ...extra,
    }
  }
</script>

<script>
  import Alert from 'components/bootstrap/Alert.svelte'
  import Copyable from 'components/Copyable.svelte'
  import Qrcode from 'components/Qrcode.svelte'

  export let name
  export let href
  export let width = 400
</script>

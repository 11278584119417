<Icon {name} title={{ content, options: tipOptions }} class="color-text-steel" {...iconProps} />

<script>
  import Icon from 'components/Icon.svelte'

  export let capacity
  export let iconProps = {}
  export let tipOptions = {}

  let name = null
  let content = null

  $: if (capacity.allowGroups) {
    name = 'users'
    content = 'Group opportunity'
  } else {
    name = 'user'
    content = 'Individual opportunity'
  }
</script>

<Grid
  let:row
  {loading}
  rows={services}
  name="services-grid"
  pagesOfLabelSingular="service"
  {pageSize}
  bind:currentPage
  paginatorTotalCount={totalCount}
  href={$isQuickFiltering ? null : viewOrEditHref}
  hrefButtonIconSelector={row => (personaService.canEditService(row) ? 'edit' : 'eye')}
  cellClassSelector={middleIfWouldLookGood}
>
  <GridCol {row} header="Service" class="shrink">
    <a href={fromTemplate(viewOrEditHref, row)}>{row.name}</a>
    {#if show.description}
      <LongDesc text={row.descriptionHtml} />
    {/if}

    {#if show.specialties}
      <div class="flex-row flex-align-center flex-wrap g05">
        {#if row.specialties?.length}
          {#each row.specialties as s}
            <QuickFilterTag
              icon="file-certificate"
              class="text-specialty"
              value={s}
              type="Specialty"
              {filtersComponent}
              filterType={FilterType.Specialties}
              configKey="specialties"
              configValue={s}
              simulationFilterOption={simpleFilterOption(s)}
              dataTest="specialty-quick-filter-tag-{s}"
            />
          {/each}
        {:else}
          <QuickFilterTag
            icon="file-certificate"
            class="text-specialty"
            value="No specialties"
            tooltipFilterByPrefix="Filter by"
            type="No specialties"
            {filtersComponent}
            filterType={FilterType.Specialties}
            configKey="specialties"
            configValue={null}
            simulationFilterOption={{
              optionValue: null,
              optionLabel: 'No specialties',
            }}
            dataTest="specialty-quick-filter-tag-none"
          >
            <em class="small text-gray">No specialties</em>
          </QuickFilterTag>
        {/if}
      </div>
    {/if}
  </GridCol>

  {#if show.organization}
    <GridCol {row} header="Organization" class="shrink">
      <QuickFilterTag
        icon="hospital"
        class="color-text-orange"
        value={row.orgName}
        type="Organization"
        href="/orgs/{row.orgId}/{row.orgRelativePath}"
        {filtersComponent}
        filterType={FilterType.HostOrgs}
        configKey="orgIds"
        configValue={row.orgId}
        simulationFilterOption={{
          orgId: row.orgId,
          name: row.orgName,
          orgRelativeName: row.orgRelativeName,
          profilePicture: row.orgProfilePicture,
        }}
        dataTest="org-quick-filter-tag-{row.orgName}"
      >
        <OrgProfilePictureAndName
          name={row.orgName}
          profilePicture={row.orgProfilePicture}
          relativeName={row.orgRelativeName}
          contentClass="text-left"
        />
      </QuickFilterTag>
    </GridCol>
  {/if}

  {#if show.team && anyRowsHaveTeam}
    <GridCol {row} header="Team" class="shrink">
      <QuickFilterTag
        icon="users"
        class="color-text-orange"
        value={row.teamName}
        tooltipFilterByPrefix={row.teamId ? null : 'Filter by'}
        type={row.teamId ? 'Team' : 'All teams'}
        href={row.teamId ? `/settings/teams/${row.teamRelativePath}` : null}
        {filtersComponent}
        filterType={FilterType.Teams}
        configKey="teamIds"
        configValue={row.teamId ?? null}
        simulationFilterOption={{
          teamId: row.teamId ?? null,
          name: row.teamName,
          orgId: row.teamOrgId ?? null,
        }}
        dataTest="team-quick-filter-tag-{row.teamName}"
      >
        <TeamProfilePictureAndName small orgId={row.teamOrgId} name={row.teamName} />
      </QuickFilterTag>
    </GridCol>
  {/if}

  {#if show.capacities}
    <GridCol {row} name="activeCapacityCount" header="Active opportunities">
      <QuickFilterTag
        icon="shapes"
        class="color-text-purple"
        type="Service"
        filterType={FilterType.Services}
        configKey="serviceIds"
        configValue={row.serviceId}
        noFilterActions
        linkToCapacitiesPage
      >
        <ActiveCapacityCount name="active-capacity" count={row.activeCapacityCount} iconFirst />
      </QuickFilterTag>
    </GridCol>
  {/if}

  {#if show.disciplines}
    <GridCol {row} header="Who is it for?">
      {@const rowDisciplines = computeServiceDisciplines(row)}
      <div class="flex-column g05">
        {#if show.disciplines}
          {#if rowDisciplines?.length}
            <div class="flex-row flex-align-center flex-wrap g05">
              {#each rowDisciplines as d}
                <QuickFilterTag
                  icon="book"
                  class="color-text-teal"
                  value={d}
                  type="Discipline"
                  {filtersComponent}
                  filterType={FilterType.Disciplines}
                  configKey="disciplines"
                  configValue={d}
                  simulationFilterOption={simpleFilterOption(d)}
                  dataTest="discipline-quick-filter-tag-{d}"
                />
              {/each}
            </div>
          {:else}
            <QuickFilterTag
              icon="book"
              class="color-text-teal"
              value="Any discipline"
              tooltipFilterByPrefix="Filter by"
              type="Allows any discipline"
              {filtersComponent}
              filterType={FilterType.Disciplines}
              configKey="disciplines"
              configValue={null}
              simulationFilterOption={{
                optionValue: null,
                optionLabel: 'Any discipline',
              }}
              dataTest="discipline-quick-filter-tag-any"
            >
              <em class="small text-gray">Any discipline</em>
            </QuickFilterTag>
          {/if}
        {/if}
      </div>
    </GridCol>
  {/if}

  {#if show.agreements}
    <GridCol {row} header="Agreements">
      {#if row.agreements.length}
        <FriendlyList
          allowShowFewer
          items={row.agreements}
          let:item
          max={show.agreementCount ?? Infinity}
          maxIsExplicit
          otherPrefix={show.agreementCount === 0 ? 'Show ' : ''}
          other={show.agreementCount === 0 ? 'agreement' : 'other'}
          otherPlural={show.agreementCount === 0 ? 'agreements' : 'others'}
        >
          <QuickFilterTag
            icon="agreement"
            iconProps={{ lg: true, autoColor: true }}
            value={item.name}
            type="Agreement"
            href="/settings/agreements/{item.agreementId}"
            {filtersComponent}
            filterType={FilterType.Agreements}
            configKey="agreementIds"
            configValue={item.agreementId}
            simulationFilterOption={item}
            dataTest="agreement-quick-filter-tag-{item.name}"
          >
            <AgreementProfilePicturesAndName
              noTooltip
              name={item.name}
              orgName={item.orgName}
              orgRelativeName={item.orgRelativeName}
              orgProfilePicture={item.orgProfilePicture}
              teamName={item.teamName}
              teamOrgId={item.teamOrgId}
              schoolName={item.schoolName}
              schoolRelativeName={item.schoolRelativeName}
              schoolProfilePicture={item.schoolProfilePicture}
              schoolTeamName={item.schoolTeamName}
              schoolTeamOrgId={item.schoolTeamOrgId}
            />

            <div slot="tooltip" class="flex-column mt1">
              <div class="flex-row flex-align-center g05 strong">
                <Icon name="hospital" class="color-text-orange" />
                <span>Health organization</span>
              </div>
              <!-- Specifying class to drop the nowrap. -->
              <OrgProfilePictureAndName
                name={item.orgName}
                relativeName={item.orgRelativeName}
                profilePicture={item.orgProfilePicture}
                class="flex-row flex-align-center"
              />
              {#if item.teamName}
                <TeamProfilePictureAndName name={item.teamName} orgId={item.teamOrgId} class="mt05" />
              {/if}

              <div class="flex-row flex-align-center g05 strong mt1">
                <Icon name="school" class="color-text-blue" />
                <span>School</span>
              </div>
              <!-- Specifying class to drop the nowrap. -->
              <OrgProfilePictureAndName
                name={item.schoolName}
                relativeName={item.schoolRelativeName}
                profilePicture={item.schoolProfilePicture}
                class="flex-row flex-align-center"
              />
              {#if item.schoolTeamName}
                <TeamProfilePictureAndName name={item.schoolTeamName} orgId={item.schoolTeamOrgId} class="mt05" />
              {/if}
            </div>
          </QuickFilterTag>
        </FriendlyList>
      {:else}
        <QuickFilterTag
          icon="agreement"
          iconProps={{ lg: true, autoColor: true }}
          value="Any agreement"
          tooltipFilterByPrefix="Filter by"
          type="Allows any agreement"
          {filtersComponent}
          filterType={FilterType.Agreements}
          configKey="agreementIds"
          configValue={null}
          simulationFilterOption={{
            agreementId: null,
            name: 'Any agreement',
          }}
          dataTest="agreement-quick-filter-tag-any"
        >
          <em class="small text-gray">Any agreement</em>
        </QuickFilterTag>
      {/if}
    </GridCol>
  {/if}

  <GridCol {row} class="text-right nowrap">
    <OverflowMenu dataTest="overflow-actions-{row.serviceId}">
      <ServiceActions all service={row} {viewOrEditHref} {utilizationHref} {staffHref} {copyHref} {onDeleteClicked} />
    </OverflowMenu>
  </GridCol>
</Grid>

<script>
  import { FilterType } from 'config/enums.js'
  import { fromTemplate } from 'services/string-utils.js'
  import ActiveCapacityCount from 'components/ActiveCapacityCount.svelte'
  import AgreementProfilePicturesAndName from 'components/AgreementProfilePicturesAndName.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Grid from 'components/Grid.svelte'
  import GridCol from 'components/GridCol.svelte'
  import Icon from 'components/Icon.svelte'
  import isQuickFiltering from 'stores/is-quick-filtering.js'
  import LongDesc from 'components/LongDesc.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import OverflowMenu from 'components/OverflowMenu.svelte'
  import personaService from 'services/persona-service.js'
  import QuickFilterTag from 'components/QuickFilterTag.svelte'
  import ServiceActions from 'components/ServiceActions.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import TeamProfilePictureAndName from 'components/TeamProfilePictureAndName.svelte'
  import { computeServiceDisciplines } from './InheritedTagPicker.svelte'

  export let services = null
  export let totalCount = null
  export let loading = false
  export let viewOrEditHref = null
  export let utilizationHref = null
  export let staffHref = null
  export let copyHref = null
  export let onDeleteClicked = _.noop
  export let currentPage = 1
  export let pageSize = 10
  export let filtersComponent = null

  $: show = $showDropdowns.serviceGrid
  $: anyRowsHaveTeam = services?.some(s => s.teamName != null)

  function simpleFilterOption(optionValue) {
    return { optionLabel: optionValue, optionValue }
  }

  // TODO: Would be nice to incorporate `show` into this decision,
  // but we'd need a way to make `<Grid>` react to changes in `show`.
  function middleIfWouldLookGood(row) {
    return row.descriptionHtml || row.specialties?.length > 0 || row.disciplines?.length > 1 || row.agreements.length > 1 ? '' : 'middle'
  }
</script>

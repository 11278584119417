<div class="flex-row flex-align-center mb05">
  <InputCheckbox
    bind:checked={includeCapacitiesOpenToAnyGuestWithMatches}
    name="include-all-open-orgs"
    class="flex-row flex-align-center m0 normal"
    labelClass="flex-row flex-align-center m0 normal"
  >
    <span class="mr05">Include opportunities open to any school with rotations in</span>
  </InputCheckbox>

  <QuickDropdown value="Any status">
    <div slot="label">
      {#if !capacitiesOpenToAnyGuestWithStatus.length || capacitiesOpenToAnyGuestWithStatus.length === options.length}
        <span>any status</span>
      {:else}
        <FriendlyList or punctuation boldPunctuation={false} toggleable={false} items={capacitiesOpenToAnyGuestWithStatus} let:item>
          <EnumIconLabel config={matchStatusHelper.get(item)} class="strong" />
        </FriendlyList>
      {/if}
    </div>
    <div class="p1">
      <InputCheckboxGroup
        name="statuses"
        class="m1"
        {options}
        valueSelector={o => o.key}
        bind:value={capacitiesOpenToAnyGuestWithStatus}
        on:change={() => updateSelectedMatchStatuses(capacitiesOpenToAnyGuestWithStatus)}
        let:option
        autofocusFirstItem
      >
        <EnumIconLabel config={option} class="normal" />
      </InputCheckboxGroup>
    </div>
  </QuickDropdown>
</div>

<script>
  import FriendlyList from 'components/FriendlyList.svelte'
  import EnumIconLabel from 'components/EnumIconLabel.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import InputCheckboxGroup from 'components/fields/InputCheckboxGroup.svelte'
  import matchStatusHelper from 'services/match-status-helper'
  import persona from 'stores/persona.js'
  import QuickDropdown from 'components/QuickDropdown.svelte'

  export let includeCapacitiesOpenToAnyGuestWithMatches = false
  export let selectedMatchStatuses

  $: options = matchStatusHelper.getOptions($persona)

  let capacitiesOpenToAnyGuestWithStatus = []

  function updateSelectedMatchStatuses(capacitiesOpenToAnyGuestWithStatus) {
    selectedMatchStatuses = capacitiesOpenToAnyGuestWithStatus
  }
</script>

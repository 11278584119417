{#if isDetailsView}
  {#if rowDisciplines.length}
    {#each rowDisciplines as discipline}
      <div><Icon name="book" class="color-text-teal" /> {discipline}</div>
    {/each}
  {:else}
    <div class="flex-row flex-align-center g05">
      <Icon name="book" class="color-text-teal" /><em class="small text-gray">Any discipline</em>
    </div>
  {/if}
{:else if rowDisciplines.length}
  <FriendlyList
    items={rowDisciplines}
    let:item
    max={show.disciplineCount ?? Infinity}
    otherPrefix={show.disciplineCount === 0 ? 'show' : ''}
    other={show.disciplineCount === 0 ? 'discipline' : 'other'}
    otherPlural={show.disciplineCount === 0 ? 'disciplines' : 'others'}
  >
    <QuickFilterTag
      icon="book"
      class="color-text-teal"
      value={item}
      type="Discipline"
      {filtersComponent}
      filterType={FilterType.Disciplines}
      configKey="disciplines"
      configValue={item}
      simulationFilterOption={simpleFilterOption(item)}
      dataTest="discipline-quick-filter-tag-{item}"
    >
      <svelte:fragment slot="tooltip">
        {#if !capacity.disciplines.length}
          {#if capacity?.serviceDisciplines?.length}
            <div class="em mt1">Inherited from <Icon name="shapes" class="color-text-purple" /> {capacity.service.name}</div>
          {/if}
          {#if capacity?.agreementDisciplines?.length}
            <div class="em mt1">Inherited from <Icon name="agreement" class="color-text-purple" /> {capacity.agreement.name}</div>
          {/if}
          {#if capacity?.teamDisciplines?.length}
            <div class="em mt1">Inherited from <Icon name="users" class="color-text-orange" /> {capacity.teamName}</div>
          {/if}
        {/if}
      </svelte:fragment>
    </QuickFilterTag>
  </FriendlyList>
{:else}
  <QuickFilterTag
    icon="book"
    class="color-text-teal"
    value="Any discipline"
    type="Allows any discipline"
    tooltipFilterByPrefix="Filter by"
    {filtersComponent}
    filterType={FilterType.Disciplines}
    configKey="disciplines"
    configValue={null}
    simulationFilterOption={{
      optionValue: null,
      optionLabel: 'Any discipline',
    }}
    dataTest="discipline-quick-filter-tag-any"
  >
    <div class="flex-row flex-align-center g05">
      <Icon name="book" class="color-text-teal" /><em class="small text-gray">Any discipline</em>
    </div>
  </QuickFilterTag>
{/if}

<script>
  import { FilterType } from 'config/enums.js'
  import { computeCapacityDisciplines } from 'components/InheritedTagPicker.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Icon from 'components/Icon.svelte'
  import QuickFilterTag from 'components/QuickFilterTag.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import { simpleFilterOption } from 'services/capacity-usage.js'

  export let capacity
  export let filtersComponent = null
  export let isDetailsView = false

  $: rowDisciplines = computeCapacityDisciplines(capacity)
  $: show = $showDropdowns.capacityGrid
</script>

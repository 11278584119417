<div class="basic-info-container flex-row flex-wrap flex-justify-between full-width">
  <Collapsible label="Opportunity details" name="match-modal-basic" noContent={!hasServiceDesc && !hasCapacityDesc}>
    <div slot="label">
      <div class="basic-info flex-row flex-align-start flex-wrap g1">
        <div class="flex-row flex-align-center g1 flex-wrap">
          <h3 class="org flex-row flex-align-center g1 m0 mr1">
            <OrgProfilePictureAndName
              name={match.org.name}
              profilePicture={match.org.profilePicture}
              href={match.org.url}
              relativeName={match.org.relativeName}
              size="default"
            />
          </h3>

          {#if !isUncategorized}
            {#if canEditService}
              <IconTextLink
                icon="shapes"
                iconProps={{ class: 'color-text-purple' }}
                text={match.service.name}
                dataTest="edit-service"
                onClick={e => {
                  e.stopPropagation()
                  unsavedForms.navigateSafe(`/services/${match.service.serviceId}`)
                }}
                class="service normal"
              />
            {:else if match.serviceId}
              <div class="flex-row flex-align-center g05">
                <Icon name="shapes" class="color-text-purple" />
                <span>{match.service.name}</span>
              </div>
            {/if}

            <span class="spacer">|</span>
            {#if canEditCapacity}
              <IconTextLink
                icon="list"
                iconProps={{ class: 'color-text-purple' }}
                text={match.capacity.name}
                dataTest="edit-capacity"
                onClick={e => {
                  e.stopPropagation()
                  unsavedForms.navigateSafe(`/opportunities/${match.capacityId}`)
                }}
                class="capacity normal"
              />
            {:else}
              <div class="flex-row flex-align-center g05">
                <Icon name="list" class="color-text-purple" />
                <span>{match.capacity.name}</span>
              </div>
            {/if}
          {/if}

          <TagCloud inline ctx={match} />

          {#each match.locations as l, i (l.orgId)}
            {#if l.orgId !== match.org.orgId}
              <!--
              Add extra margin between locations so the profile picture is more obviously associated with the correct org name.
              Add the margin to the right side, otherwise when an org wraps, it looks stupid because it it's indented compared to the
              other wrapped rows of flex-items.
            -->
              <div class="match-location">
                <OrgProfilePictureAndName
                  name={l.name}
                  profilePicture={l.profilePicture}
                  href={l.relativePath}
                  relativeName={l.relativeName}
                  size="medium"
                />
              </div>
            {/if}
          {/each}

          {#if match.customTags?.length}
            {#each match.customTags as customTag (customTag.customTagId)}
              <CustomTag {customTag} />
            {/each}
          {/if}

          {#if match.course?.name}
            {#if canEditCourse}
              <IconTextLink
                icon="course"
                iconProps={{ class: 'color-text-steel' }}
                text={match.course.name}
                dataTest="edit-course"
                onClick={e => {
                  e.stopPropagation()
                  unsavedForms.navigateSafe(`/courses/${match.courseId}`)
                }}
              />
            {:else}
              <div class="flex-row flex-align-center g05">
                <Icon name="course" class="color-text-steel" />
                <span>{match.course.name}</span>
              </div>
            {/if}
          {/if}
        </div>

        {#if match.isCoordinator || match.isSchoolCoordinator}
          <div class="flex-grow flex-row flex-align-center flex-justify-end" style="height: 50px" on:click|stopPropagation>
            <OverflowMenu dataTest="match-overflow-actions">
              <Btn
                color="outline-gray"
                icon="list"
                iconProps={{ class: 'color-text-purple' }}
                dataTest="change-capacity"
                disabled={!canChangeCapacity}
                on:click={() => (isChangingCapacity = true)}
              >
                {#if isUncategorized}Choose opportunity{:else}Use a different opportunity{/if}
              </Btn>

              <Btn
                color="outline-gray"
                icon="tag"
                iconProps={{ class: 'color-text-light-brown' }}
                dataTest="manage-custom-tags"
                disabled={!match.isCoordinator && !match.isSchoolCoordinator}
                on:click={() => (isManagingCustomTags = true)}
              >
                Manage custom tags

                {#if !personaService.canUseAnyFeatureType(FeatureType.CustomTags)}
                  <span class="ml2"><RequiresUpgrade /></span>
                {/if}
              </Btn>

              <!-- for now, don't even show this option if the org doesn't have the feature--later on, when more school functionality built out and we show the course left nav, we can show this -->
              {#if canEditCourse && hasCourseFeature}
                <Btn
                  color="outline-gray"
                  icon="course"
                  iconProps={{ class: 'color-text-steel' }}
                  dataTest="manage-course"
                  on:click={() => (isManagingCourse = true)}
                >
                  {#if match.course}Change{:else}Set{/if} course

                  {#if !hasCourseFeature}
                    <span class="ml2"><RequiresUpgrade /></span>
                  {/if}
                </Btn>
              {/if}
            </OverflowMenu>
          </div>
        {/if}
      </div>
    </div>

    <div>
      {#if hasServiceDesc}
        <SafeHtml value={match.service.descriptionHtml} />
      {/if}
      {#if hasCapacityDesc}
        <SafeHtml value={match.capacity.descriptionHtml} />
      {/if}
    </div>
  </Collapsible>
</div>

{#if isChangingCapacity}
  <SwitchCapacity {match} {onMatchChanged} onClose={() => (isChangingCapacity = false)} />
{/if}
{#if isManagingCourse}
  <SwitchCourse {match} {onMatchChanged} onClose={() => (isManagingCourse = false)} />
{/if}
{#if isManagingCustomTags}
  <ManageCustomTags bind:match onClose={() => (isManagingCustomTags = false)} />
{/if}

<script>
  import { FeatureType, MatchStatus, Permission } from 'config/enums.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import TagCloud from 'components/TagCloud.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import CustomTag from 'components/CustomTag.svelte'
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import ManageCustomTags from 'components/MatchModal.ManageCustomTags.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import OverflowMenu from 'components/OverflowMenu.svelte'
  import personaService from 'services/persona-service.js'
  import RequiresUpgrade from 'components/RequiresUpgrade.svelte'
  import SafeHtml from 'components/SafeHtml.svelte'
  import SwitchCapacity from 'components/MatchModal.SwitchCapacity.svelte'
  import SwitchCourse from 'components/MatchModal.SwitchCourse.svelte'
  import validator from 'services/validator.js'
  import unsavedForms from 'stores/unsaved-forms.js'

  export let match
  export let onMatchChanged

  let isChangingCapacity = false
  let isManagingCustomTags = false
  let isManagingCourse = false

  $: hasServiceDesc = match.serviceId && !validator.empty(match.service.descriptionHtml)
  $: hasCapacityDesc = !validator.empty(match.capacity.descriptionHtml)
  $: canEditService =
    match.serviceId &&
    personaService.hasStaffPermission(Permission.ManageOpportunitiesAndServices, match.hostOrgId, match.hostTeamId, {
      serviceId: match.serviceId,
    })
  $: canEditCapacity = personaService.hasStaffPermission(Permission.ManageOpportunitiesAndServices, match.hostOrgId, match.hostTeamId, {
    capacityId: match.capacityId,
  })
  $: canChangeCapacity = match.isCoordinator && !(match.status === MatchStatus.Unsubmitted || match.status === MatchStatus.Closed)
  $: canEditCourse = personaService.hasStaffPermission(Permission.ManageCourses, match.guestOrgId, match.guestTeamId) // later, when students can view courses, they should get a link to the course too
  $: hasCourseFeature = personaService.canUseAnyFeatureType(FeatureType.Courses)
  $: isUncategorized = match.apiMetaData && match.service?.name.toLowerCase() === 'uncategorized'
</script>

<style lang="scss">
  .spacer {
    color: #bbb;
  }

  .match-location:not(:last-child) {
    margin-right: 20px;
  }

  @media only screen and (max-width: 600px) {
    .spacer {
      display: none;
    }
  }
</style>

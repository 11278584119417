{#if showLeft || showRight}
  <div class="details-container mt1" class:no-left={!showLeft}>
    {#if showLeft}
      <div class="flex-column g1 details-left">
        {#if show.email}
          <StudentEmailsWithConfirmedStatuses {student} />
        {/if}

        <StudentYearDisciplineLabel {student} showYear={show.year} showDiscipline={show.discipline}>
          <StudentPickerStudentYearWarning {student} {capacityStudentYears} />
        </StudentYearDisciplineLabel>

        {#if show.graduationDate}
          <StudentGraduationDateLabel {student} />
        {/if}

        {#if show.customTags && $persona.personaType === PersonaType.SchoolStaff}
          <div class="flex-row flex-align-center g05">
            <FriendlyList items={student.studentRecords.filter(sr => sr.tags != null).flatMap(sr => sr.tags)} let:item={customTag}>
              <CustomTag {customTag} />
            </FriendlyList>
          </div>
        {/if}

        {#if show.stepSummary}
          <StudentStepSummary userId={student.userId} studentRecord={student.studentRecords[0]} />
        {/if}
      </div>
    {/if}

    {#if showRight}
      <div class="flex-column g2 details-right">
        <div class="student-school">
          <div class="flex-row flex-align-center g05 mb05">
            <Icon name="school" class="color-text-blue" dataTest="school-icon" />
            <h4 class="leading-none m0">Student at</h4>
          </div>

          <StudentSchools {student} />
        </div>
      </div>
    {/if}
  </div>
{/if}

<script>
  import { PersonaType } from 'config/enums.js'
  import CustomTag from 'components/CustomTag.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Icon from 'components/Icon.svelte'
  import persona from 'stores/persona.js'
  import showDropdowns from 'stores/show-dropdowns.js'
  import StudentEmailsWithConfirmedStatuses from 'components/StudentEmailsWithConfirmedStatuses.svelte'
  import StudentGraduationDateLabel from 'components/StudentGraduationDateLabel.svelte'
  import StudentPickerStudentYearWarning from 'components/fields/StudentPicker.StudentYearWarning.svelte'
  import StudentSchools from 'components/StudentSchools.svelte'
  import StudentStepSummary from 'components/StudentStepSummary.svelte'
  import StudentYearDisciplineLabel from 'components/StudentYearDisciplineLabel.svelte'

  export let student = null
  export let capacityStudentYears = []
  // export let isSelected
  // export let isHovered

  $: show = $showDropdowns.studentPicker
  $: showLeft = show.year || show.discipline || show.graduationDate || show.email || show.tags
  $: showRight = show.school
</script>

<style lang="scss">
  .details-container {
    display: grid;
    gap: 20px;
    // TODO(student): Make responsive
    grid-template-columns: auto 320px;
    grid-template-rows: auto;
    grid-template-areas: 'left right';

    &.no-left {
      grid-template-columns: auto;
      grid-template-areas: 'right';
    }
  }

  .details-left {
    grid-area: left;
  }

  .details-right {
    grid-area: right;
  }
</style>

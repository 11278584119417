<div class="mb1">
  <FileTypeDescriptions fileTypes={acceptableFileTypes} />
</div>
{#if canUseUserDocuments}
  <UserDocumentsUploadForm
    loading={adding}
    onFileUploadReady={onUploadFile}
    onDocumentUploadReady={onUploadDocument}
    {acceptableFileTypes}
    {checkGrid}
  />
{:else}
  <FileUpload
    loading={adding}
    id="fileUpload{fileUploadId}"
    {acceptableFileTypes}
    label={files?.length ? 'Add file' : 'Choose file'}
    onFileUploadReady={onUploadFile}
    class="btn-sm"
    color="primary"
  />
{/if}

<script>
  import FileTypeDescriptions from 'components/FileTypeDescriptions.svelte'
  import UserDocumentsUploadForm from 'components/UserDocumentsUploadForm.svelte'
  import FileUpload from 'components/fields/FileUpload.svelte'
  import persona from 'stores/persona.js'

  export let acceptableFileTypes
  export let adding
  export let onUploadFile
  export let onUploadDocument
  export let fileUploadId
  export let files

  $: canUseUserDocuments = $persona.links.some(l => l.slug === 'documents')

  function checkGrid(gridValues) {
    return gridValues.map(value => {
      return { ...value, disabled: files?.map(file => file.userDocumentId).includes(value.userDocumentId) }
    })
  }
</script>

import { ComparisonType } from 'config/enums.js'
import checkConditionValue from 'services/check-condition-value.js'

const fieldTypesUsingMultipleValues = new Set(['select', 'radiogroup', 'checkboxgroup'])

function checkCondition(formConfig, condition, values, fieldTrace) {
  let value = values[condition.fieldName]
  if (value == null) value = ''

  const conditionField = formConfig.fields.find(f => f.name == condition.fieldName)
  // fail condition if the field we're supposed to check isn't even present, unless it's just checking IsEmpty
  if (conditionField == null) return condition.comparisonType == ComparisonType.IsEmpty

  // recursively check if a dependent field is not displaying, then this field condition shouldn't be checked
  if (conditionField.conditions?.length) {
    const circularRef = fieldTrace.includes(condition.fieldName) // api validation will catch circular references, but in case someone inserted directly into db. I also didn't bother checking prod data for form field circular refs when I fixed this bug locally.
    if (circularRef || !shouldFieldDisplay(formConfig, conditionField, values, [...fieldTrace, conditionField.name])) return false
  }

  const isMultipleValues = fieldTypesUsingMultipleValues.has(conditionField.type)
  return checkConditionValue(value, condition.comparisonType, condition.value, isMultipleValues ? condition.values : null)
}

// if this changes, probably change `FormService.cs`
function shouldFieldDisplay(formConfig, fieldConfig, values, fieldTrace) {
  if (fieldConfig.conditions == null || fieldConfig.conditions.length == 0) return true

  fieldTrace ??= []
  // one or all conditions met, depending on and vs or
  const conditionsMet = fieldConfig.conditionalAnd
    ? fieldConfig.conditions.every(condition => checkCondition(formConfig, condition, values, fieldTrace))
    : fieldConfig.conditions.some(condition => checkCondition(formConfig, condition, values, fieldTrace))

  return fieldConfig.hideIfConditionsMet ? !conditionsMet : conditionsMet
}

function throwError(formConfig) {
  // Throw an error with a more useful message, otherwise Svelte just complains it can't #each over non-array-like objects.
  // Making this soft-fail by returning an [] instead would just mask an underlying issue.
  throw new Error(`<DynamicForm> expected config.fields to be an array. But it was: ${JSON.stringify(formConfig.fields)}`)
}

export function getVisibleFields(formConfig, values) {
  return formConfig.fields?.filter(fieldConfig => shouldFieldDisplay(formConfig, fieldConfig, values)) ?? throwError(formConfig)
}

import { isEmpty } from 'services/object-utils.js'
import domainRegex from 'services/domain-regex.js'

// list of strings => "item1, item2, itemN, and itemLast" or "item1 and item2" or "item1"
export function toFriendlyList(arr, maxDisplayed = 3, or = false) {
  arr = arr == null ? [] : arr.filter(v => v != null)
  const count = arr.length
  if (count === 0) return ''
  if (count === 1) return arr[0]
  const andOr = or ? 'or' : 'and'
  if (count === 2) return arr.join(` ${andOr} `)
  const comma = maxDisplayed > 1 ? ',' : ''
  if (count > maxDisplayed + 1)
    // plus 1 so it just shows the last item instead of saying "1 other" awkwardly
    return `${arr.slice(0, maxDisplayed).join(', ')}${comma} ${andOr} ${count - maxDisplayed} others`

  const allButLast = arr.slice(0, count - 1)
  return `${allButLast.join(', ')}${comma} ${andOr} ${arr[count - 1]}`
}

export function partialDesc(longDesc, numChars = 150) {
  const noHtml = strip(longDesc).trim()
  if (isEmpty(noHtml)) return ''
  const shortEnough = noHtml.length <= numChars
  if (shortEnough) return longDesc
  // could handle html better, but this will work well enough for now--just might result in anchor text that is just shown raw
  return noHtml.slice(0, numChars - 1).trimEnd() + '…'
}

export function strip(html) {
  // https://stackoverflow.com/questions/822452/strip-html-from-text-javascript
  const tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || ''
}

// make search term safe for being a route parameter
export function toRouteSearchTerm(q) {
  if (isEmpty(q)) return ''
  q = q.replaceAll('/', ' ') // replace slashes with spaces as well, so don't run into routing awkwardness. Search results will still work the same
  q = encodeURIComponent(q)
  return q
}

// convert byte file sizes to "1.4MB" etc
export function humanFileSize(size) {
  if (size <= 0) return 'empty'
  const i = Math.floor(Math.log(size) / Math.log(1024))
  return (size / Math.pow(1024, i)).toFixed(2) * 1 + ' ' + ['B', 'KB', 'MB', 'GB', 'TB'][i]
}

export function readableNumber(countable) {
  const count = getCount(countable)
  return Intl.NumberFormat().format(count)
}

export function formatMoney(num, explicitDecmials = true) {
  // TODO: left off--need ability to specify whether you want "$1.00" or "$1"...
  const numAbs = Math.abs(num)
  const numRounded = explicitDecmials ? numAbs.toFixed(2) : numAbs
  let formatted = Intl.NumberFormat().format(numRounded)
  // 1 --> 1.00 (only if calling code wants to)
  if (explicitDecmials && !formatted.includes('.')) formatted += '.00'
  // 1.9 --> 1.90 (always)
  if (/\.\d$/.test(formatted)) formatted += '0'
  // 1.333 --> 1.33 (get rid of extra decimals)
  if (!explicitDecmials && /\.\d{3}/.test(formatted)) formatted = formatted.replace(/(\.\d{2})\d+/, '$1')
  return num < 0 ? '-$' + formatted : '$' + formatted
}

// If this changes update StringExtensions.cs
// pluralize words our system uses as well as simple cases
const alreadyPluralRegex = /(?:faculty|staff|selected)$/i
const yToIesRegex = /(opportunit|specialt)y$/i
const esRegex = /(match)$/i
const pluralWords = {
  person: 'people',
  'other person': 'other people',
  'student/learner': 'students/learners',
}
export function pluralize(word) {
  if (alreadyPluralRegex.test(word)) return word
  if (yToIesRegex.test(word)) return word.replace(yToIesRegex, '$1ies')
  if (esRegex.test(word)) return word.replace(esRegex, '$1es')
  const plural = pluralWords[word]
  if (plural) return plural
  return word + 's'
}

const httpMethodWwwStripRegex = /^http(s?):\/\/((www\.)?)/
const httpPathStripRegex = /\/(.+)?$/
const doublePeriodRegex = /\.\./
export function toDomainName(s) {
  if (s == null) return null
  const domain = s.replace(httpMethodWwwStripRegex, '').replace(httpPathStripRegex, '')
  return domainRegex.test(domain) && !doublePeriodRegex.test(domain) ? domain : null
}

export function getCount(countable) {
  return typeof countable === 'number' ? countable : _.size(countable)
}

// mode        countable==1  countable==2
// number        1 unit        2 units
// omitNumber    unit          units
// omitOne       unit          2 units
export function pluralCount(unit, countable, mode = 'number') {
  const count = getCount(countable)
  const isOne = Math.abs(count) === 1
  return isOne
    ? ['omitNumber', 'omitOne'].includes(mode)
      ? unit
      : `1 ${unit}`
    : mode === 'omitNumber'
      ? pluralize(unit)
      : `${readableNumber(count)} ${pluralize(unit)}`
}

export function singularOrPlural(countable, singular, plural) {
  return getCount(countable) === 1 ? singular : plural ?? pluralize(singular)
}

export function toLowerSpaceCollapsed(str) {
  return str?.toString().toLowerCase().replaceAll(/\s+/g, '')
}

export function caseAndWhitespaceInsensitiveEqual(left, right) {
  return toLowerSpaceCollapsed(left) == toLowerSpaceCollapsed(right)
}

export function slugify(value) {
  if (isEmpty(value)) return ''
  return value
    .toLowerCase()
    .replaceAll(/[^\da-z]+/g, ' ')
    .trim()
    .replaceAll(/\s/g, '-')
}

export function fromTemplate(template, objOrReplacerFn) {
  if (typeof template === 'function') return template(objOrReplacerFn)
  const replacer =
    typeof objOrReplacerFn === 'function'
      ? objOrReplacerFn
      : (_, keyPath) => {
          let result = objOrReplacerFn
          for (const key of keyPath.split('.')) {
            result = result[key]
          }
          return result
        }
  return template.replaceAll(/\[([^\]]+)]/g, replacer)
}

export function getEmailUserName(email) {
  return email?.split('@')[0] ?? null
}

export function getEmailDomain(email) {
  return email?.split('@')[1] ?? null
}

export function toUpper(str) {
  return str?.toUpperCase()
}

export function toLower(str) {
  return str?.toLowerCase()
}

<div class="flex-row flex-align-center g1" style={fullWidth ? 'min-width: 500px' : 'width: 500px'}>
  <Filter
    bind:text={keywordSearch}
    placeholder="Search opportunities by name, service, organization, or address"
    on:change={onKeywordSearchChanged}
    class="flex-grow"
  />

  <slot name="after-keyword-search" />
</div>

<div bind:this={filtersContainerElem} class="flex-row flex-align-center flex-wrap g1">
  <slot />
  <Filters
    bind:this={filtersComponent}
    bind:filters
    interceptors={_interceptors}
    {includedFilterTypes}
    {excludedFilterTypes}
    {metaMapFuncs}
    {onChanged}
    {onCleared}
    class={null}
    dropdownStyle={filtersDropdownStyle}
    filterOptionsController="capacityFilterOptions"
    {label}
  />
</div>

<script context="module">
  import { buildFilterTypesArray, buildIgnoredFilterTypesArray } from 'components/Filters.svelte'
  import { PersonaType } from 'config/enums.js'

  export const includedFilterTypes = [
    FilterType.GuestOrgs,
    FilterType.Agreements,
    FilterType.Specialties,
    FilterType.Disciplines,
    FilterType.StudentYears,
    FilterType.CapacityLocations,
    FilterType.CapacityShiftLocations,
    FilterType.RotationTypes,
    FilterType.Services,
    FilterType.CapacityOpenForScheduling,
    FilterType.CapacityAllowCoordinatorScheduling,
    FilterType.CapacityAllowPlaceholderStudents,
    FilterType.CapacityAllowGroups,
    FilterType.CapacityMatchConfirmation,
    FilterType.CapacityMatchCounts,
    FilterType.CapacityStartDate,
    FilterType.CapacityEndDate,
    FilterType.GuestSchedulingStartDate,
    FilterType.GuestSchedulingEndDate,
    FilterType.CapacityPostToSearchPage,
    FilterType.CanManage,
    FilterType.Teams,
    FilterType.HostOrgs,
    FilterType.KeywordSearch,
  ]

  const metaMapFuncs = {
    [FilterType.HostOrgs]: meta => {
      meta.label = 'Organization'
      return meta
    },
    [FilterType.Teams]: meta => {
      meta.label = 'Team'
      return meta
    },
    [FilterType.RotationTypes]: meta => {
      meta.includeEmptyCheckboxLabel = 'Include opportunities without experience types'
      return meta
    },
    [FilterType.Disciplines]: meta => {
      meta.label = 'Discipline'
      meta.allowsAnyOrExplicitly = true
      meta.labelApplied = 'discipline'
      meta.excludable = false
      meta.allowEmpty = true
      meta.toMany = true
      meta.toManySuffix = 'of the following disciplines'
      meta.includeEmptyCheckboxLabel = 'Include opportunities allowing any discipline'
      meta.editLabel = null
      return meta
    },
    [FilterType.StudentYears]: meta => {
      meta.labelApplied = 'student year'
      meta.allowsAnyOrExplicitly = true
      meta.canHaveMultiple = false
      meta.optionValues = [1, 2, 3, 4, 10]
      meta.toManySuffix = 'of the following student years'
      meta.includeEmptyCheckboxLabel = 'Include opportunities allowing any student year'
      return meta
    },
    [FilterType.Specialties]: meta => {
      meta.hasDoesntHave = true
      meta.labelApplied = 'specialty'
      meta.excludable = false
      meta.allowEmpty = true
      meta.toMany = true
      meta.toManySuffix = 'of the following specialties'
      meta.includeEmptyCheckboxLabel = 'Include opportunities without any specialties'
      meta.editLabel = null
      return meta
    },
    [FilterType.Agreements]: meta => {
      meta.allowsAnyOrExplicitly = true
      meta.labelApplied = 'agreement'
      meta.excludable = false
      meta.allowEmpty = true
      meta.toMany = true
      meta.toManySuffix = 'of the following agreements'
      meta.includeEmptyCheckboxLabel = 'Include opportunities allowing any agreement'
      meta.editLabel = null
      return meta
    },
    [FilterType.GuestOrgs]: meta => {
      meta.allowsAnyOrExplicitly = true
      meta.labelApplied = 'school'
      meta.excludable = false
      meta.toMany = true
      meta.toManySuffix = 'of the following schools'
      meta.includeOpenSchoolGuest = true
      meta.includeEmptyCheckboxLabel = 'Include opportunities without any schools'
      meta.configure = function ({ persona }) {
        this.allowEmpty = persona.personaType === PersonaType.ProviderStaff
      }
      meta.editLabel = null
      return meta
    },
    [FilterType.CapacityShiftLocations]: meta => {
      meta.toMany = true
      meta.excludable = false
      meta.editLabel = null
      meta.hasDoesntHave = true
      return meta
    },
  }

  export function buildFilterTypes(excludedFilterTypes = []) {
    const array = buildFilterTypesArray(metaMapFuncs, includedFilterTypes, excludedFilterTypes)
    const filterTypes = {}
    for (const meta of array) {
      filterTypes[meta.type] = meta
    }
    return filterTypes
  }

  export function buildIgnoredFilterTypes(excludedFilterTypes = []) {
    return buildIgnoredFilterTypesArray(includedFilterTypes, excludedFilterTypes)
  }
</script>

<script>
  // TODO(services): Re-implement this. Maybe. Maybe they shouldn't bother picking the other org first cuz it could be unnecessary work.
  import { FilterType } from 'config/enums.js'
  import { onDestroy } from 'svelte'
  import Filter from 'components/Filter.svelte'
  import Filters from 'components/Filters.svelte'
  import validator from 'services/validator.js'

  export let filtersComponent = null
  export let filters = []
  export let onChanged = _.noop
  export let excludedFilterTypes = []
  export let fullWidth = false
  export let interceptors = {}
  export let label = null

  let keywordSearch = filters.find(f => f.type === FilterType.KeywordSearch)?.config.keyword ?? ''

  $: _interceptors = {
    [FilterType.KeywordSearch]: {
      canRemove: false,
      isAvailable: false,
    },
    ...interceptors,
  }

  let filtersContainerElem = null
  let filtersDropdownStyle = null

  $: filtersContainerElem, initResizeObserver()
  let observer = null
  function initResizeObserver() {
    if (filtersContainerElem == null || observer != null) return
    observer = new ResizeObserver(() => {
      if (!fullWidth) {
        filtersDropdownStyle = null
        return
      }
      if (filtersContainerElem == null) return
      filtersDropdownStyle = `width: ${filtersContainerElem.offsetWidth}px; max-width: unset`
    })
    observer.observe(filtersContainerElem)
  }

  onDestroy(() => {
    observer?.disconnect()
  })

  function onCleared() {
    keywordSearch = ''
    onKeywordSearchChanged()
  }

  function onKeywordSearchChanged() {
    filters = filters.filter(f => f.type !== FilterType.KeywordSearch)
    if (!validator.empty(keywordSearch)) filters.push({ type: FilterType.KeywordSearch, config: { keyword: keywordSearch } })
    onChanged(filters)
  }
</script>

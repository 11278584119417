<div class="title" class:scrolledTop bind:this={titleEl}>
  <div class="flex-row flex-align-center flex-wrap g1 p1">
    <a
      aria-label="Close"
      class="close-modal"
      tabindex="0"
      href={null}
      use:onInteract={close}
      use:tip={{ content: 'Back to list', options: { placement: 'left' } }}
      data-test="modal-close-btn"
    >
      <Icon name="close" />
    </a>
    <h4 class="m0 text-{statusHelper.color}">
      <Icon name={statusHelper.icon} />
      {statusHelper.label}
    </h4>

    {#if statusHelper.describe != null}
      <div class="sub-title text-{statusHelper.color}" data-test="status-info">{statusHelper.describe(match)}</div>
    {/if}
  </div>

  {#if match.hasCacheTaskQueueItem}
    <div class="title-sync-warning">
      <Alert noIcon type="warning" class="no-round">
        <div class="flex-row flex-align-center">
          <SyncAlertIcon />
          <RotationSyncAlertMessage class="m1" onReload={reloadMatchDetailsAndActivity} />
        </div>
      </Alert>
    </div>
  {/if}
</div>

<script>
  import Alert from 'components/bootstrap/Alert.svelte'
  import Icon from 'components/Icon.svelte'
  import matchStatusHelper from 'services/match-status-helper.js'
  import onInteract from 'decorators/on-interact.js'
  import RotationSyncAlertMessage from 'components/RotationSyncAlertMessage.svelte'
  import SyncAlertIcon from 'components/SyncAlertIcon.svelte'
  import tip from 'decorators/tip.js'

  export let match
  export let close
  export let titleEl
  export let scrolledTop
  export let reloadMatchDetailsAndActivity

  $: statusHelper = matchStatusHelper.get(match.status)
</script>

<style lang="scss">
  @import '../../css/helpers';

  .title {
    position: fixed;
    left: 0;
    right: 0;
    z-index: 1040;
    background-color: #fff;
    font-size: 12px;
    border-bottom: 1px solid #eee;
    transition: box-shadow 0.1s ease-in-out;
    box-shadow: 0 1px 10px 4px rgba(0, 0, 0, 0.08);
  }

  .title.scrolledTop {
    box-shadow: unset;
  }

  .sub-title {
    font-size: 13px;
    font-weight: 500;
  }

  .title-sync-warning {
    border-top: 1px solid #eee;
  }

  .close-modal {
    color: getTextColorForBg(#fff);
    position: absolute;
    right: 20px;
    top: 10px;
    border-radius: 50%;
    font-size: 24px;
    width: 30px;
    height: 30px;
    padding: 3px;
    line-height: 24px;

    &:focus,
    &:hover {
      outline: none;
      color: #222;
      background: #ddd;
      position: absolute;
    }

    &:active {
      color: #111;
      background: #e4e4e4;
    }
  }

  @media only screen and (max-width: 650px) {
    .sub-title {
      font-size: 10px;
    }
  }
</style>

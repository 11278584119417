{#if editing}
  <FormGroup>
    <InputToggle name="is-waived" bind:checked={filter.isWaived}>
      {#if filter.isWaived}
        <Icon name="search" lg class="text-gray" />
      {:else}
        <Icon name="search" lg class="text-danger" />
      {/if}
      Waived
    </InputToggle>
  </FormGroup>
{:else if filter.isWaived}
  <Icon name="search" lg class="text-gray" />
  Waived
{:else}
  <Icon name="search" lg class="text-danger" />
  <strong>NOT</strong> waived
{/if}

<script context="module">
  import Icon from 'components/Icon.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    type: FilterType.IsWaived,
    label: 'Waived',
    labelApplied: 'is waived',
    icon: 'waived',
    iconClass: 'color-text-dark-green',
    create() {
      return { isWaived: true }
    },

    validate() {
      return true
    },

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.isWaived)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.isWaived = reader.readBool()
      return config
    },
  }
</script>

<script>
  export let editing = false
  export let filter
</script>

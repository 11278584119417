export const candidateSelectors = [
  'input',
  'select',
  'textarea',
  'a[href]',
  'button',
  '[tabindex]',
  'audio[controls]',
  'video[controls]',
  '[contenteditable]:not([contenteditable="false"])',
  'details>summary:first-of-type',
  'details',
]
export const candidateSelector = candidateSelectors.join(',')
export const focusableCandidateSelector = [...candidateSelectors, 'iframe'].join(',')

export const getCandidates = (elem, includeContainer, filter) => {
  const candidates = [...elem.querySelectorAll(candidateSelector)]
  if (includeContainer && elem.matches(candidateSelector)) candidates.unshift(elem)
  return candidates.filter(filter)
}

export const getTabIndex = node => {
  const tabIndexAttr = node.getAttribute('tabindex')
  const tabIndex = Number.parseInt(tabIndexAttr, 10)
  if (!Number.isNaN(tabIndex)) return tabIndex

  // Browsers do not return `tabIndex` correctly for contentEditable nodes
  // so if they don't have a tabindex attribute specifically set, assume it's 0.
  if (node.contentEditable === 'true') return 0

  // In Chrome, <details/>, <audio controls/> and <video controls/> elements get a default
  // `tabIndex` of -1 when the 'tabindex' attribute isn't specified in the DOM,
  // yet they are still part of the regular tab order in Firefox, they get a default
  // `tabIndex` of 0 since Chrome still puts those elements in the regular tab
  // order, consider their tab index to be 0.
  if (['AUDIO', 'VIDEO', 'DETAILS'].includes(node.nodeName) && tabIndexAttr === null) return 0
  return node.tabIndex
}

export const isInput = node => node.tagName === 'INPUT'
export const isHiddenInput = node => isInput(node) && node.type === 'hidden'
export const isRadio = node => isInput(node) && node.type === 'radio'
export const isDetailsWithSummary = node => node.tagName === 'DETAILS' && [...node.children].some(child => child.tagName === 'SUMMARY')

export const isTabbableRadio = node => {
  if (!node.name) return true
  const radioScope = node.form || node.ownerDocument
  const queryRadios = name => [...radioScope.querySelectorAll(`input[type="radio"][name="${name}"]`)]
  const radioSet = queryRadios(window.CSS.escape(node.name))
  const checked = radioSet.find(radio => radio.checked && radio.form === node.form)
  return !checked || checked === node
}

export const isNonTabbableRadio = node => isRadio(node) && !isTabbableRadio(node)

export const isHidden = elem => {
  if (getComputedStyle(elem).visibility === 'hidden') return true
  const isDirectSummary = elem.matches('details>summary:first-of-type')
  const elemUnderDetails = isDirectSummary ? elem.parentElement : elem
  if (elemUnderDetails.matches('details:not([open]) *')) return true
  while (elem) {
    if (getComputedStyle(elem).display === 'none') return true
    elem = elem.parentElement
  }
  return false
}

// For a details element with a summary, the summary element gets the focus
export const isNodeMatchingSelectorFocusable = node => !node.disabled && !isHiddenInput(node) && !isHidden(node) && !isDetailsWithSummary(node)
export const isNodeMatchingSelectorTabbable = node => isNodeMatchingSelectorFocusable(node) && !isNonTabbableRadio(node) && getTabIndex(node) >= 0

{#if intersectingItems?.length}
  <InputRadioGroup
    bind:value={useInheritedItemsRadio}
    name="use-inherited-option"
    options={[{ value: true }, { value: false, disabled: onlyOneOption }]}
    let:option
  >
    {#if option.value}
      Use
      {#if onlyOneOption}
        the {type} <span class="btn btn-no-hover btn-sm btn-info">{intersectingItems[0]}</span>
      {:else}
        the <span class="has-tooltip" use:tip={{ content: itemsTooltipElem, options: { theme: 'light-gray-scrollable', interactive: true } }}
          >{intersectingItems.length} {typePlural}</span
        >
      {/if}
      specified by
      <FriendlyList items={inheritedFromSourcesWithExplicitItems} let:item={inheritedFromSource} max={10} punctuation>
        <Icon name={inheritedFromSource.icon} {...inheritedFromSource.iconProps} />
        {inheritedFromSource.name ?? inheritedFromSource.type}
      </FriendlyList>
    {:else if onlyOneOption}
      <!--
                  This will be disabled; doing this so the <InputSelect> doesn't just show a grayed out subselection
                  from before switching the capacity's service to something else.
                  As in, this being disabled doesn't tell the user anything useful:
                  ┌────────────────────────────────┐
                  │ [specialty1] [specialty2]    ▼ │
                  └────────────────────────────────┘
                -->
      Use a subset of the {typePlural} specified by <FriendlyList
        items={inheritedFromSourcesWithExplicitItems}
        let:item={inheritedFromSource}
        max={10}
        punctuation
      >
        <Icon name={inheritedFromSource.icon} {...inheritedFromSource.iconProps} />
        {inheritedFromSource.name ?? inheritedFromSource.type}</FriendlyList
      >
    {:else}
      <slot subsetOfInherited enabledOptions={intersectingItems} {placeholder} {userExplicitlySelectedAllInherited} />
      <!--TODO: consider warning that if userExplicitlySelectedAllInherited, the selection will remain the same even if they change the inherited sources' selection-->
    {/if}
  </InputRadioGroup>

  <div class="hide">
    <div bind:this={itemsTooltipElem} class="flex-row flex-align-center g05">
      {#each intersectingItems as specialty}
        <span class="btn btn-no-hover btn-sm btn-info">{specialty}</span>
      {/each}
    </div>
  </div>
{:else}
  <slot subsetOfInherited={false} enabledOptions={null} placeholder="Any {type}" userExplicitlySelectedAllInherited={false} />
{/if}

<script context="module">
  // TODO: some unit tests for this to test empty, null, etc...and also component tests for the resulting component...
  export function computeIntersectingTags(...tags) {
    if (tags == null) return []
    const tagSources = tags.filter(d => d?.length > 0)
    return _.intersection(...tagSources)
  }

  // if View-vServiceDiscipline.sql changes, probably change this
  export function computeServiceDisciplines(service) {
    if (service == null) return []
    return computeIntersectingTags(service.disciplines, service.teamDisciplines)
  }

  // if View-vCapacityDiscipline.sql changes, probably change this
  export function computeCapacityDisciplines(capacity) {
    if (capacity == null) return []
    return computeIntersectingTags(
      capacity.disciplines,
      computeServiceDisciplines(capacity.service),
      capacity.agreement?.disciplines,
      capacity.teamDisciplines
    )
  }

  // if View-vCapacitySpecialty.sql changes, probably change this
  export function computeCapacitySpecialties(capacity) {
    if (capacity == null) return []
    return computeIntersectingTags(capacity.specialties, capacity.service?.specialties)
  }
</script>

<script>
  import tip from 'decorators/tip'
  import { pluralCount, pluralize, toFriendlyList, toUpper } from 'services/string-utils'
  import validator from 'services/validator'
  import InputRadioGroup from './fields/InputRadioGroup.svelte'
  import FriendlyList from './FriendlyList.svelte'
  import Icon from './Icon.svelte'

  export let type
  export let value
  export let inheritedFromSources

  const initialValue = value
  let itemsTooltipElem = null
  let useInheritedItemsRadio = !value.length

  $: typePlural = pluralize(type)
  $: inheritedFromSourcesWithExplicitItems = inheritedFromSources.filter(d => d.items?.length > 0)
  $: intersectingItems = computeIntersectingTags(...inheritedFromSourcesWithExplicitItems.map(d => d.items))
  $: onlyOneOption = intersectingItems.length == 1
  $: placeholder = `Use a subset of the ${toFriendlyList(
    inheritedFromSourcesWithExplicitItems.map(d => (d.name ?? d.type) + '’s'),
    10
  )} ${pluralCount(type, intersectingItems)}`
  $: userExplicitlySelectedAllInherited = validator.equals(sortedUpper(value), sortedUpper(intersectingItems))
  $: value = useInheritedItemsRadio ? [] : initialValue

  const sortedUpper = array => _.sortBy(array, toUpper)
</script>

<div class="mb2">
  <Collapsible name="schools-detail" open>
    <h3 slot="label" class="mb0">Schools detail</h3>
    <div>
      <div class="flex-row flex-justify-between">
        <h4>
          School rotation utilization {#if capacity?.maxMatches}({capacity.maxMatches} total rotations available){/if}
        </h4>
        <BtnGroupPicker {options} bind:value={view} class={className} {btnClass} on:change name={dataTest} />
      </div>
      {#if view === 'opportunity-capacity-guest'}
        <WaffleChart {capacity} bind:guests {filteredGuests} {capacityMatchStatusCounts} isCapacityGuestView />
      {/if}
      {#if view === 'opportunity-status'}
        <MatchStatusLegend {matches} {capacityMatchStatusCounts} />
        <WaffleChart {capacity} {guests} bind:capacityMatchStatusCounts isStatusView />
      {/if}
      {#if view === 'capacity-guest-status'}
        <MatchStatusLegend />
        <div class="grid-container" on:mousewheel={handleScroll}>
          <div class="grid">
            {#each guestsWithChartData as guest, i}
              <div class=" p1">
                <Chart id="chart{i}" type={'bar'} data={guest.chartData} options={chartOptions} />
                <div>
                  <hr class="m0 mb05" />
                  <CapacityGuestProfilePictureAndName capacityGuest={guest} size="medium" useOverflowEllipsis />
                </div>
                <div />
              </div>
            {/each}
          </div>
        </div>
      {/if}
      <div>
        <h4>School overview</h4>
        <CapacityGuestFilters {excludedFilterTypes} bind:filtersComponent bind:filters onChanged={filterGuests} />
        <CapacityGuestDetailGrid guests={filteredGuests} {matches} />
      </div>
    </div>
  </Collapsible>
</div>

<script>
  import CapacityGuestDetailGrid from 'components/CapacityGuestDetail.Grid.svelte'
  import { MatchStatus, FilterType, DateTimeComparison, DateTimeComparisonProviderType } from 'config/enums'
  import BtnGroupPicker from 'components/bootstrap/BtnGroupPicker.svelte'
  import Chart from 'components/Chart.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import CapacityGuestFilters from 'components/CapacityGuestFilters.svelte'
  import CapacityGuestProfilePictureAndName from 'components/CapacityGuestProfilePictureAndName.svelte'
  import MatchStatusLegend from 'components/MatchStatusLegend.svelte'
  import WaffleChart from 'components/CapacityGuestDetail.WaffleChart.svelte'
  import enumToOptions from 'services/enum-to-options'
  import matchStatusHelper from 'services/match-status-helper.js'
  import dayjs from 'dayjs'

  export let btnClass = 'btn-sm'
  export let capacity
  export let matches
  export let className = ''
  export { className as class }
  export let dataTest = null
  export let view = 'opportunity-capacity-guest'
  export let filters = []
  export let excludedFilterTypes = []

  const filterGuestFuncs = {
    [FilterType.KeywordSearch]: filterGuestsByKeywordSearch,
    [FilterType.GuestOrgs]: filterGuestsByGuestOrgs,
    [FilterType.CapacityGuestHasRotations]: filterGuestsByCapacityGuestHasRotations,
    [FilterType.CapacityGuestRotationRequestRestrictionStartDate]: filterGuestsByCapacityGuestRotationRequestRestrictionStartDate,
    [FilterType.CapacityGuestRotationRequestRestrictionEndDate]: filterGuestsByCapacityGuestRotationRequestRestrictionEndDate,
  }

  const dayJsComparisonMethods = {
    [DateTimeComparison.IsBefore]: 'isBefore',
    [DateTimeComparison.IsBeforeOrEqual]: 'isSameOrBefore',
    [DateTimeComparison.Is]: 'isSame',
    [DateTimeComparison.IsAfter]: 'isAfter',
    [DateTimeComparison.IsAfterOrEqual]: 'isSameOrAfter',
  }

  let capacityMatchStatusCounts
  let guests = capacity?.guests ?? []
  let filtersComponent
  let filteredGuests = guests ?? []
  let chartMaximum = 0
  let guestsWithChartData = []

  const buildOption = (value, label, dataTest) => ({ value, label, dataTest })
  $: options = [
    buildOption('opportunity-capacity-guest', 'Opportunity by school view', 'opportunity-capacity-guest-view-tab'),
    buildOption('opportunity-status', 'Opportunity status view', 'opportunity-status-view-tab'),
    buildOption('capacity-guest-status', 'School status view', 'capacity-guest-status-view-tab'),
  ]

  $: guestStatusCounts = capacity?.statusCounts ?? []
  $: processGuests(guests)
  $: matchStatuses = enumToOptions(MatchStatus).filter(o => o.optionValue !== MatchStatus.Closed && o.optionValue !== MatchStatus.Unsubmitted)
  $: capacity, (guestsWithChartData = buildGuestChartData(filteredGuests))
  $: chartOptions = {
    backgroundColor: 'transparent',
    tooltips: {
      bodyFontSize: 16,
    },
    scales: {
      yAxes: [
        {
          ticks: {
            beginAtZero: true,
            stepSize: 1,
            precision: 0,
            max: chartMaximum,
          },
        },
      ],
    },
  }
  function handleScroll(event) {
    event.preventDefault()
    const container = event.target.closest('.grid-container')
    if (container) {
      const scrollAmount = event.deltaY
      container.scrollLeft += scrollAmount
    }
  }

  function buildGuestChartData(guests) {
    const guestsWithChartData = []
    for (const guest of guests) {
      if (!guest.statusCounts.length) continue
      const updatedGuest = _.cloneDeep(guest)
      updatedGuest.chartData = {
        labels: [''],
        datasets: [],
      }
      for (const status of matchStatuses) {
        const matchStatus = matchStatusHelper.get(status.optionValue)
        const newDataSet = {
          label: matchStatus.label,
          data: [guest.statusCounts.find(s => s.status === matchStatus.key)?.count || 0],
          backgroundColor: matchStatus.reportColor,
        }
        if (newDataSet.data[0] > chartMaximum) chartMaximum = newDataSet.data[0]
        updatedGuest.chartData.datasets.push(newDataSet)
      }
      guestsWithChartData.push(updatedGuest)
    }
    return guestsWithChartData
  }

  function processGuests(guests) {
    if (!guests) return
    for (const guest of guests) {
      const guestStatus = guestStatusCounts.find(s => s.guestOrgId === guest.guestOrgId)
      guest.statusCounts = guestStatus?.byStatus ?? []
    }

    for (const guest of guests) {
      const filteredStatusCounts = guest.statusCounts.filter(s => s.status !== MatchStatus.Closed && s.status !== MatchStatus.Unsubmitted)
      guest.statusCounts = filteredStatusCounts
      guest.approvedMatches = _.sumBy(
        guest.statusCounts.filter(s => s.status > MatchStatus.Waitlisted && s.status < MatchStatus.Closed),
        'count'
      )
      guest.pendingMatches = _.sumBy(
        guest.statusCounts.filter(s => s.status > MatchStatus.Unsubmitted && s.status < MatchStatus.Onboarding),
        'count'
      )
      guest.approvedOverMax = guest.maxMatches ? Math.max(0, guest.approvedMatches - guest.maxMatches) : 0
      guest.pendingOverMax = guest.maxMatches
        ? guest.approvedOverMax > 0
          ? guest.pendingMatches
          : Math.max(0, guest.approvedMatches + guest.pendingMatches - guest.maxMatches)
        : 0
      guest.guaranteedLeft = Math.max(0, guest.guaranteedMatchCountGranted - guest.approvedMatches - guest.pendingMatches)
      guest.textColor = getTextColorForUserColor(guest.color)
    }
    return guests.sort((a, b) => {
      // If 'included' status is different, sort by it first, with 'true' values first
      if (a.included !== b.included) {
        return a.included ? -1 : 1
      }
      // If 'included' status is the same for both, then sort by 'guestOrgId'
      return a.guestOrgId - b.guestOrgId
    })
  }

  function getTextColorForUserColor(color) {
    const lightColors = ['yellow', 'gray', 'gold', 'light-purple', 'sky', 'lime', 'pink', 'orange']
    return lightColors.includes(color) ? 'black' : 'white'
  }

  function filterGuests() {
    filteredGuests = guests
    for (const filter of filters) {
      const filterFunc = filterGuestFuncs[filter.type]
      if (!filterFunc) continue
      filteredGuests = filterFunc(filteredGuests, filter)
    }
    return filteredGuests
  }

  function filterGuestsByKeywordSearch(guests, filter) {
    const keyword = filter.config.keyword?.trim().toLowerCase()
    return keyword ? guests.filter(g => g.name.toLowerCase().includes(keyword)) : guests
  }

  function filterGuestsByGuestOrgs(guests, filter) {
    const schoolOrgIds = filter.config.schoolOrgIds
    const exclude = filter.config.exclude
    return schoolOrgIds.length
      ? exclude
        ? guests.filter(g => !schoolOrgIds.includes(g.guestOrgId))
        : guests.filter(g => schoolOrgIds.includes(g.guestOrgId))
      : guests
  }

  function filterGuestsByCapacityGuestHasRotations(guests, filter) {
    return filter.config.hasRotations
      ? guests.filter(g => g.pendingMatches > 0 || g.approvedMatches > 0)
      : guests.filter(g => g.pendingMatches === 0 && g.approvedMatches === 0)
  }

  function filterGuestsByDate(guests, filter, dateField) {
    if (filter.config.comparisonProviderType === DateTimeComparisonProviderType.Parameterless)
      return filter.config.comparisonProvider.isNull ? guests.filter(g => !g[dateField]) : guests.filter(g => g[dateField])
    const comparisonType = filter.config.comparisonProvider.comparison
    const comparisonDate = dayjs(filter.config.comparisonProvider.date, 'M/D/YYYY')
    const dayjsComparisonMethod = getDayJsComparisonMethod(comparisonType)
    return guests.filter(g => dayjs(g[dateField], 'M/D/YYYY')[dayjsComparisonMethod](comparisonDate))
  }

  function filterGuestsByCapacityGuestRotationRequestRestrictionStartDate(guests, filter) {
    return filterGuestsByDate(guests, filter, 'schedulingStartDate')
  }

  function filterGuestsByCapacityGuestRotationRequestRestrictionEndDate(guests, filter) {
    return filterGuestsByDate(guests, filter, 'schedulingEndDate')
  }

  function getDayJsComparisonMethod(comparisonType) {
    return dayJsComparisonMethods[comparisonType] ?? `invalidDateTimeComparison:${comparisonType}`
  }
</script>

<style>
  .grid-container {
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
  }
  .grid-container::-webkit-scrollbar {
    display: none;
  }
  .grid {
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(10, 300px);
    grid-template-rows: 2;
  }
</style>

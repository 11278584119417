{#if xs}
  <div class={profilePicClasses} style="height: 30px; width: 30px" use:tip={titleTip}>
    {#if includeCount}
      <Icon name="placeholder-student" sm />
      <div class="flex-row flex-align-center g03" style="font-size: 10px">
        <Icon name="times" sm />
        <span>{value}</span>
      </div>
    {:else}
      <Icon name="placeholder-student" md />
    {/if}
  </div>
{:else if sm}
  <div class={profilePicClasses} style="height: 40px; width: 40px" use:tip={titleTip}>
    <Icon name="placeholder-student" lg />
  </div>
{:else if md}
  <div class="mr1 mb1 {profilePicClasses}" style="height: 50px; width: 50px" use:tip={titleTip}>
    <Icon name="placeholder-student" md />
    <div class="flex-row flex-align-center g03" style="font-size: 13px">
      <Icon name="times" sm />
      <span>{value}</span>
    </div>
  </div>
{:else}
  <div class="grid-container bg-placeholder{className ? ` ${className}` : ''}" style="height: 92px; width: 92px; border-radius: 5px 0px 0px 5px">
    <div
      class="p05"
      style="display: grid; grid-template-columns: repeat({columnCount}, 1fr); grid-template-rows: repeat({rowCount}, 1fr); width: 92px; height: 92px"
    >
      {#each { length: Math.min(value, 25) } as duck, i}
        <div class="text-white text-center flex-row flex-align-center flex-justify-center">
          <Icon
            x3={value == 1}
            x2={value > 1 && value < 10}
            lg={value >= 10 && value < 17}
            name={i == 24 && value > 25 ? 'plus' : 'placeholder-student'}
          />
        </div>
      {/each}
    </div>
  </div>
{/if}

<script>
  import { pluralCount } from 'services/string-utils'
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'

  export let value = 0
  export let xs = false
  export let sm = false
  export let md = false
  export let includeCount = false
  export { className as class }
  export let noTip = false

  let className = ''

  $: profilePicClasses = `flex-column flex-align-center flex-justify-center bg-placeholder text-white rounded-lg${className ? ` ${className}` : ''}`
  $: rowCount = Math.min(Math.ceil(Math.sqrt(value)), 5)
  $: columnCount = Math.min(Math.ceil(value / rowCount), 5)
  $: titleTip = noTip ? null : { content: pluralCount('placeholder student', value) }
</script>

<style>
  .grid-container {
    display: flex;
    width: 100%;
  }
</style>

<Form on:submit={() => dispatch('submit')}>
  <div class="p2">
    <FormGroup valid={validator.int(match.closedReason)}>
      <label class="m0">Please tell us why you’re closing this rotation</label>
      <CloseReasonsPicker bind:value={match.closedReason} disabled={closing} />
    </FormGroup>

    <FormGroup valid={match.closedReason !== CloseReason.Other || validator.required(match.otherClosedReason)}>
      <label for="other-closed-reason">
        Comments
        {#if match.closedReason === CloseReason.Other}
          <RequiredMarker />
        {/if}
      </label>

      <InputTextarea
        name="other-closed-reason"
        bind:value={match.otherClosedReason}
        class="form-control"
        autocomplete="off"
        maxlength={700}
        bind:this={otherClosedReasonEl}
        disabled={closing}
      />
    </FormGroup>
  </div>

  <div class="modal-footer">
    <SubmitBtn dataTest="close-rotation-btn" loading={closing} color="danger" icon="Closed">
      {#if closing}
        Closing rotation…
      {:else}
        Close rotation
      {/if}
    </SubmitBtn>
    <Btn on:click={() => dispatch('cancel')}>Cancel</Btn>
  </div>
</Form>

<script>
  import CloseReasonsPicker from 'components/fields/CloseReasonsPicker.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import SubmitBtn from 'components/bootstrap/SubmitBtn.svelte'
  import { CloseReason } from 'config/enums.js'
  import validator from 'services/validator.js'
  import { createEventDispatcher } from 'svelte'
  import InputTextarea from './fields/InputTextarea.svelte'

  export let match
  export let closing = false

  const dispatch = createEventDispatcher()
  let otherClosedReasonEl

  $: if (otherClosedReasonEl && match.closedReason != null) otherClosedReasonEl.focus()
</script>

<a {href} {target} use:onInteract={onClick} use:tip={tooltip} data-test={dataTest} {id} class="specific-underline{className ? ` ${className} ` : ''}">
  <slot name="icon"><Icon name={loading ? 'spinner' : icon} {...iconProps} /></slot>
  <span class="underlineable"><slot>{text}</slot></span>
</a>

<script>
  import Icon from 'components/Icon.svelte'
  import onInteract from 'decorators/on-interact.js'
  import tip from 'decorators/tip'

  export let icon = 'plus' // The most common
  export let text = 'Add'
  export let href = null
  export let onClick = null
  export let id = null
  export let dataTest = id
  let className = null
  export { className as class }
  export let iconProps = {}
  export let target = null
  export let loading = false
  export let tooltip = null
</script>

<style>
  .disabled {
    color: #03aca096;
    cursor: not-allowed;
  }
</style>

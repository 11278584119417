{#if !validator.empty(content)}
  <Icon name="help" class="help-tip text-info" {focusable} title={content} tipOptions={options} />
{/if}
<div class="hide">
  <div bind:this={content}>
    <slot />
  </div>
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import validator from 'services/validator.js'

  export let focusable = true
  export let tipOptions = {}

  let content = null
  $: options = {
    theme: 'help',
    interactive: true,
    maxWidth: 250,
    ...tipOptions,
  }
</script>

<div data-test={dataTest} class="selected-color normal p05 color-bg-{value} contrast-shadow {className}" class:only-color={!displayLabel}>
  {#if displayLabel}
    <span>{colorService.humanizeColor(value)}</span>
  {/if}
  {#if !noCaret}
    <span class="pull-right"><Icon name="caret-down" class="color-text-{colorService.humanizeColor(value)}" /></span>
  {/if}
</div>

<script>
  import colorService from 'services/color-service.js'
  import Icon from 'components/Icon.svelte'

  export let value = null
  export let dataTest = null
  export let noCaret = false
  export let displayLabel = true
  let className = null
  export { className as class }
</script>

<style lang="scss">
  .selected-color {
    border-radius: 4px;
    width: 100px;
    text-align: center;
    padding: 8px 8px;
  }

  .only-color {
    width: 20px;
    height: 20px;
  }

  .only-color {
    width: 20px;
    height: 20px;
  }
</style>

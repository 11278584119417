<div class="flex-row flex-justify-center g2 pb2 mt1">
  {#each matchStatuses as matchStatus}
    {@const count = capacityMatchStatusCounts?.[matchStatus]}
    <MatchCountByStatus {matchStatus} {count} />
  {/each}
</div>

<script context="module">
  import { MatchStatus } from 'config/enums.js'

  const matchStatuses = Object.values(MatchStatus).filter(ms => ms !== MatchStatus.Closed && ms !== MatchStatus.Unsubmitted)
</script>

<script>
  import MatchCountByStatus from 'components/MatchCountByStatus.svelte'

  export let capacityMatchStatusCounts
</script>

// source: https://docs.microsoft.com/en-us/aspnet/core/security/anti-request-forgery?view=aspnetcore-2.2
export function get(cookieName) {
  const name = cookieName + '='
  const decodedCookie = decodeURIComponent(document.cookie).trimStart()
  const ca = decodedCookie.split(/\s*;\s*/)
  for (const c of ca) {
    if (c.startsWith(name)) return c.slice(name.length)
  }
  return ''
}

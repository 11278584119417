<div class="flex-row flex-align-center g1">
  <ProfilePic name={user.name} initials={user.initials} id={user.userId} src={user.profilePicture} class="mr1" large />

  <div>
    <h3 class="m0">
      {user.name}
      {#if capacityAccessibilityTooltip !== null}
        {#if capacityAccessibilityTooltip}
          <Icon name="hide" class="text-danger" title={capacityAccessibilityTooltip} />
        {:else}
          <Icon name="eye" class="text-success" />
        {/if}
      {/if}
    </h3>

    <div class="flex-row g1">
      <IconTextLink icon="user" text="View profile" href={user.url} />
      {#if user.email}
        <IconTextLink icon="email" text={user.email} href="mailto:{user.email}" />
      {/if}

      {#if user.phonePublic}
        <IconTextLink icon="phone" text={user.phonePublic} href="tel:{user.phonePublic}" />
      {/if}
    </div>
    <div class="mt1 em">
      Last activity: {#if user.lastActivityDate == null}unknown{:else}<FromNow date={user.lastActivityDate} precision={false} />{/if}
    </div>
  </div>
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import FromNow from './FromNow.svelte'

  export let user
  export let capacityAccessibilityTooltip = null
</script>

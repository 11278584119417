import environment from '../services/environment.js'
import { handleRequestError } from 'services/handle-request-error.js'
import { readable } from 'svelte/store'

const failure = { loaded: true, isSuccess: false, prefetch: {} }

async function load() {
  if (window.location.href.includes('__cypress')) return failure
  const params = new URLSearchParams(window.location.search)

  // first check if view model is on the querystring
  const spaViewModelJson = params?.get('spaViewModel')
  if (spaViewModelJson != null) {
    const spaViewModel = JSON.parse(spaViewModelJson)
    return { ...spaViewModel, loaded: true, isSuccess: true }
  }

  // otherwise, hit the initial endpoint to get the spa view model
  // can't use api.js since it depends on this file for determining if can use pre-fetch or not (would result in circular ref)
  const forceEnableCaptcha = params?.get('forceEnableCaptcha')
  const url = `${environment.linkApi}/api/home/initial${forceEnableCaptcha === 'true' ? '/?forceEnableCaptcha=true' : ''}`

  try {
    const res = await window.fetch(url, environment.fetchOptions)
    const data = await res.json()
    const isSuccess = res.status === 200
    if (!isSuccess) handleRequestError(res.status, data)
    return { ...data, loaded: true, isSuccess }
  } catch {
    // still want the app to render if initial endpoint fails, so that toast is rendered
    return failure
  }
}

export default readable({ loaded: false }, async set => {
  // put on window, so certain parts of the app that aren't svelte components that depend on it can await it too.
  window.initialPromise = load()
  set(await window.initialPromise)
})

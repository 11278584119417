<div>
  <div class="debug-grid gc1r05">
    <span>Form</span>
    <span class="monospace">{formName}</span>

    <span>Navigating to path</span>
    <span class="monospace">{path}</span>
    {#each allowedPaths as ap, i}
      <span>
        {#if !i}Allowed paths{/if}
      </span>
      <span class="monospace">{ap}</span>
    {:else}
      <span>Allowed paths</span>
      <em class="small">None</em>
    {/each}
  </div>

  <h4 class="m0">Differences</h4>
  {#if differences == null}
    <em class="small">No differences — not registered with <span class="monospace">formHasChanges()</span></em>
  {:else}
    <ul class="m0 pl2 monospace">
      {#each differences as d}
        <li>{d}</li>
      {/each}
    </ul>

    <JsonDiff before={inputInitial} after={input} />
  {/if}
</div>

<script>
  import JsonDiff from 'components/JsonDiff.svelte'
  import unsavedForms from 'stores/unsaved-forms.js'

  $: warnFor = _.cloneDeep($unsavedForms.warnFor)
  $: path = warnFor.path
  $: form = warnFor.form
  $: formName = form.formName
  $: allowedPaths = form.allowedPaths
  $: differences = form.differences
  $: input = form.input
  $: inputInitial = form.inputInitial
</script>

<style lang="scss">
  .debug-grid {
    display: grid;
    grid-template-columns: auto 1fr;
  }
</style>

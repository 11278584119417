{#if logs}
  {#if logs.length === 0}
    <HelpBlock>
      No hours logged yet
      {#if date}for {date}{/if}
    </HelpBlock>
  {/if}
  <div class="list-group">
    {#each logs as log (log.hourLogId)}
      <HourLogItem {log} {supervisors} {hourLogsMustBeVerified} on:click={startEditing} on:changed>
        {#if editing?.hourLogId === log.hourLogId}
          <HourLogForm
            log={editing}
            {logs}
            {match}
            {supervisors}
            {hourLogsMustBeVerified}
            {min}
            {max}
            on:cancel={stopEditing}
            on:saved={onEditComplete} />
        {/if}
      </HourLogItem>
    {/each}
  </div>
{/if}

<script>
  import HourLogForm from 'components/HourLogForm.svelte'
  import HourLogItem from 'components/HourLogItem.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import { createEventDispatcher } from 'svelte'

  export let logs
  export let match
  export let supervisors
  export let hourLogsMustBeVerified
  export let editing
  export let min
  export let max
  export let date // if looking at single date

  const dispatch = createEventDispatcher()

  function startEditing(e) {
    editing = e.detail
  }

  function stopEditing() {
    editing = null
  }

  function onEditComplete() {
    dispatch('changed')
    stopEditing()
  }
</script>

<style>
  .list-group {
    min-width: 400px;
  }
</style>

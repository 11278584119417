<InputSelect
  placeholder="…"
  prefixLabel="Sort by"
  {options}
  bind:value
  valueSelector={o => o}
  keySelector={o => o.key}
  class={className}
  {sm}
  contentClass={null}
  selectInputTextClass={null}
  {name}
  let:option
>
  <Icon lg name="sort" slot="prefix" />
  <SortDropdownSlot {option} isDefaultSlot />
  <SortDropdownSlot {option} slot="label" />
</InputSelect>

<script context="module">
  export const buildSortOption = (sortProperty, name, type = 'alpha', extra) => ({ sortProperty, name, type, ...extra })
</script>

<script>
  import Icon from 'components/Icon.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import SortDropdownSlot from 'components/SortDropdown.Slot.svelte'

  export let sortOptions = null
  export let sortProperty = null
  export let sortAscending = true
  let className = null
  export { className as class }
  export let sm = false
  export let onChange = _.noop
  export let name = 'sort-dropdown'

  let value = null
  $: options = buildOptions(sortOptions)
  $: updateValue(sortProperty, sortAscending) // Run this after buildOptions() so consumers can pass in default sortProperty/sortAscending.
  $: updateProps(value)

  function updateValue(sortProperty, sortAscending) {
    // Only update value if it's actually changed; avoid data-binding cycles.
    const found = options.find(o => o.sortProperty === sortProperty && o.sortAscending === sortAscending)
    if (value != found) value = found
  }

  function updateProps(value) {
    // Only update props if they've actually changed; avoid data-binding cycles.
    let changesMade = false

    if (value) {
      if (value.sortProperty != sortProperty) {
        sortProperty = value.sortProperty
        changesMade = true
      }
      if (value.sortAscending != sortAscending) {
        sortAscending = value.sortAscending
        changesMade = true
      }
      if (changesMade) onChange()
      return
    }

    if (sortProperty != null) {
      sortProperty = null
      changesMade = true
    }
    if (!sortAscending) {
      sortAscending = true
      changesMade = true
    }
    if (changesMade) onChange()
  }

  const buildOption = (option, sortAscending, keySuffix) => ({
    ...option,
    sortAscending,
    class: 'flex-row flex-align-center g3',
    key: `${option.sortProperty}-${keySuffix}`,
    description: getSortDescription(option, sortAscending),
    iconName: option.type === 'automatic' ? 'brain-circuit' : null,
  })

  function buildOptions(sortOptions) {
    const options = []
    for (const option of sortOptions) {
      let lastOption = null
      // These "allow" options are expected to be null most of the time
      // and null should be treated as "true" as that's the more sane default.
      if (option.allowAscending !== false) {
        lastOption = buildOption(option, true, 'asc')
        options.push(lastOption)
      }
      if (option.allowDescending !== false) {
        lastOption = buildOption(option, false, 'desc')
        options.push(lastOption)
      }
      if (lastOption) lastOption.class += ' end-of-opt-group'
    }
    return options
  }

  const sortDescriptionsByType = {
    alpha: ['A', 'Z'],
    bool: ['no', 'yes'],
    numeric: ['least', 'most'],
    date: ['earliest', 'latest'],
    time: ['earliest', 'latest'],
    size: ['smallest', 'biggest'],
  }

  function getSortDescription(option, sortAscending) {
    if (option.type === 'automatic') return null
    if (sortAscending) {
      if (option.sortAscendingDescription != null) return option.sortAscendingDescription
      const byType = sortDescriptionsByType[option.type]
      return byType ? `from ${byType[0]}–${byType[1]}` : 'ascending'
    }

    if (option.sortDescendingDescription != null) return option.sortDescendingDescription
    const byType = sortDescriptionsByType[option.type]
    return byType ? `from ${byType[1]}–${byType[0]}` : 'descending'
  }
</script>

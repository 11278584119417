{#if loading}
  <Spinner x2 />
{:else if resultCount != null}
  <HelpBlock name="result-count" class="m0">
    {#if resultCount > 0}
      {`${pluralCount('result', resultCount)}`}
    {:else if hasCriteria}
      No results match your search criteria.
    {:else}
      {noResultsMessage}
      {#if $persona.isStudent}<a href="/search/rotations">Find a rotation</a>{/if}
    {/if}
  </HelpBlock>
{/if}

<script>
  import { pluralCount } from 'services/string-utils.js'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import persona from 'stores/persona.js'
  import Spinner from 'components/Spinner.svelte'

  export let loading = false
  export let resultCount = null
  export let hasCriteria = false
  export let noResultsMessage = 'You have no rotations for this time frame.'
</script>

// https://stackoverflow.com/questions/4817029/whats-the-best-way-to-detect-a-touch-screen-device-using-javascript
let isTouch = null

export function isTouchDevice() {
  if (isTouch == null) {
    try {
      document.createEvent('TouchEvent')
      isTouch = true
    } catch {
      isTouch = false
    }
  }
  return isTouch
}

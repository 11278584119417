{#if controls === 'both' || controls === 'top'}
  <PaginatorControls
    name="{name}-top-controls"
    totalCount={_totalCount}
    bind:currentPage
    bind:pageSize
    {useDefaultPageSizeOptions}
    {pageSizeOptions}
    {pagesOfLabel}
    {onChange}
    class={topControlsClass}
  >
    <slot name="top-controls" />
  </PaginatorControls>
{/if}

{#if yieldPage}
  <!--yield with full page of items as context-->
  <slot {pageItems} />
{:else}
  <!--else we yield each item as the context-->
  {#if _totalCount}
    <div class={className} data-test={dataTest}>
      {#each pageItems as item}
        <slot {item} />
      {/each}
    </div>
  {/if}
{/if}

{#if controls === 'both' || controls === 'bottom'}
  <PaginatorControls
    name="{name}-bottom-controls"
    totalCount={_totalCount}
    bind:currentPage
    bind:pageSize
    {useDefaultPageSizeOptions}
    {pageSizeOptions}
    {pagesOfLabel}
    {onChange}
    class={bottomControlsClass}
  >
    <slot name="bottom-controls" />
  </PaginatorControls>
{/if}

<script>
  import { tick } from 'svelte'
  import optionBuilder from 'services/option-builder.js'
  import PaginatorControls from 'components/PaginatorControls.svelte'

  export let name = null
  export let items = null
  export let totalCount = null
  export let currentPage = 1
  export let pageSize = 10
  // If you don't want to be forced to pass custom `pageSizeOptions`,
  // just set `useDefaultPageSizeOptions` and the default options will be used.
  // Also consider passing `scalablePageSizeOptions` from `default-page-size-options.js`
  export let useDefaultPageSizeOptions = false
  export let pageSizeOptions = null
  export let pagesOfLabel = 'rows' // Used as `Show all ${pagesOfLabel}` and `${pageSize} ${pagesOfLabel} per page`
  export let controls = 'bottom'
  let className = null
  export { className as class }
  export let topControlsClass = null
  export let bottomControlsClass = null
  export let yieldPage = false
  export let dataTest = null
  export let onChange = _.noop

  // These two properties are used when <Grid> is displaying a tree. The idea is that
  // the <Grid> will tell the <Paginator> whether or not a given row contributes toward
  // the count of rows being displayed or should just be displayed.
  export let itemKeySelector = null
  export let countContributions = null // { [itemKey]: 0|1, ... }
  let pageItems
  $: nItemKeySelectors = optionBuilder.normalizeSelectors(itemKeySelector ?? _.identity)
  $: itemKey = row => optionBuilder.selectFromOption(row, nItemKeySelectors)

  $: _totalCount = totalCount ?? (countContributions ? _.sumBy(_.values(countContributions)) : items?.length) ?? 0
  $: setPageItems(items, totalCount, currentPage, pageSize)

  async function setPageItems(items, totalCount, currentPage, pageSize) {
    // hard setting because svelte bug probably: https://github.com/sveltejs/svelte/issues/4165
    // scenario: delete capacity on page 2, pops open asking if you want to delete the row from page 1
    pageItems = []
    await tick()
    pageItems = buildPageItems(items, totalCount, currentPage, pageSize)
  }

  function buildPageItems(items, totalCount, currentPage, pageSize) {
    if (totalCount != null) {
      return items || [] // Server-side paging, so no need to slice
    } else if (items == null || items.length === 0) {
      return []
    }
    const _currentPage = currentPage - 1
    const _pageSize = Number.parseInt(pageSize, 10)
    let start = _currentPage * pageSize
    const end = Math.min(start + _pageSize, _totalCount + 1)
    if (countContributions == null) {
      return items.slice(start, end)
    } else {
      // We need to iterate through every single item, logically assigning them to pages.
      // Then when we get on the page we're looking for, we'll start adding items to the actual
      // _pageItems till we've reached an item that would go on the next page.
      start++
      let count = 0
      const _pageItems = []
      for (const item of items) {
        count += countContributions[itemKey(item)] ?? 0
        if (count >= start && count <= end) _pageItems.push(item)
        if (count > end) return _pageItems
      }
      return _pageItems
    }
  }
</script>

<TagCloudItems
  {hideTooltip}
  items={rotationTypes}
  type="Experience type"
  icon="briefcase-medical"
  iconClass="text-rotation-type"
  theme="rotation-type"
  dataTest="rotation-type-tag"
/>
<TagCloudItems
  {hideTooltip}
  items={specialties}
  type="Specialty"
  icon="file-certificate"
  iconClass="text-specialty"
  theme="specialty"
  dataTest="specialty-tag"
/>
<TagCloudItems
  {hideTooltip}
  items={disciplines}
  type="Discipline"
  icon="book"
  iconClass="text-discipline"
  theme="discipline"
  dataTest="discipline-tag"
/>
<TagCloudItems {hideTooltip} items={orgTags} type="Organization type" icon="building" iconClass="color-text-steel" theme={null} dataTest="org-tag" />

<script>
  import TagCloudItems from 'components/TagCloud.Items.svelte'

  export let rotationTypes
  export let specialties
  export let disciplines
  export let orgTags
  export let hideTooltip
</script>

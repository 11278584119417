<FormGroup {valid} class="mb0">
  <label for="typed-confirmation" class="must-type">
    <Icon name={valid ? 'check' : 'alert-triangle'} class={valid ? 'text-success' : 'text-warning'} fw />
    Type
    <strong use:tip={'Click to select'}>{expectedValue}</strong>
    to confirm your intent.
  </label>
  <InputText bind:value name="typed-confirmation" bind:this={inputElem} {autofocus} />
</FormGroup>

<script>
  import tip from 'decorators/tip.js'
  import { caseAndWhitespaceInsensitiveEqual } from 'services/string-utils.js'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import InputText from 'components/fields/InputText.svelte'

  export let expectedValue
  export let autofocus = false
  export let valid

  $: valid = caseAndWhitespaceInsensitiveEqual(expectedValue, value)

  let value
  let inputElem

  export function focus() {
    inputElem?.focus?.()
  }
</script>

<style>
  label.must-type {
    font-weight: normal; /* Deemphasize */
    margin: 10px 0;
  }

  label.must-type strong {
    user-select: all;
    background: #eee;
    padding: 5px;
    color: #030303 !important; /* Make it easy to make out even if invalid */
  }
</style>

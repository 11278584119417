{#if conflicts}
  <MergeConflictsMatch bind:local={match} remote={matchInitial} bind:conflict={conflicts.syllabus} />
  <MergeConflictsMatch bind:local={match} remote={matchInitial} bind:conflict={conflicts.studentComments} />
{/if}

<!-- if this section should be expanded, don't look at their remembered value -->
<Collapsible label="Learning objectives" name="learning-objectives" open={expanded} remember={!expanded}>
  <h3 class="m0" slot="label">
    Learning objectives{#if isSyllabusRequired}
      <RequiredMarker />
    {/if}
  </h3>

  <FormGroup valid={!isSyllabusRequired || validator.required(match.syllabus)} validationMessage="This field is required.">
    {#if match.isEditable && match.allowChanges && ($persona.isStudent || $persona.personaType === PersonaType.SchoolStaff)}
      <label for="syllabus">What learning objectives or outcomes should this rotation provide?</label>
      <InputTextarea disabled={!match.isEditable} name="syllabus" bind:value={match.syllabus} />
    {:else}
      <label for="syllabus">Learning objectives</label>
      <Help>What learning objectives or outcomes should this rotation provide?</Help>
      <div data-test="syllabus">
        {#if !validator.empty(match.syllabusHtml)}
          <SafeHtml value={match.syllabusHtml} />
        {:else}
          <HelpBlock>No learning objectives entered.</HelpBlock>
        {/if}
      </div>
    {/if}
  </FormGroup>

  <FormGroup>
    {#if !validator.empty(match.studentCommentsHtml)}
      <label for="student-comments">Additional comments</label>
      <div data-test="student-comments">
        <SafeHtml value={match.studentCommentsHtml} />
      </div>
    {/if}
  </FormGroup>
</Collapsible>

<script>
  import { MatchRequestType, MatchStatus, PersonaType } from 'config/enums.js'
  import Collapsible from 'components/Collapsible.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Help from 'components/Help.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import InputTextarea from 'components/fields/InputTextarea.svelte'
  import MergeConflictsMatch from 'components/MergeConflictsMatch.svelte'
  import persona from 'stores/persona.js'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import SafeHtml from 'components/SafeHtml.svelte'
  import validator from 'services/validator.js'

  export let match
  export let conflicts
  export let matchInitial

  const expanded = match.status === MatchStatus.Unsubmitted

  $: isSyllabusRequired =
    match?.matchRequestType === MatchRequestType.StudentRequested && ($persona.isStudent || $persona.personaType === PersonaType.SchoolStaff)
</script>

<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} usingCustomPicker labelSelector={o => o.name} let:item>
  <div slot="picker" class="mt1 mb1">
    <AgreementPicker bind:value={filter[appliedMeta.filterProp]} multiple or={customPickerShouldUseOr(appliedMeta, filter)} />
  </div>

  <svelte:fragment slot="selectedItem">
    {#if item?.agreementId}
      <OrgProfilePictureAndName
        slot="selectedItem"
        name={$persona.personaType == PersonaType.SchoolStaff ? item.orgName : item.schoolName}
        profilePicture={$persona.personaType == PersonaType.SchoolStaff ? item.orgProfilePicture : item.schoolProfilePicture}
        relativeName={null}
        nameClass="small"
        contentClass="text-left"
      >
        <div class={item.agreementId === filter.simulatedValue ? 'strong text-success' : 'strong'}>{item.name}</div>
      </OrgProfilePictureAndName>
    {:else if !appliedMeta.toMany}
      <em>unspecified</em>
    {/if}
  </svelte:fragment>
</FilterTypeListFilter>

<script context="module">
  import { FeatureType, FilterType, PersonaType } from 'config/enums.js'
  import api from 'services/api'
  import FilterTypeListFilter, { meta as baseMeta, customPickerShouldUseOr } from 'components/filter-types/FilterTypeListFilter.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.Agreements,
    idKey: 'agreementId',
    label: 'Agreement',
    icon: 'agreement',
    iconProps: { autoColor: true },
    allowEmpty: true,
    excludable: true,
    filterProp: 'agreementIds',
    getSelected: async selectedAgreementIds => {
      const body = {
        selectedAgreementIds,
        pageSize: null,
        includePageOfData: false,
        excludeTotalCount: true,
        includeOrgAndTeam: true,
      }
      const { selectedAgreements } = await api.agreement.list(body, api.noMonitor)
      return selectedAgreements
    },
    shouldShow: () => personaService.canUseAnyFeatureType(FeatureType.AgreementManagement),
  }
</script>

<script>
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import AgreementPicker from 'components/fields/AgreementPicker.svelte'

  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>

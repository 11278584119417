{#if wasExplicitlyDeleted || wasEffectivelyDeleted}
  {#if message}
    <div class="flex-row flex-align-center g05{className ? ` ${className}` : ''}" use:tip={tooltip}>
      <Icon name="alert-triangle" />
      <span>{message}</span>
    </div>
  {:else}
    <Icon name="alert-triangle" title={tooltip} class={className} />
  {/if}
{/if}

<div class="hide">
  <div bind:this={tooltipElem}>
    <ShiftLocationDeletedTooltipMessage {wasExplicitlyDeleted} {wasEffectivelyDeleted} />
  </div>
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import ShiftLocationDeletedTooltipMessage from 'components/ShiftLocationDeletedTooltipMessage.svelte'
  import tip from 'decorators/tip.js'

  export let wasExplicitlyDeleted
  export let wasEffectivelyDeleted
  export let message = 'This location has been deleted'
  let className = 'text-danger'
  export { className as class }
  export let tipOptions = {}

  let tooltipElem = null

  $: tooltip = tooltipElem ? { content: tooltipElem, options: { maxWidth: 300, ...tipOptions } } : null
</script>

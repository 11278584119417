<Modal title="Add student" on:close={closeModal}>
  <Form on:submit={submitAddOrReplace}>
    <div class="modal-body">
      <FormGroup>
        <label for="student-picker">Select the student to add</label>
        <StudentPicker
          bind:this={studentPicker}
          bind:selected={_selected}
          bind:open={picking}
          modalTitle="Select the student to add"
          multiple={false}
          orgId={match.guestOrgId}
          capacityId={match.capacity.capacityId}
          capacityStudentYears={match.capacity.studentYears}
          disciplines={match.capacity.disciplines.length > 0 ? match.capacity.disciplines : match.service?.disciplines}
          allowStudentYearOverride={true}
          {excludeUserIds}
          hideSelectAll
        />
      </FormGroup>
      {#if _selected}
        <FormGroup>
          <InputCheckbox bind:checked={replacingPlaceholder} name="replacingPlaceholder" labelClass="mb05"
            >Replace a placeholder student</InputCheckbox
          >
        </FormGroup>
      {/if}
      {#if replacingPlaceholder && shiftsWithPlaceholderStudents?.length > 0 && _selected}
        <div class="mb05">
          The following availability windows have placeholder students scheduled, select any number of them to replace
          {#if match.placeholderStudentCount === 1}
            the placeholder student
          {:else}
            one of the {match.placeholderStudentCount} remaining placeholder students
          {/if}
          with <span class="strongish">{_selected.name}</span>.
        </div>
        <div class="flex-column g1">
          {#each shiftsWithPlaceholderStudents as shift}
            <div class="card-left-{shift.color} shift text-blackText bg-white p1" style="box-shadow: 1px 1px 3px lightgray; border-radius: 5px;">
              <h4 class="m0">
                {shift.name}
              </h4>
              {#if shift.startTime || shift.endTime}
                <StartAndEndTime startTime={shift.startTime} endTime={shift.endTime} />
              {/if}
              <InputCheckboxGroup
                name="selectedMatchDays-{shift.name}"
                let:option
                bind:value={selectedMatchDays[shift.shiftId]}
                options={match.matchDayConfigs.filter(mdc => mdc.shiftId === shift.shiftId).sort((a, b) => new Date(a.date) - new Date(b.date))}
                selectAllNone
              >
                <div class="placeholder-match-day">
                  <div class="flex-row flex-align-center g05">
                    <Icon name="calendar" class="color-text-steel" />
                    <span class="text-right" style="width: 70px;">{option.date}</span>
                    {#if option.startTime || (option.endTime && !(shift.startTime || shift.endTime))}
                      <Icon
                        title={`${dateService.formatTime(option.startTime)} - ${dateService.formatTime(option.endTime)}`}
                        name="time"
                        class="color-text-steel"
                      />
                    {/if}
                  </div>
                  <div class="flex-row flex-align-center g05">
                    <Icon name="placeholder-student" autoColor />
                    <Icon name="times" />
                    {option.placeholderStudentCount}
                    {#if selectedMatchDays[shift.shiftId]?.find(day => day.date === option.date)}
                      <Icon name="arrow-right" />
                      {option.placeholderStudentCount - 1}
                    {:else if option.placeholderStudentCount > match.placeholderStudentCount - 1}
                      <span style="opacity: .6;">
                        <Icon name="arrow-right" />
                        {option.placeholderStudentCount - 1}
                        <span
                          use:tip={`Regardless of if you add <strong>${_selected.name}</strong>, we will be reducing the placeholders on this date from ${option.placeholderStudentCount} to ${option.placeholderStudentCount - 1} because the rotation will only have ${pluralCount('placeholder student', match.placeholderStudentCount - 1)}`}
                        >
                          (max {match.placeholderStudentCount - 1})
                        </span>
                      </span>
                    {/if}
                  </div>
                </div>
              </InputCheckboxGroup>
            </div>
          {/each}
        </div>
      {/if}
    </div>

    <div class="modal-footer flex-row flex-align-center g05">
      <SubmitBtn icon={replacingPlaceholder && _selected ? 'replace' : 'plus'} disabled={_selected == null} dataTest="save-student-btn">
        {#if replacingPlaceholder && _selected}
          <span>Replace a placeholder student with {_selected.name}</span>
        {:else if _selected}
          <span>Add {_selected.name}</span>
        {:else}
          <span>Add student</span>
        {/if}
      </SubmitBtn>
      <Btn icon="close" on:click={closeModal}>Cancel</Btn>
    </div>
  </Form>
</Modal>

<script>
  import { pluralCount } from 'services/string-utils.js'
  import dateService from 'services/date-service.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import InputCheckboxGroup from './InputCheckboxGroup.svelte'
  import Modal from 'components/Modal.svelte'
  import StartAndEndTime from 'components/StartAndEndTime.svelte'
  import StudentPicker from 'components/fields/StudentPicker.svelte'
  import SubmitBtn from 'components/bootstrap/SubmitBtn.svelte'
  import tip from 'decorators/tip.js'

  // Common picker exports
  export let value
  export let open = false

  // Specific picker exports
  export let selected = null
  export let replacementConfig = null
  export let excludeUserIds = null
  export let onClose = _.noop
  export let match

  let valueTemp = null

  let _selected = selected

  let studentPicker = null
  let picking = false
  let selectedMatchDays = {}
  let replacingPlaceholder = true

  $: shiftsWithPlaceholderStudents = match.shifts.filter(s => match.matchDayConfigs.some(mdc => mdc.shiftId === s.shiftId))

  function submitAddOrReplace() {
    selected = _selected
    replacementConfig = replacingPlaceholder && _selected ? { selectedMatchDays } : null
  }

  function closeModal() {
    onClose()
    open = false
    _selected = null
    selected = null
    selectedMatchDays = {}
  }

  export function clear() {
    value = null
    valueTemp = null
  }
</script>

<style>
  .placeholder-match-day {
    width: 100%;
    display: grid;
    grid-template-columns: 100px 1fr;
    grid-gap: 5px;
  }
</style>

import { NameSuggestionIssue } from 'config/enums.js'

function makeStatusHelper(key, icon, label, iconClass, iconProps = {}) {
  return { key, icon, label, iconProps: { class: iconClass, lg: true, ...iconProps } }
}

const statuses = {
  [NameSuggestionIssue.ExceptionThrown]: makeStatusHelper(NameSuggestionIssue.ExceptionThrown, 'bomb', 'Exception thrown', null, { autoColor: true }),
  [NameSuggestionIssue.AiResponseIsNotValidJson]: makeStatusHelper(
    NameSuggestionIssue.AiResponseIsNotValidJson,
    'brackets-curly',
    'Invalid JSON',
    'text-danger'
  ),
  [NameSuggestionIssue.AiResponseHasKeysWithWrongTypes]: makeStatusHelper(
    NameSuggestionIssue.AiResponseHasKeysWithWrongTypes,
    'shapes',
    'Wrong types',
    'text-danger'
  ),
  [NameSuggestionIssue.AiResponseHasUnrecognizedKeys]: makeStatusHelper(
    NameSuggestionIssue.AiResponseHasUnrecognizedKeys,
    'block-question',
    'Unrecognized keys',
    'text-warning'
  ),

  [NameSuggestionIssue.AiResponseConfidenceOutOfRange]: makeStatusHelper(
    NameSuggestionIssue.AiResponseConfidenceOutOfRange,
    'user-robot-xmarks',
    'AI confidence out of range',
    'color-text-steel'
  ),
}

function get(status) {
  return statuses[status]
}

export default {
  get(status) {
    return get(status)
  },
}

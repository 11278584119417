<div class="mb2">
  <Collapsible name="capacity-info" bind:open>
    <h3 slot="label" class="mb0">
      {#if showName}
        <div class="small">Opportunity overview</div>
        <div class="flex-row flex-align-center g05">
          <CapacityGroupSizeIcon {capacity} iconProps={{ sm: true }} />
          <span>{capacity.name}</span>
        </div>
      {:else}
        Opportunity information
      {/if}
    </h3>
    <div class="opportunity-information-grid">
      <div>
        <h3 class="mb2">General information</h3>
        <div class="flex-row mb05 mr1 g2">
          <div class="general-info-left">
            <p class="mb05"><strong>Location</strong></p>
            <div class="mb2">
              <CapacityLocations {capacity} />
            </div>
            <p class="mb05"><strong>Service</strong></p>

            <div class="mb05">
              {#if capacity.service}
                <p class="mb05">
                  <a href="/services/{capacity.serviceId}"><Icon name="shapes" class="color-text-purple mr05" />{capacity.service.name}</a>
                </p>
              {/if}
              {#if capacity.rotationTypes.length}
                <p class="mb05"><strong>{pluralCount('Experience type', capacity.rotationTypes.length, 'omitNumber')}</strong></p>
                <TagCloud ctx={{ rotationTypes: capacity.rotationTypes }} class="flex-column g05" hideTooltip />
              {/if}
              {#if capacity?.specialties.length || capacity?.service?.specialties.length}
                {@const specialties = computeCapacitySpecialties(capacity)}
                <p class="mb05"><strong>{pluralCount('Specialty', specialties.length, 'omitNumber')}</strong></p>
                <TagCloud ctx={{ specialties }} class="flex-column g05" hideTooltip />
              {/if}
            </div>
            <p class="mb05"><strong>Lead time</strong></p>
            <CapacityLeadTimeLabel {capacity} verbose={false} />
          </div>
          <div class="general-info-right">
            <p class="mb05"><strong>Rotation scheduling settings</strong></p>
            <div class="mb05">
              <div class="mb05">
                {#if allowOverlap}
                  <div class="flex-row g05">
                    <Icon name="overlap" class="color-text-black" />
                    <div>Allows overlap</div>
                  </div>
                  <HelpBlock>Rotations can be requested regardless of if the given day’s max has been met with any rotation status.</HelpBlock>
                {:else}
                  <div class="flex-row g05">
                    <Icon name="bars" class="color-text-black" />
                    <div>No new requests when full</div>
                  </div>
                  <HelpBlock
                    >Rotations can be requested until a given day’s max has been met with <strong
                      >{formatEnumValue(MatchHasBeen, capacity.noNewMatchesForDayWhenOthersHaveBeen)}</strong
                    > rotations.</HelpBlock
                  >
                {/if}
              </div>
            </div>
            <div class="mb05">
              {#if capacity.postToSearchPage}
                <div class="flex-row g05">
                  <Icon name="search-success" autoColor />
                  <div>
                    <div>Allow students to search and apply</div>
                    {#if capacity.allowStudentScheduling}
                      <div class="mt05">Students can set detailed schedules</div>
                    {/if}
                  </div>
                </div>
              {:else}
                <Icon name="search-fail" autoColor />
                <strong>DOESN’T</strong> allow students to search and apply
              {/if}
            </div>
            <div class="mb05">
              {#if capacity.allowCoordinatorScheduling}
                <div class="flex-row g05">
                  <Icon name="calendar" class="color-text-black" />
                  <div>
                    <div>Allows coordinator scheduling</div>
                    {#if capacity.allowGuestCoordinatorScheduling && capacity.allowHostCoordinatorScheduling && isHealth}
                      <div class="mt05">Clinical site <strong>AND</strong> school coordinators can set detailed schedules</div>
                    {:else if capacity.allowHostCoordinatorScheduling && isHealth}
                      <div class="mt05">Clinical site coordinators can set detailed schedules</div>
                    {:else if capacity.allowGuestCoordinatorScheduling}
                      <div class="mt05">School coordinators can set detailed schedules</div>
                    {:else}
                      <div />
                    {/if}
                    {#if capacity.allowStudentChanges}
                      <div class="mt05">Students are allowed to request changes</div>
                    {:else}
                      <div class="mt05">Students are <strong>NOT</strong> allowed to request changes</div>
                    {/if}
                  </div>
                </div>
              {:else}
                <Icon name="cancel" class="color-text-black" /> <strong>DOESN’T</strong> allow coordinator scheduling
              {/if}
            </div>
            {#if isGroup}
              <div class="flex-row g05">
                <Icon name="users" class="color-text-black" />
                {#if capacity.allowStudentsToLeaveGroupMatch}
                  <div class="mb05">Students are allowed to leave groups once added</div>
                {:else}
                  <div>
                    Students are <strong>NOT</strong> allowed to leave groups once added
                  </div>
                {/if}
              </div>
            {/if}
          </div>
        </div>
      </div>
      <div>
        <h3 class="mb2">School & availability information</h3>
        <div class="flex-row mb05 mr1 g2">
          <div>
            <div class="mr2">
              <p class="mb05"><strong>Opportunity dates</strong></p>
              <StartAndEndDate startDate={capacity.startDate} endDate={capacity.endDate} />
            </div>
            {#if isHealth}
              <p class="mb05"><strong>Available rotations</strong></p>
              <div class="mb05">
                {#if capacity.maxMatches}
                  {totalCapacityMatchCount} / {capacity.maxMatches}
                  <CapacityGroupSizeIcon {capacity} />
                {:else}
                  {totalCapacityMatchCount} /
                  <Icon name="infinity" lg class="text-info mr05" />
                  Unlimited
                {/if}
                {#if isGroup}
                  group
                {/if}
                {pluralCount('rotation', capacity.maxMatches, 'omitNumber')}
                available
              </div>
            {/if}
            <p class="mb05"><strong>Student requirements</strong></p>
            <div class="mb05">
              {#each capacity.studentYears as studentYear}
                <div class="flex-row flex-align-center g05">
                  <Icon name="calendars" class="color-text-teal" />
                  <em class="text-gray">{toStudentYear(studentYear)} students</em>
                </div>
              {:else}
                <div class="flex-row flex-align-center g05">
                  <Icon name="calendars" class="color-text-teal" />
                  <em class="text-gray">Any year student</em>
                </div>
              {/each}
            </div>
            {#if isHealth}
              <p class="mb05"><strong>Disciplines</strong></p>
              <div class="mb05">
                {#if capacity}
                  {#if capacity.disciplines.length}
                    <div use:tip={tooltipElem ? { content: tooltipElem, options: { interactive: true, theme: 'light-gray-scrollable' } } : null}>
                      Custom disciplines set
                    </div>
                  {:else}
                    <div use:tip={tooltipElem ? { content: tooltipElem, options: { interactive: true, theme: 'light-gray-scrollable' } } : null}>
                      Inherit school disciplines
                    </div>
                  {/if}
                {/if}
              </div>
            {/if}
            <p class="mb05"><strong>Schedule allowances</strong></p>
            <div class="mb05">
              {#if capacity.requestAvailabilityPolicy === RequestAvailabilityPolicy.MultipleAvailableShifts}
                Users can schedule into <strong class="strongish">multiple</strong> windows they have access to
              {:else}
                Users can schedule into a <strong class="strongish">single</strong> windows they have access to
              {/if}
            </div>
          </div>

          {#if filteredGuests?.length}
            {#if filteredGuests.length === 1}
              <Spotlight
                enabled={spotlightEnabled}
                spotlightClass="grid g1 p2 rounded-lg border-2 btn-pulsate-info bg-white"
                spotlightStyle="grid-template-columns: auto auto auto; grid-template-rows: auto auto auto; grid-template-areas: 'header header header' 'before arrow after' 'footer footer footer'"
              >
                <svelte:fragment slot="before">
                  <h3 class="m0 pb2" style="grid-area: header">
                    <Icon name="info" class="text-info" />
                    The rotations(s) will be associated with this school instead.
                  </h3>
                  <div style="grid-area: before">
                    <CapacityGuestCardDetailed {capacity} guest={capacityGuestBefore} />
                  </div>
                  <div class="p2" style="grid-area: arrow; align-self: center">
                    <Icon x3 name="arrow-right" />
                  </div>
                </svelte:fragment>

                <div style="grid-area: after">
                  <CapacityGuestCardDetailed {capacity} guest={capacityGuest ?? filteredGuests[0]} />
                </div>

                <div slot="after" class="pt2" style="grid-area: footer">
                  <Btn
                    class="btn btn-primary"
                    on:click={() => {
                      capacityGuestBefore = capacityGuest
                    }}>Got it</Btn
                  >
                </div>
              </Spotlight>
            {:else}
              <div style="max-height: 300px; overflow-y:auto; overflow-x:hidden;">
                {#each filteredGuests as guest}
                  <CapacityGuestCardLimited {capacity} {guest} />
                {/each}
              </div>
            {/if}
          {/if}
        </div>
      </div>
    </div>
  </Collapsible>
</div>
<div class="hide">
  <div bind:this={tooltipElem} class="flex-column g05 text-left py05">
    <em>
      {#if capacity.disciplines.length}
        Inherited from
      {:else}
        Subset inherited from
      {/if}
      {#if capacity.service}
        <Icon name="shapes" class="color-text-purple" />
        {capacity.service.name}
      {/if}
    </em>
    {#if capacity.disciplines.length}
      {#each capacity.disciplines as discipline}
        <div><Icon name="book" class="color-text-teal" /> {discipline}</div>
      {/each}
    {:else}
      <div class="flex-row flex-align-center g05">
        <Icon name="book" class="color-text-teal" /><em>Any discipline</em>
      </div>
    {/if}
  </div>
</div>

<script>
  import { computeCapacitySpecialties } from 'components/InheritedTagPicker.svelte'
  import { formatEnumValue } from 'services/formatters.js'
  import { PersonaType, RequestAvailabilityPolicy, MatchHasBeen } from 'config/enums.js'
  import { pluralCount } from 'services/string-utils.js'
  import { toStudentYear } from 'services/formatters.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Spotlight from 'components/Spotlight.svelte'
  import CapacityGroupSizeIcon from 'components/CapacityGroupSizeIcon.svelte'
  import CapacityLeadTimeLabel from 'components/CapacityLeadTimeLabel.svelte'
  import CapacityLocations from 'components/CapacityLocations.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import CapacityGuestCardDetailed from './CapacityGuestCardDetailed.svelte'
  import CapacityGuestCardLimited from './CapacityGuestCard.Limited.svelte'
  import persona from 'stores/persona.js'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import TagCloud from 'components/TagCloud.svelte'
  import tip from 'decorators/tip.js'

  export let capacity
  export let showName = false
  export let capacityGuest = null
  export let capacityGuestBefore = null
  export let canSpotlightCapacityGuestChange = false

  let tooltipElem
  let open = true

  $: allowOverlap = !capacity.noNewMatchesForDayWhenOthersHaveBeen
  $: isGroup = capacity.allowGroups
  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: totalCapacityMatchCount = capacity?.guests?.[0]?.totalCapacityMatchCount ?? 0
  $: filteredGuests = capacityGuest ? [capacityGuest] : capacity.guests
  $: spotlightEnabled =
    canSpotlightCapacityGuestChange &&
    capacityGuestBefore &&
    capacityGuest &&
    (capacityGuestBefore.guestOrgId ?? null) !== (capacityGuest.guestOrgId ?? null)

  $: if (spotlightEnabled) open = true
</script>

<style>
  .opportunity-information-grid {
    padding: 0 50px;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    grid-gap: 1rem;
  }
</style>

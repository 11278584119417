<FilterTypeAddress {filter} {editing} {label} />

<script context="module">
  import { FilterType } from 'config/enums.js'
  import FilterTypeAddress, { meta as baseMeta } from 'components/filter-types/FilterTypeAddress.svelte'

  const label = 'School address'
  export const meta = {
    ...baseMeta,
    type: FilterType.MatchGuestOrgAddress,
    label,
    iconClass: 'color-text-blue',
  }
</script>

<script>
  export let filter
  export let editing = false
</script>

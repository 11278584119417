{#if service}
  <Modal on:close={onClose} xl>
    <h4 slot="title" data-test="service-header">
      {#if service?.serviceId > 0}
        <div class="small">Service</div>
        {service.name}
      {:else}
        New service
      {/if}
    </h4>

    {#if personaService.canEditService(service)}
      <ServiceForm {service} {onClose} {onSaved} />
    {:else}
      <ServiceView {service} />
    {/if}
  </Modal>
{/if}

<script>
  import api from 'services/api.js'
  import getValueFromCollectionBySubpath from 'services/get-value-from-collection-by-subpath.js'
  import Modal from 'components/Modal.svelte'
  import personaService from 'services/persona-service.js'
  import ServiceForm from 'components/ServiceForm.svelte'
  import ServiceView from 'components/ServiceView.svelte'

  export let subpath // comes from <Route>.path template
  export let services
  export let listBodyIncludes
  export let onClose = _.noop
  export let onSaved = _.noop

  let service
  $: subpath, services, setService()

  function setService() {
    const maybeValue = getValueFromCollectionBySubpath(services, subpath, 'serviceId', newService, copyService, loadService)
    if (maybeValue) service = maybeValue
  }

  async function loadService(serviceId, shouldCopy) {
    const response = await api.service.list({ ...listBodyIncludes, selectedServiceIds: [serviceId], pageSize: 0, excludeTotalCount: true })
    const selected = response.selectedServices[0]
    service = shouldCopy ? copyService(selected) : selected
  }

  function copyService(service) {
    const copy = _.cloneDeep(service)
    copy.name += ' (copy)'
    copy.serviceId = 0
    copy.rotationCount = 0
    return copy
  }

  function newService() {
    return {
      serviceId: null,
      orgId: null,
      teamId: null,
      name: '',
      description: '',
      agreementIds: [],
      staffIds: [],
      staff: [],
    }
  }
</script>

<FilterTypeOrg {filter} {editing} {filterOptions} {appliedMeta} />

<script context="module">
  import FilterTypeOrg, { meta as baseMeta } from 'components/filter-types/FilterTypeOrg.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.CapacityShiftLocations,
    label: 'Availability window location',
    labelApplied: 'availability window location',
    icon: 'hospital',
    iconClass: 'color-text-orange',
    toManySuffix: 'of the following locations',
    filterProp: 'locationIds',
    optionsEndpoint: 'getCapacityShiftLocations',
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>

<div class="flex-column g05 flex-nowrap">
  {#each shift.shiftDays as shiftDay, i (shiftDay.shiftDayId)}
    {#if i || leadingHr}<hr class="my05" />{/if}
    <ShiftDay {capacity} {shiftDay} {shift}></ShiftDay>
  {/each}
</div>

<script>
  import ShiftDay from 'components/ShiftDay.svelte'

  export let capacity
  export let shift
  export let leadingHr = false
</script>

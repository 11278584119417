<div style="height: {height}px" data-test={id} class={className}>
  <canvas bind:this={canvasEl} {id} {height} on:click={chartClick} />
  <ChartToolTip {chart} {tooltip} countSubject={customTooltip} />
</div>

<script>
  import { createEventDispatcher, onDestroy } from 'svelte'
  // this component is a very simple wrapper for chartjs
  // to use it, set up your data and options according to https://www.chartjs.org/, then...
  // <Chart {data} {options} />
  // if data or options change, <Chart /> will set them into the chartjs instance and call its update() method
  import Chart from 'chart.js'
  import ChartToolTip from 'components/ChartToolTip.svelte'

  export let id = 'chart1'
  export let height = 250
  export let type = 'bar'
  export let data = null
  export let options = null
  export let customTooltip = null
  let className = null
  export { className as class }

  const dispatch = createEventDispatcher()
  let canvasEl
  let chart
  let tooltip

  // make chart fill the background - css background color on canvas works for browser, but leaves it transparent when exporting to jpg or png
  Chart.pluginService.register({
    beforeDraw: function (chart) {
      if (chart.config.options.backgroundColor) {
        const ctx = chart.chart.ctx
        ctx.save()
        ctx.fillStyle = chart.config.options.backgroundColor
        ctx.fillRect(0, 0, chart.chart.width, chart.chart.height)
        ctx.restore()
      }
    },
  })

  Chart.defaults.global.responsive = true
  Chart.defaults.global.maintainAspectRatio = false
  Chart.defaults.global.backgroundColor = 'white'
  Chart.defaults.global.legend.display = false

  // init chart (if we have all props ready)
  // and update chart on changes to any of these props
  const createOrUpdateChartDebounced = _.debounce(createOrUpdateChart, 10)
  $: if (canvasEl != null && type != null && data != null && options != null) createOrUpdateChartDebounced()

  // you can handle on-click and use chart.getActiveElement(event.event) or chart.getDatasetAtEvent(event.event) etc
  function chartClick(event) {
    dispatch('click', { event, chart })
  }

  function createOrUpdateChart() {
    if (destroyed) return
    // supposed to be able to call chart.update() if it's already rendered
    // but was throwing fits on totals report, so we just destroy() it for now..
    // todo: figure out why later so it animates smoothly as settings/data changes
    destroyChartIfExists()

    const ctx = canvasEl.getContext('2d')

    chart = new Chart(ctx, { data, options: buildOptions(), type })
  }

  let destroyed = false
  onDestroy(() => {
    destroyed = true
    destroyChartIfExists()
  })

  function buildOptions() {
    if (customTooltip == null) return options
    return {
      ...options,
      tooltips: {
        enabled: false,
        mode: 'index',
        positions: 'nearest',
        custom: tip => (tooltip = tip),
      },
    }
  }

  function destroyChartIfExists() {
    if (chart != null) chart.destroy()
  }
</script>

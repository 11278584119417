<table class="text-left tip-table">
  {#if incompleteButScheduled > 0}
    <tr class="text-purple">
      <td class="text-right">{readableNumber(incompleteButScheduled)}</td>
      <td>scheduled {hour(incompleteButScheduled)} to complete</td>
    </tr>
  {/if}
  {#if completed > 0}
    <tr class="text-success">
      <td class="text-right">{readableNumber(completed)}</td>
      <td>completed {hour(completed)}</td>
    </tr>
  {/if}
  {#if extraScheduled > 0}
    <tr class="text-warning">
      <td class="text-right">{readableNumber(extraScheduled)}</td>
      <td>extra scheduled {hour(extraScheduled)}</td>
    </tr>
  {/if}
  {#if extraWorked > 0}
    <tr class="text-danger">
      <td class="text-right">{readableNumber(extraWorked)}</td>
      <td>extra worked {hour(extraWorked)}</td>
    </tr>
  {/if}
  {#if unscheduled > 0}
    <tr class="text-sky">
      <td class="text-right">{readableNumber(unscheduled)}</td>
      <td>unscheduled {hour(unscheduled)}</td>
    </tr>
  {/if}
</table>

<script>
  import { readableNumber, singularOrPlural } from 'services/string-utils'

  export let incompleteButScheduled
  export let completed
  export let extraScheduled
  export let extraWorked
  export let unscheduled

  const hour = count => singularOrPlural(count, 'hour')
</script>

<style>
  table tr td:last-child {
    padding-left: 2px;
  }
</style>

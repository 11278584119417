{#if hasMoreThan1PersonaOrg}
  <SimpleOrgPicker
    name="persona-filters-org"
    ancestorOrgId={personaOrgId}
    bind:value={$personaFilters.orgId}
    bind:selected={$personaFilters.org}
    placeholder="Filter location"
    title="Filter by location"
    {tooltipPlacement}
  />
{/if}

{#if personaTeams?.length > 0}
  <TeamPicker
    name="persona-filters-team"
    teams={personaTeams}
    bind:value={$personaFilters.teamId}
    bind:selected={$personaFilters.team}
    placeholder="Filter team"
    title="Filter by team"
    {tooltipPlacement}
    addNullTeamOption
  />
{/if}

<script>
  import { FilterType, PersonaType } from 'config/enums.js'
  import api from 'services/api.js'
  import persona from 'stores/persona.js'
  import personaFilters from 'stores/persona-filters.js'
  import pubsub from 'services/pubsub.js'
  import SimpleOrgPicker from './SimpleOrgPicker.svelte'
  import TeamPicker from 'components/TeamPicker.svelte'
  import user from 'stores/user.js'
  import { updateSelectedPersonaValues } from 'stores/selected-persona-values.js'

  const tooltipPlacement = 'right'

  let personaTeams
  let hasMoreThan1PersonaOrg = false
  const validPersonaTypes = [PersonaType.SchoolStaff, PersonaType.ProviderStaff, PersonaType.ConsortiumStaff]

  pubsub.on('orgs-changed', () => loadOptions())
  pubsub.on('teams-changed', () => loadOptions())

  $: personaOrgId = $persona.orgId
  $: personaFiltersOrgId = $personaFilters.orgId
  $: personaFiltersTeamId = $personaFilters.teamId
  $: personaPersonaType = $persona.personaType
  $: validPersonaType = validPersonaTypes.includes(personaPersonaType)
  $: personaOrgId, validPersonaType, setHasMoreThan1PersonaOrg()
  $: validPersonaType, personaOrgId, personaFiltersOrgId, loadOptions()
  $: personaFiltersOrgId, personaFiltersTeamId, setSavedPersonaFilters()

  function setSavedPersonaFilters() {
    updateSelectedPersonaValues($user.userId, {
      orgId: personaFiltersOrgId,
      teamId: personaFiltersTeamId,
    })
  }

  async function loadOptions() {
    if (!validPersonaType) {
      personaTeams = null
      return
    }
    const orgId = personaOrgId
    const response = await api.org.getTeams({ orgId }, api.noMonitor)
    if (personaOrgId !== orgId) return // if persona orgid changed before response, ignore it
    personaTeams = response
  }

  async function setHasMoreThan1PersonaOrg() {
    if (!personaOrgId || !validPersonaType) {
      hasMoreThan1PersonaOrg = false
      return
    }
    const res = await api.org.list(
      {
        pageSize: 2,
        excludeTotalCount: true,
        filters: [
          {
            type: FilterType.HasAncestorOrg,
            config: { ancestorOrgIds: [personaOrgId] },
          },
        ],
      },
      api.noMonitor
    )
    hasMoreThan1PersonaOrg = res.orgs.length > 1
  }
</script>

<div
  class="custom-tag inline-flex-row flex-align-center g2"
  class:g05={!customTag.teamOrgId}
  class:g2={customTag.teamOrgId}
  style="--hue: {hue}; {noOwner ? 'padding: 0 3px; border-width: 1px' : 'padding: 5px; border-width: 2px'}"
  use:tip={tooltipPlacement == null
    ? null
    : { content: tooltipElem, options: { interactive: tooltipInteractive, placement: tooltipPlacement, theme: 'light-gray-scrollable' } }}
>
  {#if !noOwner}
    <div class="relative" style="line-height: 0">
      <ProfilePic small name={customTag.orgName} src={customTag.orgProfilePicture} />
      {#if customTag.teamOrgId}
        <div class="absolute right bottom" style="transform: translateX(75%);">
          <TeamProfilePicture xs name={customTag.teamName} orgId={customTag.teamOrgId} />
        </div>
      {/if}
    </div>
  {/if}
  <strong>{customTag.name}</strong>
</div>

{#if tooltipPlacement}
  <div class="hide">
    <div bind:this={tooltipElem}>
      <div class="flex-column g1 my05 text-left">
        <h4 class="mb0" style="line-height: 1">
          Custom tag from owner location {#if customTag.teamOrgId}and team{/if}
        </h4>
        <OrgProfilePictureAndName
          size="medium"
          name={customTag.orgName}
          relativeName={customTag.orgRelativeName}
          profilePicture={customTag.orgProfilePicture}
        />
        {#if customTag.teamOrgId}
          <TeamProfilePictureAndName medium name={customTag.teamName} orgId={customTag.teamOrgId} />
        {/if}
        {#if customTag.descriptionHtml}
          <RenderedMarkdown html={customTag.descriptionHtml} />
        {/if}
      </div>
    </div>
  </div>
{/if}

<script>
  import colorService from 'services/color-service.js'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import RenderedMarkdown from 'components/RenderedMarkdown.svelte'
  import TeamProfilePicture from 'components/TeamProfilePicture.svelte'
  import TeamProfilePictureAndName from 'components/TeamProfilePictureAndName.svelte'
  import tip from 'decorators/tip.js'

  export let customTag
  export let tooltipPlacement = 'top'
  export let tooltipInteractive = true
  export let noOwner = false

  // style="background-color: hsl({hue}, 70%, 45%)"
  $: hue = colorService.getNonErrorHue(customTag.name)
  let tooltipElem = null
</script>

<style lang="scss">
  .custom-tag {
    // This sets it to 50% of the smaller dimension,
    // which is hopefully the vertical one.
    border-radius: 5px;
    border-style: solid;
    border-color: hsl(var(--hue), 70%, 45%);
    color: hsl(var(--hue), 100%, 25%);
    background: hsl(var(--hue), 75%, 94%);
  }
</style>

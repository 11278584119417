<ButtonModalPicker
  bind:this={buttonModalPicker}
  bind:open
  lg
  {disabled}
  {multiple}
  {loading}
  valueSelector={s => s.serviceId}
  labelSelector={s => s.name}
  options={services}
  optionsForLabel={servicesForLabel}
  bind:value
  bind:valueTemp
  {modalTitle}
  {placeholder}
  class={className}
  {dataTest}
  modalClass="overflow-visible"
  modalContentClass="overflow-visible"
  {allowSelectNull}
  {or}
>
  <!-- We don't want the contents of the picker to appear invalid, so wrap it in an always-valid <FormGroup> -->
  <FormGroup valid class="full-height flex-column g1">
    <ServiceFilters fullWidth {excludedFilterTypes} bind:filters {interceptors} onChanged={() => loadPage(0)}>
      <svelte:fragment slot="after-keyword-search">
        <ServicePickerShowDropdown />
      </svelte:fragment>
    </ServiceFilters>

    {#if loading && currentXhrBody.offset === 0}
      <div class="text-center mt1">
        <Spinner x3 class="m2" />
      </div>
    {:else}
      <InfiniteScroll
        currentCount={services?.length}
        {totalCount}
        distanceToLoadPage={100}
        {loadPage}
        class="scrollable-lg flex-grow flex-column {showMinimal ? 'g05' : 'g1'}"
        style="padding-right: 15px"
      >
        <EmptyPickerSlot
          bind:valueTemp
          {allowSelectNull}
          text="No service"
          {multiple}
          {buttonModalPicker}
          icon="shapes"
          iconClass="color-text-purple"
          {dataTest}
          lg={!showMinimal}
        />
        {#if services?.length}
          {#each services as s, i (s.serviceId)}
            <PickerSlot
              {i}
              {dataTest}
              {multiple}
              value={s.serviceId}
              {buttonModalPicker}
              lg={!showMinimal}
              bind:valueTemp
              let:isSelected
              let:isHovered
            >
              <slot name="header" service={s} slot="header">
                <Icon lg={!showMinimal} name="shapes" class="color-text-purple" />
                <svelte:element this={showMinimal ? 'h4' : 'h3'} class="leading-none m0 normal">{s.name}</svelte:element>
              </slot>

              <slot service={s} {isSelected} {isHovered}>
                <ServicePickerSlot service={s} {isHovered} {isSelected} />
              </slot>
            </PickerSlot>
          {/each}
        {:else if services == null}
          <!-- This should never happen, but just in case... -->
          Failed to load services. <a href={null} on:click={() => loadPage(0)}>Retry?</a>
        {:else}
          <h4 class="p3 text-center">No services found.</h4>
        {/if}
      </InfiniteScroll>
    {/if}
  </FormGroup>
</ButtonModalPicker>

<script>
  import { FeatureType } from 'config/enums.js'
  import api from 'services/api.js'
  import ButtonModalPicker from 'components/fields/ButtonModalPicker.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import InfiniteScroll from 'components/InfiniteScroll.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import PickerSlot from 'components/PickerSlot.svelte'
  import ServiceFilters from 'components/ServiceFilters.svelte'
  import ServicePickerShowDropdown from 'components/ServicePickerShowDropdown.svelte'
  import ServicePickerSlot from 'components/fields/ServicePicker.Slot.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import Spinner from 'components/Spinner.svelte'
  import validator from 'services/validator.js'
  import EmptyPickerSlot from 'components/EmptyPickerSlot.svelte'

  // Common picker exports
  export let value
  export let filters = []
  export let excludedFilterTypes = []
  export let placeholder = 'None selected'
  export let multiple = false
  export let modalTitle = multiple ? 'Select the services' : 'Select the service'
  export let disabled = false
  export let allowSelectNull = false
  let className = ''
  export { className as class }
  export let dataTest = 'service-picker'
  export let interceptors = {}
  export let open = false
  export let or = false

  // Specific picker exports
  export let serviceCount = null
  export let selectedService = null

  const pageSize = 15
  let buttonModalPicker = null
  let totalCount = null
  let services = null
  let servicesForLabel = []
  let valueTemp = null
  let loading = false
  let currentXhrBody = null

  $: show = $showDropdowns.servicePicker
  $: showMinimal = !Object.keys(show)
    .map(k => show[k])
    .some(Boolean)

  // TODO(services): Retain changes in a store.
  $: hasCoreSchedulingFeature = personaService.canUseAnyFeatureType(FeatureType.CoreScheduling)
  $: personaOrgId = $persona.orgId
  $: personaOrgId, hasCoreSchedulingFeature, loadPage(0)
  $: open, loadFirstPageIfFiltersChanged()
  $: allServices = services && servicesForLabel ? [...services, ...servicesForLabel] : []
  $: selectedService = value && allServices?.length ? allServices.find(s => s.serviceId === value) ?? null : null

  function loadFirstPageIfFiltersChanged() {
    if (!open || validator.equals(filters ?? [], currentXhrBody?.filters ?? [])) return
    if (open) loadPage(0)
  }

  async function loadPage(offset) {
    if (!$persona.orgId || !hasCoreSchedulingFeature) return

    const thisXhrBody = {
      filters: _.cloneDeep(filters),
      includeOrgAndTeam: true,
      includeAddresses: true,
      includeAgreements: true,
      includeSpecialties: true,
      includeDisciplines: true,
      pageSize,
      offset,
    }

    const selectedServiceIds = (multiple ? value ?? [] : [value]).filter(id => id != null && !servicesForLabel.some(s => s.serviceId === id))
    if (selectedServiceIds.length) thisXhrBody.selectedServiceIds = selectedServiceIds

    if (validator.equals(currentXhrBody, thisXhrBody)) return
    loading = true
    currentXhrBody = thisXhrBody

    try {
      const task = api.service.list(thisXhrBody, api.noMonitor)
      const response = await task
      // TODO: Could handle if filters were changed prior to response being received... for now, assume server is fast enough.
      totalCount = response.totalCount
      services = offset ? [...services, ...response.services] : response.services
      if (selectedServiceIds.length) servicesForLabel = [...servicesForLabel, ...(response.selectedServices ?? [])]
      if (offset === 0 && serviceCount == null) serviceCount = totalCount
    } finally {
      if (validator.equals(currentXhrBody, thisXhrBody)) loading = false
    }
  }

  export function clear() {
    value = null
    valueTemp = null
  }

  export function focusAndOpen() {
    buttonModalPicker?.focusAndOpen()
  }
</script>

import toaster from './toaster.js'

// some 3rd party scripts don't want us to bundle their js, so that they can update without us needing to.
// so load those in as we need them and only load them once.
export default function loadExternalScript(scriptUrl, scriptFriendlyName, extraAttributes) {
  return new Promise((res, rej) => {
    if (window.cn3rdPartyScripts == null) window.cn3rdPartyScripts = {}
    if (window.cn3rdPartyScripts[scriptUrl] == null)
      window.cn3rdPartyScripts[scriptUrl] = { loading: false, loaded: false, failed: false, onLoaded: [res], onFailed: [rej] }

    // if the script has been loaded or if it failed to load
    if (window.cn3rdPartyScripts[scriptUrl].loaded) {
      res()
      return
    } else if (window.cn3rdPartyScripts[scriptUrl].failed) {
      rej(window.cn3rdPartyScripts[scriptUrl].error)
      return
    }

    // if we're waiting for the script to load already, just add these new listeners
    if (window.cn3rdPartyScripts[scriptUrl].loading) {
      window.cn3rdPartyScripts[scriptUrl].onLoaded.push(res)
      window.cn3rdPartyScripts[scriptUrl].onFailed.push(rej)
      return
    }

    // load the script
    window.cn3rdPartyScripts[scriptUrl].loading = true
    const script = document.createElement('script')
    script.async = true
    script.defer = true
    script.src = scriptUrl
    if (extraAttributes) {
      for (const key of Object.keys(extraAttributes)) {
        script.setAttribute(key, extraAttributes[key])
      }
    }
    script.addEventListener('load', () => {
      window.cn3rdPartyScripts[scriptUrl].loading = false
      window.cn3rdPartyScripts[scriptUrl].loaded = true
      for (const cb of window.cn3rdPartyScripts[scriptUrl].onLoaded) cb()
    })
    script.addEventListener('error', e => {
      const errorMsg = `Failed to load ${scriptFriendlyName}. You may want to reload the page to retry.`
      if (e.message == null) e.message = errorMsg
      if (e.reason == null) e.reason = errorMsg
      e.toString = () => errorMsg
      window.cn3rdPartyScripts[scriptUrl].loading = false
      window.cn3rdPartyScripts[scriptUrl].failed = true
      window.cn3rdPartyScripts[scriptUrl].error = e
      toaster.toast({ message: errorMsg, type: 'danger', icon: 'check' })
      for (const onFailedCallback of window.cn3rdPartyScripts[scriptUrl].onFailed) {
        onFailedCallback({ ...e, toString: () => errorMsg })
      }
    })
    document.head.append(script)
  })
}

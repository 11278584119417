import loadExternalScript from './load-external-script.js'

export function loadMiniProfiler() {
  loadExternalScript('/profiler/includes.min.js?v=4.2.22+4563a9e1ab', 'mini-profiler', {
    id: 'mini-profiler',
    'data-version': '4.2.22+4563a9e1ab',
    'data-path': '/profiler/',
    'data-current-id': '110712d9-3f90-457e-9229-3df1a2a17213',
    'data-ids': 'f8560245-4443-457e-a546-9a1478dbeefe,110712d9-3f90-457e-9229-3df1a2a17213',
    'data-position': 'Right',
    'data-scheme': 'Light',
    'data-authorized': 'true',
    'data-controls': 'true',
    'data-start-hidden': 'true',
    'data-max-traces': '15',
    'data-toggle-shortcut': 'Alt+P',
    'data-trivial-milliseconds': '2.0',
    'data-ignored-duplicate-execute-types': 'Open,OpenAsync,Close,CloseAsync',
  })
}

<Grid let:row rows={guests} class="middle flex-wrap" name="capacity-guests-detail-grid" rowKeySelector={cg => cg.guestOrgId} {rowClasses}>
  <GridCol {row} header="School">
    <CapacityGuestProfilePictureAndName capacityGuest={row} />
  </GridCol>
  <GridCol {row} header="Agreement">
    {#if !row.agreement}
      <NoAgreementSpecified />
    {:else}
      {#each [row.agreement] as a}
        <AgreementProfilePicturesAndName
          noTooltip
          name={a.name}
          orgName={a.orgName}
          orgRelativeName={a.orgRelativeName}
          orgProfilePicture={a.orgProfilePicture}
          teamName={a.teamName}
          teamOrgId={a.teamOrgId}
          schoolName={a.schoolName}
          schoolRelativeName={a.schoolRelativeName}
          schoolProfilePicture={a.schoolProfilePicture}
          schoolTeamName={a.schoolTeamName}
          schoolTeamOrgId={a.schoolTeamOrgId}
        />
      {/each}
    {/if}
  </GridCol>
  <GridCol {row} header="Rotation request restriction dates" thClass="nowrap" class="shrink">
    <StartAndEndDate startDate={row.schedulingStartDate} endDate={row.schedulingEndDate} />
  </GridCol>
  <GridCol {row} header="Request coordinator approval?" thClass="nowrap" class="shrink">
    {row.autoAcceptMatches ? 'Yes' : 'No'}
  </GridCol>
  <GridCol {row} header="Release unused groups" thClass="nowrap" class="shrink">
    <AutoReleaseUnscheduledMatchesWhenLabel concise value={row.autoReleaseUnscheduledMatchesWhen} />
  </GridCol>
  <GridCol {row} header="Guaranteed rotations" thClass="nowrap" class="shrink text-right">
    {readableNumber(row.guaranteedMatchCountGranted) ?? ''}
  </GridCol>
  <GridCol {row} header="Max rotation requests" thClass="nowrap" class="shrink text-right">
    <Unlimited size="lg" value={row.maxMatches} readable={true} />
  </GridCol>
  <GridCol {row} header="Max students" thClass="nowrap" class="shrink text-right">
    <Unlimited size="lg" value={row.maxStudents} readable={true} />
  </GridCol>
  <GridCol {row} header="Rotations" class="shrink">
    <div class="flex-column">
      <em>
        {#if row.approvedMatches}
          {pluralCount('approved rotation', row.approvedMatches)}
        {/if}
      </em>
      <em>
        {#if row.pendingMatches}
          {readableNumber('pending rotation', row.pendingMatches)}
        {/if}
      </em>
    </div>
  </GridCol>
</Grid>

<script>
  import { readableNumber, pluralCount } from 'services/string-utils'
  import Grid from 'components/Grid.svelte'
  import GridCol from 'components/GridCol.svelte'
  import NoAgreementSpecified from 'components/NoAgreementSpecified.svelte'
  import CapacityGuestProfilePictureAndName from './CapacityGuestProfilePictureAndName.svelte'
  import AgreementProfilePicturesAndName from 'components/AgreementProfilePicturesAndName.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import Unlimited from 'components/Unlimited.svelte'
  import AutoReleaseUnscheduledMatchesWhenLabel from './AutoReleaseUnscheduledMatchesWhenLabel.svelte'

  export let guests

  $: rowClasses = Object.fromEntries(guests.map(g => [g.guestOrgId, `card-left-thick-${g.color}`]))
</script>

<div
  class="inline-flex-row flex-align-center g05 date-box{className ? ` ${className}` : ''}"
  class:btn-pulsate-info={dateException.pulsate}
  use:tip={tooltip}
>
  {#if dateException.isNew}
    <span class="green-dot color-text-light-green" use:tip={'New, will be added'} />
  {/if}
  <Icon name="calendar" class="color-text-dark-green" lg={iconLg} />
  <span data-test="start-date">{dateService.datestamp(dateException.startDate || dateException.endDate)}</span>
  {#if isDateRange}
    <span>–</span>
    <Icon name="calendar" class="color-text-light-red" lg={iconLg} />
    <span data-test="end-date">{dateService.datestamp(dateException.endDate)}</span>
  {/if}
  {#if clearable}
    <Btn
      clearBtnStyling
      on:click={() => onRemove(dateException.startDate, dateException.endDate)}
      title="Remove"
      icon="close"
      class="py05 px05 text-gray"
    />
  {/if}
</div>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import dateService from 'services/date-service.js'
  import Icon from 'components/Icon.svelte'
  import validator from 'services/validator.js'
  import tip from 'decorators/tip.js'

  export let dateException
  export let tooltip = null
  let className = 'inline-flex-row flex-align-center g05 date-box'
  export { className as class }
  export let iconLg = false
  export let clearable = false
  export let onRemove = _.noop

  $: isDateRange =
    dateException.startDate &&
    dateException.endDate &&
    dateException.startDate != dateException.endDate &&
    validator.dateAfter(dateException.endDate, dateException.startDate)

  $: if (dateException.pulsate) {
    setTimeout(() => (dateException.pulsate = false), 1500)
  }
</script>

<style lang="scss">
  @import '../../css/helpers';
  .date-box {
    border: 1px solid #bbb;
    padding-left: 10px;
    border-radius: 4px;
    background-color: #eeeeee;
  }
  .green-dot {
    height: 5px;
    width: 5px;
    background-color: $lightgreen;
    border-radius: 50%;
    display: inline-block;
  }
</style>

import { shiftOccursOnDate } from 'services/calendar-service.js'
import { MatchRole, MatchStatus, PersonaType } from 'config/enums.js'
import { percent } from 'services/math-service.js'
import personaService from 'services/persona-service.js'
import personaStore from 'stores/persona.js'
import personaFilters from 'stores/persona-filters.js'

let $persona
personaStore.subscribe(p => ($persona = p))

let $personaFilters
personaFilters.subscribe(filters => ($personaFilters = filters))

export const implicitMsg = (match, matchPerStudent) => {
  const studentCount = match.matchUsers.filter(u => u.matchRole === MatchRole.Student).length
  const thisOrTheseRotation = matchPerStudent && studentCount > 1 ? 'these rotations' : 'this rotation'
  const forAllStudents =
    !(matchPerStudent && !match.capacity.allowGroups) || studentCount === 1
      ? ''
      : studentCount === 2
        ? ' for both students'
        : ` for all ${studentCount} students`
  // TODO(mvp-nursing-josh): Update verbiage. Shifts usage is calculated in CapacityCalendarReport.ComputeShiftUsageFromMatchDays
  return `Because no days are explicitly configured, ${thisOrTheseRotation} will use a slot${forAllStudents} in all availability windows between these dates.`
}

/*
  TODO: unit test common scenarios
  - no match days
      - capacity.eachStudentFillsASlot = false: should increment 1 slot from each day between start/end
      - capacity.eachStudentFillsASlot = true: should increment math.max([student count], 1) from each day between start/end
  - match days
      - capacity.eachStudentFillsASlot = false: should increment 1 slot from each match day
      - capacity.eachStudentFillsASlot = true: should increment math.max([student count], 1) from each match day
  - when capacity has no shifts on certain days, no slot should be taken

  TODO: handle and test edge-case: days without students should take a slot
*/
// no-mutation to filledCapacity
// some of this is duplicated logic that happens server-side in CapacityCalendarReport.cs. if it changes, be sure to change it there too
export function addMatchToCapacityUsage(match, matchDays, filledCapacity, matchPerStudent) {
  if (match == null || filledCapacity == null) return null
  const filledCapacityCopy = _.cloneDeep(filledCapacity)
  const students = match.matchUsers.filter(u => u.matchRole === MatchRole.Student)
  const studentCount = students.length
  const matchStudents = new Set(students.map(s => s.userId))
  const shiftsSeenForDay = {}
  const matchDaysComputed = matchDays?.length > 0 ? matchDays : getImplicitMatchDays(match)
  for (const d of matchDaysComputed) {
    if (filledCapacityCopy[d.date] == null || filledCapacityCopy[d.date][d.shiftId] == null) continue

    let incrementCount = 0
    if (match.capacity.allowGroups || !matchPerStudent || studentCount === 0) {
      // if not filling for each student or if there aren't any students, regardless of what type of user it is, use one slot per match day
      const alreadyCounted = shiftsSeenForDay[d.date] && shiftsSeenForDay[d.date][d.shiftId]
      incrementCount = alreadyCounted ? 0 : 1
    } else {
      // if for all match users, consume 1 slot for each student on the match
      // if day is for a student, consume 1 slot
      incrementCount = d.userId == null ? studentCount : matchStudents.has(d.userId) ? 1 : 0
    }

    if (incrementCount > 0) {
      const shiftBucket = filledCapacityCopy[d.date][d.shiftId]
      if (match.status === MatchStatus.Waitlisted) shiftBucket.waitlisted += incrementCount
      else if (match.status >= MatchStatus.Onboarding && match.status < MatchStatus.Closed) shiftBucket.filled += incrementCount
      else if (match.status <= MatchStatus.PendingClinicalSite && match.status > MatchStatus.Unsubmitted) shiftBucket.pending += incrementCount
    }

    // track what shifts for this day we've started counting
    if (shiftsSeenForDay[d.date] == null) shiftsSeenForDay[d.date] = {}
    shiftsSeenForDay[d.date][d.shiftId] = true
  }

  // re-compute buckets, now that this match is added in (we manage this match client-side so we can update UI slot-usage without going to the server)
  for (const date in filledCapacityCopy) {
    for (const shiftId in filledCapacityCopy[date]) {
      filledCapacityCopy[date][shiftId] = setComputed(filledCapacityCopy[date][shiftId], filledCapacityCopy[date][shiftId].infinite)
    }
  }

  return filledCapacityCopy
}

export function setComputed(bucket, infinite) {
  const shiftMatches = computeMatches(bucket, infinite)
  const overfilled = computeOverfill(bucket, shiftMatches)
  const overfilledMaybe = computeOverfillMaybe(bucket, shiftMatches, overfilled)
  const calculatedTotal = bucket.filled + bucket.pending + bucket.waitlisted + overfilled + overfilledMaybe
  const relativeTotal = calculatedTotal > shiftMatches ? calculatedTotal : shiftMatches
  const computed = {
    ...bucket,
    relativeTotal,
    overfilled,
    overfilledMaybe,
    overfilledPercent: relativeTotal ? percent(overfilled, relativeTotal) : 0,
    overfilledMaybePercent: relativeTotal ? percent(overfilledMaybe, relativeTotal) : 0,
    filledPercent: relativeTotal ? percent(bucket.filled, relativeTotal) : 0,
    pendingPercent: relativeTotal ? percent(bucket.pending, relativeTotal) : 0,
    waitlistedPercent: relativeTotal ? percent(bucket.waitlisted, relativeTotal) : 0,
  }
  return computed
}

export function getImplicitMatchDays(match) {
  if (match == null || match.startDate == null || match.endDate == null) return []
  const matchDays = []
  const end = dayjs(match.endDate)
  let date = dayjs(match.startDate)
  while (date.isSameOrBefore(end)) {
    const availableShifts = match.shifts.filter(s => shiftOccursOnDate(match.capacity, s, date))
    for (const shift of availableShifts) {
      matchDays.push({
        date: date.format('M/D/YYYY'),
        shiftId: shift.shiftId,
        userId: null,
      })
    }
    date = date.add(1, 'day')
  }
  return matchDays
}

export function getTotalScheduledHoursByUserId(matchDays, shifts, matchUsers) {
  const matchSchedulableUserIds = Object.keys(getSchedulableUsers(matchUsers))
  const shiftsById = {}
  for (const shift of shifts) {
    shiftsById[shift.shiftId] = shift
  }
  const totalScheduledHoursByUserId = {}
  for (const matchDay of matchDays) {
    const shift = shiftsById[matchDay.shiftId]
    if (shift == null) continue
    const userId = matchDay.userId
    if (userId == null) {
      // if no userId, add to all users
      for (const uid of matchSchedulableUserIds) {
        totalScheduledHoursByUserId[uid] ??= 0
        totalScheduledHoursByUserId[uid] += shift.duration
      }
    } else {
      // if userId, add to that user
      totalScheduledHoursByUserId[userId] ??= 0
      totalScheduledHoursByUserId[userId] += shift.duration
    }
  }
  return totalScheduledHoursByUserId
}

function computeMatches(bucket, infinite) {
  return infinite ? bucket.filled + bucket.pending + bucket.waitlisted : bucket.maxMatchCountPerDay
}

function computeOverfill(bucket, shiftMatches) {
  return Math.max(bucket.filled - shiftMatches, 0)
}

function computeOverfillMaybe(bucket, shiftMatches, overfilled) {
  return Math.max(bucket.filled + bucket.pending + bucket.waitlisted - shiftMatches - overfilled, 0)
}

export function toggleShift(matchDays, selectedDays, shift, usersById, deselect) {
  return deselect ? deselectShift(matchDays, selectedDays, shift) : selectShift(matchDays, selectedDays, shift, usersById)
}

function deselectShift(matchDays, selectedDays, shift) {
  return matchDays.filter(d => !selectedDays.includes(d.date) || d.shiftId !== shift.shiftId)
}

function selectShift(matchDays, selectedDays, shift, usersById) {
  for (const date of selectedDays) {
    for (const userId of Object.keys(usersById)) {
      const matchDayExists = matchDays.some(md => md.date === date && md.shiftId === shift.shiftId && md.userId === userId)
      if (!matchDayExists)
        matchDays.push({
          date,
          shiftId: shift.shiftId,
          userId,
        })
    }
  }
  return matchDays
}

export function togglePerson(matchDays, selectedDays, shift, person, deselect) {
  return deselect ? deselectPerson(matchDays, selectedDays, shift, person) : selectPerson(matchDays, selectedDays, shift, person)
}

function deselectPerson(matchDays, selectedDays, shift, person) {
  for (const date of selectedDays) {
    matchDays = matchDays.filter(d => !(d.shiftId === shift.shiftId && d.date === date && d.userId === person.userId))
  }
  return matchDays
}

function selectPerson(matchDays, selectedDays, shift, person) {
  for (const date of selectedDays) {
    matchDays.push({
      date,
      shiftId: shift.shiftId,
      userId: person.userId,
    })
  }
  return matchDays
}

export function updateMatchDayConfigs(matchId, matchDayConfigs, selectedDays, shift, matchDayConfig) {
  for (const date of selectedDays) {
    const existing = matchDayConfigs.find(mdc => mdc.date === date && mdc.shiftId === shift.shiftId)
    if (existing) {
      existing.startTime = matchDayConfig?.startTime ?? existing.startTime
      existing.endTime = matchDayConfig?.endTime ?? existing.endTime
      existing.placeholderStudentCount = matchDayConfig?.placeholderStudentCount ?? existing.placeholderStudentCount
    } else {
      matchDayConfigs.push({
        matchId,
        date,
        shiftId: shift.shiftId,
        startTime: matchDayConfig?.startTime,
        endTime: matchDayConfig?.endTime,
        placeholderStudentCount: matchDayConfig?.placeholderStudentCount,
      })
    }
  }
  return matchDayConfigs.filter(mdc => mdc.placeholderStudentCount > 0 || mdc.startTime || mdc.endTime)
}

const selectFormatted = d => d.format('M/D/YYYY')
// if this changes, probably change MatchRepository.Conflicting.
export function getConflictedDates(m, m2) {
  const mStart = dayjs(m.startDate)
  const mEnd = dayjs(m.endDate)
  const m2Start = dayjs(m2.startDate)
  const m2End = dayjs(m2.endDate)
  const mMatchDays = (m.matchDaysFormatted ?? m.matchDays)?.map(md => dayjs(md.date ?? md))
  const m2MatchDays = (m2.matchDaysFormatted ?? m.matchDays)?.map(md => dayjs(md.date ?? md))

  // same match
  if (m.matchId == m2.matchId) return []
  // different schedulable user
  if (m.userId != null && m2.userId != null && m.userId != m2.userId) return []
  // both no match days
  if (!mMatchDays?.length && !m2MatchDays?.length) {
    // if start end date chunks cross, then there's a conflict
    const hasConflicts = mStart.isSameOrBefore(m2End) && mEnd.isSameOrAfter(m2Start)
    if (!hasConflicts) return []
    const startOfOverlap = dayjs.max(mStart, m2Start)
    const endOfOverlap = dayjs.min(mEnd, m2End)
    return [{ from: startOfOverlap, to: endOfOverlap }]
  }
  // both have explicit match days
  if (mMatchDays?.length && m2MatchDays?.length) {
    // if any of the match days overlap, then there's a conflict
    return mMatchDays.filter(d => m2MatchDays.some(d2 => d2.isSame(d))).map(selectFormatted)
  }
  // one has explicit match days, the other doesn't
  // if the one with explicit match days has any match days that overlap with the start/end date chunks, then there's a conflict
  return mMatchDays?.length
    ? mMatchDays.filter(d => m2Start.isSameOrBefore(d) && m2End.isSameOrAfter(d)).map(selectFormatted)
    : m2MatchDays.filter(d => mStart.isSameOrBefore(d) && mEnd.isSameOrAfter(d)).map(selectFormatted)
}

export function buildShiftDayLookup(capacity, match, matchDays, shiftId = null) {
  const startDate = match.startDate
  const endDate = match.endDate
  const shifts = shiftId ? match.shifts.filter(s => s.shiftId === shiftId) : match.shifts
  const lookup = {}
  if (startDate == null || endDate == null) return lookup
  let current = dayjs(startDate)
  const end = dayjs(endDate)
  while (current.isSameOrBefore(end)) {
    const formatted = current.format('M/D/YYYY')
    lookup[formatted] = {}
    let shiftCount = 0
    let shiftCountAvailable = 0
    for (const shift of shifts) {
      const selected = matchDays.some(md => md.date === formatted && md.shiftId === shift.shiftId)
      const available = shiftOccursOnDate(capacity, shift, current)
      lookup[formatted][shift.shiftId] = { selected, available }
      if (available || selected) shiftCount++
      if (available) shiftCountAvailable++
    }
    lookup[formatted].shiftCount = shiftCount
    lookup[formatted].shiftCountAvailable = shiftCountAvailable
    current = current.add(1, 'day')
  }
  return lookup
}

export function buildMatchDayLookup(matchDays) {
  const grouped = _.groupBy(matchDays, 'date')
  for (const date in grouped) grouped[date] = _.groupBy(grouped[date], 'shiftId')
  return grouped
}

export function getSchedulableUsers(matchUsers) {
  return _.groupBy(
    matchUsers.filter(mu => mu.matchRole !== MatchRole.SchoolCoordinator && mu.matchRole !== MatchRole.ClinicCoordinator),
    'userId'
  )
}

export function simpleFilterOption(optionValue) {
  return { optionLabel: optionValue, optionValue }
}

export function getFilteredStatusCounts(capacity, guestOrgId, fromStatus, toStatus = fromStatus) {
  return capacity?.statusCounts?.find(s => s.guestOrgId === guestOrgId)?.byStatus.filter(s => s.status >= fromStatus && s.status <= toStatus) || 0
}

export function canConfirmAndReleaseCapacityGuestMatches(capacity, capacityGuest, checkConfirmedDate = true) {
  return (
    $persona.personaType === PersonaType.SchoolStaff &&
    capacity?.maxMatches &&
    capacityGuest &&
    capacityGuest.matchCount < capacityGuest.guaranteedMatchCountRemaining &&
    (checkConfirmedDate ? !capacityGuest.desiredMatchCountConfirmedDateTime : true) &&
    personaService.isOrgSameOrDescendant($personaFilters.orgId, capacityGuest.guestOrgId)
  )
}

export function getGuestShifts(shifts, capacityGuest) {
  if (!shifts?.length) return []
  if (!capacityGuest) return shifts.filter(s => !s.guests?.length)
  return shifts.filter(s => {
    if (!s.guests.length) return true
    return s.guests.some(g => g.guestOrgId === capacityGuest.guestOrgId || (capacityGuest.guestOrgId == null && g.guestOrgId == null))
  })
}

function isCapacityGuestMatchCapacityFull(capacityGuest) {
  return capacityGuest.maxMatches > 0 && capacityGuest.matchCount >= capacityGuest.maxMatches
}

function isEntireCapacityMatchCapacityFull(capacity) {
  return capacity.maxMatches && capacity.guests[0].totalCapacityMatchCount >= capacity.maxMatches
}

function isCapacityGuestStudentCapacityFull(capacityGuest) {
  return capacityGuest.studentCountRemaining === 0
}

export function isMatchCapacityFull(capacity, capacityGuest = null) {
  // From a school perspective, the capacity is full if their org's rotation count for specific statuses meets/exceeds their
  // capacity guest's max rotations or if the students across those rotations meets/exceeds their capacity guest's max students.
  // From a health system perspective, the capacity is full if every school (capacity guest) would also be seeing that it's full.
  if (!capacity?.guests?.length) return false
  const isCapacityFull = isEntireCapacityMatchCapacityFull(capacity)
  if (isCapacityFull) return true
  if (capacityGuest) return isCapacityGuestMatchCapacityFull(capacityGuest)
  return capacity.guests.every(isCapacityGuestMatchCapacityFull)
}

export function isStudentCapacityFull(capacity, capacityGuest = null) {
  if (capacityGuest) return isCapacityGuestStudentCapacityFull(capacityGuest)
  if (!capacity?.guests?.length) return false
  return capacity.guests.every(isCapacityGuestStudentCapacityFull)
}

export function isAnyMatchAvailableInPool(capacity) {
  if (!capacity?.guests?.length) return false
  return capacity.maxMatches == null || capacity.guests[0].poolMatchCountRemaining > 0
}

export function getScheduleStudentsBtnProps(
  capacity,
  capacityGuest,
  { defaultClass = 'btn btn-primary btn-low-contrast-white', tipOptions = {} } = {}
) {
  const shouldNotScheduleStudents = getReasonWhyShouldNotScheduleStudents(capacity, capacityGuest)
  const options = { placement: 'left', maxWidth: 320, ...tipOptions }
  return shouldNotScheduleStudents.reason
    ? $persona.personaType === PersonaType.SchoolStaff || shouldNotScheduleStudents.disabled
      ? {
          class: `${defaultClass} flex-row flex-align-center g05`,
          disabled: true,
          disabledTitle: { content: `You cannot schedule students because ${shouldNotScheduleStudents.reason}.`, options },
        }
      : {
          class: 'btn btn-outline-warning flex-row flex-align-center g05',
          title: { content: `You may not want to schedule students because ${shouldNotScheduleStudents.reason}.`, options },
        }
    : {
        class: `${defaultClass} flex-row flex-align-center g05`,
      }
}

function getReasonWhyShouldNotScheduleStudents(capacity, capacityGuest = null) {
  const isMatchFull = isMatchCapacityFull(capacity, capacityGuest)
  const isStudentFull = isStudentCapacityFull(capacity, capacityGuest)
  const isHealth = $persona.personaType === PersonaType.ProviderStaff
  if (isHealth) {
    const reason = getOrgMatchPreferenceReasonShouldNotScheduleStudents(capacity, capacityGuest)
    if (reason) return { disabled: true, reason }
  }

  // TODO: Consider including counts / overages in the message.
  const reason = isMatchFull
    ? isStudentFull
      ? `all allocated rotations and students have been used`
      : `all allocated rotations have been used`
    : isStudentFull
      ? `all allocated students have been used`
      : null
  return {
    disabled: false,
    reason,
  }
}

function getOrgMatchPreferenceReasonShouldNotScheduleStudents(capacity, capacityGuest) {
  if (capacityGuest)
    return capacityGuest.isAllowedBySchool ? (capacityGuest.isAllowedByHealthSystem ? null : 'this school is blocked') : 'this school is unavailable'

  let anyUnavailableGuest = false
  let anyBlockedGuest = false
  for (const guest of capacity.guests) {
    if (!guest.isAllowedBySchool) {
      anyUnavailableGuest = true
      continue
    }
    if (!guest.isAllowedByHealth) anyBlockedGuest = true
  }
  return anyUnavailableGuest
    ? anyBlockedGuest
      ? 'all schools are blocked or unavailable'
      : 'all schools are unavailable'
    : anyBlockedGuest
      ? 'all schools are blocked'
      : null
}

<script>
  import { getLocation } from 'stores/req.js'
  import initial from 'stores/initial.js'
  import intercom from 'services/intercom-proxy.js'
  import modelState from 'stores/model-state.js'
  import pendo from 'services/pendo-proxy.js'

  const location = getLocation()
  // we pass in $location, so the reactive statement fires the handler...
  $: if ($initial.loaded) onRouteChange($location)

  function onRouteChange() {
    if ($initial.isSuccess) {
      // clear modelState between pages. But don't clear it if the initial request failed--it will set modelState and we don't want to clear it immediately here...
      $modelState = null
    }

    // update intercom
    intercom.update()

    // update pendo
    pendo.update()

    /*
      todo: consider storing in-flight XHRs that can be canceled upon route change in a store.
            Iterate them, calling abort. And update server to make more use of
            HttpContext.RequestAborted cancellation token to cancel endpoint operations accordingly
            Note, chrome sends abort signals upon closing tab or doing a full nav away from a page
    */
  }
</script>

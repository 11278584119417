<div class="locations-grid flex-align-center g05{className ? ` ${className}` : ''}">
  {#if _show.owner || (_show.locations && ownerOrgIsLocation)}
    <div>
      {#if ownerOrgIsLocation && _show.locations}
        <Icon name="map-marker" />
      {/if}
    </div>
    <div>
      {#if filtersComponent}
        <QuickFilterTag
          icon="hospital"
          class="color-text-orange"
          value={capacity.orgName}
          type="Organization"
          href="/orgs/{capacity.orgId}/{capacity.orgRelativePath}"
          {filtersComponent}
          filterType={FilterType.HostOrgs}
          configKey="orgIds"
          configValue={capacity.orgId}
          simulationFilterOption={{
            orgId: capacity.orgId,
            name: capacity.orgName,
            orgRelativeName: capacity.orgRelativeName,
            profilePicture: capacity.orgProfilePicture,
          }}
          dataTest="org-quick-filter-tag-{capacity.orgName}"
        >
          <OrgProfilePictureAndName
            {size}
            {useOverflowEllipsis}
            name={capacity.orgName}
            profilePicture={capacity.orgProfilePicture}
            formattedAddress={_show.addresses ? capacity.orgAddress?.formattedAddress : null}
          />
        </QuickFilterTag>
      {:else}
        <OrgProfilePictureAndName
          {size}
          {useOverflowEllipsis}
          name={capacity.orgName}
          profilePicture={capacity.orgProfilePicture}
          formattedAddress={_show.addresses ? capacity.orgAddress?.formattedAddress : null}
        />
      {/if}
    </div>
  {/if}

  {#if _show.team && capacity.teamOrgId}
    <div />
    <div>
      {#if filtersComponent}
        <QuickFilterTag
          icon="users"
          class="color-text-orange"
          value={capacity.teamName}
          tooltipFilterByPrefix={capacity.teamId ? null : 'Filter by'}
          type={capacity.teamId ? 'Team' : 'All teams'}
          href={capacity.teamId ? `/settings/teams/${capacity.teamRelativePath}` : null}
          {filtersComponent}
          filterType={FilterType.Teams}
          configKey="teamIds"
          configValue={capacity.teamId ?? null}
          simulationFilterOption={{
            teamId: capacity.teamId ?? null,
            name: capacity.teamName,
            orgId: capacity.teamOrgId ?? null,
          }}
          dataTest="team-quick-filter-tag-{capacity.teamName}"
        >
          <TeamProfilePictureAndName small orgId={capacity.teamOrgId} name={capacity.teamName} />
        </QuickFilterTag>
      {:else}
        <TeamProfilePictureAndName small orgId={capacity.teamOrgId} name={capacity.teamName} />
      {/if}
    </div>
  {/if}

  {#if _show.locations && locations.length}
    <hr class="grid-hr my05" />
    {#each locations as location}
      <div class="flex-row flex-align-center"><Icon name="map-marker" /></div>
      <div>
        {#if filtersComponent}
          <QuickFilterTag
            icon="hospital"
            class="color-text-orange"
            value={location.name}
            type="Opportunity location"
            href="/orgs/{location.orgId}/{location.relativePath}"
            {filtersComponent}
            filterType={FilterType.CapacityLocations}
            configKey="locationIds"
            configValue={location.orgId}
            simulationFilterOption={location}
            dataTest="location-quick-filter-tag-{location.name}"
          >
            <OrgProfilePictureAndName {size} name={location.name} formattedAddress={_show.addresses ? location.address?.formattedAddress : null} />
          </QuickFilterTag>
        {:else}
          <OrgProfilePictureAndName
            {size}
            {useOverflowEllipsis}
            name={location.name}
            profilePicture={location.profilePicture}
            formattedAddress={_show.addresses ? location.address?.formattedAddress : null}
          />
        {/if}
      </div>
    {/each}
  {/if}
</div>

<script>
  import { FilterType } from 'config/enums.js'
  import { pluralCount } from 'services/string-utils.js'
  import Icon from 'components/Icon.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import QuickFilterTag from 'components/QuickFilterTag.svelte'
  import TeamProfilePictureAndName from 'components/TeamProfilePictureAndName.svelte'

  export let capacity
  export let filtersComponent = null
  export let size = 'small'
  let className = 'mr2'
  export { className as class }
  export let show = null
  export let useOverflowEllipsis = false
  export let title = null

  $: ownerOrgIsLocation = !capacity.locations.length || capacity.locations.find(org => org.orgId === capacity.orgId)
  $: locations = capacity.locations.filter(org => org.orgId !== capacity.orgId)
  $: _show = show ?? { owner: true, team: true, locations: true, addresses: false }
  $: locationCount = locations.length + (ownerOrgIsLocation ? 1 : 0)
  $: title = _show.owner
    ? _show.locations
      ? `Owner & ${pluralCount('location', locationCount, 'omitNumber')}`
      : 'Owner'
    : _show.team && capacity.teamOrgId
      ? show.locations
        ? `Team & ${pluralCount('location', locationCount, 'omitNumber')}`
        : 'Team'
      : show.locations
        ? pluralCount('Location', locationCount, 'omitNumber')
        : null
</script>

<style>
  .locations-grid {
    display: grid;
    grid-template-columns: auto 1fr;
  }
  .grid-hr {
    grid-column: 1 / span 2;
    height: 1px;
  }
</style>

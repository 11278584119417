<Btn on:click={exportExcel} class="btn{className ? ` ${className}` : ''}" {title} {disabled} {dataTest} loading={isStartingExport} icon="download">
  {#if isStartingExport}
    Starting {download ? 'download' : 'export'}…
  {:else}
    {download ? 'Download' : 'Export'}
  {/if}
</Btn>

<script>
  import Btn from './bootstrap/Btn.svelte'

  let className = 'btn-default'
  export let exportFunc
  export { className as class }
  export let title = 'Download Excel spreadsheet'
  export let disabled = false
  export let dataTest = null
  export let download = false

  let isStartingExport = false

  async function exportExcel() {
    isStartingExport = true
    try {
      await exportFunc()
      // websockets should start flowing before this request completes to add to the exportJobs store, so no need to use the exportJobId that gets returned.
    } finally {
      isStartingExport = false
    }
  }
</script>

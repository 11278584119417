{#if $user.personas.length > 1 || ($persona?.personaType != null && $persona.personaType !== PersonaType.Student)}
  <div class="persona-dropdown" use:tip={{ content: $user.personas.length > 1 ? 'Switch persona' : $persona.label, options: { placement: 'right' } }}>
    {#if $user.personas.length > 1}
      <QuickDropdown bind:isOpen class="" btnClass="switch-persona-btn" labelClass="overflow-hidden" anyClickCloses>
        <span slot="label">
          <PersonaDropdownCurrentPersonaLabel />
        </span>

        {#if $user.personas.length}
          <div class="list-group">
            {#if $user.personas.length > 5}
              <Filter bind:text={personaSearch} class="m1" autofocus />
            {/if}
            <div class="scrollable-md">
              {#each filteredPersonas as p}
                <a
                  href={null}
                  class="list-group-item flex-row flex-align-center {p.slug === $persona.slug ? 'active' : ''}"
                  on:click={() => changePersona(p)}
                >
                  <ProfilePic src={p.image} name={p.label} class="mr1" />
                  {p.label}
                </a>
              {/each}
            </div>
          </div>
        {/if}
      </QuickDropdown>
    {:else if $user.personas.length}
      <a href="/">
        <PersonaDropdownCurrentPersonaLabel />
      </a>
    {/if}
  </div>
{/if}

<script>
  import { PersonaType } from 'config/enums.js'
  import Filter from 'components/Filter.svelte'
  import persona from 'stores/persona.js'
  import PersonaDropdownCurrentPersonaLabel from 'components/PersonaDropdown.CurrentPersonaLabel.svelte'
  import ProfilePic from './ProfilePic.svelte'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import tip from 'decorators/tip.js'
  import validator from 'services/validator.js'
  import user from 'stores/user.js'
  import personaService from 'services/persona-service'
  import unsavedForms from 'stores/unsaved-forms'
  import { navigate } from 'svelte-routing'

  let personaSearch = null
  let isOpen = false

  $: filteredPersonas = validator.empty(personaSearch)
    ? $user.personas
    : $user.personas.filter(p => p.label.toLowerCase().includes(personaSearch.toLowerCase()))
  $: userId = $user.userId
  // when you change impersonation user, clear the search, if any.
  // else you can get into a state where you're impersonating a user, and the search is still filtering the list of personas for the previous user _and_ the search box isn't being shown lol
  // alternatively, we could retain the search and show the filter box if it's not empty too.
  $: userId, clearPersonaSearch()

  function clearPersonaSearch() {
    personaSearch = null
  }

  function changePersona(persona) {
    unsavedForms.doActionSafe(() => {
      personaService.setPersona({
        slug: persona.slug,
        orgId: persona.orgId,
        teamId: persona.teamId,
      })
      navigate('/') // in case they're on a page that isn't supported by their newly-selected persona (e.g. on students page, and switch to a clinical side persona)
    })
  }
</script>

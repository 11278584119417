<RelatedEntityCount
  {count}
  {name}
  {unit}
  {iconFirst}
  {iconFirstLabel}
  {iconTitle}
  {icon}
  iconAutoColor
  {iconLg}
  {iconClass}
  {nullMeansInfinity}
  {symbol}
  class={className}
/>

<script>
  import RelatedEntityCount from 'components/RelatedEntityCount.svelte'

  export let icon = 'rotation'
  export let name = 'match'
  export let count = 0 // Can be anything countable; if an Array/Map/Set, just pass that instead of `.length`/`.size`
  export let unit = 'Rotation'
  export let iconClass = 'color-text-steel'
  export let iconFirst = false
  export let iconFirstLabel = ''
  export let iconTitle = null
  export let nullMeansInfinity
  export let iconLg = false
  export let symbol = 'times'
  let className = 'flex-row flex-align-center g05'
  export { className as class }
</script>

<div class="flex-row flex-align-center g05">
  {#if studentRecord.active}
    {#if studentRecord.deleted == null}
      <Icon name="check" class="text-success" />
      <span>Confirmed</span>
    {:else}
      <Icon name="delete" class="text-danger" />
      <span>Removed</span>
    {/if}
  {:else if studentRecord.deleted == null}
    <Icon name="comments-question" class="text-info" />
    <span>Pending/unconfirmed</span>
  {:else}
    <Icon name="thumbs-down" class="text-warning" />
    <span>Rejected</span>
  {/if}

  {#if studentRecord.deleted != null}
    <HelpBlock class="m0">
      <FromNow date={studentRecord.deleted} showTooltip />
    </HelpBlock>
  {/if}
</div>

<script>
  import FromNow from 'components/FromNow.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'

  export let studentRecord
</script>

<!--
  Any html/markdown that is controlled by end users should be heavily limited when displayed.
  Usages of SafeHtml should only ever contain basic html.
  Use svelte template instead whenever possible.
  If html/markdown is stored in db, it should _also_ be simple html for our usecases almost certainly (eula for example).
-->
{@html valueSafe}

<script>
  import config from 'config/safe-html.js'
  import validator from 'services/validator.js'

  export let value
  export let extraAllowedCssProperties = []

  const { allowedTags, allowedAttributes, allowedCssProperties, allowedSchemes } = config
  const allowedHrefStarts = ['/', '#', ...[...allowedSchemes].map(scheme => `${scheme}:`)]

  $: valueSafe = cleanHtml(value)

  function cleanHtml(html) {
    const tmp = document.createElement('DIV')
    tmp.innerHTML = html
    const allElements = tmp.querySelectorAll('*')
    let i = allElements.length
    while (i--) {
      const element = allElements[i]
      const tagName = element.tagName.toUpperCase()
      if (!allowedTags.has(tagName)) {
        log(`Html contains unallowed element ${tagName}.`)
        element.remove()
        continue
      }
      const attributes = element.attributes
      let j = attributes.length
      while (j--) {
        const attr = attributes[j]
        const attrName = attr.name
        if (!allowedAttributes.has(attrName)) {
          log(`Html contains unallowed attribute ${attrName}`)
          element.removeAttribute(attrName)
        } else if (attrName === 'href' && !allowedHrefStarts.some(s => attr.value.startsWith(s))) {
          log(`Html contains href attribute with unallowed value ${attr.value}`)
          element.removeAttribute(attrName)
        } else if (attrName === 'style') {
          setAllowedStylingOnly(element)
        }
      }
    }
    return tmp.innerHTML
  }

  function setAllowedStylingOnly(element) {
    // probably not a concern with modern browsers, but let's only allowlist some inline style properties still to be extra cautious.
    // info: https://stackoverflow.com/questions/15536499/is-a-xss-attack-possible-when-the-point-of-injection-is-the-value-of-the-style-a?noredirect=1&lq=1
    const safeStyles = {}
    for (const key in element.style) {
      if (Object.hasOwnProperty.call(element.style, key)) {
        const value = element.style[key]
        if (value === '') continue
        if (value === 'initial') continue
        if (validator.int(key)) continue // element.style has some weird numeric props...whatever
        if (!allowedCssProperties.has(key) && !extraAllowedCssProperties.includes(key)) {
          log(`Html contains style attribute with unallowed property ${key}:${value}`)
          continue
        }
        safeStyles[key] = value
      }
    }
    element.setAttribute('style', '')
    for (const key in safeStyles) element.style[key] = safeStyles[key]
  }

  function log(error) {
    // eslint-disable-next-line no-console
    console.error(`${error}. It will be stripped.`)
  }
</script>

<svelte:element
  this={href ? 'a' : 'div'}
  class="profile-pic-container{className ? ` ${className}` : ''}"
  class:xLarge
  class:large
  class:medium
  class:small
  class:xs
  class:xxs
  class:round-left-border={roundLeftBorder}
  class:input-sized={inputSized}
  class:pseudo-disabled={pseudoDisabled}
  {style}
  use:tip={titleTip || slottedTip}
  {href}
  data-test={dataTest}
  on:click={stopPropagationIfHref}
  on:keydown={stopPropagationIfHref}
>
  {#if isDefaultSrc}
    <div class="no-pic" data-test="no-pic" style={defaultSrcStyle}>
      {#if special}
        <Icon name="asterisk" class="text-primary" style="--path-0-color: initial" />
      {:else}
        {_initials}
      {/if}
    </div>
  {:else}
    <img {src} {alt} />
  {/if}
  <slot />
</svelte:element>

{#if hasTooltipSlot}
  <ConditionalTooltip bind:tooltipElem><slot name="tooltip" /></ConditionalTooltip>
{/if}

<script>
  import colorService from 'services/color-service.js'
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'
  import ConditionalTooltip from 'components/ConditionalTooltip.svelte'

  export let name = null
  export let src = null
  export let id = null
  export let href = null
  export let title = null
  export let dataTest = null
  export let initials = null
  export let xxs = false
  export let xs = false
  export let small = false
  export let medium = false
  export let large = false
  export let xLarge = false
  export let inputSized = false
  export let size = null
  export let roundLeftBorder = false
  export let special = false
  export let pseudoDisabled = false
  export { className as class }
  export let containerStyle = null
  export let slottedTipOptions = {}

  const hasTooltipSlot = !!$$props.$$slots?.tooltip
  let className = ''
  let style = ''
  let tooltipElem = null

  $: isDefaultSrc = src == null || src === ''
  $: _initials = isDefaultSrc && initials ? initials.toUpperCase() : !initials && name ? getInitials(name) : ''
  $: hue = isDefaultSrc && !special ? colorService.getHue(id || name) : null
  $: titleTip = title == null ? null : _.isString(title) ? { content: title, options: { interactive: true } } : title
  $: defaultSrcStyle = special ? 'background-color: #fff; border: 1px solid #bbb' : `background-color: hsl(${hue}, 70%, 45%)`
  $: alt = name ? `${name}'s profile picture` : ''
  $: size, containerStyle, createStyle()

  $: slottedTip = hasTooltipSlot
    ? {
        content: tooltipElem,
        options: {
          theme: 'light-gray-scrollable',
          interactive: true,
          maxWidth: 250,
          ...slottedTipOptions,
        },
      }
    : null

  function createStyle() {
    if (containerStyle) {
      style = containerStyle
    }
    if (size) {
      style += ` --size: ${size}`
    }
  }

  function getInitials(name) {
    if (special || name == null) return ''
    name = name
      .replaceAll(/\([^)]+\)/g, '') // e.g. Bill Smith (MD) --> Bill Smith
      .replaceAll(/, [.A-Za-z]+/g, '') // e.g. Bill Smith, MD --> Bill Smith
      .replaceAll(/[A-Za-z]{2}\. /g, '') // Dr. Bill Smith -> Bill Smith
      .replaceAll(/[^\d\sA-Za-z]/g, '') // get rid of non alphabet or numeric characters
      .trim()
    const parts = name.split(' ')
    if (parts.length === 0) return ''
    if (parts.length === 1) return parts[0].slice(0, 1).toUpperCase() // first char of name
    return `${parts[0][0].toUpperCase()}${parts.at(-1)[0].toUpperCase()}` // first char from first part, first char from last part
  }

  function stopPropagationIfHref(e) {
    if (href) e.stopPropagation()
  }
</script>

<style lang="scss">
  .profile-pic-container {
    text-decoration: none !important;
    --size: 50px;
    display: inline-block;
  }

  img,
  .no-pic {
    display: block;
    border-radius: 5px;
    width: var(--size);
    height: var(--size);
    line-height: var(--size);
    font-size: calc(var(--size) / 2);
  }

  .no-pic {
    font-weight: 500;
    background-color: #eee;
    color: #eee;
    text-align: center;
    user-select: none;
  }

  .xLarge {
    --size: 200px;
  }

  .large {
    --size: 92px;
  }

  .medium {
    --size: 40px;
  }

  .small {
    --size: 30px;
  }

  .xs {
    --size: 20px;
  }

  .xxs {
    --size: 16px;
  }

  .input-sized {
    --size: 39px;
  }

  .round-left-border > * {
    border-radius: 5px 0 0 5px;
  }
</style>

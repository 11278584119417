<Icon
  name="eye-limited"
  class="text-info"
  title={tooltipElem
    ? {
        content: tooltipElem,
        options: {
          interactive: true,
          placement: 'left',
          theme: 'light-gray-scrollable',
          setInstance,
          onHide: () => !visibleNestedTooltips.size,
        },
      }
    : null}
/>

<div class="hide">
  <div bind:this={tooltipElem} class="flex-column g05 text-left">
    {#if showRoles}
      <div class="strong">Visible to these roles</div>
      <div class="flex-column g05">
        {#each comment.visibleToMatchRoles as role}
          <CommentVisibilityRoleLabel label={role.label} users={role.users} tipOptions={{ onTrigger, onUntrigger }} />
        {/each}
      </div>
    {/if}

    {#if showUsers}
      <div class="strong" class:mt1={showRoles}>Visible to these users</div>
      <div class="flex-column g05">
        {#each comment.visibleToUsers as user}
          <UserProfilePicAndName {user} profilePicXs />
        {/each}
      </div>
    {/if}

    {#if !showRoles && !showUsers}
      <div class="strong">Visible only to you</div>
    {/if}

    <div class="flex-row flex-align-center g05 mt1">
      <Icon name="info" class="text-info" />
      <span class="small">Clinical admins can view all comments.</span>
    </div>
  </div>
</div>

<script>
  import CommentVisibilityRoleLabel from 'components/CommentVisibilityRoleLabel.svelte'
  import Icon from 'components/Icon.svelte'
  import UserProfilePicAndName from 'components/UserProfilePicAndName.svelte'

  export let comment

  const visibleNestedTooltips = new Set()
  let tippyInstance = null
  let tooltipElem = null

  $: showRoles = comment.matchRoles?.length > 0
  $: showUsers = comment.userIds?.length > 0

  // For some reason, Svelte really doesn't want this inlined as a lambda in the tip options above.
  function setInstance(instance) {
    tippyInstance = instance
  }

  function onTrigger(instance) {
    visibleNestedTooltips.add(instance)
  }

  function onUntrigger(instance, event) {
    visibleNestedTooltips.delete(instance)
    if (!visibleNestedTooltips.size) tippyInstance.hideWithInteractivity(event)
  }
</script>

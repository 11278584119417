{#if total}
  <div class="small em" data-test="pending-requests-count">
    {#if showPendingCount}
      <div>{pluralCount('pending request', pendingCount)}</div>
    {/if}
    {#if showApprovedCount}
      <div>{pluralCount('approved request', approvedCount)}</div>
    {/if}
    {#if showArchivedCount}
      <div>{pluralCount('archived/closed request', archivedClosedCount)}</div>
    {/if}
  </div>
{/if}

<script>
  import { pluralCount } from 'services/string-utils'
  import { MatchStatus } from 'config/enums'
  import { getFilteredStatusCounts } from 'services/capacity-usage.js'

  export let capacity
  export let guestOrgId
  export let showPendingCount = true
  export let showApprovedCount = true
  export let showArchivedCount = true

  $: pending = getFilteredStatusCounts(capacity, guestOrgId, MatchStatus.Unsubmitted, MatchStatus.Waitlisted)
  $: approved = getFilteredStatusCounts(capacity, guestOrgId, MatchStatus.Onboarding, MatchStatus.Completed)
  $: archivedClosed = getFilteredStatusCounts(capacity, guestOrgId, MatchStatus.Closed)

  $: pendingCount = _.sumBy(pending, sc => sc.count || 0)
  $: approvedCount = _.sumBy(approved, sc => sc.count || 0)
  $: archivedClosedCount = _.sumBy(archivedClosed, sc => sc.count || 0)

  $: total = pendingCount + approvedCount + archivedClosedCount
</script>

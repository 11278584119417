<a class="nav-toggle" on:click href={null}>
  <Icon name="menu" />
</a>

<script>
  import Icon from 'components/Icon.svelte'
</script>

<style lang="scss">
  @import '../../css/helpers';

  .nav-toggle {
    display: block;
    padding: 10px 0;
    font-size: 22px;
    color: #737373;
    transition: all 0.2s ease-in-out;
    text-align: center;
    // cursor: pointer;

    &:hover {
      color: $primary;
    }
  }
</style>

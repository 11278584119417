{#if diff.length}
  <pre class="monospace">{#each diff as d}<span class:added={d.added} class:removed={d.removed}>{d.value}</span>{/each}</pre>
{/if}

<script>
  import { diffJson } from 'diff'

  export let before
  export let after

  $: diff = before && after ? diffJson(before, after) : []
</script>

<style>
  .added {
    color: green;
  }
  .removed {
    color: red;
  }
</style>

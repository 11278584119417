<div class="details-container mt1">
  <div class="flex-column g1 details-left">
    {#if show.type}
      <TagCloud
        ctx={{
          orgTags: org.tags,
        }}
      />
    {/if}
    {#if show.description}
      <LongDesc class="m0" max={longDescMax} text={org.description} />
    {/if}
    {#if show.address && org.formattedAddress}
      <div>
        <Icon name="map-marker" class={orgIconColor} />
        <span>{org.formattedAddress}</span>
      </div>
    {/if}
    {#if show.phoneNumber && org.phoneNumber}
      <div>
        <Icon name="phone" class={orgIconColor} />
        <span>{org.phoneNumber}</span>
      </div>
    {/if}
    {#if show.email && org.emailPublic}
      <div>
        <Icon name="email" class={orgIconColor} />
        <span>{org.emailPublic}</span>
      </div>
    {/if}
  </div>
  <div class="flex-column g1 details-left">
    {#if show.studentCount}
      <StudentCount count={org.studentCount} />
    {/if}

    {#if show.agreementCount}
      <AgreementCount unit={'Active agreement'} count={org.agreementCount} />
    {/if}

    {#if show.activeCapacityCount}
      <ActiveCapacityCount count={org.activeCapacityCount} />
    {/if}
  </div>
</div>

<script>
  import { getOrgIconColor } from 'services/orgs-service.js'
  import AgreementCount from 'components/AgreementCount.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import TagCloud from 'components/TagCloud.svelte'
  import LongDesc from 'components/LongDesc.svelte'
  import Icon from 'components/Icon.svelte'
  import ActiveCapacityCount from 'components/ActiveCapacityCount.svelte'
  import StudentCount from 'components/StudentCount.svelte'

  export let org = null
  export let featureTypes = null

  const longDescMax = 60

  $: show = $showDropdowns.orgPicker
  $: orgIconColor = getOrgIconColor(featureTypes)
</script>

<style lang="scss">
  // TODO(phase2-nursing): Either move some fields over to the right section or blow these styles away (and classes used above).
  .details-container {
    display: grid;
    gap: 20px;
    // TODO(phase2-nursing): Make responsive
    grid-template-columns: auto 320px;
    grid-template-rows: auto;
    grid-template-areas: 'left right';
  }
</style>

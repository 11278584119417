{#if userDocument}
  <Modal
    on:close={onClose}
    style={isImage || !canManageDocuments ? 'display: flex' : 'display: block'}
    dialogStyle={isImage || !canManageDocuments ? 'width: auto' : null}
    xl={canManageDocuments && isPdf && !failedToLoad}
    dataTest="document-preview-modal"
  >
    <h3 slot="title" data-test="title">
      <div class="small">{title}</div>
      <FileName value={userDocument.nameOriginal} insideOf="modal-title" />
    </h3>

    {#if onlyYou}
      <!-- No reason to show the Who has access tab if you're the only user who can see this document. -->
      <UserDocumentAbstractPreviewModalGeneralTab
        {noMinimumAlphaWidthOrHeight}
        {userDocument}
        {userDocumentSharing}
        class={generalBodyClass}
        bind:failedToLoad
      >
        <slot name="body-top" slot="body-top" />
      </UserDocumentAbstractPreviewModalGeneralTab>
    {:else}
      <div class="m0 p0">
        <!--
          This is a bit of a hack, but we need to handle where using routing doesn't make any sense, like in the match modal where a lot of state would need to be captured
          such as the MatchID, StepID, UserID, a file that's potentially going to be uploaded, a UserDocumentID that already exists (when the filename would be the same), etc.
          and that would result in the  Back/Forward buttons acting funky unless all of that was handled. So, instead, we'll use the <Router> when we can, otherwise we'll just
          use the same content that the <Router> would have used.
        -->

        <Tabs bind:active={tabName}>
          <Tab href={baseHref ? `${baseHref}/general` : ''} name="general" icon="settings">General</Tab>
          <Tab href={baseHref ? `${baseHref}/access` : ''} name="access" icon="user">Who has access</Tab>
        </Tabs>

        {#if baseHref}
          <Router>
            <Route>
              <UserDocumentAbstractPreviewModalGeneralTab
                {noMinimumAlphaWidthOrHeight}
                {userDocument}
                {userDocumentSharing}
                class={generalBodyClass}
                bind:failedToLoad
              >
                <slot name="body-top" slot="body-top" />
              </UserDocumentAbstractPreviewModalGeneralTab>
            </Route>

            <Route path="access">
              <div class="modal-body">
                <UserDocumentAccessGrid {userDocument} {userDocumentSharing} />
              </div>
            </Route>
          </Router>
        {:else if tabName === 'general'}
          <UserDocumentAbstractPreviewModalGeneralTab
            {noMinimumAlphaWidthOrHeight}
            {userDocument}
            {userDocumentSharing}
            class={generalBodyClass}
            bind:failedToLoad
          >
            <slot name="body-top" slot="body-top" />
          </UserDocumentAbstractPreviewModalGeneralTab>
        {:else if tabName === 'access'}
          <div class="modal-body">
            <UserDocumentAccessGrid {userDocument} {userDocumentSharing} />
          </div>
        {/if}
      </div>
    {/if}

    {#if hasFooter}
      <div class="modal-footer flex-row flex-align-center g1">
        <slot name="footer" />
      </div>
    {/if}
  </Modal>
{/if}

<script>
  import { Route, Router } from 'svelte-routing'
  import { MatchStatus } from 'config/enums.js'
  import FileName from 'components/FileName.svelte'
  import Modal from 'components/Modal.svelte'
  import Tab from 'components/Tab.svelte'
  import Tabs from 'components/Tabs.svelte'
  import UserDocumentAccessGrid from 'components/UserDocumentAccessGrid.svelte'
  import UserDocumentAbstractPreviewModalGeneralTab from 'components/UserDocumentAbstractPreviewModal.GeneralTab.svelte'
  import user from 'stores/user.js'
  import { isImageFile, isPdfFile } from 'services/file-service.js'
  import { isDevEnvironment } from 'services/environment'

  export let baseHref = null
  export let title = 'Document'
  export let userDocument
  export let userDocumentSharing
  export let onClose
  export let generalBodyClass = null
  export let noMinimumAlphaWidthOrHeight = false // only dbo.UserDocument has these currently. we should add them to the other AbstractFile implementations (stepsubmissionfiles, stepfiles, agreementfiles)...but meh for now.

  let tabName = 'general'
  let failedToLoad = false

  const hasFooter = !!$$props.$$slots?.footer

  $: canManageDocuments = !$user.isImpersonating || isDevEnvironment // Impersonated students can only view documents list
  $: isImage = isImageFile(userDocument?.contentType)
  $: isPdf = isPdfFile(userDocument?.contentType)
  $: onlyYou = isOnlyYou(userDocument, userDocumentSharing)

  function isOnlyYou(userDocument, userDocumentSharing) {
    if (!userDocument || !userDocumentSharing) return true
    const matchById = new Map()
    for (const match of userDocumentSharing.matches) {
      matchById.set(match.matchId, match)
    }
    for (const msd of userDocument.matchStepDownloaders) {
      const match = matchById.get(msd.matchId)
      if (match.status > MatchStatus.Unsubmitted) return false
    }
    return true
  }
</script>

<FilterTypeUserListFilter {filter} {editing} {filterOptions} {appliedMeta} />

<script context="module">
  import FilterTypeUserListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeUserListFilter.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.MatchWaitlistedBy,
    label: 'Waitlisted by',
    labelApplied: 'Waitlisted by',
    icon: 'Waitlisted',
    iconClass: 'text-sky',
    canHaveMultiple: false,
    hasDoesntHave: false,
    optionsEndpoint: 'getWaitlistedByUsers',
    toMany: false,
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>

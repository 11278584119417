export default function getValueFromCollectionBySubpath(
  collection,
  subpathOrSubpaths,
  keyOrKeys,
  newFn,
  copyFn,
  loadAndSetAsyncFn,
  valuesBySubpath = {}
) {
  const subpaths = Array.isArray(subpathOrSubpaths) ? subpathOrSubpaths : [subpathOrSubpaths]
  const keys = Array.isArray(keyOrKeys) ? keyOrKeys : [keyOrKeys]

  if (!subpaths[0]) return
  if (subpaths[0] === '+') return newFn()
  if (!collection) return

  let hasNewKey = false
  let shouldCopy = false
  const keyValues = []
  for (const [i, subpath] of subpaths.entries()) {
    const regex = i === 0 ? /^(?:(?<plus>\+?)|(?<new>-?))(?<key>\d*)$/ : /^(?<new>-?)(?<key>\d*)$/
    const result = extractFromSubpath(subpath, regex, valuesBySubpath)
    if (!result) continue

    shouldCopy ||= result.shouldCopy
    hasNewKey ||= result.isNewKey
    const keyValue = result.keyValue == null ? null : result.isNewKey ? -result.keyValue : result.keyValue
    keyValues.push(keyValue)
    collection = keyValue == null ? collection.filter(o => o[keys[i]] == keyValue) : collection.filter(o => o[keys[i]] === keyValue)
  }

  const found = collection[0]

  if (found) return shouldCopy ? copyFn(found) : found
  // Could throw an error if it's temporary and not found. But, eh.
  if (!hasNewKey && loadAndSetAsyncFn) {
    loadAndSetAsyncFn(keyValues.length === 1 ? keyValues[0] : keyValues, shouldCopy)
  }
  return null
}

function extractFromSubpath(subpath, regex, valuesBySubpath) {
  const match = subpath.match(regex)
  if (match) {
    return {
      shouldCopy: match.groups.plus,
      isNewKey: !!match.groups.new,
      keyValue: Number.parseInt(match.groups.key, 10),
    }
  }
  if (subpath in valuesBySubpath) {
    return valuesBySubpath[subpath]
  }
  return null
}

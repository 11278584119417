{#if tree}
  {#each tree as d (d.label)}
    {#if allowParentSelections || !d.isParent}
      {#if multiple}
        <div class="checkbox checkbox-primary {d.disabled ? 'disabled' : ''}" title={d.disabled ? disabledOptionsMsg : null}>
          <input
            type="checkbox"
            id={d.label}
            disabled={d.disabled}
            checked={d.selected}
            on:change={() => toggleSelected(d)}
            indeterminate={d.indeterminate}
            data-test="{d.label}-checkbox"
          />
          <label data-test="discipline-label-{d.label}" for={d.label}>{d.label}</label>
        </div>
      {:else}
        <div class="radio radio-primary {d.disabled ? 'disabled' : ''}" title={d.disabled ? disabledOptionsMsg : null}>
          <input type="radio" id={d.label} disabled={d.disabled} checked={d.selected} on:change={() => toggleSelected(d)} />
          <label for={d.label}>{d.label}</label>
        </div>
      {/if}
    {:else}
      <div class="mt1" data-test="unselectable-label-{d.label}"><strong>{d.label}</strong></div>
    {/if}
    <div class="ml2">
      <svelte:self tree={d.children} {multiple} {toggleSelected} {disabledOptionsMsg} {allowParentSelections} />
    </div>
  {/each}
{/if}

<script>
  export let tree = null
  export let multiple = null
  export let toggleSelected = null
  export let disabledOptionsMsg = null
  export let allowParentSelections
</script>

{#each fragmentsArray as fragment}
  {#if isString(fragment) || typeof fragment === 'number'}
    {fragment}
  {:else if fragment.type === 'a'}
    <a href={null} {...fragment.props}><svelte:self fragments={fragment.childFragments} /></a>
  {:else if fragment.type === 'strong'}
    <strong {...fragment.props}><svelte:self fragments={fragment.childFragments} /></strong>
  {:else if fragment.type === 'Icon'}
    <Icon {...fragment.props} />
  {:else if fragment.type === 'FriendlyList'}
    <FriendlyList {...fragment.props} let:item><svelte:self fragments={item} /></FriendlyList>
  {:else if fragment.type === 'OrgProfilePictureAndName'}
    <OrgProfilePictureAndName {...fragment.props} />
  {:else if fragment.type === 'TeamProfilePictureAndName'}
    <TeamProfilePictureAndName {...fragment.props} />
  {/if}
{/each}

<script context="module">
  export const builder = {
    anchor: (childFragments, props = {}) => ({ type: 'a', childFragments, props }),
    strong: (childFragments, props = {}) => ({ type: 'strong', childFragments, props }),
    icon: (name, props = {}) => ({ type: 'Icon', props: { name, ...props } }),
    friendlyList: props => ({ type: 'FriendlyList', props }),
    org: props => ({ type: 'OrgProfilePictureAndName', props }),
    team: props => ({ type: 'TeamProfilePictureAndName', props }),
  }
</script>

<script>
  import Icon from 'components/Icon.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import TeamProfilePictureAndName from 'components/TeamProfilePictureAndName.svelte'

  export let fragments

  $: fragmentsArray = Array.isArray(fragments) ? fragments : [fragments]

  const isString = _.isString
</script>

<QuickDropdown
  dataTest="comment-visibility"
  btnClass="btn btn-default btn-md"
  dropdownClass="p1"
  autoSized
  positionMenu="fixed"
  popperOptions={{ placement: 'bottom-start' }}
>
  <div slot="label">
    {dropdownLabel}
    <Icon
      name="info"
      class="text-info"
      title={{ content: infoTooltip, options: { interactive: true, theme: 'light-gray-scrollable', maxWidth: 360 } }}
    />
    <div class="hide">
      <div bind:this={infoTooltip}>
        Clinical admins can view all comments. If you need a more private option, consider sending a <Link to="/messages">Message</Link> instead.
      </div>
    </div>
  </div>
  <strong>Comment visibility for {isBulkComment ? 'the selected rotations' : 'this rotation'}</strong>
  <InputCheckboxGroup name="comment-visibility-roles" options={roleGroups} bind:value={selectedRoleGroups} labelWrapperClass="full-width" let:option>
    <CommentVisibilityRoleLabel label={option.label} users={option.users} {isBulkComment} tipOptions={{ offset: [0, 36] }} />
  </InputCheckboxGroup>

  {#if users.length}
    <Collapsible label="Add individual users" name="add-individual-users" contentScrollable slim noMarginBottom>
      <div style="max-height: 20vh">
        <InputCheckboxGroup name="comment-visibility-students" options={users} bind:value={selectedUsers} let:option>
          <UserProfilePicAndName
            user={option}
            profilePicXs
            tooltip={{ content: option.tooltip, options: { theme: 'light-gray-scrollable', placement: 'left', offset: [0, 36] } }}
          />
        </InputCheckboxGroup>
      </div>
    </Collapsible>
  {/if}
</QuickDropdown>

<script>
  import { Link } from 'svelte-routing'
  import { MatchRole } from './../config/enums.js'
  import { pluralCount, toFriendlyList } from 'services/string-utils.js'
  import Collapsible from 'components/Collapsible.svelte'
  import CommentVisibilityRoleLabel from 'components/CommentVisibilityRoleLabel.svelte'
  import Icon from 'components/Icon.svelte'
  import InputCheckboxGroup from 'components/fields/InputCheckboxGroup.svelte'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import user from 'stores/user.js'
  import UserProfilePicAndName from 'components/UserProfilePicAndName.svelte'

  // inputs
  export let matchUsers = []
  export let isBulkComment = false

  // inputs/outputs
  export let selectedUsers
  export let selectedRoles

  const roleGroups = [
    { id: 1, roles: [MatchRole.SchoolCoordinator, MatchRole.GuestAdmin, MatchRole.GuestViewer], label: 'school coordinators', users: [] },
    { id: 2, roles: [MatchRole.SchoolFaculty, MatchRole.GuestAdmin, MatchRole.GuestViewer], label: 'school faculty', users: [] },
    { id: 3, roles: [MatchRole.ClinicCoordinator, MatchRole.HostAdmin, MatchRole.HostViewer], label: 'clinic coordinators', users: [] },
    { id: 4, roles: [MatchRole.Preceptor, MatchRole.HostAdmin, MatchRole.HostViewer], label: 'preceptors', users: [] },
    { id: 5, roles: [MatchRole.Student], label: 'students', users: [] },
  ]
  let infoTooltip
  let users = []
  let selectedRoleGroups

  $: matchUsers, setUsersAndRoles()
  $: selectedRoles = [...new Set(selectedRoleGroups.flatMap(rg => rg.roles))] // distinct roles
  $: dropdownLabel = `Visible to ${toFriendlyList([
    selectedRoleGroups.length
      ? selectedRoleGroups.length === 1
        ? selectedRoleGroups[0].label
        : selectedRoleGroups.length == roleGroups.length
          ? 'all roles'
          : pluralCount('role', selectedRoleGroups)
      : null,
    selectedUsers.length
      ? selectedUsers.length === 1
        ? selectedUsers[0].name
        : selectedUsers.length == users.length
          ? 'all users'
          : pluralCount('user', selectedUsers)
      : null,
    selectedRoleGroups.length || selectedUsers.length ? null : 'only you',
  ])}`

  function setUsersAndRoles() {
    // users can have multiple match roles, get 1 instance of each user (except the current user) with all their roles
    users = []
    for (const matchUser of matchUsers.filter(u => u.userId !== $user.userId)) {
      const { userId, name, initials } = matchUser
      const user = users.find(u => u.userId === userId)
      if (!user) {
        // get match roles for all instances of this user
        const matchRoles = matchUsers.filter(m => m.userId === userId).map(m => m.matchRole)
        users.push({ userId, name, initials, matchRoles })
      }
    }
    users.sort((a, b) => a.name.localeCompare(b.name))

    // if passed in selectedUsers we need to remap to the user objects so they are properly selected
    selectedUsers = selectedUsers?.map(su => users.find(u => u.userId === su.userId)) ?? []

    for (const roleGroup of roleGroups) {
      roleGroup.users = users.filter(u => roleGroup.roles.some(role => u.matchRoles.includes(role)))
    }

    // if passed in selectedRoles use them, otherwise default to all roleGroups
    selectedRoleGroups = selectedRoles ? roleGroups.filter(rg => rg.roles.every(role => selectedRoles.includes(role))) : roleGroups
    // create a tooltip for each user
    for (const u of users) {
      const userRoleGroups = roleGroups.filter(rg => rg.roles.some(role => u.matchRoles.includes(role))).map(rg => rg.label)
      u.tooltip = 'Member of ' + toFriendlyList(userRoleGroups, Infinity)
    }
  }
</script>

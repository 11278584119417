import { DashboardViewCategory, FeatureType, Permission, FilterType, CriteriaFilterType } from 'config/enums.js'

const excludedFilterTypes = [FilterType.KeywordSearch, FilterType.MatchStatusCategory, FilterType.StepSubmissionTargetUser]

export default {
  category: DashboardViewCategory.Reports,
  permission: Permission.ViewStudentReport,
  featureTypes: [FeatureType.HourLogging],
  componentName: 'HourLogReport',
  name: 'Hours',
  icon: 'time',
  criteria: {
    filters: [],
    sortProperty: 'DateCreated',
    sortAscending: false,
    startDate: dayjs().subtract(1, 'year').startOf('year').format('M/D/YYYY'),
    endDate: dayjs().subtract(1, 'year').endOf('year').format('M/D/YYYY'),
  },
  excludedFilterTypes,
  filtersConfig: [
    {
      prefix: '',
      name: 'filters',
      type: CriteriaFilterType.MatchFilter,
    },
  ],
  criteriaConfig: {
    sortProperty: { name: 'sort' },
    sortAscending: { name: 'asc', method: 'readBool' },
    startDate: { name: 'sd', method: 'readDate' },
    endDate: { name: 'ed', method: 'readDate' },
    type: { name: 'type', method: 'readInt' },
    keyword: { name: 'word' },
  },
  templates: [
    // {
    //   name: 'MERC',
    //   color: 'purple',
    //   criteria: {
    //     // last year is probably what they want to see
    //     startDate: dayjs().subtract(1, 'year').startOf('year').format('M/D/YYYY'),
    //     endDate: dayjs().subtract(1, 'year').endOf('year').format('M/D/YYYY'),
    //     sortProperty: 'Location',
    //     sortAscending: true,
    //   }
    // }
  ],
}

<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} selectedItemsContainerClass={null} let:option={o} let:item>
  {#if o?.optionLabel}
    {o.optionLabel}
  {:else}
    <em>Unspecified</em>
  {/if}

  <svelte:fragment slot="selectedItem">
    {#if item?.optionLabel}{item.optionLabel}{:else}<em>unspecified</em>{/if}
  </svelte:fragment>
</FilterTypeListFilter>

<script context="module">
  import FilterTypeListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeListFilter.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.Disciplines,
    idKey: 'optionValue',
    label: 'Student discipline',
    icon: 'book',
    iconClass: 'color-text-teal',
    canHaveMultiple: true,
    filterProp: 'disciplines',
    optionsEndpoint: 'getDisciplines',
    filterPropType: 'string',
    allowNull: true,
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>

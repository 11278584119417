<p class="{className} m0">
  <slot name="icon" />
  <span class={textClass}>
    {toStudent ? 'Your student' : 'This student’s'} year is set to
    <Icon name="calendars" class="color-text-teal" /> <strong>{toStudentYear(studentYear)}</strong>, whereas {toStudent ? 'this' : 'the selected'} opportunity
    is configured to
    <strong>only accept</strong>
    <StudentYearList studentYears={capacityStudentYears} />
    students.
  </span>
</p>

<script>
  import { toStudentYear } from 'services/formatters.js'
  import Icon from 'components/Icon.svelte'
  import StudentYearList from 'components/StudentYearList.svelte'

  export let capacityStudentYears
  export let studentYear
  export let toStudent = true
  let className = 'alert alert-info'
  export { className as class }
  export let textClass = ''
</script>

<ButtonModalPicker
  on:changed
  bind:this={buttonModalPicker}
  bind:open
  lg
  {disabled}
  {multiple}
  {loading}
  hideSelectAll
  valueSelector={o => o.userId}
  labelSelector={o => o.name}
  options={staffMembers?.map(s => s.user)}
  bind:value
  bind:valueTemp
  {modalTitle}
  {placeholder}
  {dataTest}
  modalClass="overflow-visible"
  modalContentClass="overflow-visible"
  confirmButtonLabel="Add {valueTemp?.length || ''} staff"
  confirmButtonIcon="plus"
  cancelButtonIcon="close"
  footerClass="text-left"
>
  <svelte:fragment slot="modal-link">
    <IconTextLink text="Add staff" onClick={() => (open = true)} id="add-staff-btn" />
  </svelte:fragment>

  <slot slot="top-modal-body" name="top-modal-body" tempSelectedCount={valueTemp?.length ?? 0} />

  <div class="flex-row flex-wrap flex-align-stretch g2">
    <div class="flex-grow flex-column main-form-content">
      <FormGroup valid class="full-height flex-column g1">
        <StaffFilters fullWidth {excludedFilterTypes} bind:filters {interceptors} onChanged={() => loadPage(0)}>
          <svelte:fragment slot="after-keyword-search">
            <StaffPickerShowDropdown />
          </svelte:fragment>
          <CapacityStaffFilter bind:onlyAllowedStaff {teamName} {serviceName} {orgName} disciplines={capacity.disciplines} slot="after-filters" />
        </StaffFilters>

        {#if loading && currentXhrBody.offset === 0}
          <div class="text-center mt1">
            <Spinner x3 class="m2" />
          </div>
        {:else}
          <InfiniteScroll
            currentCount={staffMembers?.length}
            {totalCount}
            distanceToLoadPage={100}
            {loadPage}
            class="scrollable-lg flex-grow flex-column {showMinimal ? 'g05' : 'g1'}"
          >
            <EmptyPickerSlot bind:valueTemp {allowSelectNull} text="No staff member" {multiple} {buttonModalPicker} {dataTest} lg={!showMinimal} />
            {#if staffMembers?.length}
              {#each staffMembers as s, i (s.user.userId)}
                <PickerSlot
                  {i}
                  {dataTest}
                  {multiple}
                  value={s.user.userId}
                  {buttonModalPicker}
                  lg={!showMinimal}
                  hideSelectedLabel
                  bind:valueTemp
                  showCheckbox
                  showHeader={false}
                  let:isSelected
                  let:isHovered
                >
                  <slot staff={s} {isSelected} {isHovered}>
                    <StaffPickerSlot staff={s} showCapacityAccessibility {isHovered} />
                  </slot>
                </PickerSlot>
              {/each}
            {:else if staffMembers == null}
              <!-- This should never happen, but just in case... -->
              Failed to load staff members. <a href={null} on:click={() => loadPage(0)}>Retry?</a>
            {:else}
              <h4 class="p3 text-center">No staff members found.</h4>
            {/if}
          </InfiniteScroll>
        {/if}
      </FormGroup>
    </div>
    <div class="line" />
    <div class="well flex-column right-panel">
      <CapacityStaffTitles bind:selectedTitles {capacity} />
      <hr class="my2" />
      <CapacityStaffPermissionOverview bind:userIds={valueTemp} {staffMembers} />
      {#if capacity.staff?.length}
        <hr class="my2" />
        <CapacityStaffAdded {capacity} />
      {/if}
    </div>
  </div>
</ButtonModalPicker>

<script>
  import { FeatureType, FilterType } from 'config/enums.js'
  import { removeDuplicatesAndSort } from 'services/array-utils.js'
  import api from 'services/api.js'
  import ButtonModalPicker from 'components/fields/ButtonModalPicker.svelte'
  import EmptyPickerSlot from 'components/EmptyPickerSlot.svelte'
  import CapacityStaffFilter from 'components/CapacityStaffFilter.svelte'
  import CapacityStaffTitles from 'components/CapacityStaffTitles.svelte'
  import CapacityStaffPermissionOverview from 'components/CapacityStaffPermissionOverview.svelte'
  import CapacityStaffAdded from 'components/CapacityStaffAdded.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import InfiniteScroll from 'components/InfiniteScroll.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import PickerSlot from 'components/PickerSlot.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import Spinner from 'components/Spinner.svelte'
  import StaffFilters from 'components/StaffFilters.svelte'
  import StaffPickerShowDropdown from 'components/StaffPickerShowDropdown.svelte'
  import StaffPickerSlot from 'components/StaffPicker.Slot.svelte'
  import validator from 'services/validator.js'
  import roleService from 'services/role-service'

  // Common picker exports
  export let value
  export let filters = []
  export let excludedFilterTypes = []
  export let placeholder = 'None selected'
  export let multiple = false
  export let modalTitle = multiple ? 'Select the staff members' : 'Select the staff member'
  export let disabled = false
  export let allowSelectNull = false
  export let dataTest = 'staff-picker'
  export let interceptors = {}
  export let open = false

  // Specific picker exports
  export let capacity = null
  export let selected = null
  export let excludeStaffIds = null
  export let excludeUserIds = null
  export let includeChildren = true
  export let includeEmail = true
  export let includeParents = false
  export let staffMemberCount = null
  export let teamName
  export let serviceName
  export let orgName

  const pageSize = 15
  let buttonModalPicker = null
  let currentXhrBody = null
  let loading = false
  let totalCount = null
  let valueTemp = null
  let onlyAllowedStaff = false
  let selectedTitles
  let staffMembers = []

  $: show = $showDropdowns.staffPicker
  $: showMinimal = !Object.keys(show)
    .map(k => show[k])
    .some(Boolean)
  $: hasCoreSchedulingFeature = personaService.canUseAnyFeatureType(FeatureType.CoreScheduling)
  $: personaOrgId = $persona.orgId
  $: personaOrgId, hasCoreSchedulingFeature, onlyAllowedStaff, loadPage(0)
  $: open, loadFirstPageIfFiltersChanged()
  $: value, staffMembers, setSelected()
  $: if (open) setViewRestrictionMessage()

  function setSelected() {
    if (value == null) {
      selected = null
    } else {
      const selectedStaff = staffMembers
        .filter(s => value.includes(s.user.userId))
        .map(({ staffId, title, externalId, user, org, capacityAccessibilityTooltip, orgStaffRoles }) => ({
          isNew: true,
          staffId,
          orgTitle: title,
          externalId,
          userId: user.userId,
          name: user.name,
          email: user.email,
          staffOrgId: org.orgId,
          profilePicture: user.profilePicture,
          capacityAccessibilityTooltip,
          orgStaffRoles,
        }))

      for (const staff of selectedStaff) {
        const i = capacity.staff.findIndex(s => s.staffId === staff.staffId)
        if (i < 0) {
          staff.titles = removeDuplicatesAndSort(selectedTitles)
          capacity.staff.push(staff)
        } else {
          staff.titles = removeDuplicatesAndSort([...selectedTitles, ...capacity.staff[i].titles])
          capacity.staff[i] = staff
        }
      }
      capacity.staff = capacity.staff
      clear()
    }
  }

  function loadFirstPageIfFiltersChanged() {
    if (!open) return
    const filtersChanged = !validator.equals(filters ?? [], currentXhrBody?.filters ?? [])
    if (filtersChanged) loadPage(0)
  }

  async function loadPage(offset) {
    if (!$persona.orgId || !hasCoreSchedulingFeature) return

    const body = {
      apiVersion: '2.0',
      orgId: personaOrgId,
      filters: _.cloneDeep(filters),
      includeParents,
      includeChildren,
      includeEmail,
      pageSize,
      offset,
    }
    if (!value && excludeUserIds?.length) body.filters.push({ type: FilterType.ExcludeUsers, config: { userIds: excludeUserIds } })
    if (!value && excludeStaffIds?.length) body.filters.push({ type: FilterType.ExcludeStaff, config: { staffIds: excludeStaffIds } })
    if (onlyAllowedStaff) {
      body.filters.push({
        type: FilterType.HasAccess,
        config: {
          capacityId: capacity.capacityId,
          orgId: capacity.orgId,
          disciplines: capacity.disciplines,
          serviceId: capacity.serviceId,
          teamId: capacity.teamId,
        },
      })
    }
    const staffRoleFilter = filters.find(f => f.type == FilterType.StaffRole)
    body.filters.push({ type: FilterType.StaffRoleOrg, config: { staffRoles: staffRoleFilter?.config.staffRoles, orgId: personaOrgId } })

    if (validator.equals(currentXhrBody, body) && noStaffUpdated()) return
    loading = true
    currentXhrBody = body

    try {
      const task = api.staff.list(body, api.noMonitor)
      const response = await task

      totalCount = response.totalCount
      staffMembers = offset ? [...staffMembers, ...response.staff] : response.staff

      if (offset === 0 && staffMemberCount == null) staffMemberCount = totalCount

      setViewRestrictionMessage()
    } finally {
      if (validator.equals(currentXhrBody, body)) loading = false
    }
  }

  function noStaffUpdated() {
    if (capacity.staff?.length) {
      return staffMembers.every(searchedStaff => {
        const capacityStaff = capacity.staff.find(cs => cs.staffId == searchedStaff.staffId)

        if (
          capacityStaff &&
          (capacityStaff.orgTitle !== searchedStaff.title ||
            capacityStaff.externalId != searchedStaff.externalId ||
            !validator.equals(capacityStaff.orgStaffRoles, searchedStaff.orgStaffRoles))
        )
          return false
        return true
      })
    }

    return true
  }

  function setViewRestrictionMessage() {
    for (const staff of staffMembers) {
      staff.capacityAccessibilityTooltip = roleService.getCapacityAccessibilityTooltip(capacity, staff.orgStaffRoles)
    }
  }

  export function clear() {
    value = null
    valueTemp = null
  }

  export function focusAndOpen() {
    buttonModalPicker?.focusAndOpen()
  }
</script>

<style lang="scss">
  .line {
    width: 1px;
    background-color: #000;
    opacity: 0.2;
  }
  .right-panel {
    width: 250px;
  }

  .main-form-content {
    width: calc(100% - 320px);
  }

  @media only screen and (max-width: 1250px) {
    .main-form-content {
      width: 100%;
    }
  }
</style>

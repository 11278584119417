<QuickDropdown label="Show…" icon="sliders-simple" btnClass="btn btn-default" labelClass="flex-row flex-align-center g05" dropdownClass="p2">
  <div class="flex-column g2">
    <div class="flex-row flex-align-center g05">
      <Btn clearBtnStyling dataTest="show-everything" on:click={() => toggleAll(true)}>Show everything</Btn>
      <div class="flex-grow" />
      <Btn clearBtnStyling dataTest="show-minimal" on:click={() => toggleAll(false)}>Show minimal</Btn>
    </div>

    <div class="flex-column g05">
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.agreementPicker.status} name="show-status">
        <Icon lg name="filter-status" class="color-text-steel" />
        Status
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.agreementPicker.activeCapacityCount} name="show-capacities">
        <Icon lg name="list" class="color-text-purple" />
        Active opportunities
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.agreementPicker.services} name="show-services">
        <Icon lg name="shapes" class="color-text-purple" />
        Services
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.agreementPicker.description} name="show-description">
        <Icon lg name="paragraph" class="color-text-steel" />
        Description
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.agreementPicker.disciplines} name="show-disciplines">
        <Icon lg name="book" class="color-text-teal" />
        Disciplines
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <h4>Provided by</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.agreementPicker.organization} name="show-organization">
        <Icon lg name="hospital" class="color-text-orange" />
        Organization
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.agreementPicker.team} name="show-team">
        <Icon lg name="users" class="color-text-orange" />
        Team
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <h4>School</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.agreementPicker.school} name="show-school">
        <Icon lg name="school" class="color-text-blue" />
        School
      </InputToggle>
    </div>
  </div>
</QuickDropdown>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'

  const toggleParams = {
    containerClass: 'flex-row flex-align-center g1',
    labelClass: 'flex-row flex-align-center g05',
    class: 'm0',
  }

  function toggleAll(checked) {
    for (const key in $showDropdowns.agreementPicker) {
      if (typeof $showDropdowns.agreementPicker[key] !== 'boolean') continue
      $showDropdowns.agreementPicker[key] = checked
    }
  }
</script>

<style>
  h4 {
    margin: 0;
    line-height: 1;
    font-size: 16px;
  }
</style>

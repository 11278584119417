Schools can schedule into
{#if capacity.requestAvailabilityPolicy === RequestAvailabilityPolicy.MultipleAvailableShifts}
  multiple windows
{:else}
  a single window
{/if}
that they have access to.

<script>
  import { RequestAvailabilityPolicy } from 'config/enums.js'

  export let capacity
</script>

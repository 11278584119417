<code data-test="copyable-code" id={name} class:may-interact={mayInteract} style={valueStyle}>{value}</code>
<a
  href={null}
  data-test="copyable-code-btn"
  class={copyClass}
  use:onInteract={() => copytext(value, successMsg)}
  use:tip={{ content: 'Copy to clipboard', options: { sticky: true } }}
  use:onMayInteract={e => (mayInteract = e.focusIn || e.mouseIn)}
>
  <Icon name="copy" />
</a>

<script>
  import copytext from 'services/copytext.js'
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'
  import onInteract from 'decorators/on-interact.js'
  import onMayInteract from 'decorators/on-may-interact.js'

  export let value
  export let successMsg = null
  export let name = null
  export let valueStyle = null
  export let copyClass = 'px1 py05'

  let mayInteract = false
</script>

<style lang="scss">
  @import '../../css/helpers';

  code {
    user-select: all;
    border: 2px solid transparent;

    &.may-interact {
      border-color: $primary;
      background: $state-primary-bg;
    }
  }
</style>

<Grid
  let:row
  {loading}
  rows={capacityGuests}
  name="capacity-guests"
  pagesOfLabelSingular="opportunity school"
  {pageSize}
  bind:currentPage
  paginatorTotalCount={totalCount}
  cellClassSelector={() => 'middle'}
  href={cg => `${baseHref}/schools/${cg.guestOrgId == null ? 'open' : cg.guestOrgId}`}
>
  <GridCol {row} header="School" name="guestOrg">
    <div data-test="capacity-guest" class="flex-column g1" style="max-width: 400px">
      {#if row.guestOrgId}
        <OrgProfilePictureAndName
          name={row.guestOrgName}
          profilePicture={row.guestOrgProfilePicture}
          relativeName={row.guestOrgRelativeName}
          class="flex-row flex-align-center"
        />

        {#if row.agreement}
          <AgreementProfilePicturesAndName
            class="flex-column g05"
            noTooltip
            name={row.agreement.name}
            orgName={row.agreement.orgName}
            orgRelativeName={row.agreement.orgRelativeName}
            orgProfilePicture={row.agreement.orgProfilePicture}
            teamName={row.agreement.teamName}
            teamOrgId={row.agreement.teamOrgId}
            schoolName={row.agreement.schoolName}
            schoolRelativeName={row.agreement.schoolRelativeName}
            schoolProfilePicture={row.agreement.schoolProfilePicture}
            schoolTeamName={row.agreement.schoolTeamName}
            schoolTeamOrgId={row.agreement.schoolTeamOrgId}
          />
        {:else if row.guestOrgId}
          <NoAgreementSpecified />
        {/if}

        <CapacityGuestBlockedMessage
          allowedByHealthSystem={row.isAllowedByHealthSystem}
          allowedBySchool={row.isAllowedBySchool}
          tipOptions={{ placement: 'bottom' }}
        />
      {:else}
        <CapacityGuestProfilePictureAndName capacityGuest={row} />
      {/if}
    </div>
  </GridCol>

  <GridCol {row} header="Scheduling between" name="schedulingDates" class="shrink" thClass="nowrap">
    <StartAndEndDate startDate={row.schedulingStartDate} endDate={row.schedulingEndDate} />
  </GridCol>

  <GridCol {row} header="Maximum attendance" name="maximumAttendance" class="shrink">
    <RotationCount count={row.maxMatches || null} name="max-rotations" nullMeansInfinity iconTitle="Maximum rotations" iconFirst />
    {#if capacity.allowGroups}
      <StudentCount count={row.maxStudents || null} name="max-students" nullMeansInfinity iconTitle="Maximum students" iconFirst />
    {/if}
  </GridCol>

  <!-- InputOptionalNumber will explicitly return undefined when they've backspaced the field -->
  <!-- To prevent jitter, using !== against null instead of != null like usual -->
  {#if capacity.maxMatches !== null}
    <GridCol {row} header="Guaranteed attendance" name="guaranteedAttendance" class="shrink">
      <RotationCount count={row.guaranteedMatches} name="guaranteed-matches" nullMeansInfinity iconTitle="Guaranteed rotations" iconFirst />
    </GridCol>
  {/if}

  <GridCol {row} header="School settings" name="schoolSettings" class="shrink" thClass="nowrap">
    <div class="flex-column">
      <span data-test="auto-accept-matches">
        {#if row.autoAcceptMatches}
          <Icon name="check" class="text-success" /> Auto
        {:else}
          <Icon name="close" class="text-danger" /> Don’t auto
        {/if}
        approve requests
      </span>

      <span data-test="allow-adding-placeholder-students">
        {#if row.allowsAddingPlaceholderStudents}
          <Icon name="check" class="text-success" /> Allowed
        {:else}
          <Icon name="close" class="text-danger" /> NOT allowed
        {/if}
        to add placeholder students
      </span>

      <AutoReleaseUnscheduledMatchesWhenLabel concise value={row.autoReleaseUnscheduledMatchesWhen} />
    </div>
  </GridCol>

  <svelte:fragment slot="footer">
    {#if capacityGuests.length > 1}
      <tfoot>
        <tr class="totals-row">
          <td colspan="2" />
          <td>
            <div class="flex-column">
              <div>
                <RotationCount
                  count={totalMaxMatches || null}
                  name="total-max-rotations"
                  nullMeansInfinity
                  iconTitle="Total maximum rotations"
                  iconFirst
                  symbol={allTruthyOrAllFalsy(capacityGuests, 'maxMatches') ? 'times' : 'greater-than-equal'}
                />
              </div>
              {#if capacity.allowGroups}
                <div>
                  <StudentCount
                    count={totalMaxStudents || null}
                    name="total-maximum-students"
                    nullMeansInfinity
                    iconTitle="Total maximum students"
                    iconFirst
                    symbol={allTruthyOrAllFalsy(capacityGuests, 'maxStudents') ? 'times' : 'greater-than-equal'}
                  />
                </div>
              {/if}
            </div>
          </td>
          {#if capacity.maxMatches !== null}
            <td>
              <RotationCount
                count={totalGuaranteedMatches || null}
                name="guaranteed-matches-count"
                iconTitle="Total guaranteed rotations"
                iconFirst
              />
            </td>
          {/if}
          <td />
        </tr>
      </tfoot>
    {/if}
  </svelte:fragment>
</Grid>

<script>
  import { allTruthyOrAllFalsy } from 'services/array-utils.js'
  import AgreementProfilePicturesAndName from 'components/AgreementProfilePicturesAndName.svelte'
  import AutoReleaseUnscheduledMatchesWhenLabel from 'components/AutoReleaseUnscheduledMatchesWhenLabel.svelte'
  import CapacityGuestProfilePictureAndName from 'components/CapacityGuestProfilePictureAndName.svelte'
  import CapacityGuestBlockedMessage from 'components/CapacityGuestBlockedMessage.svelte'
  import Grid from 'components/Grid.svelte'
  import GridCol from 'components/GridCol.svelte'
  import Icon from 'components/Icon.svelte'
  import NoAgreementSpecified from 'components/NoAgreementSpecified.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import RotationCount from 'components/RotationCount.svelte'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import StudentCount from 'components/StudentCount.svelte'

  export let baseHref
  export let capacity
  export let currentPage = 1
  export let pageSize = 10

  $: loading = capacity == null
  $: capacityGuests = capacity?.guests ?? []
  $: totalCount = capacityGuests.length
  $: totalMaxMatches = _.sumBy(capacityGuests, g => g.maxMatches || 0)
  $: totalGuaranteedMatches = _.sumBy(capacityGuests, g => g.guaranteedMatches || 0)
  $: totalMaxStudents = capacity.allowGroups ? _.sumBy(capacityGuests, g => g.maxStudents || 0) : null
</script>

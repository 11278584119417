{#if match.isCoordinator || match.isSchoolCoordinator}
  <div>
    <IconTextLink
      dataTest="add-{slugifyEnumValue(MatchRole, role)}"
      text="Add {matchRoleName}"
      id="add-{matchRoleName}"
      class={className}
      onClick={() => (adding = true)}
    />
    <div class="well hide">
      <FormGroup>
        {#if role === MatchRole.SchoolFaculty}
          <MatchStaffPicker
            bind:selected
            bind:allStaff={schoolFaculty}
            bind:open={adding}
            orgId={match.guestOrgId}
            {excludeUserIds}
            dataTest="faculty-picker"
            capacityId={match.capacity.capacityId}
            modalTitle="Select the faculty to add"
            hideSelectAll
          />
        {:else if role === MatchRole.Preceptor}
          <MatchStaffPicker
            bind:selected
            bind:allStaff={preceptors}
            bind:open={adding}
            orgId={match.hostOrgId}
            {excludeUserIds}
            dataTest="preceptor-picker"
            capacityId={match.capacity.capacityId}
            modalTitle="Select the preceptor to add"
            hideSelectAll
            personaTypeisHealth
          />
        {:else if role === MatchRole.Student}
          <!-- TODO: This doesn't support capacity team disciplines, service disciplines, or agreement disciplines -->
          {#if match.placeholderStudentCount && adding}
            <StudentPickerForPlaceholders
              bind:selected
              bind:replacementConfig
              bind:open={adding}
              {match}
              multiple
              modalTitle="Select the student to add"
              {excludeUserIds}
            />
          {:else}
            <StudentPicker
              bind:this={studentPicker}
              bind:selected
              bind:open={adding}
              modalTitle="Select the student to add"
              multiple={false}
              orgId={match.guestOrgId}
              capacityId={match.capacity.capacityId}
              capacityStudentYears={match.capacity.studentYears}
              disciplines={match.capacity.disciplines.length > 0 ? match.capacity.disciplines : match.service?.disciplines}
              allowStudentYearOverride={true}
              {excludeUserIds}
              hideSelectAll
            />
          {/if}
        {/if}
      </FormGroup>
    </div>
  </div>
{/if}

<script>
  import { formatEnumValue, slugifyEnumValue } from 'services/formatters.js'
  import { MatchRole } from 'config/enums.js'
  import { togglePerson } from 'services/capacity-usage.js'
  import MatchStaffPicker from './fields/MatchStaffPicker.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import StudentPicker from 'components/fields/StudentPicker.svelte'
  import StudentPickerForPlaceholders from 'components/fields/StudentPicker.Placeholders.svelte'
  import validator from 'services/validator.js'

  export let role = null
  export let match = null
  export let matchUsers = null
  let className = null
  export { className as class }

  let adding = false
  let studentPicker
  let selected
  let replacementConfig

  // bound to the components which will populate them. kind of awkward use of two-way binding, but works
  let schoolFaculty = null
  let preceptors = null

  $: maybeMatchRoleName = formatEnumValue(MatchRole, role)?.toLowerCase()
  $: matchRoleName = validator.empty(maybeMatchRoleName) ? 'user' : maybeMatchRoleName
  $: excludeUserIds = matchUsers == null ? [] : matchUsers.filter(mu => mu.matchRole === role).map(mu => mu.userId)
  $: if (selected) addUser()

  function addUser() {
    if (selected == null) return
    if (matchUsers.some(mu => mu.userId === selected.userId && mu.matchRole === role)) return
    const matchUser = {
      ..._.cloneDeep(selected),
      matchRole: role,
      matchId: match.matchId,
      explicitlyAdded: true,
    }
    matchUsers = [...matchUsers, matchUser]
    if (replacementConfig) {
      match.placeholderStudentCount--
      const shiftIds = new Set(Object.keys(replacementConfig.selectedMatchDays).map(Number))
      const shifts = match.shifts.filter(s => shiftIds.has(s.shiftId))
      for (const shift of shifts) {
        const matchDayConfigs = replacementConfig.selectedMatchDays[shift.shiftId]
        const days = replacementConfig.selectedMatchDays[shift.shiftId].map(d => d.date)
        match.matchDays = togglePerson(match.matchDays, days, shift, { userId: selected.userId }, false)
        match.matchDayConfigs = match.matchDayConfigs.map(mdc => {
          if (matchDayConfigs.includes(mdc)) mdc.placeholderStudentCount--
          if (mdc.placeholderStudentCount > match.placeholderStudentCount) mdc.placeholderStudentCount = match.placeholderStudentCount
          if (mdc.placeholderStudentCount < 0) mdc.placeholderStudentCount = 0
          return mdc
        })
        match.matchDayConfigs = match.matchDayConfigs.filter(mdc => mdc.placeholderStudentCount > 0 || mdc.startTime || mdc.endTime)
      }
      replacementConfig = null
    }
    adding = false
    selected = null
    studentPicker?.clear()
  }
</script>

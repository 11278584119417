<div id="spotlights" bind:this={elem} class:active use:focusTrap={{ active }}></div>

<script>
  import focusTrap from 'decorators/focus-trap.js'

  // We could just track :not(:empty), but we need the focus trap to know when to activate.
  let active = false
  let elem

  $: if (elem) addMutationObserver()

  function addMutationObserver() {
    const observer = new MutationObserver(() => {
      active = elem.children.length > 0
    })
    observer.observe(elem, { childList: true })
  }
</script>

<style lang="scss">
  #spotlights.active {
    z-index: 1051;
    position: fixed;
    inset: 0;
    background: rgba(0, 0, 0, 0.5);
  }
</style>

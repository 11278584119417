<InputSelect
  bind:value
  bind:this={inputSelect}
  {name}
  options={orgs}
  {disabled}
  max={maxSelectedToShow}
  selectedOptions={showSelectedWhenClosed ? selectedArray : null}
  {pageSize}
  {totalCount}
  loading={loadingSelected}
  loadPage={loadOrgs}
  filterable
  filterStringSelector={o => [o.name]}
  valueSelector={o => o.orgId}
  {placeholder}
  {title}
  {tooltipPlacement}
  {multiple}
  {or}
  {textStyle}
  on:toggle={onToggle}
  on:change={onChangeProxy}
  let:option={org}
>
  <OrgProfilePictureAndName name={org.name} relativeName={org.relativeName} profilePicture={org.profilePicture} />
</InputSelect>

<script context="module">
  import { getParentNames, getFlatAncestors } from 'services/orgs-service.js'
  import personaService from 'services/persona-service.js'

  // calling code should load org pages, but most of that code to live in this module, so it's easier to setup usages of this component
  const pageSize = 20

  export function processListOrgsResponse(previousRes, res, offset, requiredPermission) {
    previousRes ??= {
      orgs: [],
      totalCount: 0,
      selectedOrgs: [],
    }
    res.orgs = res.orgs.map(o => {
      return {
        ...o,
        relativeParentName: getParentNames(o.relativeName),
      }
    })

    for (const org of res.orgs) {
      org.ancestors ??= getFlatAncestors(org, res.orgs, false)
      org.toJSON = function () {
        const withoutCircularReferences = { ...this }
        delete withoutCircularReferences.ancestors
        return withoutCircularReferences
      }
    }

    if (offset !== 0) {
      // don't include any that are already loaded in (perhaps one was deleted server-side, so offset/pagesize includes one we already have--don't want page to freeze up because unique each key violation)
      res.orgs = [...previousRes.orgs, ...res.orgs.filter(o => !previousRes.orgs.some(po => po.orgId === o.orgId))]
    }

    if (requiredPermission) {
      // we need to get orgs that are selected even if the logged in user doesn't have permission to pick the org
      const originalLen = res.orgs.length
      res.orgs = personaService.getOrgsWithPermission(res.orgs, requiredPermission)
      const newLen = res.orgs.length
      res.totalCount = res.totalCount - (originalLen - newLen)
    }

    res.allLoadedOrgs = res.selectedOrgs ? [...res.orgs, ...res.selectedOrgs.filter(so => !res.orgs.some(o => o.orgId === so.orgId))] : res.orgs

    return res
  }
</script>

<script>
  import { tick } from 'svelte'
  import { FilterType, ToManyComparison } from 'config/enums.js'
  import api from 'services/api.js'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import OrgProfilePictureAndName from './OrgProfilePictureAndName.svelte'
  import validator from 'services/validator.js'

  export let value
  export let selected = null // Calling code can bind this upward to know the selected org(s), not just orgId(s). Calling code should not _set_ this, only read it.
  export let onChange = _.noop
  export let name = 'simple-org-picker'
  export let placeholder = null
  export let title = null
  export let tooltipPlacement = null
  export let multiple = false
  export let showSelectedWhenClosed = true
  export let maxSelectedToShow = 3
  export let or = false
  export let textStyle = false
  export let disabled = false

  // if calling code only needs access to the selected orgId, pass `orgId`
  export let ancestorOrgId = null
  export let parentOrgId = null
  export let relativeOrgId = null
  export let featureTypes = null
  export let tags = null
  export let includeUnverified = true // most usages need this set to true. and in future, all will have it set to true, and we'll make it clear that they're unverified.
  export let includeAncestorOrgIds = false
  export let excludeOrgIds = null
  export let excludeParentOrgIds = null
  export let autoSelectChildren = false
  export let onLoadOrgs = _.noop
  // if requiredPermission is passed, they can only choose from orgs they have the required permission in
  export let requiredPermission = null
  export let capacityOwnerOrgId = null
  export let respectOrgMatchPreferences = false

  let inputSelect = null
  let loadingSelected = false
  let listOrgsResponse = null
  let selectedArray = null
  let valuePrevious = null
  let currentXHR = null

  $: ancestorOrgId, parentOrgId, relativeOrgId, featureTypes, tags, excludeOrgIds, excludeParentOrgIds, loadOrgs(0, '', false)
  $: orgs = listOrgsResponse?.orgs
  $: totalCount = listOrgsResponse?.totalCount
  $: value, multiple, listOrgsResponse?.allLoadedOrgs, setSelectedIfChanged()

  async function loadOrgs(offset, search, isShowingSelected) {
    if (selectedArray == null) loadingSelected = true
    const filters = []
    if (ancestorOrgId) filters.push({ type: FilterType.HasAncestorOrg, config: { ancestorOrgIds: [ancestorOrgId] } })
    if (parentOrgId) filters.push({ type: FilterType.HasParentOrg, config: { parentOrgIds: [parentOrgId] } })
    if (relativeOrgId) filters.push({ type: FilterType.HasRelativeOrg, config: { relativeOrgIds: [relativeOrgId] } })
    if (featureTypes?.length) filters.push({ type: FilterType.FeatureTypes, config: { comparison: ToManyComparison.AllOf, featureTypes } })
    if (tags?.length) filters.push({ type: FilterType.OrgTags, config: { comparison: ToManyComparison.AllOf, tagSlugs: tags } })
    if (search?.trim()) filters.push({ type: FilterType.KeywordSearch, config: { keyword: search.trim() } })
    if (excludeOrgIds?.length) filters.push({ type: FilterType.ExcludeOrgs, config: { orgIds: excludeOrgIds } })
    if (excludeParentOrgIds?.length) filters.push({ type: FilterType.HasParentOrg, config: { exclude: true, parentOrgIds: excludeParentOrgIds } })
    if (includeUnverified) filters.push({ type: FilterType.Verification, config: { verified: null } })
    if (respectOrgMatchPreferences) filters.push({ type: FilterType.RespectOrgMatchPreferences, config: { capacityOwnerOrgId } })

    const body = {
      filters,
      offset,
      pageSize: 20,
      includeChildrenOrgIds: autoSelectChildren,
      selectedOrgIds: multiple ? value : value == null ? null : [value],
    }
    if (includeAncestorOrgIds) body.includeAncestorOrgIds = true
    let res
    currentXHR?.abort()
    try {
      // Since UI usually only shows like 3-5 of those that are selected, we only need to load that many.
      // Load all of them in only if user clicks "show selected"--later on, we could paginate selected, but not a big deal.
      if (isShowingSelected) {
        currentXHR = api.org.list(
          {
            ...body,
            pageSize: 0,
            excludeTotalCount: true,
          },
          api.noMonitor
        )
        res = await currentXHR
        currentXHR = null
        res.orgs = res.selectedOrgs
      } else {
        currentXHR = api.org.list(
          {
            ...body,
            selectedOrgIds: body.selectedOrgIds?.slice(0, maxSelectedToShow),
          },
          api.noMonitor
        )
        res = await currentXHR
        currentXHR = null
      }
    } finally {
      loadingSelected = false
    }
    listOrgsResponse = processListOrgsResponse(listOrgsResponse, res, offset, requiredPermission)
    onLoadOrgs(listOrgsResponse)
  }

  async function setSelectedIfChanged() {
    // Don't spam calling code with changes to `selected`--can cause unnecessary network requests and js work.
    // Since this function gets called whenever listOrgsResponse?.allLoadedOrgs changes, this would otherwise get set multiple times and cause svelte to mark `selected` dirty since it's a complex object.
    if (listOrgsResponse?.allLoadedOrgs == null) return
    if (validator.equals(value, valuePrevious)) return

    // Someone was able to make this select a duplicate value in production when updating step rules `dbo.StepRuleGuestOrgs`.
    // Not sure how, so let's just prevent non-unique values.
    if (multiple) {
      const valueUnique = _.uniq(value)
      value = valueUnique
    }

    setSelected()

    // if not found, we need to reload it--e.g. if impersonation switches and the personaFilters.orgId changes and thus needs to show selected
    if (selected == null) {
      await loadOrgs(0, '', false)
      setSelected()
    }
  }

  function setSelected() {
    valuePrevious = _.cloneDeep(value)
    selectedArray = buildSelected()
    selected = multiple ? selectedArray : selectedArray?.[0]
  }

  function buildSelected() {
    const allLoadedOrgs = listOrgsResponse?.allLoadedOrgs
    return value == null
      ? null
      : allLoadedOrgs == null
        ? null
        : multiple
          ? allLoadedOrgs.filter(o => value.includes(o.orgId))
          : allLoadedOrgs.filter(o => o.orgId === value)
  }

  function onToggle(e) {
    if (autoSelectChildren) {
      const org = e.detail.option
      const isSelected = e.detail.isSelected
      if (org.childrenOrgIds?.length) value = isSelected ? [...value, ...org.childrenOrgIds] : value.filter(v => !org.childrenOrgIds.includes(v))
    }
  }

  async function onChangeProxy() {
    await tick() // so selected gets set first
    onChange(selected)
  }

  export function focus() {
    inputSelect?.focus?.()
  }

  export function open() {
    inputSelect?.open()
  }
</script>

<Form on:submit={saveStaff} autocomplete="off" noSubmitBtn bind:this={formComponent}>
  <div class="modal-body">
    {#if input.userId == null}
      <FormGroup valid={validator.required(input.legalFirstName)} validationMessage="Legal first name is required">
        <label for="first-name">
          Legal first name
          <RequiredMarker />
          <Help>If you don’t know their legal name, that’s okay. Once they get in the system, they can update their own information.</Help>
        </label>
        <InputText bind:value={input.legalFirstName} autocomplete="off" name="first-name" placeholder="Staff member’s first name" />
      </FormGroup>
      <FormGroup valid={validator.required(input.legalLastName)} validationMessage="Legal last name is required">
        <label for="last-name" class="mt1"
          >Legal last name
          <RequiredMarker />
        </label>
        <InputText bind:value={input.legalLastName} autocomplete="off" name="last-name" placeholder="Staff member’s last name" />
      </FormGroup>
      <FormGroup valid={validator.email(input.email)} validationMessage="Email is required">
        <label for="email"
          >Email
          <RequiredMarker />
        </label>
        <InputText name="email" bind:value={input.email} autocomplete="off" placeholder="Staff member’s email" on:input={prepareToLoadStaffByEmail} />
      </FormGroup>
    {/if}
    {#if loadingStaffByEmail}
      <Loading message="Checking for existing staff" />
    {:else if existingStaff}
      <a on:click={syncInputInitial} href={routingConfig.getUrl(existingStaff.user.userName)}>Edit {existingStaff.user.email}</a>
    {:else if isNonExisting}
      {#if input.userId == null}
        <Alert type="info">An invitation will be sent to <strong>{input.email}</strong>.</Alert>
      {/if}
      <h3 class="mb1">User info</h3>
      <FormGroup class="mb1" valid={validator.int(input.orgId)} validationMessage="You must select an organization">
        <label for="orgId"
          >Organization
          <RequiredMarker />
        </label>
        <SimpleOrgPicker
          bind:value={input.orgId}
          disabled={!canManageEntireStaffRecord}
          ancestorOrgId={$persona.org?.rootOrgId}
          requiredPermission={Permission.ManageStaff}
          onLoadOrgs={r => (loadedOrgs = r.orgs)}
        />
      </FormGroup>

      <FormGroup class="mb1" valid={validator.required(input.title)} validationMessage="Title is required">
        <label for="title"
          >Title
          <RequiredMarker />
        </label>
        <InputText name="title" bind:value={input.title} disabled={!canManageEntireStaffRecord && input.staffId} />
      </FormGroup>

      {#if personaService.canUseAnyFeatureType(FeatureType.CustomFieldsOrg)}
        <FormGroup class="mb1">
          <label for="external-id">External ID</label>
          <InputText name="external-id" bind:value={input.externalId} disabled={!canManageEntireStaffRecord && input.staffId} />
        </FormGroup>
      {/if}
      {#if input.userId == null && !existingStaff}
        <FormGroup class="mb1">
          <label for="custom-message">Custom welcome message</label>
          <InputText name="custom-message" bind:value={input.customMessage} placeholder="Welcome to the team!" />
        </FormGroup>
      {/if}

      <CustomFieldsForm bind:values={input.customFieldValues} config={input.customFieldsConfig} canUseFeature={canManageCustomFields} />

      <StaffRoleEditor
        bind:input
        {orgs}
        {service}
        {capacity}
        {teams}
        {accessType}
        {accessTypeIcon}
        {accessTypeName}
        {canManageEntireStaffRecord}
        {inputInitial}
        {isCapacityStaff}
      />
    {/if}
  </div>
</Form>

<script>
  import { createEventDispatcher, tick } from 'svelte'
  import { FeatureType, FilterType, Permission } from 'config/enums.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import api from 'services/api.js'
  import CustomFieldsForm from './CustomFieldsForm.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from './bootstrap/FormGroup.svelte'
  import Help from 'components/Help.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import Loading from 'components/Loading.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import RequiredMarker from './fields/RequiredMarker.svelte'
  import SimpleOrgPicker from 'components/SimpleOrgPicker.svelte'
  import StaffRoleEditor from './StaffRoleEditor.svelte'
  import toaster from 'services/toaster.js'
  import unsavedForms from 'stores/unsaved-forms.js'
  import user from 'stores/user.js'
  import validator from 'services/validator.js'
  import personaFilters from 'stores/persona-filters'

  export let orgStaff
  export let orgs
  export let teams
  export let staff = null
  export let routingConfig
  export let setWarningContext
  // Indicate roles providing access to either the specified service or specified capacity under the service
  export let service = null
  export let capacity = null
  export let accessType = null
  export let accessTypeIcon = null
  export let accessTypeName = null
  export let isCapacityStaff = false
  export let onRemoveCapacityStaff = _.noop
  export let showFooter = false
  export let hasChanges
  export let orgStaffRoles

  const dispatch = createEventDispatcher()
  const loadStaffByEmailDebounced = _.debounce(loadStaffByEmail, 300)
  const form = 'StaffForm'

  let input = {}
  let inputInitial = {}
  let submitted = false
  let loadingStaffByEmail = false
  let staffByEmail = []
  let loadedOrgs = []
  let formComponent

  $: if (orgStaff.user?.userId == null) {initNew()}
  else {initExisting()}

  $: hasChanges = input?.staffId == null || unsavedForms.formHasChanges(form, inputInitial, input)
  $: inputEmail = input?.email
  $: existingStaff = validator.empty(inputEmail) ? null : staff?.find(s => s.user.email === inputEmail) ?? staffByEmail[0] ?? null
  $: isNonExisting = input?.userId != null || validator.email(inputEmail)
  $: canManageEntireStaffRecord =
    personaService.hasStaffPermission(Permission.ManageStaff, $personaFilters.orgId) ||
    personaService.hasStaffPermission(Permission.ManageStaff, loadedOrgs[0]?.orgId)
  $: canManageCustomFields = personaService.canUseAnyFeatureType(FeatureType.CustomFieldsStaff)
  $: showFooter = !loadingStaffByEmail && !existingStaff && isNonExisting
  $: orgStaffRoles = input?.orgStaffRoles
  $: if (input && input.orgId == null && loadedOrgs?.length > 0) {
    input.orgId = loadedOrgs[0].orgId
    input.orgName = getOrgName(loadedOrgs[0].orgId)
  }

  function prepareToLoadStaffByEmail() {
    if (!prepareToLoadStaffByEmailValidate()) return
    // show loading _immediately_ so we don't flicker new-staff form awkwardly
    loadingStaffByEmail = true
    loadStaffByEmailDebounced()
  }

  async function loadStaffByEmail() {
    if (!prepareToLoadStaffByEmailValidate()) return
    loadingStaffByEmail = true
    const body = {
      orgId: $persona.org?.orgId,
      includeParents: true,
      includeEmail: true,
      filters: [
        {
          type: FilterType.Email,
          config: { email: input.email },
        },
      ],
    }

    const result = await api.staff.list(body, api.noMonitor).finally(() => (loadingStaffByEmail = false))
    staffByEmail = result.staff
  }

  async function prepareToLoadStaffByEmailValidate() {
    await tick() // so existingStaff is set
    if (existingStaff != null || !validator.email(input.email)) {
      loadingStaffByEmail = false
      staffByEmail = []
      return false
    }
    return true
  }

  async function initNew() {
    const orgId = $personaFilters.orgId
    const customFieldsConfig = canManageCustomFields ? await api.org.getStaffCustomFieldsConfig({ orgId }, api.noMonitor) : null
    input = {
      legalFirstName: '',
      legalLastName: '',
      email: '',
      userId: null,
      orgId: null,
      staffId: null,
      title: 'Staff',
      externalId: null,
      orgStaffRoles: [],
      customFieldValues: {},
      customFieldsConfig: customFieldsConfig,
    }
    syncInputInitial()
  }

  async function initExisting() {
    const customFieldValues = orgStaff.customFieldValuesJson ? JSON.parse(orgStaff.customFieldValuesJson) : {}
    const customFieldsConfig = orgStaff.org.customFieldsConfigJson ? JSON.parse(orgStaff.org.customFieldsConfigJson) : null

    await tick() // necessary when going directly to an edit staff url
    if (orgStaff) {
      const orgId = orgStaff.org.orgId
      const orgName = getOrgName(orgId)
      input = {
        name: orgStaff.user.name,
        userId: orgStaff.user.userId,
        orgId: orgId,
        staffId: orgStaff.staffId,
        title: orgStaff.title,
        externalId: orgStaff.externalId,
        customFieldValues: customFieldValues,
        customFieldsConfig: customFieldsConfig,
        orgName: orgName,
        orgStaffRoles: _.cloneDeep(orgStaff.orgStaffRoles).map(osr => ({
          key: osr.staffRoleId, // Used as #each key, new ones are negative.
          staffRoleId: osr.staffRoleId,
          isAutoAssigned: osr.isAutoAssigned,
          staffRole: osr.staffRole,
          orgIds: osr.orgs.map(o => o.orgId),
          teamIds: osr.teams.map(t => t.teamId),
          serviceIds: osr.services.map(s => s.serviceId),
          capacityIds: osr.capacities.map(c => c.capacityId),
          disciplines: osr.disciplines.map(d => d.label),
          customTagIds: osr.customTags.map(ct => ct.customTagId),
          studentUserIds: osr.students.map(s => s.userId),
        })),
      }
      syncInputInitial()
    }
  }

  function isCurrentUser(userId) {
    return $user?.userId === userId
  }

  async function syncInputInitial() {
    await tick()
    inputInitial = _.cloneDeep(input)
    unsavedForms.del(form)
  }

  function getOrgName(orgId) {
    return orgs?.find(o => o.orgId === orgId)?.name ?? ''
  }

  export function onSubmit() {
    formComponent?.onSubmit()
  }

  async function saveStaff() {
    if (isCapacityStaff && validator.equals(inputInitial, input)) {
      // only titles changed
      dispatch('reloadStaff', { updateOnlyTitle: true })
      routingConfig.close()
      return
    }
    submitted = true
    const body =
      canManageCustomFields && input.customFieldValues
        ? _.cloneDeep({ ...input, customFieldValuesJson: JSON.stringify(input.customFieldValues) })
        : _.cloneDeep(input)
    // Save network bandwidth deleting these JSON objects; we only need to send `customFieldValuesJson`
    delete body.customFieldsConfig
    delete body.customFieldValues

    const isNew = body.userId == null || body.userId == 'new'
    if (!isNew) {
      delete body.legalFirstName
      delete body.legalLastName
    }
    for (const role of body.orgStaffRoles) {
      delete role.key
      if (!role.orgIds?.length) delete role.orgIds
      if (!role.teamIds?.length) delete role.teamIds
      if (!role.serviceIds?.length) delete role.serviceIds
      if (!role.capacityIds?.length) delete role.capacityIds
      if (!role.disciplines?.length) delete role.disciplines
      if (!role.customTagIds?.length) delete role.customTagIds
      if (!role.studentUserIds?.length) delete role.studentUserIds
    }
    delete body.customFieldsConfig
    delete body.customFieldValues
    let warnings
    if (isNew) {
      const response = await api.org.addStaff({ orgId: body.orgId }, body)
      warnings = response.warnings
    } else {
      warnings = await api.org.updateStaff({ orgId: body.orgId, userId: body.userId }, body)
    }

    dispatch('reloadStaff')
    await syncInputInitial()

    if (warnings.length) {
      setWarningContext({
        name,
        warnings,
      })
    } else {
      toaster.toast({ message: `Staff ${isNew ? 'added' : 'updated'} successfully`, type: 'success', icon: 'staff-settings' })
      routingConfig.close()
    }

    if (isCurrentUser(body.userId)) user.load()
  }
  export async function deleteStaff() {
    if (isCapacityStaff) {
      onRemoveCapacityStaff(input.userId)
    } else {
      await api.org.removeStaff({ orgId: input.orgId, staffId: input.staffId, userId: input.userId })
      dispatch('reloadStaff')
      toaster.toast({ message: 'Staff removed', type: 'success', icon: 'check' })
    }
    await syncInputInitial()
    routingConfig.close()
  }
</script>

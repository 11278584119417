<div class="flex-row flex-align-center g05">
  <Icon name={iconName} class={iconClass} />
  <span>{formatEnumValue(MatchRole, role)}</span>
</div>

<script>
  import { MatchRole } from 'config/enums.js'
  import { formatEnumValue } from 'services/formatters.js'
  import Icon from 'components/Icon.svelte'

  export let role

  let iconName
  let iconClass

  $: if ([MatchRole.ClinicCoordinator, MatchRole.Preceptor, MatchRole.HostAdmin, MatchRole.HostViewer].includes(role)) {
    iconName = 'hospital-user'
    iconClass = 'color-text-orange'
  } else if ([MatchRole.SchoolCoordinator, MatchRole.SchoolFaculty, MatchRole.GuestAdmin, MatchRole.GuestViewer].includes(role)) {
    iconName = 'user-tie'
    iconClass = 'color-text-blue'
  } else if ([MatchRole.Student].includes(role)) {
    iconName = 'users'
    iconClass = 'color-text-teal'
  }
</script>

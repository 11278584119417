{#if staffMembers?.length}
  <div class="flex-row flex-align-center g1">
    <Icon fw name={iconName} class={iconClass} />
    <div>
      {#if all}All{/if}
      <span class="has-tooltip" use:tip={{ content: tooltipElem, options: { theme: 'light-gray-scrollable', interactive: true } }}>
        {#if all}
          selected users
        {:else}
          {pluralCount('selected user', staffMembers)}
        {/if}
      </span>
      {doesDoesntOrEmpty}
      {hasOrHave} permission to view this opportunity.
    </div>
  </div>

  <div class="hide">
    <div bind:this={tooltipElem} class="flex-column g05">
      {#each staffMembers as staff}
        <div class="flex-row flex-align-center g1">
          <div><ProfilePic name={staff.user.name} src={staff.user.profilePicture} small /></div>
          <div class="flex-column">
            <span>{staff.user.name}</span>
          </div>
        </div>
      {/each}
    </div>
  </div>
{/if}

<script>
  import { pluralCount } from 'services/string-utils.js'
  import Icon from 'components/Icon.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import tip from 'decorators/tip'

  export let staffMembers
  export let all
  export let isStaffDenied = false

  let tooltipElem

  $: iconName = isStaffDenied ? 'hide' : 'eye'
  $: iconClass = isStaffDenied ? 'text-danger' : 'text-success'
  $: count = all ? Infinity : staffMembers.length
  $: hasOrHave = count === 1 && isStaffDenied ? 'has' : 'have'
  $: doesDoesntOrEmpty = isStaffDenied ? (count === 1 ? 'doesn’t' : 'don’t') : ''
</script>

<h4 class="m0 normal">Placeholder students</h4>
{#if multipleDaysSelected}
  <InputRadioGroup
    name="update-multiple-placeholder-students-{shift.name}"
    let:option
    bind:value={multipleDaysValue.updatePlaceholderStudentsForSelectedDates}
    options={[
      { value: false, label: 'Leave the current amounts of placeholder students on all selected days' },
      { value: true, label: 'Set a new amount of placeholder students on all selected days' },
    ]}
    inline
    class="mb05 pr1"
  >
    <div class="flex-row flex-align-center g05">
      {option.label}
      {#if option.value}
        <DaysConfigTooltip {matchDayConfigs} {shift} {selectedDates} placeholderStudentCount>
          <Icon name="alert-triangle" class="text-warning" />
        </DaysConfigTooltip>
      {/if}
    </div>
  </InputRadioGroup>
  {#if multipleDaysValue.updatePlaceholderStudentsForSelectedDates}
    <PlaceholderStudentInput {match} bind:value={multipleDaysValue.placeholderStudentCount} />
  {/if}
{:else}
  <PlaceholderStudentInput {match} bind:value={value.placeholderStudentCount} />
{/if}

<script>
  import InputRadioGroup from './InputRadioGroup.svelte'
  import PlaceholderStudentInput from './SchedulePicker.DaysConfig.PlaceholderStudentInput.svelte'
  import DaysConfigTooltip from './SchedulePicker.DaysConfig.Tooltip.svelte'
  import Icon from 'components/Icon.svelte'

  export let multipleDaysSelected
  export let value
  export let multipleDaysValue
  export let match
  export let matchDayConfigs
  export let shift
  export let selectedDates
</script>

<div class={className}>
  <BtnGroupPicker {options} bind:value {name} btnClass={sm ? 'btn-sm' : ''} btnActiveClass="btn-primary" />
</div>

<script>
  import { ToManyComparison } from 'config/enums.js'
  import BtnGroupPicker from 'components/bootstrap/BtnGroupPicker.svelte'

  export let value = ToManyComparison.AnyOf
  export let name = 'many-to-many'
  export let sm = false
  let className = 'flex-row g1'
  export { className as class }

  const buildOption = (value, label) => ({
    value,
    label,
    dataTest: `${name}-${label}`,
  })

  let options = []
  $: name,
    (options = [buildOption(ToManyComparison.AnyOf, 'any'), buildOption(ToManyComparison.AllOf, 'all'), buildOption(ToManyComparison.NoneOf, 'none')])
</script>

cleanDeprecatedKeys()

// just wrapping local storage in case we decide to use something else later
class StorageService {
  setItem(key, value) {
    // eslint-disable-next-line no-console
    if (key == null) console.error('setting null key', value)
    localStorage.setItem(key, JSON.stringify(value))
  }

  getItem(key) {
    if (key == null) return null
    try {
      const item = localStorage.getItem(key)
      return item == null ? null : JSON.parse(item)
    } catch {
      return null
    }
  }
}

export default new StorageService()

// if we start seeing QuotaExceededError, we could catch that error and clear out any keys not on an allowlist
// if our allowlist is accurate enough, we could also just include it in this function which gets called when the user loads the app
function cleanDeprecatedKeys() {
  setTimeout(() => {
    const deprecated = [/(^last-saved-criteria-)|(join-data)|(last-persona-slug)|(persona-filters)/i]
    const keys = Object.getOwnPropertyNames(localStorage)
    for (const key of keys) {
      const isDeprecated = deprecated.some(p => p.test(key))
      if (isDeprecated) {
        localStorage.removeItem(key)
        // eslint-disable-next-line no-console
        console.log(`Removed deprecated ${key}`)
      }
    }
  }, 3000) // wait a while since this is low-priority task
}

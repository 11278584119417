{#each visibleFields as field, i (field.name)}
  {#if viewOnly}
    <DynamicFormFieldView {field} value={values[field.name]} {compact} />
  {:else}
    <DynamicFormField {disabled} {allowSubmitIfInvalid} {field} bind:value={values[field.name]} autofocus={autoFocusFirstField && i === 0} />
  {/if}
{/each}

<script>
  import DynamicFormField from 'components/DynamicFormField.svelte'
  import DynamicFormFieldView from 'components/DynamicFormField.View.svelte'
  import { getVisibleFields } from 'services/dynamic-form-helpers.js'

  export let config = null
  export let values = null
  export let autoFocusFirstField = false
  export let allowSubmitIfInvalid = false // for form builder preview fields
  export let disabled = false
  export let viewOnly = false
  export let compact = false

  $: visibleFields = getVisibleFields(config, values)
</script>

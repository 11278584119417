import { Permission, PersonaType, StaffRole, SubscriptionStatus, FeatureType } from 'config/enums.js'
import personaStore, { defaultPersona } from 'stores/persona.js'
import selectedPersonaValues, { updateSelectedPersonaValues } from 'stores/selected-persona-values.js'

import confirms from 'stores/confirms.js'
import initial from 'stores/initial.js'
import personaFilters from 'stores/persona-filters.js'
import rolePermissions from 'config/role-permissions.js'

let $selectedPersonaValues
selectedPersonaValues.subscribe(v => ($selectedPersonaValues = v))

let $persona
personaStore.subscribe(p => ($persona = p))

let $personaFilters
personaFilters.subscribe(filters => ($personaFilters = filters))

let $initial = {}
initial.subscribe(v => ($initial = v))

const trialOrActive = new Set([SubscriptionStatus.Trial, SubscriptionStatus.Active])
const capacityManagerRoles = new Set([StaffRole.CapacityManager, StaffRole.Coordinator, StaffRole.Preceptor])

class PersonaService {
  constructor() {
    // set persona to first one, so audit headers get hydrated for all api requests
    if ($initial.prefetch?.profile?.personas?.length) {
      const profile = $initial.prefetch.profile
      this.setUser(profile)
    }
  }

  setUser(user, newPersonaValuesForUser) {
    this.personas = user?.personas
    this.userId = user?.userId
    this.setPersona(newPersonaValuesForUser)
  }

  setPersona(newPersonaValuesForUser) {
    const { rememberedValues, persona } = this.getRememberedValues(newPersonaValuesForUser)
    personaStore.set(persona)
    personaFilters.set({
      orgId: rememberedValues.orgId,
      teamId: rememberedValues.teamId,
    })
  }

  getRememberedValues(newPersonaValuesForUser) {
    if (newPersonaValuesForUser != null) $selectedPersonaValues = updateSelectedPersonaValues(this.userId, newPersonaValuesForUser)

    let res = {
      rememberedValues: $selectedPersonaValues[this.userId],
      persona: null,
    }

    // ensure remembered values are set with valid values
    const rememberedPersona = res.rememberedValues ? this.personas?.find(p => p.slug === res.rememberedValues.slug) : null
    if (rememberedPersona == null) {
      // remembered values not set yet or were set to an invalid slug or orgid
      // so let's set to the first valid persona
      res = this.setDefaultPersonaAndRemember(res.persona, res.rememberedValues)
    } else {
      res.persona = rememberedPersona
    }

    this.hydratePersonaHierarchy(res.persona)
    this.hydratePersonaIcons(res.persona)

    // if invalid orgid in flatOrgIds, reset the remembered values to default one again--extract a func
    if (res.persona.flatOrgIds != null && !res.persona.flatOrgIds.includes(res.rememberedValues.orgId)) {
      res = this.setDefaultPersonaAndRemember(res.persona, res.rememberedValues)
      this.hydratePersonaHierarchy(res.persona)
    } // ideally we'd also check teamid, but that's not in the persona data currently, so we'll just skip it for now

    return res
  }

  setDefaultPersonaAndRemember(persona, rememberedValues) {
    persona = this.personas?.[0] ?? defaultPersona
    $selectedPersonaValues = updateSelectedPersonaValues(this.userId, {
      slug: persona.slug,
      orgId: persona.orgId,
      teamId: persona.teamId,
    })
    rememberedValues = $selectedPersonaValues[this.userId]
    return { persona, rememberedValues }
  }

  hydratePersonaHierarchy(persona) {
    if (persona.orgChildrenIds != null) {
      // give persona flat list of orgIds it covers
      persona.flatOrgIds = this.getOrgDescendantIds([persona.orgId], persona.orgChildrenIds)

      // for each role on persona, give it a flat list of orgIds it applies to
      for (const osr of persona.orgStaffRoles) {
        osr.flatOrgIds = osr.orgIds?.length ? this.getOrgDescendantIds(osr.orgIds, persona.orgChildrenIds) : persona.flatOrgIds
      }
    }
  }

  hydratePersonaIcons(persona) {
    if (persona.personaType === PersonaType.SchoolStaff) {
      persona.orgIcon = 'school'
      persona.orgIconClass = 'color-text-blue'
      persona.orgFeatureType = FeatureType.TeachingInstitution
      persona.otherOrgIcon = 'hospital'
      persona.otherOrgIconClass = 'color-text-orange'
      persona.otherOrgFeatureType = FeatureType.HealthInstitution
    } else {
      persona.orgIcon = 'hospital'
      persona.orgIconClass = 'color-text-orange'
      persona.orgFeatureType = FeatureType.HealthInstitution
      persona.otherOrgIcon = 'school'
      persona.otherOrgIconClass = 'color-text-blue'
      persona.otherOrgFeatureType = FeatureType.TeachingInstitution
    }
  }

  canUseAnyFeatureType(...featureTypes) {
    if ($persona == null) return false
    if ($persona.isStudent) return true
    if (!trialOrActive.has($persona.subscriptionStatus)) return false
    return featureTypes.some(ft => $persona.subscriptionFeatureTypes.includes(ft))
  }

  hasStaffPermission(permission, orgId, teamId, details = {}) {
    return (
      // persona includes the org asked for
      $persona.flatOrgIds?.includes($persona.orgId) === true &&
      // and has a role that fits
      $persona.orgStaffRoles?.some(osr => this.roleHasStaffPermission(osr, permission, orgId, teamId, details)) === true
    )
  }

  roleHasStaffPermission(osr, permission, orgId, teamId, details = {}) {
    const { discipline, serviceId, capacityId } = details
    const result =
      // orgstaffrole has the permission asked for
      rolePermissions[osr.staffRole].includes(permission) &&
      // for all orgs, or the org asked for, or an ancestor of it
      osr.flatOrgIds.includes(orgId) &&
      // for all teams, or role includes the team asked for, or teamId is null (All teams) and this check allows it
      (osr.teamIds.length === 0 || (teamId > 0 && osr.teamIds.includes(teamId)) || (teamId == null && details.anyTeam)) &&
      // not asking for a discipline
      (discipline == null ||
        // or role is for all disciplines, or includes the discipline asked for
        osr.disciplines.length === 0 ||
        osr.disciplines.includes(discipline)) &&
      // not asking for a service or capacity
      ((serviceId == null && capacityId == null) ||
        // or role is for all services and capacities
        (osr.serviceIds.length === 0 && osr.capacityIds.length === 0) ||
        // or includes the serviceid or capacityid asked for
        osr.serviceIds.includes(serviceId) ||
        osr.capacityIds.includes(capacityId))

    return result
  }

  getRolesWithPermission(permission, orgId, teamId, details = {}) {
    if ($persona.flatOrgIds?.includes($persona.orgId) !== true) return []
    return $persona.orgStaffRoles?.filter(osr => this.roleHasStaffPermission(osr, permission, orgId, teamId, details)) ?? []
  }

  hasPermission(permission) {
    return $persona.permissions?.includes(permission) ?? false
  }

  hasPermissionAnywhere(permission) {
    return this.hasPermission(permission) || $persona.orgStaffRoles?.some(osr => rolePermissions[osr.staffRole].includes(permission))
  }

  hasAnyPermissionAnywhere(...permissions) {
    return permissions.some(p => this.hasPermissionAnywhere(p))
  }

  ensurePersonaFiltersIfHasPermission(permission, viewingWhat, orgId, teamId) {
    const isStudentAtOrg = $persona.personaType === PersonaType.Student && (orgId == null || $persona.orgId === orgId)
    const hasOrgPermission = !orgId || isStudentAtOrg || this.hasStaffPermission(permission, orgId)
    const hasTeamPermission = !teamId || isStudentAtOrg || this.hasStaffPermission(permission, orgId ?? $personaFilters.orgId, teamId)
    let needsUpdate = false
    if (hasOrgPermission && $personaFilters.orgId != orgId) {
      $personaFilters.orgId = orgId
      needsUpdate = true
    }
    if (hasTeamPermission && $personaFilters.teamId != teamId) {
      $personaFilters.teamId = teamId
      needsUpdate = true
    }

    if (needsUpdate) {
      personaFilters.set($personaFilters)
    }

    if (!hasOrgPermission || !hasTeamPermission) {
      const message = `You don’t have permission to view the ${viewingWhat} associated with this URL’s ${
        hasOrgPermission ? '' : `organization${hasTeamPermission ? '' : ' and '}`
      }${hasTeamPermission ? '' : 'team'}; you may not be seeing what the person who sent you this link wanted you to see.`
      confirms.add({
        title: 'Insufficient permission',
        message,
        color: 'warning',
        confirmLabel: 'Got it',
        confirmClass: 'btn-warning',
        cancelLabel: null,
      })
    }
  }

  // osOrgId := OrgStaff.OrgID
  // osr := OrgStaffRole
  canManageRole(osOrgId, osr) {
    // Currently, in order to manage a role, you must have access to everything the role specifies; this is a bit counterintuitive but will take some work to fix.
    // For example, if I'm Admin of Child A and Child B, and I'm managing a user whose role has access to Child B and Child C, I _should_ be able to add Child A
    // or remove Child B from their role. However, the backend doesn't allow that yet, so the frontend won't either. Another condition to be aware of is manageability
    // is determined by a single role. We _could_ determine it by considering all Admin + Service manager roles, but that would quite complicated.

    const orgIds = osr.orgIds?.length ? osr.orgIds : [osOrgId]
    for (const role of $persona.orgStaffRoles) {
      const permissions = rolePermissions[role.staffRole]
      const manageStaff = permissions.includes(Permission.ManageStaff)
      const manageServices = permissions.includes(Permission.ManageOpportunitiesAndServices)
      if (!manageStaff && !manageServices) continue
      if (!manageStaff && !capacityManagerRoles.has(osr.staffRole)) continue
      if (orgIds.some(orgId => !role.flatOrgIds.includes(orgId))) continue
      if (role.teamIds.length) {
        if (!osr.teamIds.length) continue
        if (osr.teamIds.some(teamId => !role.teamIds.includes(teamId))) continue
      }
      if (manageStaff) return true // Admins can only be limited by orgs + teams
      // Otherwise we're looking at a Service manager role and need to consider services, capacities, & disciplines
      if (role.serviceIds.length) {
        if (!osr.serviceIds.length) continue
        if (osr.serviceIds.some(serviceId => !role.serviceIds.includes(serviceId))) continue
      }
      if (role.disciplines.length) {
        if (!osr.disciplines.length) continue
        if (osr.disciplines.some(discipline => !role.disciplines.includes(discipline))) continue
      }
      return true
    }
    return false
  }

  /*
    given:
    {
      parentOrgId: [ childOrgId, childOrgId, ...]
    }

    return an array of ancestors for the org passed
    [ parentId, grandparentId, ... ]
  */
  getOrgAncestorIds(orgId, result = []) {
    result.push(orgId)

    if ($persona.orgChildrenIds == null || $persona.orgId == null) return result

    const parentOrgId = Object.keys($persona.orgChildrenIds).find(pid => $persona.orgChildrenIds[pid].includes(orgId))
    return parentOrgId == null ? result : this.getOrgAncestorIds(Number.parseInt(parentOrgId, 10), result)
  }

  isOrgSameOrDescendant(orgId, descendantOrgId) {
    if (orgId === descendantOrgId) return true

    // TODO(teams) cache descendant orgids lookup so we don't need to recurse for every check
    return this.getOrgDescendantIds([orgId], $persona.orgChildrenIds).includes(descendantOrgId)
  }

  getOrgDescendantIds(parentIds, orgChildrenIds, result = []) {
    // get childrenids of all rootorgids
    result = [...result, ...parentIds]

    const descendantIds = Object.keys(orgChildrenIds)
      // get all parent orgids that are in the list of parentOrgIds
      .filter(parentOrgId => parentIds.includes(Number.parseInt(parentOrgId, 10)))
      // turn them into 1 array
      .flatMap(parentOrgId => orgChildrenIds[parentOrgId])

    return descendantIds.length > 0 ? this.getOrgDescendantIds(descendantIds, orgChildrenIds, result) : result
  }

  getOrgsWithPermission(orgs, requiredPermission = null) {
    return requiredPermission == null ? orgs : orgs.filter(o => this.hasStaffPermission(requiredPermission, o.orgId, -1))
  }

  getTeamsWithPermissionAtOrg(orgId, teams, requiredPermission = null) {
    return requiredPermission == null
      ? teams
      : teams.filter(
          t =>
            // teams only exist at their orgid or its descendants
            this.isOrgSameOrDescendant(t.orgId, orgId) &&
            // person must have permission at that org + team combo
            this.hasStaffPermission(requiredPermission, orgId, t.teamId)
        )
  }

  hasPermissionForAllTeams(orgId, requiredPermission) {
    return this.hasStaffPermission(requiredPermission, orgId, null)
  }

  // when adding a new org, we want to make sure we include the new orgIDs for permission checks
  mergeOrgIntoPermissions(org) {
    if ($persona.orgChildrenIds != null) {
      if ($persona.orgChildrenIds.hasOwnProperty(org.parentOrgId)) {
        if (!$persona.orgChildrenIds[org.parentOrgId].includes(org.orgId))
          $persona.orgChildrenIds[org.parentOrgId] = [...$persona.orgChildrenIds[org.parentOrgId], org.orgId]
      } else {
        $persona.orgChildrenIds[org.parentOrgId] = [org.orgId]
      }
    }
    this.hydratePersonaHierarchy($persona)
  }

  canEditAgreement(agreement) {
    return (
      agreement == null ||
      this.hasStaffPermission(Permission.ManageAgreements, agreement.orgId, agreement.teamId) ||
      this.hasStaffPermission(Permission.ManageAgreements, agreement.schoolId, agreement.schoolTeamId)
    )
  }

  canEditCapacity(capacity) {
    return (
      capacity?.capacityId == null ||
      this.hasStaffPermission(Permission.ManageOpportunitiesAndServices, capacity.orgId, capacity.teamId, {
        capacityId: capacity.capacityId,
        serviceId: capacity.serviceId,
      })
    )
  }

  canViewCapacityAsHost(capacity) {
    return (
      capacity &&
      this.hasStaffPermission(Permission.ViewOpportunitiesAndServices, capacity.orgId, capacity.teamId, {
        anyTeam: true,
        capacityId: capacity.capacityId,
        serviceId: capacity.serviceId,
      })
    )
  }

  canEditService(service) {
    return (
      service?.serviceId == null ||
      this.hasStaffPermission(Permission.ManageOpportunitiesAndServices, service.orgId, service.teamId, {
        serviceId: service.serviceId,
      })
    )
  }

  canEditTeam(team, orgId, anyTeam) {
    return this.hasStaffPermission(Permission.ManageTeams, orgId, team.teamId, {
      anyTeam,
    })
  }

  canEditStep(step) {
    return step != null && this.hasStaffPermission(Permission.ManageSteps, step.orgId, step.teamId)
  }
}

export default new PersonaService()

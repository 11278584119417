import { CriteriaFilterType, DashboardViewCategory, FeatureType, FilterType, MatchStatus, Permission } from 'config/enums.js'

import dayjs from 'dayjs'

const excludedFilterTypes = [
  FilterType.KeywordSearch,
  FilterType.MatchEndDate,
  FilterType.MatchHasIncompleteSteps,
  FilterType.MatchHasProposedChanges,
  FilterType.Steps,
  FilterType.MatchHasStepsICanComplete,
  FilterType.MatchHasStepsICanVerify,
  FilterType.MatchStartDate,
  FilterType.MatchStatusCategory,
  FilterType.MatchStatuses,
  FilterType.StepSubmissionTargetUser,
]
// TODO: filterTypes and ignoredFilterTypes should use excludedFilterTypes but there is a conflict with MatchStatusCategory

export default {
  category: DashboardViewCategory.Reports,
  permission: Permission.ViewRotationStatusHistoryReport,
  featureTypes: [FeatureType.ReportRotationHistory],
  componentName: 'RotationStatusHistoryReport',
  name: 'Rotation status history',
  icon: 'chart-bar',
  criteria: {
    zoom: 'd',
    chartView: 'stacked',
    // default to 14 day range
    startDate: dayjs().subtract(14, 'days').format('M/D/YYYY'),
    endDate: dayjs().format('M/D/YYYY'),
    filters: [],
    statuses: Object.keys(MatchStatus).filter(k => MatchStatus[k] !== MatchStatus.Unsubmitted),
  },
  excludedFilterTypes,
  filtersConfig: [
    {
      prefix: '',
      name: 'filters',
      type: CriteriaFilterType.MatchFilter,
    },
  ],
  criteriaConfig: {
    startDate: { name: 'sd', method: 'readDate' },
    endDate: { name: 'ed', method: 'readDate' },
    zoom: { name: 'z' },
    chartView: { name: 'cv' },
    statuses: { name: 's', method: 'readArray' },
  },
  waitFor: { method: 'POST', route: /api\/reports\/rotation-status-history/i },
  // testClick: 'run-report-btn',
  testFor: ['chart'],
}

{#if inline}
  <TagCloudContent {...contentProps} />
{:else if disciplines.length || specialties.length || rotationTypes.length || orgTags.length}
  <div class={className}>
    <TagCloudContent {...contentProps} />
  </div>
{/if}

<script>
  import TagCloudContent from 'components/TagCloud.Content.svelte'

  export let ctx = {}
  export let inline = false
  let className = 'flex-row flex-align-center flex-wrap gc2r05'
  export { className as class }
  export let hideTooltip = false

  $: rotationTypes = ctx?.rotationTypes ?? []
  $: specialties = ctx?.specialties ?? []
  $: disciplines = ctx?.disciplines ?? []
  $: orgTags = ctx?.orgTags ?? []

  $: contentProps = {
    rotationTypes,
    specialties,
    disciplines,
    orgTags,
    hideTooltip,
  }
</script>

<ul class="cn-tabs{sm ? ' tabs-small' : ''}{className ? ` ${className}` : ''}">
  <slot />
</ul>

<script context="module">
  export const key = {}
</script>

<script>
  import { setContext } from 'svelte'
  import { writable } from 'svelte/store'

  export { className as class }
  export let active = null
  export let sm = false

  const activeStore = writable(active)
  let className = ''

  const tabsStore = writable([])

  setContext(key, {
    active: activeStore,
    tabs: tabsStore,
  })

  $: $activeStore, setActiveTabFromChildTab()
  $: active, setActiveTabFromParentComponent()

  function setActiveTabFromParentComponent() {
    $activeStore = active
    updateWindowLocationByTabName(active)
  }

  function setActiveTabFromChildTab() {
    active = $activeStore
  }

  export function back() {
    const selectedIndex = getSelectedIndex()
    if (selectedIndex <= 0) return
    selectTabByIndex(selectedIndex - 1)
  }

  export function next() {
    const selectedIndex = getSelectedIndex()
    if (selectedIndex >= $tabsStore?.length) return
    selectTabByIndex(selectedIndex + 1)
  }

  function updateWindowLocationByTabName(name) {
    for (const tab of $tabsStore) {
      if (tab.name === name) {
        tab.onSelectProgrammatically()
        return
      }
    }
  }

  function selectTabByIndex(index) {
    const tab = $tabsStore[index]
    active = tab?.name
    tab?.onSelectProgrammatically()
  }

  function getSelectedIndex() {
    return $tabsStore.findIndex(t => t.name === active)
  }
</script>

<div class="flex-column g05 card rounded-lg m0 p1">
  <ShiftDay {capacity} {shiftDay} {shift}>
    <slot name="actions" slot="actions" />
  </ShiftDay>
</div>

<script>
  import ShiftDay from 'components/ShiftDay.svelte'

  export let capacity
  export let shiftDay
  export let shift
</script>

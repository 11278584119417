{#if showYear || showDiscipline}
  <div class="flex-row flex-align-center g05">
    {#if showYear}
      <Icon name="calendars" class="color-text-teal" dataTest="calendar-icon" />
    {/if}

    {#if showDiscipline}
      <Icon name="book" class="color-text-teal" dataTest="book-icon" />
    {/if}

    <slot />

    <span data-test="student-year-discipline">
      {#if showYear && student.studentYear}
        {toStudentYear(student.studentYear)}
      {/if}

      {#if showDiscipline}
        {student.discipline || student.otherDiscipline || (showYear && student.studentYear ? 'student' : 'Student')}
      {/if}
    </span>
  </div>
{/if}

<script>
  import { toStudentYear } from 'services/formatters.js'
  import Icon from 'components/Icon.svelte'

  export let student
  export let showYear = true
  export let showDiscipline = true
</script>

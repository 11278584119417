export default function onMayInteract(node, fn) {
  let callback = fn
  let mouseIn = false
  let focusIn = false

  function callFn() {
    callback({ mouseIn, focusIn })
  }

  function onMouseEnter() {
    mouseIn = true
    callFn()
  }
  function onMouseLeave() {
    mouseIn = false
    callFn()
  }
  function onFocusIn() {
    focusIn = true
    callFn()
  }
  function onFocusOut() {
    focusIn = false
    callFn()
  }

  node.addEventListener('mouseenter', onMouseEnter)
  node.addEventListener('mouseleave', onMouseLeave)
  node.addEventListener('focusin', onFocusIn)
  node.addEventListener('focusout', onFocusOut)

  function update(fn) {
    callback = fn
  }

  function destroy() {
    node.removeEventListener('mouseenter', onMouseEnter)
    node.removeEventListener('mouseleave', onMouseLeave)
    node.removeEventListener('focusin', onFocusIn)
    node.removeEventListener('focusout', onFocusOut)
  }

  return {
    destroy,
    update,
  }
}

<FormGroup>
  <label for="add-staff-user" class="normal">
    Who would you like to add to the {accessType}
    <Icon name={accessTypeIcon} class="color-text-purple" /> <strong>{accessTypeName}</strong>?
  </label>
  <InputRadioGroup
    bind:value={searchAllUsers}
    options={[
      { value: false, label: 'Someone in my organization' },
      { value: true, label: 'Someone who has a Clinician Nexus account' },
    ]}
  />

  <AutoComplete
    bind:this={autoCompleteComponent}
    inputId="add-staff-user"
    distanceToLoadPage={20}
    {placeholder}
    loadPage={searchStaffToAddDebounced}
    {loading}
    {options}
    {totalCount}
    bind:value
    let:option={staff}
    showNoResults={totalCount === 0 && lastSearch.trim()}
  >
    <div class="flex-row flex-align-center g1">
      <ProfilePic src={staff.user.profilePicture} name={staff.user.name} initials={staff.user.initials} id={staff.userId} />
      <div>
        <div>
          <strong>{staff.user.name}</strong>
          {#if staff.user.hasAccess}
            <em class="small">(already has access)</em>
          {/if}
        </div>
        {#if staff.user.email}
          <p class="m0">{staff.user.email}</p>
        {/if}
      </div>
    </div>

    <div slot="no-results" class="p2">
      No users were found matching <em>{lastSearch}</em>.
    </div>
  </AutoComplete>
</FormGroup>

<script>
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import InputRadioGroup from 'components/fields/InputRadioGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import AutoComplete from 'components/fields/AutoComplete.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import api from 'services/api.js'

  export let accessType
  export let accessTypeIcon
  export let accessTypeName
  export let serviceId
  export let capacityId = null

  export let value = null
  export let searchAllUsers = false

  let autoCompleteComponent
  let lastSearch = ''

  // Intentionally start totalCount as null so the showNoResults content is hidden when loading the first page.
  const buildCacheEntry = () => ({ options: [], totalCount: null, loading: false })

  const orgUsersSearchCache = {}
  const allUsersSearchCache = {}
  $: cacheBySearch = searchAllUsers ? allUsersSearchCache : orgUsersSearchCache
  $: currentCacheEntry = cacheBySearch[lastSearch] ?? buildCacheEntry()
  $: options = currentCacheEntry.options
  $: totalCount = currentCacheEntry.totalCount
  $: loading = currentCacheEntry.loading

  $: placeholder = searchAllUsers ? 'Search users by name' : 'Search users by name or email'

  $: searchAllUsers, handleSearchAllUsersChanged()

  function handleSearchAllUsersChanged() {
    if (totalCount == null && lastSearch.trim()) searchStaffToAddDebounced(0, lastSearch)
  }

  const searchStaffToAdd = async (offset, search) => {
    lastSearch = search
    const cache = searchAllUsers ? allUsersSearchCache : orgUsersSearchCache
    let cacheEntry = cache[search]
    if (!cacheEntry) {
      cacheEntry = buildCacheEntry()
      cache[search] = cacheEntry
    } else if (cacheEntry.loading || cacheEntry.options.length > offset) {
      return
    }

    try {
      cacheEntry.loading = true
      updateUi(cache, search)
      const response = await api.staff.searchToAdd({ serviceId, capacityId, offset, search, searchAllUsers }, api.noMonitor)
      cacheEntry.totalCount = response.totalCount
      cacheEntry.options.push(...response.staff)
    } finally {
      cacheEntry.loading = false
      updateUi(cache, search)
    }
  }

  function updateUi(cache, search) {
    if (cache === orgUsersSearchCache) {
      orgUsersSearchCache[search] = orgUsersSearchCache[search]
    } else {
      allUsersSearchCache[search] = allUsersSearchCache[search]
    }
  }

  const searchStaffToAddDebounced = _.debounce(searchStaffToAdd, 300)
</script>

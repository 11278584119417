{#if editing}
  <CloseReasonsPicker bind:value={filter.reasons} multiple includeSystem autofocusFirstItem />
{:else}
  {meta.label} is{#if filter && selected.length}{' '}
    <!-- Yay, space hacks -->
    <FriendlyList or punctuation boldPunctuation={false} toggleable={false} items={selected} let:item>
      <strong>{formatEnumKey(item.optionLabel)}</strong>
    </FriendlyList>
  {:else}…?{/if}
{/if}

<script context="module">
  import CloseReasonsPicker from 'components/fields/CloseReasonsPicker.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    type: FilterType.MatchCloseReason,
    label: 'Closed reason',
    icon: 'Closed',
    iconClass: 'color-text-dark-red',

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.reasons)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.reasons = reader.readIntArray()
      return config
    },

    create() {
      return {
        reasons: [],
      }
    },

    validate(filter) {
      return filter.reasons?.length > 0
    },
  }
</script>

<script>
  import FriendlyList from 'components/FriendlyList.svelte'
  import { formatEnumKey } from 'services/formatters.js'
  import { CloseReason } from 'config/enums.js'
  import enumToOptions from 'services/enum-to-options.js'

  export let filter
  export let editing = false

  const closeReasons = enumToOptions(CloseReason)

  $: selected = closeReasons.filter(r => filter.reasons.includes(r.optionValue))
</script>

<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} usingCustomPicker labelSelector={o => o.name} let:item>
  <div slot="picker" class="mt1 mb1">
    <ServicePicker bind:value={filter[appliedMeta.filterProp]} multiple or={customPickerShouldUseOr(appliedMeta, filter)} />
  </div>

  <svelte:fragment slot="selectedItem">
    {#if item?.serviceId}
      <OrgProfilePictureAndName
        slot="selectedItem"
        name={item.orgName}
        profilePicture={item.orgProfilePicture}
        relativeName={null}
        nameClass="small"
        contentClass="text-left"
      >
        <div class={item.serviceId === filter.simulatedValue ? 'strong text-success' : 'strong'}>{item.name}</div>
      </OrgProfilePictureAndName>
    {:else if !appliedMeta.toMany}
      <em>unspecified</em>
    {/if}
  </svelte:fragment>
</FilterTypeListFilter>

<script context="module">
  import { FilterType } from 'config/enums.js'
  import api from 'services/api.js'
  import FilterTypeListFilter, { meta as baseMeta, customPickerShouldUseOr } from 'components/filter-types/FilterTypeListFilter.svelte'

  export const meta = {
    ...baseMeta,
    type: FilterType.Services,
    idKey: 'serviceId',
    label: 'Service',
    icon: 'shapes',
    iconClass: 'color-text-purple',
    allowEmpty: true,
    excludable: true,
    filterProp: 'serviceIds',
    getSelected: async selectedServiceIds => {
      const body = {
        selectedServiceIds,
        pageSize: null,
        includePageOfData: false,
        excludeTotalCount: true,
        includeOrgAndTeam: true,
      }
      const { selectedServices } = await api.service.list(body, api.noMonitor)
      return selectedServices
    },
  }
</script>

<script>
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import ServicePicker from 'components/fields/ServicePicker.svelte'

  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>

<div class="inline-flex-row flex-align-center g05">
  {#if startDate != null}
    <QuickFilterTag
      icon="calendar"
      class="color-text-dark-green"
      value={dateService.datestamp(startDate)}
      type="Start date"
      {filtersComponent}
      filterType={FilterType.MatchStartDate}
      filterConfig={buildQuickFilterConfig(startDate)}
      dataTest="start-date-quick-filter"
    />
  {/if}
  {#if endDate != null}
    {#if startDate != null}
      <span>–</span>
    {/if}
    <QuickFilterTag
      icon="calendar"
      class="color-text-light-red"
      value={dateService.datestamp(endDate)}
      type="End date"
      {filtersComponent}
      filterType={FilterType.MatchEndDate}
      filterConfig={buildQuickFilterConfig(endDate)}
      dataTest="end-date-quick-filter"
    />
  {/if}
</div>

<script>
  import dateService from 'services/date-service.js'
  import QuickFilterTag from 'components/QuickFilterTag.svelte'
  import { FilterType } from 'config/enums'
  import { buildQuickFilterConfig } from 'components/filter-types/FilterTypeDate.svelte'

  export let startDate
  export let endDate
  export let filtersComponent
</script>

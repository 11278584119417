<div class={className}>
  <div class="flex-row flex-align-center g05 nowrap">
    <OrgAndTeamProfilePicture {orgProfilePicture} {orgName} {orgRelativeName} {teamName} {teamOrgId} {noTooltip} />
    <Icon lg name="agreement" autoColor />
    <OrgAndTeamProfilePicture
      orgProfilePicture={schoolProfilePicture}
      orgName={schoolName}
      orgRelativeName={schoolRelativeName}
      teamName={schoolTeamName}
      teamOrgId={schoolTeamOrgId}
      {noTooltip}
    />
  </div>
  <div>
    {#if useOverflowEllipsis}
      <OverflowEllipsis wrapper="div" class={nameClass}>
        {name}
      </OverflowEllipsis>
    {:else}
      {name}
    {/if}
    <slot />
  </div>
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import OrgAndTeamProfilePicture from 'components/OrgAndTeamProfilePicture.svelte'
  import OverflowEllipsis from 'components/OverflowEllipsis.svelte'

  export let name

  export let orgName
  export let orgRelativeName
  export let orgProfilePicture
  export let teamName
  export let teamOrgId
  let className = 'flex-row flex-align-center g05 nowrap'
  export { className as class }
  export let schoolName
  export let schoolRelativeName
  export let schoolProfilePicture
  export let schoolTeamName
  export let schoolTeamOrgId
  export let useOverflowEllipsis = false
  export let nameClass = null

  export let noTooltip = false
</script>

<div class="flex-column g1 mb1 filters-sort-export">
  <CapacityMatchFilters {isDetailsView} {excludedFilterTypes} bind:filters onChanged={onFiltersChanged} bind:filtersComponent showSearchBox>
    <QuickFilterToggle />
  </CapacityMatchFilters>
</div>
<RotationListResultCount {loading} resultCount={matches.length} noResultsMessage="You have no rotations yet!" />
<Grid
  let:row
  {loading}
  bind:currentPage
  bind:pageSize
  class="middle flex-wrap"
  name="rotation-grid"
  pagesOfLabelSingular="rotation"
  paginatorTotalCount={totalCount}
  href={$isQuickFiltering ? null : '/opportunities/details/[capacityId]/matches/[matchId]'}
  rows={matches}
  useDefaultPageSizeOptions
>
  <GridCol {row} header="Status" name="status" class="shrink">
    <RotationListListGridStatus match={row} {filtersComponent} />
  </GridCol>

  <GridCol {row} header="School" name="school" class="shrink">
    <QuickFilterTag
      icon="school"
      class="color-text-blue"
      value={row.schoolName}
      type="School"
      href="/orgs/{row.schoolId}/{row.schoolRelativePath}"
      {filtersComponent}
      filterType={FilterType.GuestOrgs}
      configKey="schoolOrgIds"
      configValue={row.guestOrgId}
      simulationFilterOption={{
        orgId: row.guestOrgId,
        name: row.schoolName,
        orgRelativeName: row.schoolRelativeName,
        profilePicture: row.schoolProfilePicture,
      }}
      dataTest="school-org-tag"
    >
      <!-- consider using OrgAndTeamProfilePictureAndName - but if we do, we need to get teamorgid from the api too, currently not sending it -->
      <OrgProfilePictureAndName
        name={row.schoolName}
        profilePicture={row.schoolProfilePicture}
        relativeName={row.schoolRelativeName}
        contentClass="text-left"
      >
        {@const guest = guests.find(g => g.guestOrgId == row.guestOrgId)}
        {#if guest?.agreement}
          <div class="flex-row flex-align-center g05">
            <Icon name="agreement" autoColor lg />
            <em class="small text-gray">{guest.agreement.name}</em>
          </div>
        {/if}
      </OrgProfilePictureAndName>
    </QuickFilterTag>
  </GridCol>

  <GridCol {row} header="Rotation" name="rotation" class="shrink">
    <a href={isQuickFiltering ? `/opportunities/details/${row.capacityId}/matches/${row.matchId}` : ''} style="underline: none;">
      {#if validator.empty(row.displayName)}
        View rotation
      {:else}
        {row.displayName}
      {/if}
    </a>
  </GridCol>

  <GridCol {row} header="Location" name="location" class="shrink">
    <QuickFilterTag
      icon="hospital"
      class="color-text-orange"
      value={row.orgName}
      type="Organization"
      href="/orgs/{row.hostOrgId}/{row.hostOrgRelativePath}"
      {filtersComponent}
      filterType={FilterType.HostOrgs}
      configKey="orgIds"
      configValue={row.hostOrgId}
      simulationFilterOption={{
        orgId: row.hostOrgId,
        name: row.orgName,
        orgRelativeName: row.orgRelativeName,
        profilePicture: row.orgProfilePicture,
      }}
      dataTest="org-tag"
    >
      <OrgProfilePictureAndName
        name={row.orgName}
        profilePicture={row.orgProfilePicture}
        relativeName={row.orgRelativeName}
        contentClass="text-left"
      />
    </QuickFilterTag>
  </GridCol>

  <GridCol {row} header="Schedule" name="schedule" class="shrink">
    <div class="flex-column g05">
      <RotationListListGridStartEndDate startDate={row.startDate} endDate={row.endDate} {filtersComponent} />
      <ShiftCards {capacity} shifts={row.shifts} />
    </div>
  </GridCol>

  <GridCol {row} header="People" name="people" class="shrink">
    <div class="flex-row g1">
      <UsersProfilePicture users={row.students} additionalCount={row.placeholderStudentCount} relativeName="student" {filtersComponent}>
        <div slot="optional-users">
          {#if row.placeholderStudentCount}
            <PlaceholderStudentProfilePic xs includeCount value={row.placeholderStudentCount} />
          {/if}
        </div>
      </UsersProfilePicture>

      <UsersProfilePicture users={row.faculty} relativeName="faculty" {filtersComponent} />
      <UsersProfilePicture users={row.preceptors} relativeName="preceptor" {filtersComponent} />
    </div>
  </GridCol>

  <GridCol {row} header="Date submitted" name="dateSubmitted" class="shrink" thClass="nowrap">
    {#if row.dateSubmitted}
      <QuickFilterTag
        icon="calendar-clock"
        class="color-text-light-green"
        value={dateService.datestamp(row.dateSubmitted)}
        type="Date submitted"
        {filtersComponent}
        filterType={FilterType.MatchSubmittedDate}
        filterConfig={buildQuickFilterConfig(dateService.datestamp(row.dateSubmitted))}
        dataTest="end-date-quick-filter"
      >
        <Icon name="calendar-clock" class="color-text-light-green" />
        <div class="text-left">
          <FromNow date={row.dateSubmitted} localize />
          <br />
          {dateService.datetimestamp(row.dateSubmitted, true)}
        </div>
      </QuickFilterTag>
    {/if}
  </GridCol>

  {#if canManage}
    <GridCol {row} name="actions" class="text-right">
      <MediaQuery {...mediaQueries.actionsOverflow}>
        <OverflowMenu dataTest="overflow-actions-{row.matchId}">
          <OpportunitiesDetailsRotationListActions match={row} href="/opportunities/details/{row.capacityId}/matches/{row.matchId}" />
        </OverflowMenu>
      </MediaQuery>
    </GridCol>
  {/if}
</Grid>

<script>
  import { buildQuickFilterConfig } from 'components/filter-types/FilterTypeDate.svelte'
  import { createEventDispatcher } from 'svelte'
  import { FilterType } from 'config/enums.js'
  import CapacityMatchFilters from 'components/CapacityMatchFilters.svelte'
  import dateService from 'services/date-service'
  import FromNow from 'components/FromNow.svelte'
  import Grid from 'components/Grid.svelte'
  import GridCol from 'components/GridCol.svelte'
  import Icon from 'components/Icon.svelte'
  import isQuickFiltering from 'stores/is-quick-filtering.js'
  import MediaQuery from 'components/MediaQuery.svelte'
  import OpportunitiesDetailsRotationListActions from 'pages/authorized/org/Opportunities.Details.RotationList.Actions.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import OverflowMenu from 'components/OverflowMenu.svelte'
  import PlaceholderStudentProfilePic from 'components/PlaceholderStudentProfilePic.svelte'
  import QuickFilterTag from 'components/QuickFilterTag.svelte'
  import QuickFilterToggle from 'components/QuickFilterToggle.svelte'
  import RotationListListGridStartEndDate from 'pages/authorized/dashboard-views/RotationList.List.Grid.StartEndDate.svelte'
  import RotationListListGridStatus from 'pages/authorized/dashboard-views/RotationList.List.Grid.Status.svelte'
  import RotationListResultCount from 'pages/authorized/dashboard-views/RotationList.ResultCount.svelte'
  import ShiftCards from 'components/ShiftCards.svelte'
  import UsersProfilePicture from 'components/UsersProfilePicture.svelte'
  import validator from 'services/validator.js'

  export let capacity
  export let matches = []
  export let loading = false
  export let pageSize = 4
  export let currentPage = 1
  export let totalCount = null
  export let filtersComponent = null
  export let canManage = true
  export let filters = []
  export let onFiltersChanged = _.noop

  const isDetailsView = true

  const mediaQueries = {}
  const dispatch = createEventDispatcher()

  const excludedFilterTypes = [FilterType.MatchCapacity]

  $: pageSize, currentPage, onPageChanged()

  $: guests = capacity.guests

  function onPageChanged() {
    dispatch('pageChanged')
  }
</script>

<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} let:option={o} usingCustomPicker labelSelector={o => o.name}>
  <div slot="picker" class="mt1 mb1">
    <CapacityPicker bind:value={filter[appliedMeta.filterProp]} multiple or={customPickerShouldUseOr(appliedMeta, filter)} />
  </div>

  {#if o.orgId !== $persona.orgId}
    <strong>{o.orgName}</strong><br />
  {/if}
  {#if o.service}
    <strong>{o.service.name}</strong><br />
  {/if}
  {o.name}
</FilterTypeListFilter>

<script context="module">
  import { FilterType } from 'config/enums.js'
  import api from 'services/api.js'
  import CapacityPicker from 'components/fields/CapacityPicker.svelte'
  import FilterTypeListFilter, { meta as baseMeta, customPickerShouldUseOr } from 'components/filter-types/FilterTypeListFilter.svelte'
  import persona from 'stores/persona.js'

  const label = 'Opportunity'
  export const meta = {
    ...baseMeta,
    type: FilterType.MatchCapacity,
    idKey: 'capacityId',
    label,
    icon: 'list',
    iconClass: 'color-text-purple',
    allowEmpty: true,
    excludable: true,
    filterProp: 'capacityIds',
    getSelected: async selectedCapacityIds => {
      const body = {
        selectedCapacityIds,
        pageSize: null,
        includePageOfData: false,
        excludeTotalCount: true,
        includeServices: true,
        includeOrgAndTeam: true,
      }
      const { selectedCapacities } = await api.capacity.list(body, api.noMonitor)
      return selectedCapacities
    },
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>

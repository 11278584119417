{#if editing}
  <FormGroup>
    <InputToggle name="has-placeholder-students" bind:checked={filter.hasPlaceholderStudents}>
      {#if filter.hasPlaceholderStudents}
        <Icon name="placeholder-student" lg autoColor />
      {:else}
        <Icon name="placeholder-student" lg class="text-danger" />
      {/if} Has placeholder students
    </InputToggle>
  </FormGroup>
{:else if filter.hasPlaceholderStudents}
  <Icon name="placeholder-student" md autoColor />
  Has placeholder students
{:else}
  <Icon name="placeholder-student" md class="text-danger" />
  <strong>DOESN’T</strong>
  have placeholder students
{/if}

<script context="module">
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import { FilterType } from 'config/enums.js'

  const label = 'Has placeholder students'

  export const meta = {
    type: FilterType.MatchHasPlaceholderStudents,
    label,
    hideLabelIcon: true,
    icon: 'placeholder-student',
    iconProps: { autoColor: true },

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.hasPlaceholderStudents)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.hasPlaceholderStudents = reader.readBool()
      return config
    },

    create() {
      return { hasPlaceholderStudents: true }
    },

    validate() {
      return true
    },
  }
</script>

<script>
  import Icon from 'components/Icon.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'

  export let filter
  export let editing = false
</script>

import { AgreementStatus } from 'config/enums.js'
import { formatEnumValue } from 'services/formatters.js'

// get bootstrap color from agreement status name
const colors = {
  [AgreementStatus.Unsubmitted]: 'warning',
  [AgreementStatus.PendingClinicalSite]: 'purple',
  [AgreementStatus.PendingSchool]: 'info',
  [AgreementStatus.Active]: 'success',
  [AgreementStatus.Expired]: 'gray',
  [AgreementStatus.Closed]: 'danger',
}

// get icon from agreement status name
const icons = {
  [AgreementStatus.Unsubmitted]: 'edit',
  [AgreementStatus.PendingClinicalSite]: 'time',
  [AgreementStatus.PendingSchool]: 'time',
  [AgreementStatus.Active]: 'check',
  [AgreementStatus.Expired]: 'cancel',
  [AgreementStatus.Closed]: 'cancel',
}

class AgreementStatusHelpers {
  getLabel(status) {
    return formatEnumValue(AgreementStatus, status)
  }

  getColor(status) {
    return colors[status]
  }

  getIcon(status) {
    return icons[status]
  }

  get(status) {
    return {
      key: status,
      label: this.getLabel(status),
      color: this.getColor(status),
      icon: this.getIcon(status),
    }
  }
}

export default new AgreementStatusHelpers()

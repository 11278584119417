<!-- <div class="btn-group">
  {#each zoomOptions as zoom}
    <Btn on:click={() => (value = zoom.value)} class="btn btn-{value == zoom.value ? 'info' : 'default'}">{zoom.label}</Btn>
  {/each}
</div> -->
<InputSelect options={zoomOptions} bind:value on:change name="zoom-picker" prefixLabel="Group by" />

<script>
  // import Btn from 'components/bootstrap/Btn.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'

  export let value

  const zoomOptions = [
    { value: 'd', label: 'Day' },
    { value: 'w', label: 'Week' },
    { value: 'm', label: 'Month' },
    // { value: 'q', label: 'Quarter' },
    { value: 'y', label: 'Year' },
  ]
</script>

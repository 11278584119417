<Card name={student.name} id={student.userId} image={student.profilePicture} class="card-fill card-min-sm {className ? ` ${className}` : ''}">
  <div class="flex-row flex-justify-between g1">
    <div class="flex-column flex-justify-center g05">
      <h3 class="m0 break-spaces strong">
        {student.name}
      </h3>

      <StudentYearDisciplineLabel {student} />
      <StudentGraduationDateLabel {student} />
      <StudentEmailsWithConfirmedStatuses {student} />
    </div>

    <div class="flex-column flex-justify-center g05 flex-grow">
      <div>
        <Icon lg name="school" class="color-text-blue" />
        <strong>Student at</strong>
      </div>
      <StudentSchools {student} />
    </div>

    <div class="flex-row flex-justify-end">
      <Btn clearBtnStyling title={{ content: 'Remove student', options: { placement: 'top' } }} on:click={() => onDelete(student.userId)}>
        <Icon name="delete" class="text-danger" />
      </Btn>
    </div>
  </div>
</Card>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Card from 'components/Card.svelte'
  import Icon from 'components/Icon.svelte'
  import StudentEmailsWithConfirmedStatuses from 'components/StudentEmailsWithConfirmedStatuses.svelte'
  import StudentGraduationDateLabel from 'components/StudentGraduationDateLabel.svelte'
  import StudentSchools from 'components/StudentSchools.svelte'
  import StudentYearDisciplineLabel from 'components/StudentYearDisciplineLabel.svelte'

  export let student = {}
  let className = ''
  export { className as class }
  export let onDelete = _.noop
</script>

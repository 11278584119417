<p class="mb05">To use an authenticator app:</p>
<ol class="list">
  <li>
    <p class="mb05">Download a two-factor authenticator app. Examples include:</p>
    <AuthenticatorAppLinks />
  </li>
  <li>
    <p class="mb05">
      Scan the QR Code <em>or</em> enter the key below into your two factor authenticator app. Spaces and casing do not matter.
    </p>
    <Copyable value={authenticatorInfo.sharedKey} name="sharedkey" />
    <Qrcode value={authenticatorInfo.uri} class="my1" />
  </li>
  <li>
    <p class="mb05">
      Once you have scanned the QR code or input the key above, your two factor authentication app will provide you with a unique code. Enter the code
      in the confirmation box below.
    </p>
    <FormGroup valid={validator.required(token)}>
      <label for="token" class="control-label">Verification Code</label>
      <InputText name="token" class="form-control" autocomplete="off" bind:value={token} />
    </FormGroup>
    {#if showPassword}
      <FormGroup valid={validator.required(password)}>
        <label for="password">Password</label>
        <InputPassword autocomplete="new-password" name="password" bind:value={password} class="form-control" />
        <p class="help-block">Please re-enter your password to prove you’re the account owner</p>
      </FormGroup>
    {/if}
  </li>
</ol>

<script>
  import AuthenticatorAppLinks from 'components/AuthenticatorAppLinks.svelte'
  import Copyable from 'components/Copyable.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import InputPassword from 'components/fields/InputPassword.svelte'
  import InputText from 'components/fields/InputText.svelte'
  import Qrcode from 'components/Qrcode.svelte'
  import validator from 'services/validator.js'

  export let authenticatorInfo = null
  export let token = null
  export let password = null
  export let showPassword = false
</script>

<style lang="scss">
  ol.list li {
    margin-bottom: 5px;
  }
</style>

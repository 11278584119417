<QuickDropdown label="Show…" icon="sliders-simple" btnClass="btn btn-default" labelClass="flex-row flex-align-center g05" dropdownClass="p2">
  <div class="flex-column g2">
    <div class="flex-row flex-align-center g05">
      <Btn clearBtnStyling dataTest="show-everything" on:click={() => toggleAll(true)}>Show everything</Btn>
      <div class="flex-grow" />
      <Btn clearBtnStyling dataTest="show-minimal" on:click={() => toggleAll(false)}>Show minimal</Btn>
    </div>

    <div class="flex-column g05">
      <h4>Owner & location</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.owner} name="show-owner">
        <Icon lg name="hospital" class="color-text-orange" />
        Owner location
      </InputToggle>

      {#if canToggleTeams}
        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.team} name="show-team">
          <Icon lg name="users" class="color-text-orange" />
          Team
        </InputToggle>
      {/if}

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.locations} name="show-location">
        <Icon lg name="hospital" class="color-text-orange" />
        Location
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.addresses} name="show-addresses">
        <Icon lg name="map-marker" class="color-text-orange" />
        Addresses
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.description} name="show-description">
        <Icon lg name="paragraph" class="color-text-steel" />
        Description
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.service} name="show-service">
        <Icon lg name="shapes" class="color-text-purple" />
        Service
      </InputToggle>

      {#if $showDropdowns.capacityPicker.service}
        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.serviceDescription} name="show-service-description">
          <Icon lg name="paragraph" class="color-text-steel" />
          Service description
        </InputToggle>
      {/if}
    </div>
    <div class="flex-column g05">
      <h4>Schedule</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.startAndEndDate} name="show-start-and-end-date">
        <Icon lg name="calendar" class="color-text-dark-green" />
        Start and
        <Icon lg name="calendar" class="color-text-light-red" />
        end dates
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.matchLeadTimeDays} name="show-lead-time">
        <Icon lg name="calendar-clock" class="color-text-dark-green" />
        Lead time
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.rotationTypes} name="show-rotation-types">
        <Icon lg name="briefcase-medical" class="text-rotation-type" />
        Experience types
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.specialties} name="show-specialties">
        <Icon lg name="file-certificate" class="text-specialty" />
        Specialties
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.disciplines} name="show-disciplines">
        <Icon lg name="book" class="color-text-teal" />
        Disciplines
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <h4>Schools</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.guests} name="show-guests">
        <Icon lg name="school" class="color-text-blue" />
        School
      </InputToggle>
      {#if $showDropdowns.capacityPicker.guests}
        <div class="flex-column g05 ml2">
          <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.capacityPicker.matches} name="show-matches">
            <Icon lg name="slots" class="color-text-steel" />
            Rotations
          </InputToggle>
        </div>
      {/if}
    </div>
  </div>
</QuickDropdown>

<script>
  import { FeatureType, PersonaType } from 'config/enums.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import personaService from 'services/persona-service.js'
  import showDropdowns from 'stores/show-dropdowns.js'
  import persona from 'stores/persona.js'

  const toggleParams = {
    containerClass: 'flex-row flex-align-center g1',
    labelClass: 'flex-row flex-align-center g05',
    class: 'm0',
  }

  $: hasTeamFeature = personaService.canUseAnyFeatureType(FeatureType.TeamManagement)
  $: canToggleTeams = hasTeamFeature || $persona.personaType === PersonaType.SchoolStaff

  function toggleAll(checked) {
    for (const key in $showDropdowns.capacityPicker) {
      if (typeof $showDropdowns.capacityPicker[key] !== 'boolean') continue
      $showDropdowns.capacityPicker[key] = checked
    }
  }
</script>

<style>
  h4 {
    margin: 0;
    line-height: 1;
    font-size: 16px;
  }
</style>

<div class="card card-lg card-min-sm">
  <div class="flex-row flex-align-center">
    <PlaceholderStudentProfilePic bind:value />
    <div class="relative full-width">
      <div class="absolute p05" style="top: -100%; right: 0">
        <Btn clearBtnStyling title="Remove {pluralCount('placeholder student', value, 'omitNumber')}" on:click={onDelete}>
          <Icon name="delete" class="text-danger" />
        </Btn>
      </div>
      <h4 class="strong text-center margin-auto">
        {pluralCount('placeholder student', value)}
      </h4>
    </div>
  </div>
</div>

<script>
  import { pluralCount } from 'services/string-utils'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from './Icon.svelte'
  import PlaceholderStudentProfilePic from 'components/PlaceholderStudentProfilePic.svelte'

  export let value
  export let onDelete = _.noop
</script>

{#if mask == null || mask.trim() === ''}
  <input
    bind:this={inputEl}
    type="text"
    class:disabled
    class="form-control {className}"
    {name}
    id={name}
    data-test={name}
    bind:value
    on:blur={blurProxy}
    on:keyup
    on:keydown
    on:input
    on:focus
    {placeholder}
    {maxlength}
    {autocomplete}
    {disabled}
  />
{:else}
  <InputTextMasked bind:value {mask} {disabled} dataTest={name} on:blur={() => (blurred = true)} />
{/if}
{#if displayMaxLength && !disabled}
  <MaxLength {value} {maxlength} />
{/if}

<script>
  import { getContext, createEventDispatcher } from 'svelte'
  import InputTextMasked from './InputTextMasked.svelte'
  import MaxLength from './MaxLength.svelte'

  export { className as class }
  export let autocomplete = null
  export let autofocus = false
  export let disabled = false
  export let mask = null
  export let maxlength = null
  export let displayMaxLength = true // if your InputText is inside an input-group, for instance, you'll want to display max length from the calling code instead.
  export let name = null
  export let placeholder = null
  export let value = null

  const initialValue = value
  const dispatch = createEventDispatcher()
  const markDirty = getContext('markDirty')
  let className = ''
  let inputEl
  let blurred = false

  $: if (markDirty != null && blurred && value != initialValue) markDirty()
  $: if (autofocus && inputEl) focus()

  export function focus() {
    setTimeout(() => inputEl?.focus?.())
  }

  function blurProxy() {
    blurred = true
    dispatch('blur')
  }
</script>

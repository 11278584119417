<QuickDropdown
  label="Show…"
  icon="sliders-simple"
  btnClass="btn btn-default"
  labelClass="flex-row flex-align-center g05"
  dropdownClass="p2"
  dataTest="services-show-dropdown"
>
  <div class="flex-column g2">
    <div class="flex-row flex-align-center g05">
      <Btn clearBtnStyling dataTest="show-everything" on:click={() => toggleAll(true)}>Show everything</Btn>
      <div class="flex-grow" />
      <Btn clearBtnStyling dataTest="show-minimal" on:click={() => toggleAll(false)}>Show minimal</Btn>
    </div>

    <div class="flex-column g05">
      <h4>Service</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.serviceGrid.description} name="show-description">
        <Icon name="paragraph" class="color-text-steel" />
        Description
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.serviceGrid.specialties} name="show-specialties">
        <Icon lg name="file-certificate" class="text-specialty" />
        Specialties
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <h4>Organization</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.serviceGrid.organization} name="show-organization">
        <Icon lg name="hospital" class="color-text-orange" />
        Organization
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <h4>Team</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.serviceGrid.team} name="show-team">
        <Icon lg name="users" class="color-text-orange" />
        Team
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <h4>Active opportunities</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.serviceGrid.capacities} name="show-capacities">
        <Icon lg name="list" class="color-text-purple" />
        Active opportunities
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <h4>Who is it for?</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.serviceGrid.disciplines} name="show-disciplines">
        <Icon lg name="book" class="color-text-teal" />
        Disciplines
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <h4>Agreements</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.serviceGrid.agreements} name="show-agreement">
        <Icon lg name="agreement" autoColor />
        Agreements
      </InputToggle>

      {#if $showDropdowns.serviceGrid.agreements}
        <div class="ml3">
          <InputOptionalNumber
            name="show-agreement-count"
            bind:value={$showDropdowns.serviceGrid.agreementCount}
            autocomplete="off"
            noValueLabelClass="flex-row flex-align-center g05"
            noValueIsNull
            min={0}
            valueLabelPostfix={$showDropdowns.serviceGrid.agreementCount === 1 ? 'agreement' : 'agreements'}
            inputNumberClass="input-group-inline-grid"
            inputNumberStyle="width: 50px"
          >
            <svelte:fragment slot="no-value-label">
              All <Icon lg autoColor name="agreement" /> agreements
            </svelte:fragment>

            <span class="input-group-addon inline-flex-row flex-align-center g05">
              <Icon lg autoColor name="agreement" />
              {pluralCount('agreement', $showDropdowns.serviceGrid.agreementCount, 'omitNumber')}
            </span>
          </InputOptionalNumber>
        </div>
      {/if}
    </div>
  </div>
</QuickDropdown>

<script>
  import { pluralCount } from 'services/string-utils.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import InputOptionalNumber from 'components/InputOptionalNumber.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'

  const toggleParams = {
    containerClass: 'flex-row flex-align-center g1',
    labelClass: 'flex-row flex-align-center g05',
    class: 'm0',
  }

  function toggleAll(checked) {
    for (const key in $showDropdowns.serviceGrid) {
      if (typeof $showDropdowns.serviceGrid[key] !== 'boolean') continue
      $showDropdowns.serviceGrid[key] = checked
    }
  }
</script>

<style>
  h4 {
    margin: 0;
    line-height: 1;
    font-size: 16px;
  }
</style>

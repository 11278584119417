<FileName value={name} insideOf="inline-list" />
<span class="small em">({humanFileSize(file.size)})</span>

<script>
  import { humanFileSize } from 'services/string-utils.js'
  import FileName from 'components/FileName.svelte'

  export let file

  $: name = file.nameOriginal || file.name
</script>

{#if filterOptions}
  <FilterTypeOrg {filter} {editing} {filterOptions} {appliedMeta} placeholder="Search schools" />
{/if}

<script context="module">
  import FilterTypeOrg, { meta as baseMeta } from 'components/filter-types/FilterTypeOrg.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.GuestOrgs,
    label: 'School',
    icon: 'school',
    iconClass: 'color-text-blue',
    filterProp: 'schoolOrgIds',
    optionsEndpoint: 'getSchools',
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta

  $: filterOptions =
    appliedMeta?.includeOpenSchoolGuest && filterOptions?.every(o => o.orgId)
      ? [...filterOptions, { name: 'Open (any school)', orgId: null }]
      : filterOptions
</script>

<!--
  show absolute value, but if they choose "ago" option, store a negative number for the underyling value
  user _can_ set to negative value if that's more intuitive to them (e.g. if "-2 days from now" is more intuitive than "2 days ago")
-->
<InputNumber min={0} bind:value={abs} style="width:70px;" {sm} bind:this={inputNumberElem} name="{valueUnit}-custom">
  <InputSelect
    inline
    sm
    {options}
    bind:value={multiplier}
    placeholder="{unit} {multiplier === 1 ? 'from now' : 'ago'}"
    name="{valueUnit}-custom-now-ago"
  />
</InputNumber>

<script>
  import InputNumber from 'components/fields/InputNumber.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import { buildOption } from 'services/option-builder.js'

  export let value
  export let valueUnit // pass singular form
  export let valueUnitPlural = valueUnit + 's'
  export let sm = false

  let multiplier = Math.sign(value)
  let abs
  let inputNumberElem

  $: value, setAbs() // value is set from outside, so set abs value
  $: multiplier, abs, setValue() // abs or multiplier changes internally, so update external value
  $: unit = Math.abs(value) === 1 ? valueUnit : valueUnitPlural
  $: options = [buildOption(`${unit} from now`, 1), buildOption(`${unit} ago`, -1)]

  function setValue() {
    value = abs * multiplier
  }

  function setAbs() {
    abs = value * multiplier
  }

  export function focus() {
    inputNumberElem?.focus?.()
  }
</script>

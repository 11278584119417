{#if showLeft || showRight}
  <div class="details-container mt1" class:no-left={!showLeft}>
    {#if showLeft}
      <div class="flex-column g1 details-left">
        {#if show.status}
          <div class="flex-row flex-align-center g05 text-{status.color}">
            <Icon name={status.icon} fw />
            <span>{status.label}</span>
          </div>
        {/if}

        {#if show.activeCapacityCount}
          <ActiveCapacityCount count={agreement.activeCapacityCount} />
        {/if}

        {#if show.services}
          <ServiceCount count={agreement.serviceIds} />
        {/if}

        {#if show.disciplines && agreement.disciplines?.length}
          <div>
            <TagCloud
              ctx={{
                disciplines: show.disciplines ? agreement.disciplines : [],
              }}
            />
          </div>
        {/if}

        {#if show.description}
          <LongDesc class="m0" max={longDescMax} text={agreement.descriptionHtml} />
        {/if}
      </div>
    {/if}

    {#if showRight}
      <div class="flex-column g2 details-right">
        {#if show.organization || (show.team && agreement.teamOrgId)}
          <div class="agreement-owner">
            <div class="flex-row flex-align-center g05 mb05">
              <Icon name="hospital" class="color-text-orange" />
              <h4 class="leading-none m0">Provided by</h4>
            </div>

            <div class="flex-column g05">
              {#if show.organization}
                <OrgProfilePictureAndName
                  name={agreement.orgName}
                  relativeName={agreement.orgRelativeName}
                  profilePicture={agreement.orgProfilePicture}
                  formattedAddress={agreement.orgAddress?.formattedAddress}
                  size="medium"
                  useOverflowEllipsis
                />
              {/if}

              {#if show.team && agreement.teamOrgId}
                <TeamProfilePictureAndName medium orgId={agreement.teamOrgId} name={agreement.teamName} />
              {/if}
            </div>
          </div>
        {/if}

        {#if show.school}
          <div class="agreement-school">
            <div class="flex-row flex-align-center g05 mb05">
              <Icon name="school" class="color-text-blue" />
              <h4 class="leading-none m0">School</h4>
            </div>

            <div class="flex-column g05">
              <OrgProfilePictureAndName
                name={agreement.schoolName}
                relativeName={agreement.schoolRelativeName}
                profilePicture={agreement.schoolProfilePicture}
                formattedAddress={agreement.schoolAddress?.formattedAddress}
                size="medium"
                useOverflowEllipsis
              />

              {#if agreement.schoolTeamOrgId}
                <TeamProfilePictureAndName medium orgId={agreement.schoolTeamOrgId} name={agreement.teamName} />
              {/if}
            </div>
          </div>
        {/if}
      </div>
    {/if}
  </div>
{/if}

<script>
  import ActiveCapacityCount from 'components/ActiveCapacityCount.svelte'
  import agreementStatusHelper from 'services/agreement-status-helper.js'
  import Icon from 'components/Icon.svelte'
  import LongDesc from 'components/LongDesc.svelte'
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import ServiceCount from 'components/ServiceCount.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import TagCloud from 'components/TagCloud.svelte'
  import TeamProfilePictureAndName from 'components/TeamProfilePictureAndName.svelte'

  export let agreement = null
  // export let isSelected
  // export let isHovered

  $: show = $showDropdowns.agreementPicker
  $: showLeft = show.activeCapacityCount || show.status || show.services || show.description
  $: showRight = show.organization || (show.team && agreement.teamOrgId) || show.school
  $: status = agreementStatusHelper.get(agreement.status)

  const longDescMax = 60
</script>

<style lang="scss">
  .details-container {
    display: grid;
    gap: 20px;
    // TODO(services): Make responsive
    grid-template-columns: auto 320px;
    grid-template-rows: auto;
    grid-template-areas: 'left right';

    &.no-left {
      grid-template-columns: auto;
      grid-template-areas: 'right';
    }
  }

  .details-left {
    grid-area: left;
  }

  .details-right {
    grid-area: right;
  }
</style>

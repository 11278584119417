// *** Any changes to this function need to be synchronized to C# FormService.CheckConditionValue, and should be well-tested on both client and server
// server tests: FormServiceTests.cs
// client tests: DynamicForm.spec.js

import { ComparisonType } from 'config/enums.js'

export default function checkConditionValue(value, comparisonType, conditionValue, conditionValues) {
  switch (comparisonType) {
    // todo might want to verify it has a valid date / number / etc for IsEmpty / IsNotEmpty... but meh
    case ComparisonType.IsEmpty:
      return value == null || value === ''
    case ComparisonType.IsNotEmpty:
      return value != null && value !== ''
    case ComparisonType.Is:
      return is(conditionValues, value, conditionValue)
    case ComparisonType.IsNot:
      return !is(conditionValues, value, conditionValue)
    case ComparisonType.Contains:
      return contains(conditionValues, value, conditionValue)
    case ComparisonType.DoesNotContain:
      return !contains(conditionValues, value, conditionValue)
    case ComparisonType.GreaterThan:
      return conditionValue != null && value != null && Number.parseFloat(value) > Number.parseFloat(conditionValue)
    case ComparisonType.LessThan:
      return conditionValue != null && value != null && Number.parseFloat(value) < Number.parseFloat(conditionValue)
    case ComparisonType.IsBefore:
      return conditionValue != null && value != null && dayjs(value).isBefore(dayjs(conditionValue))
    case ComparisonType.IsAfter:
      return conditionValue != null && value != null && dayjs(value).isAfter(dayjs(conditionValue))
    case ComparisonType.IsYes:
    case ComparisonType.IsChecked:
      return value === true || value === 'true'
    case ComparisonType.IsNo:
      return value === false || value === 'false'
    case ComparisonType.IsNotChecked:
      return value !== true && value !== 'true'
  }
  return false
}

function is(conditionValues, value, conditionValue) {
  if (conditionValues == null) {
    // Simple single-value field.
    return valueStringLower(conditionValue) === valueStringLower(value)
  }

  // If a multi-select input, check if the user selected ALL of the condition values.
  if (Array.isArray(value)) {
    return conditionValues.every(v => {
      const lowerConditionValue = valueStringLower(v)
      return value.some(vv => valueStringLower(vv) === lowerConditionValue)
    })
  }

  // If a single-select input, check if the user selected ANY of the condition values.
  const lowerValue = valueStringLower(value)
  return conditionValues.some(v => valueStringLower(v) === lowerValue)
}

function contains(conditionValues, value, conditionValue) {
  if (conditionValues == null) {
    // Simple single-value field.
    return valueStringLower(value).includes(valueStringLower(conditionValue))
  }

  // If a multi-select input, check if the user selected ANY of the condition values.
  if (Array.isArray(value)) {
    return conditionValues.some(v => {
      const lowerConditionValue = valueStringLower(v)
      return value.some(vv => valueStringLower(vv) === lowerConditionValue)
    })
  }

  // If a single-select input, check if the user selected ANY of the condition values.
  const lowerValue = valueStringLower(value)
  return conditionValues.some(v => valueStringLower(v) === lowerValue)
}

function valueStringLower(val) {
  return val == null ? null : val.toString().toLowerCase()
}

<div class="image-and-text">
  <div class="image">
    {#if name}
      <ProfilePic
        {id}
        {name}
        {initials}
        src={image}
        href={imgHref}
        title={deleted ? 'Inactive user' : imgTitle}
        {dataTest}
        large
        roundLeftBorder={$windowWidth > 500}
        pseudoDisabled={deleted}
      />
    {:else if icon}
      <Icon name={icon} />
    {/if}
  </div>
  <div class="text">
    <slot />
  </div>
  <div class="controls">
    <slot name="controls" />
  </div>
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import ProfilePic from './ProfilePic.svelte'
  import windowWidth from 'stores/window-width.js'

  export let name = null
  export let id = null
  export let dataTest = null
  export let image = null
  export let imgHref = null
  export let imgTitle = null
  export let icon = null
  export let deleted = false
  export let initials = null
</script>

<style>
  @media only screen and (max-width: 500px) {
    .image-and-text {
      display: block;
    }

    .image {
      text-align: center;
      padding-top: 10px;
      padding-bottom: 5px;
    }

    .text {
      text-align: center;
    }
  }
</style>

import { ArchiveActiveFilter, CriteriaFilterType, DashboardViewCategory, FeatureType, FilterType, Permission } from 'config/enums.js'

const excludedFilterTypes = [
  FilterType.CapacityLocations,
  FilterType.KeywordSearch,
  FilterType.MatchHasIncompleteSteps,
  FilterType.Steps,
  FilterType.MatchHasStepsICanComplete,
  FilterType.MatchHasStepsICanVerify,
  FilterType.MatchStatusCategory,
  FilterType.RotationTypes,
  FilterType.Specialties,
  FilterType.StepSubmissionTargetUser,
]

// TODO: filter and ignored types should use excludedFilterTypes, but there is conflict with MatchStatusCategory

export default {
  category: DashboardViewCategory.Reports,
  permission: Permission.ViewStudentReport,
  featureTypes: [FeatureType.ReportStudentFacultyData],
  componentName: 'StudentReport',
  name: 'Student, faculty, and preceptors report',
  icon: 'user-graduate',
  criteria: {
    includeFaculty: true,
    includePreceptors: true,
    includeStudents: true,
    includeHealthOrgHierarchy: false,
    includeRotationInfo: false,
    includeProfile: true,
    filters: [
      {
        type: FilterType.MatchStatusCategory,
        config: {
          statusCategory: ArchiveActiveFilter.Active,
        },
      },
    ],
  },
  excludedFilterTypes,
  filtersConfig: [
    {
      prefix: '',
      name: 'filters',
      type: CriteriaFilterType.MatchFilter,
    },
  ],
  criteriaConfig: {
    includeProfile: { name: 'ip', method: 'readBool' },
    includeRotationInfo: { name: 'iri', method: 'readBool' },
    includeHealthOrgHierarchy: { name: 'ihoh', method: 'readBool' },
    includeStudents: { name: 'is', method: 'readBool' },
    includeFaculty: { name: 'if', method: 'readBool' },
    includePreceptors: { name: 'ipr', method: 'readBool' },
  },

  waitFor: { method: 'POST', route: /\/reports\/students/i },
  // testClick: 'run-report-btn',
  testFor: ['student-report'],
}

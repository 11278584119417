{#if shift.service != null}
  <div class="inline-flex-row flex-align-center g05">
    <Icon name="shapes" class="color-text-purple" />
    {#if $persona.personaType === PersonaType.ProviderStaff}
      <a href="/services/{shift.serviceId}" use:tip={`View/edit ${shift.service}`}>{shift.service}</a>
    {:else}
      {shift.service}
    {/if}
  </div>
  -
{/if}
<div class="inline-flex-row flex-align-center g05">
  <Icon name="list" class="color-text-purple" />
  {#if $persona.personaType === PersonaType.ProviderStaff}
    <a href="/opportunities/{shift.capacityId}" use:tip={`View/edit ${shift.capacity}`}>{shift.capacity}</a>
  {:else}
    {shift.capacity}
  {/if}
</div>
-
{shift.name}

<script>
  import { PersonaType } from 'config/enums.js'
  import Icon from 'components/Icon.svelte'
  import persona from 'stores/persona.js'
  import tip from 'decorators/tip.js'

  export let shift
</script>

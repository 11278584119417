<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} let:option={o} labelSelector={o => o.label} hideSearchBox />

<script context="module">
  import FilterTypeListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeListFilter.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    idKey: 'key',
    type: FilterType.StaffRole,
    label: 'Roles',
    labelApplied: 'staff role',
    icon: 'building',
    iconClass: 'color-text-purple',
    filterProp: 'staffRoles',
    toManySuffix: 'of the following staff members',
    editLabel: null,
    canHaveMultiple: true,
    toMany: true,
    hasDoesntHave: true,
  }
</script>

<script>
  import { StaffRole } from 'config/enums.js'
  import { formatEnumValue } from 'services/formatters.js'

  export let filter
  export let editing = false
  export let appliedMeta

  const filterOptions = Object.values(StaffRole)
    .sort((a, b) => a - b)
    .map(sr => staffRoleHelper(sr))

  function staffRoleHelper(staffRole) {
    return { key: staffRole, label: formatEnumValue(StaffRole, staffRole) }
  }
</script>

import { CriteriaFilterType, DashboardViewCategory, EvaluationReportGroupBy, FeatureType, FilterType, Permission } from 'config/enums.js'

const includedFilterTypes = new Set([
  FilterType.MatchRequestType,
  FilterType.HostOrgs,
  FilterType.MatchLocations,
  FilterType.GuestOrgs,
  FilterType.MatchCapacity, // TODO(tim) make sure this actually works - implemented on backend, I think, but not sure about UI being populated/etc
  FilterType.Services,
  FilterType.Disciplines,
  FilterType.StudentYears,
  FilterType.StepSubmissionTargetUser,
  // Nice-to-have: submitted date, so can do relative date filters. Then, could get rid of the datepicker.
])
const excludedFilterTypes = Object.keys(FilterType)
  .map(k => FilterType[k])
  .filter(t => !includedFilterTypes.has(t))

export default {
  category: DashboardViewCategory.Reports,
  permission: Permission.ViewEvaluationStepsReport,
  featureTypes: [FeatureType.Evaluations],
  componentName: 'EvaluationsReport',
  name: 'Evaluations by step',
  icon: 'Evaluation',
  color: 'black',
  criteria: {
    filters: [],
    stepIdsToShow: [], // which steps to show
    stepIdsToExpand: [], // which steps to open details for
    startDate: dayjs().utc().add(-60, 'days').format('M/D/YYYY'),
    endDate: dayjs().utc().format('M/D/YYYY'),
    groupBy: [EvaluationReportGroupBy.Step],
  },
  excludedFilterTypes,
  filtersConfig: [
    {
      prefix: '',
      name: 'filters',
      type: CriteriaFilterType.MatchFilter,
    },
  ],

  criteriaConfig: {
    startDate: { name: 'sd', method: 'readDate' },
    endDate: { name: 'ed', method: 'readDate' },
    stepIdsToShow: { name: 'toshow', method: 'readIntArray' },
    stepIdsToExpand: { name: 'toexpand', method: 'readIntArray' },
    groupBy: { name: 'group', method: 'readIntArray' },
  },

  // ideally, report-views.spec.js should do a better test of this report. its seed data doesn't have any evals setup though, so meh.
  waitFor: { method: 'GET', route: /\/reports\/evaluations/i },
  // testClick: 'run-report-btn',
  // testFor: ['step-report'],
}

{#if editing}
  <div class={editingClass}>
    <InputSelect inline sm options={comparisonOptions.filter(c => !c.isDisplayOnly)} bind:value={comparisonOptionValue} name="date-comparison" />

    {#if appliedMeta?.exactDateOnly}
      {#if filter.comparisonProviderType === DateTimeComparisonProviderType.ExactDate || filter.comparisonProviderType !== DateTimeComparisonProviderType.Parameterless}
        <div class="flex-row flex-align-center mt1">
          <DatePicker
            name="exact-date"
            popRight
            sm
            placeholder="Exact date"
            bind:value={filter.comparisonProvider.date}
            showError={filter.comparisonProviderType === DateTimeComparisonProviderType.ExactDate}
            on:input={() => setComparisonType(DateTimeComparisonProviderType.ExactDate)}
          />
        </div>
      {/if}
    {:else}
      <FormGroup
        class={formGroupClass}
        style="width: 400px; {selectedComparison.isCustomizable ? '' : 'display: none;'}"
        valid={meta.validate(filter)}
      >
        <InputRadioGroup name="compare-to" options={comparisonRadioOptions} bind:value={filter.comparisonProviderType} let:option={comparison}>
          <div class="flex-row flex-align-center g05" use:onInteract={{ tabIndex: null, fn: () => setComparisonType(comparison.value) }}>
            {#if comparison.value === DateTimeComparisonProviderType.ExactDate}
              <DatePicker
                name="exact-date"
                popRight
                sm
                placeholder="Exact date"
                bind:value={filter.comparisonProvider.date}
                showError={filter.comparisonProviderType === DateTimeComparisonProviderType.ExactDate}
                on:input={() => setComparisonType(comparison.value)}
              />
            {:else if comparison.value === DateTimeComparisonProviderType.RelativeDays}
              <FilterTypeDateNumber
                bind:value={filter.comparisonProvider.daysFromToday}
                valueUnit="day"
                options={[buildOption('today', 0), buildOption('tomorrow', 1), buildOption('yesterday', -1)]}
              />
            {:else if comparison.value === DateTimeComparisonProviderType.RelativeWeeks}
              <InputSelect
                inline
                sm
                placeholder={daysOfWeek[0].name}
                options={daysOfWeek}
                bind:value={filter.comparisonProvider.dayOfWeek}
                name="day-of-week"
              />
              of
              <FilterTypeDateNumber bind:value={filter.comparisonProvider.weeksFromNow} valueUnit="week" />
            {:else if comparison.value === DateTimeComparisonProviderType.RelativeMonths}
              <InputSelect
                inline
                sm
                placeholder={daysOfMonth[0].name}
                options={daysOfMonth}
                bind:value={filter.comparisonProvider.dayOfMonth}
                name="day-of-month"
              />
              of
              <FilterTypeDateNumber bind:value={filter.comparisonProvider.monthsFromNow} valueUnit="month" />
            {:else if comparison.value === DateTimeComparisonProviderType.RelativeQuarters}
              <InputSelect
                inline
                sm
                placeholder={rangeBoundOptions[0].name}
                options={rangeBoundOptions}
                bind:value={quarterRangeBound}
                name="qtr-range-bound"
              />
              of
              <FilterTypeDateNumber bind:value={filter.comparisonProvider.quartersFromNow} valueUnit="quarter" data-test="quarters-from-now" />
            {:else if comparison.value === DateTimeComparisonProviderType.RelativeYears}
              <InputSelect
                inline
                sm
                placeholder={rangeBoundOptions[0].name}
                options={rangeBoundOptions}
                bind:value={yearRangeBound}
                name="year-range-bound"
              />
              of
              <FilterTypeDateNumber bind:value={filter.comparisonProvider.yearsFromNow} valueUnit="year" />
            {/if}
          </div>
        </InputRadioGroup>
      </FormGroup>
    {/if}
  </div>
{:else}
  {label}
  <strong>
    {selectedComparison.name}
    {#if selectedComparison.isCustomizable}
      {#if filter.comparisonProviderType === DateTimeComparisonProviderType.ExactDate}
        <!-- Yay, space hacks-->
        {' '}
        {filter.comparisonProvider.date || '…?'}
      {:else if filter.comparisonProviderType === DateTimeComparisonProviderType.RelativeDays}
        <!-- Yay, space hacks-->
        {' '}
        {#if filter.comparisonProvider.daysFromToday === 0}
          today
        {:else if filter.comparisonProvider.daysFromToday === -1}
          yesterday
        {:else if filter.comparisonProvider.daysFromToday === 1}
          tomorrow
        {:else}
          {Math.abs(filter.comparisonProvider.daysFromToday)}
          days
          {#if filter.comparisonProvider.daysFromToday > 0}from now{:else}ago{/if}
        {/if}
      {:else if filter.comparisonProviderType === DateTimeComparisonProviderType.RelativeWeeks}
        <!-- Yay, space hacks-->
        {' '}
        {weekPart.name}
        of
        {#if filter.comparisonProvider.weeksFromNow === 0}
          this week
        {:else if filter.comparisonProvider.weeksFromNow === -1}
          last week
        {:else if filter.comparisonProvider.weeksFromNow === 1}
          next week
        {:else}
          {Math.abs(filter.comparisonProvider.weeksFromNow)}
          weeks
          {#if filter.comparisonProvider.weeksFromNow > 0}from now{:else}ago{/if}
        {/if}
      {:else if filter.comparisonProviderType === DateTimeComparisonProviderType.RelativeMonths}
        <!-- Yay, space hacks-->
        {' '}
        {monthPart.name}
        of
        {#if filter.comparisonProvider.monthsFromNow === 0}
          this month
        {:else if filter.comparisonProvider.monthsFromNow === -1}
          last month
        {:else if filter.comparisonProvider.monthsFromNow === 1}
          next month
        {:else}
          {Math.abs(filter.comparisonProvider.monthsFromNow)}
          months
          {#if filter.comparisonProvider.monthsFromNow > 0}from now{:else}ago{/if}
        {/if}
      {:else if filter.comparisonProviderType === DateTimeComparisonProviderType.RelativeYears}
        <!-- Yay, space hacks-->
        {' '}
        {bound.name}
        of
        {#if filter.comparisonProvider.yearsFromNow === 0}
          this year
        {:else if filter.comparisonProvider.yearsFromNow === -1}
          last year
        {:else if filter.comparisonProvider.yearsFromNow === 1}
          next year
        {:else}
          {Math.abs(filter.comparisonProvider.yearsFromNow)}
          years
          {#if filter.comparisonProvider.yearsFromNow > 0}from now{:else}ago{/if}
        {/if}
      {:else if filter.comparisonProviderType === DateTimeComparisonProviderType.RelativeQuarters}
        <!-- Yay, space hacks-->
        {' '}
        {bound.name}
        of
        {#if filter.comparisonProvider.quartersFromNow === 0}
          this quarter
        {:else if filter.comparisonProvider.quartersFromNow === -1}
          last quarter
        {:else if filter.comparisonProvider.quartersFromNow === 1}
          next quarter
        {:else}
          {Math.abs(filter.comparisonProvider.quartersFromNow)}
          quarters
          {#if filter.comparisonProvider.quartersFromNow > 0}from now{:else}ago{/if}
        {/if}
      {/if}
    {/if}
  </strong>
{/if}

<script context="module">
  import { buildOption } from 'services/option-builder.js'
  import { DateTimeComparison, DateTimeComparisonProviderType } from 'config/enums.js'
  import DatePicker from 'components/fields/DatePicker.svelte'
  import FilterTypeDateNumber from 'components/filter-types/FilterTypeDate.Number.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import InputRadioGroup from 'components/fields/InputRadioGroup.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import onInteract from 'decorators/on-interact.js'
  import toOrdinalNumber from 'services/to-ordinal-number.js'
  import validator from 'services/validator.js'

  export const meta = {
    label: 'Date',
    icon: 'calendar',
    canHaveMultiple: true,

    encode(writer, appliedMeta, config) {
      writer.writeArg(config.comparisonProvider.comparison)
      writer.writeArg(config.comparisonProviderType)
      if (appliedMeta.exactDateOnly) {
        writer.writeArg(config.comparisonProvider.date)
        return
      }
      switch (config.comparisonProviderType) {
        case DateTimeComparisonProviderType.Parameterless:
          writer.writeArg(config.comparisonProvider.isNull)
          break
        case DateTimeComparisonProviderType.ExactDate:
          writer.writeDate(config.comparisonProvider.date)
          break
        case DateTimeComparisonProviderType.RelativeDays:
          writer.writeArg(config.comparisonProvider.daysFromToday)
          break
        case DateTimeComparisonProviderType.RelativeWeeks:
          writer.writeArg(config.comparisonProvider.dayOfWeek)
          writer.writeArg(config.comparisonProvider.weeksFromNow)
          break
        case DateTimeComparisonProviderType.RelativeMonths:
          writer.writeArg(config.comparisonProvider.dayOfMonth)
          writer.writeArg(config.comparisonProvider.monthsFromNow)
          break
        case DateTimeComparisonProviderType.RelativeQuarters:
          writer.writeArg(config.comparisonProvider.rangeBound)
          writer.writeArg(config.comparisonProvider.quartersFromNow)
          break
        case DateTimeComparisonProviderType.RelativeYears:
          writer.writeArg(config.comparisonProvider.rangeBound)
          writer.writeArg(config.comparisonProvider.yearsFromNow)
          break
      }
    },

    decode(reader, appliedMeta) {
      const config = appliedMeta.create()
      config.comparisonProvider.comparison = reader.readInt()
      config.comparisonProviderType = reader.readInt()
      switch (config.comparisonProviderType) {
        case DateTimeComparisonProviderType.Parameterless:
          config.comparisonProvider.isNull = reader.readBool()
          break
        case DateTimeComparisonProviderType.ExactDate:
          config.comparisonProvider.date = reader.readDate(true)
          break
        case DateTimeComparisonProviderType.RelativeDays:
          config.comparisonProvider.daysFromToday = reader.readInt()
          break
        case DateTimeComparisonProviderType.RelativeWeeks:
          config.comparisonProvider.dayOfWeek = reader.readInt()
          config.comparisonProvider.weeksFromNow = reader.readInt()
          break
        case DateTimeComparisonProviderType.RelativeMonths:
          config.comparisonProvider.dayOfMonth = reader.readInt()
          config.comparisonProvider.monthsFromNow = reader.readInt()
          break
        case DateTimeComparisonProviderType.RelativeQuarters:
          config.comparisonProvider.rangeBound = reader.readInt()
          config.comparisonProvider.quartersFromNow = reader.readInt()
          break
        case DateTimeComparisonProviderType.RelativeYears:
          config.comparisonProvider.rangeBound = reader.readInt()
          config.comparisonProvider.yearsFromNow = reader.readInt()
          break
      }
      return config
    },

    create() {
      return {
        comparisonProviderType: DateTimeComparisonProviderType.ExactDate,
        comparisonProvider: {
          comparison: DateTimeComparison.IsBefore,
          date: null,
          dayOfMonth: 1,
          dayOfWeek: 1,
          daysFromToday: 0,
          isNull: null,
          monthsFromNow: 0,
          quartersFromNow: 0,
          rangeBound: RangeBound.Start,
          weeksFromNow: 0,
          yearsFromNow: 0,
        },
      }
    },

    validate(f /*, allFilters*/) {
      if (f.comparisonProviderType === DateTimeComparisonProviderType.ExactDate && !validator.date(f.comparisonProvider.date)) return false
      return true
    },
  }

  const baseDateMeta = meta.create()
  export const buildQuickFilterConfig = date => ({
    ...baseDateMeta,
    comparisonProviderType: DateTimeComparisonProviderType.ExactDate,
    comparisonProvider: {
      ...baseDateMeta.comparisonProvider,
      comparison: DateTimeComparison.Is,
      date,
    },
  })

  const comparisonRadioOptions = Object.values(DateTimeComparisonProviderType).filter(pt => pt !== DateTimeComparisonProviderType.Parameterless)
</script>

<script>
  import { RangeBound } from 'config/enums.js'

  export let filter
  export let label
  export let editing = false
  export let includeNoComparison = false
  export let includeNullComparisons = false
  export let editingClass = 'mb1'
  export let formGroupClass = null
  export let appliedMeta

  /* Backend has a few different expected shapes for `filter`. For example: {
      comparisonProviderType: DateTimeComparisonProviderType.RelativeDays,
      comparisonProvider: {
        comparison: DateTimeComparison.IsAfter,
        daysFromToday: 0
      }
    }

    But also: {
      comparisonProviderType: DateTimeComparisonProviderType.Parameterless,
      comparisonProvider: {
        isNull: true|false|null
      }
    }

    We want our UI to have a dropdown handle both shapes, so we need an enum that supports both
    that the backend doesn't need to know about.
  */

  const buildComparisonOption = (name, value, isCustomizable = true, className, isDisplayOnly) => ({
    name,
    value,
    isCustomizable,
    class: className || null,
    isDisplayOnly, // we use our "OrEqual" options programmatically, but we don't want to display them to the user and overwhelm them with options
  })
  const comparisonOptions = []

  if (includeNoComparison) {
    comparisonOptions.push(buildComparisonOption('is anything', null, false))
  }
  if (includeNullComparisons) {
    comparisonOptions.push(
      buildComparisonOption('is specified', false, false),
      buildComparisonOption('is unspecified', true, false, 'end-of-opt-group')
    )
  }
  comparisonOptions.push(
    buildComparisonOption('is before', DateTimeComparison.IsBefore),
    buildComparisonOption('is before or equal to', DateTimeComparison.IsBeforeOrEqual, true),
    buildComparisonOption('is', DateTimeComparison.Is),
    buildComparisonOption('is after', DateTimeComparison.IsAfter),
    buildComparisonOption('is after or equal to', DateTimeComparison.IsAfterOrEqual, true)
  )
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map((d, i) => ({ value: i + 1, name: d })) // server is not 0-based days
  const daysOfMonth = []
  for (let i = 1; i <= 31; i++) daysOfMonth.push(buildOption(toOrdinalNumber(i), i))
  const rangeBoundOptions = [buildOption('start', RangeBound.Start), buildOption('end', RangeBound.End)]

  let quarterRangeBound =
    filter.comparisonProviderType === DateTimeComparisonProviderType.RelativeQuarters ? filter.comparisonProvider.rangeBound : RangeBound.Start
  let yearRangeBound =
    filter.comparisonProviderType === DateTimeComparisonProviderType.RelativeYears ? filter.comparisonProvider.rangeBound : RangeBound.Start

  $: setRangeBoundIf(quarterRangeBound, DateTimeComparisonProviderType.RelativeQuarters)
  $: setRangeBoundIf(yearRangeBound, DateTimeComparisonProviderType.RelativeYears)

  function setRangeBoundIf(rangeBound, type) {
    if (editing && filter.comparisonProviderType === type) {
      filter.comparisonProvider.rangeBound = rangeBound
    }
  }

  function getComparisonOptionValue(filter) {
    return filter.comparisonProviderType === DateTimeComparisonProviderType.Parameterless
      ? filter.comparisonProvider.isNull
      : filter.comparisonProvider.comparison
  }

  let lastParameteredProviderType =
    filter.comparisonProviderType === DateTimeComparisonProviderType.Parameterless
      ? DateTimeComparisonProviderType.RelativeDays
      : filter.comparisonProviderType
  let comparisonOptionValue = getComparisonOptionValue(filter)

  $: if (editing) {
    updateFilter(comparisonOptionValue)
  }

  // This needs to be a separate block so updateFilter won't get called when filter changes.
  $: if (!editing) {
    updateFilter(getComparisonOptionValue(filter))
  }

  function updateFilter(comparisonOptionValue) {
    if ([null, true, false].includes(comparisonOptionValue)) {
      lastParameteredProviderType =
        filter.comparisonProviderType === DateTimeComparisonProviderType.Parameterless ? lastParameteredProviderType : filter.comparisonProviderType
      filter.comparisonProvider.isNull = comparisonOptionValue
      setComparisonType(DateTimeComparisonProviderType.Parameterless)
    } else {
      filter.comparisonProvider.comparison = comparisonOptionValue
      setComparisonType(lastParameteredProviderType)
    }
  }

  $: selectedComparison = comparisonOptions.find(o => o.value === getComparisonOptionValue(filter))
  $: weekPart = daysOfWeek.find(o => o.value === filter.comparisonProvider.dayOfWeek)
  $: monthPart = daysOfMonth.find(o => o.value === filter.comparisonProvider.dayOfMonth)
  $: bound = rangeBoundOptions.find(o => o.value === filter.comparisonProvider.rangeBound) || rangeBoundOptions[0]

  $: setComparisonType(filter.comparisonProviderType)
  function setComparisonType(type) {
    if (!editing) return
    filter.comparisonProviderType = type

    // set defaults if not set
    const cp = filter.comparisonProvider
    if (type !== DateTimeComparisonProviderType.Parameterless) cp.isNull ??= null
    if (type !== DateTimeComparisonProviderType.ExactDate) cp.date = null
    if (type !== DateTimeComparisonProviderType.RelativeDays) cp.daysFromToday ??= 0

    if (type !== DateTimeComparisonProviderType.RelativeWeeks) {
      cp.weeksFromNow ??= 0
      cp.dayOfWeek ??= 1
    }
    if (type !== DateTimeComparisonProviderType.RelativeMonths) {
      cp.monthsFromNow ??= 0
      cp.dayOfMonth ??= 1
    }
    if (type !== DateTimeComparisonProviderType.RelativeYears) cp.yearsFromNow ??= 0
    if (type !== DateTimeComparisonProviderType.RelativeQuarters) cp.quartersFromNow ??= 0

    // we don't use rangeBound with weeks/months on UI--user can simply pick first or last option
    switch (type) {
      case DateTimeComparisonProviderType.RelativeWeeks:
      case DateTimeComparisonProviderType.RelativeMonths:
        cp.rangeBound = null
        break
      case DateTimeComparisonProviderType.RelativeQuarters:
        cp.rangeBound = quarterRangeBound
        break
      case DateTimeComparisonProviderType.RelativeYears:
        cp.rangeBound = yearRangeBound
        break
      default:
        cp.rangeBound ??= RangeBound.Start
    }
  }
</script>

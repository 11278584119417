{#if editing}
  <FormGroup>
    <InputToggle name="is-skipped" bind:checked={filter.isSkipped}>
      {#if filter.isSkipped}
        <Icon name="search" lg class="text-gray" />
      {:else}
        <Icon name="search" lg class="text-danger" />
      {/if}
      Skipped
    </InputToggle>
  </FormGroup>
{:else if filter.isSkipped}
  <Icon name="search" lg class="text-gray" />
  Skipped
{:else}
  <Icon name="search" lg class="text-danger" />
  <strong>NOT</strong> skipped
{/if}

<script context="module">
  import Icon from 'components/Icon.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    type: FilterType.IsSkipped,
    label: 'Skipped',
    labelApplied: 'is skipped',
    icon: 'skipped',
    iconClass: 'color-text-dark-green',
    create() {
      return { isSkipped: true }
    },

    validate() {
      return true
    },

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.isSkipped)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.isSkipped = reader.readBool()
      return config
    },
  }
</script>

<script>
  export let editing = false
  export let filter
</script>

<div class="flex-column g1{className ? ` ${className}` : ''}">
  <h3 class="mb05">Assign visible titles</h3>
  <span class="mb1">These titles will appear next to the user’s name and profile photo on the opportunity and associated rotations.</span>
  {#each options as option, i}
    <InputCheckbox bind:checked={option.selected} name={'capacity-staff-title-checkbox-' + i} labelClass="normal">
      <InputText
        name={'capacity-staff-title-' + i}
        bind:value={option.description}
        placeholder={option.placeholder}
        maxlength={100}
        displayMaxLength={false}
        autocomplete="off"
      />
    </InputCheckbox>
  {/each}
  <div>
    <IconTextLink text="Add additional title" onClick={addTitle} id="add-capacity-staff-btn" />
  </div>
</div>

<script>
  import { removeDuplicatesAndSort } from 'services/array-utils.js'
  import IconTextLink from 'components/IconTextLink.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import InputText from 'components/fields/InputText.svelte'

  export let selectedTitles = []
  export let capacity = {}
  export let staffId
  let className = ''
  export { className as class }

  let options = []

  $: capacity, setOptions()
  $: options, updateSelectedTitles()

  function setOptions() {
    let titles = []
    if (capacity.staff) {
      for (const staff of capacity.staff) {
        if (staff.titles?.length) {
          titles = [...titles, ...staff.titles]
        }
      }
    }

    titles = removeDuplicatesAndSort(titles)

    // list all opportunity staff titles
    for (const title of titles) {
      if (!options.some(o => o.description === title)) {
        options.push({
          description: title,
          placeholder: 'Other',
          selected: staffId ? !!capacity.staff.find(s => s.staffId == staffId)?.titles.includes(title) : false,
        })
      }
    }
    if (!options.some(o => !o.description)) options.push({ placeholder: 'Other' })
    updateSelectedTitles()
  }

  function updateSelectedTitles() {
    selectedTitles = options.filter(t => t.selected && t.description).map(t => t.description)
  }

  function addTitle() {
    options = [...options, { placeholder: 'Other' }]
  }
</script>

{#if !validator.empty(step.descriptionHtml)}
  <div class="mb1">
    <SafeHtml value={step.descriptionHtml} />
  </div>
{/if}
{#if step.files.length}
  <div class="mb1">
    {#each step.files as f}
      <div><DownloadTextLink file={f} /></div>
    {/each}
  </div>
{/if}

<script>
  import DownloadTextLink from 'components/DownloadTextLink.svelte'
  import SafeHtml from 'components/SafeHtml.svelte'
  import validator from 'services/validator.js'

  export let step
</script>

<QuickDropdown
  autoSized
  dataTest="applied-filter-{w.type}"
  btnClass="btn btn-sm btn-outline-default{simulation?.action === 'set' && !isPlaceholder ? ' btn-pulsate-danger' : ''}{isSimulationTarget ||
  isPlaceholder
    ? ' btn-pulsate-success'
    : ''}"
  invalid={!w.valid}
  hasWarnings={w.hasUnappliedChanges}
  isOpen={filter === filterBeingEdited}
  on:open={() => (filterBeingEdited?.isNew ? null : open(filter))}
  on:close={() => closeDropdown(wrappedFilter)}
  dropdownClass={meta.dropdownClass ?? 'p2 below'}
  btnTitle={w.valid
    ? w.hasUnappliedChanges
      ? 'This filter has unapplied changes'
      : editable
      ? 'Edit filter'
      : 'Editing this filter is not allowed'
    : 'This filter is invalid'}
  disabled={!editable}
>
  <!--consider putting a locked icon on if not editable. Or do like it's done in the StudentPicker and display those filters not in the filter line, but instead as a slot (I think) below the user-editable filters... more of a read-only version without a button style to them.-->
  <span slot="label">
    {#if !meta.hideLabelIcon}
      <Icon
        name={meta.icon}
        class={w.valid && !w.hasUnappliedChanges ? meta.iconClass : 'text-default'}
        style={w.valid && !w.hasUnappliedChanges ? meta.iconStyle : null}
        {...meta.iconProps ?? {}}
      />
    {/if}
    <svelte:component this={meta.component} filter={_filter.config} appliedMeta={meta} filterOptions={_filterOptions} {interceptor} />
  </span>

  {#if filter && filter === filterBeingEdited}
    {#if meta.configurable && meta.editLabel !== null}<label for="filter-editing">{meta.editLabel ?? meta.label}</label>{/if}
    <Form on:submit={apply}>
      {#if meta.configurable}
        <svelte:component this={meta.component} filterOptions={_filterOptions} bind:filter={clone.config} editing appliedMeta={meta} {interceptor} />
      {/if}

      <div class="flex-row flex-align-center g05{meta.btnBarClass ? ` ${meta.btnBarClass}` : ''}">
        {#if meta.configurable}
          <Btn type="submit" class="btn-primary btn-sm" dataTest="apply-filter" icon="check">Apply</Btn>
        {/if}

        {#if !filterBeingEdited.isNew && editable}
          <Btn class="btn-danger btn-sm" on:click={() => remove(true)} title="Remove this filter" dataTest="remove-filter" icon="delete">
            Remove filter
          </Btn>
        {/if}

        {#if meta.configurable}
          <Btn class="btn-default btn-sm" on:click={cancel} dataTest="cancel-filter">Cancel</Btn>
        {/if}
      </div>
    </Form>
  {/if}
</QuickDropdown>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Form from 'components/Form.svelte'
  import Icon from 'components/Icon.svelte'
  import QuickDropdown from 'components/QuickDropdown.svelte'

  export let wrappedFilter
  export let interceptor = null
  export let filterBeingEdited = null
  export let filterClones
  export let filterOptions
  export let simulation
  export let isPlaceholder = false
  // Parent functions
  export let open = _.noop
  export let apply = _.noop
  export let remove = _.noop
  export let cancel = _.noop
  export let closeDropdown = _.noop

  let _filterOptions = filterOptions

  $: w = wrappedFilter
  $: meta = w.meta
  $: filter = w.filter
  $: clone = filterClones.get(w.filter) ?? {}
  $: isSimulationTarget = simulation?.action === 'addExisting' && simulation.filter === filter
  $: _filter = isSimulationTarget ? buildSimulationFilter(filter, simulation) : filter
  $: simulation, _filter, filterOptions, setFilterOptions()
  $: editable = interceptor == null || interceptor.canRemove !== false

  async function setFilterOptions() {
    const options = isSimulationTarget ? [simulation.filterOption, ...filterOptions] : filterOptions
    _filterOptions = (await interceptor?.addFilterOptions?.(options)) ?? options
  }

  function buildSimulationFilter(filter, simulation) {
    const clone = _.clone(filter)
    return {
      ...clone,
      config: {
        ...clone.config,
        simulatedValue: simulation.configValue,
        [simulation.configKey]: [simulation.configValue, ...clone.config[simulation.configKey]],
      },
    }
  }
</script>

<!--
  note the popperOptions for this tooltip is there to resolve a placement issue caused by
  `overflow: auto` of this anchor's parent element.
  Source of fix: https://atomiks.github.io/tippyjs/#all-options-tooltips-inside-a-scrollable-container
-->
<a
  class="sub-nav-item{className ? ` ${className}` : ''}"
  class:active
  use:tip={title ? { content: title, options: { placement: tooltipPlacement } } : null}
  {href}
  {noroute}
  on:click
  data-test={dataTest == null ? null : dataTest}
>
  <div class="sub-nav-decorator">
    {#if loading}
      <Spinner />
    {:else}
      {#if icon}
        <Icon name={icon} class={iconClass} fw />
      {:else if imgName}
        <ProfilePic name={imgName} initials={imgInitials} id={imgId} src={img} small />
      {/if}

      {#if badgeColor}
        <Badge xs color={badgeColor} count={badgeCount} name={badgeName} />
      {/if}
    {/if}
  </div>
  <div class="sub-nav-text full-width">
    <slot />
  </div>
</a>

<script context="module">
  export function isNavItemActive(isActive, href, pathname) {
    // if calling code explicitly marks it active or not active, use that
    if (isActive === false || isActive === true) return isActive

    const requested = (pathname || '/').toLowerCase()

    // isActive can also be a regex
    if (isActive instanceof RegExp) return isActive.test(requested)

    // if url starts with href, good enough
    const _href = (href || '/').toLowerCase()
    return requested !== '/' && requested.indexOf(_href) === 0
  }
</script>

<script>
  import Badge from 'components/Badge.svelte'
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'
  import { getLocation } from 'stores/req.js'
  import ProfilePic from './ProfilePic.svelte'
  import Spinner from './Spinner.svelte'

  const location = getLocation()

  let className = ''
  export let imgName = null
  export let imgId = null
  export let imgInitials = null
  export { className as class }
  export let href = ''
  export let img = null
  export let icon = null
  export let iconClass = null
  export let noroute = null // set to true if you want the anchor to hit the server
  export let badgeColor = null
  export let badgeCount = null
  export let badgeName = null
  export let tooltipPlacement = 'right'

  export let dataTest = null
  export let title = null
  export let isActive = null
  export let loading = false

  $: active = isNavItemActive(isActive, href, $location.pathname)
</script>

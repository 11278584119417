<div class="color-picker-choices p1">
  {#each colors as color, i (color)}
    <div
      class="color-choice color-bg-{color} contrast-shadow"
      class:selected={value === color}
      data-test="color-choice-{color}"
      use:tip={tipForColor(i, color)}
      on:click={() => select(color)}
    >
      {#if value === color}
        <Icon name="check" class="margin-auto" />
      {/if}
    </div>
  {/each}
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'
  import colorService from 'services/color-service.js'

  export let value
  export let select

  $: colors = colorService.getAllColors()

  function tipForColor(i, color) {
    return {
      content: colorService.humanizeColor(color),
      options: {
        interactive: false,
        placement: i < 7 ? 'top' : 'bottom',
      },
    }
  }
</script>

<style lang="scss">
  .color-picker-toggle:focus {
    border: none;
    outline: none;
  }

  .color-choice {
    cursor: pointer;
    height: 36px;
    width: 36px;
    display: flex;
    border-radius: 4px;
  }

  .color-picker-choices {
    display: grid;
    grid-template-columns: repeat(7, 35px);
    gap: 10px;
  }
</style>

{#if editing}
  <InputRadioGroup
    name="capacity-rotations-simple"
    {options}
    bind:value={filter.option}
    firstOptionClass="mt0"
    lastOptionClass="mb2"
    class="flex-row flex-align-center g1"
    let:option
  >
    <Icon
      lg
      name={iconByOptionValue[option.value]}
      class="color-text-steel"
      style={iconColorByOptionValue[option.value] ? `--path-2-color: var(--${iconColorByOptionValue[option.value]})` : null}
    />
    {option.name}

    <svelte:fragment slot="after-label">
      {#if option.value === MatchConfirmationOption.HasUnconfirmedMatches}
        <Help tipOptions={{ maxWidth: Infinity, placement: 'right' }}>
          <div class="flex-row flex-align-center flex-wrap g05">
            <span>Includes opportunities with</span>
            <Icon lg name="infinity" />
            <span><strong>Unlimited</strong> rotations</span>
          </div>
        </Help>
      {/if}
    </svelte:fragment>
  </InputRadioGroup>
{:else}
  <Icon
    lg
    name={iconByOptionValue[filter.option]}
    class="color-text-steel"
    style={iconColorByOptionValue[filter.option] ? `--path-2-color: var(--${iconColorByOptionValue[filter.option]})` : null}
  />
  {options.find(o => o.value === filter.option).name}
{/if}

<script context="module">
  import { buildOption } from 'services/option-builder.js'
  import { FilterType, MatchConfirmationOption } from 'config/enums.js'

  export const meta = {
    type: FilterType.CapacityMatchConfirmation,
    label: 'Rotation confirmation',
    icon: 'slots-question',
    iconClass: 'color-text-steel',
    iconStyle: `--path-2-color: var(--theme-info)`,
    hideLabelIcon: true,
    editLabel: null,

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.option)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.option = reader.readInt()
      return config
    },

    create() {
      return {
        option: MatchConfirmationOption.HasUnconfirmedMatches,
      }
    },

    validate(filter) {
      return filter.option != null
    },
  }

  const options = [
    buildOption('Has unconfirmed rotations', MatchConfirmationOption.HasUnconfirmedMatches),
    buildOption('Has confirmed rotations', MatchConfirmationOption.HasConfirmedMatches),
  ]

  const iconByOptionValue = {
    [MatchConfirmationOption.HasUnconfirmedMatches]: 'slots-exclamation',
    [MatchConfirmationOption.HasConfirmedMatches]: 'slots-check',
  }

  const iconColorByOptionValue = {
    [MatchConfirmationOption.HasUnconfirmedMatches]: 'theme-danger',
    [MatchConfirmationOption.HasConfirmedMatches]: 'theme-success',
  }
</script>

<script>
  import Help from 'components/Help.svelte'
  import Icon from 'components/Icon.svelte'
  import InputRadioGroup from 'components/fields/InputRadioGroup.svelte'

  export let filter
  // export let filterOptions
  export let editing = false
  // export let appliedMeta
</script>

<!--consider renaming to be FilterTypeEnumeration, since this isn't just used for Status enumerations (1 case in point, FilterType.StepTypes)-->
{#if editing}
  <FormGroup>
    <InputCheckboxGroup name="statuses" options={_options} valueSelector={o => o.key} bind:value={filter[filterProp]} let:option autofocusFirstItem>
      <EnumIconLabel config={option} class="normal" />
    </InputCheckboxGroup>
  </FormGroup>
{:else}
  {appliedMeta.labelApplied ?? appliedMeta.label}
  {#if filter?.[filterProp]?.length}
    <FriendlyList or punctuation boldPunctuation={false} toggleable={false} items={filter[filterProp]} let:item={s}>
      <EnumIconLabel config={helper.get(s)} class="strong" />
    </FriendlyList>
  {:else}
    …?
  {/if}
{/if}

<script context="module">
  export const meta = {
    filterProp: 'statuses',
    labelApplied: 'Status',

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config[appliedMeta.filterProp])
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config[appliedMeta.filterProp] = reader.readIntArray()
      return config
    },

    create(appliedMeta) {
      return {
        [appliedMeta.filterProp]: [],
      }
    },

    validate(filter, filters, appliedMeta) {
      return filter[appliedMeta.filterProp]?.length > 0
    },
  }
</script>

<script>
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import InputCheckboxGroup from 'components/fields/InputCheckboxGroup.svelte'
  import { getEnumerationOptions } from 'services/enemueration-helpers-utils.js'
  import EnumIconLabel from 'components/EnumIconLabel.svelte'

  export let filter
  export let editing = false
  export let appliedMeta
  export let options

  $: filterProp = appliedMeta.filterProp
  $: helper = appliedMeta.helper
  $: enumeration = appliedMeta.enumeration
  $: _options = options ?? getEnumerationOptions(enumeration, helper)
</script>

<div class="unauthorized-container">
  <div class="back-to">
    <IconTextLink class="text-white" icon="back" text={backText} href={backHref} onClick={onBackClick} />
  </div>
  <div class="body">
    <div class="logo-link">
      <a href="/">
        <img src="/images/logo.png?v=2" class="logo" alt="" />
      </a>
    </div>
    <slot />
    <p class="bottom-links">
      <a href="https://www.cliniciannexus.com">Learn more about our platform</a>
      <a href="/support/contact">Help</a>
    </p>
  </div>
</div>

<script>
  import { createEventDispatcher } from 'svelte'
  import IconTextLink from 'components/IconTextLink.svelte'

  const dispatch = createEventDispatcher()

  export let backText = 'Back to site'
  export let backHref = 'https://www.cliniciannexus.com'

  function onBackClick() {
    dispatch('backClick')
  }
</script>

<style lang="scss">
  .unauthorized-container {
    background: linear-gradient(142.41deg, #44c5b4 0%, #227774 100%);
    font-size: 16px;
    width: 100vw;
  }
  .back-to {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    margin-top: 50px;
    margin-left: 50px;
  }
  .body {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    padding: 36px;
    box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
    border-radius: 12px;
    // min-height: 470px;
    height: fit-content;
    width: 474px;

    /*eslint-disable-next-line no-svelte-global reason: grandfathered-in/un-assessed*/
    :global(h2), :global(p) {
      text-align: center;
    }
  }

  .logo-link {
    margin: 0 0 40px;
    text-align: center;
  }
  .logo {
    height: 65px;
  }
  .bottom-links {
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    margin-top: 20px;
    margin-bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  @media only screen and (max-width: 450px) {
    .body {
      min-height: 100vh;
      width: 100vw;
      border-radius: 0;
      padding: 36px 17px;
    }
    .logo-link {
      margin: 40px 0 60px;
    }
    .bottom-links {
      margin-top: 30px;
    }
  }
</style>

<div class="options g3 p3">
  <div class={optionClasses}>
    <Icon x3 name="user" />
    <h3 class="text-primary">Opportunity for individuals</h3>
    <div>This opportunity accepts individual students (whether they apply themselves or are scheduled by a coordinator).</div>
    <div>Examples include job shadows, preceptorships, etc.</div>
    <div class="flex-grow pt2" />
    <Btn class="btn-primary btn-lg full-width" dataTest="start-individual-capacity" on:click={() => (allowGroups = false)}
      >Start individual opportunity</Btn
    >
  </div>

  <div class={optionClasses}>
    <Icon x3 name="users" />
    <h3 class="text-primary">Opportunity for groups</h3>
    <div>
      This opportunity accepts groups of students scheduled together by a coordinator, or individual students who apply and are grouped together
      later.
    </div>
    <div>Examples include group preceptorships, nursing rotations, etc.</div>
    <div class="flex-grow pt2" />
    <Btn class="btn-primary btn-lg full-width" dataTest="start-group-capacity" on:click={() => (allowGroups = true)}>Start group opportunity</Btn>
  </div>
</div>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'

  export let allowGroups

  const optionClasses = 'option flex-justify-center flex-align-center p3 rounded-lg flex-column g1 self-center full-height text-center'
</script>

<style lang="scss">
  @import '../../css/helpers';

  .options {
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
  }

  .option {
    background: #e9e9e9;
  }
</style>

{#if wasExplicitlyDeleted}
  This location has been deleted; this availability window won’t be usable within a rotation.
{:else if wasEffectivelyDeleted}
  This location has been indirectly deleted because an ancestor location has been deleted; this availability window won’t be usable within a rotation.
{/if}

<script>
  export let wasExplicitlyDeleted
  export let wasEffectivelyDeleted
</script>

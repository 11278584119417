{#if show}
  <Modal on:close={cancel} class={className} {color}>
    <slot name="title" slot="title" />

    <div class="modal-body">
      <slot />

      {#if mustType != null}
        <Form on:submit={confirm}>
          <MustType bind:this={mustTypeComponent} bind:valid={mustTypeValid} expectedValue={mustType} autofocus />
        </Form>
      {/if}
    </div>

    <div class="modal-footer">
      <Btn
        autofocus={mustType == null}
        class={confirmClass}
        icon={confirmIcon}
        disabled={!valid || disabled}
        on:click={confirm}
        dataTest="confirm-yes">{confirmLabel}</Btn
      >
      {#if cancelLabel}
        <Btn class={cancelClass} icon={confirmIcon ? 'close' : null} on:click={cancel} dataTest="confirm-no">{cancelLabel}</Btn>
      {/if}
    </div>
  </Modal>
{/if}

<script>
  import { createEventDispatcher } from 'svelte'
  import Form from 'components/Form.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import MustType from 'components/MustType.svelte'
  import Modal from 'components/Modal.svelte'

  const dispatch = createEventDispatcher()

  export let show = false
  export let confirmLabel = 'Yes'
  export let confirmClass = 'btn-primary'
  export let confirmIcon = null
  export let cancelLabel = 'Cancel'
  export let cancelClass = 'btn-default'
  export let mustType = null
  export let disabled = false
  let className = null
  export { className as class }
  export let color = null
  export let autoClose = true

  let mustTypeComponent
  let mustTypeValid

  $: valid = mustType == null || mustTypeValid

  const cancel = () => fireAndClose('cancel')
  const confirm = () => {
    if (valid) fireAndClose('confirm')
    else if (mustTypeComponent) mustTypeComponent.focus()
  }

  function fireAndClose(event) {
    if (autoClose) show = false
    dispatch(event)
  }
</script>

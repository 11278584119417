{#if allowSelectNull}
  <PickerSlot {multiple} value={null} {buttonModalPicker} {lg} bind:valueTemp {dataTest}>
    <slot name="header" slot="header">
      {#if icon}
        <Icon {lg} name={icon} class={iconClass} autoColor={iconAutoColor} />
      {/if}
      <svelte:element this={lg ? 'h3' : 'h4'} class="leading-none m0 em normal">{text}</svelte:element>
    </slot>
  </PickerSlot>
{/if}

<script>
  import Icon from 'components/Icon.svelte'
  import PickerSlot from 'components/PickerSlot.svelte'

  export let valueTemp
  export let allowSelectNull
  export let text
  export let multiple
  export let buttonModalPicker
  export let icon
  export let iconClass = null
  export let iconAutoColor = false
  export let dataTest = null
  export let lg = true
</script>

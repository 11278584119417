{#if row == null}
  <th class={cssClass} style={widthStyle} data-test={grid.name && name ? `${grid.name}-column-${name}` : null}>
    <slot name="header">{header ?? ''}</slot>
    {#if tooltip}
      <Help>
        <SafeHtml value={tooltip} />
      </Help>
    {/if}
  </th>
{:else if rowspan == null || rowspan > 0}
  <td {rowspan} class={cssClass} style={widthStyle} data-test={name && grid.rowKeySelector ? `${name}-${grid.rowKey(row)}` : null}>
    {#if metadata.isFirstColumn && metadata.indent != null && !metadata.noExpandableRows}
      <div class="flex-row{treeControlClass ? ` ${treeControlClass}` : ''}">
        <span style="width: calc({metadata.indent} * var(--grid-tree-indent, 24px)" />
        <Btn
          dataTest={grid.name && grid.rowKeySelector ? `${grid.name}-toggle-${grid.rowKey(row)}` : null}
          clearBtnStyling
          class="text-gray grid-row-toggle py1 px05{metadata.hasChildren ? '' : ' invisible'}"
          icon={metadata.hasChildren ? (metadata.isExpanded ? 'chevron-down' : 'chevron-right') : 'minus'}
          stopPropagation
          on:click={metadata.hasChildren ? () => grid.toggleExpanded(row) : null}
        />
        <div>
          <slot>
            <DefaultSlot {row} {name} {type} {typeProps} />
          </slot>
        </div>
      </div>
    {:else}
      <slot>
        <DefaultSlot {row} {name} {type} {typeProps} />
      </slot>
    {/if}
  </td>
{/if}

<script>
  import { getContext, onDestroy } from 'svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import DefaultSlot from 'components/GridCol.DefaultSlot.svelte'
  import Help from 'components/Help.svelte'
  import SafeHtml from 'components/SafeHtml.svelte'

  export let name = null
  export let header = ''
  export let row = null
  export let rowspan = null
  let className = null
  export { className as class }
  export let thClass = null
  export let treeControlClass = 'g1'
  export let width = null
  $: widthStyle = width ? `width: ${width}` : null

  // changes the default renderer behavior
  export let type = null
  export let typeProps = {}
  export let tooltip = null

  const grid = getContext('grid')

  $: cssClass = row == null ? thClass ?? grid.thClass : grid.cellClassSelector(row, className)

  // If someone stuffs a <GridCol> somewhere besides a <Grid>'s slot, go kaboom on them.
  const headerKey = row == null ? {} : null
  $: if (headerKey) grid.addColumn(name, headerKey)
  $: metadata = row ? grid.getCellMetadata(name, row) : null

  onDestroy(() => {
    if (headerKey) grid.removeColumn(headerKey)
  })
</script>

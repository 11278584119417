<div class="em">{totalLoggedHours} hours logged</div>
{#if !minimal}
  {#if totalExpectedHours != null}
    <div class="em">
      {pluralCount('hour', totalExpectedHours)} required
    </div>
  {/if}
  {#if totalScheduledHours != null}<div class="em">Scheduled for {pluralCount('hour', totalScheduledHours)}</div>{/if}
  {#if totalHoursLoggedPercent != null}
    <div class="progress">
      <div class="progress-bar bg-success" role="progressbar" style="width: {totalHoursLoggedPercent}%">{totalHoursLoggedPercent}%</div>
    </div>
  {/if}
{/if}

<script>
  import { percent } from 'services/math-service.js'
  import { pluralCount } from 'services/string-utils.js'

  export let totalLoggedHours
  export let totalExpectedHours
  export let totalScheduledHours
  export let minimal = false

  $: denominator = totalExpectedHours ?? totalScheduledHours
  $: totalHoursLoggedPercent = totalLoggedHours != null && denominator ? percent(totalLoggedHours, denominator).toFixed(0) : null
</script>

<style>
  .progress {
    background-color: #c7c3c3; /**bit darker gray than default since rendered on light gray on match modal*/
  }
</style>

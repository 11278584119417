<div class="flex-row flex-align-center g05">
  <Icon name="graduation-cap" class="color-text-teal" dataTest="graduation-cap-icon" />
  {#if student.studentGraduationDate}
    <span>
      {#if validator.inFuture(student.studentGraduationDate)}
        <span class="text-success">Graduating</span>
      {:else}
        <span class="text-warning">Graduated</span>
      {/if}
      {dateService.datestamp(student.studentGraduationDate)}
    </span>
    <small><HelpBlock inline class="m0">(<FromNow date={student.studentGraduationDate} />)</HelpBlock></small>
  {:else}
    <span data-test="grad-date-unknown">Graduation date unknown</span>
  {/if}
</div>

<script>
  import dateService from 'services/date-service.js'
  import FromNow from 'components/FromNow.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import validator from 'services/validator.js'

  export let student
</script>

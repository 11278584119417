import LocalStorageStore from './local-storage-store.js'

const selectedPersonaValues = LocalStorageStore('selected-persona-values', {})
let $selectedPersonaValues
selectedPersonaValues.subscribe(v => {
  $selectedPersonaValues = v
  trimSelectedPersonaValues()
})

function trimSelectedPersonaValues() {
  const maxKeys = 3 // arbitrary--pretty small amount of data per key, so this should be fine
  const keys = Object.keys($selectedPersonaValues)
  const len = keys.length
  if (len <= maxKeys) return

  // delete oldest one and set index of latest one
  const sorted = keys.sort((a, b) => $selectedPersonaValues[a].lastUsed - $selectedPersonaValues[b].lastUsed)
  const toDelete = sorted[0]
  delete $selectedPersonaValues[toDelete]

  selectedPersonaValues.set($selectedPersonaValues)
}

export function updateSelectedPersonaValues(userId, newOverrides) {
  const currentVal = $selectedPersonaValues[userId] ?? {}
  const newVal = { ...currentVal, ...newOverrides, lastUsed: Date.now() }
  $selectedPersonaValues[userId] = newVal
  selectedPersonaValues.set($selectedPersonaValues)
  return $selectedPersonaValues
}

export default selectedPersonaValues

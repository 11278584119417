<SortDropdown {sortOptions} bind:sortProperty bind:sortAscending {onChange} />

<script context="module">
  import { CapacityListProperty } from 'config/enums.js'
  import { buildSortOption } from 'components/SortDropdown.svelte'

  export const sortOptions = [
    buildSortOption(CapacityListProperty.Automatic, 'Automatic', 'automatic', {
      allowAscending: false,
      helpText: 'Opportunities we think you’ll want first, then everything else',
    }),
    buildSortOption(CapacityListProperty.OrganizationRelativeName, 'Organization name'),
    buildSortOption(CapacityListProperty.Name, 'Name'),
    buildSortOption(CapacityListProperty.ServiceName, 'Service name'),
    buildSortOption(CapacityListProperty.OpenForScheduling, 'Open for scheduling', 'bool'),
    buildSortOption(CapacityListProperty.PostToSearchPage, 'Posted on search page', 'bool'),
    buildSortOption(CapacityListProperty.AllowCoordinatorScheduling, 'Allows coordinator scheduling', 'bool'),
    buildSortOption(CapacityListProperty.StartDate, 'Start date', 'date'),
    buildSortOption(CapacityListProperty.EndDate, 'End date', 'date'),
  ]
</script>

<script>
  import SortDropdown from 'components/SortDropdown.svelte'

  export let sortProperty = CapacityListProperty.Automatic
  export let sortAscending = false
  export let onChange
</script>

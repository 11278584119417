{#if capacityGuest}
  <div data-test="matches-summary-{capacity.name}-{capacityGuest.guestOrgName}" class={className}>
    <div class="flex-column g05">
      {#if capacity.maxMatches}
        {#if capacityGuest.guaranteedMatchCountGranted === 0 && capacityGuest.matchCount >= capacityGuest.maxMatches}
          <div class="flex-row flex-align-center g05">
            <Icon lg autoColor name={iconName} class={iconClass} />
            <span class="small">None available</span>
          </div>
        {:else}
          <CapacityMatchesSummaryUnit unit="rotation" countable={capacityGuest.matchCount} afterUnit="used" />
          <CapacityMatchesSummaryUnit unit="guaranteed rotation" countable={capacityGuest.guaranteedMatchCountGranted} />
          {#if capacityGuest.maxMatches}
            <CapacityMatchesSummaryUnit unit="maximum rotation" countable={capacityGuest.maxMatches} />
          {:else}
            <CapacityMatchesSummaryUnit unlimitedMatches />
          {/if}
          <div class="flex-row flex-align-center g05">
            <Icon lg autoColor name={iconName} class={iconClass} />
            <span class="small" use:tip={useTooltip ? tooltip : null}>{capacityGuest.matchCountAvailable} available</span>
          </div>
        {/if}
      {:else}
        <div class="flex-column g05">
          <CapacityMatchesSummaryUnit unit="rotation" countable={capacityGuest.matchCount} afterUnit="used" />
          <CapacityMatchesSummaryUnit unlimitedMatches />
        </div>
      {/if}
    </div>

    <div class="small">
      {#if capacityGuest.desiredMatchCountConfirmedDateTime}
        <span class="text-success"><Icon name="check" /> Rotations were confirmed by school</span>
      {:else if capacityGuest.matchCountAvailable === 0}
        <span class="text-danger"><Icon name="slots-stop" /> Rotations limit was reached by school</span>
      {:else if capacityGuest.confirmDesiredMatchCountByDate}
        Rotations
        {#if confirmDesiredMatchCountByDateIsPast}
          should’ve been
        {:else}
          must be
        {/if}
        confirmed or released
        <FromNow
          showTooltip
          precision={false}
          tooltipIconClass="color-text-blue"
          tipOptions={{ theme: 'light-gray-scrollable' }}
          date={capacityGuest.confirmDesiredMatchCountByDate}
          class={confirmDesiredMatchCountByDateIsPast ? 'text-danger' : confirmDesiredMatchCountByDateIsSoon ? 'text-warning' : null}
        />
        by school.
      {/if}
    </div>

    {#if showActions}
      <div class="flex-row flex-align-center g05 mt05">
        <ConfirmMatchesBtn {capacity} {capacityGuest} {onUpdate} class="btn btn-sm btn-default" href={confirmMatchesHref} />
        <ReleaseMatchesBtn {capacity} {capacityGuest} {onUpdate} class="btn btn-sm btn-default" href={releaseMatchesHref} />
      </div>
    {/if}
  </div>

  <div class="hide">
    <div bind:this={tooltipElem}>
      {#if capacityGuest.poolMatchCountRemaining === 1}
        There is only one rotation
      {:else}
        There are only {readableNumber(capacityGuest.poolMatchCountRemaining)} rotations
      {/if}
      currently available in this opportunity, but because this school hasn’t used
      {#if capacityGuest.guaranteedMatchCountGranted === 1}
        their guaranteed rotation, it is still available to them.
      {:else}
        all of their
        {pluralCount('guaranteed rotation', capacityGuest.guaranteedMatchCountGranted)}, this school still has
        {pluralCount('rotation', capacityGuest.matchCountAvailable)} available.
      {/if}
    </div>
  </div>
{/if}

<script>
  import { PersonaType } from 'config/enums.js'
  import { pluralCount, readableNumber } from 'services/string-utils.js'
  import CapacityMatchesSummaryUnit from 'components/CapacityMatchesSummary.Unit.svelte'
  import ConfirmMatchesBtn from 'components/CapacityGuestView.ConfirmMatchesBtn.svelte'
  import FromNow from 'components/FromNow.svelte'
  import Icon from 'components/Icon.svelte'
  import persona from 'stores/persona.js'
  import ReleaseMatchesBtn from 'components/CapacityGuestView.ReleaseMatchesBtn.svelte'
  import tip from 'decorators/tip.js'

  export let capacity
  export let showActions = false
  export let confirmMatchesHref = null
  export let releaseMatchesHref = null
  export let capacityGuest = null
  export let onUpdate = _.noop
  let className = null
  export { className as class }

  let tooltipElem = null

  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: useTooltip = capacity?.maxMatches && capacityGuest && isHealth && capacityGuest.matchCountAvailable > capacityGuest.poolMatchCountRemaining
  $: tooltip = useTooltip && tooltipElem ? { content: tooltipElem, options: { interactive: true } } : null
  $: confirmDesiredMatchCountByDateIsPast =
    capacityGuest && capacityGuest.confirmDesiredMatchCountByDate && dayjs(capacityGuest.confirmDesiredMatchCountByDate).isBefore(dayjs())
  $: confirmDesiredMatchCountByDateIsSoon =
    capacityGuest &&
    capacityGuest.confirmDesiredMatchCountByDate &&
    dayjs(capacityGuest.confirmDesiredMatchCountByDate).isBefore(dayjs().add(3, 'days'))

  $: iconClass =
    capacity.maxMatches == null ? 'text-info' : capacity.guaranteedMatchCountGranted > capacity.matchCount ? 'text-success' : 'text-danger'

  let iconName = null
  $: if (capacityGuest?.confirmDesiredMatchCountByDate) {
    iconName = capacityGuest.desiredMatchCountConfirmedDateTime == null ? 'slots-exclamation' : 'slots-check'
  } else {
    iconName = 'slots'
  }
</script>

<!-- MAKE SURE TO SAVE THIS FILE WITHOUT FORMATTING -->
<!-- MAKE SURE TO SAVE THIS FILE WITHOUT FORMATTING -->
<!-- MAKE SURE TO SAVE THIS FILE WITHOUT FORMATTING -->
<!-- OTHERWISE CRAPPY WHITESPACE WILL BE IN YOUR RESULT. FOR EXAMPLE: -->
<!-- I like green eggs, ham, and tacos . <== Note the trailing space before the period -->
<!-- Calling code can either pass an array of strings OR objects coupled with a slot template -->
{#if count === 1 && (!maxIsExplicit || max >= 1)}<span class={className}
    ><slot item={items[0]}
      >{#if html}<SafeHtml value={items[0]} />{:else}{items[0]}{/if}</slot
    ></span
  >{:else if count === 2 && (!maxIsExplicit || max >= 2)}<span class={className}
    ><slot item={items[0]}
      >{#if html}<SafeHtml value={items[0]} />{:else}{items[0]}{/if}</slot
    ></span
  >{#if orAnd}<span class="or-and" class:strongish={boldPunctuation}>{orAnd}</span>{:else}{space}{/if}<span class={className}
    ><slot item={items[1]}
      >{#if html}<SafeHtml value={items[1]} />{:else}{items[1]}{/if}</slot
    ></span
  >{:else if count}{#if showOthersTip}
    <div class="hide">
      <div bind:this={otherTooltipElem} class={othersTipClass}>
        {#each _otherItems as item, i}
          <span class={className}
            ><slot name="other" {item}
              >{#if i > 0}<br />{/if}{#if html}<SafeHtml value={item} />{:else}{item}{/if}</slot
            ></span
          >
        {/each}
      </div>
    </div>
  {/if}{#each _items as item, i}
    <span class={className}
      ><slot {item}
        >{#if html}<SafeHtml value={item} />{:else}{item}{/if}</slot
      ></span
    >{#if punctuation && i <= orAndIndex && (max > 1 || showAll)}<span class="comma" class:strongish={boldPunctuation}>{comma}</span
      >{:else if i <= orAndIndex}{space}{/if}{#if orAnd && i === orAndIndex}
      <span class="or-and" class:strongish={boldPunctuation}>{orAnd}</span>{/if}{/each}{#if others && (allowShowFewer || !showAll)}
    {#if toggleable && !othersAreNotLoaded}
      <a
        data-test="show-link-toggle"
        class:others={!showAll}
        class:show-fewer={showAll}
        class:strongish={boldPunctuation}
        use:onInteract={e => {
          showAll = !showAll
          e.stopPropagation()
        }}
        href={null}
        use:tip={tipConfig}
        >{#if showAll}show fewer{:else}{andOthers}{/if}</a
      >
    {:else}<span class="others" class:strongish={boldPunctuation} use:tip={tipConfig}>{andOthers}</span>{/if}
  {/if}
{/if}

<script>
  import onInteract from 'decorators/on-interact.js'
  import SafeHtml from 'components/SafeHtml.svelte'
  import tip from 'decorators/tip.js'

  export let items = []
  let className = null
  export { className as class }
  export let max = 3
  export let maxIsExplicit = false
  export let toggleable = true
  export let allowShowFewer = false
  export let or = false
  export let punctuation = false
  export let other = 'other'
  export let otherPlural = 'others'
  export let otherPrefix = ''
  export let html = false
  export let boldPunctuation = true
  export let showOthersTip = false
  export let othersTipOptions = {}
  export let othersTipClass = null
  export let totalCount = null // if items is only a partial list, use _this_ as the total count

  const space = ' '
  const comma = ', '
  let showAll = false
  let otherTooltipElem = null

  $: _items = items ? (showAll ? items : items.slice(0, max)) : []
  $: _otherItems = items ? (showAll ? [] : items.slice(max)) : []
  $: tipConfig =
    showOthersTip && _otherItems.length && otherTooltipElem
      ? {
          content: otherTooltipElem,
          options: { interactive: true, placement: 'top', ...othersTipOptions },
        }
      : { content: null }
  $: othersAreNotLoaded = totalCount != null
  $: loadedCount = _.size(items)
  $: count = othersAreNotLoaded && loadedCount >= max ? totalCount : loadedCount
  $: showingCount = _.size(_items)
  $: others = Math.max(0, count - max)
  $: orAndIndex = showingCount - 1 - (showAll ? 1 : !others)
  $: orAnd = punctuation ? (or ? ' or ' : ' and ') : ''
  $: andOthers = items ? `${otherPrefix}${others} ${others === 1 ? other : otherPlural}` : ''
</script>

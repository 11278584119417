<Card name={user.name} image={user.profilePicture} id={user.userId} class="card-small  card-sm {className ? ` ${className}` : ''}">
  <div class="flex-row flex-justify-between full-height">
    <div>
      <h3 class="break-spaces strong">
        {user.name}
      </h3>
    </div>

    <div class="text-right mr05">
      <Btn title={{ content: `Remove ${label}` }} clearBtnStyling on:click={() => onDelete(user.userId)}>
        <Icon name="delete" class="text-danger" />
      </Btn>
    </div>
  </div>
</Card>

<script>
  import Icon from 'components/Icon.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Card from './Card.svelte'

  export let user
  export let label
  let className = ''
  export { className as class }
  export let onDelete = _.noop
</script>

<InputSelect bind:value options={months} sm {disabled} placeholder="Month" />

<script context="module">
  const months = [
    { days: 31, value: 1, name: 'January', nameShort: 'Jan' },
    { days: 28, value: 2, name: 'February', nameShort: 'Feb' },
    { days: 31, value: 3, name: 'March', nameShort: 'Mar' },
    { days: 30, value: 4, name: 'April', nameShort: 'Apr' },
    { days: 31, value: 5, name: 'May', nameShort: 'May' },
    { days: 30, value: 6, name: 'June', nameShort: 'Jun' },
    { days: 31, value: 7, name: 'July', nameShort: 'Jul' },
    { days: 31, value: 8, name: 'August', nameShort: 'Aug' },
    { days: 30, value: 9, name: 'September', nameShort: 'Sep' },
    { days: 31, value: 10, name: 'October', nameShort: 'Oct' },
    { days: 30, value: 11, name: 'November', nameShort: 'Nov' },
    { days: 31, value: 12, name: 'December', nameShort: 'Dec' },
  ]

  function buildMonthsByValue() {
    const monthsByValue = {}
    for (const month of months) {
      monthsByValue[month.value] = month
    }
    return monthsByValue
  }

  export const monthsByValue = buildMonthsByValue()
</script>

<script>
  import InputSelect from './InputSelect.svelte'

  export let value
  export let disabled = false
</script>

{#if isStudent && stepSubmission.canCompleteNow}
  <Btn {disabled} class="btn-sm" on:click={() => openPreCheckModal(stepSubmission)}>
    {#if stepSubmission.completed}Edit step{:else}Complete step{/if}
  </Btn>
{/if}

{#if preCheckModalOpen}
  <Modal on:close={close}>
    <span slot="title">
      <h4>{step.name}</h4>
      {#if step.descriptionHtml}
        <HelpBlock>{step.descriptionHtml}</HelpBlock>
      {/if}
    </span>

    <div class="p2">
      {#if stepSubmissionStatus === StepSubmissionStatus.AwaitingVerification}
        <Alert type="warning">
          You have completed and shared the necessary PreCheck orders. Awaiting review by
          <strong>{stepSubmission.match.hostOrgName}</strong>.
        </Alert>
      {:else if stepSubmissionStatus === StepSubmissionStatus.Verified}
        <Alert type="success">
          You have completed and shared the necessary PreCheck orders and
          <strong>{stepSubmission.match.hostOrgName}</strong>
          has accepted them.
        </Alert>
      {:else}
        <p>
          To complete this step, you must order a {listOfPreCheckChecks(step.config.requiredChecks)}
          from PreCheck and share the results with <strong>{stepSubmission.match.hostOrgName}</strong>.
          {#if step.config.backDays}These checks must have been completed within the past {step.config.backDays} days.{/if}
        </p>
      {/if}

      {#if precheckOrders == null}
        <p>
          <Spinner />
          loading precheck orders
        </p>
      {:else if precheckOrders.length > 0}
        <table class="table table-condensed">
          <thead>
            <tr>
              <th>Order number</th>
              <th>Background check</th>
              <th>Drug test</th>
              <th>Immunization</th>
              <th>Days old</th>
              <th>Share</th>
            </tr>
          </thead>
          <tbody>
            {#each precheckOrders as po}
              <tr>
                <td>{po.orderId}</td>
                <td>{po.backgroundCheckStatus ?? 'None'}</td>
                <td>{po.drugTestStatus ?? 'None'}</td>
                <td>{po.immunizationStatus ?? 'None'}</td>
                <td>{po.daysOld}</td>
                <td>
                  <!-- allow sharing or unsharing.  can only unshare if the client hasn't acted on it yet -->
                  {#if !po.shared || po.shareStatus == null || po.shareStatus == ''}
                    <Btn
                      on:click={() => sharePreCheckOrder(po.orderId, step.orgId, !po.shared)}
                      class="btn btn-sm btn-{po.shared ? 'default' : 'primary'}"
                    >
                      {po.shared ? 'Unshare' : 'Share'}
                    </Btn>
                  {:else}
                    <span class="text-{getShareStatusColor(po.shared, po.shareStatus)}">
                      {po.shareStatus || (po.shared ? 'Shared' : '')}
                    </span>
                  {/if}
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
      {:else if precheckOrders.length === 0}
        <Alert type="info">No orders yet</Alert>
      {/if}
      <a href="{environment.linkApi}/api/precheck/link/student" target="_blank" class="btn btn-sm btn-info mb1">Start a new order with PreCheck</a>
      <HelpBlock>
        * New PreCheck orders take up to an hour to show here. If you don't see an order placed more than an hour ago, please use the chat box to
        contact us.
      </HelpBlock>
      <HelpBlock>
        * You can share an order regardless of its status, but this step will only be marked complete once the order is completed by PreCheck.
      </HelpBlock>
    </div>
  </Modal>
{/if}

<script>
  import { formatEnumKey } from 'services/formatters.js'
  import { PersonaType, StepSubmissionStatus } from 'config/enums.js'
  import { toFriendlyList } from 'services/string-utils.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import environment from 'services/environment.js'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Modal from 'components/Modal.svelte'
  import Spinner from 'components/Spinner.svelte'
  import persona from 'stores/persona'

  export let stepSubmission
  export let matchModalMatchId
  export let disabled = false

  let preCheckModalOpen
  let precheckOrders
  let stepSubmissionStatus

  $: matchModalMatchId, close()
  $: step = stepSubmission.step
  $: isStudent = $persona.personaType === PersonaType.Student

  function listOfPreCheckChecks(list) {
    return toFriendlyList(list.map(c => formatEnumKey(c).toLowerCase()))
  }

  function getShareStatusColor(shared, shareStatus) {
    if (!shared) return ''
    return shareStatus == 'Accepted' ? 'success' : 'warning'
  }

  function openPreCheckModal(stepSubmission) {
    preCheckModalOpen = true
    stepSubmissionStatus = stepSubmission.status
    precheckOrders = null
    loadPreCheckOrders()
  }

  async function sharePreCheckOrder(orderId, orgId, isAssigned) {
    await api.student.setOrderClients({
      isAssigned,
      orderId,
      orgId,
      push: orderId > 1000,
    })
    loadPreCheckOrders() // reload orders for the open modal // TODO: server side should call _notificationService.RefreshMatchModalUIAsync(_requestPersonaService.UserId.Value, matchId); to cause steps section to reload on our UI and others' UIs...
  }

  function close() {
    preCheckModalOpen = false
  }

  async function loadPreCheckOrders() {
    precheckOrders = await api.student.listOrdersForToStep({ stepId: step.stepId })
  }
</script>

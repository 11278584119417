<!--
  styling / etc copied mostly from https://codepen.io/aanjulena/pen/ZLZjzV
  -->

<div class="toggle inline-flex-row flex-align-center g05" use:_onMayInteract={onMayInteract}>
  <div class="inline-flex-row flex-align-center toggle-label{containerClass ? ` ${containerClass}` : ''}" use:onInteract={toggle} use:tip={tooltip}>
    <button
      type="button"
      class="btn btn-sm btn-toggle{className ? ` ${className}` : ''}"
      class:on-off={onOff}
      class:active={checked}
      {name}
      id={name}
      data-test={name}
      {disabled}
      tabindex="-1"
    >
      <div class="handle" />
    </button>
    <span class={labelClass ? ` ${labelClass}` : ''} class:toggle-label-disabled={disabled}>
      <slot />
    </span>
  </div>
  <slot name="after-label" />
  {#if helpTooltip}
    <Help><SafeHtml value={helpTooltip} /></Help>
  {/if}
</div>

<script>
  import { createEventDispatcher, getContext } from 'svelte'
  import Help from 'components/Help.svelte'
  import SafeHtml from 'components/SafeHtml.svelte'
  import tip from 'decorators/tip.js'
  import _onMayInteract from 'decorators/on-may-interact.js'
  import onInteract from 'decorators/on-interact.js'

  const dispatch = createEventDispatcher()

  export let disabled = false
  export let checked = false
  const initialChecked = checked
  export let name = null
  let className = ''
  export { className as class }
  export let containerClass = null
  export let labelClass = null
  export let onOff = false
  export let tooltip = null
  export let helpTooltip = null
  export let onMayInteract = _.noop

  function toggle() {
    if (disabled) return
    checked = !checked
    dispatch('change')
  }

  const markDirty = getContext('markDirty')
  $: if (markDirty != null && checked != initialChecked) markDirty()
</script>

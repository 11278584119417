{#if item?.formattedAddress}
  {#if href && !linkText}
    <!-- textClass={null} so we can have multiple icons without underlines between them -->
    <ExternalLink
      {href}
      {tooltip}
      {placement}
      class={inherited ? 'specific-underline pseudo-disabled full-interact' : 'specific-underline'}
      textClass={null}
    >
      <Icon name="map-marker" />
      <span class="underlineable"
        >{#if cityState}<CityState address={item} />{:else}{item.formattedAddress}{/if}</span
      >
    </ExternalLink>
  {:else}
    <Icon name="map-marker" />
    <span class="formatted-address"
      >{#if cityState}<CityState address={item} />{:else}{item.formattedAddress}{/if}</span
    >
    {#if href && linkText}
      <ExternalLink {href} {tooltip} {placement}>{linkText}</ExternalLink>
    {/if}
  {/if}
{/if}

<div class="hide">
  <div bind:this={tooltipElem}>
    {#if inherited}
      <div>This address is inherited from its parent location.</div>
      <div class="mt05">Click to open in Google Maps</div>
    {/if}
  </div>
</div>

<script>
  import { buildGoogleMapsLink } from 'services/address.js'
  import Icon from 'components/Icon.svelte'
  import ExternalLink from 'components/ExternalLink.svelte'
  import CityState from 'components/CityState.svelte'

  export let item
  export let linkText = null
  export let cityState = false
  export let inherited = false

  const placement = 'right'

  let tooltipElem = null

  $: tooltip = inherited && tooltipElem ? { content: tooltipElem, options: { placement } } : 'Open in Google Maps'

  $: href = buildGoogleMapsLink(item)
</script>

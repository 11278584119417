{#if conflict}
  <MergeConflicts bind:local={local[conflict.key]} remote={remote[conflict.key]} bind:conflict on:discard>
    <MatchChange conflicted match={localState} change={remoteState} />
  </MergeConflicts>
{/if}

<script>
  import MatchChange from 'components/MatchChange.svelte'
  import MergeConflicts from 'components/MergeConflicts.svelte'
  import { getChangesViewModel, changeFromPrev } from 'services/match-merger.js'

  export let local = null
  export let remote = null
  export let conflict = null

  $: change = conflict == null ? null : getChangesViewModel(local, remote).changes

  $: remoteState = (() => {
    if (conflict == null || change == null) return null
    const delta = toDiff(change)
    return delta
  })()

  $: localState = (() => {
    if (conflict == null || change == null) return null
    const state = changeFromPrev(local, change.previousState)
    const delta = toDiff(state)
    return delta
  })()

  // pass a delta with one key, so we can compare one conflict at a time on the match-modal
  function toDiff(o) {
    if (conflict == null || o == null) return null
    const key = conflict.key
    const r = {}
    r[key] = o[key]

    // schedule picker needs some extra fields. we compare start/end and match days in one merge component
    if (key === 'matchDays') {
      r.status = o.status
      r.startDate = o.startDate
      r.endDate = o.endDate
      r.shifts = o.shifts
      r.capacity = o.capacity
      r.otherMatchSchedules = o.otherMatchSchedules
      r.matchUsers = o.matchUsers
      r.other
    }

    return r
  }
</script>

<FilterTypeCount {filter} {editing} {filterOptions} {appliedMeta} optionLabel="student" />

<script context="module">
  import { FilterType } from 'config/enums.js'
  import FilterTypeCount, { meta as baseMeta } from 'components/filter-types/FilterTypeCount.svelte'

  export const meta = {
    ...baseMeta,
    type: FilterType.CapacityStudentCount,
    label: 'Total students',
    name: 'total-students',
    icon: 'users',
    iconClass: 'color-text-teal',
    hideLabelIcon: true,
    btnBarClass: 'px2 pb2',
    dropdownClass: 'below',
    editLabel: null,
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>

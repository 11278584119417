import { writable } from 'svelte/store'

function createStore() {
  const { subscribe, update } = writable([])
  const add = instance => update(v => [...v, instance])

  return {
    subscribe,
    add,
    remove(index) {
      update(v => v.filter((c, ix) => ix !== index))
    },
  }
}

export default createStore()

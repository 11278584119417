{#if stepSubmission.canCompleteNow}
  {#if !stepSubmission.completed}
    <form action={stepSubmission.step.config.uri} method="POST">
      {#each Object.keys(integrationUriBody) as key}
        {#if integrationUriBody[key] != null}
          <input type="hidden" name={key} value={integrationUriBody[key]} />
        {/if}
      {/each}
      <Btn class="btn-sm btn-primary" type="submit" {loading} {disabled}>
        {stepSubmission.step.config.buttonText}
      </Btn>
    </form>
  {/if}
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'

  export let stepSubmission
  export let disabled = false

  let loading

  // if this changes, probably change the webhook payload too: `NotificationService.cs.GenerateWebhooksFromArgsAsync`
  $: integrationUriBody = {
    matchId: stepSubmission.matchId,
    stepId: stepSubmission.stepId,
    userId: stepSubmission.userId,
    targetUserId: stepSubmission.targetUserId,
    stepSubmissionId: stepSubmission.stepSubmissionId,
  }
</script>

<MediaQuery {...mediaQueries.actionMatch ?? always}>
  <Btn
    color="outline-gray{className ? ` ${className}` : ''}"
    icon="rotation"
    title={viewRotationTitle}
    dataTest="view-rotation-btn-{match.matchId}"
    href={fromTemplate(href, match)}>{viewRotationLabel}</Btn
  >
</MediaQuery>

<script context="module">
  const always = { query: 'all' }
</script>

<script>
  import { fromTemplate } from 'services/string-utils.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import MediaQuery from 'components/MediaQuery.svelte'

  export let mediaQueries = {}
  export let match
  export let href = null

  export let viewRotationLabel = 'View rotation'
  export let viewRotationTitle = null
  export { className as class }
  let className = null
</script>

{#if showDetail || (matchDaysDict[day] && matchDaysDict[day][shift.shiftId]) || used}
  <div
    data-test="sp-day"
    class="card-left-{shift.color} shift text-blackText bg-white p05"
    class:used
    class:hide={!used && shiftLocationEffectivelyDeleted}
    class:is-drag-selecting-days={isDragSelectingDays}
    style="box-shadow: 1px 1px 3px lightgray"
  >
    <div class="pull-right">
      {#if bucket}
        {#if bucket.overfilled > 0}
          <Icon name="alert-triangle" class="text-danger" title="Overfilled" />
        {/if}
        {#if bucket.overfilledMaybe > 0}
          <Icon name="alert-triangle" class="text-warning" title="Might overfill" />
        {/if}
      {/if}
      {#if shiftDayInfo.selected && !shiftDayInfo.available}
        <Icon name="alert-triangle" class="text-warning" title="Violates {shift.name} availability window schedule" dataTest="violation-alert-icon" />
      {/if}
      {#if shiftLocationEffectivelyDeleted}
        <ShiftLocationDeletedMessage
          wasExplicitlyDeleted={shiftLocationExplicitlyDeleted}
          wasEffectivelyDeleted={shiftLocationEffectivelyDeleted}
          message={null}
          class={null}
        />
      {/if}
    </div>
    {#if !showDetail && (peopleCount > 0 || placeholderCount > 0)}
      <div data-test="sp-people">
        {#if placeholderCount > 0}
          <div>
            {#if allPlaceholders}
              <span>All placeholders</span>
            {:else}
              <span>{pluralCount('placeholder', placeholderCount)} </span>
            {/if}
          </div>
        {/if}
        {#if peopleCount === 1}
          <span>{schedulableUsers[Object.keys(schedulableUsers)[0]][0].name}</span>
        {:else if allPeople}
          <span>All people</span>
        {:else if peopleCount > 0}
          <span>{peopleCount} people</span>
        {/if}
      </div>
    {/if}
    {#if showDetail}
      <div class="shift-name ml05">
        <div class="strong">{shift.name}</div>
        {#if shift.startTime || shift.endTime}
          <StartAndEndTime class="flex-column" stacked hideIcons startTime={shift.startTime} endTime={shift.endTime} />
        {:else}
          <div class="flex">
            <InputTime bind:startValue={input[shift.shiftId].startTime} bind:endValue={input[shift.shiftId].endTime} stacked showTotal={false} {disabled} />
          </div>
        {/if}
      </div>
    {/if}
    {#if bucket}
      <MatchUsageBar {bucket} showTooltip infinite={bucket.infinite} />
    {/if}
    {#if showDetail}
      <div>
        {#if (allowsPlaceholders && match.placeholderStudentCount > 0) || (disabled && shift.shiftId in input && input.placeholderStudentCount > 0)}
          <div
            class="flex-row flex-align-center g05 my05 flex-justify-center pb05"
            on:mousedown|stopPropagation
            style="border-bottom: 1px solid #eee;"
          >
            <PlaceholderStudentProfilePic xs noTip />
            <InputNumberWithUnit
              bind:value={input[shift.shiftId].placeholderStudentCount}
              {disabled}
              max={match.placeholderStudentCount}
              min={0}
              unit="of {match.placeholderStudentCount}"
              alwaysSingularUnit
              condense
            />
          </div>
        {/if}
        {#if !disabled && schedulableUserIds.length}
          <div class="flex-row flex-align-center flex-justify-between px05 m0 text-primary pt05" on:mousedown|stopPropagation>
            <a
              class="m0"
              data-test="select-all{dataTestPostfix}"
              use:tip={'Add all people to this availability window'}
              href={null}
              on:click={() => clickShift(shift, 'all')}
            >
              Select all
            </a>
            <a
              class="m0"
              data-test="select-none{dataTestPostfix}"
              use:tip={'Remove all people from this availability window'}
              href={null}
              on:click={() => clickShift(shift, 'none')}
            >
              Select none
            </a>
          </div>
        {/if}
        {#each schedulableUserIds as userId}
          <div
            data-test="checkbox-{schedulableUsers[userId][0].name}"
            class="checkbox checkbox-primary my05"
            class:disabled
            on:mousedown|stopPropagation
            on:click={() => clickPerson(shift, schedulableUsers[userId][0])}
          >
            <input type="checkbox" {disabled} checked={used && matchDaysDict[day]?.[shift.shiftId]?.some(md => md.userId === userId)} />
            <label>
              <ProfilePic
                src={schedulableUsers[userId][0].profilePicture}
                id={userId}
                name={schedulableUsers[userId][0].name}
                initials={schedulableUsers[userId][0].initials}
                small
                class="mr1"
              />
              {schedulableUsers[userId][0].name}
            </label>
          </div>
        {/each}
      </div>
    {/if}
  </div>
{/if}

<script>
  import { createEventDispatcher } from 'svelte'
  import { pluralCount } from 'services/string-utils.js'
  import Icon from 'components/Icon.svelte'
  import InputNumberWithUnit from 'components/InputNumberWithUnit.svelte'
  import InputTime from 'components/fields/InputTime.svelte'
  import MatchUsageBar from 'components/MatchUsageBar.svelte'
  import PlaceholderStudentProfilePic from 'components/PlaceholderStudentProfilePic.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import ShiftLocationDeletedMessage from 'components/ShiftLocationDeletedMessage.svelte'
  import StartAndEndTime from 'components/StartAndEndTime.svelte'
  import tip from 'decorators/tip.js'
  import validator from 'services/validator.js'

  export let match
  export let matchDaysDict
  export let matchDayConfigs
  export let day
  export let shift
  export let schedulableUsers
  export let bucket
  export let showDetail = false
  export let disabled
  export let shiftDayInfo
  export let allowsPlaceholders
  export let isDragSelectingDays

  const dispatch = createEventDispatcher()
  const input = {}
  let inputInitial

  $: matchDayConfigs, setInput()
  $: daysFromStartDate = dayjs(day).diff(match.startDate, 'day')
  $: dataTestPostfix = `-${daysFromStartDate}-${shift.shiftId}`
  $: shiftMatchDays = matchDaysDict[day]?.[shift.shiftId] ?? []
  $: shiftMatchConfig = matchDayConfigs?.find(d => d.date == day && d.shiftId == shift.shiftId) ?? {}
  $: used = shiftMatchDays.length > 0 || shiftMatchConfig?.placeholderStudentCount > 0
  $: shiftLocationExplicitlyDeleted = shift?.locations?.some(o => o.orgExplicitlyDeleted)
  $: shiftLocationEffectivelyDeleted = shift?.locations?.some(o => o.orgEffectivelyDeleted)
  $: allPlaceholders = shiftMatchConfig?.placeholderStudentCount === match.placeholderStudentCount
  $: schedulableUserIds = Object.keys(schedulableUsers || {})
  $: shiftMatchDayUserIds = new Set(shiftMatchDays.map(smd => smd.userId))
  $: allPeople = schedulableUserIds.length && schedulableUserIds.every(id => shiftMatchDayUserIds.has(id))
  $: placeholderCount = allPlaceholders ? match.placeholderStudentCount : shiftMatchConfig?.placeholderStudentCount ?? 0
  $: peopleCount = allPeople ? schedulableUserIds.length : shiftMatchDays.length
  $: input, checkForUpdates()

  function checkForUpdates() {
    if (validator.equals(input, inputInitial)) return
    dispatch('updateMatchDayConfigs', { shifts: [shift], days: [day], matchDayConfig: input })
  }

  function clickShift(shift, type) {
    dispatch('toggleShift', { shift, users: schedulableUsers, days: [day], deselect: type === 'none' ? true : false })
  }

  function clickPerson(shift, person) {
    if (disabled) return
    dispatch('togglePerson', {
      shift,
      person,
      deselect: used && matchDaysDict[day]?.[shift.shiftId]?.find(md => md.userId === person.userId),
      days: [day],
    })
  }

  function setInput() {
    const existingConfig = matchDayConfigs?.find(d => d.date === day && d.shiftId === shift.shiftId)
    input[shift.shiftId] = existingConfig ?? { startTime: null, endTime: null, placeholderStudentCount: 0 }
    inputInitial = _.cloneDeep(input)
  }
</script>

<style lang="scss">
  .shift {
    padding: 2px;
    margin-bottom: 2px;
    filter: contrast(0.5);

    // Reduce specificity by using :where
    &:not(.is-drag-selecting-days):where(:hover, :focus-within) {
      filter: contrast(0.9);
    }

    &.used {
      filter: none;
    }
  }
  .shift-name {
    margin-bottom: 3px;
  }
</style>

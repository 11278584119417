import * as ft from './focus-trap/index.js'

import { tabbable } from 'decorators/focus-trap/tabbable.js'
import { valueOrHandler } from './focus-trap/helpers.js'

// Currently Cypress does not call onDestroy() between tests.
// That behavior results in detached node focus-traps still being active,
// thus preventing clicks/interaction on anything.
// This is an escape hatch for cy.deactivateFocusTraps() to leverage.
if (window.Cypress) window.traps = []

// Wraps https://github.com/focus-trap/focus-trap
export default function focusTrap(node, options) {
  let newestOptions = options
  let activated = false

  function allowOutsideClick(e) {
    if (valueOrHandler(newestOptions?.allowOutsideClick, e)) return true
    return e.target.closest('.impersonation, .intercom-lightweight-app, .toast, [data-tippy-root]')
  }

  const trap = ft.createFocusTrap([node], {
    preventScroll: true, // by default don't scroll (e.g. closing shift form scrolls awkwardly to top of capacity form)
    escapeDeactivates: false,
    allowOutsideClick,
    initialFocus: () => {
      const tabbableNodes = tabbable(node, options?.tabbableOptions)
      if (!tabbableNodes.length) return null
      for (const tabbableNode of tabbableNodes) {
        if (tabbableNode.matches('.modal-close, .cn-tabs li a, .not-initial-focus')) continue
        return tabbableNode
      }
      return tabbableNodes[0]
    },
    // fallback to body if no focusable nodes so that we don't get error "Your focus-trap must have at least one container with at least one tabbable node in it at all times" when deactivating a focus-trap where the node is detached from the dom.
    // can happen, for instance, when a sub modal action results in closing the parent modal underneath (closing an agreement and confirming the action, for example)
    fallbackFocus: () => document.body,
    tabbableOptions: options?.tabbableOptions,
    ...options?.createOptions,
  })
  if (window.Cypress) window.traps.push(trap)

  function update(options) {
    newestOptions = options
    if (!options || options.active) {
      if (activated) {
        trap.unpause()
      } else {
        trap.activate()
        activated = true
      }
    } else if ('active' in options && !options?.active) {
      trap.pause()
    }
  }

  update(options)
  return {
    update,
    destroy: () => {
      trap.deactivate()
      if (window.Cypress) window.traps = window.traps.filter(t => t !== trap)
    },
  }
}

import initial from 'stores/initial.js'
import loadExternalScript from './load-external-script.js'

let $initial = {}
initial.subscribe(v => ($initial = v))

let grc // set when fully loaded
const unrenderedCaptchas = []

window.handleRecaptchaLoaded = () => {
  grc = window.grecaptcha
  for (const captcha of unrenderedCaptchas) {
    captcha.render()
  }
}

function loadScript() {
  if ($initial.disableCaptcha) return Promise.resolve()
  window.loadGrecaptcha = loadExternalScript(
    'https://www.google.com/recaptcha/api.js?render=explicit&onload=handleRecaptchaLoaded',
    'Google Captcha API'
  )
}

class Captcha {
  constructor(element) {
    this.element = element
    this.grcId = null
    this.grcResolve = null
    this.executePromise = null
    if (grc) this.render()
    else unrenderedCaptchas.push(this)
  }

  async execute() {
    try {
      await window.loadGrecaptcha
    } catch {
      return { isDisabled: $initial.disableCaptcha, shouldPrompt: true }
    }
    if (!this.executePromise) this.executePromise = this.executeImpl()
    return await this.executePromise
  }

  executeImpl() {
    if ($initial.disableCaptcha) return Promise.resolve({ isDisabled: $initial.disableCaptcha })
    if (this.grcId == null) return Promise.resolve({ isDisabled: $initial.disableCaptcha, shouldPrompt: true })
    return new Promise(resolve => {
      this.grcResolve = resolve
      grc.execute(this.grcId)
    })
  }

  reset() {
    this.executePromise = null
    if (this.grcId == null || !grc) return
    if (!document.contains(this.element)) return // google recaptcha doesn't check if the element is in the DOM and can fail
    grc.reset(this.grcId)
  }

  cancel() {
    this.markRendered()
  }

  render() {
    if (this.grcId || !grc) return
    if (!document.contains(this.element)) return // google recaptcha doesn't check if the element is in the DOM and can fail
    this.grcId = grc.render(this.element, {
      sitekey: '6LdiK0oUAAAAAHFIzwL75F7ifWOMY2Q2BBjaIXxh',
      size: 'invisible',
      // This is stupid; we have to set the callback up front because the promise
      // returned by grecaptcha.execute() is useless & always resolves to null.
      callback: grcResponse => this.grcResolve({ isDisabled: $initial.disableCaptcha, grcResponse }),
    })
    this.markRendered()
  }

  markRendered() {
    const index = unrenderedCaptchas.indexOf(this)
    if (index < 0) return
    unrenderedCaptchas.splice(index, 1)
  }
}

function build(element) {
  loadScript() // no need to await--using grecaptcha's onload to render all the instances of Captcha service class
  return new Captcha(element)
}

export { build }

{#if qty >= min || label != null}
  <span class={spanClass} {style} data-test={dataTest} use:tip={tooltip ?? (useTooltip ? _label : null)}>
    {#if icon}
      <Icon name={icon} />
    {/if}
    {useTooltip ? readableNumber(qty) : _label}
    {#if max}
      /
      {#if typeof max === 'string'}
        {max}
      {:else}
        {readableNumber(max)}
      {/if}
    {/if}
    {#if iconRight}
      <Icon name={iconRight} />
    {/if}
  </span>
{/if}

<script>
  import { pluralCount, readableNumber } from 'services/string-utils.js'
  import tip from 'decorators/tip.js'
  import Icon from './Icon.svelte'

  export let label = null
  export let name = null
  export let count = 0 // Can be anything countable; if an Array/Map/Set, just pass that instead of `.length`/`.size`
  // Smallest size property passed will be the one used.
  export let xs = false
  export let sm = false // Default if no size shorthand property set.
  export let md = false
  export let lg = false
  export let xl = false
  export let color = 'default' // Automatically prefixed `badge-`.
  export let pulsateColor = null
  let className = null
  export { className as class }
  export let style = null
  export let unit = null
  export let min = 1
  export let max = null
  export let useTooltip = false
  export let icon = null
  export let iconRight = null
  export let tooltip = null

  $: dataTest = name ? `badge-${name}` : null
  $: qty = typeof count === 'number' ? count : _.size(count)
  $: _label = label ?? (unit ? pluralCount(unit, qty) : readableNumber(qty))

  let spanClass
  $: xs, sm, md, lg, xl, color, className, (spanClass = buildClass())

  function buildClass() {
    const classes = ['badge']

    if (xs) classes.push('badge-xs')
    else if (sm) classes.push('badge-sm')
    else if (md) classes.push('badge-md')
    else if (lg) classes.push('badge-lg')
    else if (xl) classes.push('badge-xl')
    else classes.push('badge-sm')

    if (color) classes.push(`badge-${color}`)
    if (pulsateColor) classes.push(`btn-pulsate-${pulsateColor}`)

    if (className) classes.push(className)

    return classes.join(' ')
  }
</script>

<style lang="scss">
  .badge {
    font-weight: normal;
    padding: 3px 5px;
    font-size: 12px;
    margin-top: 1px;
  }

  .badge-xs {
    font-size: 9px;
  }

  .badge-lg {
    font-size: 17px;
    margin-top: -3px;
  }

  .badge-xl {
    font-size: 22px;
    margin-top: -3px;
    padding: 4px 6px;
  }

  .badge-xs,
  .badge-sm {
    &:not(:where(.transform-none)) {
      transform: translate(0, -2px);
    }
  }
</style>

<div class="flex-column g05" data-test={dataTest}>
  {#each uniqueUserEmails as userEmail}
    <UserEmailWithConfirmedStatus {userEmail} />
  {/each}
</div>

<script>
  import UserEmailWithConfirmedStatus from 'components/UserEmailWithConfirmedStatus.svelte'

  export let student
  export let dataTest = 'student-email'

  $: undeletedStudentRecords = student.studentRecords.filter(sr => sr.deleted == null)
  $: uniqueUserEmails = _.uniqBy(undeletedStudentRecords, sr => sr.email)
</script>

{#if change}
  {#if alreadyApplied}
    <div>It appears this change was already applied to the rotation.</div>
  {/if}

  <div bind:this={changesElem}>
    {#if change.onboardingDeadline != null}
      <p>Onboarding deadline extended to {dateService.datestamp(change.onboardingDeadline)}</p>
    {/if}

    {#if change.matchUsers != null || change.placeholderStudentCount != null}
      <h3>Student and faculty changes</h3>
      <ul>
        {#if change.placeholderStudentCount != null}
          <li>
            Placeholder students changed from {match.placeholderStudentCount ?? 0} to {change.placeholderStudentCount}
            {#if isHealth && studentCapacityTooltip}
              <Icon name="alert-triangle" class="text-warning" title={studentCapacityTooltip} />
            {/if}
          </li>
        {/if}
        {#each matchUsersDiff as mu}
          <li>{mu}</li>
        {/each}
      </ul>
    {/if}

    {#if change.startDate != null}
      {@const fromStamp = dateService.datestamp(match.startDate)}
      {@const toStamp = dateService.datestamp(change.startDate)}
      <p>
        {#if match.startDate == null || fromStamp === toStamp}
          Start date set to {toStamp}
        {:else}
          Start date changed from {fromStamp} to {toStamp}
        {/if}
      </p>
    {/if}
    {#if change.endDate != null}
      {@const fromStamp = dateService.datestamp(match.endDate)}
      {@const toStamp = dateService.datestamp(change.endDate)}
      <p>
        {#if match.endDate == null || fromStamp === toStamp}
          End date set to {toStamp}
        {:else}
          End date changed from {fromStamp} to {toStamp}
        {/if}
      </p>
    {/if}

    {#if change.matchDays != null}
      <div class="btn-group pull-right">
        <Btn dataTest="remote-changes" class="btn-sm btn-{showNewSchedule ? 'primary' : 'default'}" on:click={() => (showNewSchedule = true)}>
          {#if proposing}
            Proposed schedule
          {:else if conflicted}
            Current schedule
          {:else}
            Changed schedule
          {/if}
        </Btn>
        <Btn dataTest="your-changes" class="btn-sm btn-{showNewSchedule ? 'default' : 'primary'}" on:click={() => (showNewSchedule = false)}>
          {#if proposing}
            Current schedule
          {:else if conflicted}
            Your unsaved schedule changes
          {:else}
            Previous schedule
          {/if}
        </Btn>
      </div>
      <h3>Schedule changed</h3>
      <div class="mb2">
        <SchedulePicker
          disabled
          show={showNewSchedule}
          match={changedMatch}
          matchDays={changedMatch.matchDays}
          bind:showDetail={showScheduleDetail}
        />
      </div>
      <div class="mb2">
        <SchedulePicker disabled show={!showNewSchedule} {match} matchDays={match.matchDays} bind:showDetail={showScheduleDetail} />
      </div>
      <!--
      we don't show guaranteed matches info since we don't store the SchedulePicker's `filledCapacity` on our `previousState`.
      We could do that though if we want to also show the matches usage at the time the change was made pass
      that into the schedulepicker and skip loading from the server
    -->
    {/if}

    {#if change.course}
      <p>
        {#if match.course == null}
          Course set to <strong class="strongish">{change.course.name}</strong>
        {:else if change.course.courseId === 0}
          Course was removed. It had been set to <strong class="strongish">{match.course.name}</strong>
        {:else}
          Course changed from <strong class="strongish">{match.course.name}</strong> to <strong class="strongish">{change.course.name}</strong>
        {/if}
      </p>
    {/if}

    {#if addedCustomTags.length}
      <div class="mb1">
        <h3 class="mb0">{pluralCount('Added custom tag', addedCustomTags, 'omitNumber')}</h3>
        <div class="flex-row flex-align-center g1">
          {#each addedCustomTags as customTag (customTag.customTagId)}
            <CustomTag {customTag} />
          {/each}
        </div>
      </div>
    {/if}

    {#if removedCustomTags.length}
      <div class="mb1">
        <h3 class="mb0">{pluralCount('Removed custom tag', removedCustomTags, 'omitNumber')}</h3>
        <div class="flex-row flex-align-center g1">
          {#each removedCustomTags as customTag (customTag.customTagId)}
            <CustomTag {customTag} />
          {/each}
        </div>
      </div>
    {/if}

    {#if change.alternateDates != null}
      <DiffMerger
        oldVersion={readableAlternateDates(match.alternateDates)}
        newVersion={readableAlternateDates(change.alternateDates)}
        title="Alternate dates"
        {proposing}
        {conflicted}
      />
    {/if}

    {#if change.syllabus != null}
      <DiffMerger oldVersion={match.syllabus || ''} newVersion={change.syllabus} title="Learning objectives" {proposing} {conflicted} />
    {/if}

    {#if change.studentComments != null}
      <DiffMerger oldVersion={match.studentComments || ''} newVersion={change.studentComments} title="Additional comments" {proposing} {conflicted} />
    {/if}
  </div>
{/if}

<script>
  import { MatchRole, PersonaType } from 'config/enums.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import CustomTag from 'components/CustomTag.svelte'
  import dateService from 'services/date-service.js'
  import DiffMerger from 'components/fields/DiffMerger.svelte'
  import Icon from 'components/Icon.svelte'
  import persona from 'stores/persona'
  import SchedulePicker from 'components/fields/SchedulePicker.svelte'
  import validator from 'services/validator.js'
  import { pluralCount } from 'services/string-utils.js'

  export let proposing = false
  export let conflicted = false
  export let match
  export let change = null
  export let showNewSchedule = true

  let changesElem
  let showScheduleDetail = false

  $: isHealth = $persona.personaType === PersonaType.ProviderStaff
  $: changedMatch = {
    ...match,
    startDate: change?.startDate || match.startDate,
    endDate: change?.endDate || match.endDate,
    matchDays: change?.matchDays,
    placeholderStudentCount: change?.placeholderStudentCount ?? match.placeholderStudentCount,
    matchUsers: change?.matchUsers ?? match.matchUsers,
  }
  $: alreadyApplied = changesElem != null && validator.empty(changesElem.innerHTML)
  $: matchUsersDiff = (() => {
    if (change == null || change.matchUsers == null) return []
    const matchUsersNew = change.matchUsers
    const matchUsers = match == null ? null : match.matchUsers
    if (matchUsers == null || matchUsersNew == null) return []
    const equals = (mu1, mu2) => mu1.userId === mu2.userId && mu1.matchRole === mu2.matchRole
    const roleName = roleId => (roleId === MatchRole.Student ? 'a student' : roleId === MatchRole.Preceptor ? 'a preceptor' : 'school faculty')
    const nameAsRole = mu => `${mu.name} as ${roleName(mu.matchRole)}`
    const added = matchUsersNew.filter(mun => !matchUsers.some(mu => equals(mun, mu))).map(mu => `Added ${nameAsRole(mu)}`)
    const removed = matchUsers.filter(mu => !matchUsersNew.some(mun => equals(mu, mun))).map(mu => `Removed ${nameAsRole(mu)}`)
    return [...added, ...removed]
  })()
  $: addedCustomTags =
    change?.customTags?.filter(ct => match.customTags == null || !match.customTags.some(ctm => ctm.customTagId === ct.customTagId)) ?? []
  $: removedCustomTags =
    match.customTags?.filter(ct => change?.customTags == null || !change.customTags.some(ctm => ctm.customTagId === ct.customTagId)) ?? []
  $: capacityGuest = match?.capacityGuest
  $: studentCapacityTooltip =
    capacityGuest?.studentCountRemaining < change.placeholderStudentCount && change.placeholderStudentCount > match.placeholderStudentCount
      ? capacityGuest.studentCountOverMax > 0
        ? `${match.school.name} currently has ${capacityGuest.studentCountOverMax} students over their maximum.`
        : `${match.school.name} currently has ${pluralCount('student', capacityGuest.studentCount)}; this change will exceed their maximum of ${pluralCount('student', capacityGuest.maxStudents)}.`
      : null
  function readableAlternateDates(alternateDates) {
    if (alternateDates == null) return ''
    return alternateDates.map(ad => `${ad.startDate} - ${ad.endDate}`).join('\n')
  }
</script>

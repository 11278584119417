{#if open && selectedDayCount > 0}
  <Modal {focusTrapSettings} on:close={cancel}>
    <div slot="title">
      <h4>{pluralCount('day', selectedDayCount)} selected</h4>
    </div>
    <div class="modal-body">
      {#if multipleDaysSelected}
        Changes made to the availability windows will apply to all selected days
      {/if}
      {#if selectedDayCount === 1}
        <Tip>{dragSelectTip}</Tip>
      {/if}
      {#if availableShifts.length === 0}
        <HelpBlock>No shifts available for the {pluralCount('selected day', selectedDayCount)}</HelpBlock>
      {:else}
        <div class="shifts">
          {#each availableShifts as shift}
            <div
              class="shift card card-left-{shift.color} p1"
              class:hide={shift?.locations?.some(l => l.orgEffectivelyDeleted)}
              style="width: 500px;"
            >
              <div>
                <h4 class="m0">{shift.name}</h4>
                <div class="mb05">
                  {#if shift.startTime || shift.endTime}
                    <StartAndEndTime startTime={shift.startTime} endTime={shift.endTime} />
                  {:else}
                    <DaysConfigTime
                      {shift}
                      {matchDayConfigs}
                      {selectedDates}
                      {multipleDaysSelected}
                      value={matchDayConfigsTemp[shift.shiftId]}
                      multipleDaysValue={matchDayConfigsTempMultiple[shift.shiftId]}
                    />
                  {/if}
                  {#if shift.location}
                    <div>
                      <!--TODO: match details doesn't bring down shift.location-->
                      <Icon name="map-marker" fw />
                      {shift.location.name}
                    </div>
                  {/if}
                </div>
              </div>
              {#if match.placeholderStudentCount && allowsPlaceholders}
                <DaysConfigPlaceholderStudents
                  {shift}
                  {match}
                  {matchDayConfigs}
                  {selectedDates}
                  {multipleDaysSelected}
                  value={matchDayConfigsTemp[shift.shiftId]}
                  multipleDaysValue={matchDayConfigsTempMultiple[shift.shiftId]}
                />
              {/if}
              {#if Object.keys(schedulableUsers).length > 0}
                <DaysConfigPeople {shift} {meta} {match} {schedulableUsers} onClickShift={clickShift} onClickPerson={clickPerson} />
              {/if}
            </div>
          {/each}
        </div>
      {/if}

      <div class="mt1">
        {#if noExplicitDays}
          <Alert type="warning">{implicitMsg(match, matchPerStudent)}</Alert>
        {/if}
      </div>
    </div>

    <div class="modal-footer flex-row flex-align-center g05">
      <Btn icon="check" on:click={apply} class="btn-primary" dataTest="apply">Apply</Btn>
      <div class="flex-grow" />
      <Btn icon="expand" on:click={() => (open = false)} class="btn-warning" dataTest="modify">Modify selection</Btn>
      <Btn icon="cancel" on:click={cancel} dataTest="cancel">Cancel</Btn>
    </div>
  </Modal>
{/if}

<script>
  import { createEventDispatcher } from 'svelte'
  import { dragSelectTip } from 'decorators/drag-and-select.js'
  import { implicitMsg } from 'services/capacity-usage.js'
  import { pluralCount } from 'services/string-utils.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import Modal from 'components/Modal.svelte'
  import DaysConfigTime from './SchedulePicker.DaysConfig.Time.svelte'
  import DaysConfigPlaceholderStudents from './SchedulePicker.DaysConfig.PlaceholderStudents.svelte'
  import DaysConfigPeople from './SchedulePicker.DaysConfig.People.svelte'
  import StartAndEndTime from 'components/StartAndEndTime.svelte'
  import Tip from 'components/Tip.svelte'
  import validator from 'services/validator.js'

  export let selectedDays
  export let match
  export let matchDays
  export let matchDayConfigs = []
  export let schedulableUsers
  export let matchPerStudent
  export let availableShifts
  export let allowsPlaceholders

  const dispatch = createEventDispatcher()
  const focusTrapSettings = { createOptions: { initialFocus: '.shifts' } }
  const matchDayConfigsTemp = {}
  const matchDayConfigsTempMultiple = {}
  let matchDayConfigsTempInitial = {}
  let matchDaysInitial
  let meta
  let open = true // open as soon as they've selected days, but give them ability to close and select more days

  $: multipleDaysSelected = selectedDates.length > 1
  $: selectedDates = selectedDays ? [...selectedDays] : []
  $: noExplicitDays = matchDays.length === 0
  $: selectedDays, openModal()
  $: selectedDayCount = selectedDays ? selectedDays.size : 0
  $: selectedMatchDays = selectedDays ? matchDays.filter(d => selectedDays.has(d.date)) : []
  $: selectedDays, matchDays, schedulableUsers, setMeta()
  $: selectedDays, setMatchDayConfig()

  function openModal() {
    matchDaysInitial = _.cloneDeep(matchDays)
    open = true
  }

  function clickShift(shift, type) {
    if ((type === 'all' && meta[shift.shiftId].all) || (type === 'none' && !meta[shift.shiftId].some)) {
      return
    }
    dispatch('toggleShift', {
      shift,
      deselect: type === 'none' ? true : false,
      days: selectedDates,
      users: schedulableUsers,
    })
  }

  function clickPerson(shift, person) {
    dispatch('togglePerson', { shift, person, deselect: meta[shift.shiftId][person.userId].some, days: selectedDays })
  }

  function matchDayConfigHasChanges() {
    dispatch('updateMatchDayConfigs', { shifts: availableShifts, days: selectedDays, matchDayConfig: matchDayConfigsTemp })
  }

  function cancel() {
    selectedDays = new Set()
    matchDays = matchDaysInitial
  }

  function apply() {
    if (multipleDaysSelected) updateMultipleMatchDayConfigs()
    if (!validator.equals(matchDayConfigsTempInitial, matchDayConfigsTemp)) {
      matchDayConfigHasChanges()
    }
    selectedDays = new Set()
  }

  function setMeta() {
    meta = {}
    for (const s of match.shifts) {
      const schedulableUserIds = Object.keys(schedulableUsers)
      const selectedForShift = selectedMatchDays.filter(d => d.shiftId === s.shiftId)
      // every selected day has every person selected for this shift
      const allShift = selectedDates.every(date =>
        selectedForShift.some(d => d.date === date && schedulableUserIds.every(u => selectedForShift.some(sd => sd.date === date && sd.userId === u)))
      )

      // some selected days have this shift used
      const someShift = allShift
        ? true
        : selectedDates.some(date =>
            selectedForShift.some(
              d => d.date === date && schedulableUserIds.some(u => selectedForShift.some(sd => sd.date === date && sd.userId === u))
            )
          )

      meta[s.shiftId] = {
        all: allShift,
        some: someShift,
      }

      // at people level too
      for (const userId in schedulableUsers) {
        const forDateAndUser = date => selectedForShift.some(d => d.date === date && d.userId === userId)
        // every selected day has this person selected for this shift
        const allPerson = selectedDates.every(forDateAndUser)

        // some selected days have this person on this shift
        const somePerson = selectedDates.some(forDateAndUser)
        meta[s.shiftId][userId] = {
          all: allPerson,
          some: somePerson,
        }
      }
    }
  }

  function buildDefaultMatchDayConfig() {
    return {
      startTime: null,
      endTime: null,
      placeholderStudentCount: 0,
    }
  }

  function buildDefaultMatchDayConfigMultiple() {
    return {
      ...buildDefaultMatchDayConfig(),
      updateTimesForSelectedDates: false,
      updatePlaceholderStudentsForSelectedDates: false,
    }
  }

  function setMatchDayConfig() {
    if (multipleDaysSelected) {
      for (const shift of availableShifts) {
        matchDayConfigsTempMultiple[shift.shiftId] = buildDefaultMatchDayConfigMultiple()
        const selectedConfigs = matchDayConfigs.filter(d => selectedDates.includes(d.date) && d.shiftId === shift.shiftId)
        if (selectedConfigs.length === selectedDates.length) {
          const firstConfig = selectedConfigs[0]
          if (!firstConfig) continue
          const currentConfig = matchDayConfigsTempMultiple[shift.shiftId]
          if (selectedConfigs.every(d => d?.startTime === firstConfig.startTime)) currentConfig.startTime = firstConfig.startTime
          if (selectedConfigs.every(d => d?.endTime === firstConfig.endTime)) currentConfig.endTime = firstConfig.endTime
          if (selectedConfigs.every(d => d?.placeholderStudentCount === firstConfig.placeholderStudentCount))
            currentConfig.placeholderStudentCount = firstConfig.placeholderStudentCount
        }
      }
    }

    for (const date of selectedDates) {
      for (const shift of availableShifts) {
        const existingConfig = matchDayConfigs.find(d => d.date === date && d.shiftId === shift.shiftId)
        matchDayConfigsTemp[shift.shiftId] = _.cloneDeep(existingConfig) ?? buildDefaultMatchDayConfig()
      }
    }

    matchDayConfigsTempInitial = _.cloneDeep(matchDayConfigsTemp)
  }

  function updateMultipleMatchDayConfigs() {
    for (const shift of availableShifts) {
      const currentConfigMultiple = matchDayConfigsTempMultiple[shift.shiftId]
      const currentConfig = matchDayConfigsTemp[shift.shiftId]
      if (currentConfigMultiple.updateTimesForSelectedDates) {
        currentConfig.startTime = currentConfigMultiple.startTime
        currentConfig.endTime = currentConfigMultiple.endTime
      }
      if (currentConfigMultiple.updatePlaceholderStudentsForSelectedDates) {
        currentConfig.placeholderStudentCount = currentConfigMultiple.placeholderStudentCount
      }
      // Reactivity
      matchDayConfigsTemp[shift.shiftId] = matchDayConfigsTemp[shift.shiftId]
    }
  }
</script>

<style>
  .shifts {
    display: flex;
    align-items: stretch;
    justify-content: center;
    flex-wrap: wrap;
  }
  .shift {
    margin: 10px;
  }
  .shift-name {
    color: #eee;
    width: 100%;
  }
  .shift-time {
    font-style: italic;
  }
  .placeholders {
    padding-left: 25px;
  }
  .people {
    margin-top: 20px;
  }
</style>

<QuickDropdown label="Show…" icon="sliders-simple" btnClass="btn btn-default" labelClass="flex-row flex-align-center g05" dropdownClass="p2">
  <div class="flex-column g2">
    <div class="flex-row flex-align-center g05">
      <Btn clearBtnStyling on:click={() => toggleAll(true)}>Show everything</Btn>
      <div class="flex-grow" />
      <Btn clearBtnStyling on:click={() => toggleAll(false)}>Show minimal</Btn>
    </div>

    <div class="flex-column g2">
      <div class="flex-column g05">
        <h4>Provided by</h4>
        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.coursePicker.org} name="show-org">
          <Icon lg name="school" class="color-text-blue" />
          Organization
        </InputToggle>
        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.coursePicker.team} name="show-team">
          <Icon lg name="users" class="color-text-blue" />
          Team
        </InputToggle>
      </div>

      <div class="flex-column g05">
        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.coursePicker.description} name="show-description">
          <Icon lg name="paragraph" class="color-text-steel" />
          Description
        </InputToggle>
      </div>

      <div class="flex-column g05">
        <h4>Details</h4>
        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.coursePicker.status} name="show-status">
          <Icon lg name="filter-status" class="color-text-steel" />
          Status
        </InputToggle>

        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.coursePicker.dates} name="show-dates">
          <Icon lg name="calendar" class="color-text-dark-green" />
          Start and
          <Icon lg name="calendar" class="color-text-light-red" />
          end dates
        </InputToggle>

        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.coursePicker.hours} name="show-hours">
          <Icon lg name="time" class="color-text-teal" />
          Hours required
        </InputToggle>

        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.coursePicker.students} name="show-students">
          <Icon lg name="graduation-cap" class="color-text-teal" />
          Students
        </InputToggle>

        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.coursePicker.rotationTypes} name="show-rotation-types">
          <Icon lg name="briefcase-medical" class="text-rotation-type" />
          Experience types
        </InputToggle>
      </div>

      <div class="flex-column g05">
        <h4>Usage</h4>

        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.coursePicker.matches} name="show-matches">
          <Icon lg name="rotation" autoColor />
          Rotation count
        </InputToggle>

        <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.coursePicker.progress} name="show-progress">Progress</InputToggle>

        {#if $showDropdowns.coursePicker.progress}
          <!-- Without the flex class here, the container has extra height added for dumb reasons. -->
          <div class="flex ml2">
            <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.coursePicker.progressDetails} name="show-progress"
              >Hours breakdown</InputToggle
            >
          </div>
        {/if}
      </div>
    </div>
  </div></QuickDropdown
>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'

  const toggleParams = {
    containerClass: 'flex-row flex-align-center g1',
    labelClass: 'flex-row flex-align-center g05',
    class: 'm0',
  }

  function toggleAll(checked) {
    for (const key in $showDropdowns.coursePicker) {
      if (typeof $showDropdowns.coursePicker[key] !== 'boolean') continue
      $showDropdowns.coursePicker[key] = checked
    }
  }
</script>

<style>
  h4 {
    margin: 0;
    line-height: 1;
    font-size: 16px;
  }
</style>

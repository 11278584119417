<Captcha bind:this={captchaInstance} idPostfix="login-or-join" />

<Route component={Login} {captchaInstance} />
<Route path="/join" component={Join} {captchaInstance} />
<Route path="/join/:referralId" component={Join} {captchaInstance} />
<Route path="/forgot-password" component={ForgotPassword} />

<script>
  import { Route } from 'svelte-routing'
  import Captcha from 'components/fields/Captcha.svelte'
  import ForgotPassword from 'pages/ForgotPassword.svelte'
  import Join from 'pages/Join.svelte'
  import Login from 'pages/Login.svelte'

  let captchaInstance
</script>

<a
  use:tip={isNotMatch[org.orgId] ? 'This organization doesn’t match your search, but it has locations that do.' : null}
  {href}
  on:click={onClick}
  class="tree-btn flex-row flex-align-center g05"
  class:selected={selected[org.orgId]}
  class:not-match={isNotMatch[org.orgId]}
  data-test="orgs-picker-{org.orgId}"
>
  <ProfilePic src={org.profilePicture} name={org.name} {small} {medium} {large} {xLarge} {special} />
  <div class="full-width">
    <div class="flex-row flex-align-center g05">
      <strong>{org.name}</strong>

      {#if nested}<Badge xs count={org.children} useTooltip unit="child location" />{/if}

      {#if selected[org.orgId]}
        <div class="flex-grow" />
        <Icon name="check" title="Selected" />
      {/if}
    </div>

    {#if org.formattedAddress}
      <div class="address">{org.formattedAddress}</div>
    {/if}
  </div>
</a>

<script>
  import { buildOrgProfileUrl } from 'services/orgs-service.js'
  import Badge from 'components/Badge.svelte'
  import Icon from 'components/Icon.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import tip from 'decorators/tip.js'

  export let org
  export let selected
  export let isNotMatch
  export let nested
  export let onSelectToggle
  export let special = false
  export let small = false
  export let medium = false
  export let large = false
  export let xLarge = false

  $: href = buildOrgProfileUrl(org)

  function onClick(e) {
    if (!onSelectToggle) return
    e.preventDefault()
    onSelectToggle(org)
  }
</script>

<style>
  .address {
    color: #888;
    font-size: 10px;
    font-style: italic;
  }
</style>

<Modal on:close={onClose}>
  <div slot="title">
    <h3>Set required hours</h3>
  </div>
  <Form on:submit={_onSave}>
    <div class="modal-body">
      <FormGroup valid={num >= 0}>
        <label class="flex-row flex-align-center g05" for="required-hours">
          <Icon name="time" class="color-text-teal" />
          <span>Required hours</span>
        </label>
        <InputNumber name="required-hours" bind:value={num} allowDecimal />
      </FormGroup>
      <FormGroup>
        <label class="flex-row flex-align-center g05" for="userIds">
          <Icon name="graduation-cap" class="color-text-teal" />
          <span>Students</span>
        </label>
        <InputSelect
          name="userIds"
          bind:value={userIds}
          multiple
          options={users}
          placeholder="All students"
          valueSelector={o => o.userId}
          let:option={user}
        >
          <div class="flex-row flex-align-center g1">
            <ProfilePic src={user.profilePicture} initials={user.initials} name={user.name} id={user.userId} small />
            <div>{user.name}</div>
          </div>
        </InputSelect>
      </FormGroup>
    </div>
    <div class="modal-footer">
      <SubmitBtn icon="save" loading={saving}>Save</SubmitBtn>
      <Btn on:click={onClose}>Cancel</Btn>
    </div>
  </Form>
</Modal>

<script>
  import Btn from './bootstrap/Btn.svelte'
  import FormGroup from './bootstrap/FormGroup.svelte'
  import SubmitBtn from './bootstrap/SubmitBtn.svelte'
  import InputNumber from './fields/InputNumber.svelte'
  import InputSelect from './fields/InputSelect.svelte'
  import Form from './Form.svelte'
  import Icon from './Icon.svelte'
  import Modal from './Modal.svelte'
  import ProfilePic from './ProfilePic.svelte'

  export let users
  export let onClose
  export let onSave

  let saving = false
  let num
  let userIds = []

  async function _onSave() {
    saving = true
    try {
      await onSave(num, userIds.length ? userIds : users.map(u => u.userId))
      onClose()
    } catch {
      saving = false
    }
  }
</script>

{#if personaService.canUseAnyFeatureType(FeatureType.Courses)}
  <Modal title={courseId ? 'Use a different course' : 'Set a course for this rotation'} on:close={onClose}>
    <Form on:submit={onSubmit}>
      <div class="modal-body">
        <FormGroup>
          <label for="course-picker">Which course should this rotation apply to?</label>
          <CoursePicker bind:value={courseId} bind:selectedCourse placeholder="No course" />
        </FormGroup>
      </div>
      <div class="modal-footer">
        <SubmitBtn dataTest="btn-switch-course" loading={saving} icon="save">
          {#if courseId}
            Change course
          {:else}
            Set course
          {/if}
        </SubmitBtn>
        <Btn on:click={onClose}>Cancel</Btn>
      </div>
    </Form>
  </Modal>
{:else}
  <RequiresUpgradeModal {onClose} action="manage courses" />
{/if}

<script>
  import { FeatureType } from 'config/enums'
  import api from 'services/api'
  import personaService from 'services/persona-service'
  import toaster from 'services/toaster'
  import Btn from './bootstrap/Btn.svelte'
  import FormGroup from './bootstrap/FormGroup.svelte'
  import SubmitBtn from './bootstrap/SubmitBtn.svelte'
  import CoursePicker from './CoursePicker.svelte'
  import Form from './Form.svelte'
  import Modal from './Modal.svelte'
  import RequiresUpgradeModal from './RequiresUpgradeModal.svelte'

  export let match
  export let onClose
  export let onMatchChanged

  let saving = false
  let courseId = match.courseId
  let selectedCourse = null

  async function onSubmit() {
    saving = true
    // hacky...for now, let's say that setting to 0 clears course. might either want this to be a separate endpoint, or check the actual body of the request for the presence of a property explicitly set to null.
    const course = { courseId: selectedCourse?.courseId ?? 0, name: selectedCourse?.name }
    try {
      await api.match.changeMatch({ matchId: match.matchId }, { changes: { course } }, api.noMonitor)
      await onMatchChanged({ details: true, activity: true })
      toaster.toast({
        message: 'Saved successfully.',
        type: 'success',
        icon: 'check',
      })
      onClose()
    } finally {
      saving = false
    }
  }
</script>

<QuickDropdown label="Show…" icon="sliders-simple" btnClass="btn btn-default" labelClass="flex-row flex-align-center g05" dropdownClass="p2">
  <div class="flex-column g2">
    <div class="flex-row flex-align-center g05">
      <Btn clearBtnStyling on:click={() => toggleAll(true)}>Show everything</Btn>
      <div class="flex-grow" />
      <Btn clearBtnStyling on:click={() => toggleAll(false)}>Show minimal</Btn>
    </div>

    <div class="flex-column g05">
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.staffPicker.orgTitle} name="show-title">
        <Icon lg name="id-badge-duo" autoColor class={orgClass} />
        Organization title
      </InputToggle>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.staffPicker.externalId} name="show-external-id">
        <Icon lg name="solid-finger-print" class={orgClass} />
        External ID
      </InputToggle>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.staffPicker.email} name="show-roles">
        <Icon lg name="email" class={emailClass} />
        Email
      </InputToggle>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.staffPicker.org} name="show-roles">
        <Icon lg name={orgType} class={orgClass} />
        Organization
      </InputToggle>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.staffPicker.roles} name="show-roles">
        <Icon lg name={'roles-list'} autoColor class={orgClass} />
        Roles
      </InputToggle>
    </div>
  </div>
</QuickDropdown>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import personaService from 'services/persona-service'
  import showDropdowns from 'stores/show-dropdowns.js'
  import { FeatureType } from 'config/enums'

  const hasHealthFeature = personaService.canUseAnyFeatureType(FeatureType.HealthInstitution)
  const orgClass = hasHealthFeature ? 'color-text-orange' : 'color-text-blue'
  const orgType = hasHealthFeature ? 'hospital' : 'school'
  // email is the opposite color, for a little bit of contrast and being less org specific
  const emailClass = hasHealthFeature ? 'color-text-blue' : 'color-text-orange'

  const toggleParams = {
    containerClass: 'flex-row flex-align-center g1',
    labelClass: 'flex-row flex-align-center g05',
    class: 'm0',
  }

  function toggleAll(checked) {
    for (const key in $showDropdowns.staffPicker) {
      if (typeof $showDropdowns.staffPicker[key] !== 'boolean') continue
      $showDropdowns.staffPicker[key] = checked
    }
  }
</script>

import validator from 'services/validator.js'

// @id => @user-name
export function parseUserIdMentionsToUserNames(content, mentionableUsers) {
  if (validator.empty(content) || mentionableUsers.length === 0) return content
  const userIdMentionRegex = /@([\da-z]{8}-(?:[\da-z]{4}-){3}[\da-z]{12})/gi
  return content.replaceAll(userIdMentionRegex, (match, userId) => {
    const user = mentionableUsers.find(u => u.userId === userId)
    if (user == null) return match
    return `@${user.userName}`
  })
}

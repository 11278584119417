<div class="waffle-grid-container mb2">
  <div
    use:tip={tooltip}
    class="waffle-grid"
    style="grid-template-columns: repeat({columnCount}, 30px); grid-template-rows: repeat({rowCount}, 30px);"
  >
    {#if isCapacityGuestView && guests}
      {#each [...filteredGuests, ...remainingGuests] as guest}
        {#each { length: guest.approvedMatches + guest.pendingMatches } as _, index}
          {@const isPending = index >= guest.approvedMatches}
          {@const overMaxRotations = index >= guest.maxMatches}
          <div
            class="color-text-{guest.color}"
            class:remaining-guest={remainingGuests.includes(guest)}
            use:onMayInteract={event => setTooltipGuest(event, guest)}
          >
            {#if overMaxRotations}
              <Icon
                cell
                name={overMaxRotations ? 'square-exclamation' : 'square-exclamation'}
                style="--path-0-color: {guest.textColor}; --path-0-opacity: 1 !important; --path-1-color: var(--user-{guest.color}); --path-1-opacity: {guest ===
                  tooltipGuest && isPending
                  ? '.6'
                  : '1'};"
              />
            {:else}
              <Icon
                cell
                name="square"
                style="--path-0-color: var(--user-{guest.color}); --path-0-opacity: {guest === tooltipGuest && isPending ? '.6' : '1'};"
              />
            {/if}
          </div>
        {/each}

        {#each { length: guest.guaranteedLeft } as _}
          <div
            class="color-text-{guest.color} {remainingGuests.includes(guest) ? 'remaining-guest' : ''}"
            use:onMayInteract={event => setTooltipGuest(event, guest)}
          >
            <Icon cell name="square" style="--path-0-opacity: {guest === tooltipGuest ? '.3' : '1'}" />
          </div>
        {/each}
      {/each}
    {/if}

    {#if isStatusView}
      {#each Object.entries(capacityMatchStatusCounts) as [status, count]}
        {@const matchStatus = matchStatusHelper.get(status)}
        {#each { length: count } as _}
          <div
            class="waffle-cell"
            style="background-color: {matchStatus.reportColor}"
            use:onMayInteract={event => setTooltipStatus(event, status, count)}
          />
        {/each}
      {/each}
    {/if}

    {#each { length: emptyCells } as _}
      <div style="color: #eee;">
        <Icon cell name="square" />
      </div>
    {/each}
  </div>
  <div bind:this={tooltipGuestElem} class="text-left">
    <CapacityGuestDetailTooltip guest={tooltipGuest} />
  </div>

  <div bind:this={tooltipStatusElem} class="text-left">
    <MatchCountByStatus matchStatus={tooltipStatus} count={capacityMatchStatusCounts[tooltipStatus]} times iconProps={{ lg: true }} />
  </div>
</div>

<script>
  import MatchCountByStatus from 'components/MatchCountByStatus.svelte'
  import CapacityGuestDetailTooltip from 'components/CapacityGuestDetail.Tooltip.svelte'
  import onMayInteract from 'decorators/on-may-interact.js'
  import Icon from 'components/Icon.svelte'
  import matchStatusHelper from 'services/match-status-helper.js'
  import tip from 'decorators/tip.js'

  export let capacity
  export let guests
  export let filteredGuests = []
  export let isCapacityGuestView = false
  export let isStatusView = false
  export let capacityMatchStatusCounts = []

  let tooltipGuestElem
  let tooltipStatusElem
  let tooltipGuest = null
  let tooltipStatus = null
  let tooltipGuestToClear = null
  let tooltipStatusToClear = null

  $: tooltipContent = tooltipGuest ? tooltipGuestElem : tooltipStatus ? tooltipStatusElem : null
  $: tooltip = tooltipContent
    ? {
        content: tooltipContent,
        options: {
          theme: 'light-gray-scrollable',
          interactive: true,
          placement: 'left',
          show: true,
        },
      }
    : null

  function buildCapacityMatchStatusCounts(guests) {
    const countsByStatus = {}
    for (const { statusCounts } of guests) {
      for (const { status, count } of statusCounts) {
        if (countsByStatus[status]) {
          countsByStatus[status] += count
        } else {
          countsByStatus[status] = count
        }
      }
    }
    return countsByStatus
  }

  $: maxMatches = capacity.maxMatches ?? 0
  $: capacityMatchStatusCounts = buildCapacityMatchStatusCounts(guests)
  $: remainingGuests = guests.filter(g => !filteredGuests.includes(g))
  $: totalApprovedCells = capacity ? _.sumBy(guests, g => g.approvedMatches || 0) : 0
  $: totalPendingCells = capacity ? _.sumBy(guests, g => g.pendingMatches || 0) : 0
  $: totalGuaranteedCells = capacity ? _.sumBy(guests, g => g.guaranteedMatches || 0) : 0
  $: totalCells = isCapacityGuestView
    ? Math.max(maxMatches, totalApprovedCells + totalPendingCells + Math.max(0, totalGuaranteedCells - totalApprovedCells - totalPendingCells))
    : capacityMatchStatusCounts
      ? Math.max(maxMatches, _.sum(Object.values(capacityMatchStatusCounts)))
      : 0
  $: emptyCells = isCapacityGuestView
    ? totalCells - (totalApprovedCells + totalPendingCells + Math.max(0, totalGuaranteedCells - totalApprovedCells - totalPendingCells))
    : totalCells - _.sum(Object.values(capacityMatchStatusCounts))
  $: rowCount = Math.min(Math.ceil(Math.sqrt(totalCells)), 10)
  $: columnCount = isCapacityGuestView
    ? Math.max(Math.ceil(totalCells / rowCount), Math.ceil(maxMatches / rowCount))
    : Math.max(Math.ceil(maxMatches / rowCount), Math.ceil(_.sumBy(capacity.statusCounts, s => s.count) / rowCount))

  async function setTooltipGuest(event, guest) {
    if (event.mouseIn || event.focusIn) {
      if (tooltipGuest !== guest) tooltipGuest = guest
      tooltipGuestToClear = null
    } else {
      tooltipGuestToClear = tooltipGuest
      setTimeout(() => {
        if (tooltipGuestToClear && tooltipGuestToClear === tooltipGuest) {
          tooltipGuest = null
        }
      }, 0)
    }
  }

  function setTooltipStatus(event, status) {
    // Not very DRY but it's temporary until we can refactor the waffle chart to be more universal/reusable
    if (event.mouseIn || event.focusIn) {
      if (tooltipStatus !== status) tooltipStatus = status
      tooltipStatusToClear = null
    } else {
      tooltipStatusToClear = tooltipStatus
      setTimeout(() => {
        if (tooltipStatusToClear && tooltipStatusToClear === tooltipStatus) {
          tooltipStatus = null
        }
      }, 0)
    }
  }
</script>

<style lang="scss">
  /* waffle stuff */
  .waffle-grid-container {
    width: 100%;
    margin: 0 auto;
    overflow-x: auto;
    position: relative;
    display: flex;
  }
  .waffle-grid {
    padding: 3px;
    display: grid;
    grid-auto-flow: column;
    margin: auto;
  }
  .waffle-cell {
    background-color: #eee;
    height: 30px;
    width: 30px;
    border-radius: 5px;
    border: 2px solid #f7f7f7;
    font-size: 2rem;
    font-weight: bolder;
  }

  .remaining-guest {
    opacity: 0.1 !important;
  }
</style>

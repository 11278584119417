{#if showAnything}
  <div class="flex-column g1 mt1">
    {#if show.owner || showTeam || show.locations}
      <div class="capacity-owner mb1">
        <div class="flex-row flex-align-center g05 mb05">
          <Icon name="hospital" class="color-text-orange" />
          <h4 class="leading-none m0">{capacityLocationsTitle}</h4>
        </div>

        <CapacityLocations {capacity} {show} useOverflowEllipsis bind:title={capacityLocationsTitle} />
      </div>
    {/if}

    {#if showCapacityDescription}
      <LongDesc class="m0 wrap" max={longDescMax} text={capacity.descriptionHtml} />
    {/if}

    {#if showService}
      <div class="flex-row flex-align-center g05{showServiceDescription ? '' : ' mb05'}">
        <Icon name="shapes" class="color-text-purple" />
        <h4 class="leading-none m0">{capacity.service.name}</h4>
      </div>
      {#if showServiceDescription}
        <LongDesc class="m0 wrap" max={longDescMax} text={capacity.service.descriptionHtml} />
      {/if}
    {/if}

    <div class="flex-row g3">
      {#if show.startAndEndDate}
        <div>
          <h4 class="leading-none m0">Schedule</h4>
          <StartAndEndDate startDate={capacity.startDate} endDate={capacity.endDate} />
        </div>
      {/if}

      {#if show.matchLeadTimeDays}
        <div>
          <h4 class="leading-none m0">Lead time</h4>
          <Icon lg name="calendar-clock" class="color-text-dark-green" />
          {#if capacity.matchLeadTimeDays === 0}
            <em>None</em>
          {:else}
            <span>{pluralCount('day', capacity.matchLeadTimeDays)}</span>
          {/if}
        </div>
      {/if}
    </div>

    {#if showRotationTypes || showSpecialties || showDisciplines}
      <div>
        <TagCloud
          ctx={{
            rotationTypes: showRotationTypes ? capacity.rotationTypes : [],
            specialties: showSpecialties ? specialties : [],
            disciplines: showDisciplines ? disciplines : [],
          }}
        />
      </div>
    {/if}

    {#if show.guests}
      <div class="flex-column g05">
        <h4 class="leading-none m0">Schools</h4>
        {#each capacity.guests as capacityGuest}
          <CapacityGuestProfilePictureAndName {capacityGuest} class="flex-row flex-align-start">
            <div slot="profile-pic">
              {#if show.matches}
                <CapacityMatchesSummary {capacity} {capacityGuest} progressClass={isHovered || isSelected ? 'bg-white' : null} />
                {#if capacityGuest.maxMatches}
                  <CapacityMatchStatusCount {capacity} guestOrgId={capacityGuest.guestOrgId} showApprovedCount={false} showArchivedCount={false} />
                {/if}
              {/if}
            </div>
          </CapacityGuestProfilePictureAndName>
        {/each}
      </div>
    {/if}
  </div>
{/if}

<script>
  import { computeCapacityDisciplines, computeCapacitySpecialties } from 'components/InheritedTagPicker.svelte'
  import { pluralCount } from 'services/string-utils.js'
  import CapacityGuestProfilePictureAndName from 'components/CapacityGuestProfilePictureAndName.svelte'
  import CapacityMatchesSummary from 'components/CapacityMatchesSummary.svelte'
  import CapacityMatchStatusCount from 'components/CapacityMatchStatusCount.svelte'
  import CapacityLocations from 'components/CapacityLocations.svelte'
  import Icon from 'components/Icon.svelte'
  import LongDesc from 'components/LongDesc.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import StartAndEndDate from 'components/StartAndEndDate.svelte'
  import TagCloud from 'components/TagCloud.svelte'

  export let capacity = null
  export let isSelected
  export let isHovered

  let capacityLocationsTitle = null
  const longDescMax = 120

  $: specialties = computeCapacitySpecialties(capacity)
  $: disciplines = computeCapacityDisciplines(capacity)

  $: show = $showDropdowns.capacityPicker
  $: showCapacityDescription = show.description && capacity.description
  $: showService = show.service && capacity.service
  $: showServiceDescription = show.serviceDescription && capacity.service?.description
  $: showRotationTypes = show.rotationTypes && capacity.rotationTypes?.length
  $: showSpecialties = show.specialties && specialties?.length
  $: showDisciplines = show.disciplines && disciplines?.length
  $: showTeam = show.team && capacity.teamOrgId
  $: showAnything =
    showCapacityDescription ||
    show.startAndEndDate ||
    show.matchLeadTimeDays ||
    showRotationTypes ||
    showSpecialties ||
    showDisciplines ||
    show.matches ||
    show.guests ||
    showService ||
    show.owner ||
    show.locations ||
    showTeam
</script>

/*
  <button bind:this={buttonElement}>Default</button>
  <div use:popper={{ reference: buttonElement }}>
    I am a popup near buttonElement
  </div>
*/

import { createPopper } from '@popperjs/core'

// InputSelect positions awkwardly off to the side in modals if using 'absolute' strategy.
// But display issues happen to QuickFilterTag in modal and they seem to be fine with 'absolute' strategy, so only use fixed for InputSelects that are in modals seems happy.
function getStrategy(node) {
  while (node) {
    if (node instanceof HTMLElement) {
      const position = getComputedStyle(node).getPropertyValue('position')
      if (position === 'fixed') return 'fixed'
      if (position === 'absolute' || position === 'relative') return 'absolute'
    }
    node = node.parentNode
  }
  return 'absolute'
}

export default function popper(node, options) {
  if (options == null) return
  let instance = null

  create(options)

  function create(o) {
    if (o == null) return
    const strategy = o.strategy ?? getStrategy(o.reference)
    instance = createPopper(o.reference, node, {
      placement: 'bottom-start',
      strategy,
      ...options,
    })
  }

  function destroy() {
    if (instance != null) {
      instance.destroy()
      instance = null
    }
  }

  return {
    update(options) {
      destroy()
      create(options)
    },

    destroy() {
      destroy()
    },
  }
}

<div class="flex-row flex-align-center g05">
  <Icon name="agreement" autoColor lg />
  <em class={style}>None specified</em>
</div>

<script>
  import Icon from 'components/Icon.svelte'

  export let style = 'small text-gray'
</script>

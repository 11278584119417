// sort case-insensitively if string items
// else sort like normal
// only handles strings and numbers currently. Not date, etc.
export function sort(array, selector = null, nullsFirst = false) {
  if (array == null) return null
  selector ??= _.identity
  return array.sort((_a, _b) => {
    if (_a === _b) return 0 // short circuit if same reference
    if (nullsFirst) {
      if (_a === undefined && _b == null) return -1 // let's put undefined before null
      if (_a === null && _b === undefined) return 1
      if (_a == null) return -1
      if (_b == null) return 1
    }

    const a = _a && selector(_a)
    const b = _b && selector(_b)

    if (a === b) return 0 // short circuit if same reference
    if (nullsFirst) {
      if (a === undefined && b == null) return -1 // undefined before null technically
      if (a === null && b === undefined) return 1
      if (a == null) return -1
      if (b == null) return 1
    }

    if (a.localeCompare) return a.localeCompare(b, undefined, { sensitivity: 'base' })
    return a - b
  })
}

// If you change this, consider also changing EnumerableExtensions.GroupByContiguous.
// Given [2, 3, 4, 6, 7, 9] returns [[2, 3, 4], [6, 7], [9]]
// because 2, 3, 4 are contiguous and 6, 7 are contiguous and 9 is not.
// Assumes intValues is sorted.
export function groupByContiguous(source) {
  if (source == null) return []
  const contiguousGroups = []
  let previous = source[0]
  let currentGroup = [previous]
  for (let i = 1; i < source.length; i++) {
    const current = source[i]
    if (current === previous + 1) {
      currentGroup.push(current)
    } else {
      contiguousGroups.push(currentGroup)
      currentGroup = [current]
    }
    previous = current
  }
  contiguousGroups.push(currentGroup)
  return contiguousGroups
}

// If you change this, consider also changing EnumerableExtensions.GroupByContiguousFriendly.
// Given [2, 3, 4, 6, 7, 9] returns [[2, 4], [6], [7], [9]]
// because there's a big enough gap between 2  and 4 to omit 3,
// whereas 6 and 7 are right next to each other so you still have to say both.
// Useful for saying "2-4, 6, 7, 9" instead of "2, 3, 4, 6, 7, 9"
// Assumes intValues is sorted.
export function groupByContiguousFriendly(intValues) {
  const ranges = groupByContiguous(intValues)
  const friendlyRanges = []
  for (const range of ranges) {
    const first = range[0]
    const last = range.at(-1)
    if (first === last) friendlyRanges.push([first])
    else if (first === last - 1) friendlyRanges.push([first], [last])
    else friendlyRanges.push([first, last])
  }
  return friendlyRanges
}

export function removeDuplicatesAndSort(array) {
  return [...new Set(array)].sort()
}

export function allTruthyOrAllFalsy(array, prop) {
  const countWithValue = array.filter(g => g[prop]).length
  return countWithValue === 0 || countWithValue === array.length
}

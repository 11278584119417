<span
  class="flex-row flex-align-center g05"
  use:tip={tooltipElem
    ? {
        content: tooltipElem,
        options: { placement: 'left', theme: 'light-gray-scrollable', ...tipOptions },
      }
    : null}
>
  <Icon name={stepTypeIcon} class={iconClass} {...iconProps} />
  {#if stepInputTypeIcon && !hideStepType}<Icon name={stepInputTypeIcon} class={iconClass} {...iconProps} />{/if}
  <span class={className}>{step.name}</span>
  <div class="hide">
    <div bind:this={tooltipElem} class="flex-column g05">
      <div class="flex-row flex-align-center g05">
        <Icon name={stepTypeIcon} class={iconClass} lg {...iconProps} />
        <span>{stepType} step</span>
      </div>
      {#if stepInputTypeIcon && !hideStepType}
        <div class="flex-row flex-align-center g05">
          <Icon name={stepInputTypeIcon} class={iconClass} lg {...iconProps} />
          <span>{stepInputType}</span>
        </div>
      {/if}
    </div>
  </div>
</span>

<script context="module">
  import { StepInputType, StepType } from 'config/enums.js'
  const iconByStepType = _.invert(StepType)
  const iconByStepInputType = _.invert(StepInputType)
</script>

<script>
  import { formatEnumValue } from 'services/formatters.js'
  import Icon from 'components/Icon.svelte'
  import matchStatusHelper from 'services/match-status-helper.js'
  import tip from 'decorators/tip.js'

  export let step
  export let hideStepType = false
  let className = null
  export { className as class }
  export let iconProps = {}
  export let tipOptions = {}

  let tooltipElem = null

  $: stepType = step.stepType == null ? '' : formatEnumValue(StepType, step.stepType)
  $: stepInputType = step.stepInputTypeName || step.stepInputType == null ? '' : formatEnumValue(StepInputType, step.stepInputType)
  $: stepTypeIcon = iconByStepType[step.stepType]
  $: stepInputTypeIcon = iconByStepInputType[step.stepInputType]
  $: iconClass = `text-${matchStatusHelper.getByStepType(step.stepType).color}`
</script>

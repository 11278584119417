<!--
  {#if showing}
    <Modal on:close={() => showing = false}>
      some content for your modal inside
    </Modal>
  {/if}
  -->

<!-- using portal component instead of action breaks reactivity of the template in some situations--e.g. share modal doesn't ever un-disable "Save sharing settings" button for some reason, unless we use the portal Action-->
<!-- note that if you run into issues with portal, you could add an `export let noPortal = false` property and set it to true if your modal situation
     doesn't require the modal to be rendered at a different dom location. Note that `use:links` would _also_ not be needed if you're not using portal, so turn that off too if portal is off. -->
<div use:portal={'#modals'}>
  <div class="modal-backdrop" />
  <div bind:this={modalElem} class="modal" style={_style} role="dialog" aria-hidden on:keydown={closeIfEscape}>
    <!--
    only fly in for now--issue scenario:
    - edit service
    - click link from modal "Create an agreement" (when you have no agreements setup)
    - route transitions behind the modal, but the modal remains
    - removing the out transition makes it work. couldn't reproduce in REPL: https://svelte.dev/repl/cc91d9c3c13a4a198f769b4b81145839?version=3.17.1 -->
    <div
      class="modal-dialog{className ? ` ${className}` : ''}"
      class:modal-lg={lg}
      class:modal-xl={xl}
      style={dialogStyle}
      in:fly={{ y: -100, duration: 300 }}
    >
      <div class="modal-content{contentClass ? ` ${contentClass}` : ''}" use:focusTrap={_focusTrapSettings} data-test={dataTest}>
        {#if showHeader}
          <div class="modal-header{headerClass ? ` ${headerClass}` : ''}">
            <slot name="title">
              {#if title}
                <h4>
                  <SafeHtml value={title} />
                </h4>
              {/if}
            </slot>
            {#if showClose}
              <ModalCloseBtn on:close={() => close('button')} />
            {/if}
          </div>
        {/if}
        <slot />
      </div>
    </div>
  </div>
</div>

<script context="module">
  // track what instances of modals are open, so we can close them one by one and unlock scrolling on the document when none are open anymore
  // todo: with svelte, we can probably refactor modal usages to put modal components at end of dom so nested modals don't get affected by parent styling. but not a big deal currently
  import { writable } from 'svelte/store'
  const modals = writable([])
  // modals.subscribe(m => console.log('modals: ', m))
</script>

<script>
  import { portal } from 'svelte-portal'
  import { fly } from 'svelte/transition'
  import { lockScroll, unlockScroll } from 'services/scroll-service.js'
  import { onDestroy, createEventDispatcher } from 'svelte'
  import focusTrap from 'decorators/focus-trap.js'
  import Key from 'config/key.js'
  import ModalCloseBtn from 'components/Modal.CloseBtn.svelte'
  import SafeHtml from 'components/SafeHtml.svelte'

  export let title = null
  let className = ''
  export { className as class }
  export let style = 'display: block'
  export let dialogStyle = null
  export let contentClass = null
  export let showClose = true
  export let lg = false
  export let xl = false
  export let color = null
  export let showHeader = true
  export let focusTrapSettings = null
  export let dataTest = null

  // Svelte has a bug regarding semi-colon insertion when this expression is defined in the template.
  // See https://github.com/sveltejs/svelte/issues/8239
  $: _style = `${style ? `; ${style}` : ''}`

  const dispatch = createEventDispatcher()
  let modalElem

  onDestroy(() => {
    // console.log('destroying modal')
    removeCloseListeners()
    removeModal()
  })

  $: headerClass = color == null ? '' : `modal-header-${color}`
  $: _focusTrapSettings = {
    active: true,
    ...focusTrapSettings,
  }

  // unique identifier for this modal
  const modalId = $modals.length + Date.now()

  // when user presses escape, close the modal
  const escapeListener = e => {
    const pressedEscape = (e.which || e.keyCode) === Key.Escape
    if (pressedEscape && wasLastModalOpen() && inputNotFocused() && dropdownNotFocused()) {
      e.preventDefault()
      close('escape')
    }
  }

  // when user clicks outside modal content, close the modal
  const backdropListener = e => {
    if (clickShouldClose(e) && wasLastModalOpen()) {
      close('backdrop')
      return false
    }
  }

  $: if (modalElem) {
    addCloseListeners()
    addModal()
  } else {
    removeCloseListeners()
  }

  function clickShouldClose(e) {
    const closest = e.target.closest == null ? e.target.parentElement == null ? null : e.target.parentElement.closest : e.target.closest
    if (closest == null) return false // they might've clicked outside, but we can't tell anyway

    const clickedOutsideModal =
      e.clientX != null && e.clientY != null && document.elementFromPoint(e.clientX, e.clientY) === modalElem && e.target.closest('option') == null //didn't click a select <option> (firefox Version 62.0 (64-bit) doesn't handle this automatically)

    if (!clickedOutsideModal) return false

    const clickedScrollbar = e.clientX != null && e.clientX + 10 >= window.innerWidth
    return !clickedScrollbar
  }

  function addCloseListeners() {
    lockBodyScroll()
    document.addEventListener('keydown', escapeListener)
    document.addEventListener('mousedown', backdropListener)
    document.addEventListener('touchstart', backdropListener)
  }

  function removeCloseListeners() {
    unlockBodyScroll()
    document.removeEventListener('keydown', escapeListener)
    document.removeEventListener('mousedown', backdropListener)
    document.removeEventListener('touchstart', backdropListener)
  }

  function close(reason) {
    dispatch('close', { reason })
  }

  function wasLastModalOpen() {
    return $modals.length > 0 && $modals.at(-1) === modalId
  }

  function inputNotFocused() {
    // make sure an in input element doesn't have focus--means user just wants to blur on that element if they press esc
    const inputFocused = document.activeElement?.nodeName?.toLowerCase() === 'input'
    return !inputFocused
  }

  function dropdownNotFocused() {
    return document.activeElement.closest('.quick-dropdown') == null
  }

  function closeIfEscape(e) {
    const key = e.which || e.keyCode
    if (key === Key.Escape) {
      close('escape')
      e.stopImmediatePropagation()
    }
  }

  function lockBodyScroll() {
    lockScroll(document.body)
  }

  function unlockBodyScroll() {
    if ($modals.length === 0) unlockScroll(document.body)
  }

  function addModal() {
    $modals = [...$modals, modalId]
  }

  function removeModal() {
    $modals = $modals.filter(m => m !== modalId)
    unlockBodyScroll()
  }
</script>

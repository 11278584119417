<div class="activity bg-white" class:drawer class:invisible={drawer && !isOpen}>
  {#if drawer}
    <div class="flex-row flex-justify-between flex-align-center bg-primary" style="padding: 15px; margin-bottom: 15px">
      <h3 class="flex-row flex-align-center g1 m0 text-white">
        <span>Activity</span>
        <Badge lg class="bg-white text-black" {count} />
      </h3>
      <a
        class="text-white"
        href={null}
        data-test="activity-panel-close-btn"
        on:click={() => {
          isOpen = false
          unreadActivityCount = null
        }}
      >
        <Icon name="close" x2 autoColor />
      </a>
    </div>
  {:else}
    <h3 class="flex-row flex-align-center g1 m0" style="padding: 15px">
      <span>Activity</span>
      <Badge lg {count} />
    </h3>
  {/if}

  <div style="padding: 0 15px">
    {#if !disableComments}
      <AddComment {onAddComment} {mentionableUsers} {disabled} {commentBoxHelpText} />

      {#if filterable && activity?.length > 0}
        <div class="mb2">
          <strong>Show</strong><br />
          <CommentActivityPicker
            bind:value={commentActivityFilter}
            dataTest="capacity-comment-activity-filter"
            btnActiveClass="btn-primary text-light"
            btnInactiveClass="btn-outline-primary color-text-teal btn-hover-light"
          />
        </div>
      {/if}

      {#if !_activity.length && activity?.length}
        <HelpBlock>No {commentActivityFilter === CommentActivityFilter.Comment ? 'comments' : 'activity'}</HelpBlock>
      {/if}
    {/if}
  </div>

  <div
    class="activity-items scrollable flex-column pr05"
    style="padding-left: 15px; padding-bottom: 120px; max-height: {disableComments ? '100vh' : 'calc(100vh - 300px)'}"
  >
    {#if activity == null}
      <Spinner />
    {:else if !activity.length}
      <HelpBlock>No activity</HelpBlock>
    {:else}
      {#each _activity as a, i}
        {@const allUnread = (_unreadActivityCount || _unreadCommentCount) && _dateTimeLastViewed == null}
        {@const thisIsUnread = a.dateCreated > _dateTimeLastViewed}
        {@const next = _activity[i + 1]}
        {@const nextIsRead = next ? next.dateCreated <= _dateTimeLastViewed : false}
        <div data-test="activity-item">
          <div class="flex-row flex-align-center g05">
            <ProfilePic src={a.profilePicture} name={a.name} initials={a.initials} id={a.userId} href={a.url} small class="mr05" />
            <div class="full-width">
              <div>
                <strong>{a.name}</strong>
                <div class="small em">
                  {#if a.isComment}
                    commented {dateService.calendarTimeLower(a.dateCreated)}
                  {:else}
                    {dateService.calendarTimeLower(a.dateCreated)}
                  {/if}
                </div>
              </div>
            </div>
          </div>
          <div class="flex-row flex-align-center g05">
            <div class="flex-row flex-align-center flex-justify-center mr05" style="width: 30px !important; height: 30px;">
              {#if a.statusName}
                <Icon name={a.statusIcon} class="float-left text-{a.statusColor}" title={formatEnumKey(a.statusName)} fw />
              {/if}
            </div>
            <div class="full-width">
              {#if a.isComment}
                <DisplayComment messageHtml={a.changeComment} />
              {:else}
                <span>{a.changeComment}</span>
              {/if}

              {#if a.changeDetails}
                &nbsp;
                <a use:tip={'View change details'} on:click={() => (changeToShow = a)} href={null}>
                  <Icon name="eye" />
                </a>
              {/if}
            </div>
          </div>
        </div>
        {#if (thisIsUnread && nextIsRead) || (allUnread && i === _activity.length - 1)}
          <UnreadLine style="padding-right: 15px" />
        {/if}
      {/each}
    {/if}
  </div>

  {#if changeToShow}
    <Modal on:close={() => (changeToShow = null)}>
      <h3 slot="title">Change details</h3>

      <div class="modal-body">
        <div class="mb2">{changeToShow.changeComment}</div>

        <Collapsible label="Compare raw change data">
          <DiffMerger
            oldVersion={JSON.stringify(changeToShow.changeDetails.previous, null, 2)}
            newVersion={JSON.stringify(changeToShow.changeDetails.current, null, 2)}
          />
        </Collapsible>
      </div>
      <div class="modal-footer" />
    </Modal>
  {/if}
</div>

<script>
  import { CommentActivityFilter } from 'config/enums.js'
  import { formatEnumKey } from 'services/formatters.js'
  import AddComment from 'components/AddComment.svelte'
  import Badge from 'components/Badge.svelte'
  import Collapsible from 'components/Collapsible.svelte'
  import CommentActivityPicker from 'components/fields/CommentActivityPicker.svelte'
  import dateService from 'services/date-service.js'
  import DiffMerger from 'components/fields/DiffMerger.svelte'
  import DisplayComment from 'components/DisplayComment.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import Modal from 'components/Modal.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import Spinner from 'components/Spinner.svelte'
  import UnreadLine from 'components/UnreadLine.svelte'
  import tip from 'decorators/tip.js'

  export let isOpen = true
  export let headerStyle = null
  export let disabled
  export let onAddComment
  export let activity
  export let commentBoxHelpText
  export let disableComments = false
  export let unreadActivityCount = null
  export let unreadCommentCount = null
  export let dateTimeLastViewed = null
  export let filterable = false
  export let updateDateTimeLastViewedActivity = null

  // TODO: load up with all the notifiable users (use capacityService/agreementService.ListNotifiableUsersAsync)
  //       and hook up like match activity comments hook them up server-side.
  const mentionableUsers = []
  let commentActivityFilter = CommentActivityFilter.All
  let changeToShow = null

  // Snapshot the dateTimeLastViewed when the panel is opened so we can display a "New" label on new items.
  // and not lose it due to reactive updates to dateTimeLastViewed.
  let _unreadActivityCount = null
  let _unreadCommentCount = null
  let _dateTimeLastViewed = null

  $: drawer = headerStyle === 'drawer'
  $: if (isOpen && dateTimeLastViewed) _dateTimeLastViewed ??= dateTimeLastViewed
  $: if (isOpen && unreadActivityCount) _unreadActivityCount ??= unreadActivityCount
  $: if (isOpen && unreadCommentCount) _unreadCommentCount ??= unreadCommentCount
  $: if (!isOpen) {
    _unreadActivityCount = null
    _unreadCommentCount = null
    _dateTimeLastViewed = null
  }

  $: count = _unreadActivityCount != null || _unreadCommentCount != null ? _unreadActivityCount + _unreadCommentCount : activity
  $: _activity = getFilteredActivity(activity, commentActivityFilter)
  $: dateTimeNewestVisible = isOpen && _activity.length ? getNewest(_activity) : null
  $: hasUpdateFn = updateDateTimeLastViewedActivity
  // Both these dateTime variables are either null or an ISO-8601 string (not necessarily at the same time; one could be null and the other not)
  // Therefore we can compare them with > and get the expected result.
  // If they were strings of numbers without -, T, and Z, we'd have to do something else.
  $: if (isOpen && hasUpdateFn && (dateTimeNewestVisible > _dateTimeLastViewed || (dateTimeNewestVisible && _dateTimeLastViewed == null)))
    updateDateTimeLastViewedActivity(dateTimeNewestVisible)

  function getFilteredActivity(activity, filter) {
    if (activity == null) return []
    if (filter === CommentActivityFilter.Comment) return activity.filter(a => a.isComment)
    if (filter === CommentActivityFilter.Activity) return activity.filter(a => !a.isComment)
    return activity
  }

  function getNewest(activity) {
    // Each record's dateCreated is an ISO-8601 string
    // So we can sort them lexicographically (aka just do a string sort)
    // See https://stackoverflow.com/a/12192544
    const dates = activity.map(a => a.dateCreated)
    dates.sort((a, b) => b - a)
    return dates[0]
  }
</script>

<style lang="scss">
  .activity {
    width: 400px;
    border-left: 1px solid #eee;

    &.drawer {
      position: fixed;
      inset: 0 0 0 auto;
      height: 100vh;
    }
  }

  @media only screen and (max-width: 1250px) {
    .activity {
      width: 100%;
      border-top: 1px solid #eee;
      border-left: none;
    }
  }
</style>

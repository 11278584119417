<span class={isDefaultSlot ? 'flex-row flex-align-center g05' : null}>
  {#if isDefaultSlot}
    <Icon lg name={option.iconName || (option.sortAscending ? 'caret-up' : 'caret-down')} />
  {/if}
  <span>{option.name}</span>
</span>

{#if option.description}
  <small class="flex-grow text-right" class:em={isDefaultSlot} style="font-size: 11px; opacity: 0.9">{option.description}</small>
{/if}

{#if isDefaultSlot && option.helpText}
  <div class="flex-grow text-right">
    <Help>{option.helpText}</Help>
  </div>
{/if}

<script>
  import Icon from 'components/Icon.svelte'
  import Help from 'components/Help.svelte'

  export let option
  export let isDefaultSlot = false
</script>

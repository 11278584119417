<script>
  import { navWidth } from 'stores/media-query.js'

  export let query = null // Make sure to add your own () if necessary
  // Width queries will be adjusted based on main/side nav current size.
  export let minWidth = null
  export let maxWidth = null
  export let onChange = _.noop

  $: query, minWidth, maxWidth, $navWidth, init()
  $: onChange(matches)

  let list
  let matches

  function onListChange(e) {
    matches = e.matches
  }

  function buildMediaQuery() {
    const queries = []
    if (query) queries.push(query)
    if (minWidth != null) queries.push(`(min-width: ${minWidth + $navWidth}px)`)
    if (maxWidth != null) queries.push(`(max-width: ${maxWidth + $navWidth}px)`)
    return queries.join(' and ')
  }

  function init() {
    list?.removeEventListener('change', onListChange)
    list = window.matchMedia(buildMediaQuery())
    matches = list.matches
    list?.addEventListener('change', onListChange)
  }
</script>

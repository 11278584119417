<h1 class="mb05">Services</h1>
<!-- TODO(services): some kind of description here indicating what services are for / that they can be used to tag opportunities / report on / etc -->

<PersonaFilterWarning />

{#if $persona.personaType === PersonaType.ProviderStaff && personaService.hasPermissionAnywhere(Permission.ManageOpportunitiesAndServices)}
  <Btn icon="plus" class="btn-default btn-sm mb1" href="/services/+" dataTest="add-service-btn">Add service</Btn>
{/if}

<div class="filters-sort-export">
  <ServiceFilters bind:filtersComponent {filters} onChanged={onFiltersChanged} bind:isQuickFiltering>
    <QuickFilterToggle />
  </ServiceFilters>

  <div class="flex-row flex-align-center g05" style="grid-area: export-sort">
    <ServiceGridShowDropdown />
    <ServiceSortDropdown bind:sortAscending bind:sortProperty onChange={() => (currentPage = 1)} />
    <ExportButtonExcel disabled={loading} exportFunc={exportExcel} />
    <ShareDropdown name="service" href={shareHref} />
  </div>
</div>

<ServiceGrid
  {services}
  viewOrEditHref="{baseHref}/[serviceId]"
  utilizationHref="{baseHref}/[serviceId]/utilization"
  staffHref="{baseHref}/[serviceId]/staff"
  copyHref="{baseHref}/+[serviceId]"
  {onDeleteClicked}
  bind:currentPage
  bind:pageSize
  {totalCount}
  {loading}
  {filtersComponent}
  $isQuickFiltering={isQuickFiltering}
/>

<Router>
  <Route path=":subpath/*" component={ServiceModal} {services} {listBodyIncludes} {onClose} {onSaved} />

  <Route path=":serviceId/staff/*" let:params>
    <!-- If the service isn't on the page, StaffRoleGridModal will load it itself -->
    <StaffRoleGridModal
      serviceId={+params.serviceId}
      service={services?.find(s => s.serviceId === +params.serviceId)}
      {onClose}
      {setWarningContext}
      {baseHref}
      bind:orgs
      bind:teams
    />
  </Route>

  <Route path=":serviceId/utilization/*" let:params>
    <!-- If the service isn't on the page, CapacityCalendarModal will load it itself -->
    <CapacityCalendarModal serviceId={+params.serviceId} service={services?.find(s => s.serviceId === +params.serviceId)} {onClose} {baseHref} />
  </Route>
</Router>

<ServiceConfirmDeleteModal service={deletingService} onDelete={onDeleted} onCancel={() => (deletingService = null)} />
<StaffFormWarningContextModal bind:warningContext />

<script>
  import { buildShareHref, parseShareHref } from 'components/ShareHref.svelte'
  import { FilterDecoder, FilterEncoder } from 'services/filters/index.js'
  import { navigate } from 'svelte-routing'
  import { Permission, PersonaType, ServiceListProperty } from 'config/enums.js'
  import { Router, Route } from 'svelte-routing'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import CapacityCalendarModal from 'components/CapacityCalendarModal.svelte'
  import ExportButtonExcel from 'components/ExportButtonExcel.svelte'
  import persona from 'stores/persona.js'
  import personaFilters from 'stores/persona-filters.js'
  import PersonaFilterWarning from 'components/PersonaFilterWarning.svelte'
  import personaService from 'services/persona-service.js'
  import QuickFilterToggle from 'components/QuickFilterToggle.svelte'
  import ServiceConfirmDeleteModal from 'pages/authorized/org/Services.ConfirmDeleteModal.svelte'
  import ServiceFilters, { buildFilterTypes, buildIgnoredFilterTypes } from 'components/ServiceFilters.svelte'
  import ServiceGrid from 'components/ServiceGrid.svelte'
  import ServiceGridShowDropdown from 'components/ServiceGridShowDropdown.svelte'
  import ServiceModal from 'components/ServiceModal.svelte'
  import ServiceSortDropdown, { sortOptions } from 'components/ServiceSortDropdown.svelte'
  import ShareDropdown from 'components/ShareDropdown.svelte'
  import StaffFormWarningContextModal from 'components/StaffForm.WarningContextModal.svelte'
  import StaffRoleGridModal from 'components/StaffRoleGridModal.svelte'
  import unsavedForms from 'stores/unsaved-forms.js'
  import validator from 'services/validator.js'
  import { getLocation } from 'stores/req.js'

  const location = getLocation()
  const baseHref = '/services'
  const defaultSortProperty = ServiceListProperty.OrganizationRelativeName
  const defaultSortAscending = true
  let sortProperty = defaultSortProperty
  let sortAscending = defaultSortAscending
  let currentPage = 1
  let pageSize = 10
  let loading = false
  let services = null
  let totalCount = null
  let filters = []
  let currentXhr = null
  let currentXhrBody = null
  let filtersComponent = null
  let deletingService = null
  let isQuickFiltering = false
  let hadModalOpen = $location.pathname.includes(`${baseHref}/`)

  // Set by <StaffRoleGridModal> the first time it's opened, then cached here via two-way binding
  // so if the user opens another staff modal, we won't have to make another request
  let orgs = null
  let teams = null

  // reload when persona, sort, page, or filters change
  $: personaFiltersOrgId = $personaFilters.orgId
  $: personaFiltersTeamId = $personaFilters.teamId
  $: personaFiltersOrgId, personaFiltersTeamId, sortProperty, sortAscending, currentPage, filtersComponent, load()
  $: personaFiltersOrgId, personaFiltersTeamId, resetOrgsAndTeams()

  $: filterTypes = buildFilterTypes()
  $: ignoredFilterTypes = buildIgnoredFilterTypes()
  $: filterDecoder = new FilterDecoder(filterTypes, ignoredFilterTypes)
  $: filterEncoder = new FilterEncoder(filterTypes, ignoredFilterTypes)
  $: commonShareArgs = {
    filterDecoder,
    sortOptions,
    defaultSortProperty,
    defaultSortAscending,
  }
  $: locationOrigin = $location.origin
  $: locationPathname = $location.pathname
  $: locationSearch = $location.search
  $: locationOrigin, locationPathname, locationSearch, loadInitial()
  $: shareHref = buildShareHref({
    baseHref,
    filterSets: [{ filters, filterEncoder }],
    sortProperty,
    sortAscending,
    ...commonShareArgs,
  })
  $: if (!hadModalOpen) navigate(shareHref, { replace: true })

  function onFiltersChanged(_filters) {
    currentPage = 1
    filters = _filters
    load()
  }

  function resetOrgsAndTeams() {
    orgs = null
    teams = null
  }

  function loadInitial() {
    const { pathname } = $location
    if (!pathname.includes(baseHref)) return
    if (pathname.includes(`${baseHref}/`)) {
      hadModalOpen = true
      return
    }
    if (hadModalOpen) {
      hadModalOpen = false
      return
    }
    const parsed = parseShareHref($location, commonShareArgs)
    if (!parsed) {
      // Reset state in case they came in with a query string and then clicked the nav.
      sortProperty = defaultSortProperty
      sortAscending = defaultSortAscending
      filters = []
      return load()
    }
    sortProperty = parsed.sortProperty
    sortAscending = parsed.sortAscending
    filters = parsed.filters ?? []
    load()
  }

  async function load() {
    // Wait for filtersComponent to update filters; it may automatically add some.
    if (!filtersComponent) return
    loading = true
    let thisXhr = null
    try {
      thisXhr = getGridData()
      const response = await thisXhr
      if (thisXhr === currentXhr) {
        totalCount = response.totalCount
        services = response.services
      }
    } finally {
      if (thisXhr === currentXhr) {
        loading = false
        currentXhr = null
        currentXhrBody = null
      }
    }
  }

  function exportExcel() {
    const body = buildGetDataBodyBase()
    return api.service.export(body, api.noMonitor)
  }

  const listBodyIncludes = {
    includeSpecialties: true,
    includeAgreements: true,
    includeDisciplines: true,
    includeCapacities: true,
    includeOrgAndTeam: true,
  }

  function buildGetDataBodyBase() {
    return {
      filters: _.cloneDeep(filters),
      sortProperty,
      sortAscending,
      ...listBodyIncludes,
    }
  }

  function getGridData() {
    const body = {
      ...buildGetDataBodyBase(),
      pageSize,
      offset: pageSize * (currentPage - 1),
    }

    if (currentXhr && validator.equals(currentXhrBody, body)) {
      return currentXhr
    }

    currentXhr = api.service.list(body, api.noMonitor)
    currentXhrBody = body
    return currentXhr
  }

  function onDeleteClicked(service) {
    deletingService = service
  }

  function onDeleted() {
    deletingService = null
    load()
  }

  function onClose() {
    unsavedForms.navigateSafe(shareHref)
  }

  function onSaved() {
    load()
  }

  let warningContext = null
  function setWarningContext(context) {
    warningContext = context
  }
</script>

<style lang="scss">
  .filters-sort-export {
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr auto;
    grid-template-rows: auto auto;
    grid-template-areas:
      'search export-sort'
      'filters filters';
  }
</style>

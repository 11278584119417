{#if warning}
  <Alert type="warning">{warning}</Alert>
{:else}
  <div class="calendar" class:selectable data-test="calendar" use:dragAndSelect={dragSelectOptions}>
    {#each [0, 1, 2, 3, 4, 5, 6] as d (d)}
      <div class="day-of-week-heading" style={weekdayLabelStyle}>{dateService.weekday(d).format(fullWeekdayLabel ? 'dddd' : 'ddd')}</div>
    {/each}
    {#each weeks as w (w)}
      {#each w as d (d.formatted)}
        {@const dayClass = dayClasses[d.formatted]}
        <div
          class="day flex-column{dayClass ? ` ${dayClass}` : ''} {d.disabled ? ` ${disabledClass}` : ''}{allDaysClass ? ` ${allDaysClass}` : ''}"
          style={dateStyle}
          data-date={selectable && !d.disabled ? d.formatted : null}
          data-test-date={d.formatted}
          on:click={() => (d.disabled ? null : dispatch('dayClick', d))}
          class:alt={d.alt}
          class:pre-selected={preselected.has(d.formatted)}
          class:selected={selected && selected.has(d.formatted)}
          class:not-selected={anySelected && !selected.has(d.formatted) && !preselected.has(d.formatted)}
        >
          <div class="flex-row flex-justify-between">
            <slot name="icons" day={d} />
            <div class="day-label" style={dayLabelStyle} data-test="day-of-month" class:badge={d.today} class:bg-info={d.today} class:today={d.today}>
              {#if !dispatchDateClick || d.disabled}
                {#if d.showMonth}
                  <strong>{dateService.dateformat(d.date, 'MMM')} {dateService.dateformat(d.date, 'D')}</strong>
                {:else}{dateService.dateformat(d.date, 'D')}{/if}
              {:else}
                <a on:click={() => dispatch('dateClick', d)} href={null}>
                  {#if d.showMonth}
                    <strong>{dateService.dateformat(d.date, 'MMM')} {dateService.dateformat(d.date, 'D')}</strong>
                  {:else}{dateService.dateformat(d.date, 'D')}{/if}
                </a>
              {/if}
            </div>
          </div>
          <slot day={d} />
        </div>
      {/each}
    {/each}
  </div>
{/if}

<script>
  import Alert from 'components/bootstrap/Alert.svelte'
  import { createEventDispatcher } from 'svelte'
  import dateService from 'services/date-service.js'
  import dragAndSelect from 'decorators/drag-and-select.js'

  const dispatch = createEventDispatcher()

  export let startDate
  export let endDate
  export let selected = null
  export let showMonthStartOfWeek = false
  export let min = null
  export let max = null
  export let dispatchDateClick = false
  export let fullWeekdayLabel = false
  export let weekdayLabelStyle = null
  export let allDaysClass = null
  export let dateStyle = null
  export let dayLabelStyle = null
  export let dayClasses = {}
  export let dateSelector = _.noop
  export let disabledClass = 'disabled'
  export let isDragSelectingDays = false

  let warning
  let preselected = new Set()

  $: selectable = selected != null
  $: anySelected = selected?.size > 0
  $: dragSelectOptions = selectable ? { selector: '[data-date]', onSelectDays, onPreSelect } : null

  $: days = (() => {
    if (!validate()) return []
    const today = dayjs().startOf('day')
    const days = []
    const startDateParsed = dayjs(startDate)
    let date = startDateParsed.startOf('week')
    const to = dayjs(endDate).endOf('week')
    let showMonth = true
    const firstMonthEven = startDateParsed.get('month') % 2 === 0 // always start with non-alt color for first month
    const altIfEven = !firstMonthEven
    while (date.isSameOrBefore(to)) {
      const evenMonth = date.get('month') % 2 === 0
      const day = {
        day: date,
        date: date.toDate(),
        formatted: date.format('M/D/YYYY'),
        formattedDashes: date.format('M-D-YYYY'),
        today: date.isSame(today, 'day'),
        showMonth: date.date() === 1 || showMonth || (showMonthStartOfWeek && date.day() === 0),
        disabled: !(date.isSameOrAfter(startDate) && date.isSameOrBefore(endDate)) || outsideMinMax(date),
        alt: (altIfEven && evenMonth) || (!altIfEven && !evenMonth),
      }
      days.push(day)
      date = date.add(1, 'days')
      showMonth = false
    }
    return days
  })()

  $: weeks = (() => {
    // Chunk into list of weeks:
    const weeks = []
    for (let i = 0, len = days.length; i < len; i += 7) {
      weeks.push(days.slice(i, i + 7))
    }
    return weeks
  })()

  function outsideMinMax(day) {
    if (min != null && dayjs(day).isBefore(min)) return true
    if (max != null && dayjs(max).isBefore(day)) return true
  }

  function validate() {
    if (startDate == null || endDate == null) return false
    const diff = dayjs(endDate).diff(dayjs(startDate), 'days')
    warning = diff > 1098 ? 'Date range is very long, so calendar will not be displayed' : null // ideally we'd just infinite scroll, but this should cover use-cases currently
    return warning === null
  }

  function onPreSelect(dayElems) {
    isDragSelectingDays = true
    preselected = new Set()
    for (const date of getDates(dayElems)) {
      if (!dateSelector(date)) preselected.add(date)
    }
  }

  function onSelectDays(dayElems) {
    isDragSelectingDays = false
    preselected = new Set()
    const dates = getDates(dayElems).filter(date => !dateSelector(date))
    const allSelected = dates.every(d => selected.has(d))
    for (const date of dates) {
      if (allSelected) selected.delete(date)
      else selected.add(date)
    }
    selected = selected // so svelte compiler knows to invalidate
  }

  function getDates(dayEls) {
    return dayEls.map(el => el.dataset.date)
  }
</script>

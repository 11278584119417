import { FeatureType, Permission } from 'config/enums.js'
import metadata from './step-report.js'

export default {
  ...metadata,
  permission: Permission.ViewApplicationStepsReport,
  featureTypes: [FeatureType.HealthInstitution, FeatureType.TeachingInstitution],
  componentName: 'ApplicationStepsReport',
  name: 'Application steps report',
  icon: 'PendingApplicationVerification',
  color: 'purple',
}

{#if capacityId > 0}
  <Activity
    {headerStyle}
    {disabled}
    {onAddComment}
    {activity}
    commentBoxHelpText="Comments are visible to internal staff at your organization who can view this opportunity. However, only those who can manage it are notified."
    disableComments={$persona.personaType !== PersonaType.ProviderStaff}
    bind:unreadActivityCount
    bind:unreadCommentCount
    bind:dateTimeLastViewed
    {updateDateTimeLastViewedActivity}
    filterable
    bind:isOpen
  />
{/if}

<script>
  import { onDestroy } from 'svelte'
  import { PersonaType } from 'config/enums.js'
  import Activity from 'components/Activity.svelte'
  import api from 'services/api.js'
  import persona from 'stores/persona.js'
  import sockets from 'services/sockets.js'

  export let headerStyle = null
  export let capacityId
  export let disabled
  export let saving = false
  export let isOpen = true
  export let unreadActivityCount
  export let unreadCommentCount
  export let dateTimeLastViewed

  let activity = null
  let outdated = true

  $: if (capacityId) getUnreadActivityCount()
  $: if (isOpen && outdated && capacityId) load()

  const unsubscribe = sockets.on('UI_Capacity', ui => {
    if (ui?.capacityId != capacityId) return
    if (saving) return
    outdated = true
    getUnreadActivityCount()
  })
  onDestroy(unsubscribe)

  async function load() {
    if (capacityId == null) {
      activity = []
      return
    }
    activity = await api.capacity.listActivity({ capacityId }, api.noMonitor)
    outdated = false
  }

  async function getUnreadActivityCount() {
    if (capacityId == null) return
    const response = await api.capacity.getUnreadActivityCount({ capacityId }, api.noMonitor)
    unreadActivityCount = response.unreadActivityCount ?? null
    unreadCommentCount = response.unreadCommentCount ?? null
    dateTimeLastViewed = response.dateTimeLastViewed ?? null
  }

  async function updateDateTimeLastViewedActivity(newestVisibleActivityDateTime) {
    await api.capacity.updateDateTimeLastViewedActivity({ capacityId, dateTimeLastViewed: newestVisibleActivityDateTime }, api.noMonitor)
    dateTimeLastViewed = newestVisibleActivityDateTime
  }

  async function onAddComment(message) {
    await api.capacity.addComment({ capacityId }, { message }, api.noMonitor)
    load()
  }
</script>

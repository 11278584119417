<div class="flex-row flex-align-center m0 mb05 g1 text-primary">
  <a
    class="m0"
    data-test="add-all"
    use:tip={'Add all placeholder students to this availability window'}
    href={null}
    on:click={() => (value = match.placeholderStudentCount)}
  >
    Add all
  </a>
  <a
    class="m0"
    data-test="remove-all"
    use:tip={'Remove all placeholder students from this availability window'}
    href={null}
    on:click={() => (value = 0)}
  >
    Remove all
  </a>
</div>
<div class="placeholders mb05">
  <div class="flex-row flex-align-center">
    <PlaceholderStudentProfilePic sm noTip class="mr1" />
    <InputNumberWithUnit
      bind:value
      max={match.placeholderStudentCount}
      min={0}
      unit="of {match.placeholderStudentCount}"
      alwaysSingularUnit
      condense
    />
  </div>
</div>

<script>
  import PlaceholderStudentProfilePic from 'components/PlaceholderStudentProfilePic.svelte'
  import InputNumberWithUnit from 'components/InputNumberWithUnit.svelte'
  import tip from 'decorators/tip'

  export let match
  export let value
</script>

<Modal lg on:close={() => routingConfig.close()}>
  <h4 slot="title">
    <div class:small={capacity?.name}>Editing opportunity staff</div>
    {capacity?.name ?? ''}
  </h4>
  {#if loading || !orgStaff}
    <div class="p3">
      <Loading />
    </div>
  {:else if orgStaff && capacity}
    <div class="flex-row flex-wrap flex-align-stretch">
      <div class="flex-grow flex-column main-form-content p2">
        <StaffHeader user={orgStaff.user} bind:capacityAccessibilityTooltip />
        <StaffForm
          bind:this={staffForm}
          bind:hasChanges
          bind:capacityAccessibilityTooltip
          bind:orgStaffRoles
          on:reloadStaff
          {capacity}
          {orgStaff}
          {routingConfig}
          {setWarningContext}
          {orgs}
          {teams}
          accessType="opportunity"
          accessTypeIcon="list"
          accessTypeName={capacity.name}
          onRemoveCapacityStaff={onRemoveStaff}
          isCapacityStaff
          showFooter
        />
      </div>
      <div class="line" />
      <div class="flex-column right-panel p2">
        <CapacityStaffTitles bind:selectedTitles {capacity} staffId={orgStaff?.staffId} class="well" />
      </div>
    </div>
    <StaffFooter
      {staffForm}
      disabled={!hasChanges && !titlesHasChanges}
      {orgStaff}
      {routingConfig}
      onBeforeAction={() => (titlesHasChanges = false)}
      deleteButtonLabel="Remove from opportunity"
      isCapacityStaff
    />
  {/if}
</Modal>

<script>
  import api from 'services/api.js'
  import { tick } from 'svelte'
  import persona from 'stores/persona.js'
  import CapacityStaffTitles from 'components/CapacityStaffTitles.svelte'
  import Modal from 'components/Modal.svelte'
  import StaffForm from 'components/StaffForm.svelte'
  import Loading from 'components/Loading.svelte'
  import roleService from 'services/role-service'
  import StaffHeader from 'components/StaffHeader.svelte'
  import StaffFooter from 'components/StaffFooter.svelte'
  import validator from 'services/validator.js'
  import unsavedForms from 'stores/unsaved-forms.js'

  export let orgs
  export let teams
  export let setWarningContext
  export let onClose
  export let capacity
  export let staffId
  export let onRemoveStaff = _.noop
  export let selectedTitles
  export let capacityAccessibilityTooltip = null
  export let routingConfig

  let loading = false
  let orgStaff
  let staffForm
  let hasChanges
  let selectedTitlesInitial
  let orgStaffRoles
  const form = 'CapacityVisibleStaffModal'

  $: if (staffId) loadStaff()
  $: routingConfig = {
    close: () => onClose(hasChanges || titlesHasChanges),
  }
  $: titlesHasChanges = !validator.equals(selectedTitles, selectedTitlesInitial)
  $: hasChanges || titlesHasChanges ? unsavedForms.add(form) : unsavedForms.del(form)
  $: if (orgStaffRoles) {
    capacityAccessibilityTooltip = roleService.getCapacityAccessibilityTooltip(capacity, orgStaffRoles)
  }

  async function loadStaff() {
    loading = true
    try {
      const response = await api.staff.list({ orgId: $persona.orgId, staffId, includeLastActivityDate: true, includeEmail: true }, api.noMonitor)
      orgStaff = response.staff?.shift()
    } finally {
      loading = false
    }
    await tick()
    selectedTitlesInitial = _.cloneDeep(selectedTitles)
  }
</script>

<style lang="scss">
  .line {
    width: 1px;
    background-color: #000;
    opacity: 0.2;
  }
  .right-panel {
    width: 280px;
  }

  .main-form-content {
    width: calc(100% - 300px);
  }

  @media only screen and (max-width: 1250px) {
    .main-form-content {
      width: 100%;
    }
  }
</style>

// autosize a textarea
// <textarea use:autosize ...></textarea>

import autosize from 'autosize'
import { tick } from 'svelte'

const autosizeDecorator = function (node) {
  autosize(node)

  // when value changes, update autosize
  node.addEventListener('keyup', onValChange)

  async function onValChange() {
    await tick()
    autosize.update(node)
  }

  return {
    destroy() {
      autosize.destroy(node)
      node.removeEventListener('keyup', onValChange)
    },
  }
}

export default autosizeDecorator

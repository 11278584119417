import { readable } from 'svelte/store'
import initial from 'stores/initial.js'
import loadExternalScript from 'services/load-external-script.js'

let setter = null
const value = {
  ready: false,
  clientId: null,
}

initial.subscribe(v => {
  value.clientId = v.adobeEmbedClientId
  if (setter) setter({ ...value })
})

const store = readable(value, set => {
  setter = set
})

export function initAdobeEmbed() {
  document.addEventListener('adobe_dc_view_sdk.ready', () => {
    value.ready = true
    if (setter) setter({ ...value })
  })

  loadExternalScript('https://acrobatservices.adobe.com/view-sdk/viewer.js', 'Adobe PDF Embed API')
}

export default store

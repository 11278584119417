<h4 class="m0 normal">People</h4>
<div class="flex-row flex-align-center m0 g1 text-primary">
  <a class="m0" data-test="select-all" use:tip={'Add all people to this availability window'} href={null} on:click={() => onClickShift(shift, 'all')}>
    Select all
  </a>
  <a
    class="m0"
    data-test="select-none"
    use:tip={'Remove all people from this availability window'}
    href={null}
    on:click={() => onClickShift(shift, 'none')}
    disabled={meta[shift.shiftId].some}
  >
    Select none
  </a>
</div>
<div class="people m0 p0">
  {#each match.matchUsers as u}
    {#if schedulableUsers[u.userId]}
      <div class="checkbox checkbox-primary" on:click={() => onClickPerson(shift, u)}>
        <!-- must keep parent checkbox in sync manually: https://github.com/sveltejs/svelte/issues/4836 -->
        <input
          data-test="person-checkbox"
          type="checkbox"
          checked={meta[shift.shiftId][u.userId].all}
          indeterminate={meta[shift.shiftId][u.userId].some && !meta[shift.shiftId][u.userId].all}
          on:change={e => {
            e.target.checked = meta[shift.shiftId].all || meta[shift.shiftId][u.userId].all
            e.target.indeterminate = meta[shift.shiftId][u.userId].some && !(meta[shift.shiftId].all || meta[shift.shiftId][u.userId].all)
          }}
        />
        <label>
          <ProfilePic src={u.profilePicture} initials={u.initials} name={u.name} id={u.userId} class="mr1" medium />
          <div class="inline-block">
            <strong>{u.name}</strong>
            <p class="m0">{formatEnumValue(MatchRole, u.matchRole)}</p>
          </div>
        </label>
      </div>
    {/if}
  {/each}
</div>

<script>
  import { MatchRole } from 'config/enums'
  import { formatEnumValue } from 'services/formatters.js'
  import ProfilePic from 'components/ProfilePic.svelte'
  import tip from 'decorators/tip'

  export let shift
  export let meta
  export let match
  export let schedulableUsers

  export let onClickShift = _.noop
  export let onClickPerson = _.noop
</script>

<div on:keydown={handleKeydown}>
  <!--we add id, so ProfileProgress works...nothing else needs this picker to have an id set-->
  <QuickDropdown bind:this={quickDropdown} {id} {dataTest} class={className} {btnClass} bind:isOpen dropdownClass="p1" {disabled} on:open on:close>
    <span slot="label">
      <slot name="prefix" />

      {#if !enabledSelected?.length}
        {placeholder}
      {:else}
        <FriendlyList
          items={enabledSelected}
          {or}
          punctuation
          toggleable={false}
          showOthersTip
          othersTipOptions={{ theme: 'light-gray-scrollable' }}
          othersTipClass="text-left"
        />
      {/if}
    </span>

    <div class="disciplines-picker-filter">
      <Filter bind:text={search} autofocus id="{id}-filter" />
    </div>

    <div class="scrollable-md">
      {#if loading}
        <Spinner />
      {:else}
        <DisciplinesPickerRenderChildren tree={treeFiltered} {multiple} {toggleSelected} {disabledOptionsMsg} {allowParentSelections} />
      {/if}
    </div>
  </QuickDropdown>
</div>

<script>
  import { tick } from 'svelte'
  import api from 'services/api.js'
  import DisciplinesPickerRenderChildren from 'components/fields/DisciplinesPicker.RenderChildren.svelte'
  import Filter from 'components/Filter.svelte'
  import FriendlyList from 'components/FriendlyList.svelte'
  import Key from 'config/key.js'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import Spinner from 'components/Spinner.svelte'
  import validator from 'services/validator.js'

  export let value = []
  export let options = null
  export let multiple = true
  let className = 'inline-block'
  export { className as class }
  export let btnClass = 'btn btn-default'
  export let placeholder = 'None selected'
  export let disabled = false
  export let enabledOptions = null // ['label', ...] or [{ label, studentYearRequired }, ...]
  export let disabledOptionsMsg = null
  export let dataTest = 'discipline-picker'
  export let id = null
  export let hideDisabledOptions = false
  export let allowParentSelections = true
  export let leaveDisabledOptionsSelected = false
  export let or = false

  let search
  let loading = false
  let isOpen = false
  let tree
  let treeFiltered
  let enabledOptionsPrev

  setOptions()

  $: if (!isOpen) search = null
  $: options, selected, setTree()
  $: tree, search, options, setTreeFiltered()

  // when enabledOptions changes, clear any selection that is now not selectable
  $: if (enabledOptions && !validator.equals(enabledOptions, enabledOptionsPrev)) {
    enabledOptionsPrev = enabledOptions
    clearAnyNotSelectable()
  }

  $: selected = value == null ? (multiple ? [] : null) : multiple ? value : [value]
  $: enabledSelected = filterEnabled(selected)

  function filterEnabled(selected) {
    return enabledOptions?.length ? selected.filter(s => enabledOptions.some(e => e.label === s || e === s)) : selected
  }

  async function clearAnyNotSelectable() {
    if (leaveDisabledOptionsSelected) {
      setTree()
      setTreeFiltered() // Reactivity should be calling this automatically, but it's not...
      value = value // Update view
      return
    }
    const newSelected = filterEnabled(selected)
    hardSetValue(multiple ? newSelected : newSelected[0] ?? null)
  }

  function handleKeydown(e) {
    const pressedEscape = (e.which || e.keyCode) === Key.Escape
    if (!pressedEscape) return
    e.stopImmediatePropagation()
    isOpen = false
  }

  function setTree() {
    tree = buildTree(options)
  }

  function buildTree(opts) {
    let _options = _.cloneDeep(opts)
    if (_options == null || _options.length === 0) return null
    if (hideDisabledOptions) {
      _options = _options.filter(o => !isDisabled(o))
    }

    const recursivelySetChildren = d => {
      const isSelected = multiple ? selected.includes(d.label) : selected === d.label
      const disabled = isDisabled(d)
      const children = _options.filter(o => o.parent === d.label).map(recursivelySetChildren)
      // we always use ALL options to determine if parent, so when filtering the parent
      // options are still not selectable if the component is configured to not allow parent selection
      const isParent = options.some(o => o.parent === d.label)
      d = {
        ...d,
        selected: isSelected,
        disabled,
        children,
        isParent,
      }

      // if it has children, determine whether it's indeterminate or fully selected based on children
      // const children = getFlatDisciplines(d, false).filter(df => df != d)
      // if (children.length) {
      //     // indeterminate if some children are selected AND some children are not selected
      //     const anySelected = children.some(c => selected.some(s => s === c.label))
      //     const anyNotSelected = children.some(c => !selected.some(s => s === c.label))
      //     d.indeterminate = anySelected && anyNotSelected
      //     // fully selected if all children are selected
      //     d.selected = anySelected && !anyNotSelected
      // }

      return d
    }

    // Either explicitly doesn't have a parent, or its parent is itself, or its parent is not in the visible options.
    // In the future, we'll enforce this with a null `ParentDisciplineID`.
    const roots = _options.filter(o => o.parent == null || o.parent === o.label || !_options.some(p => p.label === o.parent))
    return roots.map(recursivelySetChildren)
  }

  function setTreeFiltered() {
    if (options == null || validator.empty(search)) return (treeFiltered = tree)

    // consider also showing the parent even if it's not a match like we do with org filter, but just filtering down to flat actually feels pretty good
    treeFiltered = buildTree(options.filter(o => o.label.toLowerCase().includes(search.toLowerCase())))
  }

  function setOptions() {
    if (options == null) {
      loading = true
      api.tag
        .listDisciplines(api.noMonitor)
        .then(ops => (options = ops))
        .finally(() => (loading = false))
    }
  }

  function toggleSelected(discipline) {
    let newValue
    if (multiple) {
      const isSelected = selected.includes(discipline.label)
      const disciplineFlat = getFlatDisciplines(discipline, true).map(d => d.label)
      const others = selected.filter(s => !disciplineFlat.includes(s))
      newValue = isSelected ? others : [...others, ...disciplineFlat]
      newValue = newValue.filter(d => !isDisabled({ label: d })) // can only select non-disabled options
    } else {
      newValue = discipline.label
      isOpen = false
    }
    hardSetValue(newValue)
  }

  function getFlatDisciplines(discipline, includeSelf) {
    const recursivelyGetChildren = d => {
      const directChildren = d.children || []
      return [directChildren, ...directChildren.map(d => recursivelyGetChildren(d))].flat()
    }
    const allchildren = recursivelyGetChildren(discipline)
    const flat = includeSelf ? [discipline, ...allchildren] : allchildren
    return flat
  }

  function isDisabled(d) {
    if (enabledOptions == null || enabledOptions.length == 0) return false
    return !enabledOptions.some(o => o.label === d.label || o === d.label)
  }

  async function hardSetValue(val) {
    // svelte bug probably: https://github.com/sveltejs/svelte/issues/4165
    // scenario: edit service, deselect a discipline at service level, staff discipline picker(s) should deselect that option too if they have it selected
    await tick()
    value = val
  }

  let quickDropdown = null
  export function focusAndOpen() {
    quickDropdown?.focusAndOpen()
  }
</script>

<style lang="scss">
  .disciplines-picker-filter {
    min-width: 200px;
    max-width: 400px;
  }
</style>

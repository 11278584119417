<div class={sm ? null : 'relative'} style={sm ? null : 'width: 24px; height: 24px'} use:tip={tooltip}>
  <Icon name="sync-alt" spin x2={!sm} class="{sm ? '' : 'absolute left top'}{className ? ` ${className}` : ''}" />

  {#if !sm}
    <Icon name="alert" sm class="absolute center{className ? ` ${className}` : ''}" />
  {/if}
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'

  export let sm = false
  let className = null
  export { className as class }
  export let tooltip = null
</script>

<Modal on:close={close}>
  <div slot="title">
    <div class="flex-row flex-align-center g1">
      <ProfilePic src={user.profilePicture} initials={user.initials} id={user.userId} name={user.name} />
      <div>
        <h3><Icon name="time" /> {user.name}</h3>
      </div>
    </div>
  </div>
  <div class="modal-body">
    <HourLoggerUserSummary totalExpectedHours={user.totalExpectedHours} totalLoggedHours={status.totalHrs} {totalScheduledHours} />
    {#if status}<p class="text-{status.color}">{status.desc}</p>{/if}
    {#if editing && editing.hourLogId == null}
      <HourLogForm log={editing} {match} {logs} {supervisors} {hourLogsMustBeVerified} {min} {max} on:cancel={stopEditing} on:saved={onChanged} />
    {:else if logs}
      <div class="mb1">
        <a href={null} on:click={addNew}>
          <Icon name="plus" />
          New
        </a>
      </div>
    {/if}
    <div class="flex-row flex-align-center">
      {#if loading}
        <Spinner />
      {/if}
    </div>
    <HourLogList {date} {match} {logs} {supervisors} {hourLogsMustBeVerified} {min} {max} bind:editing on:changed={onChanged} />
  </div>
  <div class="modal-footer">
    <Btn on:click={close} dataTest="close-hours-modal">Close</Btn>
  </div>
</Modal>

<script>
  import { createEventDispatcher, tick } from 'svelte'
  import { getHoursSummary, prepareHoursFromServer } from 'services/hourlog-service.js'
  import { HourType, MatchRole } from 'config/enums.js'
  import api from 'services/api.js'
  import Btn from './bootstrap/Btn.svelte'
  import HourLogForm from './HourLogForm.svelte'
  import HourLogList from './HourLogList.svelte'
  import Icon from './Icon.svelte'
  import Modal from './Modal.svelte'
  import ProfilePic from './ProfilePic.svelte'
  import Spinner from './Spinner.svelte'
  import HourLoggerUserSummary from './HourLoggerUserSummary.svelte'

  export let user
  export let match
  export let matchDaysComputed
  export let date = null
  export let totalScheduledHours

  const dispatch = createEventDispatcher()
  let loading = false
  let logsAll = null
  let logs = null
  let editing = null

  $: hourLogsMustBeVerified = match.hourLogsMustBeVerified
  $: min = match.startDate
  $: max = match.endDate
  $: status = getHoursSummary(user, match, matchDaysComputed, date)
  $: supervisorsDict = _.groupBy(
    match.matchUsers.filter(mu => mu.matchRole !== MatchRole.Student),
    'userId'
  )
  $: supervisors = Object.values(supervisorsDict).map(s => s[0])

  load().then(assumeAddingIfNoneToVerify)

  async function load() {
    loading = true
    logsAll = await api.hourLog
      .list(
        {
          userId: user.userId,
          matchId: match.matchId,
        },
        api.noMonitor
      )
      .finally(() => (loading = false))
    logsAll = prepareHoursFromServer(logsAll)
    logsAll = logsAll.map(l => ({
      ...l,
      verifier: l.verifiedUserId == null ? null : supervisors.find(mu => mu.userId === l.verifiedUserId),
    }))

    // if filtering by single date, only show logs for that date
    logs = date ? logsAll.filter(l => l.date === date) : logsAll
  }

  async function assumeAddingIfNoneToVerify() {
    await tick() // so logsToVerify gets set
    // always pop open if filtered to specific date and no logs for that date exist yet
    if (date && logs.length === 0) addNew()
    // upon opening, if looks like hours are still needed and no hours to verify and not already in add-mode, go into add-mode
    else if (status.daysMissingHrsInPast.length > 0 && status.logsToVerify.length === 0 && (editing == null || editing.hourLogId == null)) addNew()
    else editing = null
  }

  function addNew() {
    editing = setDefaults({
      matchId: match.matchId,
      userId: user.userId,
      type: HourType.OnSite,
      orgId: match.locations?.length ? match.locations[0].orgId : match.hostOrgId,
    })
  }

  async function onChanged() {
    await load()
    dispatch('change', { user, logs: logsAll })

    if (date) {
      editing = null
    } else {
      await tick()
      if (status.daysMissingHrsInPast.length > 0) {
        // go to next day/shift-time as they log hours so they can quickly fill in missing hours
        if (editing == null) addNew()
        else if (editing.hourLogId == null) editing = setDefaults(editing)
      } else {
        // once caught up or complete, close form
        editing = null
      }
    }
  }

  function setDefaults(log) {
    const firstDayNotFilled = status.userMatchDays.find(md => !logs.some(l => l.date === md.date))
    const defaultDate = date || (firstDayNotFilled ? firstDayNotFilled.date : dayjs().format('M/D/YYYY'))
    const matchDay = status.userMatchDays.find(md => md.date === defaultDate)
    const defaultShift = (matchDay ? match.shifts.find(s => s.shiftId === matchDay.shiftId) : null) || match.shifts[0]
    return {
      ...log,
      date: defaultDate,
      from: defaultShift.startTime,
      to: defaultShift.endTime,
    }
  }

  function stopEditing() {
    editing = null
  }

  function close() {
    dispatch('close')
  }
</script>

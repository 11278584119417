<div class={className} class:g05={xs || small} class:g1={medium || large || auto || noSize}>
  <div class={profilePicClass} style={profilePicStyle}>
    <ProfilePic {name} src={profilePicture} {xs} {small} {medium} {large} size={_size} {href} {special} dataTest="profile-pic-{name}" />
    <slot name="profile-pic" />
  </div>

  <div class={contentClass} class:overflow-hidden={useOverflowEllipsis}>
    {#if icon}
      <div class="flex-row {hasAncestors ? 'flex-align-start' : 'flex-align-center'} g05">
        <Icon name={icon} class={iconClass} style={hasAncestors ? 'margin-top: 2px' : null} />
        <div class:overflow-hidden={useOverflowEllipsis}>
          <OrgProfilePictureAndNameNames {name} {nameClass} {relativeName} {maxNameLength} {useOverflowEllipsis} {href} />
        </div>
      </div>
    {:else}
      <OrgProfilePictureAndNameNames {name} {nameClass} {relativeName} {maxNameLength} {useOverflowEllipsis} {href} />
    {/if}
    {#if address != null}
      <OverflowEllipsis wrapper="div" disabled={!useOverflowEllipsis}>
        <FormattedAddress item={address} />
      </OverflowEllipsis>
    {:else if formattedAddress != null}
      {#if icon}
        <div class="flex-row flex-align-center g05">
          <Icon name="map-marker" class={iconClass} />
          <span class="small">{formattedAddress}</span>
        </div>
      {:else}
        <OverflowEllipsis wrapper="div" class="small em" disabled={!useOverflowEllipsis}>
          {formattedAddress}
        </OverflowEllipsis>
      {/if}
    {/if}

    <slot />
  </div>
</div>

<script>
  import FormattedAddress from 'components/FormattedAddress.svelte'
  import Icon from 'components/Icon.svelte'
  import ProfilePic from 'components/ProfilePic.svelte'
  import OrgProfilePictureAndNameNames from 'components/OrgProfilePictureAndName.Names.svelte'
  import OverflowEllipsis from 'components/OverflowEllipsis.svelte'

  export let name
  export let relativeName = null
  export let profilePicture = null
  export let address = null
  export let formattedAddress = null
  export let size = 'small'
  export let href = null
  let className = 'flex-row flex-align-center nowrap'
  export { className as class }
  export let profilePicClass = null
  export let profilePicStyle = null
  export let contentClass = null
  export let nameClass = null
  export let maxNameLength = Infinity
  export let icon = null
  export let iconClass = null
  export let useOverflowEllipsis = false
  export let special = false

  $: hasAncestors = relativeName && relativeName !== name
  $: auto = size === 'auto' // Handles if there's an address, but not slotted content
  $: xs = size === 'xs'
  $: small = size === 'small'
  $: _medium = size === 'medium'
  $: medium = _medium || (auto && (!hasAncestors || (!address && !formattedAddress)))
  $: _size = auto && hasAncestors && (address || formattedAddress) ? '58px' : null
  $: large = size === 'large'
  $: noSize = !xs && !small && !_medium && !large
</script>

<style>
  .address {
    font-weight: 400;
    font-size: 10px;
    color: #334155;
  }
</style>

{#if stepSubmission.status !== StepSubmissionStatus.Rejected}
  <Btn {disabled} on:click={startReject} class="btn-danger{btnSm ? ' btn-sm' : ''}">Reject</Btn>
{/if}

{#if rejectingSub}
  <Modal on:close={close}>
    <h4 slot="title" data-test="reject-step-title">Reject step: {stepSubmission.step.name}</h4>

    <Form on:submit={reject}>
      <div class="p2">
        <FormGroup>
          <label for="rejected-reason">Reason</label>
          <InputTextarea name="rejected-reason" bind:value={rejectedReason} maxlength="500" />
        </FormGroup>

        {#if stepSubmission.matchId}
          <FormGroup>
            <InputCheckbox name="close-match" bind:checked={closeMatch}>Close rotation?</InputCheckbox>
          </FormGroup>
        {/if}
      </div>
      <div class="modal-footer">
        <SubmitBtn color="danger" dataTest="reject-step-btn" icon="save" {loading}>Submit</SubmitBtn>
      </div>
    </Form>
  </Modal>
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import SubmitBtn from 'components/bootstrap/SubmitBtn.svelte'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import InputTextarea from 'components/fields/InputTextarea.svelte'
  import Form from 'components/Form.svelte'
  import Modal from 'components/Modal.svelte'
  import { StepAction, StepSubmissionStatus } from 'config/enums.js'
  import api from 'services/api.js'

  export let stepSubmission
  export let matchModalMatchId
  export let startDateOverride
  export let endDateOverride
  export let onAction
  export let btnSm
  export let disabled = false

  let rejectingSub = false
  let rejectedReason = ''
  let closeMatch = false
  let loading = false

  $: matchModalMatchId, close()

  function startReject() {
    rejectingSub = true
  }

  async function reject() {
    loading = true
    try {
      await onAction({
        type: StepAction.Reject,
        invoke,
        reset: close,
        alwaysShowOthers: true,
      })
    } finally {
      loading = false
    }
  }

  function invoke(stepSubmissions) {
    return api.step.setStepsRejected(
      {
        startDateOverride,
        endDateOverride,
      },
      {
        rejectedReason,
        closeMatch,
        stepSubmissions,
      },
      api.noMonitor
    )
  }

  function close() {
    rejectingSub = false
    rejectedReason = ''
  }
</script>

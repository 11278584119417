<FriendlyList items={groups} let:item={group}>
  {@const students = course.students.filter(s => s.courseStudentGroupId == group.courseStudentGroupId)}
  {@const isSelected = selectedStudentUserIds?.length === students.length && students.every(s => selectedStudentUserIds.includes(s.userId))}
  <Btn
    icon={isSelected ? 'check' : null}
    class="btn-sm{isSelected ? ' btn-outline-primary' : ''}"
    on:click={() => onSelect(group)}
    title={toFriendlyList(students.map(s => s.name))}
  >
    {group.name}
    {#if group.courseStudentGroupId !== -1}
      <Badge label={students.length} />
    {/if}
  </Btn>
</FriendlyList>

<script>
  import { toFriendlyList } from 'services/string-utils'
  import Badge from './Badge.svelte'
  import Btn from './bootstrap/Btn.svelte'
  import FriendlyList from './FriendlyList.svelte'

  export let course
  export let onSelect
  export let selectedStudentUserIds

  $: hasUngroupedStudents = course.students.some(s => s.courseStudentGroupId == null)
  $: groups = hasUngroupedStudents ? [{ name: 'Ungrouped students', courseStudentGroupId: null }, ...course.studentGroups] : course.studentGroups
</script>

<FilterTypeStep {filter} {filterOptions} {editing} {appliedMeta} isStepSelectionOptional />

<script context="module">
  import FilterTypeStep, { meta as baseMeta } from './FilterTypeStep.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.MatchHasIncompleteSteps,
    label: 'Has incomplete step',
    labelApplied: 'incomplete step',
    hasDoesntHave: true,
    icon: 'clipboard-list',
    iconClass: 'color-text-orange',
    canHaveMultiple: true,
    optionsEndpoint: 'getSteps',
    validate(/* filter, filters, appliedMeta */) {
      return true // doesn't need to have steps selected
    },
  }
</script>

<script>
  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta
</script>

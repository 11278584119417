<!-- we put use:links on the outer app div to make routing happen for all relative URL <a> clicks, too: https://github.com/EmilTholin/svelte-routing#links -->
<div use:links>
  <ConfirmNavigateAway>
    <GlobalLoading show={!$initial.loaded} />
    <GlobalErrors />
    <GlobalConfirms />
    <ExportJobs />
    <Toasts />
    <Router>
      <OnRouteChange />
      <div class="rows">
        <div class="columns">
          {#if $initial.loaded}
            {#if $user}
              <IdleTimer timeoutMinutes={$user.idleTimeout} />
              {#if !$user.hasAcceptedLatestEula && !$user.isImpersonating}
                <EulaForceAccept />
              {:else}
                <AuthenticatedNavigation />
                <AuthenticatedRoutes />
                <SharedRoutes />
              {/if}
            {:else}
              <PublicRoutes />
              <SharedRoutes />
            {/if}
          {/if}
        </div>
      </div>
    </Router>
    <div id="modals" />
    <Spotlights />
  </ConfirmNavigateAway>
  <Intercom />
  <ImpersonationOverlay />
</div>

<script>
  import { flushAppInsights, throwError, WillRejectUnhandled } from 'services/errors.js'
  import { handleExportJobUpdate, loadExportJobs } from 'services/export-job-service.js'
  import { initAdobeEmbed } from 'stores/adobe-embed.js'
  import { loadMiniProfiler } from 'services/load-mini-profiler.js'
  import { Router, links, navigate } from 'svelte-routing'
  import { unreadMessageCounts, totalUnreadMessageCount } from 'stores/unread-message-counts.js'
  import api from 'services/api.js'
  import AuthenticatedNavigation from 'components/AuthenticatedNavigation.svelte'
  import AuthenticatedRoutes from 'components/routing/AuthenticatedRoutes.svelte'
  import ConfirmNavigateAway from 'components/routing/ConfirmNavigateAway.svelte'
  import connections from 'stores/connections.js'
  import environment from 'services/environment.js'
  import EulaForceAccept from 'components/EulaForceAccept.svelte'
  import ExportJobs from 'components/ExportJobs.svelte'
  import featureFlags from 'stores/feature-flags.js'
  import GlobalConfirms from 'components/GlobalConfirms.svelte'
  import GlobalErrors from 'components/GlobalErrors.svelte'
  import GlobalLoading from 'components/GlobalLoading.svelte'
  import IdleTimer from 'components/IdleTimer.svelte'
  import ImpersonationOverlay from 'components/ImpersonationOverlay.svelte'
  import initial from 'stores/initial.js'
  import Intercom from 'Intercom.svelte'
  import notificationService from 'services/notification-service.js'
  import OnRouteChange from 'components/routing/OnRouteChange.svelte'
  import persona from 'stores/persona.js'
  import personaFilters from 'stores/persona-filters.js'
  import PublicRoutes from 'components/routing/PublicRoutes.svelte'
  import savedViews from 'stores/saved-views.js'
  import SharedRoutes from 'components/routing/SharedRoutes.svelte'
  import Spotlights from 'components/Spotlights.svelte'
  import sockets from 'services/sockets.js'
  import toaster from 'services/toaster.js'
  import Toasts from 'components/Toasts.svelte'
  import unreadNotificationCount from 'stores/unread-notification-count.js'
  import user from 'stores/user.js'

  sockets.connect()

  let initiated = false

  // expose some stuff for cypress when testing
  if (window.Cypress) {
    window.__navigate = navigate
    window.__clearToasts = () => toaster.clear()
    window.__getPermissions = () => $persona?.permissions
    window.__getPersonaAndFilters = () => ({ persona: $persona, personaFilters: $personaFilters })
    window.__flushAppInsights = flushAppInsights
    window.__unhandledRejection = WillRejectUnhandled
    window.__throwError = throwError
    window.__overrideFeatureFlag = (flag, value) => featureFlags.overrideFeatureFlag(flag, value)
  }

  $: isLoggedIn = $initial.prefetch?.profile != null
  $: if ($initial.profilingEnabled) loadMiniProfiler()
  $: if (isLoggedIn && !initiated) initiateUserAndLeftNavCounts()
  $: totalUpdatesCount = $unreadNotificationCount ?? 0 + totalUnreadMessageCount ?? 0 + $connections?.pendingIncoming ?? 0
  $: totalUpdatesCount, updateDocumentTitleWithCount()
  $: initAdobeEmbed() // Only in $: so it runs after other init stuff

  function updateDocumentTitleWithCount() {
    document.title = totalUpdatesCount > 0 ? `(${totalUpdatesCount}) ${environment.title}` : environment.title
  }

  async function initiateUserAndLeftNavCounts() {
    initiated = true

    // un-authenticated users don't receive notifications
    sockets.on('UserChanged', reloadUser)
    sockets.on('EmailConfirmed', reloadUser)
    sockets.on('IsImpersonatingChanged', loadUserAndNavToCnUsers)
    sockets.on('IM', imReceived)
    sockets.on('Notification', notificationReceived)
    sockets.on('ClearClientApiCacheInstructions', msg => api.executeClearApiCacheInstructions(msg))
    sockets.on('ExportJobs', handleExportJobUpdate)
    sockets.on('SavedViewsChanged', savedViews.load)

    // profile has to be set
    await user.load($initial.prefetch.profile)
    delete $initial.prefetch.profile
    await loadExportJobs()
  }

  function reloadUser() {
    return user.load()
  }

  async function loadUserAndNavToCnUsers() {
    await reloadUser()
    navigate('/cn-users')
  }

  function notificationReceived(notification) {
    // some notifications simply result in the UI updating and don't need to notify the user
    if (notification.message == null) return

    // refresh unread count - probably just increment in the future
    unreadNotificationCount.load()

    // alert it
    // use default color and icon if multiple notification types in one toast
    const singleType = _.uniq(notification.notificationTypeNames).length === 1
    const type = singleType ? notification.notificationTypeNames[0] : null
    const color = notificationService.getColor(type)
    const icon = notificationService.getIcon(type)
    toaster.toast({ message: notification.message, type: color, icon, url: notification.url, notification: notification.detail })
  }

  function imReceived(im) {
    // show a toast if they're not on the page with that user already
    if (!document.location.href.includes(`/messages/${im.from.userName}`))
      toaster.toast({ message: 'New message from ' + im.from.name, type: 'info', icon: 'comment', url: '/messages/' + im.from.userName })

    // load unread message counts
    unreadMessageCounts.load()
  }
</script>

<style>
  #modals {
    z-index: 1050;
    position: relative;
  }
</style>

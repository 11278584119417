<FilterTypeListFilter bind:filter {editing} {appliedMeta} {filterOptions} {labelApplied} let:option={customTag} let:item={selectedCustomTag}>
  <CustomTag {customTag} tooltipPlacement="right" />
  <CustomTag slot="selectedItem" customTag={selectedCustomTag} tooltipPlacement={null} noOwner />
</FilterTypeListFilter>

<script context="module">
  import { FeatureType, FilterType, ToManyComparison } from 'config/enums.js'
  import FilterTypeListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeListFilter.svelte'
  import personaService from 'services/persona-service'

  const label = 'Custom tag'
  const filterProp = 'customTagIds'
  export const meta = {
    ...baseMeta,
    type: FilterType.MatchCustomTags,
    toMany: true,
    idKey: 'customTagId',
    label,
    labelApplied: 'custom tag',
    icon: 'tag',
    iconClass: 'color-text-light-brown',
    editLabel: null,
    canHaveMultiple: true,
    hasDoesntHave: true,
    toManySuffix: 'of the following custom tags',
    filterProp,
    optionsEndpoint: 'getCustomTags',
    shouldShow: () => personaService.canUseAnyFeatureType(FeatureType.CustomTags),
  }
</script>

<script>
  import CustomTag from 'components/CustomTag.svelte'

  export let filter
  export let filterOptions
  export let editing = false
  export let appliedMeta

  // TODO(services): Build this into <FilterTypeListFilter> differently.
  $: labelApplied = getLabelApplied(filter.comparison)

  function getLabelApplied(comparison) {
    switch (comparison) {
      case ToManyComparison.AnyOf:
        return 'Has <strong>any</strong> of the custom tags'
      case ToManyComparison.AllOf:
        return 'Has <strong>all</strong> of the custom tags'
      case ToManyComparison.NoneOf:
        return 'Has <strong>none</strong> of the custom tags'
    }
  }
</script>

<div
  use:tip={tooltipPlacement == null
    ? null
    : { content: tooltipElem, options: { interactive: tooltipInteractive, placement: tooltipPlacement, theme: 'light-gray-scrollable' } }}
>
  <slot />
  <div class="hide">
    <div bind:this={tooltipElem} class="flex-column g05 text-left py05">
      {#if startAndEndTime}
        <span class="strongish"> You will lose previously set times for the selected days </span>
        {#each matchDayConfigs
          .filter(d => d.shiftId === shift.shiftId && selectedDates.includes(d.date))
          .sort((a, b) => new Date(a.date) - new Date(b.date)) as config}
          <div class="flex-row flex-align-center g05">
            <Icon name="calendar" class="text-info" /><strong>{config.date}</strong>
            <StartAndEndTime startTime={config.startTime} endTime={config.endTime} />
          </div>
        {/each}
      {/if}
      {#if placeholderStudentCount}
        <span class="strongish"> You will lose previously set placeholder student counts for the selected days </span>
        {#each matchDayConfigs.filter(d => d.shiftId === shift.shiftId && selectedDates.includes(d.date)) as config}
          <div class="flex-row flex-align-center g05">
            <Icon name="calendar" class="text-info" /> <strong>{config.date}</strong><Icon name="times" />
            {config.placeholderStudentCount}<Icon name="placeholder-student" autoColor />
          </div>
        {/each}
      {/if}
    </div>
  </div>
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import StartAndEndTime from 'components/StartAndEndTime.svelte'
  import tip from 'decorators/tip.js'

  export let matchDayConfigs
  export let shift
  export let selectedDates
  export let placeholderStudentCount = false
  export let startAndEndTime = false
  export let tooltipPlacement = 'top'
  export let tooltipInteractive = true

  let tooltipElem = null
</script>

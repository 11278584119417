<BtnGroup class={className} dataTest={name}>
  {#each options as o, i}
    <Btn
      title={o.title}
      dataTest={o.dataTest}
      icon={o.icon}
      on:click={() => onChange(o.value)}
      class="btn{btnClass ? ` ${btnClass}` : ''} {value === o.value ? btnActiveClass : btnInactiveClass}"
      on:keydown={i === 0 ? firstBtnKeyListener : i === options.length - 1 ? lastBtnKeyListener : null}
    >
      <slot option={o}>
        {o.label || o.value}
      </slot>
    </Btn>
  {/each}
</BtnGroup>

<script>
  import { createEventDispatcher } from 'svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import BtnGroup from 'components/bootstrap/BtnGroup.svelte'
  import Key from 'config/key.js'

  export let name = 'btn-group-picker'
  export let value
  export let options
  let className = ''
  export { className as class }
  export let btnClass = 'btn-sm'
  export let btnActiveClass = 'btn-lightgray'
  export let btnInactiveClass = 'btn-outline-lightgray'

  const dispatch = createEventDispatcher()

  function onChange(val) {
    if (val === value) return
    value = val
    dispatch('change', value)
  }

  function firstBtnKeyListener(e) {
    const key = e.which || e.keyCode
    if (key === Key.Tab && e.shiftKey) dispatch('tab-off-start')
  }

  function lastBtnKeyListener(e) {
    const key = e.which || e.keyCode
    if (key === Key.Tab && !e.shiftKey) dispatch('tab-off-end')
  }
</script>

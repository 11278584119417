{#if _options}
  {#each _options as option, i (option.value)}
    <div
      class="radio radio-primary{disabled || option.disabled ? ' disabled' : ''}{className ? ` ${className}` : ''}{i === 0 && firstOptionClass
        ? ` ${firstOptionClass}`
        : ''}{i === _options.length - 1 && lastOptionClass ? ` ${lastOptionClass}` : ''}{i === _options.length - 1 && lastOptionClass
        ? ` ${lastOptionClass}`
        : ''}"
    >
      <input
        {name}
        bind:this={inputs[i]}
        type="radio"
        bind:group={value}
        value={option.value}
        id="{name}{i}"
        data-test="{name}{i}"
        disabled={disabled || option.disabled}
      />
      <label for="{name}{i}" data-test="{name}-label">
        <div class={labelClass}>
          <slot {option}>
            <SafeHtml value={option.label} />
          </slot>
        </div>
      </label>

      <slot {option} name="after-label" />
    </div>
  {/each}
{/if}

<script>
  import { getContext, onMount } from 'svelte'
  import optionBuilder from 'services/option-builder.js'
  import SafeHtml from 'components/SafeHtml.svelte'

  export let options = []
  export let value = null
  export let name = ''
  export let disabled = false
  export let valueSelector = optionBuilder.defaultValueSelector
  export let labelSelector = optionBuilder.defaultLabelSelector
  export let autofocusFirstItem = false
  let className = null
  export { className as class }
  export let labelClass = null
  export let firstOptionClass = null
  export let lastOptionClass = null

  const inputs = []
  const initialValue = value
  const markDirty = getContext('markDirty')
  $: if (markDirty != null && value != initialValue) markDirty()

  let _options
  $: setOptions(optionBuilder.buildOptions(options, valueSelector, labelSelector))

  //Added this to fix issue where value is undefined when autofocusFirstItem is true. In OrgAdvancedSettings for StaffInviteOnly, if current persona has a true or false value for StaffInviteOnly
  //when you switch to a persona who inherits the rule, the value is undefined and the first radio button is not selected unless you refreshed the page to have onMount trigger..

  $: if (autofocusFirstItem && value == undefined) value = null

  // Need this function to prevent infinite reactivity. https://github.com/sveltejs/svelte/issues/5719
  function setOptions(builtOptions) {
    _options = builtOptions
  }

  export function focusFirstItem() {
    inputs[0]?.focus?.()
  }

  onMount(() => {
    if (autofocusFirstItem) focusFirstItem()
  })
</script>

<!-- Only present a single element/node to the container, because it's a flex container -->
{#if hasDoesntHave}
  {#if negated}
    <span>
      <strong>DOESN’T</strong> have
      {#if !toMany || (noneOf && selectedValues.length)}
        {verblessLabel}
      {:else}
        {pluralize(verblessLabel)}
        {#if selectedValues.length > 1}or <strong>HAS</strong> {verblessLabel}{/if}
      {/if}
    </span>
  {:else}
    <span>Has {verblessLabel}</span>
  {/if}
{:else if allowsAnyOrExplicitly}
  {#if negated}
    <span>
      {#if !toMany || (noneOf && selectedValues.length)}
        <strong>DOESN’T</strong> allow
        {verblessLabel}
      {:else}
        Allows <strong>ANY</strong>
        {verblessLabel}
        {#if includeEmpty}or without <strong>ANY</strong> {verblessLabel}{/if}
        {#if selectedValues.length > 1}or <strong>ALLOWS</strong> {verblessLabel}{/if}
      {/if}
    </span>
  {:else if includeEmpty}Without <strong>ANY</strong>
    {verblessLabel}
    {#if selectedValues.length}{noneOf ? 'and' : 'or'} <strong>ALLOWS</strong> {verblessLabel}{/if}
  {:else}
    <span>Allows {verblessLabel}</span>
  {/if}
{:else if negated}
  <span>{verblessLabel} is <strong>NOT</strong></span>
{:else}
  {verblessLabel}
  {#if excludable}is{/if}
{/if}

<script>
  import { ToManyComparison } from 'config/enums.js'
  import { pluralize } from 'services/string-utils.js'

  export let filter
  export let appliedMeta

  $: excludable = appliedMeta.excludable
  $: toMany = appliedMeta.toMany
  $: hasDoesntHave = appliedMeta.hasDoesntHave ?? false
  $: allowsAnyOrExplicitly = appliedMeta.allowsAnyOrExplicitly ?? false
  $: includeEmpty = filter.includeEmpty ?? false
  $: noneOf = filter.comparison === ToManyComparison.NoneOf
  $: selectedValues = filter[appliedMeta.filterProp]
  $: verblessLabel = appliedMeta.labelApplied ?? appliedMeta.label ?? ''
  $: negated = (excludable && filter.exclude) || (toMany && noneOf)
</script>

{#if show}
  <ModalConfirm show on:cancel={onClose} on:confirm={confirmMatches} confirmIcon="check">
    <h4 slot="title">
      <div class="small">Confirm guaranteed rotations for</div>
      {capacity.name}
    </h4>

    <CapacityConfirmOrReleaseMatchesModal orgName={capacity.orgName} confirmDesiredMatchCountByDate={capacityGuest.confirmDesiredMatchCountByDate} />

    <p class="m0" data-test="modal-confirm-matches-message">
      Are you sure you need all <strong>{pluralCount('guaranteed rotation', capacityGuest.guaranteedMatchCountRemaining)}</strong> for <Icon
        name="school"
        class="color-text-blue"
      /> <strong>{capacityGuest.guestOrgName}</strong>?
    </p>
  </ModalConfirm>
{/if}

<script>
  import { pluralCount } from 'services/string-utils.js'
  import { canConfirmAndReleaseCapacityGuestMatches } from 'services/capacity-usage.js'
  import api from 'services/api.js'
  import CapacityConfirmOrReleaseMatchesModal from 'components/CapacityConfirmOrReleaseMatchesModal.Preamble.svelte'
  import Icon from 'components/Icon.svelte'
  import ModalConfirm from 'components/ModalConfirm.svelte'
  import toaster from 'services/toaster.js'

  export let capacityId
  export let capacity
  export let capacityGuest
  export let onClose = _.noop
  export let onUpdate = _.noop

  $: if (capacityId && capacity == null) loadCapacity()
  $: show = canConfirmAndReleaseCapacityGuestMatches(capacity, capacityGuest)

  async function loadCapacity() {
    const response = await api.capacity.list({ selectedCapacityIds: [capacityId], pageSize: 0, includeGuests: true, excludeTotalCount: true })
    capacity = response.selectedCapacities[0]
  }

  async function confirmMatches() {
    await api.capacity.confirmMatches({
      capacityId: capacity.capacityId,
      guestOrgId: capacityGuest.guestOrgId,
    })
    onUpdate()
    toaster.toast({
      message: 'Rotations confirmed.',
      type: 'success',
      icon: 'check',
    })
  }
</script>

{#if editing}
  <FormGroup>
    <InputToggle name="i-can-complete" bind:checked={filter.iCanComplete}>
      {#if filter.iCanComplete}
        <Icon name="search" lg class="text-gray" />
      {:else}
        <Icon name="search" lg class="text-danger" />
      {/if}
      Completed
    </InputToggle>
  </FormGroup>
{:else if filter.iCanComplete}
  <Icon name="search" lg class="text-gray" />
  I can complete
{:else}
  <Icon name="search" lg class="text-danger" />
  I can <strong>NOT</strong> complete
{/if}

<script context="module">
  import Icon from 'components/Icon.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    type: FilterType.ICanComplete,
    label: 'I can complete',
    labelApplied: 'I can complete',
    icon: 'clipboard-list',
    iconClass: 'color-text-orange',
    create() {
      return { iCanComplete: true }
    },

    validate() {
      return true
    },

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.iCanComplete)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.iCanComplete = reader.readBool()
      return config
    },
  }
</script>

<script>
  export let editing = false
  export let filter
</script>

{#if multipleDaysSelected}
  <InputRadioGroup
    name="update-multiple-times-{shift.name}"
    let:option
    bind:value={multipleDaysValue.updateTimesForSelectedDates}
    options={[
      { value: false, label: 'Leave times as they are for selected days' },
      { value: true, label: 'Set all times to a new range' },
    ]}
    inline
    class="mb05 pr1"
  >
    <div class="flex-row flex-align-center g05">
      {option.label}
      {#if option.value}
        <DaysConfigTooltip {matchDayConfigs} {shift} {selectedDates} startAndEndTime>
          <Icon name="alert-triangle" class="text-warning" />
        </DaysConfigTooltip>
      {/if}
    </div>
  </InputRadioGroup>
  {#if multipleDaysValue.updateTimesForSelectedDates}
    <div class="flex-row flex-align-center g05">
      <InputTime bind:startValue={multipleDaysValue.startTime} bind:endValue={multipleDaysValue.endTime} range showTotal={false} />
    </div>
  {/if}
{:else}
  <div class="flex-row flex-align-center g05">
    <InputTime bind:startValue={value.startTime} bind:endValue={value.endTime} range showTotal={false} />
  </div>
{/if}

<script>
  import InputRadioGroup from './InputRadioGroup.svelte'
  import InputTime from './InputTime.svelte'
  import DaysConfigTooltip from './SchedulePicker.DaysConfig.Tooltip.svelte'
  import Icon from 'components/Icon.svelte'

  export let multipleDaysSelected
  export let value
  export let multipleDaysValue
  export let matchDayConfigs
  export let shift
  export let selectedDates
</script>

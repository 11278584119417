const labels = {
  1: 'Clinical site coordinator',
  2: 'Student',
  3: 'School coordinator',
  4: 'School faculty',
  5: 'Preceptor',
}

const colors = {
  1: 'health',
  2: 'primary',
  3: 'school',
  4: 'school',
  5: 'health',
}

const MatchRoleHelpers = {
  getLabel(userType, pluralize = false) {
    return (labels[userType] || '') + (pluralize && userType != 4 ? 's' : '')
  },

  getColor(userType) {
    return colors[userType] || ''
  },

  toList(roles, andOr, pluralize, sort = true) {
    if (sort) roles = roles.toSorted()
    roles = roles.map(r => `<span class="text-${MatchRoleHelpers.getColor(r)}">${MatchRoleHelpers.getLabel(r, pluralize).toLowerCase()}</span>`)

    let result = ''
    for (let i = 0; i < roles.length; i++) {
      if (i > 0 && roles.length > 2) result += ', '

      if (roles.length > 1 && i == roles.length - 1) result += ` ${andOr} `

      result += roles[i]
    }
    return result
  },
}

export default MatchRoleHelpers

{#if file.url}
  <IconTextLink icon="download" href={canShowPreview ? null : file.url} onClick={showPreviewIfCan} target="_blank" class="normal">
    <FileNameAndSize {file} />
    <slot />
  </IconTextLink>
{:else}
  <div><FileNameAndSize {file} /></div>
{/if}

{#if showPreview}
  <UserDocumentAbstractPreviewModal userDocument={file} onClose={closePreview} baseHref={null} noMinimumAlphaWidthOrHeight>
    <svelte:fragment slot="footer">
      <slot name="footer" />
      {#if !hideDoneBtn}
        <Btn class="btn-primary initial-focus" icon="check" on:click={closePreview} dataTest="btn-done">Done</Btn>
      {/if}
      <div class="flex-grow" />
      <Btn class="btn-default" icon="download" on:click={download} dataTest="btn-download" loading={downloading}>Download</Btn>
    </svelte:fragment>
  </UserDocumentAbstractPreviewModal>
{/if}

<script>
  import IconTextLink from 'components/IconTextLink.svelte'
  import FileNameAndSize from 'components/FileNameAndSize.svelte'
  import UserDocumentAbstractPreviewModal from './UserDocumentAbstractPreviewModal.svelte'
  import { canPreviewFile } from 'services/file-service.js'
  import { downloadBinary } from 'services/file-service.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import api from 'services/api.js'

  export let file
  export let hideDoneBtn = false

  let showPreview = false
  let downloading = false

  $: canShowPreview = canPreviewFile(file.contentType)

  function showPreviewIfCan() {
    if (canShowPreview) showPreview = true
  }

  async function download() {
    downloading = true
    try {
      const uri = file.url.replace(api.baseUrl, '')
      const request = api.fetch(uri, 'GET', { sendAsDownloadable: true })
      const response = await request
      await downloadBinary(response)
    } finally {
      downloading = false
    }
  }

  export function closePreview() {
    showPreview = false
  }
</script>

<div class="activity-container" {style}>
  <h3>Activity <Badge lg count={activity} /></h3>

  {#if match.canComment}
    <AddComment {onAddComment} {mentionableUsers} matchUsers={match.matchUsers} enableCommentVisibilityPicker />
  {/if}

  {#if loading}
    <Loading message="Loading activity" />
  {:else}
    {#if !activity.length}
      <HelpBlock>No activity</HelpBlock>
    {/if}
    <div class="activity pr05">
      {#each activity as a}
        {@const userDeleted = a.user?.deletedDateTime != null}
        <div class="flex-row flex-align-center g05">
          <ProfilePic
            src={a.user?.name ? a.user.profilePicture : '/images/logo-no-words.png'}
            name={a.user?.name}
            initials={a.user?.initials}
            id={a.user?.userId}
            href={a.user?.url}
            small
            class="mr05"
            pseudoDisabled={userDeleted}
            title={userDeleted ? 'Inactive user' : null}
          />
          <div class="full-width">
            <InactiveUserIcon show={userDeleted} />
            <div>
              <strong class:text-gray={userDeleted}>{a.user?.name ?? 'System'}</strong>
            </div>
            <div class="small em nowrap">
              {#if a.messageHtml}
                commented {dateService.calendarTimeLower(a.dateCreated)}
              {:else}
                {dateService.calendarTime(a.dateCreated)}
              {/if}
              {#if a.editHistory?.length}
                <a data-test="edit-history-btn" on:click={() => openEditHistory(a.user, a.editHistory)} href={null} class="ml05"> edited </a>
              {/if}
              {#if a.matchChangeDetailId != null}
                <a on:click={() => loadChangeDetail(a.matchChangeDetailId)} href={null} class="ml05">
                  view details
                  {#if matchChangeDetailLoading}
                    <Spinner />
                  {/if}
                </a>
              {/if}
            </div>
          </div>
        </div>
        <div data-test="activity-item">
          {#if a.messageHtml}
            <DisplayComment messageHtml={a.messageHtml}>
              <div class="flex-row flex-align-center flex-justify-center mr05" slot="visibility" style="width: 30px; height: 30px;">
                {#if a.matchRoles?.length < countPotentialMatchRoles}
                  <CommentVisibilityTooltip comment={a} />
                {/if}
              </div>
              <div slot="edit">
                {#if a.user?.userId == $user.userId}
                  <a
                    class="edit-btn"
                    use:tip={'Edit comment'}
                    on:click={() => editComment(a.matchCommentId, a.message, mentionableUsers)}
                    href={null}
                  >
                    <Icon name="edit" class="text-primary" />
                  </a>
                {/if}
              </div>
            </DisplayComment>
          {:else}
            <div class="flex-row flex-align-center g05">
              <div class="flex-row flex-align-center flex-justify-center mr05" style="width: 30px; height: 30px;">
                {#if a.status != null}
                  {@const matchStatus = matchStatusHelper.get(a.status)}
                  <Icon name={matchStatus.icon} class="text-{matchStatus.color}" title={matchStatus.label} />
                {/if}
              </div>
              <div>
                &nbsp;{a.changeSummary}
              </div>
            </div>
          {/if}
        </div>
      {/each}
    </div>
  {/if}
</div>

{#if editingComment}
  <Modal on:close={() => (editingComment = false)} title="Edit comment">
    <Form
      on:submit={() =>
        editCommentSubmit(
          editingMatchCommentId,
          editingMatchComment.message,
          editingMatchComment.matchRoles,
          editingMatchComment.users.map(u => u?.userId)
        )}
    >
      {#if loadingComment}
        <Loading message="Loading comment" />
      {:else}
        <div class="p2">
          <FormGroup valid={validator.required(editingMatchComment.message)} class="mb1">
            <MessageBox bind:message={editingMatchComment.message} {mentionableUsers} name="edit-comment" />
          </FormGroup>
          <FormGroup>
            <CommentVisibilityPicker
              matchUsers={match.matchUsers}
              bind:selectedUsers={editingMatchComment.users}
              bind:selectedRoles={editingMatchComment.matchRoles}
            ></CommentVisibilityPicker>
          </FormGroup>
        </div>

        <div class="modal-footer">
          <Btn
            type="submit"
            class="btn-{isEditCommentChanged ? 'primary' : 'disabled'}"
            disabled={!isEditCommentChanged}
            dataTest="edit-comment-submit-btn"
            icon="save"
          >
            Save changes
          </Btn>
          <Btn on:click={() => (editingComment = false)}>Cancel</Btn>
        </div>
      {/if}
    </Form>
  </Modal>
{/if}

{#if editHistoryOpen}
  <Modal on:close={() => (editHistoryOpen = false)} title="Comment edit history">
    <div class="p2">
      <div class="activity">
        <!-- consider using diff-merger to show differences, like we do for proposed match changes -->
        {#each editHistory as h}
          <div class="activity-item mb2" data-test="activity-item">
            {#if editHistoryUser?.profilePicture}
              <!-- ProfilePic instead here? don't bother? -->
              <img src={editHistoryUser.profilePicture} alt="" />
            {/if}
            <div class="text">
              <strong>{editHistoryUser.name}</strong>
              <span class="small em nowrap">{dateService.calendarTime(h.date)}</span>
              <div class="message">{h.message}</div>
            </div>
          </div>
        {/each}
      </div>
    </div>
  </Modal>
{/if}

{#if matchChangeDetail}
  <MatchChangeModal match={matchChangeDetailToCompareTo} {onMatchChanged} change={matchChangeDetail} onClose={() => (matchChangeDetail = null)} />
{/if}

<script>
  import { MatchRole } from 'config/enums'
  import { changeFromPrev } from 'services/match-merger.js'
  import { onDestroy } from 'svelte'
  import { parseUserIdMentionsToUserNames } from 'services/message-parser.js'
  import AddComment from './AddComment.svelte'
  import api from 'services/api.js'
  import Badge from 'components/Badge.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import CommentVisibilityPicker from './CommentVisibilityPicker.svelte'
  import CommentVisibilityTooltip from './CommentVisibilityTooltip.svelte'
  import dateService from 'services/date-service.js'
  import DisplayComment from './DisplayComment.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import getMatchController from 'services/match-controller.js'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import Icon from 'components/Icon.svelte'
  import InactiveUserIcon from 'components/InactiveUserIcon.svelte'
  import Loading from 'components/Loading.svelte'
  import MatchChangeModal from 'components/MatchChangeModal.svelte'
  import matchStatusHelper from 'services/match-status-helper.js'
  import MessageBox from 'components/fields/MessageBox.svelte'
  import Modal from 'components/Modal.svelte'
  import ProfilePic from './ProfilePic.svelte'
  import Spinner from './Spinner.svelte'
  import tip from 'decorators/tip.js'
  import toaster from 'services/toaster.js'
  import user from 'stores/user.js'
  import validator from 'services/validator.js'

  export let match
  export let onMatchChanged
  export let style

  const matchChangeDetailLoading = false
  const loadingDetail = {}
  const editingMatchComment = { message: '', matchRoles: [], users: [] }
  const countPotentialMatchRoles = Object.keys(MatchRole).length

  let activity = []
  let loading = false
  let editingComment = false
  let loadingComment = false
  let editingMatchCommentId = null
  let editHistoryOpen = false
  let editHistory = null
  let editHistoryUser = null
  let matchChangeDetail = null
  let matchChangeDetailToCompareTo = null
  let activityTask = null

  $: mentionableUsers = match.notifiableUsers?.filter(u => u.deletedDateTime == null) ?? []
  $: match, load() // noticed sometimes changes would happen and activity would show "No activity" until refreshing.
  $: isEditCommentChanged =
    editingMatchComment.message !== editingMatchComment.originalMessage ||
    !validator.equals(_.sortBy(editingMatchComment.matchRoles), _.sortBy(editingMatchComment.originalMatchRoles)) ||
    !validator.equals(_.sortBy(editingMatchComment.users?.map(u => u?.userId)), _.sortBy(editingMatchComment.originalUsers?.map(u => u?.userId)))

  onDestroy(() => activityTask?.abort())

  export async function load() {
    loading = true
    // (TODO: server-side should actually abort the query like kanban query does at dapper level)
    if (activityTask) activityTask.abort()
    activityTask = getMatchController().listActivity({ matchId: match.matchId }, api.noMonitor)
    activity = await activityTask.finally(() => (loading = false))
    activityTask = null
    for (const a of activity) {
      const visibleTo = getVisibleTo(a)
      a.visibleToMatchRoles = visibleTo.roles
      a.visibleToUsers = visibleTo.users
    }
  }

  async function onAddComment(message, matchRoles, userIds) {
    await getMatchController().addComment({ matchId: match.matchId }, { message, matchRoles, userIds }, api.noMonitor)
    load() // don't do this asynchronously (3-9-2022, why though?  i don't remember)
  }

  async function editComment(matchCommentId, message, mentionableUsers) {
    loadingComment = true
    editingComment = true
    editingMatchCommentId = matchCommentId
    message = parseUserIdMentionsToUserNames(message, mentionableUsers)
    editingMatchComment.originalMessage = editingMatchComment.message = message
    const comment = activity.find(a => a.matchCommentId === matchCommentId)
    editingMatchComment.originalMatchRoles = editingMatchComment.matchRoles = comment.matchRoles
    editingMatchComment.originalUsers = editingMatchComment.users = comment.visibleToUsers
    loadingComment = false
  }

  async function editCommentSubmit(matchCommentId, message, matchRoles, userIds) {
    await getMatchController()
      .editComment({ matchCommentId, matchId: match.matchId }, { message, matchRoles, userIds }, api.noMonitor)
      .then(async () => {
        await load()
        toaster.toast({ message: 'Comment updated', type: 'success', icon: 'comment-empty' })
        editingComment = false
      })
      .catch(() => (editingComment = false))
  }

  function openEditHistory(user, edit_history) {
    editHistoryOpen = true
    editHistory = edit_history
    editHistoryUser = user
  }

  async function loadChangeDetail(matchChangeDetailId) {
    // if we already have it loaded, just use that. else get from api
    const _matchChangeDetail = match.proposedChanges.find(c => c.matchChangeDetailId === matchChangeDetailId)
    if (_matchChangeDetail == null) {
      const loadKey = `detail${matchChangeDetailId}`
      loadingDetail[loadKey] = true
      try {
        const _matchChangeDetail = await getMatchController().getChangeDetail({ matchChangeDetailId, matchId: match.matchId }, api.noMonitor)
        setMatchChangeDetail(_matchChangeDetail)
      } finally {
        loadingDetail[loadKey] = false
      }
    } else {
      setMatchChangeDetail(_matchChangeDetail)
    }
  }

  function setMatchChangeDetail(change) {
    // if it's already merged in or rejected, compare it to its previous state. otherwise, it's a proposed change that should be compared to the latest
    const proposed = !change.merged && change.rejectedDate == null
    matchChangeDetailToCompareTo = proposed ? match : changeFromPrev(match, change.changes.previousState)
    matchChangeDetail = change
  }

  function getVisibleTo(a) {
    const users = []
    for (const matchUser of match.matchUsers) {
      const { userId, name, initials } = matchUser
      const user = users.find(u => u.userId === userId)
      if (!user) {
        // get match roles for all instances of this user
        const matchRoles = match.matchUsers.filter(m => m.userId === userId).map(m => m.matchRole)
        users.push({ userId, name, initials, matchRoles })
      }
    }
    users.sort((a, b) => a.name.localeCompare(b.name))

    const selectedUsers = a.userIds ? users.filter(u => a.userIds.includes(u.userId)) : []

    const roleGroups = [
      { id: 1, roles: [MatchRole.SchoolCoordinator, MatchRole.GuestAdmin, MatchRole.GuestViewer], label: 'school coordinators', users: [] },
      { id: 2, roles: [MatchRole.SchoolFaculty, MatchRole.GuestAdmin, MatchRole.GuestViewer], label: 'school faculty', users: [] },
      { id: 3, roles: [MatchRole.ClinicCoordinator, MatchRole.HostAdmin, MatchRole.HostViewer], label: 'clinic coordinators', users: [] },
      { id: 4, roles: [MatchRole.Preceptor, MatchRole.HostAdmin, MatchRole.HostViewer], label: 'preceptors', users: [] },
      { id: 5, roles: [MatchRole.Student], label: 'students', users: [] },
    ]

    for (const roleGroup of roleGroups) {
      roleGroup.users = users.filter(u => roleGroup.roles.some(role => u.matchRoles.includes(role)))
    }
    const selectedRoleGroups = a.matchRoles ? roleGroups.filter(rg => rg.roles.every(role => a.matchRoles.includes(role))) : roleGroups

    return { roles: selectedRoleGroups, users: selectedUsers }
  }
</script>

<style lang="scss">
  @import '../../css/helpers';

  .activity-container {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    padding-left: 25px;
    padding-right: 20px;
    background-color: #fff;
    width: 25%;
    max-height: 100vh;

    .activity {
      max-height: calc(100vh - 370px);
      overflow-y: auto;

      .activity-item {
        display: flex;
        margin-bottom: 10px;

        .text {
          display: inline-block;
          padding-right: 10px;
        }
      }
    }
  }

  @media only screen and (max-width: 1200px) {
    .activity-container {
      position: relative;
      width: 100%;
      z-index: unset;
      padding-top: 0;
      right: unset;
      top: unset;
      bottom: unset;
    }

    .activity {
      max-height: none;
    }
  }
</style>

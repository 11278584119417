{#if matchStatus != null}
  <div class="strongish flex-row flex-align-center g05" style="color: {helper.reportColor}">
    <span>{readableNumber(count)}</span>
    {#if times}
      <Icon name="times" {...iconProps} />
    {/if}
    <div class="flex-row flex-align-center g05">
      <Icon name={helper.icon} {...iconProps} />
      <span>{helper.label}</span>
    </div>
  </div>
{/if}

<script>
  import { readableNumber } from 'services/string-utils.js'
  import Icon from 'components/Icon.svelte'
  import matchStatusHelper from 'services/match-status-helper.js'

  export let matchStatus
  export let count
  export let iconProps = {}
  export let times = false

  $: helper = matchStatusHelper.get(matchStatus)
</script>

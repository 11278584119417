// all svelte components we code-split
// mostly high-level route components
// https://github.com/kaisermann/svelte-loadable#registering-a-loader

import { register } from 'svelte-loadable'

const pages = {
  // shared
  Referrals: () => import('pages/Referrals.svelte'),
  Support: () => import('pages/Support.svelte'),
  AppVersionPage: () => import('pages/AppVersionPage.svelte'),
  ApiDocumentation: () => import('pages/support/ApiDocumentation.svelte'),
  Pricing: () => import('pages/Pricing.svelte'),
  SetPromotionCodeAndRedirect: () => import('components/routing/SetPromotionCodeAndRedirect.svelte'),
  ResetPassword: () => import('pages/ResetPassword.svelte'),

  // authorized
  AfterJoining: () => import('components/AfterJoining.svelte'),
  InactivePersonas: () => import('pages/authorized/InactivePersonas.svelte'),
  Dashboard: () => import('pages/authorized/Dashboard.svelte'),
  Reports: () => import('pages/authorized/Reports.svelte'),
  OrgWizard: () => import('components/OrgWizard.svelte'),
  Search: () => import('pages/authorized/Search.svelte'),
  Account: () => import('pages/authorized/Account.svelte'),
  Connections: () => import('pages/authorized/Connections.svelte'),
  Notifications: () => import('pages/authorized/Notifications.svelte'),
  Messages: () => import('pages/authorized/Messages.svelte'),
  UserProfile: () => import('pages/authorized/UserProfile.svelte'),
  OrgProfile: () => import('pages/authorized/org/OrgProfile.svelte'),

  // org pages
  ScheduleStudents: () => import('pages/authorized/org/ScheduleStudents.svelte'),
  Services: () => import('pages/authorized/org/Services.svelte'),
  OpportunitiesView: () => import('pages/authorized/org/OpportunitiesView.svelte'),
  Settings: () => import('pages/authorized/org/Settings.svelte'),
  Students: () => import('pages/authorized/school/Students.svelte'),
  MySteps: () => import('pages/authorized/MySteps.svelte'),
  Courses: () => import('pages/authorized/Courses.svelte'),

  // cn pages
  CNOrgs: () => import('pages/authorized/cn/Orgs.svelte'),
  CNSubscriptions: () => import('pages/authorized/cn/Subscriptions.svelte'),
  CNFeaturePackages: () => import('pages/authorized/cn/FeaturePackages.svelte'),
  CNBlockAndAllowOrgsSettings: () => import('pages/authorized/cn/BlockAndAllowOrgs.svelte'),
  CNUsers: () => import('pages/authorized/cn/Users.svelte'),
  CNNameSuggestions: () => import('pages/authorized/cn/NameSuggestions.svelte'),
  CNDevTools: () => import('pages/authorized/cn/DevTools.svelte'),
  CNSupportTools: () => import('pages/authorized/cn/SupportTools.svelte'),
  CNFormTemplates: () => import('pages/authorized/cn/FormTemplates.svelte'),
  CNFieldChanger: () => import('pages/authorized/cn/FieldChanger.svelte'),
  CNDocumentation: () => import('pages/documentation/Documentation.svelte'),

  // student pages
  UserDocuments: () => import('pages/authorized/UserDocuments.svelte'),

  // dashboard views
  RotationList: () => import('pages/authorized/dashboard-views/RotationList.svelte'),
  CapacityCalendar: () => import('pages/authorized/dashboard-views/CapacityCalendar.svelte'),
  MyStepsReport: () => import('pages/authorized/MyStepsReport.svelte'),
  SchoolComplianceStepsReport: () => import('pages/authorized/dashboard-views/SchoolComplianceStepsReport.svelte'),
  ApplicationStepsReport: () => import('pages/authorized/dashboard-views/ApplicationStepsReport.svelte'),
  OffboardingStepsReport: () => import('pages/authorized/dashboard-views/OffboardingStepsReport.svelte'),
  EvaluationsReport: () => import('pages/authorized/dashboard-views/EvaluationsReport.svelte'),
  EvaluationStepsReport: () => import('pages/authorized/dashboard-views/EvaluationStepsReport.svelte'),
  OnboardingStepsReport: () => import('pages/authorized/dashboard-views/OnboardingStepsReport.svelte'),
  ActiveStepsReport: () => import('pages/authorized/dashboard-views/ActiveStepsReport.svelte'),
  PerformanceAndUtilizationReport: () => import('pages/authorized/dashboard-views/PerformanceAndUtilizationReport.svelte'),
  RotationBreakdownReport: () => import('pages/authorized/dashboard-views/RotationBreakdownReport.svelte'),
  RotationStatusHistoryReport: () => import('pages/authorized/dashboard-views/RotationStatusHistoryReport.svelte'),
  StepReport: () => import('pages/authorized/dashboard-views/StepReport.svelte'),
  StudentReport: () => import('pages/authorized/dashboard-views/StudentReport.svelte'),
  HourLogReport: () => import('pages/authorized/dashboard-views/HourLogReport.svelte'),
  ConsortiumRelations: () => import('pages/authorized/dashboard-views/ConsortiumRelations.svelte'),
  ConsortiumRotations: () => import('pages/authorized/dashboard-views/ConsortiumRotations.svelte'),
  CNOverview: () => import('pages/authorized/dashboard-views/CNOverview.svelte'),
}

const pageLoaders = {}
for (const key of Object.keys(pages)) {
  pageLoaders[key] = register({
    loader: pages[key],
    resolve: () => key,
  })
}

export default function getPageLoader(componentName) {
  const pageLoader = pageLoaders[componentName]
  if (pageLoader == null) throw `"${componentName}" is not registered in page-loaders.js`
  return pageLoader
}

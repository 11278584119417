<Route {path} let:params>
  <Loadable loader={getPageLoader(component)} let:component={loadedComponent}>
    <div slot="loading">
      <Spinner x2 class="m2" />
    </div>
    <svelte:component this={loadedComponent} {...params} />
  </Loadable>
</Route>

<script>
  import { Route } from 'svelte-routing'
  import getPageLoader from 'services/page-loaders.js'
  import Loadable from 'svelte-loadable'
  import Spinner from 'components/Spinner.svelte'

  export let component
  export let path = ''
</script>

{#if !leftNavHide}
  <div
    class="main-nav {$leftNavExpanded ? 'expanded' : 'collapsed'}"
    {style}
    on:transitionstart|self={onTransitionStart}
    on:transitionend|self={onTransitionEnd}
  >
    <div class="main-nav-top">
      <NavToggle on:click={() => ($leftNavExpanded = !$leftNavExpanded)} />

      <a href="/" class="logo">
        <img src="/images/logo{$leftNavExpanded ? '' : '-no-words'}.png?v=2" alt="Clinician Nexus" />
      </a>

      <SubNav class="account-nav">
        <SubNavItem
          title={numPendingEndorsements ? `My account (${pluralCount('pending endorsement', numPendingEndorsements)})` : 'My account'}
          dataTest="nav-account"
          href="/account/profile"
          isActive={/^\/account/}
          img={$user.profilePicture}
          imgName={$user.name}
          imgInitials={$user.initials}
          imgId={$user.userId}
          tooltipPlacement={accountNavTooltipPlacement}
          badgeColor="success"
          badgeCount={numPendingEndorsements}
        >
          Account
        </SubNavItem>

        <SubNavItem
          loading={$connections === null}
          title={anyPendingIncoming ? `Connections (${$connections.pendingIncoming.length} pending)` : 'Connections'}
          dataTest="nav-connections"
          icon="network{anyPendingIncoming ? '' : '-empty'}"
          href="/connections"
          badgeColor="success"
          badgeCount={$connections?.pendingIncoming}
          tooltipPlacement={accountNavTooltipPlacement}
        >
          Connections
        </SubNavItem>

        <SubNavItem
          loading={$totalUnreadMessageCount === null}
          title={$totalUnreadMessageCount ? `Messages (${$totalUnreadMessageCount} unread)` : 'Messages'}
          dataTest="nav-messages"
          class="messages"
          href="/messages"
          icon="comment{$totalUnreadMessageCount ? '' : '-empty'}"
          badgeColor="danger"
          badgeCount={$totalUnreadMessageCount}
          tooltipPlacement={accountNavTooltipPlacement}
        >
          Messages
        </SubNavItem>

        <SubNavItem
          loading={$unreadNotificationCount === null}
          title={$unreadNotificationCount ? `Notifications (${$unreadNotificationCount} unread)` : 'Notifications'}
          dataTest="nav-notifications"
          href="/notifications"
          icon="notification{$unreadNotificationCount ? '' : '-empty'}"
          badgeColor="info"
          badgeCount={$unreadNotificationCount}
          tooltipPlacement={accountNavTooltipPlacement}
        >
          Notifications
        </SubNavItem>
      </SubNav>

      <EmailConfirmationWarning />

      <!-- select current persona -->
      <PersonaDropdown />
      <PersonaFilters />
      <SubscriptionStatusWarning />
    </div>

    <div class="main-nav-middle">
      <!-- persona-specific links -->
      <SubNav class="main-sub-nav">
        <SubNavItem
          href="/dashboard"
          icon={shouldBeDashboard ? 'home' : 'rotation'}
          title={$leftNavExpanded ? null : shouldBeDashboard ? 'Dashboard' : 'Rotations'}
          isActive={/^\/($|dashboard)/}>{shouldBeDashboard ? 'Dashboard' : 'Rotations'}</SubNavItem
        >
        <SubNavItemCollection links={$persona.links} />
        {#if firstSettingsSlug != null}
          <SubNavItem
            href="/settings/{firstSettingsSlug}"
            icon="org-settings"
            title={getPendingSettingsTooltip($orgPendingConfigCounts, $leftNavExpanded)}
            isActive={/^\/($|settings)/}
            badgeColor="info"
            badgeCount={settingsPendingCount}
            badgeName="settings-pending-count"
          >
            Settings
          </SubNavItem>
        {/if}
      </SubNav>
    </div>

    <div class="main-nav-bottom">
      <SubNav>
        <SubNavItem href="/search/rotations" icon="search" title={$leftNavExpanded ? null : 'Find clinical experiences'}>
          Find clinical experiences
        </SubNavItem>
        <SubNavItem href="/search/profiles" icon="users" title={$leftNavExpanded ? null : 'Search community'}>Search community</SubNavItem>
        <SubNavItem href="/support/contact" icon="help" title={$leftNavExpanded ? null : 'Support'} isActive={/^\/support/}>Support</SubNavItem>
        <SubNavItem href="/referral" icon="referral" title={$leftNavExpanded ? null : 'Referrals'}>Referrals</SubNavItem>
        <AppVersion />
        <SubNavItemCollection links={$persona.bottomLinks} />
      </SubNav>
    </div>
  </div>
{/if}

{#if showChangeNameForm}
  <Modal title="Name formatting change" showClose={false}>
    <ChangeNameForm on:close={close} />
  </Modal>
{/if}

<script>
  import { getLocation } from 'stores/req.js'
  import { mainNavWidth, mainNavTransitioning } from 'stores/media-query.js'
  import { onDestroy } from 'svelte'
  import { PersonaType } from 'config/enums.js'
  import { toFriendlyList, pluralCount } from 'services/string-utils.js'
  import { totalUnreadMessageCount } from 'stores/unread-message-counts.js'
  import AppVersion from 'components/AppVersion.svelte'
  import ChangeNameForm from 'components/ChangeNameForm.svelte'
  import connections from 'stores/connections.js'
  import EmailConfirmationWarning from 'components/EmailConfirmationWarning.svelte'
  import featureFlags from 'stores/feature-flags.js'
  import leftNavExpanded from 'stores/left-nav-expanded.js'
  import Modal from 'components/Modal.svelte'
  import NavToggle from 'components/NavToggle.svelte'
  import orgPendingConfigCounts from 'stores/org-pending-config-counts.js'
  import persona from 'stores/persona.js'
  import PersonaDropdown from 'components/PersonaDropdown.svelte'
  import PersonaFilters from 'components/PersonaFilters.svelte'
  import SubNav from 'components/SubNav.svelte'
  import SubNavItem from 'components/SubNavItem.svelte'
  import SubNavItemCollection from 'components/SubNavItemCollection.svelte'
  import SubscriptionStatusWarning from 'components/SubscriptionStatusWarning.svelte'
  import unreadNotificationCount from 'stores/unread-notification-count.js'
  import user from 'stores/user.js'
  import validator from 'services/validator.js'

  const location = getLocation()

  const expandedWidth = 200
  const collapsedWidth = 50

  let lastUserName = $user.userName
  let lastPath = null
  let lastIsNameOverhaulEnabled = false
  let lastCheckTime = null

  $: settingsPendingCount =
    $orgPendingConfigCounts == null
      ? null
      : $orgPendingConfigCounts.pendingAgreementCount + $orgPendingConfigCounts.pendingStaffCount + $orgPendingConfigCounts.pendingImportStaffCount
  $: numPendingEndorsements = $user?.incomingEndorsements.filter(e => e.status === 0).length
  $: leftNavHide = /^\/(?:add-role|api-docs|pricing|reset-password)/i.test($location.pathname)
  $: anyPendingIncoming = $connections?.pendingIncoming.length > 0
  $: accountNavTooltipPlacement = $leftNavExpanded ? 'top' : 'right'
  $: firstSettingsSlug = $persona?.settingsLinks?.find(l => l.slug != null)?.slug
  $: style = `
    --expanded-width: ${expandedWidth}px;
    --collapsed-width: ${collapsedWidth}px;
  `
  $: $mainNavWidth = $leftNavExpanded ? expandedWidth : collapsedWidth

  $: userName = $user.userName
  $: hasUpdatedName = !validator.empty($user.legalFirstName) || !validator.empty($user.legalLastName)
  $: path = $location.pathname
  $: isNameOverhaulEnabled = $featureFlags['name-overhaul']
  $: showChangeNameForm = shouldShowChangeNameForm(userName, hasUpdatedName, path, isNameOverhaulEnabled, $user?.isImpersonating)

  $: shouldBeDashboard = $persona.personaType === PersonaType.CN || $persona.personaType === PersonaType.ConsortiumStaff
  onDestroy(() => ($mainNavWidth = 0))

  function addToList(list, unit, count) {
    if (count > 0) list.push(pluralCount(unit, count))
  }

  function close() {
    showChangeNameForm = false
  }

  function getPendingSettingsTooltip(counts, leftNavExpanded) {
    if (counts == null || counts.pendingStaffCount + counts.pendingAgreementCount + counts.pendingImportStaffCount === 0)
      return leftNavExpanded ? null : 'Settings'
    const list = []
    addToList(list, 'pending staff', counts.pendingStaffCount)
    addToList(list, 'pending agreement', counts.pendingAgreementCount)
    addToList(list, 'pending import staff', counts.pendingImportStaffCount)
    return `Settings (${toFriendlyList(list)})`
  }

  function onTransitionStart() {
    $mainNavTransitioning = true
  }

  function onTransitionEnd() {
    $mainNavTransitioning = false
  }

  // We want to display the name change nag when the user first logs in
  // or when they navigate to an /account page and they haven't updated their name yet
  // Without this logic, when the LaunchDarkly flag toggles to true, the nag will show
  // immediately, even if the user is in the middle of doing something else.
  // If a user has seen the form, they should continue to see the form till they update their name.

  const firstPagePaths = new Set(['/', '/dashboard/dashhboard', '/dashboard/temp/rotation-list', '/dashboard/rotation-list'])
  const countNavigationsPerUserName = {}
  const userNamesWhoHaveSeenChangeNameForm = {}
  
  function shouldShowChangeNameForm(userName, hasUpdatedName, path, isNameOverhaulEnabled, isImpersonating) {
    const hasChangedUsers = userName !== lastUserName
    const isNavigating = path !== lastPath && !hasChangedUsers
    const isFlagJustToggling = !hasChangedUsers && !isNavigating && lastIsNameOverhaulEnabled !== isNameOverhaulEnabled
  
    if (hasChangedUsers) countNavigationsPerUserName[userName] = 0
    countNavigationsPerUserName[userName] = countNavigationsPerUserName[userName] || 0
    const countNavigations = isNavigating ? ++countNavigationsPerUserName[userName] : countNavigationsPerUserName[userName]
    const isGoodPageToShowChangeNameForm = path.toLowerCase().startsWith('/account')
    const hasSeenChangeNameForm = userNamesWhoHaveSeenChangeNameForm[userName] ?? false
  
    // If the flag is just toggling, and they haven't been on the page long, it's likely
    // because it took a moment to contact LaunchDarkly and update the flag. Let's assume we're clear to show the modal.
    const isLikelyToBeFirstPageVisit = countNavigations <= 3 && firstPagePaths.has(lastPath)
    const isWithinLaunchDarklyTimeUpdateWindow = lastCheckTime != null && Date.now() - lastCheckTime <= 5000

    // console.log({
    //   userName,
    //   hasUpdatedName,
    //   path,
    //   isNameOverhaulEnabled,
    //   hasChangedUsers,
    //   isNavigating,
    //   isFlagJustToggling,
    //   countNavigations,
    //   isGoodPageToShowChangeNameForm,
    //   hasSeenChangeNameForm,
    //   isLikelyToBeFirstPageVisit,
    //   isWithinLaunchDarklyTimeUpdateWindow,
    //   lastUserName,
    //   lastPath,
    //   lastIsNameOverhaulEnabled,
    //   lastCheckTime,
    // })

    lastUserName = userName
    lastPath = path
    lastIsNameOverhaulEnabled = isNameOverhaulEnabled
    lastCheckTime = new Date()

    if (
      !isNameOverhaulEnabled ||
      isImpersonating ||
      (isFlagJustToggling && (!isLikelyToBeFirstPageVisit || !isWithinLaunchDarklyTimeUpdateWindow)) ||
      hasUpdatedName
    )
      return false
    if (hasSeenChangeNameForm) return true
    if ((isLikelyToBeFirstPageVisit && isWithinLaunchDarklyTimeUpdateWindow) || (isNavigating && isGoodPageToShowChangeNameForm)) {
      userNamesWhoHaveSeenChangeNameForm[userName] = true
      return true
    }

    return false
  }
</script>

<!--if this component changes, update NotificationSettings.Checkboxes.svelte and SchedulePicker.Day.svelte if necessary-->
{#if options}
  {#if selectAllNone}
    <div class="flex-row g1">
      <a class="small" href={null} on:click={selectAll}>Select all</a>
      <a class="small" href={null} on:click={selectNone}>Select none</a>
    </div>
  {/if}

  {#each _options as option, i (option)}
    <slot name="before-input" {option} previousOption={_options[i - 1]} {i} />
    <div
      class="checkbox checkbox-primary{className ? ` ${className}` : ''}"
      class:disabled={disabled || option.disabled}
      style={_styleSelector(option, i)}
    >
      <!-- can't do bind:group={value} because svelte clears values that aren't in the group...so do on:click
    handler and checked instead for now: https://github.com/sveltejs/svelte/issues/4239 -->
      <input
        bind:this={inputs[i]}
        type="checkbox"
        on:click={() => toggleOption(option)}
        checked={value.includes(option.value)}
        value={option.value}
        id="{name}{i}"
        data-test="{name}{i}"
        disabled={disabled || option.disabled}
        on:change
      />
      <label for="{name}{i}" data-test="{name}-label">
        <div class={labelWrapperClass}>
          <slot {option} previousOption={_options[i - 1]} {i}>
            <SafeHtml value={option.label} />
          </slot>
        </div>
      </label>
    </div>
  {/each}
{/if}

<script>
  import { getContext, onMount } from 'svelte'
  import { sort } from 'services/array-utils.js'
  import optionBuilder from 'services/option-builder.js'
  import SafeHtml from 'components/SafeHtml.svelte'
  import validator from 'services/validator.js'

  export let options = []
  export let value = []
  export let name = ''
  export let disabled = false
  export let valueSelector = optionBuilder.defaultValueSelector
  export let labelSelector = optionBuilder.defaultLabelSelector
  export let autofocusFirstItem = false
  let className = null
  export { className as class }
  export let styleSelector = null
  export let sortNullsFirst = false
  export let selectAllNone = false
  export let labelWrapperClass = null

  $: _styleSelector = styleSelector ?? (() => null)

  valueToArray()
  const inputs = []
  const initialValue = value
  const markDirty = getContext('markDirty')

  $: value, valueToArray()
  $: if (markDirty != null && value != null && !validator.equals(value, initialValue)) markDirty()

  let _options
  $: setOptions(optionBuilder.buildOptions(options, valueSelector, labelSelector))

  // Need this function to prevent infinite reactivity. https://github.com/sveltejs/svelte/issues/5719
  function setOptions(builtOptions) {
    _options = builtOptions
  }

  export function focusFirstItem() {
    inputs[0]?.focus?.()
  }

  onMount(() => {
    if (autofocusFirstItem) focusFirstItem()
  })

  function valueToArray() {
    if (value == null) value = []
    else if (!Array.isArray(value)) value = [value]
    value = sort(value, null, sortNullsFirst)
  }

  function toggleOption(option) {
    value = value.includes(option.value) ? value.filter(v => v !== option.value) : [...value, option.value]
  }

  function selectAll() {
    value = _options.map(o => o.value)
  }

  function selectNone() {
    value = []
  }
</script>

<Form on:submit={waitlistMatch}>
  <div class="p2">
    <FormGroup valid={validator.int($waitlistDays)}>
      <label for="waitlist-expire-within-days">Close rotation if it’s not accepted from waitlist at least this many days prior to start date:</label>
      <InputNumber bind:value={$waitlistDays} name="waitlist-expire-within-days" min={1} max={maxExpirationDays} />
      <HelpBlock>Request will close if not accepted by end of day {waitlistExpirationDate}</HelpBlock>
    </FormGroup>
  </div>

  <div class="modal-footer">
    <Btn type="submit" {loading} class="btn btn-sky" dataTest="add-to-waitlist-submit-btn">Add to waitlist</Btn>
    <Btn class="btn-default" on:click={onClose}>Cancel</Btn>
  </div>
</Form>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import HelpBlock from 'components/fields/HelpBlock.svelte'
  import InputNumber from 'components/fields/InputNumber.svelte'
  import waitlistDays from 'stores/waitlist-days.js'
  import validator from 'services/validator.js'
  import getMatchController from 'services/match-controller'
  import toaster from 'services/toaster'
  import api from 'services/api'

  export let match
  export let onClose
  export let onMatchChanged

  let loading = false

  // sync value to store so it remembers for next time they add something to waitlist
  $: if ($waitlistDays == null) $waitlistDays = 7 // otherwise gets set to null, presumably in InputNumber.svelte
  $: maxExpirationDays = Math.abs(dayjs().diff(match.startDate, 'days')) + 1
  $: waitlistExpirationDate = dayjs(match.startDate).add(-$waitlistDays, 'day').format('M/D/YYYY')

  async function waitlistMatch() {
    const data = {
      waitlistExpireWithinDays: $waitlistDays,
    }
    loading = true
    try {
      await getMatchController().addToWaitlist({ matchId: match.matchId }, data, api.noMonitor)
      onClose()
      await onMatchChanged({ detailsHard: true, activity: true })
      toaster.toast({ message: 'Rotation added to waitlist', type: 'success', icon: 'edit' })
    } finally {
      loading = false
    }
  }
</script>

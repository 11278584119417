<Modal xl on:close={onClose}>
  <h4 slot="title">
    <div class:small={service?.name}>Opportunity utilization</div>
    {service?.name ?? ''}
  </h4>

  <div class="modal-body">
    <CapacityCalendar
      {criteria}
      hideMatchFilters
      groupByOptions={[GroupBy.Service, GroupBy.Opportunity, GroupBy.Shift]}
      baseUrl="{baseHref}/{serviceId}/utilization"
    />
  </div>
</Modal>

<script>
  import { GroupBy } from 'config/enums.js'
  import api from 'services/api.js'
  import CapacityCalendar from 'pages/authorized/dashboard-views/CapacityCalendar.svelte'
  import capacityCalendarConfig from 'config/dashboard-view-types/capacity-calendar.js'
  import Modal from 'components/Modal.svelte'

  export let serviceId
  export let service
  export let onClose
  export let baseHref

  $: criteria = {
    ...capacityCalendarConfig.criteria,
    serviceId,
    groupBy: GroupBy.Opportunity,
    startDate: dayjs().format('M/D/YYYY'),
    endDate: dayjs().add(3, 'months').format('M/D/YYYY'),
    keyword: null,
    zoom: 'm',
  }

  $: if (serviceId && service == null) loadService()

  async function loadService() {
    const response = await api.service.list({ selectedServiceIds: [serviceId], pageSize: 0, excludeTotalCount: true })
    service = response.selectedServices[0]
  }
</script>

<Modal on:close={onSubmit}>
  <div slot="title" data-test="verification-expiration-suggestion-modal">
    <h4>
      {labelText}
    </h4>
    {helpText}
  </div>
  <Form on:submit={_onSubmit}>
    <div class="modal-body">
      <StepSubmissionSuggestedExpirationDate bind:stepSubmission hideLabelAndHelpBlock />
    </div>
    <div class="modal-footer">
      <SubmitBtn dataTest="suggest-verification-expiration-btn" icon="save" {loading}>Submit</SubmitBtn>
    </div>
  </Form>
</Modal>

<script>
  import Form from './Form.svelte'
  import Modal from './Modal.svelte'
  import StepSubmissionSuggestedExpirationDate, { labelText, helpText } from './StepSubmissionSuggestedExpirationDate.svelte'
  import SubmitBtn from './bootstrap/SubmitBtn.svelte'

  let loading = false

  export let stepSubmission
  export let onSubmit

  async function _onSubmit() {
    loading = true
    try {
      await onSubmit()
    } finally {
      loading = false
    }
  }
</script>

<FilterTypeListFilter
  bind:filter
  {editing}
  {appliedMeta}
  filterOptions={options}
  selectedItemsContainerClass={null}
  let:option={o}
  let:item
  hideSearchBox
  name="student-year-"
>
  {o.name}
  <svelte:fragment slot="selectedItem">
    {item.name}
  </svelte:fragment>
</FilterTypeListFilter>

<script context="module">
  import FilterTypeListFilter, { meta as baseMeta } from 'components/filter-types/FilterTypeListFilter.svelte'
  import { FilterType } from 'config/enums.js'

  export const meta = {
    ...baseMeta,
    type: FilterType.StudentYears,
    label: 'Student years',
    icon: 'calendars',
    iconClass: 'color-text-teal',
    canHaveMultiple: true,
    optionValues: [1, 2, 3, 4, 10, null],
    nullYearLabel: 'Not specified',
    filterProp: 'studentYears',
    idKey: 'value',
    allowEmpty: true,
    toMany: true,
  }
</script>

<script>
  import { toStudentYear } from 'services/formatters.js'

  export let filter
  export let editing = false
  export let appliedMeta

  const options = appliedMeta.optionValues.map(year => ({
    value: year,
    name: year === null ? appliedMeta.nullYearLabel : toStudentYear(year),
  }))
</script>

import validator from 'services/validator.js'

const defaultFormat = 'M/D/YYYY'

function dateformat(date, format = defaultFormat, localize) {
  if (validator.empty(date)) return null
  const m = dayjs(date)
  return localize ? m.local().format(format) : m.utc().format(format)
}

class DateService {
  dateformat(date, format = defaultFormat, localize) {
    return dateformat(date, format, localize)
  }

  datetimestamp(date, localize) {
    return dateformat(date, 'M/D/YYYY h:mmA', localize)
  }

  datestamp(date, localize) {
    return dateformat(date, defaultFormat, localize)
  }

  fromnow(date, localize) {
    return localize ? dayjs(date).local().fromNow() : dayjs(date).utc().fromNow()
  }

  fromnowLessPrecise(date, localize) {
    const today = localize ? dayjs().local() : dayjs().utc()
    const startOfToday = today.startOf('day')
    const startOfDate = dayjs(date).startOf('day')

    const years = startOfDate.diff(startOfToday, 'year')
    if (years !== 0) return this._getTimeSpanString(years, 'year')

    const months = startOfDate.diff(startOfToday, 'month')
    if (months !== 0) return this._getTimeSpanString(months, 'month')

    const days = startOfDate.diff(startOfToday, 'days')
    return this._getTimeSpanString(days, 'day')
  }

  _getTimeSpanString(count, type) {
    return count === 0
      ? 'today'
      : count === 1
        ? type === 'day'
          ? 'tomorrow'
          : `in a ${type}`
        : count === -1
          ? type === 'day'
            ? 'yesterday'
            : `a ${type} ago`
          : count < 0
            ? `${Math.abs(count)} ${type}s ago`
            : `in ${count} ${type}s`
  }

  calendarTime(date) {
    return dayjs(date).local().calendar()
  }

  calendarTimeLower(date) {
    return dayjs(date).local().calendar(null, {
      sameDay: '[today at] h:mm A',
      nextDay: '[tomorrow at] h:mm A',
      nextWeek: 'dddd [at] h:mm A',
      lastDay: '[yesterday at] h:mm A',
      lastWeek: '[last] dddd [at] h:mm A',
      sameElse: 'MM/DD/YYYY',
    })
  }

  // localized weekday name
  weekday(day) {
    return dayjs().weekday(day)
  }

  datechanged(d1, d2) {
    return dateformat(d1, defaultFormat) !== dateformat(d2, defaultFormat)
  }

  rangesOverLap(s1, e1, s2, e2) {
    const start1 = dayjs(s1)
    const end1 = dayjs(e1)
    const start2 = dayjs(s2)
    const end2 = dayjs(e2)
    const overlapping =
      (start1.isBefore(start2) && end1.isAfter(start2)) ||
      (start1.isSameOrAfter(start2) && end1.isSameOrBefore(end2)) ||
      (start1.isBefore(end2) && end1.isAfter(end2))
    return overlapping
  }

  getDatesInRange(startDate, endDate, zoom = 'd') {
    const ranges = []
    let currDate = dayjs(startDate).startOf('day')
    const lastDate = dayjs(endDate).startOf('day')

    while (currDate.isSameOrBefore(lastDate)) {
      const range = {
        start: currDate.toDate(),
      }

      switch (zoom) {
        case 'd':
          currDate = currDate.add(1, 'days')
          break
        case 'w':
          currDate = currDate.add(7, 'days')
          break
        case 'm':
          currDate = currDate.add(1, 'months')
          break
        case 'y':
          currDate = currDate.add(1, 'years')
          break
        // No default
      }

      range.end = currDate.toDate()
      ranges.push(range)
    }

    return ranges
  }

  getSingleDatesInRange(startDate, endDate, format = defaultFormat) {
    const singleDates = []
    let currDate = dayjs(startDate, format).startOf('day')
    const lastDate = dayjs(endDate, format).startOf('day')

    while (currDate.isSameOrBefore(lastDate)) {
      singleDates.push(currDate.format(format))
      currDate = currDate.add(1, 'days')
    }

    return singleDates
  }

  formatTimeRange(t1, t2) {
    if (validator.empty(t1)) return null
    const t1Formatted = this.formatTime(t1)
    if (validator.empty(t2)) return t1Formatted
    const t2Formatted = this.formatTime(t2)
    return `${t1Formatted}-${t2Formatted}`
  }

  // take a parsed time string, turn into succinct friendly format like "3pm"
  formatTime(parsedTime) {
    const parts = this.getTimeParts(parsedTime)
    if (parts == null) return null
    const { hr, min } = parts
    let hrInt = Number.parseInt(hr, 10)
    const amPm = hrInt >= 12 ? 'pm' : 'am'
    hrInt = hrInt % 12 === 0 ? 12 : hrInt % 12
    const parsed = `${hrInt}${min > 0 ? `:${min}` : ''}${amPm}`
    return parsed
  }

  // take a user-typed time string, turn into a parsable TimeSpan format like "15:00"
  parseTime(str, assumePm = false) {
    if (str == null || str.toString().trim() === '') return null
    const timeRegex = /(?<hr>\d+):?(?:(?<min>\d+))?(?:\s*(?<amPm>[ap]m?))?/i
    const timeParts = timeRegex.exec(str)
    if (timeParts == null) return null
    const parts = timeParts.groups
    let hr = parts.hr
    let min = parts.min
    const amPm = parts.amPm

    // hr
    let hrInt = Number.parseInt(hr, 10)
    if (!Number.isFinite(hrInt)) return null
    else if (hrInt >= 100) {
      if (hr.length === 3) {
        min = hr.slice(1)
        hr = hr[0]
      } else {
        min = hr.slice(2)
        hr = hr.slice(0, 2)
      }
      hrInt = Number.parseInt(hr, 10)
    } else if (hrInt > 24 || (hrInt === 24 && min > 0)) {
      hrInt = 23
      hr = hrInt.toString()
    }

    // min
    const minInt = Number.parseInt(min, 10)
    if (Number.isNaN(minInt)) {
      min = '00'
    } else {
      if (min.length === 1) min = `${minInt}0`
      // if they've typed only 1 digit, assume there will be another digit. e.g. "4:1" should be assumed to be "4:10"
      else if (minInt > 59) min = '59'
    }

    // amPm
    const isAm = /a/i.test(amPm)
    const isPm = /p/i.test(amPm)
    if (hrInt === 12 && isAm) {
      hrInt = 0
      hr = '00'
    } else if (hr < 12 && !isAm && (assumePm || isPm)) {
      hrInt += 12
      hr = hrInt.toString()
    }

    return `${hr}:${min}`
  }

  diffTimeHrs(t1Parsed, t2Parsed) {
    if (validator.empty(t1Parsed) || validator.empty(t2Parsed)) return null
    const diffMinutes = this.parseTimeToDate(t2Parsed).diff(this.parseTimeToDate(t1Parsed), 'minutes')
    if (diffMinutes === 60) return 1
    let diffHrs = diffMinutes / 60
    if (diffHrs < 0) diffHrs += 24 // assume next day, if t1 > t2. e.g. 10pm to 1am should be 3hrs, not -21hrs
    return Number.isFinite(diffHrs) ? diffHrs : null
  }

  diffTimeHrsFormatted(t1Parsed, t2Parsed) {
    const diff = this.diffTimeHrs(t1Parsed, t2Parsed)
    if (diff === 1) return '1hr'
    return diff == null ? null : diff.toFixed(2).replace(/\.00$|0$/, '') + 'hrs'
  }

  parseTimeToDate(time) {
    return dayjs(`1/1/2020 ${time}`)
  }

  getTimeParts(parsedTime) {
    const parsedTimeRegex = /(?<hr>\d{1,2}):(?<min>\d{2})/
    const timeParts = parsedTimeRegex.exec(parsedTime)
    if (timeParts == null) return null
    return timeParts.groups
  }

  mergeConsecutiveDates(dateArray, format = 'M/D/YYYY') {
    if (!dateArray?.length) return []
    const mapped = dateArray.map(d => dayjs(d, format))
    mapped.sort((a, b) => a.diff(b))
    const mergedRanges = []
    let startDate = mapped[0]
    let endDate = startDate

    for (let i = 1; i < dateArray.length; i++) {
      const date = mapped[i]
      const diff = date.diff(endDate, 'day')
      if (diff < 0) throw new Error('Cannot merge consecutive dates as they are not in order.')
      if (diff <= 1) {
        endDate = date
      } else {
        mergedRanges.push({
          startDate,
          endDate,
          formattedStartDate: startDate.format(format),
          formattedEndDate: endDate.format(format),
        })
        startDate = date
        endDate = date
      }
    }

    mergedRanges.push({
      startDate,
      endDate,
      formattedStartDate: startDate.format(format),
      formattedEndDate: endDate.format(format),
    })

    return mergedRanges
  }

  compareTimes(a, b, aNullValue) {
    if (a == null) {
      if (b == null) return 0
      return aNullValue
    }
    if (b == null) return -aNullValue
    const aTime = this.parseTimeToDate(a)
    const bTime = this.parseTimeToDate(b)
    return aTime.diff(bTime)
  }

  compareDays(a, b, format = defaultFormat) {
    if (a == null || b == null) return 0
    const start = dayjs(a, format)
    const end = dayjs(b, format)
    if (start.isValid() && end.isValid()) return end.diff(start, 'd')
    return 0
  }

  sortByStartAndEndTime(times) {
    return times.sort((a, b) => {
      const startDiff = this.compareTimes(a.startTime, b.startTime, -1)
      if (startDiff !== 0) return startDiff
      return this.compareTimes(a.endTime, b.endTime, 1)
    })
  }

  // Assumes times are already sorted by start and end time
  distinctStartAndEndTime(times) {
    const distinctTimes = []
    let lastTime = null
    for (const time of times) {
      if (lastTime == null || time.startTime != lastTime.startTime || time.endTime != lastTime.endTime) {
        distinctTimes.push(time)
        lastTime = time
      }
    }
    return distinctTimes
  }
}

export default new DateService()

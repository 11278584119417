class NotificationService {
  getIcon(notificationTypeName) {
    switch (notificationTypeName) {
      case 'ConnectionRequested':
      case 'ConnectionAccepted':
        return 'user'
      case 'MatchStudentAccepted':
      case 'MatchClinicalSiteAccepted':
      case 'StaffConfirmed':
      case 'StudentConfirmed':
      case 'AgreementAccepted':
      case 'MatchApplicationStepsCompleted':
      case 'MatchOnboardingCompleted':
      case 'MatchOffboardingCompleted':
      case 'MatchEvaluationCompleted':
      case 'MatchStepVerified':
        return 'check'
      case 'MatchStudentClosed':
      case 'MatchClinicalSiteClosed':
      case 'StaffRejected':
      case 'StudentRejected':
      case 'AgreementClosed':
      case 'MatchStepRejected':
        return 'close'
      case 'MatchRequested':
      case 'MatchAddedBySchoolCoordinator':
      case 'MatchStudentProposedChanges':
      case 'MatchClinicalSiteProposedChanges':
      case 'MatchClinicalSiteReopened':
      case 'MatchStudentReopened':
      case 'AgreementProposedChanges':
        return 'edit'
      case 'EndorsementReceived':
        return 'thumbs-up'
      case 'MatchStudentOnboardingReminder':
      case 'MatchSchoolOnboardingReminder':
      case 'MatchClinicalSiteOnboardingReminder':
      case 'AgreementSubmitted':
        return 'time'
      case 'Mentioned':
        return 'comment-empty'
      default:
        return 'notification-empty'
    }
  }

  getColor(notificationTypeName) {
    switch (notificationTypeName) {
      case 'MatchStudentAccepted':
      case 'MatchClinicalSiteAccepted':
      case 'EndorsementReceived':
      case 'StaffConfirmed':
      case 'StudentConfirmed':
      case 'ConnectionAccepted':
      case 'AgreementAccepted':
      case 'MatchApplicationStepsCompleted':
      case 'MatchOnboardingCompleted':
      case 'MatchOffboardingCompleted':
      case 'MatchEvaluationCompleted':
      case 'MatchStepVerified':
        return 'success'
      case 'MatchStudentClosed':
      case 'MatchClinicalSiteClosed':
      case 'StaffRejected':
      case 'StudentRejected':
      case 'ConnectionRejected':
      case 'AgreementClosed':
      case 'MatchStepRejected':
        return 'danger'
      case 'MatchRequested':
      case 'MatchAddedBySchoolCoordinator':
      case 'MatchStudentProposedChanges':
      case 'MatchClinicalSiteProposedChanges':
      case 'MatchClinicalSiteReopened':
      case 'MatchStudentReopened':
      case 'MatchStudentOnboardingReminder':
      case 'MatchSchoolOnboardingReminder':
      case 'MatchClinicalSiteOnboardingReminder':
      case 'AgreementSubmitted':
      case 'AgreementProposedChanges':
        return 'warning'
      default:
        return 'info'
    }
  }
}

export default new NotificationService()

// chrome's implementation of `Element.scrollIntoView` doesn't seem to work as well
// on profile progress, for instance, so just always use the polyfill
// https://github.com/iamdustan/smoothscroll
window.__forceSmoothScrollPolyfill__ = true

import smoothscroll from 'smoothscroll-polyfill'

const previousRightMargins = {}

smoothscroll.polyfill()

export default function scrollToEl(el, behavior = 'smooth') {
  if (el == null) return
  el.scrollIntoView({ behavior })
}

export function getScrollParent(el) {
  if (el == null) return null
  if (isScrollable(el)) return el
  return getScrollParent(el.parentNode)
}

// TODO: consider alternative ways to lock scroll bar that don't require `addScrollbarOffset`
// https://stackoverflow.com/a/29299549/221867
// or https://stackoverflow.com/a/8701977/221867
export function lockScroll(el, offsetScrollBar = false) {
  if (el == null) return
  el.style.overflow = 'hidden'
  if (offsetScrollBar) addScrollbarOffset(el)
}

export function unlockScroll(el) {
  if (el == null) return
  el.style.overflow = null
  removeScrollbarOffset(el)
}

function isScrollable(el) {
  // add 30 since seems to be 20 off sometimes. not sure why. and can't find a height of el that includes the 20. maybe not accounting for padding or something.
  return el.scrollHeight > el.clientHeight + 40
}

function addScrollbarOffset(el) {
  // if el has a scrollbar and bump it over a bit, so content doesn't shift when scrollbar goes away
  if (isScrollable(el) && el.style.marginRight === '') {
    previousRightMargins[el] = true //el.style.marginRight
    el.style.marginRight = '10px'
  }
}

function removeScrollbarOffset(el) {
  if (previousRightMargins[el]) {
    el.style.marginRight = null
    delete previousRightMargins[el]
  }
}

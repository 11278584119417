<ButtonModalPicker
  bind:this={buttonModalPicker}
  bind:open
  lg
  {disabled}
  {multiple}
  valueSelector={c => c.capacityId}
  labelSelector={c => c.name}
  options={capacities}
  optionsForLabel={capacitiesForLabel}
  bind:value
  bind:valueTemp
  {modalTitle}
  {placeholder}
  {dataTest}
  modalClass="overflow-visible"
  modalContentClass="overflow-visible"
  {allowSelectNull}
  {or}
>
  <!-- We don't want the contents of the picker to appear invalid, so wrap it in an always-valid <FormGroup> -->
  <FormGroup valid class="full-height flex-column g1">
    <CapacityFilters fullWidth {excludedFilterTypes} bind:filters {interceptors} onChanged={() => loadPage(0)}>
      <svelte:fragment slot="after-keyword-search">
        <CapacityPickerShowDropdown />
      </svelte:fragment>
    </CapacityFilters>

    {#if otherOrg}
      <InputCheckbox
        bind:checked={includeCapacitiesOpenToAnyGuest}
        name="include-all-open-orgs"
        labelClass="flex-row flex-align-center g05 m0 normal"
      >
        Include opportunities open to any school
      </InputCheckbox>
    {/if}

    {#if loading && currentXhrBody.offset === 0}
      <div class="text-center mt1" data-test="{dataTest}-loading">
        <Spinner x3 class="m2" />
      </div>
    {:else}
      <InfiniteScroll
        currentCount={capacities?.length}
        {totalCount}
        distanceToLoadPage={100}
        {loadPage}
        class="scrollable-lg flex-grow flex-column {showMinimal ? 'g05' : 'g1'}"
        style="padding-right: 15px"
      >
        <EmptyPickerSlot
          bind:valueTemp
          {allowSelectNull}
          text="No capacity"
          {multiple}
          {buttonModalPicker}
          icon="list"
          iconClass="color-text-purple"
          {dataTest}
          lg={!showMinimal}
        />
        {#if capacities?.length}
          {#each capacities as c, i (c.capacityId)}
            <PickerSlot
              {i}
              {dataTest}
              disabled={c.disabled}
              {multiple}
              disabledMessage={c.disabledMessage}
              value={c.capacityId}
              {buttonModalPicker}
              lg={!showMinimal}
              bind:valueTemp
              let:isSelected
              let:isHovered
            >
              <slot name="header" capacity={c} slot="header">
                <Icon lg={!showMinimal} name="list" class="color-text-purple" />
                <CapacityGroupSizeIcon capacity={c} iconProps={{ lg: !showMinimal }} />

                <svelte:element this={showMinimal ? 'h4' : 'h3'} class="leading-none m0 normal" data-test="capacity-name">{c.name}</svelte:element>
              </slot>

              <slot capacity={c} {isSelected} {isHovered}>
                <CapacityPickerSlot capacity={c} {isHovered} {isSelected} />
              </slot>
            </PickerSlot>
          {/each}
        {:else if capacities == null}
          <!-- This should never happen, but just in case... -->
          Failed to load opportunities. <a href={null} on:click={() => loadPage(0)}>Retry?</a>
        {:else if otherOrg}
          <Alert type="warning" dataTest="no-opportunities-warning">
            No opportunities found with <strong class="strongish">{otherOrg.name}</strong> matching all filters.
            {#if $persona.personaType === PersonaType.SchoolStaff}
              Common reasons you may not be seeing what you expect:
              <ul>
                <li>The opportunity must be <strong class="strongish">Open for scheduling</strong>.</li>
                <li>It must not be past the opportunity’s <strong class="strongish">End date</strong>.</li>
                <li>The opportunity must have <strong class="strongish">Rotations available</strong>.</li>
                <li>The opportunity must allow <strong class="strongish">School coordinator scheduling</strong>.</li>
                <li>
                  If the opportunity is made available via an <strong class="strongish">Agreement</strong>, it must be
                  <strong class="strongish">Active</strong>.
                </li>
              </ul>
            {/if}
          </Alert>
        {:else}
          <h4 class="p3 text-center">No opportunities found.</h4>
        {/if}
      </InfiniteScroll>
    {/if}
  </FormGroup>
</ButtonModalPicker>

<script>
  import { CapacityListProperty, FeatureType, FilterType, PersonaType, DateTimeComparison, DateTimeComparisonProviderType } from 'config/enums.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import api from 'services/api.js'
  import ButtonModalPicker from 'components/fields/ButtonModalPicker.svelte'
  import CapacityFilters from 'components/CapacityFilters.svelte'
  import CapacityGroupSizeIcon from 'components/CapacityGroupSizeIcon.svelte'
  import CapacityPickerShowDropdown from 'components/CapacityPickerShowDropdown.svelte'
  import CapacityPickerSlot from 'components/fields/CapacityPicker.Slot.svelte'
  import EmptyPickerSlot from 'components/EmptyPickerSlot.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from 'components/Icon.svelte'
  import InfiniteScroll from 'components/InfiniteScroll.svelte'
  import persona from 'stores/persona.js'
  import personaService from 'services/persona-service.js'
  import PickerSlot from 'components/PickerSlot.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'
  import Spinner from 'components/Spinner.svelte'
  import validator from 'services/validator.js'
  import InputCheckbox from 'components/fields/InputCheckbox.svelte'
  import personaFilters from 'stores/persona-filters.js'

  // Common picker exports
  export let value
  export let filters = []
  export let excludedFilterTypes = []
  export let placeholder = 'None selected'
  export let multiple = false
  export let modalTitle = multiple ? 'Select the opportunities' : 'Select the opportunity'
  export let disabled = false
  export let allowSelectNull = false
  export let dataTest = 'capacity-picker'
  export let interceptors = {}
  export let open = false

  // Specific picker exports
  export let capacityCount = null
  export let selected = null // Calling code can bind this upward to know the selected capacity(ies), not just capacityId(s). Calling code should not _set_ this, only read it.
  export let otherOrg
  export let includeShifts = false
  export let or = false
  export let switchingFromCapacityId = null

  const pageSize = 15
  let buttonModalPicker = null
  let totalCount = null
  let capacities = []
  let capacitiesMap = new Map()
  let capacitiesForLabel = []
  let hiddenFilters = []
  let valueTemp = null
  let loading = false
  let currentXhrBody = null
  let includeCapacitiesOpenToAnyGuest = false
  let currentPersonaOrgId

  $: show = $showDropdowns.capacityPicker
  $: showMinimal = !Object.keys(show)
    .map(k => show[k])
    .some(Boolean)

  // TODO(services): Retain changes in a store.
  $: personaOrgId = $personaFilters.orgId
  $: personaTypeIsSchool = $persona.personaType === PersonaType.SchoolStaff
  $: hasCoreSchedulingFeature = personaService.canUseAnyFeatureType(FeatureType.CoreScheduling)
  $: switchingFromCapacityId, setDefaultFilters()
  $: hiddenCanSwitchMatchToCapacityFilter = switchingFromCapacityId
    ? {
        type: FilterType.CanSwitchMatchToCapacity,
        config: { capacityId: switchingFromCapacityId },
      }
    : null
  $: hiddenConnectedOrgFilter = otherOrg
    ? {
        type: personaTypeIsSchool ? FilterType.ConnectedHostOrg : FilterType.ConnectedGuestOrg,
        config: {
          orgId: otherOrg.orgId,
          includeCapacitiesOpenToAnyGuest: includeCapacitiesOpenToAnyGuest,
        },
      }
    : null
  $: hiddenFilters = [
    hiddenCanSwitchMatchToCapacityFilter,
    //hiddenRespectOrgMatchPreferencesFilter,
    hiddenConnectedOrgFilter,
  ].filter(Boolean)
  $: personaOrgId, hasCoreSchedulingFeature, otherOrg, includeCapacitiesOpenToAnyGuest, loadPage(0)
  $: open, loadFirstPageIfFiltersChanged()
  $: capacitiesForLabel = [...capacitiesMap.values()]
  $: selected = value == null ? null : multiple ? value.map(capacityId => capacitiesMap.get(capacityId)).filter(Boolean) : capacitiesMap.get(value)

  function loadFirstPageIfFiltersChanged() {
    if (!open || validator.equals(filters ?? [], currentXhrBody?.filters ?? [])) return
    if (open) loadPage(0)
  }

  function setDefaultFilters() {
    if (!switchingFromCapacityId) return
    filters = filters.filter(f => f.type !== FilterType.CapacityStartDate && f.type !== FilterType.CapacityEndDate)
    filters.push(
      {
        type: FilterType.CapacityStartDate,
        config: {
          comparisonProviderType: DateTimeComparisonProviderType.RelativeDays,
          comparisonProvider: {
            comparison: DateTimeComparison.IsBeforeOrEqual,
            daysFromToday: 0,
          },
        },
      },
      {
        type: FilterType.CapacityEndDate,
        config: {
          comparisonProviderType: DateTimeComparisonProviderType.RelativeDays,
          comparisonProvider: {
            comparison: DateTimeComparison.IsAfterOrEqual,
            daysFromToday: 0,
          },
        },
      }
    )
  }

  async function loadPage(offset) {
    if (!$persona.orgId || !hasCoreSchedulingFeature) return

    const thisXhrBody = {
      filters: _.cloneDeep([...filters, ...hiddenFilters]),
      sortProperty: CapacityListProperty.OrganizationRelativeName,
      includeServices: true,
      includeGuests: true,
      includeOrgAndTeam: true,
      includeAddresses: true,
      includeAgreements: true,
      includeLocations: true,
      includeShifts,
      includeDateExceptions: true,
      includeStatusCounts: true,
      includeTags: true,
      hasntEnded: true,
      includeValidationData: true,
      pageSize,
      offset,
    }

    const selectedCapacityIds = (multiple ? value ?? [] : [value]).filter(id => id != null && !capacitiesForLabel.some(c => c.capacityId === id))
    if (selectedCapacityIds.length) thisXhrBody.selectedCapacityIds = selectedCapacityIds

    const ignoreKeys =
      selected != null &&
      (validator.equals([selected.capacityId], currentXhrBody.selectedCapacityIds) ||
        validator.equals([selected.capacityId], thisXhrBody.selectedCapacityIds))
        ? ['selectedCapacityIds']
        : []
    if (validator.equals(currentXhrBody, thisXhrBody, ignoreKeys) && currentPersonaOrgId === personaOrgId) return
    loading = true
    currentXhrBody = thisXhrBody
    currentPersonaOrgId = personaOrgId

    try {
      const task = api.capacity.list(thisXhrBody, api.noMonitor)
      const response = await task
      // TODO: Could handle if filters were changed prior to response being received... for now, assume server is fast enough.
      totalCount = response.totalCount
      capacities = offset ? [...capacities, ...response.capacities] : response.capacities
      // Set the freshest copy of each capacity for the label.
      for (const capacity of response.capacities ?? []) capacitiesMap.set(capacity.capacityId, capacity)
      for (const capacity of response.selectedCapacities ?? []) capacitiesMap.set(capacity.capacityId, capacity)
      // Reactivity
      capacitiesMap = capacitiesMap
      if (offset === 0 && capacityCount == null) capacityCount = totalCount
    } finally {
      if (validator.equals(currentXhrBody, thisXhrBody)) loading = false
    }
  }

  export function clear() {
    value = null
    valueTemp = null
  }

  export function focusAndOpen() {
    buttonModalPicker?.focusAndOpen()
  }
</script>

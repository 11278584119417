<div
  class="flex-row flex-align-center g05"
  use:tip={noTooltip || !tooltipElem ? null : { content: tooltipElem, options: { theme: 'light-gray-scrollable', ...tipOptions } }}
>
  {#if course.status === CourseStatus.Upcoming}
    <Icon name="Ready" class={className ?? 'text-purple'} {...iconProps} />
    <span>Upcoming</span>
  {:else if course.status === CourseStatus.Active}
    <Icon name="Active" class={className ?? 'text-success'} {...iconProps} />
    <span>Active</span>
  {:else if course.status === CourseStatus.Completed}
    <Icon name="Completed" class={className ?? 'text-gray'} {...iconProps} />
    <span>Completed</span>
  {:else if course.status === CourseStatus.Incomplete}
    <Icon name="Closed" class={className ?? 'text-danger'} {...iconProps} />
    <span>Incomplete</span>
  {/if}
</div>

<div class="hide">
  <div bind:this={tooltipElem}>
    {#if course.status === CourseStatus.Upcoming}
      Course will start {fromNowStart} on {startDate} and will end {duration} after that on {endDate}.
    {:else if course.status === CourseStatus.Active}
      Course started {fromNowStart} on {startDate} and will end {fromNowEnd} on {endDate}.
    {:else if course.status === CourseStatus.Completed}
      Course ended {fromNowEnd} and all hours have been logged.
    {:else if course.status === CourseStatus.Incomplete}
      Course ended {fromNowEnd}, but some or all required hours have not been logged.
    {/if}
  </div>
</div>

<script>
  import { CourseStatus } from 'config/enums.js'
  import Icon from 'components/Icon.svelte'
  import tip from 'decorators/tip.js'
  import dateService from 'services/date-service.js'

  export let course
  let className = null
  export { className as class }
  export let noTooltip = false
  export let tipOptions = null
  export let iconProps = {}

  let tooltipElem

  $: fromNowStart = dateService.fromnow(course.startDate)
  $: fromNowEnd = dateService.fromnow(course.endDate)
  $: startDate = dateService.datestamp(course.startDate)
  $: endDate = dateService.datestamp(course.endDate)
  $: duration = dayjs(course.startDate).from(course.endDate, true)
</script>

<div use:tip={tooltip} class={className} class:g05={xxs || xs || _small} class:g1={medium || large || inputSized} class:g2={xLarge}>
  {#if !deleted}
    <TeamProfilePicture allowSpecial {orgId} {name} {xxs} {xs} small={_small} {medium} {large} {xLarge} {inputSized} />
    {#if !profilePicOnly && name}
      {#if orgRelativeName}
        <div>
          <div class="strong">{name}</div>
          <small>{formatRelativeName(orgRelativeName)}</small>
        </div>
      {:else}
        <span class={nameClass}>{name}</span>
      {/if}
    {:else if !profilePicOnly}
      <span class={nameClass}>{specialName}</span>
    {/if}
  {/if}
</div>

<script>
  import tip from 'decorators/tip.js'
  import TeamProfilePicture from 'components/TeamProfilePicture.svelte'
  import { formatRelativeName } from 'services/orgs-service.js'

  export let orgId
  export let name
  export let specialName = 'All teams'
  export let orgRelativeName = null
  export let profilePicOnly = false
  export let xxs = false
  export let xs = false
  export let small = false
  export let medium = false
  export let large = false
  export let xLarge = false
  export let inputSized = false
  export let tooltip = null
  let className = 'flex-row flex-align-center text-left'
  export { className as class }
  export let nameClass = null
  export let deleted = false

  $: _small = small || (!xxs && !xs && !medium && !large && !xLarge && !inputSized)
</script>

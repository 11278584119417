{#each orgStaff.orgStaffRoles as osr (osr.staffRoleId)}
  <StaffRolesRole
    {countsOnly}
    {hasHealthFeature}
    {hasSchoolFeature}
    {hasTeamFeature}
    {hasCustomTagFeature}
    {orgStaff}
    {showAutoAssigned}
    {showMultipleRestrictions}
    role={osr}
  />
{/each}

<script>
  import StaffRolesRole from 'components/StaffRoles.Role.svelte'

  export let hasHealthFeature
  export let hasSchoolFeature
  export let hasTeamFeature
  export let hasCustomTagFeature
  export let orgStaff
  export let showAutoAssigned = false
  export let showMultipleRestrictions = false
  export let countsOnly = false
</script>

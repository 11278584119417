{#if editing}
  <FormGroup>
    <InputToggle name="post-to-search-page" bind:checked={filter.postToSearchPage}>
      Available on the
      <Icon name="search" lg class="text-gray" />
      <strong class="strongish"> Find clinical experiences </strong> page
    </InputToggle>
  </FormGroup>
{:else if filter.postToSearchPage}
  Available on the
  <Icon name="search" lg class="text-gray" />
  <strong class="strongish">Find clinical experiences</strong> page
{:else}
  <strong>NOT</strong> available on the
  <Icon name="search" lg class="text-gray" />
  <strong class="strongish">Find clinical experiences</strong> page
{/if}

<script context="module">
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import { FilterType } from 'config/enums.js'

  const label = '<strong class="strongish">Find clinical experiences</strong> page availability'

  export const meta = {
    type: FilterType.CapacityPostToSearchPage,
    label,
    editLabel: null,
    hideLabelIcon: true,
    icon: 'search',
    iconClass: 'text-gray',

    // eslint-disable-next-line no-unused-vars
    encode(writer, appliedMeta, config) {
      writer.writeArg(config.postToSearchPage)
    },

    // eslint-disable-next-line no-unused-vars
    decode(reader, appliedMeta) {
      const config = {}
      config.postToSearchPage = reader.readBool()
      return config
    },

    create() {
      return { postToSearchPage: true }
    },

    validate() {
      return true
    },
  }
</script>

<script>
  import Icon from 'components/Icon.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'

  export let filter
  export let editing = false
  // export let appliedMeta
</script>

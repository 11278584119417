<InputSelect
  bind:this={inputSelect}
  {name}
  bind:value
  bind:selected
  {multiple}
  filterable={options.length > 5}
  filterStringSelector={o => [o.name, o.description, o.orgName ?? o.org?.name, o.teamName ?? o.team?.name]}
  {placeholder}
  options={visibleOptions}
  contentClass="flex-row flex-align-center g1"
  valueSelector={o => o.customTagId}
  {loading}
  showOthersTip
  othersTipClass="flex-column g05"
  let:option={customTag}
>
  <CustomTag {customTag} tooltipPlacement="right" tooltipInteractive={false} />
  <CustomTag slot="label" {customTag} />
  <CustomTag slot="labelOther" {customTag} tooltipPlacement="right" />
</InputSelect>

<script>
  import api from 'services/api.js'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import CustomTag from 'components/CustomTag.svelte'

  export let orgId
  export let multiple = true
  export let value = multiple ? [] : null
  export let selected = null
  export let name = 'custom-tag-ids'
  export let enabledCustomTagIds = null
  export let disabledCustomTagIds = null
  export let hideDisabledOptions = false
  export let leaveDisabledOptionsSelected = false
  export let placeholder = 'Select custom tags'
  export let unavailableCustomTagIds = null
  export let options = [] // Intended to be readonly (at time of writing)

  $: if (orgId) loadOptions()

  $: visibleOptions =
    hideDisabledOptions && enabledCustomTagIds != null
      ? options.filter(o => enabledCustomTagIds.includes(o.customTagId))
      : hideDisabledOptions && disabledCustomTagIds != null
      ? options.filter(o => !disabledCustomTagIds.includes(o.customTagId))
      : options
  $: value = value
    ? leaveDisabledOptionsSelected || loading
      ? value
      : multiple
      ? value.filter(id => visibleOptions.some(o => o.customTagId === id))
      : visibleOptions.includes(value)
      ? value
      : null
    : null

  $: unavailableCustomTagIds = value
    ? loading
      ? []
      : multiple
      ? value.filter(id => !visibleOptions.some(o => o.customTagId === id))
      : visibleOptions.some(o => o.customTagId === value)
      ? []
      : [value]
    : null

  let currentXHR = null
  let loading = false
  async function loadOptions() {
    if (loading) return
    loading = true
    let thisXHR
    try {
      currentXHR = thisXHR = api.customTag.getCustomTags({ orgId }, api.noMonitor)
      const response = await thisXHR
      if (thisXHR === currentXHR) options = response
    } finally {
      if (thisXHR === currentXHR) loading = false
    }
  }

  let inputSelect
  export function focus() {
    inputSelect?.focus?.()
  }

  export function open() {
    inputSelect?.open()
  }
</script>

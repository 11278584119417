<!--
  issues:
  - paste not perfect--seems to only works if cursor is in right place
  - ctrl+back not perfect--should back up to next word boundary

  later on, we could implement our own, but this handles the needed scenarios well for now
-->
<MaskInput
  alwaysShowMask
  {maskChar}
  {mask}
  {maskFormat}
  {value}
  defaultValue={value}
  on:change={onChange}
  on:blur
  on:keyup
  on:keydown
  on:input
  on:focus
  class="form-control"
  type="text"
  {disabled}
  data-test={dataTest}
/>

<script context="module">
  // If you add another one, make sure to update validator.mask() & getRegexFromMask()
  export const maskFormat = [
    {
      str: '0',
      regexp: /\d/,
      desc: 'numeric characters (0-9)',
    },
    {
      str: '*',
      regexp: /./,
      desc: 'any character',
    },
    {
      str: 'a',
      regexp: /[A-Za-z]/,
      desc: 'alphabet characters (a-z, A-Z)',
    },
  ]
</script>

<script>
  import MaskInput from 'svelte-input-mask'

  export let value
  export let mask
  export let disabled = false
  export let dataTest = null

  const maskChar = '_'

  function onChange(e) {
    const maskedVal = e.detail.inputState.maskedValue
    const complete = !maskedVal.includes(maskChar) // this wouldn't work if the mask had a literal "_" in it...
    value = complete ? maskedVal : null
  }
</script>

<div class="flex-row flex-align-center mb05{i ? ' mt2' : ''}">
  <OrgProfilePictureAndName
    name={step.orgName}
    relativeName={step.orgRelativeName}
    profilePicture={step.orgProfilePicture}
    nameClass="strong"
    size={anyNestedOrgs ? 'medium' : 'small'}
    class="flex-row flex-align-center nowrap"
  />
  <a class="small ml05" href={null} on:click={() => toggleOrgSelection(step.orgId)}>
    {#if anyStepsSelected}deselect{:else}select{/if} all
  </a>
</div>

<script>
  import OrgProfilePictureAndName from './OrgProfilePictureAndName.Names.svelte'

  export let selectedStepIds
  export let step
  export let i
  export let filterOptions
  export let anyNestedOrgs
  export let onChangeSelectedStepIds // not sure why, but two-way binding filter or filter.stepIds on `FilterTypeStepOrg` causes an infinite loop

  $: anyStepsSelected = anyStepsSelectedForOrg(step.orgId)

  function anyStepsSelectedForOrg(orgId) {
    return selectedStepIds.some(id => filterOptions.find(o => o.stepId === id)?.orgId === orgId)
  }

  function toggleOrgSelection(orgId) {
    const orgStepIds = filterOptions.filter(o => o.orgId === orgId).map(s => s.stepId)
    if (anyStepsSelected) {
      onChangeSelectedStepIds(selectedStepIds.filter(id => !orgStepIds.includes(id)))
    } else {
      onChangeSelectedStepIds([...selectedStepIds, ...orgStepIds.filter(id => !selectedStepIds.includes(id))])
    }
  }
</script>

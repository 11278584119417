<QuickDropdown label="Show…" icon="sliders-simple" btnClass="btn btn-default" labelClass="flex-row flex-align-center g05" dropdownClass="p2">
  <div class="flex-column g2">
    <div class="flex-row flex-align-center g05">
      <Btn clearBtnStyling dataTest="show-everything" on:click={() => toggleAll(true)}>Show everything</Btn>
      <div class="flex-grow" />
      <Btn clearBtnStyling dataTest="show-minimal" on:click={() => toggleAll(false)}>Show minimal</Btn>
    </div>

    <div class="flex-column g05">
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.servicePicker.activeCapacityCount} name="show-capacities">
        <Icon lg name="list" class="color-text-purple" />
        Active opportunities
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.servicePicker.agreements} name="show-agreements">
        <Icon lg name="agreement" autoColor />
        Agreements
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.servicePicker.description} name="show-description">
        <Icon lg name="paragraph" class="color-text-steel" />
        Description
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.servicePicker.specialties} name="show-specialties">
        <Icon lg name="file-certificate" class="text-specialty" />
        Specialties
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.servicePicker.disciplines} name="show-disciplines">
        <Icon lg name="book" class="color-text-teal" />
        Disciplines
      </InputToggle>
    </div>

    <div class="flex-column g05">
      <h4>Provided by</h4>
      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.servicePicker.organization} name="show-organization">
        <Icon lg name="hospital" class="color-text-orange" />
        Organization
      </InputToggle>

      <InputToggle {...toggleParams} class="m0" bind:checked={$showDropdowns.servicePicker.team} name="show-team">
        <Icon lg name="users" class="color-text-orange" />
        Team
      </InputToggle>
    </div>
  </div>
</QuickDropdown>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'
  import InputToggle from 'components/fields/InputToggle.svelte'
  import QuickDropdown from 'components/QuickDropdown.svelte'
  import showDropdowns from 'stores/show-dropdowns.js'

  const toggleParams = {
    containerClass: 'flex-row flex-align-center g1',
    labelClass: 'flex-row flex-align-center g05',
    class: 'm0',
  }

  function toggleAll(checked) {
    for (const key in $showDropdowns.servicePicker) {
      if (typeof $showDropdowns.servicePicker[key] !== 'boolean') continue
      $showDropdowns.servicePicker[key] = checked
    }
  }
</script>

<style>
  h4 {
    margin: 0;
    line-height: 1;
    font-size: 16px;
  }
</style>

<Modal title="Account already exists" on:close>
  {#if successMessage}
    <p>
      <SafeHtml value={successMessage} />
    </p>
    <div class="modal-footer">
      <Btn class="btn-primary" href="/login" dataTest="goto-login-btn">Go to login</Btn>
    </div>
  {:else}
    <p>This email is already registered.</p>
    <p>
      If you didn't create this account, your school or program may have created an account for you when they invited you to join the system. If you
      didn't see the invite link, you can reset the password on the account to begin using the account.
    </p>

    <div class="modal-footer">
      <!-- If the user has a confirmed email, the primary action is resetting the password. -->
      <!-- Otherwise, it's resending the invite. Currently, our convention is primary left-aligned. -->
      <OrderedSlots order={existingUser.hasConfirmedEmail ? '0-1' : '1-0'}>
        <span slot="0">
          <SendPasswordResetBtn
            user={existingUser}
            color={existingUser.hasConfirmedEmail ? 'primary' : 'default'}
            autofocus={existingUser.hasConfirmedEmail}
            on:sent={onSentEmail}
          />
        </span>
        <span slot="1">
          <ResendInviteBtn
            user={existingUser}
            class={existingUser.hasConfirmedEmail ? 'btn-default' : 'btn-primary'}
            autofocus={!existingUser.hasConfirmedEmail}
            on:sent={onSentEmail}
            alwaysShow
            clearBtnStyling={false}
          />
        </span>
      </OrderedSlots>
    </div>
  {/if}
</Modal>

<script>
  import SafeHtml from 'components/SafeHtml.svelte'
  import Modal from 'components/Modal.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import OrderedSlots from 'components/OrderedSlots.svelte'
  import ResendInviteBtn from 'components/account/ResendInviteBtn.svelte'
  import SendPasswordResetBtn from 'components/account/SendPasswordResetBtn.svelte'
  export let existingUser
  let successMessage = null

  function onSentEmail(e) {
    e.detail.shouldToast = false // "prevent default"
    successMessage = e.detail.message
  }
</script>

<style>
  p {
    margin: 20px;
  }
</style>

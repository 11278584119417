{#if stepSubmission.canSetCompleteToDate}
  <Btn on:click={startSetDueDate} clearBtnStyling loading={!collectingNewDueDate && loading} dataTest="set-due-date-btn">Change due date</Btn>
{/if}

{#if collectingNewDueDate}
  <Modal on:close={close}>
    <h4 slot="title" data-test="set-due-date-title">
      <div class="small">Change step due date</div>
      {stepSubmission.step.name}
    </h4>
    <Form on:submit={setStepDueDate}>
      <div class="modal-body">
        <FormGroup valid={dueDate != null}>
          <label for="stepDueDate">
            When should this step be due?
            <RequiredMarker />
          </label>
          <DatePicker name="stepDueDate" min={today} bind:value={dueDate} clearable />
        </FormGroup>
      </div>
      <div class="modal-footer">
        <SubmitBtn dataTest="set-due-date-step-btn" icon="save" {loading}>Change due date</SubmitBtn>
      </div>
    </Form>
  </Modal>
{/if}

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Form from 'components/Form.svelte'
  import Modal from 'components/Modal.svelte'
  import DatePicker from 'components/fields/DatePicker.svelte'
  import SubmitBtn from 'components/bootstrap/SubmitBtn.svelte'
  import api from 'services/api.js'
  import RequiredMarker from 'components/fields/RequiredMarker.svelte'
  import { StepAction } from 'config/enums.js'

  export let stepSubmission
  export let startDateOverride
  export let endDateOverride
  export let onAction

  let collectingNewDueDate = false
  let dueDate
  let loading = false

  $: today = dayjs().startOf('day')

  function startSetDueDate() {
    dueDate = stepSubmission.completeToDate
    collectingNewDueDate = true
  }

  async function setStepDueDate() {
    loading = true
    try {
      await onAction({
        type: StepAction.SetDueDate,
        invoke,
        reset: close,
        alwaysShowOthers: true,
      })
    } finally {
      loading = false
    }
  }

  function invoke(stepSubmissions) {
    return api.step.setStepsDueDate(
      {
        dueDate,
        startDateOverride,
        endDateOverride,
      },
      stepSubmissions,
      api.noMonitor
    )
  }

  function close() {
    collectingNewDueDate = false
    dueDate = null
  }
</script>

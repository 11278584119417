{#each $confirms as c, i}
  <ModalConfirm
    show
    on:confirm={c.onConfirm}
    on:cancel={() => confirms.remove(i)}
    mustType={c.mustType}
    class={c.class}
    color={c.color}
    confirmLabel={c.confirmLabel}
    confirmClass={c.confirmClass}
    confirmIcon={c.confirmIcon}
    cancelLabel={c.cancelLabel}
    cancelClass={c.cancelClass}
  >
    <h4 slot="title">{c.title || ''}</h4>
    <SafeHtml value={c.message || ''} />
  </ModalConfirm>
{/each}

<script>
  import SafeHtml from 'components/SafeHtml.svelte'
  import ModalConfirm from 'components/ModalConfirm.svelte'
  import confirms from 'stores/confirms.js'
</script>

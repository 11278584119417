<div
  class="flex-row flex-align-center g05 full-width"
  use:tip={isBulkComment || !tooltipElem
    ? null
    : {
        content: tooltipElem,
        options: {
          interactive: true,
          theme: 'light-gray-scrollable',
          placement: 'left-start',
          ...tipOptions,
        },
      }}
>
  <span class="flex-grow">All {label}</span>

  {#if !isBulkComment}
    <Badge min={0} count={users} name={label} />
  {/if}

  <div class="hide">
    <div bind:this={tooltipElem} class="flex-column g05 text-left">
      <div class="strong">Currently consists of</div>
      {#each users as user}
        <UserProfilePicAndName {user} profilePicXs />
      {:else}
        <em>Nobody</em>
      {/each}
    </div>
  </div>
</div>

<script>
  import Badge from 'components/Badge.svelte'
  import UserProfilePicAndName from './UserProfilePicAndName.svelte'
  import tip from 'decorators/tip.js'

  export let label
  export let users = []
  export let isBulkComment = false
  export let tipOptions = {}

  let tooltipElem = null
</script>

{#if stepIsNotDone}
  {#if stepSubmission.canBeCompleteNow}
    {#if stepSubmission.stepIsPastDue}{lowercase ? 's' : 'S'}hould{:else}{lowercase ? 'm' : 'M'}ust{/if}
    {#if stepSubmission.stepIsPastDue}have been{:else}be{/if}
    completed
  {:else}
    Can be completed
  {/if}
{:else if !stepSubmission.anonymize}
  Configured to be completed
{/if}
{#if stepSubmission.step.assignment === StepCompletionAssignment.Any}
  <StepSubmissionDueDate {stepSubmission} />
  by a
  <SafeHtml value={matchRoleHelpers.toList(stepSubmission.step.completion.roles, 'or', false)} />
{:else if !stepSubmission.anonymize}
  <StepSubmissionDueDate {stepSubmission} />
  by
  <span class="name">{youOrName(stepSubmission.user)}</span>
  {#if stepSubmission.step.additionalCoordinatorRoles.length}
    (or a
    <SafeHtml value={matchRoleHelpers.toList(stepSubmission.step.additionalCoordinatorRoles, 'or', false)} />
    on
    {stepSubmission.user?.userId === $user.userId ? 'your' : 'their'}
    behalf)
  {/if}
{/if}
{#if stepSubmission.targetUser?.name}for <span class="name">{youOrName(stepSubmission.targetUser)}</span>{/if}
{#if onAction && stepIsNotDone}
  <StepSetDueDate {stepSubmission} {startDateOverride} {endDateOverride} {onAction} />
{/if}

<script>
  import { StepCompletionAssignment, StepSubmissionStatus } from 'config/enums.js'
  import { youOrName } from 'services/you-service.js'
  import matchRoleHelpers from 'services/match-role-helpers.js'
  import SafeHtml from 'components/SafeHtml.svelte'
  import StepSubmissionDueDate from './StepSubmissionDueDate.svelte'
  import user from 'stores/user.js'
  import StepSetDueDate from './step-inputs/StepSetDueDate.svelte'

  export let stepSubmission
  export let lowercase = false
  export let startDateOverride = null
  export let endDateOverride = null
  export let onAction = null

  $: stepIsNotDone = [StepSubmissionStatus.Incomplete, StepSubmissionStatus.AwaitingVerification, StepSubmissionStatus.Rejected].includes(
    stepSubmission.status
  )
</script>

<style>
  .name {
    font-weight: bold;
    font-size: larger;
  }
</style>

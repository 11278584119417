<h3 class="m0 flex-row flex-align-center g05">
  <Icon name="graduation-cap" class="color-text-teal" />
  <Badge count={students} min={0} />
  {pluralCount('Student', students, 'omitNumber')}
</h3>
{#if canAdd}
  <AddMatchUser role={MatchRole.Student} {match} bind:matchUsers={match.matchUsers} />
{/if}
{#if students.length}
  <FormGroup class="flex-column g1 m0">
    {#each students as s}
      <UserCard
        showStudentInfo
        user={s}
        school={match.school}
        showEmail
        showPhone
        matchStatus={s.status}
        showOnboardingWarning={s.status < MatchStatus.Ready && match.status == MatchStatus.Active}
        class="m0"
        onDelete={canDelete ? deleteMatchUser(MatchRole.Student) : null}
        deleteMessage="Remove {userNameFriendly(s)} from the rotation"
        deleteMessageConfirm={{
          title: 'Remove student',
          message: `Are you sure you’d like to remove <strong>${userNameFriendly(s)}</strong> from the rotation?`,
          confirmLabel: 'Remove',
          confirmClass: 'btn-danger',
        }}
      />
    {/each}
  </FormGroup>
{/if}

<script>
  import { MatchRole, MatchStatus } from 'config/enums.js'
  import { pluralCount } from 'services/string-utils.js'
  import AddMatchUser from 'components/AddMatchUser.svelte'
  import Badge from 'components/Badge.svelte'
  import Icon from 'components/Icon.svelte'
  import UserCard from 'components/UserCard.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'

  export let match
  export let students
  export let userNameFriendly
  export let deleteMatchUser

  $: canAdd = match.isEditable && (match.isCoordinator || match.isSchoolCoordinator) // allow coordinators to change matches even after they're completed for reporting purposes
  $: canDelete = canAdd && (students.length > 1 || match.placeholderStudentCount > 0)
</script>

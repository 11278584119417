{#if team.value === ''}
  None selected
{:else}
  <TeamProfilePictureAndName
    orgId={team.orgId}
    name={team.teamId ? team.name : null}
    {orgRelativeName}
    specialName={nullTeamLabel}
    {profilePicOnly}
    {...profilePicProps}
    tooltip={tooltipElem ? { content: tooltipElem, options: { interactive: true, theme: 'light-gray-scrollable' } } : null}
  />
{/if}

{#if profilePicOnly || (!showOrgRelativeName && realOrgRelativeName)}
  <div class="hide">
    <div bind:this={tooltipElem}>
      {#if profilePicOnly}
        <svelte:self {team} showOrgRelativeName profilePicProps={{ medium: true }} />
      {:else}
        <div class="small flex-row flex-align-center g05">
          <Icon name="map-marker" />
          {formatRelativeName(realOrgRelativeName)}
        </div>
      {/if}
    </div>
  </div>
{/if}

<script>
  import TeamProfilePictureAndName from 'components/TeamProfilePictureAndName.svelte'
  import Icon from 'components/Icon.svelte'
  import { formatRelativeName } from 'services/orgs-service.js'

  export let team
  export let nullTeamLabel
  export let showOrgRelativeName = false
  export let profilePicOnly = false
  export let profilePicProps = {}

  let tooltipElem = null

  $: realOrgRelativeName = team.orgRelativeName ?? team.org?.relativeName
  $: orgRelativeName = showOrgRelativeName ? realOrgRelativeName : null
</script>

/*
SCENARIOS:
  - user sitting on page, connected to websocket
    - we deploy, they lose their websocket connection, it automatically re-connects when the server is responsive again, they see the toast to update the app
  - user sitting on page, somehow lost their websocket connection and it failed to re-connect (could happen if they lost internet connectivity long enough for signalr to give up trying to re-connect)
    - we deploy, they don't get the websocket update. But eventually they either get to a chunk of the app that fails to get some js (which we handle) or they logout/back in (which is a full page reload currently)
      - we could also make all API responses send back a "v" header, check it in api.js, but this will almost always be overkill. Not hard to hook up though--just middleware in startup.
        - or we could run a setInterval every min and do a request, but meh.
      - related: public side doesn't connect to websocket, but also doesn't change much. But public app also doesn't do much api calls, so even with that in place they wouldn't know about it necessarily
        - but again, eventually they'll either login or restart their browser.
*/

import globalLoading from 'stores/loading.js'
import sockets from 'services/sockets.js'
import toaster from 'services/toaster.js'

let showing = false
sockets.on('v', notifyIfAppWasUpdated)

function notifyIfAppWasUpdated(version) {
  // eslint-disable-next-line no-console
  console.log('checking app version', version, window.APP_VERSION)
  // Not sure why, but preview environment seems to get its AppVersion.cs file rewritten. So ignoring if either server side OR client side still somehow set to default version string
  const defaultVersion = 'YYYYMMDD_hhmmss_GitHash'
  if (version === defaultVersion) {
    // eslint-disable-next-line no-console
    console.log('Ignoring invalid AppVersion from server.')
    return
  }
  if (window.APP_VERSION === defaultVersion) {
    // eslint-disable-next-line no-console
    console.log('Ignoring invalid AppVersion from client.')
    return
  }
  if (version !== window.APP_VERSION) appWasUpdated()
}

export function appWasUpdated() {
  if (showing) return // don't show more than one at a time
  toaster.toast({
    message: 'A new version of ClinicianNexus is available. Click this to reload. Otherwise, the page will automatically reload soon.',
    type: 'info',
    icon: 'wrench',
    onClick: reload,
    onWaitComplete: reload,
    onCancel: rePromptLater,
    duration: 60000,
  })
  showing = true
  // we don't auto reload if they have no unsaved forms since they might be reading something. So just toast.
}

function reload() {
  window.location.reload()
  globalLoading.set(true)
}

function rePromptLater() {
  // if they dismiss, prompt them again in a bit
  showing = false
  setTimeout(appWasUpdated, 60000)
}

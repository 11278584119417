import toaster from 'services/toaster.js'

export default function copyToClipboard(value, successMsg = null) {
  if (navigator.clipboard?.writeText) {
    navigator.clipboard.writeText(value)
    toaster.toast({ message: successMsg || `Copied “${value}” to your clipboard.`, type: 'success', icon: 'copy' })
  } else {
    // all our supported browsers should work, but just in case...
    toaster.toast({ message: 'Failed to copy text to your clipboard. You’ll have to manually copy the text instead.', type: 'danger', icon: 'copy' })
  }
}

window.readClipboardText = async () => {
  const text = await navigator.clipboard.readText()
  toaster.toast({ message: 'CLIPBOARD TEST: ' + text, type: 'success', icon: 'copy' })
}

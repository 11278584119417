{#if userDocument && userDocumentSharing}
  <UserDocumentAbstractPreviewModal {userDocument} {userDocumentSharing} onClose={onDone} baseHref={baseHref ? `${baseHref}/${subpath}` : null}>
    <svelte:fragment slot="footer">
      <Btn class="btn-primary initial-focus" on:click={onDone} dataTest="btn-done">Done</Btn>
      {#if canManageDocuments && canEdit}
        <Btn class="btn-default" icon="edit" on:click={() => (editingUserDocument = userDocument)} dataTest="btn-rename">Rename</Btn>
      {/if}

      {#if canManageDocuments && canDownload}
        <Btn class="btn-default" icon="download" on:click={download} dataTest="btn-download" loading={downloading}>Download</Btn>
      {/if}

      {#if canManageDocuments && canDelete}
        <div class="flex-grow" />
        <Btn icon="delete" class="btn-danger" on:click={() => (deletingUserDocuments = [userDocument])} dataTest="btn-delete">Delete</Btn>
      {/if}
    </svelte:fragment>
  </UserDocumentAbstractPreviewModal>
{/if}

<script>
  import { downloadUserDocuments } from 'services/user-document-service.js'
  import { isDevEnvironment } from 'services/environment.js'
  import api from 'services/api.js'
  import Btn from 'components/bootstrap/Btn.svelte'
  import user from 'stores/user.js'
  import UserDocumentAbstractPreviewModal from 'components/UserDocumentAbstractPreviewModal.svelte'

  export let baseHref = null
  export let subpath = null // comes from <Route>.path template
  export let userDocuments = null
  export let userDocument = null // If subpath passed, this component pulls out of userDocuments, otherwise this can be manually set
  export let userDocumentSharing
  export let listBodyIncludes = {}
  export let onDone
  export let canEdit = true
  export let canDownload = true
  export let canDelete = true
  export let deletingUserDocuments = null
  export let editingUserDocument = null

  let downloading = false

  $: canManageDocuments = !$user.isImpersonating || isDevEnvironment // Impersonated students can only view documents list
  $: subpath, userDocuments, setUserDocument()

  async function download() {
    downloading = true
    try {
      await downloadUserDocuments([userDocument])
    } finally {
      downloading = false
    }
  }

  function setUserDocument() {
    if (!subpath || !userDocuments) return
    // Regex copy-pasted from other modals, but we don't support copying here
    const match = subpath.match(/^(?<plus>\+?)(?<id>\d*)$/)
    if (!match) return
    const userDocumentId = Number.parseInt(match.groups.id, 10)
    const foundUserDocument = userDocuments.find(ud => ud.userDocumentId === userDocumentId)
    if (foundUserDocument) {
      userDocument = foundUserDocument
      return
    }
    loadUserDocument(userDocumentId)
  }

  async function loadUserDocument(userDocumentId) {
    // TODO: Should show modal with loading indicator instead of global loading indicator
    const response = await api.userDocument.list({
      ...listBodyIncludes,
      selectedUserDocumentIds: [userDocumentId],
      pageSize: 0,
      excludeTotalCount: true,
    })
    userDocument = response.selectedUserDocuments[0]
    userDocumentSharing = response.userDocumentSharing
  }
</script>

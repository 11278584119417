<Btn
  on:click={startSkip}
  {loading}
  {disabled}
  class="{btnSm ? 'btn-sm ' : ''} btn-{stepSubmission.skipped ? 'default' : 'warning'}"
  dataTest="skip-step"
>
  {stepSubmission.skipped ? 'Unskip' : 'Skip'}
</Btn>

<script>
  import Btn from 'components/bootstrap/Btn.svelte'
  import { StepAction } from 'config/enums.js'
  import api from 'services/api.js'

  export let stepSubmission
  export let startDateOverride
  export let endDateOverride
  export let onAction
  export let btnSm
  export let disabled = false

  let loading = false

  async function startSkip() {
    loading = true
    try {
      const shouldSkip = !stepSubmission.skipped
      await onAction({
        type: shouldSkip ? StepAction.Skip : StepAction.Unskip,
        invoke: buildInvoke(shouldSkip),
        alwaysShowOthers: true,
      })
    } finally {
      loading = false
    }
  }

  function buildInvoke(skipped) {
    return stepSubmissions =>
      api.step.setStepsSkipped(
        {
          startDateOverride,
          endDateOverride,
          skipped,
        },
        stepSubmissions,
        api.noMonitor
      )
  }
</script>

<QuickDropdown
  anyClickCloses
  btnClass="btn btn-primary text-light btn-sm"
  dropdownClass="no-round btn-list"
  label="Add / remove calendar item"
  dataTest="add-remove-calendar-item"
>
  <div class="flex-column">
    <Btn color="outline-gray" icon="plus" on:click={addShift} dataTest="add-shift">
      Create new availability window
      {#if selectedDaysArray.length}
        with {selectedDaysCountMsg}
      {/if}
    </Btn>

    <Btn color="outline-gray" on:click={onDateExceptionsBtnClicked}>
      <div class="flex-row flex-align-center g05">
        {#if selectedDaysArray.length || !capacity?.dateExceptions.length}
          <Icon name="plus" />
          Add date {pluralCount('exception', selectedDaysArray, 'omitNumber')}
          {#if selectedDaysArray.length}
            for {selectedDaysCountMsg}
          {/if}
        {:else}
          <Icon name="edit" />
          Manage date exceptions
          <Badge xs count={capacity.dateExceptions} class="transform-none" />
        {/if}
      </div>
    </Btn>

    <Btn color="outline-gray" disabled={!selectedDaysArray.length} on:click={() => startSelectedDaysAction(false)}>
      <div class="flex-row flex-align-center g05">
        <Icon name="plus" />
        Apply {selectedDaysCountMsg} to <strong class="strongish">{existingWindowsOrOnlyShiftName}</strong>
      </div>
    </Btn>

    <Btn color="outline-danger" disabled={!selectedDaysArray.length} on:click={() => startSelectedDaysAction(true)}>
      <div class="flex-row flex-align-center g05">
        <Icon name="delete" />
        Remove {selectedDaysCountMsg} from <strong class="strongish">{existingWindowsOrOnlyShiftName}</strong>
      </div>
    </Btn>
  </div>
</QuickDropdown>

{#if showExistingShiftPicker}
  <Modal
    xl
    title="Which availability windows would you like to {removeSelection ? 'remove' : 'apply'} the {pluralCount(
      'selected day',
      selectedDaysArray
    )} {removeSelection ? 'from' : 'to'}?"
    on:close={closeApplyToExistingModal}
  >
    <div class="modal-body">
      <ShiftGrid {capacity} {orgs} bind:selectedShiftIds={shiftIdsToApplyTo} selectable />
    </div>
    <div class="modal-footer">
      <Btn class="btn-primary" dataTest="apply" icon="check" on:click={applySelectionAction}>Apply</Btn>
      <Btn icon="cancel" on:click={closeApplyToExistingModal}>Cancel</Btn>
    </div>
  </Modal>
{/if}

{#if showDateExceptionsForm}
  <Modal on:close={() => (showDateExceptionsForm = false)}>
    <h4 slot="title">
      {#if !capacity.dateExceptions?.length}
        Add date exceptions
      {:else}
        Manage date exceptions
      {/if}
    </h4>

    <DateExceptionForm bind:dateExceptions={capacity.dateExceptions} onCancel={() => (showDateExceptionsForm = false)} />
  </Modal>
{/if}

<script>
  import { removeSpecificDayAndSplitShifts, mergeShiftDays, datesToShiftDays } from 'services/calendar-service.js'
  import { pluralCount } from 'services/string-utils.js'
  import { RepeatUnit } from 'config/enums.js'
  import Badge from 'components/Badge.svelte'
  import Btn from './bootstrap/Btn.svelte'
  import DateExceptionForm, { addExceptionDate, getInputDates, getFormattedDates } from 'components/DateExceptionForm.svelte'
  import Icon from 'components/Icon.svelte'
  import Modal from 'components/Modal.svelte'
  import QuickDropdown from './QuickDropdown.svelte'
  import ShiftGrid from 'components/ShiftGrid.svelte'

  export let capacity
  export let orgs
  export let selectedDays
  export let addShift = _.noop
  export let clearSelectedDays = _.noop

  const labelFormat = 'M/D/YYYY'
  let showExistingShiftPicker = false
  let removeSelection = false
  let shiftIdsToApplyTo = []
  let showDateExceptionsForm = false

  $: capacityShifts = capacity.shifts
  $: selectedDaysArray = selectedDays ? [...selectedDays] : []
  $: selectedDaysCountMsg = pluralCount('selected day', selectedDaysArray)
  $: existingWindowsOrOnlyShiftName = capacityShifts?.length > 1 ? 'existing windows' : capacityShifts[0]?.name

  function startSelectedDaysAction(clearDays) {
    removeSelection = clearDays
    if (capacityShifts.length === 1) {
      shiftIdsToApplyTo = [capacityShifts[0].shiftId]
      applySelectionAction()
    } else {
      showExistingShiftPicker = true
    }
  }

  function closeApplyToExistingModal() {
    showExistingShiftPicker = false
  }

  function applySelectionAction() {
    const selectedShifts = capacityShifts.filter(s => shiftIdsToApplyTo.map(sid => Number.parseInt(sid, 10)).includes(s.shiftId))
    for (const shift of selectedShifts) {
      // if implicitly on all days, let's add an explicit version of that, so that the mergeShiftDays function can result in a allowlist of rules rather than a blacklist
      const allDaysImplicitly = shift.shiftDays?.length === 0
      const initialShiftDays = allDaysImplicitly
        ? [
            {
              date: capacity.startDate,
              repeatEndDate: null,
              repeatUnit: RepeatUnit.Daily,
              repeatEvery: 1,
              repeatDaysOfWeek: [],
              repeatDaysOfMonth: [],
              startTime: null,
              endTime: null,
              shiftDayId: -1,
            },
          ]
        : shift.shiftDays

      if (removeSelection) {
        shift.shiftDays = removeSpecificDayAndSplitShifts(capacity, initialShiftDays, selectedDaysArray)
      } else {
        const minShiftDayId = Math.min(0, ...shift.shiftDays.map(shift => shift.shiftDayId)) - 1
        shift.shiftDays = mergeShiftDays(initialShiftDays, datesToShiftDays(selectedDaysArray, minShiftDayId))
      }
    }

    capacity.shifts = capacity.shifts // invalidate svelte state
    clearSelectedDays()
    closeApplyToExistingModal()
  }

  function onDateExceptionsBtnClicked() {
    if (!selectedDaysArray.length) {
      showDateExceptionsForm = true
      return
    }

    let newDateExceptions = getInputDates(capacity.dateExceptions)
    // if we have selected days, let's sort them and run addExceptionDate method from DateExceptionForm
    const sortedSelectedDays = selectedDaysArray
      .map(date => dayjs(date, labelFormat))
      .sort((a, b) => a.diff(b))
      .map(date => date.format(labelFormat))
    // need to know that selected days could have many dates so we need to iterate through them but be can only add one date exception at a time
    for (const day of sortedSelectedDays) {
      newDateExceptions = addExceptionDate(day, day, newDateExceptions)
    }
    capacity.dateExceptions = getFormattedDates(newDateExceptions)
    clearSelectedDays()
  }
</script>

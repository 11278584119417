{#if crumbs?.length}
  <ul {style} class="breadcrumbs{className ? ` ${className}` : ''}">
    {#each crumbs as c, i (optionBuilder.selectFromOption(c, nKeySelectors))}
      <li class={liClass}>
        {#if hrefSelector}
          <a class={itemClass} href={optionBuilder.selectFromOption(c, nHrefSelectors)}>{optionBuilder.selectFromOption(c, nLabelSelectors)}</a>
        {:else}
          <span class={itemClass}>{optionBuilder.selectFromOption(c, nLabelSelectors)}</span>
        {/if}
        {#if current || i !== crumbs.length - 1}
          <Icon {xxs} {xs} {sm} name="chevron-right" style={iconStyle} />
        {/if}
      </li>
      {#if current || i !== crumbs.length - 1}
        {space}
      {/if}
    {/each}
    {#if current}
      <li class={liClass}>
        <span class={itemClass}>
          {optionBuilder.selectFromOption(current, nLabelSelectors)}
        </span>
      </li>
    {/if}
  </ul>
{/if}

<script>
  import optionBuilder from 'services/option-builder.js'
  import Icon from 'components/Icon.svelte'

  export let crumbs
  export let current
  export let keySelector = [c => c]
  export let labelSelector = optionBuilder.defaultLabelSelector
  export let hrefSelector = optionBuilder.defaultHrefSelector
  let className = 'inline-flex-row flex-align-center bg-light p1 m0 g05'
  export { className as class }
  export let style = null
  export let itemClass = null
  export let liClass = 'flex-row flex-align-center g05'
  export let iconSize = 'sm'
  export let iconStyle = 'color: #AAA'

  const space = ' '

  $: nKeySelectors = optionBuilder.normalizeSelectors(keySelector)
  $: nLabelSelectors = optionBuilder.normalizeSelectors(labelSelector)
  $: nHrefSelectors = optionBuilder.normalizeSelectors(hrefSelector)
  $: xxs = iconSize === 'xxs'
  $: xs = iconSize === 'xs'
  $: sm = iconSize === 'sm'
</script>

<style lang="scss">
  @import '../../css/helpers';

  .breadcrumbs {
    border-radius: 5px;
    list-style: none;
  }
</style>

<Modal on:close={onClose} color="danger" title="Error" dataTest="capacity-error-modal">
  <div class="modal-body" data-test="errors">
    <p class="my1">
      <Icon name="alert-triangle" class="text-danger" />
      {errorMessage}
    </p>
  </div>
  <div class="modal-footer">
    <Btn class="btn-danger" on:click={onClose} dataTest="dismiss-err">Dismiss</Btn>
  </div>
</Modal>

<script>
  import Modal from 'components/Modal.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Icon from 'components/Icon.svelte'

  export let capacityId
  export let onClose = _.noop
  export let errorMessage = `Opportunity ${capacityId || ''} either does not exist or you do not have access to it.`
</script>

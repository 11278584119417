{#if includeLabel}
  <h3 class="flex-row flex-align-center g05 m0">
    <Icon name="placeholder-student" autoColor />
    <Badge count={value} min={0} />
    {pluralCount('Placeholder student', value, 'omitNumber')}
  </h3>
{/if}
<IconTextLink
  text={actionTitle}
  icon={value === 0 ? 'plus' : 'edit'}
  onClick={() => (open = true)}
  id="add-placeholder-student-btn"
  class={`${includeLabel ? 'mb1' : ''}`.trimStart()}
  tooltip={atMaxStudents
    ? {
        content: 'Maximum student limit reached for this opportunity.',
        options: { placement: 'right' },
      }
    : null}
/>
{#if open}
  <Modal title={actionTitle} on:close={closeModal}>
    <Form on:submit={savePlaceholders}>
      <div class="modal-body">
        <p class="mb1">Placeholder students are temporary. They can be used to indicate how many students you want to join this rotation.</p>
        <!-- TODO(phase2-nursing): Update when auto close rules are implemented -->
        <FormGroup>
          <label class="block" for="placeholder-student-count">How many placeholder students should this rotation have?</label>
          <InputNumberWithUnit
            name="placeholder-student-count"
            condense
            bind:value={valueTemp}
            max={maxStudentCount ?? null}
            unit="placeholder student"
            class="input-group-inline-grid"
          />
        </FormGroup>
        {#if remainingStudentSpots != null}
          <em>This opportunity has {pluralCount('student spot', remainingStudentSpots)} remaining.</em>
        {/if}
        {#if invalidMatchDayConfigs.length && valueTemp != null}
          <Alert type="info">Removing placeholder students will affect the following schedules.</Alert>
          <div class="flex-column g1 mt1">
            {#each filteredShifts as shift}
              <div class="card-left-{shift.color} shift text-blackText bg-white p1 rounded-lg" style="box-shadow: 1px 1px 3px lightgray">
                <strong>{shift.name}</strong>
                {#each invalidMatchDayConfigs.filter(mdc => mdc.shiftId === shift.shiftId) as mdc}
                  <div class="flex-row flex-align-center g05">
                    <Icon name="calendar" class="color-text-steel" />
                    <span class="text-right" style="width: 70px;">
                      {mdc.date}
                    </span>
                    <Icon name="placeholder-student" autoColor />
                    <Icon name="times" />
                    <span class="text-right" style="width: 15px;">
                      {mdc.placeholderStudentCount}
                    </span>
                    <Icon name="arrow-right" />
                    <span>
                      {valueTemp}
                    </span>
                  </div>
                {/each}
              </div>
            {/each}
          </div>
        {/if}
      </div>
      <div class="modal-footer flex-row flex-align-center g05">
        <SubmitBtn icon={submitBtnIcon} disabled={submitBtnDisabled} dataTest="save-placeholder-students-btn">
          {submitBtnLabel}
        </SubmitBtn>
        <Btn icon="close" on:click={closeModal}>Cancel</Btn>
      </div>
    </Form>
  </Modal>
{/if}

<script>
  import { pluralCount } from 'services/string-utils.js'
  import Alert from 'components/bootstrap/Alert.svelte'
  import Badge from './Badge.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import Form from 'components/Form.svelte'
  import FormGroup from 'components/bootstrap/FormGroup.svelte'
  import Icon from './Icon.svelte'
  import Modal from 'components/Modal.svelte'
  import IconTextLink from 'components/IconTextLink.svelte'
  import InputNumberWithUnit from './InputNumberWithUnit.svelte'
  import SubmitBtn from 'components/bootstrap/SubmitBtn.svelte'

  export let shifts = []
  export let matchDayConfigs
  export let value
  export let open = false
  export let atMaxStudents
  export let includeLabel = false
  export let maxStudentCount

  $: remainingStudentSpots = maxStudentCount ? Math.max(maxStudentCount - valueTemp, 0) : null
  $: actionTitle =
    value > 0 ? (remainingStudentSpots === 0 ? 'Remove placeholder students' : 'Add/remove placeholder students') : 'Add placeholder students'
  $: valueTemp = value
  $: invalidMatchDayConfigs = matchDayConfigs.filter(mdc => mdc.placeholderStudentCount > valueTemp)
  $: shiftsWithInvalidMatchDayConfigs = invalidMatchDayConfigs.map(mdc => mdc.shiftId)
  $: nPlaceholderStudents = pluralCount('placeholder student', Math.abs(value - valueTemp))
  $: filteredShifts = shifts?.filter(s => shiftsWithInvalidMatchDayConfigs.includes(s.shiftId)) || []
  $: submitBtnDisabled = valueTemp === value || Number.isNaN(valueTemp)
  $: submitBtnIcon = Number.isNaN(valueTemp) ? 'cancel' : valueTemp >= value ? 'plus' : 'delete'
  $: submitBtnLabel =
    valueTemp > value
      ? `Add ${nPlaceholderStudents}`
      : valueTemp < value
        ? value === 1
          ? 'Remove the placeholder student'
          : `Remove ${valueTemp <= 0 ? 'all ' : ''}${nPlaceholderStudents}`
        : 'Add placeholder students'
  function closeModal() {
    open = false
    valueTemp = value
  }

  function savePlaceholders() {
    value = valueTemp
    for (const mdc of matchDayConfigs) {
      mdc.placeholderStudentCount = Math.clamp(mdc.placeholderStudentCount, 0, value)
    }
    matchDayConfigs = matchDayConfigs.filter(mdc => mdc.placeholderStudentCount > 0 || mdc.startTime || mdc.endTtime)
    open = false
  }
</script>

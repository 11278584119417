{#if stacked}
  <div class="flex-column g05 mb05" style="max-width: 160px;">
    <div class="flex-row flex-align-center g05 input-group" style="height: 30px" on:mousedown|stopPropagation>
      <Icon name="time" class="text-success" />
      <div class="flex-row flex-align-center" style="height: 30px">
        <Box
          {autofocus}
          bind:value={startValue}
          placeholder={startPlaceholder}
          {disabled}
          dataTest="{name}-start"
          stacked
          on:changed={e => (_start = e.detail)}
          on:selected={focusEnd}
        />
      </div>
    </div>
    <div class="flex-row flex-align-center g05 input-group" style="height: 30px" on:mousedown|stopPropagation>
      <Icon name="time" class="text-danger" />
      <Box
        bind:this={endBox}
        bind:value={endValue}
        placeholder={endPlaceholder}
        {disabled}
        dataTest="{name}-end"
        stacked
        on:changed={e => (_end = e.detail)}
      />
    </div>
  </div>
{:else}
  <div class="input-time">
    <div class="input-group" id="{name}Range">
      <span class="input-group-addon {range ? 'p05 text-success' : ''}">
        <Icon name="time" fw class={iconClass} />
      </span>
      <Box
        {autofocus}
        bind:value={startValue}
        placeholder={startPlaceholder}
        {disabled}
        dataTest="{name}-start"
        on:changed={e => (_start = e.detail)}
        on:selected={focusEnd}
      />
      {#if range}
        <label for="{name}-end" class="input-group-addon p05"><slot name="rangeEndLabel">to<Icon class="ml05 text-danger" name="time" /></slot></label
        >
        <Box
          bind:this={endBox}
          bind:value={endValue}
          placeholder={endPlaceholder}
          {disabled}
          dataTest="{name}-end"
          on:changed={e => (_end = e.detail)}
        />
      {/if}
      {#if showTotal}
        <span class="diff input-group-addon" data-test="{name}-total">{diffTimeHrsFormatted || '0hrs'}</span>
      {/if}
      {#if allowClear}
        <a class="input-group-addon" on:click={clear} href={null} data-test="{name}-clear">
          <Icon name="close" />
        </a>
      {/if}
    </div>
  </div>
{/if}

<script>
  import { getContext } from 'svelte'
  import Icon from 'components/Icon.svelte'
  import dateService from 'services/date-service.js'
  import Box from 'components/fields/InputTime.Box.svelte'

  export let name = null
  export let startValue = null
  export let endValue = null
  export let startPlaceholder = 'Start'
  export let endPlaceholder = 'End'
  const initialStartValue = startValue
  const initialEndValue = endValue
  export let allowClear = false
  export let disabled = false
  export let range = false
  export let autofocus = false
  export let iconClass = null
  export let showTotal = true
  export let stacked = false

  let _start
  let _end
  let endBox

  $: diffTimeHrsFormatted = dateService.diffTimeHrsFormatted(_start, _end)

  function clear() {
    startValue = null
    endValue = null
    _start = null
    _end = null
  }

  function focusEnd() {
    if (endBox) endBox.focus()
  }

  const markDirty = getContext('markDirty')
  $: if (markDirty != null && (startValue != initialStartValue || endValue != initialEndValue)) markDirty()
</script>

<style>
  .input-time {
    max-width: 370px;
  }
  .diff {
    min-width: 75px; /*so doesn't bounce around as you press up/down */
    text-align: center;
    font-size: 12px;
  }
</style>

{#if showPresets}
  <InputSelect inline sm {options} bind:value name="{valueUnit}-num" />
{:else}
  <InputNumberAbs bind:value {valueUnit} sm bind:this={inputNumberElem} />
{/if}

<script>
  import InputNumberAbs from 'components/fields/InputNumberAbs.svelte'
  import InputSelect from 'components/fields/InputSelect.svelte'
  import { buildOption } from 'services/option-builder.js'

  export let value
  export let valueUnit
  export let options = null

  const initialValue = value
  let needInitialValueCheck = true

  const custom = 'custom'
  let showPresets = true
  let inputNumberElem

  if (options == null)
    options = [
      ['this', 0],
      ['next', 1],
      ['last', -1],
    ].map(p => buildOption(`${p[0]} ${valueUnit}`, p[1]))
  options.push({ name: custom, value: 2, class: 'em' })

  $: selected = options.find(o => o.value === value)

  let hasAutofocused = false
  $: if (selected == null || selected.name === custom) {
    showPresets = false
    // Put this method's body separate so we don't gain unnecessary
    // dependencies and evaluate this twice immediately.
    autofocusIfNecessary()
  }

  function autofocusIfNecessary() {
    // If this is the first time evaluating this block, the <QuickDropdown>
    // just opened. If so, we don't want to autofocus the non-preset input.
    // We only want to autofocus it if the user just clicked 'custom', and not if they
    // just switched from `{valueUnit} from now` to `{valueUnit} ago` (or vice versa).
    if (!hasAutofocused && (value !== initialValue || !needInitialValueCheck)) {
      setTimeout(() => inputNumberElem?.focus?.())
      hasAutofocused = true
    }
    needInitialValueCheck = false
  }
</script>

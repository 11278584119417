<div class="input-group">
  <span class="input-group-addon" on:click={focus}>
    <Icon name="phone" fw />
  </span>
  <input
    type="text"
    class="form-control {className}
    {disabled ? 'disabled' : ''}"
    bind:this={inputElem}
    {name}
    id={name}
    data-test={name}
    bind:value
    on:blur={() => (blurred = true)}
    {placeholder}
    {style}
    {maxlength}
    {autocomplete}
    {disabled}
  />
</div>

<script>
  import Icon from 'components/Icon.svelte'
  import { getContext } from 'svelte'

  export let autofocus = false
  export let autocomplete = null
  export let value = null
  export let name = null
  export let placeholder = null
  export let disabled = false
  export { className as class }
  export let style = ''
  export let maxlength = null

  const initialValue = value
  const markDirty = getContext('markDirty')
  let className = ''
  let blurred = false
  let inputElem = null

  $: if (autofocus && inputElem) focus()
  $: if (markDirty && blurred && value != initialValue) markDirty()

  export function focus() {
    setTimeout(() => inputElem?.focus?.())
  }
</script>

{#if log.verifiedDate == null}
  <Btn class="btn-primary btn-sm" on:click={() => verify(true)} {loading}>
    <Icon name="check" />
    Verify
  </Btn>
{:else}
  <Btn class="btn-outline-warning btn-sm" on:click={() => verify(false)} {loading}>Unverify</Btn>
{/if}

<script>
  import Icon from 'components/Icon.svelte'
  import Btn from 'components/bootstrap/Btn.svelte'
  import { createEventDispatcher } from 'svelte'
  import api from 'services/api.js'
  import toaster from 'services/toaster.js'

  export let log

  const dispatch = createEventDispatcher()
  let loading = false

  async function verify(verified) {
    loading = true
    await api.hourLog
      .verify(
        {
          hourLogId: log.hourLogId,
          matchId: log.matchId,
          verified,
        },
        api.noMonitor
      )
      .finally(() => (loading = false))
    toaster.toast({
      message: `Hour log ${verified ? 'verified' : 'unverified'}`,
      type: 'success',
      icon: 'time',
      url: `/dashboard/rotation-list/matches/${log.matchId}`,
    })
    dispatch('changed')
  }
</script>

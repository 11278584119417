<div class="flex-column g05">
  {#each undeletedStudentRecords as sr (sr.orgId)}
    <OrgProfilePictureAndName
      name={sr.orgName}
      relativeName={sr.orgRelativeName}
      profilePicture={sr.orgProfilePicture}
      size="medium"
      useOverflowEllipsis
    >
      {#if sr.active}
        <StudentRecordStatus studentRecord={sr} />
      {/if}
    </OrgProfilePictureAndName>
  {/each}
</div>

<script>
  import OrgProfilePictureAndName from 'components/OrgProfilePictureAndName.svelte'
  import StudentRecordStatus from 'components/StudentRecordStatus.svelte'

  export let student

  $: undeletedStudentRecords = student.studentRecords.filter(sr => sr.deleted == null)
</script>
